import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { useSplitList, useVisibleListItems } from '@gain/utils/list'
import React from 'react'

import { CardContentEmpty } from '../../../../../common/card/card'
import ViewMoreButton from '../../../../../common/view-more-button'
import VirtualTable, { ColumnConfig } from '../../../../../common/virtual-table'
import AssetContentLink from '../../../../../features/asset/asset-content-link'
import FinancialValue from '../../../../../features/financial/financial-value'

const similarCompaniesColumns: ColumnConfig<AssetListItem>[] = [
  {
    headerName: 'Similar companies',
    field: 'name',
    sortable: false,
    width: 0,
    flex: 1,
    renderCell: ({ row }) => (
      <AssetContentLink
        asset={row}
        id={row.id}
        logoFileUrl={row.logoFileUrl}
        name={row.name}
      />
    ),
  },
  {
    field: 'region',
    headerName: 'Region',
    align: 'center',
    width: 48,
    sortable: false,
    renderCell: ({ value }) => (value ? <Flag code={value} /> : <>-</>),
  },
  {
    field: 'revenueEur',
    headerName: 'Revenue',
    align: 'right',
    width: 100,
    sortable: false,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
]

interface AssetMarketSimilarCompaniesProps {
  assetList: AssetListItem[]
  loading: boolean
}

export default function AssetMarketSimilarCompanies({
  assetList,
  loading,
}: AssetMarketSimilarCompaniesProps) {
  const [initialAssets, additionalAssets] = useSplitList(assetList, 4)
  const [rows] = useVisibleListItems(initialAssets, additionalAssets)

  if (rows.length === 0 && !loading) {
    return <CardContentEmpty message={'No data available'} />
  }

  return (
    <>
      <VirtualTable
        columns={similarCompaniesColumns}
        loadingInitial={loading}
        RowComponentProps={{
          hover: false,
        }}
        rows={rows}
        variant={'inline'}
      />

      <ViewMoreButton
        amount={additionalAssets.length}
        // href is not needed as the card itself is clickable
        href={undefined}
      />
    </>
  )
}

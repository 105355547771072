import { formatNumber } from './format-number'

interface FormatNumberOptions {
  round?: number
  prefix?: string
  suffix?: string
  emptyValue?: string
}

// formatNumberInternal is used by formatMultiple, formatThousands, and formatPercentage
// and should not be used outside the number-module.
//
// Ideally we change this method to use formatNumber instead.
export function formatNumberInternal(
  number: number | null | undefined,
  options: FormatNumberOptions = {}
) {
  const { suffix = '', prefix = '', emptyValue = '-' } = options
  if (typeof number !== 'number') {
    return emptyValue
  }

  let negative = ''
  if (number < 0) {
    negative = '-'
  }

  let round = 1
  if (number === 0) {
    round = 0
  } else if (typeof options.round === 'number') {
    round = options.round
  }

  return `${negative}${prefix}${formatNumber(Math.abs(number), { round })}${suffix}`
}

import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import React from 'react'

import { MobileListSkeleton } from '../../../../common/list-mobile'

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const StyledTabsSkeletonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  height: 48,
  padding: theme.spacing(0, 3),
}))

export default function SearchResultSkeleton() {
  return (
    <StyledRoot>
      <StyledTabsSkeletonContainer>
        <Skeleton width={71} />
        <Skeleton width={73} />
        <Skeleton width={87} />
      </StyledTabsSkeletonContainer>
      <Divider />
      <MobileListSkeleton nrOfItems={30} />
    </StyledRoot>
  )
}

import { useUserCurrency } from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { useConvertCurrencyCallback, useFormatCurrencyCallback } from '@gain/utils/currency'
import { parseYearMonth } from '@gain/utils/date'
import { ratingInvestmentCriteriaMap } from '@gain/utils/investment-criteria'
import { scaleLog } from 'd3'
import { differenceInMonths } from 'date-fns/differenceInMonths'
import { range } from 'lodash'
import { useCallback, useMemo } from 'react'

import { ScaleConfig } from '../charts/bubble-chart/bubble-chart'

// The currency logarithmic scale has intervals of 1, 10, 100, and 1000 (in
// millions). For some currencies the exchange rate is so low that we want to
// add an extra interval of 10,000. This threshold determines when to add this 10k.
export const CONVERSION_THRESHOLD_RATIO_10K = 0.25

export const INVESTMENT_CRITERIA_KEYS = [
  'ratingGrowth',
  'ratingOrganicGrowth',
  'ratingGrossMargin',
  'ratingEbitda',
  'ratingConversion',
  'ratingNonCyclical',
  'ratingContracted',
  'ratingLeader',
  'ratingMultinational',
  'ratingBuyAndBuild',
] as const

function isInvestmentCriteriaKey(key: string): key is (typeof INVESTMENT_CRITERIA_KEYS)[number] {
  return (INVESTMENT_CRITERIA_KEYS as unknown as string[]).includes(key)
}

function createInvestmentCriteriaAxisConfig(
  key: (typeof INVESTMENT_CRITERIA_KEYS)[number]
): ScaleConfig<AssetListItem> {
  const rating = ratingInvestmentCriteriaMap[key]

  return {
    label: rating.label,
    explainer: rating.explainer,
    options: rating.options.map((option) => option.value),
    getValue: ((d) => d[key]) || null,
    getLabel: (value) =>
      rating.options.find((option) => option.value === value)?.label || 'Unknown',
  }
}

const KEYS = [
  ...INVESTMENT_CRITERIA_KEYS,
  'ebitda',
  'yearsInPortfolio',
  'yearsWithBusiness',
  'ceoAge',
] as const

const CEO_TENURE_AXIS_CONFIG: ScaleConfig<AssetListItem> = {
  label: 'CEO years with the business',
  options: range(0, 8).map((value) => value * 5),
  getValue: (asset) => {
    if (asset.ceoTenure === null) {
      return null
    }

    return asset.ceoTenure > 35 ? 35 : asset.ceoTenure
  },
  getLabel: (value) => (value < 35 ? value.toString(10) : '>35'),
}

const YEARS_IN_PORTFOLIO_AXIS_CONFIG: ScaleConfig<AssetListItem> = {
  label: 'Years in portfolio',
  options: range(0, 10),
  getValue: (asset) => {
    if (asset.lastDealYear === null && asset.lastDealMonth === null) {
      return null
    }
    const lastDealDate = parseYearMonth(asset.lastDealYear, asset.lastDealMonth)
    const differenceInYears = differenceInMonths(new Date(), lastDealDate) / 12
    if (differenceInYears < 9) {
      return differenceInYears
    }

    return 9
  },
  getLabel: (value) => (value < 9 ? value.toString(10) : '>9'),
}

function useCreateEbitdaAxisConfig() {
  const formatCurrency = useFormatCurrencyCallback()
  const convertCurrency = useConvertCurrencyCallback()
  const userCurrency = useUserCurrency()

  return useCallback((): ScaleConfig<AssetListItem> => {
    const maxValue = userCurrency.toEur < CONVERSION_THRESHOLD_RATIO_10K ? 10_000 : 1_000

    return {
      label: 'Latest EBITDA',
      getValue: (asset) => convertCurrency(asset.ebitdaEur, 'EUR', userCurrency.name),
      getLabel: (value) =>
        formatCurrency(value, {
          dataCurrency: userCurrency.name,
          disablePrefix: true,
          round: 0,
        }),
      scaleFn: ({ size }) =>
        scaleLog()
          .domain([1, maxValue])
          .range([32, size - 32]),
      ticksFn: () => {
        const ticks: number[] = []
        for (let i = 1; i <= maxValue; i *= 10) {
          ticks.push(i)
        }
        return ticks
      },
      showGridLine: () => false,
    }
  }, [formatCurrency, convertCurrency, userCurrency])
}

const CEO_AGE_AXIS_CONFIG: ScaleConfig<AssetListItem> = {
  label: 'CEO age',
  options: range(0, 8).map((value) => 35 + value * 5),
  getValue: (asset) => {
    if (!asset.ceoAge) {
      return null
    }

    if (asset.ceoAge < 35) {
      return 35
    }

    if (asset.ceoAge > 70) {
      return 70
    }

    return asset.ceoAge
  },
  getLabel: (value) => {
    if (value <= 35) {
      return '<35'
    } else if (value >= 70) {
      return '>70'
    } else {
      return value.toString(10)
    }
  },
}

export type CompanyAxisKey = (typeof KEYS)[number]

export default function useCompanyAxisConfig(key: CompanyAxisKey) {
  const createEbitdaAxisConfig = useCreateEbitdaAxisConfig()

  return useMemo(() => {
    if (isInvestmentCriteriaKey(key)) {
      return createInvestmentCriteriaAxisConfig(key)
    }
    switch (key) {
      case 'yearsInPortfolio':
        return YEARS_IN_PORTFOLIO_AXIS_CONFIG
      case 'yearsWithBusiness':
        return CEO_TENURE_AXIS_CONFIG
      case 'ceoAge':
        return CEO_AGE_AXIS_CONFIG
      case 'ebitda':
        return createEbitdaAxisConfig()
      default:
        throw new Error(`${key} is not supported`)
    }
  }, [createEbitdaAxisConfig, key])
}

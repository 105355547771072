import { FilterableInvestorListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

import {
  investorListItemTableColumns,
  investorTableColumnNames,
} from '../../investor/investor-list-item-table/investor-list-item-table-columns'
import useListViewColumnVisibilityModel from '../use-list-view-column-visibility-model'

export default function useInvestorListViewColumns(
  activeFilterColumns: (keyof FilterableInvestorListItem)[]
) {
  const visibleColumns = useListViewColumnVisibilityModel(
    'investor',
    investorTableColumnNames,
    activeFilterColumns
  )

  return useMemo(() => {
    return investorListItemTableColumns.filter(({ field }) => visibleColumns[field])
  }, [visibleColumns])
}

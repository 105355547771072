import { SpinnerIcon } from '@gain/components/icons'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import {
  AutocompleteFetchSuggestionsFn,
  AutocompleteOption,
} from '../../filter-config/filter-config-model'
import { AutocompleteFormSchemaType } from './filter-autocomplete'
import {
  formatAutocompleteOptionLabel,
  useAutocompleteSuggestions,
} from './filter-autocomplete-utils'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

interface FilterAutocompleteSuggestionsProps {
  label?: string
  onSelectOption: (option: AutocompleteOption) => void
  fetchSuggestions?: AutocompleteFetchSuggestionsFn
}

export default function FilterAutocompleteSuggestions({
  label,
  onSelectOption,
  fetchSuggestions,
}: FilterAutocompleteSuggestionsProps) {
  const includedOptions = useWatch<AutocompleteFormSchemaType, 'include.value'>({
    name: 'include.value',
  })

  const includedValues = useMemo(
    () => includedOptions.map((option) => option.value),
    [includedOptions]
  )

  const suggestions = useAutocompleteSuggestions(includedValues, fetchSuggestions)

  if (!fetchSuggestions || includedValues.length === 0) {
    return null
  }

  return (
    <StyledRoot>
      <Divider sx={{ my: 1.5 }} />
      <Typography
        sx={{ display: 'block', mb: 0.5 }}
        variant={'overline'}>
        {label || 'Suggestions'}
      </Typography>
      {suggestions.loading && <SpinnerIcon />}
      {!suggestions.loading && suggestions.value.length === 0 && <span>No suggestions found</span>}
      {!suggestions.loading && suggestions.value.length > 0 && (
        <Stack
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={0.5}>
          {suggestions.value.map((option, index) => (
            <Chip
              key={index}
              color={'default'}
              label={formatAutocompleteOptionLabel(option)}
              onClick={() => onSelectOption(option)}
              size={'medium'}
            />
          ))}
        </Stack>
      )}
    </StyledRoot>
  )
}

import { iconButtonClasses } from '@mui/material/IconButton'
import { alpha, Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export const MuiIconButton = (theme: Theme): Components['MuiIconButton'] => ({
  defaultProps: {
    size: 'small',
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: 6,
      fontSize: 24,
      borderRadius: 8,
      color: theme.palette.text.primary,
      [`& .${svgIconClasses.root}`]: {
        width: '1em',
        height: '1em',
        fontSize: 'inherit',
      },
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
        },
      },
    },
    edgeEnd: {
      marginRight: -8,
      [`& .${iconButtonClasses.sizeSmall}`]: {
        marginRight: -8,
      },
    },
    edgeStart: {
      marginLeft: -8,
      [`& .${iconButtonClasses.sizeSmall}`]: {
        marginLeft: -8,
      },
    },
    sizeSmall: {
      padding: 6,
      fontSize: 20,
    },
    colorPrimary: {
      color: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      },
    },
  },
  variants: [
    {
      props: { color: 'secondary' },
      style: {
        color: theme.palette.text.secondary,
      },
    },
  ],
})

import { ArrowUpRightIcon } from '@gain/components/icons'
import { SlotName, SlotPortal } from '@gain/components/slot'
import { addHttpsIfMissing, formatUrlHostname } from '@gain/utils/common'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { alpha, styled } from '@mui/material/styles'
import { tabClasses } from '@mui/material/Tab'
import { tabsClasses } from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import React from 'react'

import { ProfileInfoAndTabsProps } from './profile-info-and-tabs-model'
import ProfileTabsScrollable from './profile-tabs-scrollable'

const StyledTabBarContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  height: 56,
  padding: theme.spacing(1.5, 0),
  margin: 0,
  position: 'relative',
  bottom: 0,

  [`& .${tabsClasses.indicator}`]: {
    backgroundColor: alpha(theme.palette.info.main, 0.14),
    borderRadius: 16,
    height: 32,
  },

  [`& .${tabsClasses.root}`]: {
    borderRadius: 0,
    padding: theme.spacing(0.5, 0),
  },

  [`& .${tabClasses.root}`]: {
    padding: theme.spacing(0, 1.5),
    minHeight: 32,
    marginRight: 2,
    marginLeft: 2,
    '&:first-of-type': {
      marginLeft: `${theme.spacing(1.5)} !important`,
      marginRight: 2,
    },
    '&:last-of-type': {
      marginLeft: 2,
      marginRight: `${theme.spacing(1.5)} !important`,
    },
  },

  [`& .${tabClasses.selected}`]: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const StyledLogoAndTextContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(3),
}))

const StyledLogo = styled('img', {
  shouldForwardProp: (prop) => !['fullSize', 'size'].includes(prop.toString()),
})<{ fullSize?: boolean; size: number }>(({ theme, fullSize, size }) => ({
  boxSizing: 'border-box',
  borderRadius: 8,
  objectFit: fullSize ? 'cover' : 'contain',
  backgroundColor: theme.palette.background.paper,
  width: size,
  height: size,
  ...(!fullSize && {
    border: `1px solid ${theme.palette.divider}`,
    padding: '0.5%',
  }),
}))

const StyledIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ theme, size }) => ({
  boxSizing: 'border-box',
  borderRadius: 8,
  backgroundColor: theme.palette.grey['100'],
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: size,
  height: size,
  '& svg': {
    color: alpha(theme.palette.text.secondary, 0.6),
    width: '50%',
    height: '50%',
  },
}))

const StyledTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
})

const StyledSubtitle = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}))

export default function ProfileInfoAndTabsXs({
  activeTab,
  replaceUrl,
  tabs,
  logoFileUrl,
  logoFullSize,
  onLogoClick,
  defaultIcon: DefaultIcon,
  title,
  titleNoWrap = false,
  subtitle,
  subtitleUrl,
}: ProfileInfoAndTabsProps) {
  return (
    <>
      <StyledLogoAndTextContainer>
        {logoFileUrl && (
          <StyledLogo
            fullSize={logoFullSize}
            onClick={onLogoClick}
            size={56}
            src={logoFileUrl}
            sx={{ cursor: onLogoClick ? 'pointer' : 'default' }}
          />
        )}
        {!logoFileUrl && DefaultIcon && (
          <StyledIconContainer size={56}>
            <DefaultIcon />
          </StyledIconContainer>
        )}
        <StyledTextContainer>
          <Typography
            color={'text.primary'}
            noWrap={titleNoWrap}
            variant={'h5'}>
            {title}
          </Typography>
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
          {subtitleUrl && (
            <StyledSubtitle>
              <Link
                color={'text.secondary'}
                href={addHttpsIfMissing(subtitleUrl)}
                rel={'noopener noreferrer'}
                target={'_blank'}
                underline={'none'}>
                {formatUrlHostname(subtitleUrl)}
                <ArrowUpRightIcon
                  fontSize={'inherit'}
                  sx={{ verticalAlign: 'middle' }}
                />
              </Link>
            </StyledSubtitle>
          )}
        </StyledTextContainer>
      </StyledLogoAndTextContainer>

      <SlotPortal slotName={SlotName.MobileBottom}>
        {tabs && tabs.length > 1 && (
          <StyledTabBarContainer>
            <ProfileTabsScrollable
              activeTab={activeTab}
              replaceUrl={replaceUrl}
              tabs={tabs}
            />
          </StyledTabBarContainer>
        )}
      </SlotPortal>
    </>
  )
}

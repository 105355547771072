import { RpcMethodMap } from '@gain/rpc/app-model'
import { SWRConfiguration } from 'swr'

import { useRpc, UseRpcParams, UseRpcResponse } from './use-rpc'

export interface AppSwrKey<Method extends keyof RpcMethodMap> {
  method: Method
  params: Partial<RpcMethodMap[Method]['params']> | null | undefined
}

export function useAppSwr<
  Method extends keyof RpcMethodMap,
  Params extends RpcMethodMap[Method]['params'],
  Response extends RpcMethodMap[Method]['result'],
  Error = unknown
>(
  method: Method,
  params?: UseRpcParams<Params>,
  options?: SWRConfiguration
): UseRpcResponse<Response> {
  return useRpc<Params, Response, Error>(method, params, options)
}

export function calculateGrowthPercentage(
  base: number | undefined | null,
  comparedTo: number
): number | undefined | null {
  if (typeof base !== 'number') {
    return base
  }

  const percentage = ((comparedTo - base) / base) * 100

  if (percentage < -100) {
    return -100
  }

  return percentage
}

import { BriefcaseMoneyIcon } from '@gain/components/icons'
import {
  AssetListItem,
  FILTERABLE_INVESTOR_LIST_ITEM_TO_ASSET_MAP,
  FilterableInvestorListItem,
  InvestorListItem,
} from '@gain/rpc/app-model'
import { listFilter, listSort, serializeListSort } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { useIsXs } from '@gain/utils/responsive'
import Hidden from '@mui/material/Hidden'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemSecondaryValue,
  MobileListItemText,
} from '../../../common/list-mobile'
import Logo from '../../../common/logo'
import { useMeasureMinWidth } from '../../../common/responsive'
import { RowComponentProps } from '../../../common/virtual-table'
import { generateInvestorPagePath } from '../../../routes/utils'
import { AssetFilterField } from '../../asset/asset-filter-bar'
import ColumnPickerButton from '../../column-picker/column-picker-button'
import { ExportListButton } from '../../export/export-button'
import { useFilterModelQueryParam, useHasActiveFilterPrefix } from '../../filter/filter-bar'
import {
  INVESTOR_DEFAULT_FILTERS,
  INVESTOR_FILTER_MAP,
  INVESTOR_FILTER_MENU,
  InvestorFilterField,
} from '../../investor/investor-filter-bar'
import { investorTableColumnNames } from '../../investor/investor-list-item-table/investor-list-item-table-columns'
import ListView, { ListViewApi, ListViewProps } from '../core'
import ListViewFooter from '../list-view-footer'
import { createListViewRowLink } from '../list-view-row-link'
import useActiveFilterColumns from '../use-active-filter-columns'
import filterToInvestorTableColumnMapping from './filter-to-investor-table-column-mapping'
import useInvestorListViewColumns from './use-investor-list-view-columns'

type InvestorListViewProps = Pick<
  ListViewProps<
    'data.listInvestors',
    InvestorListItem,
    FilterableInvestorListItem,
    InvestorFilterField
  >,
  | 'defaultFilter'
  | 'defaultSort'
  | 'disableFilters'
  | 'renderBulkActions'
  | 'apiRef'
  | 'onFilter'
  | 'tableEmptyProps'
  | 'renderPageActions'
  | 'hideCount'
  | 'inline'
>

const InvestorListPageInvestorRow = createListViewRowLink<
  'data.listInvestors',
  InvestorListItem,
  FilterableInvestorListItem,
  InvestorFilterField,
  'data.listAssets',
  AssetListItem,
  AssetListItem,
  AssetFilterField
>('/portfolio', FILTERABLE_INVESTOR_LIST_ITEM_TO_ASSET_MAP)

export default function InvestorListView({ defaultFilter, ...props }: InvestorListViewProps) {
  const [listFooterRef, isLargeFooter] = useMeasureMinWidth<HTMLDivElement>(600)
  const formatCurrency = useFormatCurrencyCallback()
  const [filterModel] = useFilterModelQueryParam<FilterableInvestorListItem, InvestorFilterField>()
  const activeFilterColumns = useActiveFilterColumns(
    filterModel,
    INVESTOR_FILTER_MAP,
    investorTableColumnNames,
    filterToInvestorTableColumnMapping
  )
  const columns = useInvestorListViewColumns(activeFilterColumns)
  const hasAssetFilter = useHasActiveFilterPrefix('asset', INVESTOR_FILTER_MAP)

  const isXs = useIsXs()

  const handleRenderPageActions = useCallback(
    (
      api: ListViewApi<
        'data.listInvestors',
        InvestorListItem,
        FilterableInvestorListItem,
        InvestorFilterField
      >
    ) => (
      <>
        <Hidden mdDown>
          <ColumnPickerButton
            activeFilterColumns={activeFilterColumns}
            columnConfigId={'investor'}
          />
        </Hidden>
        <Hidden smDown>
          <ExportListButton
            method={'data.exportInvestors'}
            params={{
              filter: api.queryParams.filter,
              columns: [],
              filename: '',
              search: api.search || '',
              sort: api.sort.map(serializeListSort),
            }}
            tooltipMode={'always'}
            variant={'text'}
          />
        </Hidden>
      </>
    ),
    [activeFilterColumns]
  )

  const handleRenderBulkActions = useCallback(
    (
      api: ListViewApi<
        'data.listInvestors',
        InvestorListItem,
        FilterableInvestorListItem,
        InvestorFilterField
      >
    ) => (
      <ListViewFooter
        ref={listFooterRef}
        api={api}
        exportMethod={'data.exportInvestors'}
        itemNamePlural={'investors'}
        itemNameSingular={'investor'}>
        <ExportListButton
          color={'primary'}
          method={'data.exportInvestors'}
          params={{
            columns: [],
            filename: 'Investor export - Gain.pro.xlsx',
            filter: [
              listFilter<InvestorListItem>(
                'id',
                '=',
                api.selectedRows.map(({ id }) => id)
              ),
            ],
            search: api.search || '',
            sort: api.sort.map(serializeListSort),
          }}
          tooltipMode={'never'}
          variant={isLargeFooter ? 'contained' : 'icon-contained'}
        />
      </ListViewFooter>
    ),
    [listFooterRef, isLargeFooter]
  )

  return (
    <ListView
      {...props}
      addFilterMenu={INVESTOR_FILTER_MENU}
      defaultFilter={defaultFilter}
      defaultFilterModel={INVESTOR_DEFAULT_FILTERS}
      defaultSort={
        hasAssetFilter
          ? [listSort<InvestorListItem>('assetsFiltered', 'desc')]
          : [listSort<InvestorListItem>('fundsRaisedLastFiveYearsEur', 'desc')]
      }
      filterBarSearchLabel={'Investor name'}
      filterBarSearchPlaceholder={'E.g. EQT'}
      filterConfigMap={INVESTOR_FILTER_MAP}
      inline={isXs}
      method={'data.listInvestors'}
      renderBulkActions={handleRenderBulkActions}
      renderPageActions={handleRenderPageActions}
      sm={{
        variant: 'virtual-table',
        VariantProps: {
          columns: columns,
          RowComponentProps: ({ row }) =>
            ({
              component: InvestorListPageInvestorRow,
              to: generateInvestorPagePath({
                investorId: row.id,
                investorName: row.name,
              }),
            } as RowComponentProps),
        },
      }}
      xs={{
        variant: 'list',
        VariantProps: {
          headerProps: {
            title: 'Name',
            secondaryTitle: 'Companies',
          },
          renderListItem: (item) => (
            <MobileListItem
              key={item.id}
              disableDivider>
              <MobileListItemButton
                as={Link}
                {...{
                  to: generateInvestorPagePath({
                    investorId: item.id,
                    investorName: item.name,
                  }),
                }}>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<BriefcaseMoneyIcon />}
                    size={40}
                    src={item.logoFileUrl}
                  />
                </MobileListItemIcon>
                <MobileListItemText
                  primary={item.name}
                  secondary={
                    isDefined(item.fundsRaisedLastFiveYearsEur)
                      ? `Fundraising (L5Y): ${formatCurrency(item.fundsRaisedLastFiveYearsEur)}`
                      : ''
                  }
                />
                <MobileListItemSecondaryValue>{item.assetsTotal}</MobileListItemSecondaryValue>
              </MobileListItemButton>
            </MobileListItem>
          ),
        },
      }}
      checkboxSelection
      disableOrFilter
    />
  )
}

import { CopyIcon, InfoIcon } from '@gain/components/icons'
import Snackbar from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import { writeToClipboard } from '@gain/utils/common'
import { useDialogState } from '@gain/utils/dialog'
import Chip, { chipClasses } from '@mui/material/Chip'
import { dialogClasses } from '@mui/material/Dialog'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { SnackbarKey } from 'notistack'
import React, { forwardRef } from 'react'

import { AlertDialog } from '../../common/dialog'
import { RequestEmailPerson } from './request-email-button'

/**
 * Chip that shows the icon on the right instead of left.
 */
const StyledChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.icon}`]: {
    order: 1,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
  },
}))
/**
 * AlertDialog with increased maxWidth to provide a better user experience with
 * a lot of text.
 */
const StyledAlertDialog = styled(AlertDialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 500,
  },
})

interface CopyToClipboardSnackbarProps {
  snackbarId: SnackbarKey
  email: string
  confidence: number
  person: RequestEmailPerson
}

/**
 * Snackbar that is shown when an email address is found on user request. This
 * snackbar allows the user to manually copy the email address to the clipboard.
 */
const CopyToClipboardSnackbar = forwardRef<HTMLDivElement, CopyToClipboardSnackbarProps>(
  ({ snackbarId, email, person, confidence }, ref) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState()

    return (
      <>
        <StyledAlertDialog
          message={
            <>
              <p>
                This score represents the confidence level that this corporate email address is
                correct. It is provided by our third party email provider.
              </p>
              <p>
                Email addresses are requested in real time to ensure freshness. For more information
                about privacy at Gain.pro, please read our{' '}
                <Link
                  href={'https://www.gain.pro/privacy-and-cookie-statement'}
                  rel={'noreferrer'}
                  target={'_blank'}>
                  privacy policy.
                </Link>
              </p>
            </>
          }
          onClose={closeDialog}
          open={dialogOpen}
          title={'Requesting corporate emails'}
        />
        <Snackbar
          ref={ref}
          action={{
            onClick: () => {
              writeToClipboard(email)
            },
            title: 'Copy',
            icon: CopyIcon,
            disableCloseOnClick: true,
          }}
          id={snackbarId}
          message={
            <Stack>
              <Typography
                color={'text.secondary'}
                variant={'overline'}
                disableTooltip
                noWrap>
                {person.firstName} {person.lastName}
              </Typography>
              <Stack
                alignItems={'center'}
                flexDirection={'row'}
                gap={1}
                minWidth={0}>
                <Typography
                  color={'text.primary'}
                  variant={'subtitle2'}
                  disableTooltip
                  noWrap>
                  {email}
                </Typography>
                <StyledChip
                  color={confidence < 90 ? 'warning' : 'success'}
                  icon={<InfoIcon />}
                  label={`${confidence}%`}
                  onClick={openDialog}
                  size={'small'}
                />
              </Stack>
            </Stack>
          }
        />
      </>
    )
  }
)

export default CopyToClipboardSnackbar

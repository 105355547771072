import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { ReactNode } from 'react'

export const StyledTypography = styled(Typography)<TypographyProps>({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
})

interface TabPageTitleProps {
  children: ReactNode
}

export default function TabPageTitle({ children }: TabPageTitleProps) {
  return <StyledTypography variant={'h2'}>{children}</StyledTypography>
}

import { ChevronLeftIcon, ChevronRightIcon } from '@gain/components/icons'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton from '@mui/material/IconButton'
import { styled, SxProps, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { forwardRef, MouseEventHandler } from 'react'

const buttonScrollClasses = generateUtilityClasses('ButtonScroll', ['left', 'right'])

const StyledIconButtonContainer = styled('div')({
  zIndex: 1,

  [`&.${buttonScrollClasses.left}`]: {
    background:
      'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)',
  },

  [`&.${buttonScrollClasses.right}`]: {
    background:
      'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)',
  },
})

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 'inherit',
  fontSize: 20,
  color: theme.palette.text.secondary,
}))

export interface ButtonScrollProps {
  direction: 'left' | 'right'
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  sx?: SxProps<Theme>
}

const ButtonScroll = forwardRef<HTMLDivElement, ButtonScrollProps>(function ButtonScroll(
  { direction, className, onClick, ...containerProps },
  ref
) {
  return (
    <StyledIconButtonContainer
      ref={ref}
      className={clsx(className, {
        [buttonScrollClasses.left]: direction === 'left',
        [buttonScrollClasses.right]: direction === 'right',
      })}
      {...containerProps}>
      <StyledIconButton onClick={onClick}>
        {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </StyledIconButton>
    </StyledIconButtonContainer>
  )
})

export default ButtonScroll

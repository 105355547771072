import { ArticleListItem } from '@gain/rpc/app-model'
import { List } from '@gain/rpc/list-model'
import { formatWeek } from '@gain/utils/date'
import { parseISO } from 'date-fns/parseISO'
import { useMemo } from 'react'

interface NewsFeedItemHeader {
  type: 'header'
  data: string
}

interface NewsFeedItemArticle {
  type: 'article'
  data: ArticleListItem
}

export type NewsFeedItemType = NewsFeedItemArticle | NewsFeedItemHeader

export function useNewsFeedItems(groups?: List<ArticleListItem>[]) {
  return useMemo(() => {
    if (!groups) {
      return []
    }

    const articles = groups.reduce(
      (acc, current) => acc.concat(current.items),
      new Array<ArticleListItem>()
    )

    const items: NewsFeedItemType[] = []
    let currentHeading: string | null = null
    for (const article of articles) {
      const heading = formatWeek(parseISO(article.date), {
        thisWeekLabel: 'In the news',
      })

      if (currentHeading === null || currentHeading !== heading) {
        currentHeading = heading
        items.push({
          type: 'header',
          data: heading,
        })
      }

      items.push({
        type: 'article',
        data: article,
      })
    }
    return items
  }, [groups])
}

import { useContext } from 'react'

import UserContext from './user-context'

export function useUserContext() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('UserContext not provided')
  }

  return context
}

export function useUserProfileContext() {
  const { userProfile } = useUserContext()

  return userProfile
}

export default useUserContext

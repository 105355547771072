import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  region,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export interface RegionFilterItem {
  region: string | null
}

export const REGION_FILTER_FIELDS = ['region'] as const

export type RegionFilterField = (typeof REGION_FILTER_FIELDS)[number]

// We avoid using "By region" and "By area" tabs here on purpose. This region-only
// filter is used in tables that don't have our normal list* logic and require
// custom code to handle the filtering. To decrease the complexity of this hack
// we decided to only use the country code filter for the Company HQ.
export const REGION_FILTER_MAP = createFilterMap<RegionFilterItem, typeof REGION_FILTER_FIELDS>(
  region('region', 'Company HQ')
)

export const REGION_FILTERS: FilterModel<RegionFilterItem, RegionFilterField> = [
  filterValueGroup(filterValueItem('region')),
]

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const REGION_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<RegionFilterItem, RegionFilterField>
> = [REGION_FILTER_MAP.region]

import { AppSwrInfiniteResponse, useAppInfiniteListSwr } from '@gain/api/swr'
import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListArgs, ListFilter, ListItemKey, ListSort } from '@gain/rpc/list-model'
import { useSortQueryParam } from '@gain/utils/sort'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import { VirtualSort, VirtualSortHandler } from '../../../common/virtual-table'
import {
  FilterConfigMap,
  FilterModel,
  fromFilterModel,
  useFilterModelQueryParam,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'
import { GaCategory } from '../../google-analytics'
import { useSearchQueryParam } from '../list-view-search'
import {
  ClearSelection,
  HandleSelectionChange,
  useListViewRowSelection,
} from './use-list-view-row-selection'

interface CollectionViewConfig {
  gaCategory: GaCategory
}

export type ListViewMethod =
  | 'admin.listUsers'
  | 'admin.listUserSessions'
  | 'data.listAssets'
  | 'data.listAdvisors'
  | 'data.listDeals'
  | 'data.listIndustries'
  | 'data.listIndustryMarketSegments'
  | 'data.listLegalEntities'
  | 'data.listInvestors'
  | 'data.listInvestorFunds'
  | 'data.listInvestorStrategies'
  | 'data.listAdvisorDeals'
  | 'data.listConferenceEditions'
  | 'data.listLenders'
  | 'data.listCredits'

const listMethodGaCategoryMap: Record<ListViewMethod, CollectionViewConfig> = {
  'admin.listUsers': {
    gaCategory: 'User',
  },
  'admin.listUserSessions': {
    gaCategory: 'User session',
  },
  'data.listAssets': {
    gaCategory: 'Asset',
  },
  'data.listAdvisors': {
    gaCategory: 'Advisor',
  },
  'data.listDeals': {
    gaCategory: 'Deal',
  },
  'data.listIndustries': {
    gaCategory: 'Industry',
  },
  'data.listIndustryMarketSegments': {
    gaCategory: 'Industry market segment',
  },
  'data.listLegalEntities': {
    gaCategory: 'Legal entity',
  },
  'data.listInvestors': {
    gaCategory: 'Investor',
  },
  'data.listInvestorFunds': {
    gaCategory: 'Investor fund',
  },
  'data.listInvestorStrategies': {
    gaCategory: 'Investor strategy',
  },
  'data.listAdvisorDeals': {
    gaCategory: 'Advisor Deal',
  },
  'data.listConferenceEditions': {
    gaCategory: 'Conference',
  },
  'data.listLenders': {
    gaCategory: 'Lender',
  },
  'data.listCredits': {
    gaCategory: 'Credit',
  },
}

export const LIST_VIEW_ITEMS_PER_REQUEST_LIMIT = 50

export interface ListViewApiProps<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
> {
  addFilterMenu: DropdownMenuOptions<FilterConfig<FilterItem, FilterField>>
  defaultFilterModel?: FilterModel<FilterItem, FilterField>
  defaultFilter?: ListFilter<FilterItem>[]
  defaultSort?: ListSort<Item>[]
  filterConfigMap: FilterConfigMap<FilterItem, FilterField>
  method: Method
}

export interface ListViewApi<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
> {
  loading: boolean
  setSort: VirtualSortHandler<Item>
  sort: VirtualSort<Item>[]
  queryParams: ListArgs<Item, FilterItem>
  search: string | null
  setSearch: (search: string | null) => void
  filterModel: FilterModel<FilterItem, FilterField> | null
  setFilters: (filterModel: FilterModel<FilterItem, FilterField>) => void

  swr: AppSwrInfiniteResponse<Item, FilterItem>
  fetchMore: () => Promise<void>

  data: {
    totalSize: number
    rows: Item[]
  }

  handleSelectionChange: HandleSelectionChange
  clearSelection: ClearSelection
  selectedRows: Item[]
  selectedRowIndexes: number[]
  gaCategory: GaCategory
}

function formatQueryParams<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>
>(
  sort: ListSort<Item>[],
  activeFilter: ListFilter<FilterItem>[],
  search: string | null
): ListArgs<Item, FilterItem> {
  return {
    limit: LIST_VIEW_ITEMS_PER_REQUEST_LIMIT,
    page: 0,
    sort,
    search: search || '',
    filter: activeFilter,
  }
}

export const useListViewApi = <
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
>({
  defaultFilterModel,
  defaultFilter,
  defaultSort,
  filterConfigMap,
  method,
}: ListViewApiProps<Method, Item, FilterItem, FilterField>): ListViewApi<
  Method,
  Item,
  FilterItem,
  FilterField
> => {
  const [sort, setSort] = useSortQueryParam<Item>(defaultSort)

  const [filterModel, setFilterModel] = useFilterModelQueryParam<FilterItem, FilterField>()
  const [search, setSearch] = useSearchQueryParam()

  // Determine the active filter based on the filter model. When available
  // the defaultFilter is always applied on top of the new filters.
  const activeFilter = useMemo(() => {
    const newFilter = fromFilterModel(filterModel || defaultFilterModel || [], filterConfigMap)
    return defaultFilter ? defaultFilter.concat(newFilter) : newFilter
  }, [filterModel, defaultFilterModel, filterConfigMap, defaultFilter])

  const [queryParams, setQueryParams] = useState<ListArgs<Item, FilterItem>>(
    formatQueryParams(sort, activeFilter, search)
  )

  const swrApi = useAppInfiniteListSwr<Method, Item, FilterItem, ListArgs<Item, FilterItem>>(
    method,
    queryParams,
    {
      // Set revalidateOnMount to undefined to ensure data fetches only if cache
      // is empty, otherwise behaves like false.
      revalidateOnMount: undefined,
      suspense: false,
    }
  )

  const handleFetchMore = useCallback(async () => {
    if (!swrApi.isLoadingMore || swrApi.isReachingEnd) {
      await swrApi.setSize(swrApi.size + 1)
    }
  }, [swrApi])

  const data = useMemo(() => {
    if (!swrApi.data) {
      return {
        totalSize: 0,
        rows: [],
      }
    }

    return {
      totalSize: swrApi.data?.[0]?.counts.filtered,
      rows: swrApi.data.reduce((rows, response) => rows.concat(response.items), new Array<Item>()),
    }
  }, [swrApi.data])

  const [handleSelectionChange, selectedRows, selectedRowIndexes, clearSelection] =
    useListViewRowSelection(data.rows)

  // Update query parameters when active filter changes
  useEffect(() => {
    const nextQueryParams = formatQueryParams(sort, activeFilter, search)
    setQueryParams((prev) => {
      if (JSON.stringify(nextQueryParams) === JSON.stringify(prev)) {
        return prev
      }

      return nextQueryParams
    })
  }, [sort, activeFilter, search])

  // Clear selection when queryParams change
  useEffect(() => {
    clearSelection()
  }, [clearSelection, queryParams])

  return {
    loading: !swrApi.isLoadingInitial || swrApi.isLoadingMore,
    sort,
    setSort,
    queryParams,
    search,
    setSearch,
    filterModel: filterModel,
    setFilters: setFilterModel,

    handleSelectionChange,
    clearSelection,
    selectedRowIndexes,
    selectedRows,

    data,
    swr: swrApi,
    fetchMore: handleFetchMore,
    gaCategory: listMethodGaCategoryMap[method].gaCategory,
  }
}

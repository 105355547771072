import { objectKeys } from '@gain/utils/typescript'

export enum RpcErrorCode {
  InvalidToken = -32001,
  InvalidCredentials = -32002,
  Unauthorized = -32003,
  InvalidResourceIdentifier = -32004,
  ResourceOffline = -32007,
  ValidationFailed = -32008,
  ParseGermanFinancialsFailed = -32011,
  ExportLimitReached = -32011,
}

export function serializeError(error: any) {
  if (error instanceof RpcError) {
    return {
      name: error.name,
      code: error.code,
      message: error.message,
      data: error.data,
    }
  } else if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
    }
  }
  return error
}

const rpcErrorCodesAsString = objectKeys(RpcErrorCode).map((key) => RpcErrorCode[key].toString())

interface SerializedRpcError {
  code: string
  message: string
}

export const isSerializedJsonRpcError = (error: any): error is SerializedRpcError =>
  typeof error === 'object' &&
  'code' in error &&
  typeof error.code === 'string' &&
  rpcErrorCodesAsString.includes(error.code) &&
  'message' in error &&
  typeof error.message === 'string'

export const deserializeJsonRpcError = (error: SerializedRpcError) =>
  new RpcError(error.message, parseInt(error.code, 10) as RpcErrorCode)

export class RpcError<Data = any> extends Error {
  constructor(
    override readonly message: string,
    readonly code: RpcErrorCode,
    readonly data?: Data
  ) {
    super(message)
  }
}

export const isNotAuthorized = (code: RpcErrorCode) => code === RpcErrorCode.InvalidToken

export const isInvalidResource = (code: RpcErrorCode) =>
  code === RpcErrorCode.InvalidResourceIdentifier

export const isOffLineResource = (code: RpcErrorCode) => code === RpcErrorCode.ResourceOffline

export const isParsingOfFinancials = (code: RpcErrorCode) =>
  code === RpcErrorCode.ParseGermanFinancialsFailed

export const isValidationFailed = (code: RpcErrorCode) => code === RpcErrorCode.ValidationFailed

import { RowType } from '@gain/utils/table'
import { useCallback } from 'react'

import { ColumnFieldConfig, VirtualSort, VirtualSortDirection } from './virtual-table-model'

export type UseSortDirectionCallbackFn<Row extends RowType> = <Field extends keyof Row>(
  config: ColumnFieldConfig<Row, Field>
) => VirtualSortDirection

export function useSortDirectionCallback<Row extends RowType>(
  sort: VirtualSort<Row>[]
): UseSortDirectionCallbackFn<Row> {
  return useCallback(
    <Field extends keyof Row>(config: ColumnFieldConfig<Row, Field>) => {
      const sortField =
        config.sortFields && config.sortFields.length > 0 ? config.sortFields[0] : config.field
      if (sortField === (sort.length > 0 && sort[0].field)) {
        return sort[0].direction
      } else {
        return 'desc'
      }
    },
    [sort]
  )
}

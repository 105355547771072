import {
  TableValueDistributionConfig,
  useValueDistributionRows,
  ValueDistributionRow,
} from '@app/common/table-value-distribution'
import { AdvisorDealListItem } from '@gain/rpc/app-model'
import { AdvisoryActivity, DealAdvisorAdvised } from '@gain/rpc/shared-model'
import { chartColorSetGrey } from 'apps/app/src/common/chart/chart-colors'
import { useMemo } from 'react'

import {
  getAdvisorAdvisedLabel,
  getAdvisoryActivityLabel,
  getAdvisoryActivityValue,
} from './advisor-utils'

export interface AdvisedOnRow {
  dealId: number
  advised: DealAdvisorAdvised
  advisedOn: AdvisoryActivity
}

function createDealAdvisorActivityConfig(): TableValueDistributionConfig<AdvisedOnRow> {
  return {
    getGroupBy: (value: string) => value.split('-').shift(),
    formatGroup: (group: string) => getAdvisoryActivityLabel(group as AdvisoryActivity),
    getValue: (dealAdvisor: AdvisedOnRow) => `${dealAdvisor.advisedOn}-${dealAdvisor.advised}`,
    formatValue: (value: string) =>
      getAdvisorAdvisedLabel(value.split('-').pop() as DealAdvisorAdvised) ?? '',
    colorSet: chartColorSetGrey,
  }
}

/**
 * For deal advisors activity we need to "duplicate" deals so each advised on is correctly added
 */
export function useDealAdvisorDistributionRows(
  items: AdvisorDealListItem[]
): ValueDistributionRow[] {
  const rows = useMemo(
    () =>
      items.reduce((advisedOnRow, deal) => {
        for (const advisedOn of deal.advisedOn) {
          advisedOnRow.push({
            dealId: deal.id,
            advisedOn: getAdvisoryActivityValue(advisedOn),
            advised: deal.advised,
          })
        }

        return advisedOnRow
      }, [] as AdvisedOnRow[]),
    [items]
  )

  return useValueDistributionRows(rows, createDealAdvisorActivityConfig())
}

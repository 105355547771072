import type { Rating, RatingGroup } from '../rating-model'
import { RatingEsgKey, ratingEsgOptions } from './rating-esg'

export const ratingEsgOverall: Rating<RatingEsgKey> = {
  label: 'Overall ESG risk',
  key: 'ratingOverall',
  options: ratingEsgOptions,
  explainer: 'Weighted ESG risk assessment consisting of environmental and social risk',
}

export const ratingEsgSocial: Rating<RatingEsgKey> = {
  label: 'Social risk',
  key: 'ratingSocial',
  options: ratingEsgOptions,
  explainer:
    'Research team assessment of the underlying industry’s social risks (labour management, supply chain standards, product safety and quality, health and safety, human capital development, privacy and data security, etc.)',
}

export const ratingEsgEnvironmental: Rating<RatingEsgKey> = {
  label: 'Environmental risk',
  key: 'ratingEnvironmental',
  options: ratingEsgOptions,
  explainer:
    'Research team assessment of the underlying industry’s environmental risks (carbon emissions, water stress, raw material sourcing, energy consumption, packaging material, etc.)',
}

export const ratingEsgMap: Record<RatingEsgKey, Rating<RatingEsgKey>> = {
  ratingSocial: ratingEsgSocial,
  ratingEnvironmental: ratingEsgEnvironmental,
  ratingOverall: ratingEsgOverall,
}

export const ratingEsgList: Rating<RatingEsgKey>[] = [
  ratingEsgOverall,
  ratingEsgEnvironmental,
  ratingEsgSocial,
]

export const ratingEsgGroup: RatingGroup<RatingEsgKey> = {
  ratings: ratingEsgList,
}

import { useIndustry, useRelatedAssets } from '@gain/api/app/hooks'
import { AssetListItem, ListRelatedAssetsSource } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'

import { ListViewAsset } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import { useSimilarCompaniesDefaultSort } from '../../features/similar-companies/similar-companies-card'
import ListPageLayout from '../../layout/list-page-layout'
import { useIndustryPageParams } from '../utils'

export default function IndustrySimilarCompanies() {
  const isXs = useIsXs()
  const { id, preview } = useIndustryPageParams()
  const sort = useSimilarCompaniesDefaultSort()

  useTrackPageView('industry', { id, tab: 'similar_assets', requireTab: true })

  const { data: industry, loading } = useIndustry({
    id,
    preview,
  })

  const swrRelatedAssets = useRelatedAssets({
    source: ListRelatedAssetsSource.Industry,
    id,
    limit: 100,
  })

  if (!industry || loading) {
    return null
  }

  if (!swrRelatedAssets.data || swrRelatedAssets.loading) {
    return null
  }

  const title = `${industry.generalInfo?.title || ''} - Similar companies`
  const assetIds = swrRelatedAssets.data.items.map((item) => item.id)
  const defaultFilter = listFilters<AssetListItem>(listFilter('id', '=', assetIds))

  return (
    <ListPageLayout title={title}>
      <ListViewAsset
        defaultFilter={defaultFilter}
        defaultSort={sort}
        disableFilters={isXs}
        inline={isXs}
      />
    </ListPageLayout>
  )
}

import { useUserProfileContext } from '@gain/modules/user'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'

import BlockHotjar from '../../features/hotjar'

function useGreeting() {
  return useMemo(() => {
    const date = new Date()
    const hours = date.getHours()

    if (hours >= 5 && hours <= 11) {
      return 'Good morning'
    } else if (hours >= 12 && hours <= 17) {
      return 'Good afternoon'
    } else if (hours >= 18 && hours <= 21) {
      return 'Good evening'
    } else {
      return 'Good night'
    }
  }, [])
}

export default function HomePageWelcome() {
  const userProfile = useUserProfileContext()
  const greeting = useGreeting()

  return (
    <Typography variant={'h2'}>
      {greeting}, <BlockHotjar>{userProfile.firstName}</BlockHotjar>
    </Typography>
  )
}

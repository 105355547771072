import Box from '@mui/material/Box'

import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import SearchResults from '../../features/search/search-results/search-results-container'

export default function SearchPage() {
  useTrackPageView('search')

  return (
    <Box sx={{ minHeight: '100%' }}>
      <SearchResults />
    </Box>
  )
}

import { AssetListItem } from '@gain/rpc/app-model'
import { useResizeObserver } from '@gain/utils/dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { sum } from 'lodash'
import { RefObject, useEffect, useState } from 'react'

import { AssetFilterField } from '../../asset/asset-filter-bar'
import { FilterModel } from '../../filter/filter-bar'

const EXTRA_FILTERS_WIDTH = 60
const UPDATES_BADGE_WIDTH_LG_UP = 70
const UPDATES_BADGE_WIDTH_MD_DOWN = 34

export type OnChangeValueLabelType = (
  groupIndex: number,
  valueIndex: number
) => (label: string, value: string | null) => void

let canvas: HTMLCanvasElement | null = null

/**
 * getTextWidth returns the text width using canvas' measureText().
 */
function getTextWidth(text: string, font = '14px Mullish,sans-serif') {
  // if set, use cached canvas for better performance
  if (!canvas) {
    canvas = document.createElement('canvas')
  }

  const context = canvas.getContext('2d')
  if (!context) {
    return 0
  }

  context.font = font
  return context.measureText(text).width
}

/**
 * useCalculateMaxWidth returns the maximum width available before
 * showing the "+X filters" tooltip.
 *
 * This is not perfect, but should be good enough for most use cases.
 */
function useCalculateMaxWidth(ref: RefObject<HTMLAnchorElement | null>, hasUpdates: boolean) {
  const theme = useTheme()
  const [maxWidth, setMaxWidth] = useState(0)
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const handleResize = (target: ResizeObserverEntry) => {
    let availableWidth = target.contentRect.width

    // If an updates badge is shown account for that as well
    if (hasUpdates) {
      availableWidth -= isLgUp ? UPDATES_BADGE_WIDTH_LG_UP : UPDATES_BADGE_WIDTH_MD_DOWN
    }

    setMaxWidth(availableWidth)
  }

  useResizeObserver(ref, handleResize)

  return maxWidth
}

/**
 * useCalculateNumberOfFilters determines the number of filters to be
 * displayed and the number of filters to be hidden with the tooltip
 * "+X filters".
 *
 * This function addresses several complexities:
 *
 * - Filters overflowing ('...') should be hidden except for the
 *   first filter.
 * - Certain values, such as tag and investor names, are loaded
 *   asynchronously.
 * - Responsiveness.
 * - An optional updates badge and the "+X filters" tooltip reduce the
 *   available width.
 */
export default function useCalculateNumberOfFilters(
  ref: RefObject<HTMLAnchorElement | null>,
  filterModel: FilterModel<AssetListItem, AssetFilterField> = [],
  hasUpdates = false
) {
  const maxWidth = useCalculateMaxWidth(ref, hasUpdates)

  // This number[][] represents number[groupIndex][valueIndex] = widthInPixels
  const [values, setValues] = useState<number[][]>([])

  // As long as one or more values has no width we're still waiting for async
  // values to load.
  const isLoading = values.some((value) => value.some((length) => length === 0))

  // Update values if external model changes
  useEffect(() => {
    setValues(filterModel.map((group) => group.value.map(() => 0)))
  }, [filterModel])

  // Values can be loaded asynchronously (such as investor name and tag names), so provide
  // a callback to update these values.
  const handleChangeValueLabel: OnChangeValueLabelType =
    (groupIndex, valueIndex) => (label, value) => {
      setValues((prev) => {
        if (value !== null && value !== '' && prev[groupIndex]?.[valueIndex] === 0) {
          const result = [...prev]
          result[groupIndex][valueIndex] = getTextWidth(
            `${valueIndex > 0 ? ' OR ' : ''}${label}: ${value}, `
          )
          return result
        }
        return prev
      })
    }

  // Determine number of filters to show
  let showNrOfFilters = 1
  if (!isLoading) {
    let totalChars = 0
    for (let i = 0; i < values.length; i++) {
      let max = maxWidth

      // Unless this filter is the last one, account for the space required by "+X filters"
      if (i !== values.length - 1) {
        max -= EXTRA_FILTERS_WIDTH
      }

      totalChars += sum(values[i])

      // When exceeding max characters stop
      if (totalChars > max) {
        break
      }

      showNrOfFilters = i + 1
    }
  }

  return {
    isLoading,
    onChangeValueLabel: handleChangeValueLabel,
    extraFilters: values.length - showNrOfFilters,
    showNrOfFilters,
  }
}

import { TooltipProps } from '@mui/material/Tooltip'
import React from 'react'

import ChartTooltip from '../../../../common/chart/chart-tooltip'
import BrokerRecommendationTooltipTitle from './broker-recommendation-tooltip-title'
import { BrokerRecommendation } from './market-chart-types'

const LEFT_TOOLTIP_VERTICAL_OFFSET = -70
const TOP_TOOLTIP_VERTICAL_OFFSET = -118

type BrokerRecommendationTooltipProps = Omit<
  TooltipProps,
  'title' | 'children' | 'followCursor'
> & {
  recommendation?: BrokerRecommendation
}

/**
 * BrokerRecommendationTooltip is a custom tooltip for the broker recommendations.
 */
function BrokerRecommendationTooltip({
  open,
  recommendation,
  ...props
}: BrokerRecommendationTooltipProps) {
  const totalRatings = recommendation?.total ?? 0

  // Determine position of the tooltip. If there are ratings, show a tooltip to
  // the left, vertically centered. Otherwise, to the top, horizontally centered.
  let placement: 'left' | 'top' = 'left'
  let top = LEFT_TOOLTIP_VERTICAL_OFFSET
  if (totalRatings === 0) {
    placement = 'top'
    top = TOP_TOOLTIP_VERTICAL_OFFSET
  }

  return (
    <ChartTooltip
      open={open}
      placement={placement}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // MUIs default offset is way too far from the actual target element
              offset: [0, -8],
            },
          },
        ],
      }}
      title={<BrokerRecommendationTooltipTitle recommendation={recommendation} />}
      disableInteractive
      {...props}>
      {/* The market data chart is already wrapped in a tooltip. This second one
       is only supposed to cover the lower broker recommendation part of the chart.
       As this is a part of a canvas, it doesn't really wrap a component, as is
       needed for a MUI component. Therefore, we wrap an empty div that is properly
       positioned. */}
      <div
        style={{
          position: 'relative',
          top,
          left: recommendation?.startX ?? 0,
          width: (recommendation?.endX ?? 0) - (recommendation?.startX ?? 0),
        }}
      />
    </ChartTooltip>
  )
}

// Prevent unnecessary re-renders by making this a pure component. Re-rendering
// this tooltip unnecessarily causes a small jump of the tooltip's arrow while
// the rest of the tooltip is stationary.
export default React.memo(BrokerRecommendationTooltip)

import { AdvisorDealListItem } from '@gain/rpc/app-model'
import { formatAdvisoryActivitiesLabels, getAdvisorAdvisedLabel } from '@gain/utils/advisor'

import { ColumnConfig } from '../../../../common/virtual-table'
import { dealTableColumns } from '../deal-table/deal-table-columns'

// Clone the items, so we don't modify the original dealTableColumns
const clonedDealListItemColumns = [...(dealTableColumns as ColumnConfig<AdvisorDealListItem>[])]

// Add the new columns
clonedDealListItemColumns.splice(
  3,
  0,
  {
    field: 'advised',
    headerName: 'Party advised',
    width: 148,
    valueFormatter: ({ value }) => getAdvisorAdvisedLabel(value),
  },
  {
    field: 'advisedOn',
    headerName: 'Advisory activity',
    width: 200,
    valueFormatter: ({ value }) => formatAdvisoryActivitiesLabels(value),
  }
)

// Return the final columns
export const dealAdvisorTableColumns = clonedDealListItemColumns

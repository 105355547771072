import { ChevronRightIcon } from '@gain/components/icons'
import { Components, Theme } from '@mui/material/styles'
import React from 'react'

export function MuiBreadcrumbs(theme: Theme): Components['MuiBreadcrumbs'] {
  return {
    defaultProps: {
      separator: <ChevronRightIcon />,
    },
    styleOverrides: {
      root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      separator: {
        marginLeft: 0,
        marginRight: 0,
        '& > *': {
          width: 16,
          height: 16,
        },
      },
    },
  }
}

import { DealReason } from '@gain/rpc/shared-model'

export const dealReasons = [
  { label: 'Divestiture', value: DealReason.Divestiture },
  { label: 'Platform', value: DealReason.Platform },
  { label: 'Strategic exit', value: DealReason.StrategicExit },
  { label: 'Public-to-private', value: DealReason.PublicToPrivate },
  { label: 'IPO', value: DealReason.IPO },
  { label: 'VC-Round', value: DealReason.VcRound },
]

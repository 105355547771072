import { ElementType, PropsWithChildren } from 'react'

export interface BlockHotjarProps {
  component?: ElementType
}

export default function BlockHotjar({
  component: Component = 'span',
  children,
}: PropsWithChildren<BlockHotjarProps>) {
  return <Component data-hj-suppress>{children}</Component>
}

import { useMemo } from 'react'

export const useColumnOffsets = (columnWidths: number[]) =>
  useMemo(() => {
    const offsets = new Array<number>()
    for (let i = 0; i < columnWidths.length; i++) {
      offsets[i] = i > 0 ? offsets[i - 1] + columnWidths[i - 1] : 0
    }
    return offsets
  }, [columnWidths])

import Popover, { popoverClasses, PopoverProps } from '@mui/material/Popover'
import { styled } from '@mui/material/styles'

import { useAnchorOffset } from '../../../../common/dropdown-menu'

const StyledPopover = styled(
  ({ className, ...props }: PopoverProps) => (
    <Popover
      {...props}
      classes={{
        root: className,
      }}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'offsetTop',
  }
)<{ offsetTop: number }>(({ theme, offsetTop }) => ({
  [`& .${popoverClasses.paper}`]: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    boxShadow: theme.palette.shadow.level1a,
    padding: theme.spacing(0.75, 2, 0, 2),
    minWidth: 295,
    maxWidth: 364,
    maxHeight: '70vh',
    ...(offsetTop > -1 && {
      maxHeight: `calc(100vh - ${offsetTop}px - 16px)`,
    }),
  },
}))

export type FilterPopoverProps = PopoverProps

export default function FilterPopover({ children, ...props }: FilterPopoverProps) {
  const [anchorBottom] = useAnchorOffset(props.anchorEl)

  return (
    <StyledPopover
      {...props}
      anchorOrigin={props.anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
      offsetTop={anchorBottom ?? -1}
      disableRestoreFocus>
      {children}
    </StyledPopover>
  )
}

import { compareNumberAsc } from '@gain/utils/common'
import { CountryCode, getCountry } from '@gain/utils/countries'
import { PropsOfType } from '@gain/utils/typescript'
import { useCallback, useLayoutEffect, useState } from 'react'

import { calculateFteRangeAvg } from '../../common/fte'
import { VirtualSort } from '../../common/virtual-table'
import { BenchmarkingItem, BenchmarkingItemType, compareBenchmarkRank } from './utils'

/**
 * Creates a sorting function for assets, prioritizing newly added assets.
 * In case of a target asset, it is always shown at the top first.
 *
 * The returned function sorts the assets such that assets with IDs in the
 * `addedAssetIds` array always appear at the top next. If both assets are newly
 * added, the newest asset is shown first. If neither asset is newly added,
 * the provided comparison function is used.
 */
function createSortFn(
  assets: BenchmarkingItem[],
  addedAssetIds: number[],
  direction: 'asc' | 'desc'
) {
  return (compare: (a: BenchmarkingItem, b: BenchmarkingItem) => number) => {
    return assets.slice().sort((a, b) => {
      // Target asset is always at the top.
      if (a.type === BenchmarkingItemType.Target) {
        return -1
      }

      if (b.type === BenchmarkingItemType.Target) {
        return 1
      }

      const addedAIndex = addedAssetIds.indexOf(a.id)
      const addedBIndex = addedAssetIds.indexOf(b.id)

      // Added assets are at the top next.
      if (addedAIndex > -1 && addedBIndex === -1) {
        return -1
      }
      if (addedAIndex === -1 && addedBIndex > -1) {
        return 1
      }

      // If both assets are newly added, we sort the newest first.
      if (addedAIndex > -1 && addedBIndex > -1) {
        return addedAIndex - addedBIndex
      }

      // If neither asset is newly added, use the provided comparison function.
      return direction === 'asc' ? compare(a, b) : compare(b, a)
    })
  }
}

const numericFields = new Array<keyof BenchmarkingItem>(
  'ebitdaPctRevenue',

  'enterpriseValueEbitdaRatioLastFiscalYear',
  'enterpriseValueEbitRatioLastFiscalYear',
  'enterpriseValueRevenueRatioLastFiscalYear',

  'enterpriseValueEbitdaRatioCurrentFiscalYear',
  'enterpriseValueEbitRatioCurrentFiscalYear',
  'enterpriseValueRevenueRatioCurrentFiscalYear',

  'enterpriseValueEbitdaRatioNextFiscalYear',
  'enterpriseValueEbitRatioNextFiscalYear',
  'enterpriseValueRevenueRatioNextFiscalYear',

  'enterpriseValueEbitdaRatioLastTwelveMonths',
  'enterpriseValueEbitRatioLastTwelveMonths',
  'enterpriseValueRevenueRatioLastTwelveMonths',

  'enterpriseValueEbitdaRatioNextTwelveMonths',
  'enterpriseValueEbitRatioNextTwelveMonths',
  'enterpriseValueRevenueRatioNextTwelveMonths',

  'enterpriseValueEur',
  'revenueEur'
)

function isNumericField(
  field: keyof BenchmarkingItem
): field is PropsOfType<BenchmarkingItem, number | null> {
  return numericFields.includes(field)
}

/**
 * Sorts the assets based on the sort field and direction. Any new assets added
 * using the "Add company" dialog are always shown on top.
 */
function sortAssets(
  assets: BenchmarkingItem[],
  selectedAssetIds: number[],
  sort: VirtualSort<BenchmarkingItem>,
  addedAssetIds: number[]
): BenchmarkingItem[] {
  const sortFn = createSortFn(assets, addedAssetIds, sort.direction)

  const field = sort.field
  if (isNumericField(field)) {
    return sortFn((a, b) => {
      return compareNumberAsc(a[field], b[field])
    })
  }

  switch (sort.field) {
    case 'name':
      return sortFn((a, b) => a.name.localeCompare(b.name))
    case 'region':
      return sortFn((a, b) => {
        return getCountry(a.region as CountryCode).localeCompare(
          getCountry(b.region as CountryCode)
        )
      })
    case 'fte':
      return sortFn((a, b) => {
        let result = (a.fteRangeCategory || 0) - (b.fteRangeCategory || 0)
        if (result === 0) {
          let fteA = a.fte
          let fteB = b.fte

          // If fte is not defined but the fte range is, we use the midpoint of the range.
          if (!a.fte && typeof a.fteRange === 'string') {
            fteA = calculateFteRangeAvg(a.fteRange)
          }
          if (!b.fte && typeof b.fteRange === 'string') {
            fteB = calculateFteRangeAvg(b.fteRange)
          }

          result = (fteA || 0) - (fteB || 0)
        }
        return result
      })
    // Sorts by "Include" column for which no field is available, hence 'id' is used.
    case 'id':
      return sortFn((a, b) => {
        const selectedA = selectedAssetIds.includes(a.id)
        const selectedB = selectedAssetIds.includes(b.id)
        if (selectedA && !selectedB) {
          return 1
        }
        if (!selectedA && selectedB) {
          return -1
        }
        return a.name.localeCompare(b.name)
      })
    case 'relevanceRank': {
      return sortFn(compareBenchmarkRank)
    }
    default:
      return assets
  }
}

/**
 * The records are ordered as follows:
 *
 * 1. Assets added using "Add company" dialog always appear on top no matter what.
 * 2. Either:
 *    a. If there was a previous order, that is preserved.
 *    b. If there was no previous order (i.e. on mount), the selected
 *       assets are shown in the order they were selected.
 * 3. All other assets.
 */
export default function useSortedAssets(
  assets: BenchmarkingItem[],
  selectedAssetIds: number[],
  addedAssetIds: number[],
  initialSort: VirtualSort<BenchmarkingItem>[]
): [
  sortedAssets: BenchmarkingItem[],
  sort: VirtualSort<BenchmarkingItem>[],
  onSort: (nextSort: VirtualSort<BenchmarkingItem>[]) => void
] {
  const [ignoreAddedAssetIds, setIgnoreAddedAssetIds] = useState<number[]>([])
  const [sort, setSort] = useState<VirtualSort<BenchmarkingItem>[]>(initialSort)

  const [sortedAssets, setSortedAssets] = useState<BenchmarkingItem[]>([])

  // Callback used in the VirtualTable to sort the assets when user clicks the header
  const onSort = useCallback(
    (nextSort: VirtualSort<BenchmarkingItem>[]) => {
      // When the user explicitly sorts the table, any pre-existing added asset IDs
      // become part of the normal sorting algorithm.
      setIgnoreAddedAssetIds(addedAssetIds)

      // Update the sort state and the sorted assets
      const newlyAddedAssetIds = addedAssetIds.filter((id) => !ignoreAddedAssetIds.includes(id))
      setSortedAssets(sortAssets(assets, selectedAssetIds, nextSort[0], newlyAddedAssetIds))
      setSort(nextSort)
    },
    [assets, ignoreAddedAssetIds, addedAssetIds, selectedAssetIds]
  )

  // Update the sorted assets when the assets or selected asset IDs change.
  // useLayoutEffect is used to prevent flickering of an empty table when the
  // loading state changes from true to false, ensuring the table is rendered
  // with the sorted assets on the first render.
  useLayoutEffect(() => {
    const newlyAddedAssetIds = addedAssetIds.filter((id) => !ignoreAddedAssetIds.includes(id))
    setSortedAssets(sortAssets(assets, selectedAssetIds, sort[0], newlyAddedAssetIds))
  }, [
    addedAssetIds,
    assets,
    ignoreAddedAssetIds,
    onSort,
    selectedAssetIds,
    sort,
    sortedAssets.length,
  ])

  return [sortedAssets, sort, onSort]
}

import { useAssetListItem } from '@gain/api/app/hooks'
import { ChevronRightIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Alert, { alertClasses } from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

import TooltipAsset from '../../../../features/asset/asset-tooltip'
import { generateAssetPagePath } from '../../../utils'

const StyledLabel = styled(Typography)({
  whiteSpace: 'nowrap',
})

const StyledAlert = styled(Alert)(({ theme }) => ({
  cursor: 'default',
  display: 'flex',
  padding: theme.spacing('7px', 2),
  margin: theme.spacing(0, -2),
  [`& .${alertClasses.message}`]: {
    overflow: 'visible',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
  },
}))

const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  minWidth: 0,
})

interface SubsidiaryParentItemProps {
  parentId: number
}

export default function SubsidiaryParentAlert({ parentId }: SubsidiaryParentItemProps) {
  const parent = useAssetListItem(parentId)
  const history = useHistory()

  if (!parent.data) {
    return null
  }

  return (
    <StyledAlert
      icon={false}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
      severity={'error'}>
      <StyledLabel typography={'body2'}>This is a subsidiary</StyledLabel>
      <TooltipAsset
        asset={parent.data}
        id={parent.data.id}>
        <StyledButton
          color={'inherit'}
          endIcon={<ChevronRightIcon />}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            history.push(
              generateAssetPagePath({
                id: parentId,
                name: parent.data?.name,
              })
            )
          }}
          variant={'inline'}>
          <Typography
            variant={'inherit'}
            disableTooltip
            noWrap>
            Go to {parent.data.name}
          </Typography>
        </StyledButton>
      </TooltipAsset>
    </StyledAlert>
  )
}

import { IndustryIcon } from '@gain/components/icons'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import { formatIndustryScopeText } from 'libs/utils/countries/src/format-scope-and-countries'
import { generatePath } from 'react-router-dom'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
  MobileListSubheader,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { generateIndustryPagePath, INDUSTRY_LIST_PATH } from '../../../utils'
import { useHomeIndustryList } from '../../use-home-industry-list'

export default function IndustryList() {
  const { data: industries } = useHomeIndustryList(3)

  return (
    <List disablePadding>
      <MobileListSubheader
        href={generatePath(INDUSTRY_LIST_PATH)}
        sx={{ pt: 0.5 }}>
        Latest industries
      </MobileListSubheader>
      {industries.items.map((industry) => (
        <MobileListItem key={industry.id}>
          <MobileListItemButton
            as={Link}
            {...{
              href: generateIndustryPagePath({
                id: industry.id,
                title: industry.title,
                preview: false,
              }),
            }}>
            <MobileListItemIcon>
              <Logo
                defaultIcon={<IndustryIcon />}
                size={40}
                src={industry.smallImageFileUrl}
                fullSize
              />
            </MobileListItemIcon>
            <MobileListItemText
              primary={industry.title}
              secondary={formatIndustryScopeText(industry.scope)}
            />
          </MobileListItemButton>
        </MobileListItem>
      ))}
    </List>
  )
}

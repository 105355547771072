import { styled } from '@mui/material/styles'

import { panelTitleClasses } from './panel-title.component'

export interface PanelHeaderProps {
  align?: 'center' | 'start'
}

export const PanelHeader = styled('div')<PanelHeaderProps>(({ theme, align = 'center' }) => ({
  backgroundColor: theme.palette.background.default,
  borderTopLeftRadius: 'inherit',
  borderTopRightRadius: 'inherit',
  alignItems: 'center',
  height: 48,
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  [`& .${panelTitleClasses.root}`]: {
    margin: align === 'center' ? 'auto' : theme.spacing(0, 3),
  },
}))

import { Components, Theme } from '@mui/material/styles'
import { toggleButtonClasses } from '@mui/material/ToggleButton'

export const MuiToggleButtonGroup = (theme: Theme): Components['MuiToggleButtonGroup'] => ({
  styleOverrides: {
    root: {
      borderRadius: 8,
      border: `2px solid ${theme.palette.grey['100']}`,
      backgroundColor: theme.palette.grey['100'],
    },
    grouped: {
      [`& .${toggleButtonClasses.root}`]: {
        borderRadius: 8,
      },
    },
  },
  defaultProps: {},
})

import { Components, Theme } from '@mui/material/styles'

export const MuiDialogTitle = (theme: Theme): Components['MuiDialogTitle'] => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: theme.typography.h4.fontWeight,
    },
  },
})

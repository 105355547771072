import { AssetListItem } from '@gain/rpc/app-model'
import { assetUpdateType, useUpdateTypeColor } from '@gain/utils/asset'
import { formatDate } from '@gain/utils/date'
import { RenderCellParams } from '@gain/utils/table'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { parseJSON } from 'date-fns/parseJSON'
import React from 'react'

const Root = styled('div')({
  display: 'grid',
  gridTemplateColumns: '98px 1fr',
})

export function AssetListItemTableCellUpdatedDate({
  row,
}: RenderCellParams<AssetListItem, 'updatedAt'>) {
  const updateType = assetUpdateType(row)
  const color = useUpdateTypeColor(updateType, row)
  const date = row.financialsAt && parseJSON(row.financialsAt)

  return (
    <Root>
      {date ? formatDate(date) : '-'}
      <div>
        {updateType && (
          <Chip
            color={color}
            label={updateType}
            size={'small'}
          />
        )}
      </div>
    </Root>
  )
}

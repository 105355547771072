import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { useVisibleColumns } from '@gain/utils/table'
import { useRef } from 'react'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import Table from '../../../../common/table'
import {
  assetIndustryMarketColumns,
  columnVisibility,
} from './card-companies-related-industries.hooks'

export interface CardAssetShareholdersProps extends CardProps {
  segments: IndustryMarketSegmentListItem[]
}

export default function CardCompaniesRelatedIndustries({
  segments,
  ...cardProps
}: CardAssetShareholdersProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const visibleColumns = useVisibleColumns(tableRef, assetIndustryMarketColumns, columnVisibility)

  return (
    <Card {...cardProps}>
      <CardHeader title={'Featured industry reports'} />

      <Table
        ref={tableRef}
        columns={visibleColumns}
        rows={segments}
      />
    </Card>
  )
}

import { ChevronDownIcon } from '@gain/components/icons'
import { Components, Theme } from '@mui/material/styles'
import { tableSortLabelClasses } from '@mui/material/TableSortLabel'

export const MuiTableSortLabel = (theme: Theme): Components['MuiTableSortLabel'] => ({
  defaultProps: {
    IconComponent: ChevronDownIcon,
  },
  styleOverrides: {
    root: {
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
      lineHeight: theme.typography.subtitle2.lineHeight,
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
      [`&.${tableSortLabelClasses.active}`]: {
        color: theme.palette.text.primary,
        [`& > .${tableSortLabelClasses.icon}`]: {
          color: `${theme.palette.text.primary} !important`,
        },
      },
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: 2,
      marginLeft: 2,
    },
  },
})

import { alpha, styled } from '@mui/material/styles'
import React from 'react'

const StyledSpan = styled('span')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  borderRadius: 1,
}))

interface TextHighlightProps {
  text: string
  highlightText?: string
}

export default function TextHighlight({ text, highlightText }: TextHighlightProps) {
  if (!highlightText || highlightText.length < 2) {
    return text
  }

  const regex = new RegExp(`(${highlightText})`, 'gi')
  const parts = text.split(regex)

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? <StyledSpan key={index}>{part}</StyledSpan> : part
      )}
    </>
  )
}

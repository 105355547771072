import { useResizeObserver } from '@gain/utils/dom'
import debounce from '@mui/utils/debounce'
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'

interface Dimensions {
  width: number
  height: number
}

interface MeasureWidthProps {
  minHeight?: number
  fixedHeight?: number | string
  fixedWidth?: number
  children: (dimensions: Dimensions) => ReactNode
}

export default function MeasureDimensions({
  minHeight,
  fixedHeight,
  fixedWidth,
  children,
}: MeasureWidthProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState<Dimensions | null>(null)

  const handleResize = useCallback(() => {
    setDimensions(() => {
      if (!ref.current) {
        return null
      }

      const { width, height } = ref.current.getBoundingClientRect()

      return {
        width,
        height,
      }
    })
  }, [])

  const debouncedHandleResize = useMemo(() => debounce(handleResize, 60), [handleResize])

  useResizeObserver(ref, debouncedHandleResize)

  return (
    <div
      ref={ref}
      style={{
        height: fixedHeight || '100%',
        minHeight: minHeight || fixedHeight,
      }}>
      <div style={{ position: 'absolute', width: fixedWidth }}>
        {dimensions !== null &&
          dimensions.width > 0 &&
          dimensions.height > 0 &&
          children(dimensions)}
      </div>
    </div>
  )
}

import { LenderType } from './lib/rpc-shared-model'
import { Option } from './option'

export const LENDER_TYPE_OPTIONS: ReadonlyArray<Option<LenderType>> = [
  {
    label: 'Direct lender',
    value: LenderType.Direct,
  },
  {
    label: 'Bank',
    value: LenderType.Bank,
  },
]

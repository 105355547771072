import { useAssetList } from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListFilter, ListSort } from '@gain/rpc/list-model'
import { listSort } from '@gain/rpc/utils'

/**
 * This hook can be used to fetch assets for valuation cards. The reason for this hook
 * is to standardize the valuation size and sort order, with the ability to override
 * the defaults.
 */
export function useValuationAssets(
  filters: ListFilter<AssetListItem>[],
  limit = 150,
  initialSort: ListSort<AssetListItem>[] = [listSort('enterpriseValueEur', 'desc')]
) {
  return [
    initialSort,
    useAssetList({
      filter: filters,
      limit,
      sort: initialSort,
    }),
  ] as const
}

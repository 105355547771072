import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { HOME_PATH } from '../../../utils'
import { useSearchActive } from './use-search-active'

const StyledButton = styled(Button)({
  padding: 0,
})

export default function SearchCancelButton() {
  const history = useHistory()
  const active = useSearchActive()

  const handleCancel = useCallback(() => {
    if (active) {
      history.push(HOME_PATH)
    }
  }, [active, history])

  return (
    <StyledButton
      color={'primary'}
      onClick={handleCancel}>
      Cancel
    </StyledButton>
  )
}

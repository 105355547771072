import { Components, Theme } from '@mui/material/styles'
import { switchClasses } from '@mui/material/Switch'

export function MuiSwitch(theme: Theme): Components['MuiSwitch'] {
  return {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: 30,
        height: 15,
        padding: 0,
        [`& .${switchClasses.switchBase}`]: {
          [`& .${switchClasses.input}`]: {
            width: 60,
            left: -30,
            height: 30,
            top: -10,
          },
          padding: 0,
          margin: 1.36,
          [`&.${switchClasses.checked}`]: {
            transform: 'translateX(15px)',
            color: theme.palette.common.white,
            [`& + .${switchClasses.track}`]: {
              opacity: 1,
              border: 0,
            },
          },
          [`&.${switchClasses.disabled}`]: {
            [`& + .${switchClasses.track}`]: {
              opacity: 0.14,
            },
          },
        },
        [`& .${switchClasses.thumb}`]: {
          boxShadow: 'none',
          boxSizing: 'border-box',
          width: 12.27,
          height: 12.27,
          backgroundColor: theme.palette.common.white,
        },
        [`& .${switchClasses.track}`]: {
          borderRadius: 7.5,
          backgroundColor: theme.palette.grey[300],
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      },
    },
  }
}

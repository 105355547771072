import { styled } from '@mui/material/styles'

import { ListContainer } from '../list-container'

export const ListPageHeaderContainer = styled(ListContainer)<{ top?: number }>(
  ({ theme, top }) => ({
    flex: 'unset',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    flexDirection: 'row',
    marginBottom: 0,

    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.background.default,
      ...(top !== undefined && {
        position: 'sticky',
        top,
        zIndex: 3,
      }),
    },
  })
)

import { compareDesc } from 'date-fns/compareDesc'

function isDate(value: Date | number | null | undefined): value is Date | number {
  return typeof value === 'number' || value instanceof Date
}

export function compareDateDesc(
  a: Date | number | null | undefined,
  b: Date | number | null | undefined
) {
  if (!isDate(a) && isDate(b)) {
    return 1
  } else if (isDate(a) && !isDate(b)) {
    return -1
  } else if (isDate(a) && isDate(b)) {
    return compareDesc(a, b)
  }

  return 0
}

export function compareDateAsc(
  a: Date | number | null | undefined,
  b: Date | number | null | undefined
) {
  return compareDateDesc(b, a)
}

import { Date as ApiDate, InvestorFund } from '@gain/rpc/app-model'
import { compareDateDesc, compareNumberDesc } from '@gain/utils/common'

function formatYear(year: number) {
  return year < 100 ? year + 2000 : year
}

function toDate(date: ApiDate | null): Date | null {
  return date !== null ? new Date(formatYear(date.year), date.month || 0, date.day || 1) : null
}

function sortByFundSizeDesc(a: InvestorFund, b: InvestorFund) {
  return compareNumberDesc(a.fundSizeEur, b.fundSizeEur)
}

export function sortByVintageDateDesc(a: InvestorFund, b: InvestorFund) {
  const result = compareDateDesc(toDate(a.vintageDate), toDate(b.vintageDate))

  if (result === 0) {
    return sortByFundSizeDesc(a, b)
  }

  return result
}

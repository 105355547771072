import { AssetChart } from '@gain/rpc/app-model'
import { isDefined, isTruthy } from '@gain/utils/common'
import { formatNumber, formatPercentage } from '@gain/utils/number'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { scaleOrdinal } from 'd3'
import { useMemo } from 'react'

import Table, { ColumnConfig, tableClasses, TableProps } from '../../../../common/table'
import Bar from './bar.component'

const assetSegmentsTableClasses = generateUtilityClasses('AssetSegmentsTable', [
  'negativeValue',
  'overflowHeader',
])

const StyledOverflowHeader = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  textAlign: 'inherit',
}))

const StyledTable = styled(Table)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    [`& .${tableClasses.table}`]: {
      tableLayout: 'auto',
    },
  },
  [`& .${tableClasses.thead} .${tableClasses.th}.${assetSegmentsTableClasses.overflowHeader}`]: {
    zIndex: 3,
    overflow: 'visible',
  },
  [`& .${assetSegmentsTableClasses.negativeValue}`]: {
    color: theme.palette.error.main,
  },
  [`& .${tableClasses.tbody} .${tableClasses.tr}:last-of-type .${tableClasses.td}`]: {
    ...theme.typography.subtitle2,
  },
})) as typeof Table

interface AssetSegmentsTableRow {
  title: string
  fromValue: number | null
  fromPercentageOfMaxValue: number | null
  fromPercentageOfTotal: number | null
  toValue: number | null
  toPercentageOfMaxValue: number | null
  toPercentageOfTotal: number | null
  cagr: number | null
  color: string
}

function useAssetSegmentsTableRows(chart: AssetChart) {
  const theme = useTheme()
  return useMemo(() => {
    const totalFrom = chart.items.reduce((total, current) => total + (current.shareFrom || 0), 0)
    const totalTo = chart.items.reduce((total, current) => total + (current.shareTo || 0), 0)
    const totalCagr = cagr(totalFrom, totalTo, chart.periodFrom, chart.periodTo)
    const maxFrom = Math.max(...chart.items.map((item) => item.shareFrom || 0))
    const maxTo = Math.max(...chart.items.map((item) => item.shareTo || 0))
    const colorFn = scaleOrdinal(theme.palette.chart)

    return chart.items
      .map(
        (item) =>
          ({
            title: item.title,
            fromValue: item.shareFrom,
            fromPercentageOfMaxValue:
              item.shareFrom && item.shareFrom > 0 ? (item.shareFrom / maxFrom) * 100 : null,
            fromPercentageOfTotal: item.shareFrom ? (item.shareFrom / totalFrom) * 100 : null,
            toValue: item.shareTo,
            toPercentageOfMaxValue:
              item.shareTo && item.shareTo > 0 ? (item.shareTo / maxTo) * 100 : null,
            toPercentageOfTotal: item.shareTo ? (item.shareTo / totalTo) * 100 : null,
            cagr: item.cagr,
            color: colorFn(item.title),
          } as AssetSegmentsTableRow)
      )
      .concat({
        title: 'Total',
        fromValue: totalFrom,
        fromPercentageOfMaxValue: null,
        fromPercentageOfTotal: 100,
        toValue: totalTo,
        toPercentageOfMaxValue: null,
        toPercentageOfTotal: 100,
        cagr: totalCagr,
        color: '',
      })
  }, [chart, theme])
}

export type ValueFormat = 'number' | 'percentage'

interface FormatValueParams {
  amount: number | null
  percentage: number | null
  format: ValueFormat
  round: number
}

function formatValue({ amount, percentage, format, round }: FormatValueParams) {
  if (format === 'number') {
    return formatNumber(amount, { round })
  }

  return formatPercentage(percentage, { round })
}

function cagr(
  beginValue: number | null,
  endValue: number | null,
  periodFrom: number | null,
  periodTo: number | null
) {
  if (!beginValue || !endValue || !isDefined(periodFrom) || !isDefined(periodTo)) {
    return null
  }

  const nrOfYears = periodTo - periodFrom

  return (Math.pow(endValue / beginValue, 1 / nrOfYears) - 1) * 100
}

function useAssetChartsTableColumns(
  chart: AssetChart,
  rows: AssetSegmentsTableRow[],
  valueFormat: ValueFormat
) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const round = useMemo(() => {
    if (valueFormat === 'percentage') {
      return 1
    }

    const hasRowsWithValuesBelow10 = rows.find((row) => {
      const from = row['fromValue']
      const to = row['toValue']

      return (
        (isDefined(from) && from > -10 && from < 10 && from !== 0) ||
        (isDefined(to) && to > -10 && to < 10 && to !== 0)
      )
    })
    return hasRowsWithValuesBelow10 ? 1 : 0
  }, [rows, valueFormat])

  const has = useMemo(() => {
    const result = {
      cagr: false,
      from: false,
      to: false,
    }

    for (const item of chart.items) {
      if (item.cagr !== null) {
        result.cagr = true
      }
      if (item.shareFrom !== null) {
        result.from = true
      }
      if (item.shareTo !== null) {
        result.to = true
      }
    }

    return result
  }, [chart])

  return useMemo(() => {
    return [
      {
        field: 'title',
        headerName: chart.by,
        width: isXs ? 'auto' : '30%',
      },
      has.from &&
        isXs && {
          field: 'fromValue',
          headerName: chart.periodFrom,
          width: 64,
          align: 'right',
          valueFormatter: ({ value, row, rowIndex }) =>
            formatValue({
              amount: value,
              percentage: row.fromPercentageOfTotal,
              format: valueFormat,
              round: rowIndex === rows.length - 1 && valueFormat === 'percentage' ? 0 : round,
            }),
        },
      has.from &&
        !isXs && {
          field: 'fromValue',
          renderHeader: () => (
            <StyledOverflowHeader>{`${chart.of} ${chart.periodFrom}`}</StyledOverflowHeader>
          ),
          headerWidth: '30%',
          headerClassName: assetSegmentsTableClasses.overflowHeader,
          width: '1%',
          align: 'right',
          headerAlign: 'left',
          valueFormatter: ({ value, row, rowIndex }) =>
            formatValue({
              amount: value,
              percentage: row.fromPercentageOfTotal,
              format: valueFormat,
              round: rowIndex === rows.length - 1 && valueFormat === 'percentage' ? 0 : round,
            }),
        },
      has.from &&
        !isXs && {
          field: 'fromPercentageOfMaxValue',
          align: 'left',
          renderCell: ({ value, row }) => (
            <Bar
              color={row.color}
              percentage={value}
            />
          ),
        },
      has.to &&
        isXs && {
          field: 'toValue',
          headerName: chart.periodTo,
          width: has.from ? 64 : 76,
          align: 'right',
          valueFormatter: ({ value, row, rowIndex }) =>
            formatValue({
              amount: value,
              percentage: row.toPercentageOfTotal,
              format: valueFormat,
              round: rowIndex === rows.length - 1 && valueFormat === 'percentage' ? 0 : round,
            }),
        },
      has.to &&
        !isXs && {
          field: 'toValue',
          renderHeader: () => (
            <StyledOverflowHeader>{`${chart.of} ${chart.periodTo}`}</StyledOverflowHeader>
          ),
          headerWidth: '30%',
          headerClassName: assetSegmentsTableClasses.overflowHeader,
          width: '1%',
          align: 'right',
          headerAlign: 'left',
          valueFormatter: ({ value, row, rowIndex }) =>
            formatValue({
              amount: value,
              percentage: row.toPercentageOfTotal,
              format: valueFormat,
              round: rowIndex === rows.length - 1 && valueFormat === 'percentage' ? 0 : round,
            }),
        },
      has.to &&
        !isXs && {
          field: 'toPercentageOfMaxValue',
          align: 'left',
          renderCell: ({ value, row }) => (
            <Bar
              color={row.color}
              percentage={value}
            />
          ),
        },
      has.cagr && {
        field: 'cagr',
        headerName: 'CAGR',
        width: isXs ? 76 : '10%',
        align: 'right',
        cellClassName: ({ value }) => value && value < 0 && assetSegmentsTableClasses.negativeValue,
        valueFormatter: ({ value }) => formatPercentage(value, { round: 1 }),
      },
    ].filter(isTruthy) as ColumnConfig<AssetSegmentsTableRow>[]
  }, [
    chart.by,
    chart.periodFrom,
    chart.periodTo,
    chart.of,
    isXs,
    has.from,
    has.to,
    has.cagr,
    valueFormat,
    rows.length,
    round,
  ])
}

export interface AssetChartsTableProps
  extends Omit<TableProps<AssetSegmentsTableRow>, 'columns' | 'rows'> {
  chart: AssetChart
  valueFormat: ValueFormat
}

export default function AssetChartsTable({
  chart,
  valueFormat,
  ...tableProps
}: AssetChartsTableProps) {
  const rows = useAssetSegmentsTableRows(chart)
  const columns = useAssetChartsTableColumns(chart, rows, valueFormat)

  return (
    <StyledTable
      columns={columns}
      rows={rows}
      {...tableProps}
    />
  )
}

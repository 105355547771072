import { ListItemKey } from '@gain/rpc/list-model'
import React, { Fragment } from 'react'

import { FilterConfigMap } from '../filter-config/filter-config-model'
import { FilterModel } from '../filter-model'
import FilterValueLabel from './filter-value-label'

export interface FilterModelLabelProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filterModel: FilterModel<Item, FilterField>
  filterMap: FilterConfigMap<Item, FilterField>
}

export default function FilterModelLabel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ filterModel, filterMap }: FilterModelLabelProps<Item, FilterField>) {
  return (
    <>
      {filterModel.map((group, groupIndex) => (
        <Fragment key={groupIndex}>
          {groupIndex > 0 && ', '}
          {group.value.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {itemIndex > 0 && <strong> OR </strong>}
              <FilterValueLabel
                filter={filterMap[item.filterId]}
                value={item.value}
              />
            </React.Fragment>
          ))}
        </Fragment>
      ))}
    </>
  )
}

import { Components, Theme } from '@mui/material/styles'

export const MuiAppBar = (theme: Theme): Components['MuiAppBar'] => ({
  defaultProps: {
    color: 'default',
    elevation: 0,
  },
  styleOverrides: {
    root: {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
    },
    colorDefault: {
      backgroundColor: theme.palette.common.white,
    },
  },
})

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { ReactElement } from 'react'

const StyledRoot = styled('div')(({ theme }) => ({
  minHeight: '20vh',
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    flex: '1 1 100%',
  },
  [theme.breakpoints.only('xs')]: {
    backgroundColor: 'initial',
  },
}))

const StyledContainer = styled('div')({
  maxWidth: 416,
  margin: '0 auto',
})

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

const StyledMessage = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

export interface EmptyParams {
  title?: string
  message?: string
  actions?: ReactElement
}

export default function Empty({
  title = 'No filter results',
  message = 'Adjust your filter settings for better results',
  actions,
}: EmptyParams) {
  return (
    <StyledRoot>
      <StyledContainer>
        <StyledTitle variant={'h3'}>{title}</StyledTitle>
        <StyledMessage color={'text.secondary'}>{message}</StyledMessage>
        {actions && <Box mt={2}>{actions}</Box>}
      </StyledContainer>
    </StyledRoot>
  )
}

import { ListItemKey } from '@gain/rpc/list-model'
import { useRef } from 'react'
import { useFormState } from 'react-final-form'

import { FilterModel } from '../filter-model'
import { useTrackFilterEvent } from '../use-track-filter-event'
import { useFilterConfigMap } from './filter-bar-context'
import { FilterFormValues } from './filter-form-value'

export interface FilterBarValueChangeSpyProps<
  Item extends object,
  FilterField extends ListItemKey<Item>
> {
  onChange: (value: FilterModel<Item, FilterField>) => void
}

export function FilterBarValueChangeSpy<
  Item extends object,
  FilterField extends ListItemKey<Item>
>({ onChange }: FilterBarValueChangeSpyProps<Item, FilterField>) {
  const previousFormState = useRef<FilterFormValues<Item, FilterField>>()
  const trackEvent = useTrackFilterEvent()
  const prevLabel = useRef<string>()
  const filterConfigMap = useFilterConfigMap<Item, FilterField>()

  useFormState<FilterFormValues<Item, FilterField>>({
    onChange: (formState) => {
      // The asset tags column can update the filter model from outside the filter bar, so check
      // equality to prevent updates which are quite slow.
      if (JSON.stringify(previousFormState.current) !== JSON.stringify(formState.values)) {
        previousFormState.current = formState.values

        // Hack to prevent errors for bad setState() during rendering on
        // parent components if they use state. Rather have the hack here
        // than in all places where state management is needed on change.
        setTimeout(() => {
          onChange(formState.values.groups ? formState.values.groups : [])
        })
      }

      const label = formState.values.groups
        .reduce((groupAcc, group) => {
          const groupLabel = group.value.reduce((itemAcc, item) => {
            const config = filterConfigMap[item.filterId]
            if (item.value !== null && config) {
              return itemAcc.concat(config.label)
            }

            return itemAcc
          }, new Array<string>())

          if (groupLabel.length === 1) {
            return groupAcc.concat(groupLabel[0])
          }

          if (groupLabel.length > 1) {
            return groupAcc.concat(`(${groupLabel.join(' or ')})`)
          }

          return groupAcc
        }, new Array<string>())
        .join(' and ')

      if (label !== prevLabel.current) {
        prevLabel.current = label
        trackEvent('Filters composition change', label, null, 500)
      }
    },
    subscription: {
      values: true,
    },
  })

  return null
}

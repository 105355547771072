/**
 * Calculates the average of a given FTE (Full-Time Equivalent) range.
 *
 * The function splits the FTE range string by the '-' character to get the minimum and maximum values.
 * It then returns the average of the two values.
 *
 * For example: '501-5,000' would return 2500.5
 */
export function calculateFteRangeAvg(fteRange: string): number {
  const parts = fteRange.split('-')
  const sum = parts.reduce((total, part) => {
    // fte range may contain , or + characters (e.g. 10,000+ or 501-5,000)
    // remove those before parsing to int
    const sanitizedPart = part.replace(/[,+]/g, '')
    return total + parseInt(sanitizedPart, 10)
  }, 0)

  return sum / parts.length
}

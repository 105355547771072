import { RowType } from '@gain/utils/table'
import { useMemo } from 'react'
import { VirtualItem } from 'react-virtual'

import { ColumnConfig } from './virtual-table-model'

export interface VirtualColumn<Row extends RowType> {
  config: ColumnConfig<Row>
  position: 'sticky' | 'absolute'
  index: number
}

export function useVirtualColumns<Row extends RowType>(
  items: VirtualItem[],
  columns: ColumnConfig<Row>[]
): Array<VirtualColumn<Row>> {
  return useMemo(() => {
    const visible = new Array<VirtualColumn<Row>>()

    for (let i = 0; i < columns.length; i++) {
      const virtualItem = items.find((item) => item.index === i)
      if (columns[i].sticky || virtualItem) {
        visible.push({
          config: columns[i],
          position: columns[i].sticky ? 'sticky' : 'absolute',
          index: i,
        })
      }
    }

    return visible
  }, [items, columns])
}

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { range } from 'lodash'

import {
  StyledNewsFeedItemArticleLink,
  StyledNewsFeedItemArticleTextContainer,
} from './news-feed-item-article'

export default function NewsFeedItemArticleSkeleton() {
  const chipRange = range(0, 3)

  return (
    <StyledNewsFeedItemArticleLink
      as={'div'}
      to={''}>
      <Skeleton
        height={56}
        sx={{ borderRadius: 2, mr: 3 }}
        variant={'rounded'}
        width={56}
      />
      <StyledNewsFeedItemArticleTextContainer>
        <Skeleton
          height={20}
          width={'80%'}
        />
        <Skeleton
          height={22}
          sx={{ mt: 0.25 }}
          width={'100%'}
        />
        <Box sx={{ mt: 1.5, display: 'flex', gap: 1 }}>
          {chipRange.map((item) => (
            <Skeleton
              key={item}
              height={24}
              sx={{ borderRadius: 4 }}
              variant={'rounded'}
              width={80}
            />
          ))}
        </Box>
      </StyledNewsFeedItemArticleTextContainer>
    </StyledNewsFeedItemArticleLink>
  )
}

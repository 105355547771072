import { AssetListItemRatings } from '@gain/utils/asset'
import {
  findRatingInvestmentCriteria,
  formatInvestmentCriteria,
  ratingInvestmentCriteriaList,
} from '@gain/utils/investment-criteria'
import { styled } from '@mui/material/styles'
import { ReactNode, useMemo } from 'react'

import Card, { CardContentEmpty, CardHeader, CardProps } from '../../../common/card/card'
import CardAssessmentSummaryTable, { RatingRow } from './card-assessment-summary-table'

function useRows(rating: Partial<AssetListItemRatings> | null, nrOfRows: number): RatingRow[] {
  return useMemo(() => {
    if (rating === null) {
      return []
    }

    return ratingInvestmentCriteriaList
      .filter((item) => rating[item.key] !== null)
      .map((item) => ({
        label: item.label,
        value: rating[item.key] ?? null,
        formattedValue: formatInvestmentCriteria(
          rating[item.key],
          findRatingInvestmentCriteria(item.key),
          { withSuffix: true }
        ),
      }))
      .slice(0, nrOfRows)
  }, [nrOfRows, rating])
}

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
}))

interface CardAssessmentSummaryProps extends CardProps {
  ratings: Partial<AssetListItemRatings> | null
  renderEmptyCard?: () => ReactNode
  nrOfRows?: number
}

export default function CardAssessmentSummary({
  ratings,
  nrOfRows = 6,
  renderEmptyCard,
  ...cardProps
}: CardAssessmentSummaryProps) {
  const rows = useRows(ratings, nrOfRows)
  return (
    <StyledCard {...cardProps}>
      <CardHeader title={'Assessment'} />
      {rows.length > 0 && <CardAssessmentSummaryTable rows={rows} />}
      {rows.length === 0 && (
        <CardContentEmpty message={'No ratings available'}>{renderEmptyCard?.()}</CardContentEmpty>
      )}
    </StyledCard>
  )
}

import SelectMenu from '../../select-menu/select-menu'
import { ChartGroupByConfig, ChartGroupValueType } from './chart-group'

interface ChartGroupBySelectProps<
  Data,
  ValueType extends ChartGroupValueType = ChartGroupValueType
> {
  options: ChartGroupByConfig<Data, ValueType>[]
  value: ChartGroupByConfig<Data, ValueType>
  onChange: (value: ChartGroupByConfig<Data, ValueType>) => void
}

export default function ChartGroupBySelect<
  Data,
  ValueType extends ChartGroupValueType = ChartGroupValueType
>({ options, value, onChange }: ChartGroupBySelectProps<Data, ValueType>) {
  return (
    <SelectMenu
      label={'Group by'}
      onChange={onChange}
      options={options.map((option) => ({
        label: option.label,
        icon: option.icon,
        showDividerAbove: option.showDividerAbove,
        value: option,
      }))}
      value={value}
    />
  )
}

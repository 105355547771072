export const chartColorSet = [
  '#748DFA',
  '#67CFBC',
  '#F5DD90',
  '#D573B4',
  '#9873D5',
  '#68D1FF',
  '#66A373',
  '#5D7B64',
  '#4F553F',
  '#8D8D8D',
]

export const chartColorSetGrey = [
  '#BFC1D9',
  '#A5A8CA',
  '#8B8FBB',
  '#7276AC',
  '#5B5F9A',
  '#4B4F81',
  '#3C3F67',
  '#2D2F4D',
  '#1E2033',
]

export const chartColorSetGreen = [
  '#E7F2ED',
  '#A5CCB6',
  '#89B89B',
  '#79A88A',
  '#6F987C',
  '#678B71',
  '#5D7B64',
  '#546B59',
  '#444E44',
]

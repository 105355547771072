import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

export const StyledNewsFeedItemHeader = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),

  ':not(:first-child)': {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(4),
  },
}))

export interface NewsFeedItemHeaderProps {
  title: string
}

export default function NewsFeedItemHeader({ title }: NewsFeedItemHeaderProps) {
  return (
    <StyledNewsFeedItemHeader>
      <Typography
        color={'textPrimary'}
        variant={'subtitle2'}>
        {title}
      </Typography>
    </StyledNewsFeedItemHeader>
  )
}

import { useUserCurrency } from '@gain/api/app/hooks'
import { Asset } from '@gain/rpc/app-model'
import { FinancialResultPeriodicityType } from '@gain/rpc/shared-model'
import {
  createEmptyFinancialResult,
  FinancialResult,
  useConvertFinancialResults,
} from '@gain/utils/financials'
import { range } from 'lodash'
import { useMemo } from 'react'

/**
 * Returns the asset financial results that should be displayed on the financial
 * summary card. Financial results that should not be displayed (too old, forecast)
 * are omitted from the result.
 */
export default function useSummaryFinancials(asset: Asset, maxFinancialResults: number) {
  const userCurrency = useUserCurrency()
  const financials = useConvertFinancialResults(
    asset.financialResults,
    asset.generalInfo?.currency,
    userCurrency.name
  )

  return useMemo(() => {
    // Find LTM financial results
    const ltm = financials.find(
      (result) =>
        !result.isForecast && result.periodicity === FinancialResultPeriodicityType.LastTwelveMonths
    )

    // Find NTM financial results
    const ntm = financials.find(
      (result) =>
        result.isForecast && result.periodicity === FinancialResultPeriodicityType.NextTwelveMonths
    )

    // Find first forecasted financial results that are reported annually
    const forecast = financials.find(
      (result) => result.isForecast && result.periodicity === FinancialResultPeriodicityType.Annual
    )

    // Find all reported financials
    const reported = financials.filter(
      (result) => !result.isForecast && result.periodicity === FinancialResultPeriodicityType.Annual
    )

    // Add missing years for reported financials (e.g. if we have 2021 and 2023,
    // this makes sure to include empty financials for 2022 so we don't have gaps
    // in the table columns)
    const withMissingYears = reported.reduce((acc, current) => {
      if (acc.length === 0) {
        return [current]
      }

      const missing = range(acc[acc.length - 1].year + 1, current.year - 1, 1).map((year) =>
        createEmptyFinancialResult(year)
      )

      return acc.concat(...missing, current)
    }, new Array<FinancialResult>())

    // Use LTM when available
    if (ltm) {
      withMissingYears.push(ltm)
    }

    // Use NTM when available but only when there are 2 columns or more
    if (ntm && maxFinancialResults > 1) {
      withMissingYears.push(ntm)
    }

    // If there is no LTM and no NTM fallback to a forecast when available
    // but only when there are 2 columns or more
    if (!ltm && !ntm && forecast && maxFinancialResults > 1) {
      withMissingYears.push(forecast)
    }

    // Pick the last maxFinancialResults financial results (these are the
    // ones that will be displayed in the table)
    return withMissingYears.slice(-maxFinancialResults)
  }, [financials, maxFinancialResults])
}

import { RpcMethodMap } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { useAppSwr } from './use-app-swr'
import { UseRpcParams, UseRpcResponse } from './use-rpc'

interface CreateOptions<InputParams, OutputParams> extends SWRConfiguration {
  paramTransformer?: (input: InputParams) => OutputParams
}

export function createUseAppSwr<
  Method extends keyof RpcMethodMap,
  OutputParams extends RpcMethodMap[Method]['params'],
  Response extends RpcMethodMap[Method]['result'],
  InputParams = UseRpcParams<OutputParams>
>(
  method: Method,
  { paramTransformer, ...defaultOptions }: CreateOptions<InputParams, OutputParams> = {}
) {
  return (params?: InputParams | null, options?: SWRConfiguration): UseRpcResponse<Response> => {
    const transformedParams = useMemo(() => {
      const realParams = typeof params === 'function' ? params() : params

      if (isDefined(realParams) && paramTransformer) {
        return paramTransformer(realParams)
      }

      return realParams
    }, [params])

    const mergedOptions = useMemo(
      () => ({
        ...defaultOptions,
        ...options,
      }),
      [options]
    )
    return useAppSwr<Method, OutputParams, Response>(method, transformedParams, mergedOptions)
  }
}

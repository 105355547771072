import { CompanyIcon } from '@gain/components/icons'

import ContentLink from '../../../common/content-link'
import { generateLegalEntityPagePath } from '../../../routes/utils'
import LegalEntityTooltip from '../legal-entity-tooltip'

export interface ContentLinkLegalEntityProps {
  id: number
  name: string
  region?: string | null
}

export default function ContentLinkLegalEntity({ id, name, region }: ContentLinkLegalEntityProps) {
  return (
    <LegalEntityTooltip id={id}>
      <ContentLink
        avatarProps={{
          children: <CompanyIcon />,
        }}
        href={generateLegalEntityPagePath({
          id,
          name,
        })}
        label={name}
        labelTypographyProps={{
          disableTooltip: true,
        }}
        region={region}
      />
    </LegalEntityTooltip>
  )
}

import { useConferenceEditionListItem } from '@gain/api/app/hooks'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { ConferenceEditionListItem } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'
import React, { PropsWithChildren, useMemo } from 'react'

import ConferenceEditionTooltipTitle from './conference-edition-tooltip-title'

export interface ConferenceTooltipProps extends Omit<TooltipProps, 'title' | 'id'> {
  id: number
  conferenceEdition?: ConferenceEditionListItem
}

export default function ConferenceEditionTooltip({
  id,
  conferenceEdition: conferenceEditionProp,
  children,

  variant = 'preview',
  ...props
}: PropsWithChildren<ConferenceTooltipProps>) {
  const [open, setOpen, setClose] = useDialogState()

  const swrConferenceEdition = useConferenceEditionListItem(
    conferenceEditionProp || !id ? null : id,
    {
      suspense: false,
    }
  )

  const conferenceEdition = useMemo(
    () => conferenceEditionProp || swrConferenceEdition.data,
    [conferenceEditionProp, swrConferenceEdition.data]
  )

  return (
    <Tooltip
      onClose={setClose}
      onOpen={setOpen}
      open={Boolean(open && conferenceEdition)}
      title={
        conferenceEdition ? (
          <ConferenceEditionTooltipTitle conferenceEdition={conferenceEdition} />
        ) : (
          ''
        )
      }
      variant={variant}
      disableInteractive
      disablePadding
      {...props}>
      {children}
    </Tooltip>
  )
}

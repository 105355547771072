import AccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'

interface CheckboxAccordionDetailsProps {
  inset?: boolean
}

export const CheckboxAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: (prop) => prop !== 'inset',
})<CheckboxAccordionDetailsProps>(({ inset }) => ({
  flexDirection: 'column',
  padding: 0,
  paddingLeft: inset ? 24 : 0,
}))

import { AssetChart } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useCallback, useMemo, useState } from 'react'

import Card, { CardHeader, CardProps, CardTabs } from '../../../../common/card/card'
import AssetChartsTable, { ValueFormat } from './asset-charts-table'

const StyledToggleButton = styled(ToggleButton)({
  width: 32,
})

export interface AssetChartsCardProps extends CardProps {
  charts: AssetChart[]
}

export default function AssetChartsCard({ charts, ...cardProps }: AssetChartsCardProps) {
  const [valueFormat, setValueFormat] = useState<ValueFormat>('number')
  const tabs = charts.map((chart, index) => ({
    label: `${chart.of} by ${chart.by.toLowerCase()}`,
    value: index,
  }))
  const [currentTab, setCurrentTab] = useState(tabs[0].value)
  const currentChart = useMemo(() => {
    return charts[currentTab]
  }, [currentTab, charts])

  const handleTabChange = useCallback((event, value) => setCurrentTab(value), [])

  return (
    <Card {...cardProps}>
      <CardHeader
        actions={
          <ToggleButtonGroup
            onChange={(_, value) => setValueFormat(value)}
            size={'small'}
            value={valueFormat}
            exclusive>
            <StyledToggleButton value={'number'}>#</StyledToggleButton>
            <StyledToggleButton value={'percentage'}>%</StyledToggleButton>
          </ToggleButtonGroup>
        }
        title={'Segments'}
      />

      <CardTabs
        onChange={handleTabChange}
        options={tabs}
        value={currentTab}
      />

      <AssetChartsTable
        chart={currentChart}
        sx={{ flex: 1 }}
        valueFormat={valueFormat}
      />
    </Card>
  )
}

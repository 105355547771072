import Typography from '@gain/components/typography'
import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

import Card, { CardContent, CardHeader, CardProps } from '../card'

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(1),

  '& p': {
    whiteSpace: 'pre-wrap',
  },
}))

export interface CardDescriptionProps extends CardProps {
  title: string
}

export default function CardDescription({
  title,
  children,
  ...cardProps
}: PropsWithChildren<CardDescriptionProps>) {
  return (
    <Card {...cardProps}>
      <CardHeader title={title} />
      <StyledCardContent>
        <Typography
          variant={'body2'}
          paragraph>
          {children}
        </Typography>
      </StyledCardContent>
    </Card>
  )
}

import { RowType } from '@gain/utils/table'
import Fade from '@mui/material/Fade'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import React, { ForwardedRef, forwardRef, RefObject, useImperativeHandle, useRef } from 'react'

import { TABLE_HEAD_CELL_HEIGHT } from '../../theme'
import Empty from '../empty'
import { TableEmptyProps } from '../table'
import { InnerVirtualTable } from './inner-virtual-table'
import { UseGetRowComponentPropsCallbackParams } from './use-get-row-component-props-callback'
import useVirtualScrollPositionApi from './use-virtual-scroll-position-api'
import { useVirtualTableApi } from './use-virtual-table-api'
import { VirtualTableBody } from './virtual-table-body'
import { VirtualTableBodyCell } from './virtual-table-body-cell'
import { VirtualTableBodyCellContent } from './virtual-table-body-cell-content'
import { VirtualTableBodyRow } from './virtual-table-body-row'
import { VirtualTableHead } from './virtual-table-head'
import { VirtualTableHeadCell } from './virtual-table-head-cell'
import { VirtualTableHeadCellContent } from './virtual-table-head-cell-content'
import { VirtualTableHeadRow } from './virtual-table-head-row'
import { VirtualTableLoader } from './virtual-table-loader'
import {
  ColumnConfig,
  RowClickEvent,
  SelectionChangeEvent,
  VirtualSort,
  VirtualSortHandler,
} from './virtual-table-model'

export type VirtualTableVariant = 'default' | 'inline'

export const virtualTableClasses = generateUtilityClasses('VirtualTable', [
  'root',
  'container',
  'inner',
  'head',
  'headCell',
  'checkboxCell',
  'checkboxHeadCell',
  'shadowCell',
  'variantDefault',
  'variantInline',
])

export const StyledVirtualTableContainer = styled('div', {
  shouldForwardProp: (prop) =>
    !['variant', 'disableXScroll', 'showEmptyTable'].includes(prop as string),
})<{ variant?: VirtualTableVariant; disableXScroll: boolean; showEmptyTable: boolean }>(
  ({ disableXScroll, showEmptyTable }) => ({
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    transform: 'translateZ(0)',
    borderRadius: 8,

    ...(disableXScroll && {
      overflowX: 'hidden',
    }),

    ...(showEmptyTable && {
      minHeight: 'calc(20vh + 50px)', // 20vh for empty table message + 50 for table header
    }),

    [`& .${virtualTableClasses.checkboxCell}, & .${virtualTableClasses.checkboxHeadCell}`]: {
      paddingLeft: 0,
      paddingRight: 0,
      '& + div': {
        paddingLeft: 0,
      },
    },
  })
)

const StyledRootContainer = styled('div')({
  position: 'relative',
  flex: 1,
  display: 'flex',
  minHeight: 0,
  minWidth: 0,
})

export type RowClickHandlerFn<Row extends RowType> = (
  data: RowClickEvent<Row>,
  event: React.MouseEvent
) => void

export interface VirtualTableClasses {
  table?: string
  tableBody?: string
}

export interface VirtualTableProps<Row extends RowType> {
  checkboxSelection?: boolean
  classes?: VirtualTableClasses
  className?: string
  columns: ColumnConfig<Row>[]
  hideTotalSize?: boolean
  loadingInitial?: boolean
  loadingMore?: boolean
  maxItemsSelected?: number
  onLoadMore?: () => void
  onRowClick?: RowClickHandlerFn<Row>
  onSelectionChange?: (event: SelectionChangeEvent) => void
  onSort?: VirtualSortHandler<Row>
  RowComponentProps?: UseGetRowComponentPropsCallbackParams<Row>
  rows: Row[]
  selectionModel?: number[]
  sort?: VirtualSort<Row>[]
  tableEmptyProps?: TableEmptyProps
  totalSize?: number
  variant?: VirtualTableVariant
  disablePaddingStart?: boolean
}

export interface VirtualTableRef {
  resetScroll: () => void
  rootContainerRef: RefObject<HTMLDivElement>
}

function VirtualTable<Row extends RowType>(
  props: VirtualTableProps<Row>,
  ref: ForwardedRef<VirtualTableRef>
) {
  const api = useVirtualTableApi<Row>(props)
  const virtualScrollPositionApi = useVirtualScrollPositionApi(api)
  const rootContainerRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(
    ref,
    () => ({
      resetScroll: virtualScrollPositionApi.resetScroll,
      rootContainerRef: rootContainerRef,
    }),
    [virtualScrollPositionApi.resetScroll, rootContainerRef]
  )

  // Show empty table if there are no rows and tableEmptyProps are provided
  const showEmptyTable =
    !props.loadingInitial &&
    !isEmpty(props.tableEmptyProps) &&
    api.virtualY.virtualItems.length === 0

  return (
    <StyledRootContainer
      ref={rootContainerRef}
      className={clsx(
        virtualTableClasses.root,
        props.className,
        props.variant === 'inline'
          ? virtualTableClasses.variantInline
          : virtualTableClasses.variantDefault
      )}>
      <Fade
        in={props.loadingInitial || props.loadingMore}
        mountOnEnter
        unmountOnExit>
        <VirtualTableLoader height={api.containerHeight || 0} />
      </Fade>

      <StyledVirtualTableContainer
        ref={api.containerRef}
        className={virtualTableClasses.container}
        disableXScroll={api.disableXScroll}
        onScroll={virtualScrollPositionApi.onScroll}
        showEmptyTable={showEmptyTable}
        variant={props.variant}>
        <InnerVirtualTable
          className={clsx(virtualTableClasses.inner, api.overrideClasses.table)}
          height={
            api.virtualY.totalSize + (props.variant !== 'inline' ? TABLE_HEAD_CELL_HEIGHT : 40)
          }
          width={api.virtualX.totalSize}>
          <VirtualTableHead
            ref={api.tableHeadRef}
            className={virtualTableClasses.head}
            variant={props.variant}
            width={api.virtualX.totalSize}>
            <VirtualTableHeadRow width={api.virtualX.totalSize}>
              {api.virtualColumns.map(({ config, index, position }) => (
                <VirtualTableHeadCell
                  key={index}
                  align={config.align}
                  className={clsx(virtualTableClasses.headCell, config.headerClassName)}
                  left={api.columnOffsets[index]}
                  position={position}
                  shadow={api.shadowColumnIndex === index}
                  size={props.variant === 'inline' ? 'small' : 'medium'}
                  width={api.columnWidths[index]}>
                  {config.renderHeader ? (
                    config.renderHeader({
                      field: config.field,
                      colDef: config,
                      api: api,
                    })
                  ) : config.sortable === false ? (
                    config.headerName
                  ) : (
                    <VirtualTableHeadCellContent
                      api={api}
                      colDef={config}
                      field={config.field}
                      variant={props.variant}
                    />
                  )}
                </VirtualTableHeadCell>
              ))}
            </VirtualTableHeadRow>
          </VirtualTableHead>

          {showEmptyTable ? (
            <Empty {...props.tableEmptyProps} />
          ) : (
            <VirtualTableBody
              ref={api.tableBodyRef}
              className={api.overrideClasses.tableBody}
              height={api.virtualY.totalSize}>
              {api.virtualY.virtualItems.map((row) => (
                <VirtualTableBodyRow
                  {...api.getRowComponentProps({ row: api.rows[row.index] })}
                  key={row.index}
                  onClick={(event) =>
                    api.onRowClick(
                      {
                        row: api.rows[row.index],
                        rowIndex: row.index,
                      },
                      event
                    )
                  }
                  selected={api.selectionModel.includes(row.index)}
                  top={row.start}
                  width={api.virtualX.totalSize}>
                  {api.virtualColumns.map(({ config, index, position }) => (
                    <VirtualTableBodyCell
                      key={index}
                      align={config.align}
                      className={config.cellClassName}
                      left={api.columnOffsets[index]}
                      position={position}
                      shadow={api.shadowColumnIndex === index}
                      size={props.variant === 'inline' ? 'small' : 'medium'}
                      width={api.columnWidths[index]}>
                      <VirtualTableBodyCellContent
                        column={config}
                        row={api.rows[row.index]}
                        rowIndex={row.index}
                        variant={props.variant}
                      />
                    </VirtualTableBodyCell>
                  ))}
                </VirtualTableBodyRow>
              ))}
            </VirtualTableBody>
          )}
        </InnerVirtualTable>
      </StyledVirtualTableContainer>
    </StyledRootContainer>
  )
}

export default forwardRef(VirtualTable) as <Row extends RowType>(
  props: VirtualTableProps<Row> & { ref?: ForwardedRef<VirtualTableRef | undefined> }
) => ReturnType<typeof VirtualTable>

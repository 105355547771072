export const valueToEnum = <EnumType, ValueType>(
  enm: { [s: string]: EnumType },
  value: ValueType
): EnumType | undefined => {
  const values = Object.values(enm) as unknown as ValueType[]
  if (values.includes(value)) {
    return value as unknown as EnumType
  }

  return undefined
}

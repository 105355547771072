import { useRpcClient } from '@gain/api/swr'
import { useStartSession } from '@gain/modules/auth'
import Debug from 'debug'
import { useMemo } from 'react'

import { useTrackEvent } from '../../features/google-analytics'

const debug = Debug('gain-pro:sso-callback')

export function useSSOApi() {
  const fetcher = useRpcClient()
  const trackEvent = useTrackEvent()
  const startSession = useStartSession()

  return useMemo(
    () => ({
      getCode: (url = window.location.href) => new URL(url).searchParams.get('code'),

      login: async (code: string | null): Promise<string | null> => {
        if (!code) {
          return null
        }

        try {
          // Perform server side login for SSO
          const token = await fetcher({
            method: 'account.loginSso',
            params: { workOsAccessToken: code },
          })

          // Perform client side login
          startSession()

          // Redirect user
          trackEvent('SSO login success', 'Auth')

          return token
        } catch (error) {
          debug('SSO login failed', error)
          trackEvent('SSO login failed', 'Auth')

          return null
        }
      },
    }),
    [fetcher, startSession, trackEvent]
  )
}

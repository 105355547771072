import { Components, Theme } from '@mui/material/styles'

export const MuiInputAdornment = (theme: Theme): Components['MuiInputAdornment'] => ({
  styleOverrides: {
    sizeSmall: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
})

import { Components } from '@mui/material/styles'

export const MuiPaper = (): Components['MuiPaper'] => ({
  styleOverrides: {
    rounded: {
      borderRadius: '8px',
    },
    outlined: {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
    },
  },
})

import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Typography, { TypographyProps } from '@mui/material/Typography'
import clsx from 'clsx'

export const panelTitleClasses = generateUtilityClasses('PanelTitle', ['root'])

export function PanelTitle({ variant = 'h4', className, ...props }: TypographyProps) {
  return (
    <Typography
      className={clsx(panelTitleClasses.root, className)}
      variant={variant}
      {...props}
    />
  )
}

/**
 * Copied over from https://github.com/GoogleCloudPlatform/stackdriver-errors-js
 * This version is extended a little bit and written in Typescript, what is added:
 * - Setting of error location (See ReportOptions)
 */

import StackTrace from 'stacktrace-js'

import {
  Context,
  InitialConfiguration,
  PayloadContext,
  ReportOptions,
  ServiceContext,
} from './cloud-error-reporter.interfaces'

/**
 * An Error handler that sends errors to the Google Cloud Error Reporting API.
 *
 * API docs: https://cloud.google.com/error-reporting/reference/rest/v1beta1/projects.events/report
 */
export default class CloudErrorReporter {
  private apiKey: string | undefined
  private projectId: string | undefined
  private disabled = true
  private reportUncaughtExceptions = false
  private reportUnhandledPromiseRejections = false
  private serviceContext: ServiceContext = { service: 'web' }
  private context: Context = {}

  public start(config: InitialConfiguration) {
    if (!config.key) {
      console.warn('[CloudErrorReporter] Cannot initialize: No API key provided.')

      return
    }

    if (!config.projectId) {
      throw new Error('Cannot initialize: No project ID provided.')
    }

    this.apiKey = config.key
    this.projectId = config.projectId
    this.context = config.context || {}
    this.serviceContext = { service: config.service || 'web' }
    if (config.version) {
      this.serviceContext.version = config.version
    }
    this.reportUncaughtExceptions = config.reportUncaughtExceptions !== false
    this.reportUnhandledPromiseRejections = config.reportUnhandledPromiseRejections === true
    this.disabled = !!config.disabled

    this.registerHandlers()
  }

  /**
   * Report an error to the Google Error Reporting API
   */
  public report(
    err: Error | unknown,
    options?: ReportOptions
  ): Promise<{ message: string } | null> {
    if (this.disabled) {
      return Promise.resolve(null)
    }

    if (!err) {
      return Promise.reject(new Error('no error to report'))
    }

    const payload: PayloadContext = {
      message: err as string,
      serviceContext: this.serviceContext,
      context: {
        ...options,
        httpRequest: {
          userAgent: window.navigator.userAgent,
          url: window.location.href,
        },
        ...this.context,
      },
    }

    let result: Error

    if (!(err instanceof Error)) {
      // If we have a reportLocation we don't need a parsed error
      if (options?.reportLocation) {
        payload.message = `${options.reportLocation.functionName}: ${payload.message}`
        return this.sendErrorPayload(payload)
      }

      // Transform the message in an error, use try/catch to make sure the stacktrace is populated.
      try {
        throw new Error(String(err))
      } catch (e) {
        result = e as Error
      }
    } else {
      result = err
    }

    return this.resolveError(result, 1).then((message) => {
      payload.message = message

      return this.sendErrorPayload(payload)
    })
  }

  /**
   * Set the user for the current context.
   *
   * @param user The unique identifier of the user (can be ID, email or
   * custom token) or undefined if not logged in
   */
  public setUser(user: string | null) {
    this.context.user = user
  }

  private registerHandlers() {
    const noop = (...events: never[]) => {
      // Do nothing
    }

    if (this.reportUncaughtExceptions) {
      const oldErrorHandler = window.onerror || noop

      window.onerror = (message, source, lineno, colno, error) => {
        if (error) {
          this.report(error).catch(noop as never)
        }

        oldErrorHandler(
          message as never,
          source as never,
          lineno as never,
          colno as never,
          error as never
        )
        return true
      }
    }

    if (this.reportUnhandledPromiseRejections) {
      const oldPromiseRejectionHandler = window.onunhandledrejection || noop

      window.onunhandledrejection = (promiseRejectionEvent) => {
        if (promiseRejectionEvent) {
          this.report(promiseRejectionEvent.reason).catch(noop as never)
        }

        oldPromiseRejectionHandler(promiseRejectionEvent as never)
        return true
      }
    }
  }

  private resolveError(err: Error, firstFrameIndex: number): Promise<string> {
    // This will use sourcemaps and normalize the stack frames
    return StackTrace.fromError(err).then(
      function (stack) {
        const lines = [err.toString()]

        // Reconstruct to a JS stackframe as expected by Error Reporting parsers.
        for (let s = firstFrameIndex; s < stack.length; s++) {
          // Cannot use stack[s].source as it is not populated from source maps.
          lines.push(
            [
              '    at ',
              // If a function name is not available '<anonymous>' will be used.
              stack[s].getFunctionName() || '<anonymous>',
              ' (',
              stack[s].getFileName(),
              ':',
              stack[s].getLineNumber(),
              ':',
              stack[s].getColumnNumber(),
              ')',
            ].join('')
          )
        }

        return lines.join('\n')
      },
      (reason) => {
        const error = err as { file?: string; line?: string; column?: string }

        // Failure to extract stacktrace
        return [
          'Error extracting stack trace: ',
          reason,
          '\n',
          err.toString(),
          '\n',
          '    (',
          error.file,
          ':',
          error.line,
          ':',
          error.column,
          ')',
        ].join('')
      }
    )
  }

  private sendErrorPayload(payload: PayloadContext): Promise<{ message: string }> {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.reportUrl, true)
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')

    return new Promise(function (resolve, reject) {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          const code = xhr.status
          if (code >= 200 && code < 300) {
            resolve({ message: payload.message })
          } else if (code === 429) {
            // HTTP 429 responses are returned by Stackdriver when API quota
            // is exceeded. We should not try to reject these as unhandled errors
            // or we may cause an infinite loop with 'reportUncaughtExceptions'.
            reject({
              message: 'quota or rate limiting error on stackdriver report',
              name: 'Http429FakeError',
            })
          } else {
            const condition = code ? code + ' http response' : 'network error'
            reject(new Error(condition + ' on stackdriver report'))
          }
        }
      }

      xhr.send(JSON.stringify(payload))
    })
  }

  private get reportUrl() {
    return `https://clouderrorreporting.googleapis.com/v1beta1/projects/${this.projectId}/events:report?key=${this.apiKey}`
  }
}

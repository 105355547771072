import { startOfNextMonth } from '@gain/utils/date'

import { UNLIMITED_EXPORTS } from './export-method-config'

export function exportsRemainingMessage(currentExports: number, maxExports: number): string {
  if (maxExports === UNLIMITED_EXPORTS) {
    return 'No download limit'
  }

  // Whatever the case, we don't want to show less than 0
  const remainingDownloads = Math.max(maxExports - currentExports, 0)

  let tooltipMsg = 'Limit reached'
  if (remainingDownloads > 0) {
    if (remainingDownloads === 1) {
      tooltipMsg = '1 download left'
    } else {
      tooltipMsg = `${remainingDownloads} downloads left`
    }
  }
  return `${tooltipMsg}, resets ${startOfNextMonth()}`
}

import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import { menuItemClasses } from '@mui/material/MenuItem'
import { alpha, Components, Theme } from '@mui/material/styles'

export const MuiMenuItem = (theme: Theme): Components['MuiMenuItem'] => ({
  defaultProps: {
    dense: true,
  },
  styleOverrides: {
    root: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      paddingTop: 2,
      paddingRight: 8,
      paddingBottom: 2,
      paddingLeft: 8,
      '&[data-focus="true"]': {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
      [`&.${menuItemClasses.selected}, &.${menuItemClasses.selected}:hover, &[data-focus="true"]`]:
        {
          color: theme.palette.primary.main,
          backgroundColor: alpha(theme.palette.primary.main, 0.16),
          [`& .${listItemIconClasses.root}`]: {
            color: theme.palette.primary.main,
          },
        },
      minHeight: 34,
      [`& .${listItemSecondaryActionClasses.root}`]: {
        display: 'contents',
        position: 'relative',
        top: 0,
        right: 0,
        transform: 'none',
        '&::before': {
          display: 'block',
          content: '""',
          paddingLeft: theme.spacing(3.5),
        },
      },
      '&[color="error"]': {
        color: theme.palette.error.main,
        '&:hover, &:focus': {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
        },
      },
      borderRadius: 4,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      transition: 'unset',
      [`&.${menuItemClasses.dense}`]: {
        minHeight: 34,
      },
    },
  },
})

import { formatNumberInternal } from './format-number-internal'

export interface FormatThousandsOptions {
  prefix?: string
  disableSuffix?: boolean
  emptyValue?: string
}

export function formatThousands(
  number: number | null,
  { prefix = '', disableSuffix = false, emptyValue = '-' }: FormatThousandsOptions = {}
) {
  if (typeof number !== 'number') {
    return emptyValue
  }

  if (number < 1_000) {
    return formatNumberInternal(number, {
      round: 0,
      suffix: '',
      prefix,
    })
  }

  if (number < 100_000) {
    const result = number / 1_000

    if (result % 1 > 0) {
      return formatNumberInternal(number / 1_000, {
        round: 1,
        suffix: disableSuffix ? '' : 'k',
        prefix,
      })
    }
  }

  if (number < 1_000_000) {
    return formatNumberInternal(number / 1_000, {
      round: 0,
      suffix: disableSuffix ? '' : 'k',
      prefix,
    })
  }

  return formatNumberInternal(number / 1_000_000, {
    round: 1,
    suffix: disableSuffix ? '' : 'm',
    prefix,
  })
}

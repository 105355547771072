import { DealStatus } from '@gain/rpc/shared-model'

export function getDealReasonColor(dealStatus: DealStatus | null) {
  switch (dealStatus) {
    case DealStatus.Aborted:
      return 'error'
    case DealStatus.Live:
      return 'warning'
    default:
      return undefined
  }
}

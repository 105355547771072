import { Components, Theme } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableRowClasses } from '@mui/material/TableRow'

export const MuiTableFooter = (theme: Theme): Components['MuiTableFooter'] => ({
  styleOverrides: {
    root: {
      [`& .${tableRowClasses.root}:first-child .${tableCellClasses.root}`]: {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
      },
      [`& .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]: {
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
})

import Typography from '@gain/components/typography'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import React, { ComponentType, ReactElement } from 'react'

import Logo from '../logo'
import MainContainer from '../main-container'

const StyledContainer = styled(MainContainer)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 352,
  padding: theme.spacing(3),
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const StyledMessage = styled(Typography)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey['100'],
  borderRadius: 8,
  marginTop: theme.spacing(2),
}))

const StyledLogo = styled(Logo)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}))

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.25),
}))

const StyledActionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  width: '100%',
}))

export interface ResourceNotAvailableProps {
  icon?: ComponentType | null
  avatarSrc?: string | null
  title: string
  subtitle?: string | null
  message?: string | ReactElement | null
  actions?: ReactElement | null
}

export default function ResourceNotAvailable({
  icon: Icon,
  avatarSrc,
  title,
  subtitle,
  message,
  actions,
}: ResourceNotAvailableProps) {
  return (
    <StyledContainer>
      <StyledPaper>
        {(Icon || avatarSrc) && (
          <StyledLogo
            defaultIcon={Icon ? <Icon /> : null}
            size={72}
            src={avatarSrc}
          />
        )}
        {title && (
          <Typography
            textAlign={'center'}
            variant={'h5'}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <StyledSubtitle
            color={'text.secondary'}
            textAlign={'center'}
            variant={'body2'}>
            {subtitle}
          </StyledSubtitle>
        )}
        {message && (
          <StyledMessage
            sx={{
              mt: title ? 2 : 0,
            }}
            textAlign={'center'}
            variant={'body2'}>
            {message}
          </StyledMessage>
        )}
        {actions && <StyledActionContainer>{actions}</StyledActionContainer>}
      </StyledPaper>
    </StyledContainer>
  )
}

import Typography from '@gain/components/typography'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { ReactNode } from 'react'

import Card, { CardContent, CardHeader, CardProps } from '../card'

export const cardIconListClasses = generateUtilityClasses('CardIconList', [
  'alignStart',
  'alignCenter',
  'item',
  'cardContent',
])

const StyledListItemIcon = styled('div')({
  width: 24,
  height: 24,
  fontSize: 24,
})
const StyledListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  padding: theme.spacing(0, 0),
}))

const StyledList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  gap: theme.spacing(2),
  [`&.${cardIconListClasses.alignStart} .${cardIconListClasses.item}`]: {
    alignItems: 'flex-start',
  },
  [`&.${cardIconListClasses.alignCenter} .${cardIconListClasses.item}`]: {
    alignItems: 'center',
  },
}))

interface CardIconListProps extends CardProps {
  title: string
  items: string[]
  icon: ReactNode
  iconPosition?: 'center' | 'top'
}

export default function CardIconList({
  title,
  items,
  icon,
  iconPosition = 'center',
  ...cardProps
}: CardIconListProps) {
  return (
    <Card {...cardProps}>
      <CardHeader title={title} />
      <CardContent className={cardIconListClasses.cardContent}>
        <StyledList
          className={clsx({
            [cardIconListClasses.alignStart]: iconPosition === 'top',
            [cardIconListClasses.alignCenter]: iconPosition === 'center',
          })}>
          {items.map((item, index) => (
            <StyledListItem
              key={index}
              className={cardIconListClasses.item}>
              <StyledListItemIcon>{icon}</StyledListItemIcon>
              <Typography variant={'body2'}>{item}</Typography>
            </StyledListItem>
          ))}
        </StyledList>
      </CardContent>
    </Card>
  )
}

import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import React, { useCallback, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import { ChartSizeTypeConfig } from '../../common/chart/chart-select'
import { CompanyBubbleEChart } from '../chart'
import {
  AxisConfig,
  findAxisConfigById,
  useAxisConfig,
} from '../chart/company-bubble-echart/axis/axis-config'
import { CompanyBubbleEChartProps } from '../chart/company-bubble-echart/company-bubble-echart'
import ChartLibrary from './chart-library'
import { ChartLibraryOption } from './chart-library'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 220px',
  width: '100%',
  gap: theme.spacing(2),
}))

const StyledCard = styled(Card)({
  width: '100%',
  minWidth: 0,
  paddingBottom: 0,
})

interface BenchmarkingChartProps {
  assets: AssetListItem[]
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorProfileStrategy[]
  onChangeSettings?: CompanyBubbleEChartProps['onChangeSettings']
  spotlightAssetIds?: number[]
}

export default function BenchmarkingChart({
  assets,
  industrySegments,
  investorStrategies,
  onChangeSettings,
  spotlightAssetIds,
}: BenchmarkingChartProps) {
  const axisConfig = useAxisConfig(assets)
  const [xAxisConfig, setXAxisConfig] = useState<AxisConfig | null>(
    findAxisConfigById('fteCagrPctTwoYears', axisConfig)
  )
  const [yAxisConfig, setYAxisConfig] = useState<AxisConfig | null>(
    findAxisConfigById('fteGrowthPctSixMonths', axisConfig)
  )

  const handleChange = useCallback(
    (option: ChartLibraryOption) => {
      setXAxisConfig(findAxisConfigById(option.x, axisConfig))
      setYAxisConfig(findAxisConfigById(option.y, axisConfig))
    },
    [axisConfig]
  )

  // Ensure that the axis configuration is always in sync with the selected axis
  // to enable/disable the correct graph in the chart library.
  const handleChangeSettings = useCallback(
    (xAxis: AxisConfig, yAxis: AxisConfig, sizeType: ChartSizeTypeConfig<AssetListItem>) => {
      setXAxisConfig(xAxis)
      setYAxisConfig(yAxis)

      onChangeSettings?.(xAxis, yAxis, sizeType)
    },
    [onChangeSettings]
  )

  // This is always a programmer error
  if (!xAxisConfig || !yAxisConfig) {
    throw new Error('Could not find axis configuration for x or y axis in Benchmarking')
  }

  return (
    <StyledRoot>
      <StyledCard>
        <CardHeader title={'Benchmarking'} />

        <CompanyBubbleEChart
          assets={assets}
          axisConfig={axisConfig}
          height={406}
          industrySegments={industrySegments}
          investorStrategies={investorStrategies}
          onChangeSettings={handleChangeSettings}
          spotlightAssetIds={spotlightAssetIds}
          xAxisId={xAxisConfig.id}
          yAxisId={yAxisConfig.id}
        />
      </StyledCard>

      <ChartLibrary
        axisConfig={axisConfig}
        onChange={handleChange}
        xAxisId={xAxisConfig.id}
        yAxisId={yAxisConfig.id}
      />
    </StyledRoot>
  )
}

import { Asset, InvestorListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { ExportListButton } from '../../../../features/export/export-button'
import { Shareholder } from './asset-shareholder-util'
import ListAssetShareholder from './list-asset-shareholder'
import TableAssetShareholderContainer from './table-asset-shareholder-container'

export interface CardAssetShareholdersProps extends CardProps {
  shareholders: Shareholder[]
  maxNrOfInitialItems?: number
  asset: Asset
}

export default function CardAssetShareholders({
  shareholders,
  maxNrOfInitialItems,
  asset,
  ...cardProps
}: CardAssetShareholdersProps) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Card {...cardProps}>
      <CardHeader
        actions={
          <ExportListButton
            color={'primary'}
            method={'data.exportInvestors'}
            params={{
              filename: `${asset.generalInfo?.name} - Investors.xlsx`,
              search: '',
              columns: [],
              sort: [],
              filter: [
                listFilter<InvestorListItem>(
                  'id',
                  '=',
                  shareholders.map((shareholder) => shareholder.investorId)
                ),
              ],
            }}
            tooltipMode={'always'}
            variant={'icon'}
          />
        }
        title={'Investors'}
      />
      {!isXs && (
        <TableAssetShareholderContainer
          maxNrOfInitialItems={maxNrOfInitialItems}
          shareholders={shareholders}
        />
      )}
      {isXs && (
        <CardContent sx={{ pt: 1, pb: 2 }}>
          <ListAssetShareholder shareholders={shareholders} />
        </CardContent>
      )}
    </Card>
  )
}

import { Components } from '@mui/material/styles'

export const MuiListItemText = (): Components['MuiListItemText'] => ({
  defaultProps: {
    primaryTypographyProps: {
      variant: 'body2',
      noWrap: true,
    },
    secondaryTypographyProps: {
      variant: 'body2',
      noWrap: true,
    },
  },
  styleOverrides: {
    primary: {},
    secondary: {
      marginTop: 2,
    },
    multiline: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
})

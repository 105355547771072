import { formatNumberInternal } from './format-number-internal'

export interface FormatMultipleOptions {
  round?: number
  disableSuffix?: boolean
  emptyValue?: string
}

export function formatMultiple(
  number: number | null | undefined,
  options: FormatMultipleOptions = {}
) {
  const round = number === 0 ? 0 : options.round
  return formatNumberInternal(number, {
    round,
    suffix: options.disableSuffix ? '' : 'x',
    emptyValue: options.emptyValue,
  })
}

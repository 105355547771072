import { ReactNode, useState } from 'react'
import { useFormState } from 'react-final-form'

import { FilterFormValues } from './filter-form-value'

export interface ActionsProps {
  hasValues: boolean
}

export interface FilterBarActionsContainerProps {
  children: ((props: ActionsProps) => ReactNode) | ReactNode
}

export default function FilterBarActionsContainer({ children }: FilterBarActionsContainerProps) {
  const [hasValues, setHasValues] = useState(false)

  useFormState<FilterFormValues>({
    subscription: {
      values: true,
    },
    onChange: (formState) =>
      setHasValues(
        formState.values.groups.some((group) => group.value.some((item) => item.value !== null))
      ),
  })

  if (typeof children === 'function') {
    return children({ hasValues }) || null
  }

  return children
}

/**
 * Checks if the given search query matches the provided value and optionally
 * other search words (such as aliases, abbreviations, etc.).
 *
 * The function performs a case-insensitive search and returns true if all
 * words in the search query are found within the value and other search words.
 */
export default function matchesSearchQuery(
  value: string,
  searchQuery: string,
  otherSearchWords: string[] = []
) {
  const sanitizedSearchQuery = searchQuery.trim().toLowerCase().split(' ')

  // Join the column label, parentGroupLabels and searchAliases
  // in one long lowercase string to perform the search on.
  // For example: 'business model customer base b2b b2c b2g'
  const searchWords = [value, ...otherSearchWords]
    .flatMap((word) => word.split(' '))
    .map((word) => word.toLowerCase().trim())
    .join(' ')

  // The option matches when all words included within the search query
  // exist within searchWords.
  return sanitizedSearchQuery.every((word) => searchWords.includes(word))
}

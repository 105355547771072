/**
 * Similar to encodeURIComponent except that it also removes characters that cause encoding or
 * decoding issues.
 */
export function encodeUrlStringComponent(value: string | null | undefined) {
  if (typeof value === 'string') {
    return encodeURIComponent(value.replace(/%/g, ''))
  }

  return undefined
}

import { Components, Theme } from '@mui/material/styles'

export const MuiListItemSecondaryAction = (
  theme: Theme
): Components['MuiListItemSecondaryAction'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.grey['600'],
      display: 'flex',
      right: 8,
      fontSize: 20,
    },
  },
})

import { SECTORS as SECTORS_WITHOUT_SUBSECTORS } from './sectors'
import { SUBSECTORS } from './subsectors'
export { formatSector, formatSubsector } from './sector-formatters'
export { getSectorForSubsector } from './sector-utils'
export type { Sector } from './sectors'
export type { Subsector } from './subsectors'

export const SECTORS = Object.freeze(
  SECTORS_WITHOUT_SUBSECTORS.map((sector) => ({
    ...sector,
    subsectors: SUBSECTORS.filter((subsector) => subsector.sector === sector.name),
  }))
)

export { SUBSECTORS }

import { useSessionId } from '@gain/modules/auth'
import { useCallback, useMemo } from 'react'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'

import { useAppBatchFetcher } from './use-app-batch-fetcher'

export interface AppBatchSwrRequest<Params> {
  method: string
  params: Params
}

export interface UseAppBatchSwrResponse<Response>
  extends Omit<SWRResponse<{ [Key in keyof Response]: Response[Key] }>, 'data'> {
  loading: boolean
  data: { [Key in keyof Response]: Response[Key] }
}

export function useAppBatchSwr<Response>(
  requests: AppBatchSwrRequest<unknown>[] | null | (() => AppBatchSwrRequest<unknown>[] | null),
  options?: SWRConfiguration
): UseAppBatchSwrResponse<{ [Key in keyof Response]: Response[Key] }> {
  const token = useSessionId()
  const fetcher = useAppBatchFetcher()

  const formatKey = useCallback(
    (paramsResponse: AppBatchSwrRequest<unknown>[]) => ({
      requests: paramsResponse,
      token,
    }),
    [token]
  )

  const key = useMemo(() => {
    if (requests === null) {
      return null
    }

    if (typeof requests === 'function') {
      return () => {
        const paramsResponse = requests()

        if (paramsResponse) {
          return formatKey(paramsResponse)
        }

        return paramsResponse
      }
    }

    return formatKey(requests)
  }, [requests, formatKey])

  const result = useSWR(key, {
    ...options,
    fetcher,
  })

  return useMemo(
    () => ({
      ...result,
      loading: !result.error && !result.data,
      data: result.data || new Array<Response>(),
    }),
    [result]
  )
}

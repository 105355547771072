import { useRpcClient } from '@gain/api/swr'
import { SpinnerIcon } from '@gain/components/icons'
import { SnackbarProps } from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import { DealCloudItem, DealCloudStatus, RpcMethodMap } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { showDealCloudResults } from './dealcloud-snackbar'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  border: `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  padding: theme.spacing(1, 2),
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  gap: theme.spacing(1),
}))

export function createFailedResult(assetId: number) {
  return {
    status: DealCloudStatus.Failed,
    error: 'Network error',
    assetId,
    entryId: 0,
    entryListId: 0,
    name: '' + assetId, // use id; if indeed a network error the name is empty and nothing can be shown to the user
    url: '',
  }
}

export const DealCloudProgress = forwardRef<HTMLDivElement, SnackbarProps & { assetIds: number[] }>(
  function Snackbar({ assetIds, id, action, icon, disableCloseButton, variant = 'default' }, ref) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const fetchCompletedRef = useRef(false)
    const fetcher = useRpcClient<RpcMethodMap>()
    const [results, setResults] = useState<DealCloudItem[]>([])

    const addToDealCloud = useCallback(async () => {
      let fetchedResults: DealCloudItem[] = []
      for (const assetId of assetIds) {
        try {
          const response = await fetcher({
            method: 'data.addToDealCloud',
            params: {
              assetIds: [assetId],
            },
          })
          fetchedResults = fetchedResults.concat(response)
          setResults(fetchedResults)
        } catch (error) {
          fetchedResults.push(createFailedResult(assetId))
          setResults(fetchedResults)
        }
      }
      closeSnackbar(id)
      showDealCloudResults(enqueueSnackbar, fetchedResults)
    }, [id, assetIds, fetcher, closeSnackbar, enqueueSnackbar])

    useEffect(() => {
      // make sure there is only one addToDealCloud running because of the snackbar state changes
      if (!fetchCompletedRef.current) {
        addToDealCloud()
        fetchCompletedRef.current = true
      }
    }, [addToDealCloud])

    let message = 'Adding 1 company to DealCloud.'
    if (assetIds.length > 1) {
      message = `${results.length} out of ${assetIds.length} companies added to DealCloud.`
    }

    return (
      <StyledRoot ref={ref}>
        <SpinnerIcon />
        <Typography
          color={'text.primary'}
          variant={'body2'}
          noWrap>
          {message} <strong>You can continue using the app</strong>
        </Typography>
      </StyledRoot>
    )
  }
)

export default DealCloudProgress

import { generatePath } from 'react-router-dom'

import { LENDER_PAGE_PATH, Params } from '../utils'

export enum RouteLenderTabs {
  Summary = 'summary',
}

export interface LenderPageParams extends Params {
  id: string
  name?: string
}

export const LENDER_SUMMARY_PATH = `${LENDER_PAGE_PATH}/${RouteLenderTabs.Summary}`

interface GenerateLenderPagePathParams {
  id: string | number
  name?: string
}

export function generateLenderPagePath(params: GenerateLenderPagePathParams) {
  return generatePath(LENDER_SUMMARY_PATH, params)
}

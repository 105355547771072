import type { RowType } from '@gain/utils/table'

import type { SummaryColumnConfig } from './table-summary'

/**
 * The available aggregation methods for the table summary. The object key is
 * the identifier of the method and can be referenced in the `summaryColumns`.
 */
export const aggregationMethods = {
  min: {
    label: 'Min.',
    calculate: (numbers: number[]) => (numbers.length > 0 ? Math.min(...numbers) : null),
  },
  max: {
    label: 'Max.',
    calculate: (numbers: number[]) => (numbers.length > 0 ? Math.max(...numbers) : null),
  },
  median: {
    label: 'Median',
    calculate: (numbers: number[]) => {
      if (numbers.length === 0) {
        return null
      }

      const sorted = [...numbers].sort((a, b) => a - b)
      const middle = Math.floor(sorted.length / 2)

      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2
      }

      return sorted[middle]
    },
  },
}

/**
 * Calculate the aggregation value for a column based on the method and the
 * values of the rows.
 */
export function calculateAggregation<Row extends RowType>(
  method: keyof typeof aggregationMethods,
  summaryColumn: SummaryColumnConfig<Row>,
  rows: Array<Row>
): number | null {
  const values = columnValues(rows, summaryColumn)
  return aggregationMethods[method].calculate(values)
}

/**
 * Get all numeric values of a column from a list of items. Optionally, you can
 * provide a function to transform or omit the value before adding it to the list.
 */
export function columnValues<Row extends RowType>(
  items: Row[],
  summaryColumn: SummaryColumnConfig<Row>
): number[] {
  return items.reduce<number[]>((acc, item) => {
    const value = item[summaryColumn.field]
    if (value !== undefined && value !== null) {
      if (summaryColumn.getAggregationValue) {
        const actualValue = summaryColumn.getAggregationValue(value)
        if (actualValue !== null) {
          acc.push(actualValue)
        }
      } else {
        acc.push(value)
      }
    }
    return acc
  }, [])
}

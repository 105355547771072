import { Asset, AssetAdvisor } from '@gain/rpc/app-model'
import { formatAdvisoryActivitiesLabels, getAdvisorAdvisedLabel } from '@gain/utils/advisor'
import { formatMonthYear } from '@gain/utils/date'
import { DealMetrics } from '@gain/utils/deal'
import { useSplitList } from '@gain/utils/list'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { MouseEvent, useCallback, useMemo, useRef, useState } from 'react'

import ContentLink, { ContentLinkCellContainer } from '../../../common/content-link'
import Table, { createTableColumns } from '../../../common/table'
import ViewMoreButton from '../../../common/view-more-button'
import AdvisorContentLink from '../../../features/advisor/advisor-content-link'
import { ADVISOR_PAGE_CLIENTS_ASSETS_PATH, generateAdvisorPagePath } from '../../utils'

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
})

export interface AssetAdvisorsTableProps {
  asset: Asset
  items: AssetAdvisor[]
  onMoreClick?: (event: MouseEvent) => void
  nrOfVisibleRows?: number
}

export default function AssetAdvisorsTable({
  asset,
  items,
  onMoreClick,
  nrOfVisibleRows = 20,
}: AssetAdvisorsTableProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const [initialRows, otherRows] = useSplitList(items, nrOfVisibleRows)
  const [showAll, setShowAll] = useState(false)

  const columns = useMemo(() => {
    return createTableColumns<AssetAdvisor>(
      {
        field: 'advisorId',
        headerName: 'Name',
        renderCell: ({ row }) => (
          <ContentLinkCellContainer>
            <AdvisorContentLink
              advisorId={row.advisorId}
              advisorName={row.advisorName}
              logoFileUrl={row.advisorLogoFileUrl}
            />
          </ContentLinkCellContainer>
        ),
      },
      {
        field: 'dealIds',
        headerName: 'No. of deals advised',
        align: 'right',
        width: 164,
        renderCell: ({ row }) => {
          const dealsPath = generateAdvisorPagePath(
            {
              advisorId: row.advisorId,
              advisorName: row.advisorName,
              assetId: `${asset.id}`,
              assetName: asset.generalInfo?.name,
            },
            ADVISOR_PAGE_CLIENTS_ASSETS_PATH
          )

          return (
            <Stack
              direction={'row'}
              display={'flex'}
              justifyContent={'flex-end'}>
              <ContentLink
                href={dealsPath}
                label={`${row.dealIds.length}`}
              />
            </Stack>
          )
        },
      },
      {
        field: 'lastDealAdvised',
        headerName: 'Party advised',
        width: 148,
        valueFormatter: ({ value }) => getAdvisorAdvisedLabel(value),
      },
      {
        field: 'lastDealAdvisedOn',
        headerName: 'Last advised on',
        width: 225,
        valueFormatter: ({ value }) => formatAdvisoryActivitiesLabels(value),
      },
      {
        field: 'dealCount', // There is no other name we can use to make it unique for column visibility
        headerName: 'Last deal metrics',
        width: 264,
        renderCell: ({ row }) => (row.lastDeal ? <DealMetrics deal={row.lastDeal} /> : null),
      },
      {
        field: 'lastDeal',
        headerName: 'Last deal',
        align: 'right',
        width: 104,
        valueFormatter: ({ value }) =>
          formatMonthYear(value?.announcementDate?.month, value?.announcementDate?.year),
      }
    )
  }, [asset])

  const columnVisibility = useMemo((): ColumnVisibilityModel<AssetAdvisor> => {
    return {
      dealCount: 1100,
      lastDealAdvised: 910,
      lastDealAdvisedOn: 780,
      dealIds: 550,
    }
  }, [])

  const handleMoreClick = useCallback(
    (event: MouseEvent) => {
      if (onMoreClick) {
        onMoreClick(event)
      } else {
        setShowAll(true)
      }
    },
    [onMoreClick]
  )

  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  return (
    <StyledRoot>
      <Table
        ref={tableRef}
        amountOfPlaceholders={4}
        columns={visibleColumns}
        rows={showAll ? items : initialRows}
        disablePaddingBottomOnLastRow
      />

      {!showAll && (
        <ViewMoreButton
          amount={otherRows.length}
          onClick={handleMoreClick}
          variant={'chevron-down'}
        />
      )}
    </StyledRoot>
  )
}

import { useBookmarkListAddAssets, useCreateBookmarksList } from '@gain/api/app/hooks'
import { CustomAssetList } from '@gain/rpc/app-model'
import { DialogProps } from '@mui/material/Dialog'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { BOOKMARKS_LIST_SUMMARY_PATH } from '../../../routes/utils'
import AddAssetsDialog from '../../add-assets-dialog'
import BookmarksListTitleDialog from '../bookmarks-list-title-dialog/bookmarks-list-title-dialog'

export type BookmarksListCreateCloseHandler = (
  reason: 'cancel' | 'create',
  list?: CustomAssetList
) => void

interface BookmarksListCreateDialogProps extends Omit<DialogProps, 'onClose'> {
  open: boolean
  onClose: BookmarksListCreateCloseHandler
  enableAddAssets?: boolean
  disableRedirect?: boolean
  dialogTitle?: string
  infoSlot?: ReactNode
  assetIdsToAdd?: number[]
  disableSnackbar?: boolean
  initialListTitle?: string
}

/**
 * BookmarksListCreateDialog is a flow of dialogs that prompt the user
 * for information required to create a bookmark list. It asks for a
 * title, optionally to add companies to the list directly
 */
export default function BookmarksListCreateDialog({
  disableRedirect,
  enableAddAssets: enableAddAsset,
  onClose,
  open,
  dialogTitle = 'Create new bookmark list',
  infoSlot,
  assetIdsToAdd,
  initialListTitle,
  disableSnackbar,
  ...props
}: BookmarksListCreateDialogProps) {
  const history = useHistory()
  const [titleDialogOpen, setTitleDialogOpen] = useState(false)
  const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false)
  const [title, setTitle] = useState('')
  const newList = useRef<CustomAssetList | null>(null)
  const createList = useCreateBookmarksList()
  const addAssets = useBookmarkListAddAssets()

  useEffect(() => {
    setTitleDialogOpen(open)
  }, [open])

  // Handle submitting the title, either directly submitting or adding assetIds
  const handleTitleSubmit = async (newTitle: string) => {
    setTitle(newTitle)
    setTitleDialogOpen(false)

    // Either show the dialog for adding assetIds or go directly to submitting
    if (enableAddAsset) {
      setAddCompanyDialogOpen(true)
    } else {
      await handleSubmit(newTitle, assetIdsToAdd)
      handleClose()
    }
  }

  // Handle submitting the assetIds
  const handleAddAssetsSubmit = async (assetIdsFromDialog: number[]) => {
    setAddCompanyDialogOpen(false)
    await handleSubmit(title, assetIdsFromDialog)
    handleClose()
  }

  // Create the list and save the assetIds
  const handleSubmit = async (newTitle: string, assetIds?: number[]) => {
    newList.current = await createList(newTitle)
    if (newList.current && assetIds) {
      await addAssets(newList.current, assetIds, disableSnackbar)
    }

    return newList.current
  }

  // Close title dialog and cancel the workflow
  const handleTitleDialogClose = () => {
    setTitleDialogOpen(false)
    onClose('cancel')
  }

  // Close add assets dialog and cancel the workflow
  const handleAddAssetsDialogClose = () => {
    setAddCompanyDialogOpen(false)
    onClose('cancel')
  }

  // Communicate list if success
  const handleClose = useCallback(() => {
    // Close the dialog, cancel workflow if no list was made
    if (!newList.current) {
      onClose('cancel')
      return
    }

    // Communicate success
    onClose('create', newList.current)

    // Skip redirect if explicitly mentioned
    if (!disableRedirect) {
      history.push(generatePath(BOOKMARKS_LIST_SUMMARY_PATH, { listId: newList.current.id }))
    }
  }, [disableRedirect, history, newList, onClose])

  return (
    <>
      <BookmarksListTitleDialog
        {...props}
        dialogTitle={dialogTitle}
        infoSlot={infoSlot}
        onClose={handleTitleDialogClose}
        onSubmit={handleTitleSubmit}
        open={titleDialogOpen}
        // show a label if the initial title is set, to make it clear to the user
        // that the text input field is used to name the list.
        titleLabel={!!initialListTitle}
        value={initialListTitle}
      />

      {enableAddAsset && (
        <AddAssetsDialog
          onClose={handleAddAssetsDialogClose}
          onSave={handleAddAssetsSubmit}
          open={addCompanyDialogOpen}
        />
      )}
    </>
  )
}

import { styled } from '@mui/material/styles'
import Table, { TableProps } from '@mui/material/Table'

export interface InnerVirtualTableProps {
  width: number
  height: number
}

const propNames = ['width', 'height']

export const InnerVirtualTable = styled(
  (props: TableProps) => (
    <Table
      component={'div'}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => !propNames.includes(prop as string),
  }
)<InnerVirtualTableProps>(({ width, height }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0%',
  height: height,
  minHeight: height,
  maxHeight: height,
  width: width,
  minWidth: width,
  maxWidth: width,
}))

import { LegalEntityIcon, UserIcon } from '@gain/components/icons'
import { LegalEntity, LegalEntityShareholder } from '@gain/rpc/app-model'
import { compareNumberDesc } from '@gain/utils/common'
import { formatExternalId } from '@gain/utils/legal-entity'
import { useIsXs } from '@gain/utils/responsive'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow, { tableRowClasses } from '@mui/material/TableRow'
import { Fragment } from 'react'

import ContentLink from '../../../../common/content-link'
import ContentLinkLegalEntity from '../../../../features/legal-entity/legal-entity-content-link'
import { formatSharePercentage } from '../../../legal-entity/ownership/legal-entity-ownership-columns'

const StyledTable = styled(Table)({
  tableLayout: 'fixed',
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.background.paper,
    fontWeight: theme.typography.fontWeightLight,
    height: 40,
  },
}))

const StyledTableBody = styled(TableBody)({
  [`& .${tableCellClasses.root}`]: {
    height: 56,
  },
})

const StyledShareholdersTableBody = styled(TableBody)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.background.default,
    borderBottom: 0,
    height: 40,

    '&:first-child': {
      // force overwrite initial cell padding added by the table
      paddingLeft: `${theme.spacing(5)} !important`,
    },
  },
  [`& .${tableRowClasses.root}:last-child`]: {
    [`& .${tableCellClasses.root}`]: {
      paddingBottom: theme.spacing(1),
      height: 48,
    },
  },
  [`&:last-child .${tableRowClasses.root}:last-child`]: {
    [`& .${tableCellClasses.root}:first-child`]: {
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
    },
    [`& .${tableCellClasses.root}:last-child`]: {
      borderBottomRightRadius: theme.shape.borderRadius * 2,
    },
  },
}))

function getShareMidPoint(shareholder: LegalEntityShareholder) {
  if (typeof shareholder.percentageShareExact === 'number') {
    return shareholder.percentageShareExact
  }

  if (
    typeof shareholder.percentageShareMin === 'number' &&
    typeof shareholder.percentageShareMax === 'number'
  ) {
    return (shareholder.percentageShareMin + shareholder.percentageShareMax) / 2
  }

  return 0
}

function compareShareholdersByPercentageAndName(
  a: LegalEntityShareholder,
  b: LegalEntityShareholder
) {
  const aShare = getShareMidPoint(a)
  const bShare = getShareMidPoint(b)

  const shareCompare = compareNumberDesc(aShare, bShare)
  if (shareCompare !== 0) {
    return shareCompare
  }

  return a.name.localeCompare(b.name)
}

/**
 * Renders a table showing the shareholders of per given legal entity.
 */
export default function LegalStructuresAndShareholdersTable(props: {
  legalEntities: LegalEntity[]
}) {
  const isXs = useIsXs()

  return (
    <StyledTable size={'medium'}>
      <StyledTableHead>
        <TableRow>
          <TableCell>Legal entity</TableCell>
          {!isXs && <TableCell sx={{ width: [148, 200] }}>Entity number</TableCell>}
          <TableCell
            align={'right'}
            sx={{ width: [140, 140, 140, 180] }}>
            Ownership %
          </TableCell>
        </TableRow>
      </StyledTableHead>

      {props.legalEntities.map((legalEntity) => (
        <Fragment key={legalEntity.id}>
          <StyledTableBody>
            <TableRow>
              <TableCell colSpan={isXs ? 2 : 1}>
                <ContentLinkLegalEntity
                  id={legalEntity.id}
                  name={legalEntity.name}
                  region={legalEntity.region}
                />
              </TableCell>
              {!isXs && (
                <TableCell colSpan={2}>
                  {formatExternalId(legalEntity.externalId, legalEntity.region)}
                </TableCell>
              )}
            </TableRow>
          </StyledTableBody>
          <StyledShareholdersTableBody>
            {legalEntity.legalEntityShareholders
              .slice()
              .sort(compareShareholdersByPercentageAndName)
              .map((shareholder) => (
                <TableRow key={shareholder.id}>
                  <TableCell colSpan={isXs ? 2 : 1}>
                    <Stack
                      alignItems={'center'}
                      direction={'row'}
                      gap={0.5}
                      justifyContent={'space-between'}>
                      <ContentLink
                        avatarProps={{
                          children:
                            shareholder.type === 'Person' ? <UserIcon /> : <LegalEntityIcon />,
                        }}
                        label={shareholder.name}
                      />
                      {isXs && formatSharePercentage(shareholder)}
                    </Stack>
                  </TableCell>
                  {!isXs && (
                    <>
                      <TableCell>
                        {shareholder.shareholderExternalId
                          ? formatExternalId(shareholder.shareholderExternalId, shareholder.region)
                          : '-'}
                      </TableCell>
                      <TableCell align={'right'}>{formatSharePercentage(shareholder)}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </StyledShareholdersTableBody>
        </Fragment>
      ))}
    </StyledTable>
  )
}

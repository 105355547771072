import { Components, Theme } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableRowClasses } from '@mui/material/TableRow'

export const MuiTableBody = (theme: Theme): Components['MuiTableBody'] => ({
  styleOverrides: {
    root: {
      [`&:not(:last-of-type) .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
})

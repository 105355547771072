import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { Link } from 'react-router-dom'

interface FullWidthButtonProps extends LoadingButtonProps {
  to?: string
}

/**
 * FullWidthButton renders a full width, primary button with
 * loading support that can be used in a form.
 */
export default function FullWidthButton(props: FullWidthButtonProps) {
  const { to, children, ...restProps } = props

  // If `to` is provided, return a button that renders as a link
  if (to != null) {
    return (
      <LoadingButton
        color={'primary'}
        component={Link}
        fullWidth={true}
        to={to}
        variant={'contained'}>
        {children}
      </LoadingButton>
    )
  }

  return (
    <LoadingButton
      color={'primary'}
      fullWidth={true}
      variant={'contained'}
      {...restProps}>
      {children}
    </LoadingButton>
  )
}

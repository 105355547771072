import { TableIcon, TrendingUpIcon } from '@gain/components/icons'
import { InvestorFundListItem } from '@gain/rpc/app-model'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  rangeCurrency,
  rangeMultiple,
  rangePercentage,
  rangeYear,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const INVESTOR_FUND_FILTER_FIELDS = [
  'fundSizeEur',
  'netIrr',
  'grossIrr',
  'twr',
  'tvpi',
  'moic',
  'dpi',
  'rvpi',
  'vintageYear',
] as const

export type InvestorFundFilterField = (typeof INVESTOR_FUND_FILTER_FIELDS)[number]

export const INVESTOR_FUND_FILTER_MAP = createFilterMap<
  InvestorFundListItem,
  typeof INVESTOR_FUND_FILTER_FIELDS
>(
  rangeCurrency('fundSizeEur', 'Fund size', {
    icon: TableIcon,
  }),
  rangePercentage('netIrr', 'Net IRR'),
  rangePercentage('grossIrr', 'Gross IRR'),
  rangePercentage('twr', 'TWR'),
  rangeMultiple('tvpi', 'TVPI'),
  rangeMultiple('moic', 'MOIC'),
  rangeMultiple('dpi', 'DPI'),
  rangeMultiple('rvpi', 'RVPI'),
  rangeYear('vintageYear', 'Vintage date')
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const INVESTOR_FUND_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<InvestorFundListItem, InvestorFundFilterField>
> = [
  INVESTOR_FUND_FILTER_MAP.fundSizeEur,
  INVESTOR_FUND_FILTER_MAP.vintageYear,
  dropdownMenuGroup('fundPerformance', 'Fund performance', {
    icon: TrendingUpIcon,
    children: [
      INVESTOR_FUND_FILTER_MAP.netIrr,
      INVESTOR_FUND_FILTER_MAP.grossIrr,
      INVESTOR_FUND_FILTER_MAP.twr,
      INVESTOR_FUND_FILTER_MAP.tvpi,
      INVESTOR_FUND_FILTER_MAP.moic,
      INVESTOR_FUND_FILTER_MAP.dpi,
      INVESTOR_FUND_FILTER_MAP.rvpi,
    ],
  }),
]

export const INVESTOR_FUND_DEFAULT_FILTERS: FilterModel<
  InvestorFundListItem,
  InvestorFundFilterField
> = [
  filterValueGroup(filterValueItem('fundSizeEur')),
  filterValueGroup(filterValueItem('vintageYear')),
]

import { BriefcaseMoneyIcon } from '@gain/components/icons'
import { SearchResultItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import React from 'react'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { generateInvestorPagePath } from '../../../utils'

export interface SearchResultListItemInvestorProps {
  item: SearchResultItem
}

export default function SearchResultListItemInvestor({ item }: SearchResultListItemInvestorProps) {
  return (
    <MobileListItem key={`${item.type}-${item.id}`}>
      <MobileListItemButton
        as={Link}
        {...{
          href: generateInvestorPagePath({
            investorId: item.id,
            investorName: item.name,
            preview: false,
          }),
        }}>
        <MobileListItemIcon>
          <Logo
            defaultIcon={<BriefcaseMoneyIcon />}
            size={40}
            src={item.imageUrl}
          />
        </MobileListItemIcon>
        <MobileListItemText
          primary={item.name}
          secondary={item.description}
        />
      </MobileListItemButton>
    </MobileListItem>
  )
}

import OverflowContainer from '@gain/components/overflow-container'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatThousands } from '@gain/utils/number'
import React from 'react'

import LatestFinancialsHorizontalItem from './latest-financials-horizontal-item.component'
import LatestFinancialsVerticalItem, {
  LatestFinancialsItemProps,
} from './latest-financials-vertical-item.component'

const MAX_FINANCIALS = 3

export interface LatestFinancialsProps {
  // use dataCurrency to specify the currency if the data is not in EUR
  dataCurrency?: string | null

  variant: 'horizontal' | 'vertical'
  emptyText?: string | null // Text shown when no financials are available

  revenue?: number | null
  revenueYear?: number | null
  revenueLines?: LatestFinancialsItemProps['lineValues']

  grossMargin?: number | null
  grossMarginYear?: number | null
  grossMarginLines?: LatestFinancialsItemProps['lineValues']

  ebitda?: number | null
  ebitdaYear?: number | null
  ebitdaLines?: LatestFinancialsItemProps['lineValues']

  ebit?: number | null
  ebitYear?: number | null
  ebitLines?: LatestFinancialsItemProps['lineValues']

  totalAssets?: number | null
  totalAssetsYear?: number | null
  totalAssetsLines?: LatestFinancialsItemProps['lineValues']

  netDebt?: number | null
  netDebtYear?: number | null
  netDebtLines?: LatestFinancialsItemProps['lineValues']

  capital?: number | null
  capitalYear?: number | null
  capitalLines?: LatestFinancialsItemProps['lineValues']

  fte?: number | null
  fteYear?: number | null
  fteLines?: LatestFinancialsItemProps['lineValues']
}

export function LatestFinancials({
  dataCurrency,
  variant,
  emptyText,
  ...props
}: LatestFinancialsProps) {
  const formatCurrency = useFormatCurrencyCallback(dataCurrency)
  const results: LatestFinancialsItemProps[] = []

  if (props.revenue) {
    results.push({
      label: 'Revenue',
      value: formatCurrency(props.revenue),
      lineValues: props.revenueLines,
      year: props.revenueYear,
    })
  }

  if (props.grossMargin) {
    results.push({
      label: 'Gross margin',
      value: formatCurrency(props.grossMargin),
      lineValues: props.grossMarginLines,
      year: props.grossMarginYear,
    })
  }

  if (props.ebitda) {
    results.push({
      label: 'EBITDA',
      value: formatCurrency(props.ebitda),
      lineValues: props.ebitdaLines,
      year: props.ebitdaYear,
    })
  }

  if (props.ebit) {
    results.push({
      label: 'EBIT',
      value: formatCurrency(props.ebit),
      lineValues: props.ebitLines,
      year: props.ebitYear,
    })
  }

  if (props.totalAssets) {
    results.push({
      label: 'Total assets',
      value: formatCurrency(props.totalAssets),
      lineValues: props.totalAssetsLines,
      year: props.totalAssetsYear,
    })
  }

  if (props.netDebt) {
    results.push({
      label: 'Net debt',
      value: formatCurrency(props.netDebt),
      lineValues: props.netDebtLines,
      year: props.netDebtYear,
    })
  }

  if (props.capital) {
    results.push({
      label: 'Capital',
      value: formatCurrency(props.capital),
      lineValues: props.capitalLines,
      year: props.capitalYear,
    })
  }

  if (props.fte) {
    results.push({
      label: 'FTE',
      value: formatThousands(props.fte),
      lineValues: props.fteLines,
      year: props.fteYear,
    })
  }

  const items = results.slice(0, MAX_FINANCIALS)

  const isHorizontal = variant === 'horizontal'

  if (items.length === 0) {
    return emptyText
  }

  return (
    <OverflowContainer
      lineHeight={isHorizontal ? 22 : 34}
      maxLines={1}
      spacing={isHorizontal ? 2 : 4}
      sx={{ mt: isHorizontal ? 0 : 2 }}
      disableCountChip>
      {items.map((row) => {
        if (isHorizontal) {
          return (
            <LatestFinancialsHorizontalItem
              {...row}
              key={row.label}
            />
          )
        }

        return (
          <LatestFinancialsVerticalItem
            {...row}
            key={row.label}
          />
        )
      })}
    </OverflowContainer>
  )
}

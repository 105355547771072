import { Trash2Icon } from '@gain/components/icons'
import { ListItemKey } from '@gain/rpc/list-model'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { FilterConfig } from '../filter-config/filter-config-model'
import FilterItemHeaderSelect from './filter-item-header-select'

const StyledRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const StyledClearButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  ...theme.typography.overline,
  color: theme.palette.text.secondary,
  height: 15,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  width: 24,
  height: 24,
  fontSize: 16,
}))

export interface FilterItemHeaderProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filter: FilterConfig<Item, FilterField>
  disableClear?: boolean
  onClear: () => void
  onChange: (filterId: string) => void
  disableDelete?: boolean
  onDelete: () => void
}

export default function FilterItemHeader<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({
  filter,
  disableClear,
  onClear,
  disableDelete,
  onDelete,
  onChange,
}: FilterItemHeaderProps<Item, FilterField>) {
  return (
    <StyledRoot>
      {filter.select && (
        <FilterItemHeaderSelect
          filter={filter}
          onChange={onChange}
        />
      )}
      {!filter.select && <Typography variant={'subtitle2'}>{filter.label}</Typography>}

      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}>
        <Fade in={!disableClear}>
          <StyledClearButton onClick={onClear}>Clear</StyledClearButton>
        </Fade>
        {!disableDelete && (
          <StyledIconButton onClick={onDelete}>
            <Trash2Icon fontSize={'inherit'} />
          </StyledIconButton>
        )}
      </Stack>
    </StyledRoot>
  )
}

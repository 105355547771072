import { useTooltipOnEllipsis } from '@gain/components/typography'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ComponentProps } from 'react'

const StyledTableHeadCellTitle = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'initial',
})

export default function TableHeadCellTitle(props: ComponentProps<typeof StyledTableHeadCellTitle>) {
  const tooltip = useTooltipOnEllipsis('vertical')
  return (
    <Tooltip
      open={tooltip.tooltipOpen}
      title={props.children || ''}
      disableInteractive>
      <StyledTableHeadCellTitle
        {...props}
        onMouseOut={tooltip.handleMouseOut}
        onMouseOver={tooltip.handleMouseOver}
      />
    </Tooltip>
  )
}

import { ExternalLinkIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { Investor, InvestorFund, InvestorProfileStrategy } from '@gain/rpc/app-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { investorFundExplanations, sortByVintageDateDesc } from '@gain/utils/investor-fund'
import { formatMultiple, formatPercentage } from '@gain/utils/number'
import { useIsXs } from '@gain/utils/responsive'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Chip from '@mui/material/Chip'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { useCallback, useMemo, useRef } from 'react'

import ContentLink from '../../../common/content-link'
import Table, { createTableColumns } from '../../../common/table'
import FinancialValue from '../../../features/financial/financial-value'
import TooltipInvestorStrategy from '../../../features/investor-strategy/investor-strategy-tooltip'
import { generateInvestorStrategyPagePathParams } from '../../investor-strategy'
import { DRY_POWDER_EXPLAINER } from '../investor-profile-util'
import FundNotesChip from './fund-notes.chip'
import { TableCellFinancialAmount } from './table-cell-financial-amount'

type FundPerformanceAmountKey = keyof Pick<
  InvestorFund,
  'netIrr' | 'grossIrr' | 'twr' | 'tvpi' | 'moic' | 'dpi' | 'rvpi'
>

type FundPerformanceDateKey = keyof Pick<
  InvestorFund,
  'netIrrDate' | 'grossIrrDate' | 'twrDate' | 'tvpiDate' | 'moicDate' | 'dpiDate' | 'rvpiDate'
>

interface FundPerformanceColumn {
  field: FundPerformanceAmountKey
  dateField: FundPerformanceDateKey
  label: string
  helperText: string
  width: number
  formatAmount: (value: number | null) => string
}

const investorFundsTableClasses = generateUtilityClasses('InvestorFundsTable', ['estimate'])

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${investorFundsTableClasses.estimate}`]: {
    color: theme.palette.info.main,
  },
})) as typeof Table

const fundPerformanceColumns = new Array<FundPerformanceColumn>(
  {
    field: 'netIrr',
    dateField: 'netIrrDate',
    label: 'Net IRR',
    helperText: investorFundExplanations.netIrr,
    width: 120,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'grossIrr',
    dateField: 'grossIrrDate',
    label: 'Gross IRR',
    helperText: investorFundExplanations.grossIrr,
    width: 120,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'twr',
    dateField: 'twrDate',
    label: 'TWR',
    helperText: investorFundExplanations.twr,
    width: 120,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'tvpi',
    dateField: 'tvpiDate',
    label: 'TVPI',
    helperText: investorFundExplanations.tvpi,
    width: 120,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'moic',
    dateField: 'moicDate',
    label: 'MOIC',
    helperText: investorFundExplanations.moic,
    width: 120,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'dpi',
    dateField: 'dpiDate',
    label: 'DPI',
    helperText: investorFundExplanations.dpi,
    width: 120,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'rvpi',
    dateField: 'rvpiDate',
    label: 'RVPI',
    helperText: investorFundExplanations.rvpi,
    width: 120,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  }
)

function hasFundMetricValues(config: FundPerformanceColumn, investor: Investor) {
  return investor.funds.some((fund) => fund[config.field] !== null)
}

function useColumns(investor: Investor, strategies: InvestorProfileStrategy[]) {
  const isXs = useIsXs()
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return useMemo(() => {
    const hasNotes = investor.funds.some((fund) => fund.notes.length > 0)

    return createTableColumns<InvestorFund>(
      {
        field: 'name',
        headerName: 'Fund',
        align: 'left',
        width: 232,
        sticky: !isXs,
        renderCell: ({ value, row }) => (
          <Stack
            direction={'row'}
            gap={0.5}>
            <Typography
              variant={'body2'}
              noWrap>
              {value}
            </Typography>
            {row.continuationFund && <Chip label={'Continuation fund'} />}
          </Stack>
        ),
      },
      {
        field: 'investorStrategyId',
        headerName: 'Strategy',
        align: 'left',
        width: 184,
        paddingLeft: 3,
        renderCell: ({ value }) => {
          const investorStrategy = strategies.find((item) => item.id === value)
          if (investorStrategy) {
            return (
              <TooltipInvestorStrategy id={investorStrategy.id}>
                <ContentLink
                  href={generateInvestorStrategyPagePathParams({
                    investorId: investor.id,
                    strategyId: investorStrategy.id,
                    strategyName: investorStrategy.name,
                    investorName: investor.name,
                  })}
                  label={investorStrategy.name}
                />
              </TooltipInvestorStrategy>
            )
          }

          const strategy = investor.strategies.find((item) => item.id === value)
          if (strategy) {
            return strategy.name
          }
          return investor.strategies.length > 0 ? 'Unknown' : 'Single strategy'
        },
      },
      {
        field: 'fundSizeEur',
        headerName: 'Size',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        field: 'dryPowderMin',
        headerName: 'Drypowder est.',
        width: 131,
        align: 'right',
        headerExplainer: DRY_POWDER_EXPLAINER,
        cellClassName: ({ row }) => {
          if (row.dryPowderMin !== null && row.dryPowderMax !== null) {
            return investorFundsTableClasses.estimate
          }

          return undefined
        },
        valueFormatter: ({ row }) => {
          if (row.dryPowderMin === null || row.dryPowderMax === null) {
            return '-'
          }

          return formatCurrencyRange(row.dryPowderMin, row.dryPowderMax, {
            dataCurrency: row.currency,
            round: 'estimate',
          })
        },
      },
      ...fundPerformanceColumns
        .filter((config) => hasFundMetricValues(config, investor))
        .map((config) => ({
          field: config.field,
          headerName: config.label,
          width: config.width,
          headerExplainer: config.helperText,
          renderCell: ({ row }) => (
            <TableCellFinancialAmount
              amount={row[config.field]}
              date={row[config.dateField]}
              formatAmount={config.formatAmount}
            />
          ),
        })),
      {
        field: 'vintageDate',
        headerName: 'Vintage date',
        align: 'right',
        width: 120,
        valueFormatter: ({ value }) => value?.year || '-',
      },
      hasNotes && {
        field: 'notes',
        headerName: 'Notes',
        width: 96,
        renderCell: ({ value }) => <FundNotesChip notes={value} />,
      },
      {
        field: 'source',
        headerName: 'Source',
        width: 80,
        align: 'center',
        renderCell: ({ value }) => (
          <IconButton
            color={'primary'}
            component={'a'}
            href={value}
            size={'small'}
            target={'_blank'}>
            <ExternalLinkIcon />
          </IconButton>
        ),
      }
    )
  }, [investor, isXs, strategies, formatCurrencyRange])
}

const columnVisibility: ColumnVisibilityModel<InvestorFund> = {
  investorStrategyId: 360,
  vintageDate: 360,
  dryPowderMin: 360,
  notes: 360,
  source: 360,
  netIrr: 360,
  grossIrr: 360,
  twr: 360,
  tvpi: 360,
  moic: 360,
  dpi: 360,
  rvpi: 360,
}

export interface InvestorFundTableProps {
  investor: Investor
  strategies: InvestorProfileStrategy[]
}

export default function InvestorFundsTable({ investor, strategies }: InvestorFundTableProps) {
  const handleGroupBySort = useCallback(
    (strategyIdA: number | null, strategyIdB: number | null) => {
      const strategyA = strategies.find((strategy) => strategy.id === strategyIdA)
      const strategyB = strategies.find((strategy) => strategy.id === strategyIdB)

      return (strategyB?.assetCount || 0) - (strategyA?.assetCount || 0)
    },
    [strategies]
  )

  const tableRef = useRef<HTMLDivElement>(null)
  const columns = useColumns(investor, strategies)

  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  return (
    <StyledTable
      ref={tableRef}
      columns={visibleColumns}
      groupBy={'investorStrategyId'}
      groupBySort={handleGroupBySort}
      rows={investor.funds}
      sort={sortByVintageDateDesc}
    />
  )
}

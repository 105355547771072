import { createContext, useContext } from 'react'

export interface ZendeskApi {
  isLoading: boolean
  enableChat: () => void
}

export const ZendeskContext = createContext<ZendeskApi | null>(null)

export const useZendesk = () => {
  const context = useContext(ZendeskContext)

  if (!context) {
    throw new Error('ZendeskContext not provided')
  }

  return context
}

import { AssetSalesChannelType } from '@gain/rpc/shared-model'

export const formatAssetSalesChannelLabel = (value: AssetSalesChannelType): string => {
  switch (value) {
    case AssetSalesChannelType.DirectSales:
      return 'Direct sales'
    case AssetSalesChannelType.IndirectSales:
      return 'Indirect sales'
    case AssetSalesChannelType.BrickAndMortar:
      return 'Brick-and-mortar'
    case AssetSalesChannelType.Online:
      return 'Online'
  }
}

import { SpinnerIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'
import React, { ReactElement, Ref } from 'react'

import Card, { CardHeader, CardHeaderProps } from '../../card/card'
import InViewport from '../../in-viewport'

const StyledChartContentWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'grid',
  placeItems: 'center',
  height: '100%',
}))

interface ChartWrapperContentProps {
  loading?: boolean
  children: ReactElement
}

function ChartWrapperContent({ children, loading }: ChartWrapperContentProps) {
  if (loading) {
    return (
      <StyledChartContentWrapper>
        <SpinnerIcon />
      </StyledChartContentWrapper>
    )
  }

  // All is OK, render the chart
  return children
}

interface ChartWrapperProps extends ChartWrapperContentProps {
  title: string
  minHeight?: number
  cardHeaderProps?: CardHeaderProps
}

function ChartWrapper(
  { title, minHeight = 536, cardHeaderProps = {}, children, ...props }: ChartWrapperProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <InViewport
      ref={ref}
      minHeight={minHeight}
      showLoader={false}
      keepMounted>
      <Card sx={{ pb: 0, height: minHeight }}>
        <CardHeader
          title={title}
          {...cardHeaderProps}
        />
        <ChartWrapperContent {...props}>{children}</ChartWrapperContent>
      </Card>
    </InViewport>
  )
}

export default React.forwardRef(ChartWrapper)

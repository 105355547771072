import {
  ChevronLeftEndIcon,
  ChevronLeftIcon,
  ChevronRightEndIcon,
  ChevronRightIcon,
} from '@gain/components/icons'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { MouseEvent, useMemo } from 'react'

export function usePageItems<D>(page: number, itemsPerPage: number, items: D[]) {
  return useMemo(() => {
    const startIndex = page * itemsPerPage
    return items.slice(startIndex, startIndex + itemsPerPage)
  }, [page, itemsPerPage, items])
}

const StyledRoot = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing('7px', 2, 1, 2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
}))

const StyledButtonContainer = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
})

interface CardPaginationProps {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  onPageChange: (page: number, event: MouseEvent) => void
}

export default function CardPagination({
  currentPage,
  itemCount,
  itemsPerPage,
  onPageChange,
}: CardPaginationProps) {
  const nrOfPages = Math.ceil(itemCount / itemsPerPage)
  const isLastPage = currentPage + 1 === nrOfPages

  return (
    <StyledRoot>
      <StyledButtonContainer>
        <IconButton
          disabled={currentPage === 0}
          onClick={(event) => onPageChange(0, event)}>
          <ChevronLeftEndIcon />
        </IconButton>

        <IconButton
          disabled={currentPage === 0}
          onClick={(event) => onPageChange(currentPage - 1, event)}>
          <ChevronLeftIcon />
        </IconButton>
      </StyledButtonContainer>

      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        Page {currentPage + 1} of {nrOfPages}
      </Typography>

      <StyledButtonContainer>
        <IconButton
          disabled={isLastPage}
          onClick={(event) => onPageChange(currentPage + 1, event)}>
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          disabled={isLastPage}
          onClick={(event) => onPageChange(nrOfPages - 1, event)}>
          <ChevronRightEndIcon />
        </IconButton>
      </StyledButtonContainer>
    </StyledRoot>
  )
}

import { useRpcClient } from '@gain/api/swr'
import { useUserProfileIgnoreAuthError } from '@gain/modules/user'
import { isAcc, isProduction } from '@gain/utils/environment'
import { useCallback, useEffect, useRef, useState } from 'react'

import { ZendeskApi } from './zendesk-context'
import { ZendeskSettings } from './zendesk-model'
import { zendeskCommand } from './zendesk-util'

declare global {
  interface Window {
    zESettings?: ZendeskSettings
  }
}

const defaultSettings: ZendeskSettings = {
  webWidget: {
    chat: {
      suppress: false,
    },
    contactForm: {
      suppress: false,
    },
    helpCenter: {
      suppress: false,
    },
    talk: {
      suppress: false,
    },
    answerBot: {
      suppress: false,
    },
  },
}

enum ZendeskKey {
  // Default key is used for the main Zendesk chat widget on acceptance and
  // production environments. Other environments are not whitelisted.
  Default = '4595d1b7-685c-4784-84de-a64b70fbf053',

  // TestWidget key is used to experiment with Zendesk's automated chatbot widget
  // on EXP, TEST and local. See also:
  // https://gainpro.zendesk.com/admin/channels/messaging_and_social/channels_list/web_widget/10167705245329/edit
  TestWidget = '99b9d077-9a01-4194-b81f-ec55fefd229d',
}

export async function initializeZendesk(key: ZendeskKey) {
  return new Promise((resolve) => {
    window.zESettings = defaultSettings

    // Prevent third party script loading from going into browser extension source code
    if (process.env['NX_PUBLIC_TASK_TARGET'] !== 'browser-extension') {
      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
      script.async = true
      script.onload = () => {
        resolve(void 0)
      }

      document.body.appendChild(script)
    }
  })
}

export default function useZendeskApi(): ZendeskApi {
  const userProfile = useUserProfileIgnoreAuthError()
  const [isLoading, setIsLoading] = useState(false)
  const isInitializedRef = useRef(false)
  const rpcClient = useRpcClient()
  const key = isAcc() || isProduction() ? ZendeskKey.Default : ZendeskKey.TestWidget

  // Keep track of commands that need to be executed after the Zendesk script has been initialized
  const initialCommandsRef = useRef<Array<() => Promise<void>>>([])

  // Loads the Zendesk script and executes commands scheduled for after initialization
  const initialize = useCallback(async () => {
    if (isInitializedRef.current) {
      return Promise.resolve()
    }

    setIsLoading(true)
    await initializeZendesk(key)
    for (const command of initialCommandsRef.current) {
      await command()
    }
    isInitializedRef.current = true
    setIsLoading(false)
  }, [key])

  const scheduleCommand = useCallback((command: () => Promise<void>) => {
    // When not initialized, schedule command to be executed after initialization
    if (!isInitializedRef.current) {
      initialCommandsRef.current.push(() => command())
      return
    }

    // Otherwise execute the command immediately
    return command()
  }, [])

  const enableChat = useCallback(async () => {
    await initialize()

    zendeskCommand('messenger', 'open')
  }, [initialize])

  const login = useCallback(() => {
    scheduleCommand(async () => {
      // No need for error handling; if the request fails the chat still works
      // but the user will be unverified.
      const response = await rpcClient({ method: 'account.loginZendesk' })

      return new Promise((resolve) => {
        // See https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/
        zendeskCommand('messenger', 'loginUser', (callback: (token: string) => void) => {
          callback(response.jwt)
          resolve()
        })
      })
    })
  }, [scheduleCommand, rpcClient])

  const logout = useCallback(() => {
    scheduleCommand(() => {
      zendeskCommand('messenger', 'logoutUser')
      return Promise.resolve()
    })
  }, [scheduleCommand])

  const isLoggedIn = userProfile.data !== undefined

  // Effect to login if we have a user profile
  useEffect(() => {
    if (userProfile.loading) {
      return
    }

    if (isLoggedIn) {
      login()
    } else {
      logout()
    }
  }, [login, logout, isLoggedIn, userProfile.loading])

  return {
    isLoading,
    enableChat,
  }
}

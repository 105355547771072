import {
  useAssetList,
  useDealList,
  useGlobalUltimateOwner,
  useLegalEntity,
} from '@gain/api/app/hooks'
import { Asset, AssetListItem, LegalEntity } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { useCallback, useMemo } from 'react'

export function useLinkedAssets(legalEntity: LegalEntity | undefined) {
  return useAssetList(
    useCallback(() => {
      if (legalEntity && legalEntity.assets.length) {
        return {
          filter: [
            listFilter<AssetListItem>(
              'id',
              '=',
              legalEntity.assets.map((asset) => asset.assetId)
            ),
          ],
        }
      }

      return null
    }, [legalEntity])
  )
}

export function useSubtitle(legalEntity: LegalEntity | undefined) {
  return useMemo(() => {
    if (legalEntity && legalEntity.previousNames && legalEntity.previousNames.length > 0) {
      return `Previously: ${legalEntity.previousNames.join(', ')}`
    }
    return undefined
  }, [legalEntity])
}

export function useUltimateOwner(legalEntity: LegalEntity | undefined) {
  const { data } = useGlobalUltimateOwner(
    useCallback(() => {
      if (!legalEntity?.globalUltimateOwnerId) {
        return null
      }
      return { globalUltimateOwnerId: legalEntity.globalUltimateOwnerId }
    }, [legalEntity])
  )

  return data
}

export function useUltimateOwnerUsingAsset(asset: Asset | undefined) {
  const { data } = useLegalEntity(
    asset?.legalEntities.find(({ financials }) => financials)?.legalEntityId ?? null
  )

  return useUltimateOwner(data)
}

export function useHasAssetDeals(assetId: number) {
  const assetDealHistoryDeals = useDealList({
    filter: [listFilter('linkedAssetId', '=', assetId)],
  })
  return useMemo(() => assetDealHistoryDeals.data.items.length !== 0, [assetDealHistoryDeals])
}

import { useTooltipOnEllipsis } from '@gain/components/typography'
import { CellValue } from '@gain/utils/table'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

import { tableClasses } from './table'

interface StyledTdProps {
  width?: number | string
  textAlign?: 'left' | 'right' | 'center' | 'justify'
  disableEllipsis?: boolean
  paddingLeft?: number
  paddingRight?: number
  divider?: boolean
}

type StyledTdProp = keyof StyledTdProps

const STYLED_TD_PROPS = new Array<StyledTdProp>(
  'width',
  'textAlign',
  'disableEllipsis',
  'paddingLeft',
  'paddingRight',
  'divider'
)

const StyledTd = styled('td', {
  shouldForwardProp: (prop) => !STYLED_TD_PROPS.includes(prop as StyledTdProp),
})<StyledTdProps>(
  ({ theme, width, textAlign = 'left', disableEllipsis, paddingLeft, paddingRight, divider }) => ({
    height: 38,
    boxSizing: 'border-box',
    padding: theme.spacing(0),

    minWidth: width,
    width: width,
    maxWidth: width,

    paddingLeft: theme.spacing(paddingLeft || 1),
    paddingRight: theme.spacing(paddingRight || 1),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },

    textAlign,
    overflow: 'hidden',

    ...(!disableEllipsis && {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),

    ...theme.typography.body2,

    position: 'relative',

    '&:last-of-type': {
      paddingRight: theme.spacing(3),
    },

    '&:first-of-type': {
      paddingLeft: theme.spacing(3),
    },

    [`&.${tableClasses.stickyCell}`]: {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      borderRight: `1px solid ${theme.palette.divider}`,
      boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.09)',
      clipPath: 'inset(0px -5px 0px 0px)',
    },

    ...(divider && {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),

      '&::after': {
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'block',
        position: 'absolute',
        content: '""',
        top: 4,
        left: 0,
        width: '100%',
      },

      '&:first-of-type::after': {
        left: theme.spacing(3),
        width: 'calc(100% - 24px)',
      },

      '&:last-of-type::after': {
        right: theme.spacing(3),
        width: 'calc(100% - 24px)',
      },
    }),
  })
)

export interface TableCellProps extends StyledTdProps {
  children: ReactElement | ReactNode | CellValue
  className: string | undefined
  sticky?: boolean
  divider?: boolean
}

export default function TableCell({ children, className, sticky, ...styledProps }: TableCellProps) {
  const tooltip = useTooltipOnEllipsis()

  if (typeof children === 'string' || typeof children === 'number') {
    return (
      <Tooltip
        open={tooltip.tooltipOpen}
        title={children || ''}
        disableInteractive>
        <StyledTd
          {...styledProps}
          className={clsx(className, {
            [tableClasses.stickyCell]: sticky,
          })}
          onMouseOut={tooltip.handleMouseOut}
          onMouseOver={tooltip.handleMouseOver}>
          {children}
        </StyledTd>
      </Tooltip>
    )
  }

  return (
    <StyledTd
      className={clsx(className, {
        [tableClasses.stickyCell]: sticky,
      })}
      disableEllipsis
      {...styledProps}>
      {children}
    </StyledTd>
  )
}

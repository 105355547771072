import { formatYear } from '@gain/utils/date'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

import { LatestFinancialsSparkLine, LineValue } from './latest-financials-spark-line.component'

export interface LatestFinancialsItemProps {
  label: string
  value: string
  year?: number | null
  lineValues?: LineValue[]
}

export default function LatestFinancialsVerticalItem({
  label,
  year,
  value,
  lineValues,
}: LatestFinancialsItemProps) {
  return (
    <Stack gap={0.5}>
      <Typography
        color={'text.secondary'}
        variant={'overline'}>
        {label} {year && formatYear(year, 'short')}
      </Typography>

      <Stack direction={'row'}>
        <Typography
          color={'text.primary'}
          variant={'overline'}>
          {value}
        </Typography>

        <LatestFinancialsSparkLine lineValues={lineValues} />
      </Stack>
    </Stack>
  )
}

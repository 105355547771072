import { AssetListItem } from '@gain/rpc/app-model'

import { Rating, RatingGroup } from '../rating-model'
import {
  ratingBuyAndBuild,
  ratingContracted,
  ratingConversion,
  ratingEbitda,
  ratingGrossMargin,
  ratingGrowth,
  ratingLeader,
  ratingMultinational,
  ratingNonCyclical,
  ratingOrganicGrowth,
  ratingOverall,
} from './rating-investment-criteria'
import {
  RatingInvestmentCriteriaKey,
  RatingInvestmentCriteriaKeyWithOverall,
  RatingKeys,
} from './rating-investment-criteria-model'

export const ratingInvestmentCriteriaMap: Record<
  RatingKeys<AssetListItem>,
  Rating<RatingInvestmentCriteriaKeyWithOverall>
> = {
  ratingGrowth,
  ratingOrganicGrowth,
  ratingGrossMargin,
  ratingEbitda,
  ratingConversion,
  ratingNonCyclical,
  ratingContracted,
  ratingLeader,
  ratingMultinational,
  ratingBuyAndBuild,
  ratingOverall,
}

export const ratingAssetCriteriaMap: Record<
  RatingKeys<AssetListItem>,
  Rating<RatingInvestmentCriteriaKeyWithOverall>
> = {
  ratingGrowth,
  ratingOrganicGrowth,
  ratingGrossMargin,
  ratingEbitda,
  ratingConversion,
  ratingNonCyclical,
  ratingContracted,
  ratingLeader,
  ratingMultinational,
  ratingBuyAndBuild,
  ratingOverall,
}

export const ratingInvestmentCriteriaList: Rating<RatingInvestmentCriteriaKey>[] = [
  ratingGrowth,
  ratingOrganicGrowth,
  ratingGrossMargin,
  ratingEbitda,
  ratingConversion,
  ratingNonCyclical,
  ratingContracted,
  ratingLeader,
  ratingMultinational,
  ratingBuyAndBuild,
]

export function findRatingInvestmentCriteria(
  key: RatingInvestmentCriteriaKeyWithOverall
): Rating<RatingInvestmentCriteriaKeyWithOverall> {
  const result = Object.values(ratingInvestmentCriteriaMap).find((rating) => rating.key === key)

  // This is always a programmer error
  if (!result) {
    throw new Error(`Rating not found for key: ${key}`)
  }

  return result
}

export const ratingInvestmentCriteriaGroups: RatingGroup<RatingInvestmentCriteriaKey>[] = [
  {
    label: 'Growth',
    ratings: [ratingGrowth, ratingOrganicGrowth],
  },
  {
    label: 'Profitability',
    ratings: [ratingGrossMargin, ratingEbitda, ratingConversion],
  },
  {
    label: 'Resilience',
    ratings: [ratingNonCyclical, ratingContracted],
  },
  {
    label: 'Profile',
    ratings: [ratingLeader, ratingMultinational, ratingBuyAndBuild],
  },
]

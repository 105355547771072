import { AssetFilterField } from '@app/features/asset/asset-filter-bar'
import { ListFilter } from '@gain/rpc/list-model'
import { IncludeStartsWith, PartialRecord } from '@gain/utils/typescript'

import {
  AdvisorListItem,
  AssetListItem,
  CustomAssetQueryList,
  CustomAssetQueryListItem,
  DealListItem,
  InvestorListItem,
  InvestorStrategyListItem,
  ListArgs,
  ListCounts,
} from './lib/rpc-app-model'

// The CustomAssetQueryListItem returns strings that are more strictly typed in the frontend.
export interface BookmarksFilteredList extends Omit<CustomAssetQueryListItem, 'filters'> {
  filters: ListFilter<AssetListItem>[]
}

export interface BookmarksFilteredLists extends Omit<CustomAssetQueryList, 'items'> {
  items: BookmarksFilteredList[]
  counts: ListCounts
  args: ListArgs
}

export interface FilterableInvestorListItem extends InvestorListItem {
  'asset.ebitdaEur': AssetListItem['ebitdaEur']
  'asset.tagIds': AssetListItem['tagIds']

  'asset.ratingGrowth': AssetListItem['ratingGrowth']
  'asset.ratingOrganicGrowth': AssetListItem['ratingOrganicGrowth']
  'asset.ratingGrossMargin': AssetListItem['ratingGrossMargin']
  'asset.ratingEbitda': AssetListItem['ratingEbitda']
  'asset.ratingConversion': AssetListItem['ratingConversion']
  'asset.ratingNonCyclical': AssetListItem['ratingNonCyclical']
  'asset.ratingContracted': AssetListItem['ratingContracted']
  'asset.ratingLeader': AssetListItem['ratingLeader']
  'asset.ratingMultinational': AssetListItem['ratingMultinational']
  'asset.ratingBuyAndBuild': AssetListItem['ratingBuyAndBuild']
}

export const FILTERABLE_INVESTOR_LIST_ITEM_TO_ASSET_MAP: PartialRecord<
  IncludeStartsWith<keyof InvestorListItem, 'asset.'>,
  AssetFilterField
> = {
  assetRegions: 'region',
  assetTagIds: 'tagIds',
  assetSubsectors: 'subsector',
  assetEbitdasEur: 'ebitdaEur',
  'asset.tagIds': 'tagIds',

  // Old ones (For investor strategy list item)
  'asset.region': 'region',
  'asset.subsector': 'subsector',
  'asset.ebitdaEur': 'ebitdaEur',
  'asset.ebitEur': 'ebitEur',
  'asset.ratingGrowth': 'ratingGrowth',
  'asset.ratingOrganicGrowth': 'ratingOrganicGrowth',
  'asset.ratingGrossMargin': 'ratingGrossMargin',
  'asset.ratingEbitda': 'ratingEbitda',
  'asset.ratingConversion': 'ratingConversion',
  'asset.ratingNonCyclical': 'ratingNonCyclical',
  'asset.ratingContracted': 'ratingContracted',
  'asset.ratingLeader': 'ratingLeader',
  'asset.ratingMultinational': 'ratingMultinational',
  'asset.ratingBuyAndBuild': 'ratingBuyAndBuild',
  'asset.ownership': 'ownership',
  'asset.esgOutperformer': 'esgOutperformer',
}

export interface FilterableInvestorStrategyListItem extends InvestorStrategyListItem {
  'asset.region': AssetListItem['region']
  'asset.subsector': AssetListItem['subsector']
  'asset.ebitdaEur': AssetListItem['ebitdaEur']

  'asset.ratingGrowth': AssetListItem['ratingGrowth']
  'asset.ratingOrganicGrowth': AssetListItem['ratingOrganicGrowth']
  'asset.ratingGrossMargin': AssetListItem['ratingGrossMargin']
  'asset.ratingEbitda': AssetListItem['ratingEbitda']
  'asset.ratingConversion': AssetListItem['ratingConversion']
  'asset.ratingNonCyclical': AssetListItem['ratingNonCyclical']
  'asset.ratingContracted': AssetListItem['ratingContracted']
  'asset.ratingLeader': AssetListItem['ratingLeader']
  'asset.ratingMultinational': AssetListItem['ratingMultinational']
  'asset.ratingBuyAndBuild': AssetListItem['ratingBuyAndBuild']

  'asset.ownership': AssetListItem['ownership']

  'asset.esgOutperformer': AssetListItem['esgOutperformer']
}

export interface FilterableAdvisorListItem extends AdvisorListItem {
  'deal.tagIds': DealListItem['tagIds']

  'deal.investorIds': DealListItem['investorIds']
  'deal.buyerInvestorIds': DealListItem['buyerInvestorIds']
  'deal.sellerInvestorIds': DealListItem['sellerInvestorIds']
}

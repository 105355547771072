import { formLabelClasses } from '@mui/material/FormLabel'
import { Components, Theme } from '@mui/material/styles'

export const MuiFormLabel = (theme: Theme): Components['MuiFormLabel'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.text.primary,
      marginTop: 7,
      marginBottom: 7,
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
      lineHeight: theme.typography.subtitle2.lineHeight,
      [`&.${formLabelClasses.focused}`]: {
        color: theme.palette.text.primary,
      },
    },
  },
})

import { DealStatus } from '@gain/rpc/shared-model'
import { isTruthy, valueToEnum } from '@gain/utils/common'

export function parseDealStatus(status: DealStatus | string | null | undefined): DealStatus | null {
  if (!isTruthy(status)) {
    return null
  }

  if (typeof status === 'string') {
    return valueToEnum(DealStatus, status.toLowerCase()) || null
  }

  return status
}

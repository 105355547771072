import { DownloadIcon } from '@gain/components/icons'
import { AssetListItem, Investor } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'
import React from 'react'

import InvestorDownloadsDialog from './investor-downloads-dialog'

export interface InvestorDownloadsButtonProps {
  investor: Investor
  assets: AssetListItem[]
}

export default function InvestorDownloadsButton({
  investor,
  assets,
}: InvestorDownloadsButtonProps) {
  const [dialogOpen, handleShowDialog, handleHideDialog] = useDialogState()

  return (
    <>
      <InvestorDownloadsDialog
        assets={assets}
        investor={investor}
        onClose={handleHideDialog}
        open={dialogOpen}
      />

      <Hidden xsDown>
        <Button
          color={'primary'}
          onClick={handleShowDialog}
          startIcon={<DownloadIcon />}
          variant={'contained'}>
          Downloads
        </Button>
      </Hidden>
    </>
  )
}

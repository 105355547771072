import Container, { ContainerProps } from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

export const StyledContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  position: 'relative',
  height: `calc(100% - ${theme.spacing(6)})`,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(3, 0),
  },

  [theme.breakpoints.only('xs')]: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

export interface MainContainerProps {
  /**
   * If `true` render in full width
   */
  fluid?: boolean
  /**
   * Allow for setting a custom max width of the container
   */
  maxWidth?: number
  className?: string
  children?: ReactNode
}

export default function MainContainer({
  children,
  fluid = false,
  maxWidth,
  className,
}: MainContainerProps) {
  return (
    <StyledContainer
      className={className}
      maxWidth={fluid || maxWidth ? false : undefined}
      sx={maxWidth ? { maxWidth } : undefined}>
      {children}
    </StyledContainer>
  )
}

import { formatCountry } from './format-scope-and-countries'
import { isUS } from './region-checks'

export function formatCountryCity(
  countryCode?: string | null,
  city?: string | null,
  region?: string | null
): string {
  return (
    countryCode && isUS(countryCode)
      ? [city, region ? `${region} (${countryCode})` : formatCountry(countryCode)]
      : [city, formatCountry(countryCode)]
  )
    .filter(Boolean)
    .join(', ')
}

import React from 'react'

import { NewsFeedItemArticle } from './news-feed-item-article'
import NewsFeedItemHeader from './news-feed-item-header'
import { NewsFeedItemType } from './use-news-feed-items'

export interface NewsFeedItemProps {
  item: NewsFeedItemType
}

export default function NewsFeedItem({ item }: NewsFeedItemProps) {
  switch (item.type) {
    case 'header':
      return <NewsFeedItemHeader title={item.data} />
    case 'article':
      return <NewsFeedItemArticle article={item.data} />
  }
}

import { useCurrencyListItems } from '@gain/api/app/hooks'
import { CurrencyListItem } from '@gain/rpc/app-model'
import { currencyFullName, formatCurrency, useConvertCurrencyCallback } from '@gain/utils/currency'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns/format'
import React, { useEffect, useMemo, useState } from 'react'
import { FieldInputProps } from 'react-final-form'

function formatConversion(
  updatedAt: string | null,
  sortedCurrencies: CurrencyListItem[],
  convertCurrency: (
    v: number | null | undefined,
    from: string | null | undefined,
    to: string | null | undefined
  ) => number | null,
  selectedCurrency: string,
  selectableCurrency: CurrencyListItem
): string {
  if (updatedAt && selectedCurrency === selectableCurrency.name) {
    const date = new Date(updatedAt)
    return `FX rates last updated on ${format(date, 'd MMMM, yyyy')}`
  }
  const conversion = convertCurrency(1, selectedCurrency, selectableCurrency.name)
  if (!conversion) {
    return ''
  }
  const selected = sortedCurrencies.find(({ name }) => name === selectedCurrency)
  if (!selected) {
    return ''
  }

  const from = formatCurrency(1, { currency: selected, format: 'raw', round: 0 })
  const to = formatCurrency(conversion, { currency: selectableCurrency, format: 'raw', round: 4 })
  return `${from} = ${to}`
}

const sortCurrencies = (currencies: CurrencyListItem[], name: string): CurrencyListItem[] => {
  return [...currencies].sort((a, b) => {
    if (a.name === name) {
      return -1
    }
    if (b.name === name) {
      return 1
    }
    return a.title.localeCompare(b.title)
  })
}

interface UpdateAccountCurrencyProps {
  currencyName: string
  input: FieldInputProps<object>
  mode: string
}

export default function UpdateAccountCurrency({
  currencyName,
  input,
  mode,
}: UpdateAccountCurrencyProps) {
  const convertCurrency = useConvertCurrencyCallback()
  const currencyList = useCurrencyListItems()
  const [selectedCurrency, setSelectedCurrency] = useState(currencyName)
  const handleCurrencyChange = (event) => setSelectedCurrency(event.target.value)
  const [sortedCurrencies, setSortedCurrencies] = useState<CurrencyListItem[]>([])

  const allowedCurrencies = useMemo(() => {
    if (currencyList?.data.items) {
      return currencyList.data.items.filter(({ allowUserSelect }) => allowUserSelect)
    }
    return []
  }, [currencyList?.data.items])

  const currencyUpdatedAt = useMemo(() => {
    const item = allowedCurrencies.find(({ updatedAt }) => updatedAt)
    if (item) {
      return item.updatedAt
    }
    return ''
  }, [allowedCurrencies])

  useEffect(() => {
    setSortedCurrencies(sortCurrencies(allowedCurrencies, selectedCurrency))
  }, [allowedCurrencies, selectedCurrency])

  if (!sortedCurrencies.length) {
    return null
  }

  return (
    <Select
      inputProps={{
        ...input,
        readOnly: mode === 'view',
      }}
      label={'Display currency'}
      onChange={handleCurrencyChange}
      readOnly={mode === 'view'}
      variant={'outlined'}
      fullWidth>
      {sortedCurrencies.map((selectableCurrency) => (
        <MenuItem
          key={selectableCurrency.name}
          value={selectableCurrency.name}>
          <Stack
            alignItems={'center'}
            direction={'row'}
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}>
            {currencyFullName(selectableCurrency)}
            <Typography
              color={'text.secondary'}
              variant={'body2'}>
              {formatConversion(
                currencyUpdatedAt,
                sortedCurrencies,
                convertCurrency,
                selectedCurrency,
                selectableCurrency
              )}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  )
}

import Paper, { PaperProps } from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const SettingContainer = styled((props: PaperProps) => (
  <Paper
    variant={'outlined'}
    {...props}
  />
))(({ theme }) => ({
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
  padding: theme.spacing(2),
}))

import { ChevronRightIcon } from '@gain/components/icons'
import { useResizeObserver } from '@gain/utils/dom'
import Alert, { alertClasses } from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useRef, useState } from 'react'

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 2),

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& button': {
      width: '100%',
    },
  },
}))

const StyledRightButton = styled(Button)(({ theme }) => ({
  marginInline: theme.spacing(1),
}))

const StyledAlert = styled(Alert)<{ layout: 'row' | 'column' }>(({ layout, theme }) => ({
  padding: theme.spacing(0.5, 0),
  [`& .${alertClasses.message}`]: {
    flex: 1,
    display: 'flex',
    ...(layout === 'row' && {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    }),
    ...(layout === 'column' && {
      alignItems: 'start',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: theme.spacing(0.5, 0),
      },
    }),
  },
}))

export interface ShareFeedbackBarProps {
  onOpenFeedbackDialog: () => void
  onOpenInfoDialog: () => void
}

export default function ShareFeedbackBar({
  onOpenFeedbackDialog,
  onOpenInfoDialog,
}: ShareFeedbackBarProps) {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const ref = useRef<HTMLDivElement>(null)
  const [layout, setLayout] = useState<'column' | 'row'>('row')

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    const nextLayout = ref.current.getBoundingClientRect().width < 760 ? 'column' : 'row'
    setLayout(nextLayout)
  }, [])

  useResizeObserver(ref, handleResize)

  return (
    <StyledAlert
      ref={ref}
      icon={false}
      layout={layout}
      severity={'info'}>
      <StyledStack
        alignItems={'center'}
        direction={isSmUp ? 'row' : layout}
        gap={1}>
        <span>Do you have any comments or additional insights?</span>
        <Button
          color={'primary'}
          onClick={onOpenFeedbackDialog}
          variant={'text'}>
          Share feedback
        </Button>
      </StyledStack>

      {isSmUp && (
        <StyledRightButton
          color={'info'}
          endIcon={<ChevronRightIcon color={'inherit'} />}
          onClick={onOpenInfoDialog}>
          Why would I share my insights?
        </StyledRightButton>
      )}
    </StyledAlert>
  )
}

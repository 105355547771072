import { styled } from '@mui/material/styles'

const FilterItemContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.75, 0, 1.5, 0),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),
}))

export default FilterItemContainer

import { DealSideShare } from '@gain/rpc/shared-model'

// Index determines importance ordered from least to most important
const DEAL_SHARE_IMPORTANCE = [
  DealSideShare.Minority,
  DealSideShare.SharedMajority,
  DealSideShare.Majority,
]

export function compareDealShareDesc(a: DealSideShare | undefined, b: DealSideShare | undefined) {
  if (typeof a !== 'string' && typeof b === 'string') {
    return 1
  } else if (typeof a === 'string' && typeof b !== 'string') {
    return -1
  } else if (typeof a === 'string' && typeof b === 'string') {
    const aScore = DEAL_SHARE_IMPORTANCE.indexOf(a)
    const bScore = DEAL_SHARE_IMPORTANCE.indexOf(b)

    return bScore - aScore
  }
  return 0
}

import { useTooltipOnEllipsis } from '@gain/components/typography'
import Badge, { badgeClasses } from '@mui/material/Badge'
import ListItem, { listItemClasses } from '@mui/material/ListItem'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import React, { memo, ReactElement } from 'react'

import { useMenuContext } from './menu-provider'

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  [`& .${listItemClasses.secondaryAction}`]: {
    ...(open && {
      [theme.breakpoints.up('md')]: {
        opacity: 1,
        transition: theme.transitions.create(['opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    }),
    ...(!open && {
      [theme.breakpoints.up('md')]: {
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    }),
  },
}))

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (propName) => !['open', 'hasSecondaryAction'].includes(propName as string),
})<{ open: boolean; hasSecondaryAction: boolean }>(({ open, hasSecondaryAction }) => ({
  ...(hasSecondaryAction && {
    paddingRight: '32px !important',
  }),
  ...(open && {
    overflow: 'hidden',
  }),
  ...(!open && {
    maxWidth: 32,
  }),
}))

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  [`&.${listItemTextClasses.primary}`]: {
    '&::after': {
      content: '""',
      display: 'block',
    },
  },
  ...(open && {
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create(['max-width', 'margin-right', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  ...(!open && {
    [theme.breakpoints.up('md')]: {
      maxWidth: 0,
      overflow: 'hidden',
      opacity: 0,
      transition: theme.transitions.create(['max-width', 'margin-right', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      '&&': {
        marginRight: 0,
      },
    },
  }),
}))

const StyledBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    top: -4,
    right: 12,
  },
})

export type MenuListItemProps = ListItemButtonProps & {
  component?: React.ElementType
  label: string
  icon?: ReactElement
  secondaryAction?: ReactElement | false
  className?: string
  classNameButton?: string
  iconBadge?: number | false | null
}

function MenuListItem({
  label,
  icon,
  secondaryAction,
  className,
  classNameButton,
  iconBadge,
  component,
  ...props
}: MenuListItemProps) {
  const [isOpen] = useMenuContext()
  const tooltip = useTooltipOnEllipsis()

  return (
    <StyledListItem
      className={className}
      open={isOpen}
      secondaryAction={secondaryAction}
      disablePadding>
      <StyledListItemButton
        as={component}
        className={classNameButton}
        hasSecondaryAction={!!secondaryAction}
        open={isOpen}
        {...props}>
        {icon && (
          <StyledBadge
            badgeContent={iconBadge || 0}
            color={'error'}
            max={9}>
            <Tooltip
              placement={'right'}
              title={!isOpen ? label : ''}
              disableInteractive>
              <ListItemIcon>{icon}</ListItemIcon>
            </Tooltip>
          </StyledBadge>
        )}
        <Tooltip
          open={tooltip.tooltipOpen}
          title={label}
          disableInteractive>
          <StyledListItemText
            open={isOpen}
            primary={label}
            primaryTypographyProps={{
              onMouseOver: tooltip.handleMouseOver,
              onMouseOut: tooltip.handleMouseOut,
              noWrap: true,
            }}
          />
        </Tooltip>
      </StyledListItemButton>
    </StyledListItem>
  )
}

export default memo(MenuListItem)

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, {
  MouseEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { CheckboxAccordion } from './checkbox-accordion/checkbox-accordion.component'
import { CheckboxAccordionDetails } from './checkbox-accordion/checkbox-accordion-details.component'
import { CheckboxAccordionSummary } from './checkbox-accordion/checkbox-accordion-summary.component'
import { CheckboxListValueType } from './checkbox-list'
import { useCheckboxList } from './checkbox-list-context'
import { CheckboxListOptionProps } from './checkbox-list-option'

export interface CheckboxListGroupProps<ValueType extends CheckboxListValueType> {
  label: string
  children: Array<ReactElement<CheckboxListOptionProps<ValueType>>>
  values: ValueType[]
  expanded?: boolean
}

export default function CheckboxListGroup<ValueType extends CheckboxListValueType>({
  label,
  children,
  values,
  expanded = false,
}: CheckboxListGroupProps<ValueType>) {
  const [isExpanded, setIsExpanded] = useState(expanded)
  const context = useCheckboxList()
  const checkedValues = values.filter(
    (value) => context.value !== null && context.value.includes(value)
  )

  const indeterminate = checkedValues.length > 0 && checkedValues.length < values.length
  const checked = checkedValues.length === values.length

  // When clicking the group checkbox toggle all values in the group.
  const toggleAll = useCallback(
    (event: SyntheticEvent, newChecked: boolean) => {
      event.stopPropagation()
      if (newChecked) {
        context.addCheckboxValues(values.filter((value) => !checkedValues.includes(value)))
      } else {
        context.removeCheckboxValues(checkedValues)
      }
    },
    [checkedValues, context, values]
  )

  // Toggle expanded state when clicking the group row.
  const handleClickGroup = (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setIsExpanded((prev) => !prev)
  }

  // Prevent the group accordion from expanding/collapsing when clicking the group's checkbox.
  const handleClickCheckbox = (event: MouseEvent) => {
    event.stopPropagation()
  }

  // Keep isExpanded in sync with expanded prop changes
  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  return (
    <CheckboxAccordion
      expanded={isExpanded}
      onClick={handleClickGroup}>
      <CheckboxAccordionSummary>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
            />
          }
          label={label}
          onChange={toggleAll}
          onClick={handleClickCheckbox}
        />
      </CheckboxAccordionSummary>
      <CheckboxAccordionDetails inset>{children}</CheckboxAccordionDetails>
    </CheckboxAccordion>
  )
}

import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const CheckboxIcon = styled('span')({
  backgroundColor: '#FFFFFF',
  borderRadius: 4,
  '&:before': {
    borderRadius: 4,
    border: `1px solid ${grey['400']}`,
    display: 'block',
    width: 16,
    height: 16,
    content: '""',
  },
})

import { MutableRefObject, useCallback } from 'react'

import { getAbsoluteOffsetTop } from './get-absolute-offset-top'

function getScrollTop(index: number, total: number, element: HTMLElement, topMargin: number) {
  if (index === 0) {
    // If it's the first element, scroll to the top
    return 0
  } else if (index === total - 1) {
    // If it's the last element, scroll to the bottom
    return document.body.scrollHeight
  } else {
    // If the element is in the middle we center it on the screen
    return (
      getAbsoluteOffsetTop(element) -
      window.innerHeight / 2 +
      element.getBoundingClientRect().height / 2 -
      topMargin / 2
    )
  }
}

export function useScrollToSectionCallback(
  sectionsRef: MutableRefObject<Array<HTMLElement | null>>,
  activeSectionIndex: number,
  topMargin: number
) {
  return useCallback(
    (index: number) => {
      if (activeSectionIndex === index) {
        return
      }

      const el = sectionsRef.current[index]
      if (el) {
        window.scrollTo({
          top: getScrollTop(index, sectionsRef.current.length, el, topMargin),
          behavior: 'smooth',
        })
      }
    },
    [activeSectionIndex, sectionsRef, topMargin]
  )
}

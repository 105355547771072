import { BadgeCheckIcon, FlagIcon, GlobeIcon, IndustryIcon } from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { compareNumberDesc } from '@gain/utils/common'
import { formatCountry } from '@gain/utils/countries'
import { formatSubsector } from '@gain/utils/sector'

import { ChartGroup, ChartGroupByConfig } from '../../../common/chart/chart-groups'
import { formatAssetOwnershipType } from '../../asset/asset-ownership'

export enum AssetChartGroupType {
  Ownership = 'ownership',
  Sector = 'sector',
  Country = 'country',
  State = 'state',
}

function totalRevenue(assets: AssetListItem[]) {
  return assets.reduce((acc, asset) => (asset.revenueEur || 0) + acc, 0)
}

export function compareByRevenueDesc(a: ChartGroup<AssetListItem>, b: ChartGroup<AssetListItem>) {
  return compareNumberDesc(totalRevenue(a.items), totalRevenue(b.items))
}

export const ASSET_CHART_GROUPS = [
  {
    id: AssetChartGroupType.Ownership,
    label: 'Ownership',
    getValue: (asset) => asset.ownership,
    getLabel: (value) => formatAssetOwnershipType(value),
    compare: compareByRevenueDesc,
    icon: BadgeCheckIcon,
  } as ChartGroupByConfig<AssetListItem, AssetOwnershipType | null>,
  {
    id: AssetChartGroupType.Sector,
    label: 'Sector',
    getValue: (asset) => asset.subsector || '',
    getLabel: (value) => (value && formatSubsector(value)) || 'Unknown',
    compare: compareByRevenueDesc,
    icon: IndustryIcon,
  } as ChartGroupByConfig<AssetListItem, string>,
] as ChartGroupByConfig<AssetListItem>[]

export const ASSET_CHART_GROUP_COUNTRY = {
  id: AssetChartGroupType.Country,
  label: 'Country',
  getValue: (asset) => asset.region || '',
  getLabel: (value) => (value ? formatCountry(value) : 'Unknown'),
  compare: compareByRevenueDesc,
  showDividerAbove: true,
  icon: GlobeIcon,
} as ChartGroupByConfig<AssetListItem, string>

export const ASSET_CHART_GROUP_US_STATES = {
  id: AssetChartGroupType.State,
  label: 'US States',
  getValue: (asset) => {
    if (asset.region === 'US') {
      return asset.headquartersRegion || 'Unknown State'
    }
    return null
  },
  getLabel: (value) => value || 'Rest of the World',
  compare: compareByRevenueDesc,
  icon: FlagIcon,
} as ChartGroupByConfig<AssetListItem, string | null>

export function getDefaultAssetChartGroupType(assets: AssetListItem[]) {
  const assetsInUS = assets.filter((asset) => asset.region === 'US')

  return assetsInUS.length / assets.length > 0.7
    ? AssetChartGroupType.State
    : AssetChartGroupType.Country
}

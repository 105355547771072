import { ReactElement, ReactNode, useMemo } from 'react'

import { CellValue, RowType } from './table-model'

export interface RenderCellParams<Row extends RowType, Field extends keyof Row> {
  field: Field
  rowIndex: number
  value: Row[Field]
  row: Row
  formattedValue: CellValue
}

export type RenderCellReturnType = ReactElement | ReactNode | CellValue

export type RenderCellFn<Row extends RowType, Field extends keyof Row> = (
  params: RenderCellParams<Row, Field>
) => ReactElement | ReactNode | CellValue

export function renderCell<Row extends RowType, Field extends keyof Row>(
  field: Field,
  row: Row,
  rowIndex: number,
  formattedValue: CellValue,
  renderCellFn?: RenderCellFn<Row, Field>
): RenderCellReturnType {
  if (renderCellFn) {
    return renderCellFn({
      field,
      row,
      value: row[field],
      rowIndex,
      formattedValue,
    })
  }

  return formattedValue
}

export function useRenderCell<Row extends RowType, Field extends keyof Row>(
  field: Field,
  row: Row,
  rowIndex: number,
  formattedValue: CellValue,
  renderCellFn?: RenderCellFn<Row, Field>
): RenderCellReturnType {
  return useMemo(() => {
    return renderCell(field, row, rowIndex, formattedValue, renderCellFn)
  }, [field, row, rowIndex, formattedValue, renderCellFn])
}

import { ChevronDownIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'
import { MouseEvent } from 'react'

const StyledSelect = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 3,
  color: theme.palette.text.primary,
  cursor: 'pointer',
  ...theme.typography.overline,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export interface FilterSelectButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void
}

export default function FilterSelectButton({ onClick }: FilterSelectButtonProps) {
  return (
    <StyledSelect onClick={onClick}>
      select
      <ChevronDownIcon
        color={'inherit'}
        fontSize={'inherit'}
      />
    </StyledSelect>
  )
}

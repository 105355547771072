import { AssetListItem } from '@gain/rpc/app-model'
import { RenderCellParams } from '@gain/utils/table'
import React from 'react'

import { InvestorListItemChip } from '../../investor/investor-list-item-chip/investor-list-item-chip'

export function AssetListItemTableCellMajorityOwnerName({
  row: { majorityOwnerId, majorityOwnerName },
}: RenderCellParams<AssetListItem, 'majorityOwnerName'>) {
  return majorityOwnerId !== null ? (
    <InvestorListItemChip
      id={majorityOwnerId}
      label={majorityOwnerName || ''}
    />
  ) : (
    '-'
  )
}

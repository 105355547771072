import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import { Components } from '@mui/material/styles'

export const MuiList = (): Components['MuiList'] => ({
  styleOverrides: {
    root: {},
    dense: {
      [`& .${listItemSecondaryActionClasses.root}`]: {
        fontSize: 16,
      },
    },
  },
})

import Typography from '@gain/components/typography'
import { AssetFinancialPrediction, AssetRating } from '@gain/rpc/app-model'
import { useElementWidthEffect } from '@gain/utils/dom'
import { getValueCellWidth } from '@gain/utils/table'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ButtonScroll from '../../../../common/button-scroll'
import MenuExpandIcon from '../../../../common/menu-expand-icon'
import {
  formatFinancialPredictionValue,
  useFinancialPredictionGroups,
} from './valuation-estimates-util'

const tableValuationEstimatesClasses = generateUtilityClasses('TableValuationEstimates', [
  'root',
  'scrollable',
  'scrollButtonLeft',
  'scrollButtonRight',
  'tableContainer',
  'table',
  'headSection',
  'cell',
  'row',
  'legendCell',
  'valueCell',
  'dividerCell',
  'groupSection',
  'groupTypeMultiple',
  'groupItemSection',
])

const LEGEND_CELL_WIDTH = 168
const VALUE_CELL_MIN_WIDTH = 88
const VALUE_CELL_WIDTH_VAR = '--table-valuation-estimates-value-cell-width'

const StyledRoot = styled('div')({
  position: 'relative',
})

const StyledTableContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  [`&.${tableValuationEstimatesClasses.scrollable}`]: {
    [`& .${tableValuationEstimatesClasses.table}`]: {
      width: 'unset',
    },
    [`& .${tableValuationEstimatesClasses.legendCell}`]: {
      borderRight: `1px solid ${theme.palette.divider}`,
      boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.09)',
      clipPath: 'inset(0px -15px 0px 0px)',
    },
  },
}))

const StyledTable = styled('div')({
  width: '100%',
  display: 'inline-flex',
  flexDirection: 'column',
})

const StyledTableSection = styled('div')({})

const StyledRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
})

const StyledCell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
  color: theme.palette.text.primary,
  ...theme.typography.body2,
  padding: theme.spacing(0, 1),
  '&:first-child': {
    paddingLeft: theme.spacing(3),
  },
  '&:last-child': {
    paddingRight: theme.spacing(3),
  },
  whiteSpace: 'nowrap',

  [`&.${tableValuationEstimatesClasses.legendCell}`]: {
    position: 'sticky',
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: `0 0 ${LEGEND_CELL_WIDTH}px`,
    minWidth: LEGEND_CELL_WIDTH,
    width: LEGEND_CELL_WIDTH,
    textAlign: 'left',
    zIndex: 4,
    ...theme.typography.subtitle2,
  },

  [`&.${tableValuationEstimatesClasses.valueCell}`]: {
    minWidth: `calc(var(${VALUE_CELL_WIDTH_VAR}, ${VALUE_CELL_MIN_WIDTH}) * 1px)`,
  },
}))

const StyledYearCell = styled(StyledCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',
}))

const StyledHeadSection = styled(StyledTableSection)(({ theme }) => ({
  [`& .${tableValuationEstimatesClasses.cell}`]: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.common.white,
  },
}))

const StyledGroupSection = styled(StyledTableSection)(({ theme }) => ({
  zIndex: 3,
  [`& .${tableValuationEstimatesClasses.cell}`]: {
    height: 56,
    minHeight: 56,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`&.${tableValuationEstimatesClasses.groupTypeMultiple} .${tableValuationEstimatesClasses.valueCell}`]:
    {
      color: theme.palette.text.secondary,
    },
}))

const StyledGroupItemSection = styled(StyledTableSection)(({ theme }) => ({
  [`& > .${tableValuationEstimatesClasses.row} > .${tableValuationEstimatesClasses.legendCell}`]: {
    zIndex: 2,
    ...theme.typography.body2,
  },
  [`& .${tableValuationEstimatesClasses.cell}`]: {
    backgroundColor: theme.palette.background.default,
    height: 30,
    minHeight: 30,
  },
  [`& .${tableValuationEstimatesClasses.row}:first-child > .${tableValuationEstimatesClasses.cell}`]:
    {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(0.5),
    },
  [`& .${tableValuationEstimatesClasses.row}:last-child > .${tableValuationEstimatesClasses.cell}`]:
    {
      paddingBottom: theme.spacing(0.5),
      ...theme.typography.subtitle2,
    },

  [`& .${tableValuationEstimatesClasses.dividerCell}`]: {
    display: 'block',
    height: 'auto',
    minHeight: 'auto',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    [`&.${tableValuationEstimatesClasses.legendCell}`]: {
      paddingRight: 0,
    },
    [`&.${tableValuationEstimatesClasses.valueCell}`]: {
      paddingLeft: 0,
    },
  },
}))

const StyledCollapse = styled(Collapse)({
  zIndex: 1,
  overflow: 'clip',
})

function GroupItemDivider() {
  return (
    <StyledRow className={tableValuationEstimatesClasses.row}>
      <StyledCell
        className={clsx(
          tableValuationEstimatesClasses.legendCell,
          tableValuationEstimatesClasses.cell,
          tableValuationEstimatesClasses.dividerCell
        )}>
        <Divider />
      </StyledCell>
      <StyledCell
        className={clsx(
          tableValuationEstimatesClasses.valueCell,
          tableValuationEstimatesClasses.cell,
          tableValuationEstimatesClasses.dividerCell
        )}>
        <Divider />
      </StyledCell>
    </StyledRow>
  )
}

function useHandleScrollTo(tableContainerRef: RefObject<HTMLDivElement>) {
  return useCallback(
    (direction: 'left' | 'right') => () => {
      if (tableContainerRef.current) {
        const availableWidth =
          tableContainerRef.current.getBoundingClientRect().width - LEGEND_CELL_WIDTH

        const offset =
          direction === 'left'
            ? tableContainerRef.current.scrollLeft - availableWidth
            : tableContainerRef.current.scrollLeft + availableWidth

        tableContainerRef.current.scrollTo({
          left: offset,
          behavior: 'smooth',
        })
      }
    },
    [tableContainerRef]
  )
}

function useScroll(tableContainerRef: RefObject<HTMLDivElement>) {
  const [isScrollStart, setIsScrollStart] = useState(true)
  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const handleScroll = useCallback(() => {
    const element = tableContainerRef.current

    if (!element) {
      return
    }

    setIsScrollStart((prev) => (prev === (element.scrollLeft === 0) ? prev : !prev))
    setIsScrollEnd((prev) =>
      prev === element.scrollLeft + element.clientWidth >= element.scrollWidth ? prev : !prev
    )
  }, [tableContainerRef])
  return [isScrollStart, isScrollEnd, handleScroll] as const
}

export interface TableValuationEstimatesProps {
  dataCurrency?: string | null
  rating: AssetRating | null
  financialPredictions: AssetFinancialPrediction[]
  predictedExitYear: number | null
}

export default function TableValuationEstimates({
  dataCurrency,
  rating,
  financialPredictions,
  predictedExitYear,
}: TableValuationEstimatesProps) {
  const financialPredictionGroups = useFinancialPredictionGroups(dataCurrency)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLDivElement>(null)

  const visiblePredictions = useMemo(
    () =>
      financialPredictions
        .filter((prediction) => prediction.year > new Date().getFullYear())
        .slice(0, 5),
    [financialPredictions]
  )

  const [isOverflowing, setIsOverflowing] = useState(false)
  const [expandedGroupIndex, setExpandedGroupIndex] = useState<number>(-1)

  const handleUpdateValueCellWidth = useCallback(
    (width: number) => {
      const availableWidth = width - LEGEND_CELL_WIDTH
      const cellWidth = getValueCellWidth(
        availableWidth,
        visiblePredictions.length,
        VALUE_CELL_MIN_WIDTH,
        undefined,
        isXs
      )
      document.documentElement.style.setProperty(VALUE_CELL_WIDTH_VAR, cellWidth.toString(10))
    },
    [isXs, visiblePredictions.length]
  )

  useEffect(() => {
    return () => {
      document.documentElement.style.removeProperty(VALUE_CELL_WIDTH_VAR)
    }
  }, [])

  const handleWidthChange = useCallback(
    (width: number) => {
      if (!tableContainerRef.current) {
        return
      }
      setIsOverflowing(
        tableContainerRef.current.scrollWidth > tableContainerRef.current.clientWidth
      )
      handleUpdateValueCellWidth(width)
    },
    [handleUpdateValueCellWidth]
  )
  useElementWidthEffect(tableContainerRef, handleWidthChange)

  const handleScrollTo = useHandleScrollTo(tableContainerRef)
  const [isScrollStart, isScrollEnd, handleScroll] = useScroll(tableContainerRef)

  return (
    <StyledRoot className={tableValuationEstimatesClasses.root}>
      {!isXs && isOverflowing && (
        <>
          <Fade in={!isScrollStart}>
            <ButtonScroll
              className={tableValuationEstimatesClasses.scrollButtonLeft}
              direction={'left'}
              onClick={handleScrollTo('left')}
              sx={{ position: 'absolute', top: 0, left: LEGEND_CELL_WIDTH }}
            />
          </Fade>
          <Fade in={!isScrollEnd}>
            <ButtonScroll
              direction={'right'}
              onClick={handleScrollTo('right')}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            />
          </Fade>
        </>
      )}

      <StyledTableContainer
        ref={tableContainerRef}
        className={clsx(tableValuationEstimatesClasses.tableContainer, {
          [tableValuationEstimatesClasses.scrollable]: isOverflowing,
        })}
        onScroll={handleScroll}>
        <StyledTable
          ref={tableRef}
          className={tableValuationEstimatesClasses.table}>
          <StyledHeadSection className={tableValuationEstimatesClasses.headSection}>
            <StyledRow className={tableValuationEstimatesClasses.row}>
              <StyledCell
                className={clsx(
                  tableValuationEstimatesClasses.cell,
                  tableValuationEstimatesClasses.legendCell
                )}>
                &nbsp;
              </StyledCell>
              {visiblePredictions.map((prediction) => (
                <StyledYearCell
                  key={prediction.id}
                  className={clsx(
                    tableValuationEstimatesClasses.cell,
                    tableValuationEstimatesClasses.valueCell
                  )}>
                  {prediction.year}
                  {prediction.year === predictedExitYear && (
                    <Typography
                      color={'info.main'}
                      variant={'caption'}>
                      Predicted exit
                    </Typography>
                  )}
                </StyledYearCell>
              ))}
            </StyledRow>
          </StyledHeadSection>

          {financialPredictionGroups.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <StyledGroupSection
                className={clsx(tableValuationEstimatesClasses.groupSection, {
                  [tableValuationEstimatesClasses.groupTypeMultiple]: group.type === 'multiple',
                })}>
                <StyledRow
                  className={tableValuationEstimatesClasses.row}
                  onClick={() =>
                    setExpandedGroupIndex((prev) => (prev === groupIndex ? -1 : groupIndex))
                  }>
                  <StyledCell
                    className={clsx(
                      tableValuationEstimatesClasses.cell,
                      tableValuationEstimatesClasses.legendCell
                    )}>
                    {group.label}
                    <MenuExpandIcon open={groupIndex === expandedGroupIndex} />
                  </StyledCell>
                  {visiblePredictions.map((prediction) => (
                    <StyledCell
                      key={prediction.id}
                      className={clsx(
                        tableValuationEstimatesClasses.cell,
                        tableValuationEstimatesClasses.valueCell
                      )}>
                      {formatFinancialPredictionValue(group.formatter, prediction, rating)}
                    </StyledCell>
                  ))}
                </StyledRow>
              </StyledGroupSection>

              <StyledCollapse in={groupIndex === expandedGroupIndex}>
                <StyledGroupItemSection className={tableValuationEstimatesClasses.groupItemSection}>
                  {group.items.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                      <StyledRow className={tableValuationEstimatesClasses.row}>
                        <StyledCell
                          className={clsx(
                            tableValuationEstimatesClasses.cell,
                            tableValuationEstimatesClasses.legendCell
                          )}>
                          {item.label}
                        </StyledCell>
                        {visiblePredictions.map((prediction) => (
                          <StyledCell
                            key={prediction.id}
                            className={clsx(
                              tableValuationEstimatesClasses.cell,
                              tableValuationEstimatesClasses.valueCell
                            )}>
                            {formatFinancialPredictionValue(item.formatter, prediction, rating)}
                          </StyledCell>
                        ))}
                      </StyledRow>
                      {item.divider && <GroupItemDivider />}
                    </React.Fragment>
                  ))}

                  <GroupItemDivider />

                  <StyledRow className={tableValuationEstimatesClasses.row}>
                    <StyledCell
                      className={clsx(
                        tableValuationEstimatesClasses.cell,
                        tableValuationEstimatesClasses.legendCell
                      )}>
                      {group.resultLabel || 'Result'}
                    </StyledCell>
                    {visiblePredictions.map((prediction) => (
                      <StyledCell
                        key={prediction.id}
                        className={clsx(
                          tableValuationEstimatesClasses.cell,
                          tableValuationEstimatesClasses.valueCell
                        )}>
                        {group.resultFormatter(prediction, rating)}
                      </StyledCell>
                    ))}
                  </StyledRow>
                </StyledGroupItemSection>
              </StyledCollapse>
            </React.Fragment>
          ))}
        </StyledTable>
      </StyledTableContainer>
    </StyledRoot>
  )
}

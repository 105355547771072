import { HOME_PATH } from '@app/routes/utils'
import { CmdClickEvent, isCmdClickEvent } from '@gain/utils/event'
import { useCallback, useMemo } from 'react'
import { match, matchPath, useRouteMatch } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'

export function useIsFirstLocationInHistory() {
  const location = useLocation()

  return useMemo(() => {
    if (
      location !== null &&
      'state' in location &&
      typeof location.state === 'object' &&
      location.state !== null &&
      'isFirstLocationInHistory' in location.state
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (location.state as any).isFirstLocationInHistory
    }

    return false
  }, [location])
}

export function useNavigateBackOrHomeCallback() {
  const history = useHistory()
  const isFirstLocationInHistory = useIsFirstLocationInHistory()

  return useCallback(() => {
    if (isFirstLocationInHistory) {
      history.replace(HOME_PATH)
    } else {
      history.goBack()
    }
  }, [history, isFirstLocationInHistory])
}

export function useFullRouteMatch<
  Params extends { [K in keyof Params]?: string }
>(): match<Params> {
  const location = useLocation()
  const routeMatch = useRouteMatch<Params>()

  return (
    matchPath<Params>(location.pathname, {
      path: routeMatch.path,
      exact: true,
      strict: false,
    }) || routeMatch
  )
}

/**
 * openLink ensures sure that ctrl + click or cmd + click opens in a new tab
 */
export function useOpenLink() {
  const history = useHistory()

  return useCallback(
    (path: string, event: CmdClickEvent) => {
      if (isCmdClickEvent(event)) {
        window.open(path, '_blank')
      } else {
        history.push(path)
      }
    },
    [history]
  )
}

import Popover, { popoverClasses, PopoverProps } from '@mui/material/Popover'
import { styled } from '@mui/material/styles'

import useAnchorOffset from './use-anchor-offset'

const StyledPopover = styled(
  ({ className, ...props }: PopoverProps) => (
    <Popover
      {...props}
      classes={{ root: className }}
    />
  ),
  { shouldForwardProp: (prop) => !['maxHeight', 'showAboveAnchor'].includes(prop.toString()) }
)<{ showAboveAnchor: boolean; maxHeight?: string }>(({ theme, showAboveAnchor, maxHeight }) => ({
  [`& .${popoverClasses.paper}`]: {
    marginTop: theme.spacing(showAboveAnchor ? -0.5 : 0.5), // marginBottom doesn't work for extra offset
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    willChange: 'transform', // Fixes ghosting issue in Safari
    boxShadow: '0px 24px 90px -20px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(0, 0, 1, 0),
    minWidth: 282,
    maxWidth: 282,
    maxHeight: maxHeight ?? '70vh',
  },
}))

interface DropdownMenuPopoverProps extends PopoverProps {
  showAboveAnchor: boolean
}

export default function DropdownMenuPopover({
  children,
  showAboveAnchor,
  ...props
}: DropdownMenuPopoverProps) {
  const [anchorBottom, anchorTop] = useAnchorOffset(props.anchorEl)

  // Calculate how much height the popover can take up before it should overflow
  // and become scrollable.
  let maxHeight: string | undefined
  if (showAboveAnchor && anchorTop) {
    maxHeight = `calc(${anchorTop}px - 16px)`
  } else if (anchorBottom) {
    maxHeight = `calc(100vh - ${anchorBottom}px - 16px)`
  }

  return (
    <StyledPopover
      {...props}
      anchorOrigin={props.anchorOrigin}
      maxHeight={maxHeight}
      showAboveAnchor={showAboveAnchor}
      disableRestoreFocus>
      {children}
    </StyledPopover>
  )
}

import { useUserProfileContext } from '@gain/modules/user'
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'

declare global {
  interface Window {
    beamer_config: unknown
  }
}

// Beamer attaches itself to buttons with this ID
export const BEAMER_BUTTON_ID = 'beamerButton'

// The BeamerContext is used to keep track of unseen beamer notification count
const BeamerContext = createContext<number>(0)

/**
 * Returns the number of unseen Beamer notifications.
 */
export function useBeamerCount() {
  return useContext(BeamerContext)
}

interface BeamerProviderProps {
  children: ReactNode
}

/**
 * BeamerProvider loads the Beamer third party script and initializes the
 * BeamerContext with the number of unseen Beamer notifications.
 */
export default function BeamerProvider({ children }: BeamerProviderProps) {
  const initialized = useRef(false)
  const userProfile = useUserProfileContext()
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    // Initialize the beamer script only once
    if (initialized.current) {
      return
    }

    initialized.current = true

    // Set the beamer config (see: https://www.getbeamer.com/docs/)
    window.beamer_config = {
      product_id: 'OtvUYUqd24313', //DO NOT CHANGE: This is your product code on Beamer
      selector: `.${BEAMER_BUTTON_ID}`,
      user_id: userProfile.intercomHmac,
      user_firstname: userProfile.firstName,
      user_lastname: userProfile.lastName,
      callback: (newCount: number) => {
        setCount(newCount)
      },
    }

    // Don't include third party scripts in the source code when building
    // the browser extension
    if (process.env['NX_PUBLIC_TASK_TARGET'] !== 'browser-extension') {
      const script = document.createElement('script')
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
      document.body.appendChild(script)
    }
  }, [userProfile])

  return <BeamerContext.Provider value={count}>{children}</BeamerContext.Provider>
}

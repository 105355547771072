import { difference } from 'lodash'

import { COUNTRY_CODE_MAP, CountryCode } from './country-code-map'
import { Region, REGIONS, Subregion } from './regions'

export function isCountryCode(countryCode: unknown): countryCode is CountryCode {
  return typeof countryCode === 'string' && countryCode in COUNTRY_CODE_MAP
}

export function isCountryCodeArray(countryCodes: unknown[]): countryCodes is CountryCode[] {
  return countryCodes.every(isCountryCode)
}

/**
 * Returns all country codes for a given region, including subregions.
 */
export function getCountryCodes(region: Region): CountryCode[] {
  return region.options.flatMap((option) => {
    if (isCountryCode(option)) {
      return [option]
    }

    return getCountryCodes(option)
  })
}

export function getRegionAndCountryNames(countryCodes: (CountryCode | string)[]): string[] {
  if (!isCountryCodeArray(countryCodes)) {
    throw new Error(`Invalid CountryCodes: "${countryCodes.join('", "')}"`)
  }

  // Returns all country codes belonging to a certain option
  const findCountryCodes = (opt: CountryCode | Subregion): CountryCode[] => {
    if (isCountryCode(opt)) {
      return [opt]
    }

    return opt.options.flatMap((opt2) => findCountryCodes(opt2))
  }

  // Keep track of found countryCodes, so we can add missing ones later on
  const codesFound = new Array<CountryCode | string>()

  // Recursive function to find selected region and country names
  const findSelected = (opt: Region | Subregion): string[] => {
    // If all values of an option group are selected, return the option group's name
    const codes = opt.options.flatMap(findCountryCodes)
    if (codes.every((code) => countryCodes.includes(code))) {
      codesFound.push(...codes)
      return [opt.name]
    }

    // If it is all country codes, return the country names directly
    if (isCountryCodeArray(opt.options)) {
      return opt.options.flatMap((code) => {
        if (countryCodes.includes(code)) {
          codesFound.push(code)
          return COUNTRY_CODE_MAP[code]
        }
        return []
      })
    }

    // Otherwise, make a recursive call to find nested selected options
    return opt.options.flatMap(findSelected)
  }

  const result = REGIONS.flatMap(findSelected)

  // Add remaining countries not part of a region or subregion directly as country name
  for (const code of difference(countryCodes, codesFound)) {
    if (isCountryCode(code)) {
      result.push(COUNTRY_CODE_MAP[code])
    }
  }

  return result
}

export interface FormatCountriesOptions {
  scope?: string | null
  emptyValue?: string
}

export function formatScopeAndCountries(
  countryCodes: (CountryCode | string)[] | undefined,
  { scope, emptyValue = '-' }: FormatCountriesOptions = {}
) {
  if (scope) {
    return scope
  }
  if (!countryCodes || countryCodes.length === 0) {
    return emptyValue
  }
  if (!isCountryCodeArray(countryCodes)) {
    throw new Error(`Invalid CountryCodes: "${countryCodes.join('", "')}"`)
  }
  return getRegionAndCountryNames(countryCodes).join(', ')
}

interface FormatCountryOptions {
  defaultValue?: string
}

export function formatCountry(
  countryCode: CountryCode | string | null | undefined,
  options: FormatCountryOptions = {}
) {
  if (isCountryCode(countryCode)) {
    return COUNTRY_CODE_MAP[countryCode]
  }
  return options.defaultValue || '-'
}

export function formatCountryAndCity(
  countryCode: CountryCode | string | null | undefined,
  city?: string | null
): string {
  return countryCode ? [city, formatCountry(countryCode)].filter(Boolean).join(', ') : '-'
}

export function formatIndustryScopeText(scope?: string | null) {
  // The scope (e.g. 'In Europe') is used on the main page and industry list, and there it
  // is important that 'United States' is correctly prefixed with the word 'the'. Discussed this
  // with Brian and the conclusion was that hardcoding this in the frontend is the best way
  // forward for now. Also fyi, the only other industry scope value is 'Europe' for now.
  if (scope === 'United States') {
    return 'In the United States'
  } else {
    return `In ${scope}`
  }
}

import { styled } from '@mui/material/styles'

export const RadioCheckedIcon = styled('span')({
  backgroundColor: 'currentColor',
  borderRadius: '50%',
  fontSize: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 16,
  height: 16,
  minWidth: 16,
  minHeight: 16,
  boxSizing: 'border-box',
  '&:before': {
    display: 'block',
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    content: '""',
  },
})

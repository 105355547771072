import { capitalize } from '@mui/material/utils'

export const formatTeamMemberStatus = (status: string | undefined) => {
  switch (status) {
    case 'active':
      return 'Active'
    case 'invited':
      return 'Invited'
    case 'deactivated':
      return 'Deactivated'
    case 'notInvited':
      return 'Not invited'
    default:
      return capitalize(status || '')
  }
}

import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { List } from '@gain/rpc/list-model'
import { SWRConfiguration } from 'swr'

import { AppListParams, useAppListSwr, UseAppListSwrResult } from './use-app-list-swr'
import useMergeOptions from './use-merge-options'

export function createUseAppListSwr<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>
>(method: Method, defaultOptions?: SWRConfiguration) {
  return (
    params?: AppListParams<Method, FilterItem>,
    options?: SWRConfiguration
  ): UseAppListSwrResult<List<Item, FilterItem>> => {
    const mergedOptions = useMergeOptions(defaultOptions, options)
    return useAppListSwr<Method, Item, FilterItem>(method, params, mergedOptions)
  }
}

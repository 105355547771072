import { useRpcClient } from '@gain/api/swr'
import { DealCloudIcon } from '@gain/components/icons'
import { useUserContext } from '@gain/modules/user'
import { DealCloudItem, RpcMethodMap } from '@gain/rpc/app-model'
import LoadingButton, { loadingButtonClasses, LoadingButtonProps } from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'

import { createFailedResult } from './dealcloud-progress.component'
import { showDealCloudResults } from './dealcloud-snackbar'

export const StyledLoadingButton = styled(LoadingButton)(({ theme, variant, loading }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.divider}`,
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: '#E9EEFE',
  },

  ...(variant === 'icon' &&
    loading && {
      '& > svg': {
        opacity: 0,
      },
    }),

  ...(variant === 'contained' && {
    [`& .${loadingButtonClasses.loadingIndicator}`]: {
      left: theme.spacing(1),
    },
  }),
}))

interface ButtonAddToDealCloudProps extends Pick<LoadingButtonProps, 'variant'> {
  assetId: number
}

export default function ButtonAddToDealCloud({
  assetId,
  variant = 'outlined',
}: ButtonAddToDealCloudProps) {
  const { enqueueSnackbar } = useSnackbar()
  const userContext = useUserContext()
  const fetcher = useRpcClient<RpcMethodMap>()
  const [loading, setLoading] = useState(false)

  const addToDealCloud = useCallback(async () => {
    let fetchedResults: DealCloudItem[] = []
    try {
      setLoading(true)
      const response = await fetcher({
        method: 'data.addToDealCloud',
        params: {
          assetIds: [assetId],
        },
      })
      fetchedResults = fetchedResults.concat(response)
    } catch (error) {
      fetchedResults.push(createFailedResult(assetId))
    } finally {
      setLoading(false)
    }
    showDealCloudResults(enqueueSnackbar, fetchedResults)
  }, [assetId, fetcher, enqueueSnackbar])

  if (!userContext.userProfile?.featureDealCloud) {
    return null
  }

  if (variant === 'icon') {
    return (
      <StyledLoadingButton
        loading={loading}
        onClick={addToDealCloud}
        variant={variant}>
        <DealCloudIcon />
      </StyledLoadingButton>
    )
  }

  return (
    <StyledLoadingButton
      loading={loading}
      loadingPosition={'start'}
      onClick={addToDealCloud}
      startIcon={<DealCloudIcon />}
      variant={variant}>
      Add to DealCloud
    </StyledLoadingButton>
  )
}

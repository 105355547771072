import { ArrowRightIcon, ArrowUpIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import React from 'react'

import InfoButton from '../../info-button'

const StyledRoot = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: 'x' | 'y' }>(({ theme, variant: axis }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  color: theme.palette.text.secondary,

  ...(axis === 'x' && {
    justifyContent: 'end',
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
  }),

  [`& .${svgIconClasses.root}`]: {
    fontSize: 14,
  },

  [`& .${iconButtonClasses.root}`]: {
    color: theme.palette.text.secondary,
    padding: '2px',

    [`& .${svgIconClasses.root}`]: {
      fontSize: 14,
    },
  },
}))

interface ChartAxisTitleProps {
  label: string
  axis: 'x' | 'y'
  explainer?: string
}

export default function ChartAxisTitle({ label, explainer, axis }: ChartAxisTitleProps) {
  return (
    <StyledRoot variant={axis}>
      {axis === 'y' ? <ArrowUpIcon /> : <ArrowRightIcon />}

      <Typography
        variant={'body2'}
        noWrap>
        {label}
      </Typography>

      {explainer && (
        <InfoButton
          dialogMessage={explainer}
          dialogTitle={label}
        />
      )}
    </StyledRoot>
  )
}

import { Components, Theme } from '@mui/material/styles'

export const MuiTooltip = (theme: Theme): Components['MuiTooltip'] => ({
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      borderRadius: 4,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      lineHeight: theme.typography.caption.lineHeight,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      padding: '4px 8px',
      maxWidth: 320,
    },
    arrow: {
      color: theme.palette.grey[900],
    },
  },
})

import { useOpenDialog } from '@app/common/dialog'
import { useRpcClient } from '@gain/api/swr'
import { AssetListItem, BookmarksFilteredList } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { useCallback } from 'react'

import { useUserProfile } from './app-api-hooks'
import { useMutateBookmarksFilteredList } from './bookmarks-filtered-hooks'
import { useSetBookmarksFilteredLastViewedAt } from './bookmarks-filtered-last-viewed-hooks'
import { useShareBookmarksFilteredList } from './bookmarks-filtered-share-hooks'
import { useCheckBookmarksListError, useNavigateToNextBookmarksList } from './bookmarks-hooks'
import { useUserCurrency } from './currency-hooks'

export function useCreateBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const shareList = useShareBookmarksFilteredList()
  const { data: userProfile } = useUserProfile()
  const openDialog = useOpenDialog()
  const userCurrency = useUserCurrency()

  return useCallback(
    async (
      title: string,
      userIds: number[],
      filters: ListFilter<AssetListItem>[],
      isRecentlyFiltered = false
    ): Promise<BookmarksFilteredList | null> => {
      try {
        const result = await fetcher({
          method: 'lists.createCustomAssetQuery',
          params: {
            partial: {
              title,
              isRecentlyFiltered,
              filters,

              // What the user sees in their filters is a conversion from EUR to
              // their user currency. When the exchange rate is updated in the
              // backend we use the currency and historical exchange rate to keep
              // the filters the same.
              currency: userCurrency.name,
              exchangeRate: userCurrency.toEur,
            },
          },
        })

        // Add list to SWR cache
        await mutateList(result.id, () => result)

        const members = userIds.filter((id) => id !== userProfile?.id)
        if (members.length > 0) {
          await shareList(result.id, userIds) // share updates SWR cache
        }

        return result
      } catch (err) {
        // If the list was generated from a recent filter operation showing an error
        // would just confuse the user as he didn't explicitly created the list.
        if (!isRecentlyFiltered) {
          openDialog({
            title: 'Create list failed',
            message: 'Something went wrong while creating the list. Please try again.',
            buttonText: 'Ok',
          })
        }
        return null
      }
    },
    [fetcher, mutateList, openDialog, shareList, userCurrency, userProfile?.id]
  )
}

export interface UpdateBookmarksFilteredListParams {
  title?: string
  description?: string
  filters?: ListFilter<AssetListItem>[]
}

export function useUpdateBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const checkListError = useCheckBookmarksListError()
  const userCurrency = useUserCurrency()
  const updateLastViewedAt = useSetBookmarksFilteredLastViewedAt()

  return useCallback(
    async (id: number, changes: UpdateBookmarksFilteredListParams) => {
      try {
        const result = await fetcher({
          method: 'lists.updateCustomAssetQuery',
          params: {
            id,
            partial: {
              ...changes,
              currency: userCurrency.name,
              exchangeRate: userCurrency.toEur,
            },
          },
        })

        // Add list to SWR cache
        await mutateList(result.id, () => result)

        // When filters are changed the previously viewed lastViewedAt value becomes irrelevant
        if (changes.filters) {
          await updateLastViewedAt(id)
        }
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, fetcher, mutateList, userCurrency, updateLastViewedAt]
  )
}

export function useDeleteBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const checkListError = useCheckBookmarksListError()
  const navigateAway = useNavigateToNextBookmarksList()

  return useCallback(
    async (listId: number) => {
      try {
        await fetcher({
          method: 'lists.deleteCustomAssetQuery',
          params: { id: listId },
        })

        // Remove list from cache
        await mutateList(listId, () => undefined)

        navigateAway(listId, 'customAssetQueryList')
      } catch (err) {
        checkListError(err)
      }
    },
    [fetcher, navigateAway, mutateList, checkListError]
  )
}

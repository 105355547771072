import { useMeasure } from 'react-use'
import { UseMeasureRef } from 'react-use/lib/useMeasure'

/**
 * Hook to measure the width of an element and return a boolean if it is larger
 * than the given minWidth.
 */
export function useMeasureMinWidth<E extends Element = Element>(
  minWidth: number
): [UseMeasureRef<E>, boolean] {
  const [ref, { width }] = useMeasure()
  return [ref, width > minWidth]
}

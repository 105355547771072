import * as Shared from '@gain/rpc/shared-model'

import useFinancialVariantColor from '../use-financial-variant-color'

interface CircleIconProps {
  amountType?: Shared.FinancialResultAmountType
  isForecast?: boolean
}

export default function CircleIcon({
  amountType = Shared.FinancialResultAmountType.Actual,
  isForecast = false,
}: CircleIconProps) {
  const color = useFinancialVariantColor(amountType, isForecast)

  return (
    <svg
      fill={'none'}
      height={'8'}
      viewBox={'0 0 9 8'}
      width={'9'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <circle
        cx={4.5}
        cy={4}
        r={3}
        stroke={color}
        strokeWidth={2}
      />
    </svg>
  )
}

import TextField from '@mui/material/TextField'
import { TextFieldVariants } from '@mui/material/TextField/TextField'
import { MutableRefObject, useCallback } from 'react'
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'

export interface TextInputProps<
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>
> extends Omit<UseControllerProps<T, N>, 'control'> {
  name: N
  inputRef?: MutableRefObject<HTMLInputElement | undefined>
  autoComplete?: string
  placeholder?: string
  type?: string
  autoFocus?: boolean
  onBlur?: () => void
  variant?: TextFieldVariants
  label?: string
  disableHelperText?: boolean
  maxRows?: number
  minRows?: number
  multiline?: boolean
}

/**
 * TextInput renders a full width text input field that works
 * within a `react-hook-form` form.
 */
export default function TextInput<
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>
>(props: TextInputProps<T, N>) {
  const {
    autoComplete,
    autoFocus,
    disableHelperText,
    inputRef,
    label,
    maxRows,
    minRows,
    multiline,
    onBlur,
    placeholder,
    type,
    variant,
  } = props
  const { field, fieldState } = useController(props)

  // Allow to add additional logic on blur
  const handleBlur = useCallback(() => {
    if (onBlur != null) {
      onBlur()
    }
    field.onBlur()
  }, [onBlur, field])

  return (
    <TextField
      {...field}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      error={fieldState.error != null}
      fullWidth={true}
      helperText={!disableHelperText && fieldState.error?.message}
      inputRef={inputRef}
      label={label}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      onBlur={handleBlur}
      placeholder={placeholder}
      type={type ?? 'text'}
      variant={variant}
    />
  )
}

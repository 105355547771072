import { isDefined } from '@gain/utils/common'
import { startOfYear } from 'date-fns/startOfYear'
import { range } from 'lodash'

export interface SparklineValue {
  year: Date
  value: number | null
}

function yearToDate(year: number) {
  const date = new Date()
  date.setFullYear(year)
  return startOfYear(date)
}

export function mapSparklineValues(years: number[], results: number[]): SparklineValue[] | null {
  const values = years
    .filter((year, index) => isDefined(results[index]))
    .map((year, index) => ({
      year,
      value: results[index] || null,
    }))
    .slice()
    .sort((a, b) => a.year - b.year)

  if (values.length === 0) {
    return null
  }

  const maxYear = Math.max(...values.map((financial) => financial.year))

  return range(maxYear - 4, maxYear + 1).map((year) => ({
    year: yearToDate(year),
    value: values.find((financial) => financial.year === year)?.value || null,
  }))
}

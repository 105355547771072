import { ChevronRightIcon } from '@gain/components/icons'
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import React, { forwardRef } from 'react'

export const StyledButton = styled(Button)<ButtonProps>(({ theme, size, color }) => ({
  ...theme.typography.body1,
  minWidth: 'initial',
  padding: 0,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block',
  position: 'relative',
  paddingRight: theme.spacing(1.5),

  [`& .${buttonClasses.endIcon}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.grey['600'],
    marginLeft: 0,
    lineHeight: theme.typography.body1.lineHeight,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  '&:hover': {
    backgroundColor: 'inherit',
    textDecoration: 'underline',
  },

  ...(size === 'small' && theme.typography.body2),

  ...(color === 'primary' && {
    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.primary.main,
    },
  }),

  ...(color === 'info' && {
    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.info.main,
    },
  }),
}))

const ButtonLink = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonLink(
  { children, ...props },
  ref
) {
  return (
    <StyledButton
      ref={ref}
      endIcon={<ChevronRightIcon fontSize={'inherit'} />}
      disableElevation
      disableRipple
      {...props}>
      {children}
    </StyledButton>
  )
})

export default ButtonLink

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import { useSearchAPI } from '../../../search-hooks'
import { useCorrectMatchReason } from '../../search-results/use-correct-match-reason'

const StyledTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
})

export default function SearchTopResultTitle() {
  const {
    result: { topResult },
  } = useSearchAPI()
  const matchReason = useCorrectMatchReason(topResult)

  return (
    <StyledTypography
      color={'textPrimary'}
      variant={'h5'}>
      {topResult?.name}&nbsp;
      {matchReason}
    </StyledTypography>
  )
}

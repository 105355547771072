import { useSessionId } from '@gain/modules/auth'
import { RpcMethodMap } from '@gain/rpc/app-model'
import { useCallback } from 'react'
import { MutatorCallback, MutatorOptions, useSWRConfig } from 'swr'

import { useFormatRpcParams, UseRpcParams, useRpcSwrKey } from './use-rpc'

export function useAppMutateSwr<
  Method extends keyof RpcMethodMap,
  Params extends RpcMethodMap[Method]['params'],
  Data extends RpcMethodMap[Method]['result']
>(method: Method, params?: UseRpcParams<Params>) {
  const { mutate } = useSWRConfig()
  const token = useSessionId()
  const formattedParams = useFormatRpcParams(params)
  const key = useRpcSwrKey(method, formattedParams, token)

  return useCallback(
    (
      data?: Data | Promise<Data> | MutatorCallback<Data>,
      opts?: boolean | MutatorOptions<Data>
    ) => {
      return mutate<Data>(key, data, opts)
    },
    [mutate, key]
  )
}

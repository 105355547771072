import { useSimilarAssets } from '@gain/api/app/hooks'
import { Asset, AssetListItem } from '@gain/rpc/app-model'

import { ASSET_LIST_PATH } from '../../../routes/utils'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../filter/filter-bar'
import SimilarCompaniesCard from '../../similar-companies/similar-companies-card'

interface AssetSimilarCompaniesCardProps {
  asset: Asset
  hideAutomated?: boolean
  visibleRowCount?: number
}

export default function AssetSimilarCompaniesCard({
  asset,
  hideAutomated,
  visibleRowCount,
}: AssetSimilarCompaniesCardProps) {
  const swrSimilarAssets = useSimilarAssets(asset.id, 300)

  if (swrSimilarAssets.loading || !swrSimilarAssets.data) {
    return null
  }

  const showMoreHref = [
    ASSET_LIST_PATH,
    filterModelToQueryString<AssetListItem, 'relevanceRank'>([
      filterValueGroup(filterValueItem('relevanceRank', asset.id)), // find and sort similar asset to their relevance rank compared to this asset
    ]),
  ]
    .filter(Boolean)
    .join('?')

  return (
    <SimilarCompaniesCard
      assetListItems={swrSimilarAssets.data.items}
      hideAutomated={hideAutomated}
      nrOfVisibleRows={visibleRowCount}
      showMoreHref={showMoreHref}
    />
  )
}

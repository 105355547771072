import { BookmarkIcon } from '@gain/components/icons'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: 8,
}))

interface SimilarCompaniesSubmitButtonProps {
  callback: () => Promise<void>
  reset: boolean
}

export function SimilarCompaniesSubmitButton({
  callback,
  reset,
}: SimilarCompaniesSubmitButtonProps) {
  const [submitting, setSubmitting] = useState(false)

  const onClick = useCallback(async () => {
    setSubmitting(true)
    await callback()
  }, [callback, setSubmitting])

  useEffect(() => {
    if (submitting && reset) {
      setSubmitting(false)
    }
  }, [reset, submitting, setSubmitting])

  return (
    <StyledContainer>
      <LoadingButton
        color={'primary'}
        disabled={submitting}
        loading={submitting}
        onClick={onClick}
        startIcon={<BookmarkIcon />}
        variant={'contained'}>
        Add to this list
      </LoadingButton>
    </StyledContainer>
  )
}

import { CompanyIcon } from '@gain/components/icons'

import ContentLink from '../../../common/content-link/content-link'
import { generateAdvisorPagePath } from '../../../routes/utils'
import TooltipAdvisor from '../advisor-tooltip'

interface AdvisorContentLinkProps {
  advisorId: number
  advisorName: string
  logoFileUrl: string | null
}

export default function AdvisorContentLink({
  advisorId,
  advisorName,
  logoFileUrl,
}: AdvisorContentLinkProps) {
  const href = generateAdvisorPagePath({ advisorId, advisorName })

  return (
    <TooltipAdvisor id={advisorId}>
      <ContentLink
        avatarProps={{
          src: logoFileUrl ?? undefined,
          children: !logoFileUrl && <CompanyIcon />,
        }}
        href={href}
        label={advisorName}
      />
    </TooltipAdvisor>
  )
}

import Typography from '@gain/components/typography'
import { CurrencyListItem } from '@gain/rpc/app-model'
import {
  FinancialResult,
  useDefinedAmountTypes,
  useFinancialsWithMissingYears,
} from '@gain/utils/financials'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import Card, { CardHeader, CardHeaderProps } from '../../../common/card/card'
import CurrencyToggle from '../../currency-toggle'
import {
  ExportButton,
  ExportContainerMethod,
  ExportContainerProps,
} from '../../export/export-button'
import { useVisibleFinancials } from '../financial-results-table/financial-results-table-hooks'
import FinancialsTable from './financials-table'

const StyledCard = styled(Card)({
  paddingBottom: 0,
})

const StyledCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 3),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5, 2, 0, 2),
  },
}))

interface FinancialsCardProps<ExportMethod extends ExportContainerMethod> {
  dataCurrency?: string | null
  financialResults: FinancialResult[]
  exportContainerProps?: Omit<ExportContainerProps<ExportMethod>, 'children'>
  currency: CurrencyListItem | null

  // The currency toggle will be shown if the currency and dataCurrency are different
  onCurrencyChange: (newCurrency: CurrencyListItem) => void
}

export default function FinancialsCard<ExportMethod extends ExportContainerMethod>({
  dataCurrency,
  financialResults,
  exportContainerProps,
  currency,
  onCurrencyChange,
}: FinancialsCardProps<ExportMethod>) {
  const financialsWithMissingYears = useFinancialsWithMissingYears(financialResults)
  const visibleFinancials = useVisibleFinancials(financialsWithMissingYears)
  const usedAmountTypes = useDefinedAmountTypes(visibleFinancials)

  return (
    <StyledCard>
      <StyledCardHeader
        actions={
          <Stack
            direction={'row'}
            gap={1}>
            <CurrencyToggle
              dataCurrency={dataCurrency}
              onChange={onCurrencyChange}
              value={currency}
            />
            {usedAmountTypes.hasActuals && exportContainerProps && (
              <ExportButton
                {...exportContainerProps}
                color={'primary'}
                tooltipMode={'always'}
              />
            )}
          </Stack>
        }
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={1}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              Financials
            </Typography>
          </Stack>
        }
      />

      {currency && (
        <FinancialsTable
          currency={currency}
          financials={visibleFinancials}
        />
      )}
    </StyledCard>
  )
}

import { DealStatus } from '@gain/rpc/shared-model'
import { isDefined, isTruthy } from '@gain/utils/common'
import { formatPercentage } from '@gain/utils/number'
import { capitalize } from 'lodash'

import { DealPartyType } from './deal-util'

function formatDealShare(share: string) {
  const words = share.match(/[A-Za-z][a-z]*/g) || []

  return words.join(' ').toLowerCase()
}

function formatPercentageOrShare(
  sharePct: number | null | undefined,
  share: string | null | undefined
) {
  if (isDefined(sharePct)) {
    return formatPercentage(sharePct * 100, {
      round: 0,
    })
  }

  return formatDealShare(share || '')
}

function formatStatusByPercentageOrShare(label: string, format: 'short' | 'default' = 'default') {
  if (label === 'shared majority') {
    return capitalize(label)
  }
  return capitalize([format === 'default' && 'Share', label].filter(isTruthy).join(': '))
}

export interface FormatDealShareLabelOptions {
  format?: 'short' | 'default'
  emptyValue?: string
}

export function formatDealShareLabel(
  share: string | null | undefined,
  sharePct: number | null | undefined,
  status: DealStatus | null | undefined,
  type: DealPartyType | null,
  options: FormatDealShareLabelOptions = {}
) {
  const format = options.format || 'default'
  const emptyValue = options.emptyValue || ''

  if (!isDefined(share) && !isDefined(sharePct)) {
    return emptyValue
  }

  const label = formatPercentageOrShare(sharePct, share)

  if (isDefined(status)) {
    return formatStatusByPercentageOrShare(label, format)
  }

  switch (type) {
    case 'buy':
      return capitalize([format === 'default' && 'Acquired', label].filter(isTruthy).join(' '))
    case 'sell':
      return capitalize([format === 'default' && 'Sold', label].filter(isTruthy).join(' '))
    default:
      return capitalize(label)
  }
}

import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

export const SettingDescription = styled((props: TypographyProps) => (
  <Typography
    color={'text.secondary'}
    variant={'body2'}
    {...props}
  />
))({
  marginBottom: 12,
})

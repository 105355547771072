import { AdvisorDealListItem } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { median } from 'd3-array'

export function useMedianDealsEbitda(deals: AdvisorDealListItem[]): string {
  const formatCurrency = useFormatCurrencyCallback()

  return formatCurrency(median(deals.map(({ ebitdaEur }) => ebitdaEur)), {
    emptyValue: '',
  })
}

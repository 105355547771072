import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

const StyledDivider = styled(Divider)({
  flex: 1,
})

export default function FilterItemDivider() {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={1}>
      <StyledDivider />
      <Chip
        label={'OR'}
        size={'small'}
      />
      <StyledDivider />
    </Stack>
  )
}

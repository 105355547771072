import { AssetBusinessActivityType } from '@gain/rpc/shared-model'

export const assetBusinessActivities: AssetBusinessActivityType[] = [
  AssetBusinessActivityType.Services,
  AssetBusinessActivityType.Manufacturing,
  AssetBusinessActivityType.Distribution,
  AssetBusinessActivityType.Retail,
  AssetBusinessActivityType.Engineering,
  AssetBusinessActivityType.Design,
  AssetBusinessActivityType.ResearchAndDevelopment,
  AssetBusinessActivityType.Operator,
  AssetBusinessActivityType.Other,
]

import { Deal } from '@gain/rpc/app-model'
import { DealMetrics, DealPartyBuyer, DealPartySeller } from '@gain/utils/deal'
import { ColumnVisibilityModel } from '@gain/utils/table'
import { useMemo } from 'react'

import { createTableColumns } from '../../../../common/table'
import ContentLinkDeal from '../../../../features/deal/deal-content-link'
import TableCellDealAnnouncementDate from './table-cell-deal-announcement-date'
import TableCellDealParties from './table-cell-deal-parties'

export interface DealRow extends Omit<Deal, 'buyers' | 'sellers'> {
  buyers: DealPartyBuyer[]
  sellers: DealPartySeller[]
}

interface DealTableColumnsOptions {
  disableDealMetrics?: boolean
  disableDealTargetMetrics?: boolean
}

export const columnVisibility: ColumnVisibilityModel<DealRow> = {
  id: 928,
  sellers: 1160,
}

export function useDealTableColumns({
  disableDealMetrics = false,
  disableDealTargetMetrics = false,
}: DealTableColumnsOptions) {
  return useMemo(() => {
    return createTableColumns<DealRow>(
      {
        field: 'announcementDate',
        headerName: 'Date',
        width: 200,
        renderCell: ({ row }) => <TableCellDealAnnouncementDate deal={row} />,
      },
      {
        field: 'asset',
        headerName: 'Deal',
        renderCell: ({ row }) => (
          <ContentLinkDeal
            deal={row}
            id={row.id}
          />
        ),
      },
      !disableDealMetrics && {
        field: 'id',
        headerName: 'Deal metrics',
        renderCell: ({ row }) => <DealMetrics deal={row} />,
      },
      !disableDealTargetMetrics && {
        field: 'id',
        headerName: 'Target at time of deal',
        renderCell: ({ row }) => (
          <DealMetrics
            deal={row}
            showTargetOfDeal
          />
        ),
      },
      {
        field: 'buyers',
        headerName: 'Buyers',
        renderCell: ({ value, row }) => (
          <TableCellDealParties
            dealStatus={row.dealStatus}
            parties={value}
            type={'buy'}
          />
        ),
      },
      {
        field: 'sellers',
        headerName: 'Sellers',
        renderCell: ({ value, row }) => (
          <TableCellDealParties
            dealStatus={row.dealStatus}
            parties={value}
            type={'sell'}
          />
        ),
      }
    )
  }, [disableDealMetrics, disableDealTargetMetrics])
}

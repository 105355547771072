import { HOME_PATH } from '@app/routes/utils'
import { useIsXs } from '@gain/utils/responsive'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'

import ConditionalWrapper from '../../../../apps/app/src/common/conditional-wrapper'
import GainProLogo from '../../../../apps/app/src/common/gain-pro-logo'

export interface PublicPageProps {
  title?: string
  message?: string | React.ReactNode
  children: React.ReactNode
  logoSubView?: React.ReactNode
}

// Container for full screen / centered screen
const StyledFullScreenContainer = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
})

// Unfortunately, Safari doesn't properly render transparent gradients.
// This means we can not embed 2 radial gradients in 1 background. So,
// we just use absolute positioned divs for both gradients.
const StyledLeftGradient = styled('div')({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  right: '50%',
  bottom: 0,
  left: 0,
  background: 'radial-gradient(circle at 0 0, #b8dcf1 0%, #fbfbfc 35vw)',
})

const StyledRightGradient = styled(StyledLeftGradient)({
  right: 0,
  left: '50%',
  background: 'radial-gradient(circle at 100% 0, #c6ebde 0%, #fbfbfc 35vw)',
})

// Wrappers for the content on the page
const StyledMobileWrapper = styled('div')(({ theme }) => ({
  zIndex: 10,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

const StyledNormalWrapper = styled(StyledMobileWrapper)({
  padding: '40px 64px',
  margin: 25,
  borderRadius: 16,
  boxShadow: '0px 24px 90px -20px rgba(0, 0, 0, 0.2)',
})

// Stack that contains the form elements using lower spacing
const StyledFormStack = styled(Stack)({
  maxWidth: 312,
})

// Styling around the Gain.pro logo
const StyledLogoLink = styled(Link)({
  lineHeight: 0,
})

const LogoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const LogoSubview = styled('div')({
  marginLeft: 5,
})

/**
 * PublicPage is a component that builds up the page for public
 * routes like the login page, forgot password, etc. It adds the
 * design for
 */
export default function PublicPage({ title, message, children, logoSubView }: PublicPageProps) {
  const isXs = useIsXs()

  return (
    <StyledFullScreenContainer>
      <StyledLeftGradient />
      <StyledRightGradient />

      <ConditionalWrapper
        condition={isXs}
        wrapper={(child) => <StyledMobileWrapper>{child}</StyledMobileWrapper>}
        wrapperWhenFalse={(child) => <StyledNormalWrapper>{child}</StyledNormalWrapper>}>
        <StyledFormStack
          alignItems={'center'}
          spacing={2}>
          <LogoWrapper>
            <StyledLogoLink to={HOME_PATH}>
              <GainProLogo disableXs={true} />
            </StyledLogoLink>

            {logoSubView != null && <LogoSubview>{logoSubView}</LogoSubview>}
          </LogoWrapper>

          <Stack
            alignItems={'center'}
            spacing={1}>
            {title != null && (
              <Typography
                align={'center'}
                variant={'h6'}>
                {title}
              </Typography>
            )}

            {message != null && (
              <Typography
                align={'center'}
                variant={'body2'}>
                {message}
              </Typography>
            )}
          </Stack>

          {children}
        </StyledFormStack>
      </ConditionalWrapper>
    </StyledFullScreenContainer>
  )
}

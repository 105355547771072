import { ReactComponent as LogoTextIcon } from '@gain/assets/logo-text.svg'
import { noop } from '@gain/utils/common'
import { useIsXs } from '@gain/utils/responsive'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import { ReactComponent as GainPro } from './gain-pro.svg'
import { ReactComponent as Santa } from './santa_hat.svg'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  height: 26,
  color: theme.palette.text.primary,
}))

const StyledSanta = styled(Santa)(({ theme }) => ({
  position: 'absolute',
  left: -9,
  top: -12,
  width: 28,
  height: 18.01,
  transform: 'rotate(-19.62deg)',
  [theme.breakpoints.only('xs')]: {
    left: -9,
    top: -14,
    width: 32,
    height: 20.58,
  },
}))

export interface GainProLogoProps {
  onClick?: () => void
  disableXs?: boolean
  height?: number
}

/**
 * GainProLogo renders the logo of Gain.pro. Automatically it
 * will scale down to the logo without text in mobile, unless
 * `disableXs` is set to `true`.
 */
export default function GainProLogo(props: GainProLogoProps) {
  const { onClick = noop, disableXs = false } = props

  const isDecember = useMemo(() => new Date().getMonth() === 11, [])
  const isXs = useIsXs()

  const LogoComponent = useMemo(
    () => (!isXs || disableXs ? LogoTextIcon : GainPro),
    [isXs, disableXs]
  )

  return (
    <StyledRoot>
      {isDecember && (
        <StyledSanta title={'Merry Christmas and a happy new year from the Gain.pro team!'} />
      )}
      <LogoComponent onClick={onClick} />
    </StyledRoot>
  )
}

import {
  useAdvisor,
  useAsset,
  useAssetListItem,
  useConferenceEditionListItem,
  useIndustry,
  useInvestor,
  useLegalEntity,
} from '@gain/api/app/hooks'
import { SearchItemType } from '@gain/rpc/app-model'
import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import { useMemo } from 'react'

import { generateConferencePagePath } from '../../../../routes/conference/conference-path'
import {
  generateAdvisorPagePath,
  generateAssetPagePath,
  generateIndustryPagePath,
  generateInvestorPagePath,
  generateLegalEntityPagePath,
} from '../../../../routes/utils'
import { useSearchAPI } from '../../search-hooks'

export function useSearchTopResultApi() {
  const {
    result: { topResult },
    searchingOther,
  } = useSearchAPI()

  const asset = useAsset(
    () => {
      if (
        topResult &&
        [SearchItemType.AutomatedAsset, SearchItemType.CuratedAsset].includes(topResult.type)
      ) {
        return {
          assetId: topResult.id,
        }
      }

      return null
    },
    { errorRetryCount: 0 }
  )

  const assetListItem = useAssetListItem(() => {
    if (
      topResult &&
      asset.error &&
      isJsonRpcError(asset.error) &&
      asset.error.code === RpcErrorCode.ResourceOffline
    ) {
      return topResult.id
    }

    return null
  })

  const industry = useIndustry(() => {
    if (topResult?.type === SearchItemType.Industry) {
      return {
        id: topResult.id,
      }
    }

    return null
  })

  const investor = useInvestor(() => {
    if (topResult?.type === SearchItemType.Investor) {
      return {
        id: topResult.id,
      }
    }

    return null
  })

  const advisor = useAdvisor(() => {
    if (topResult?.type === SearchItemType.Advisor) {
      return {
        id: topResult.id,
      }
    }

    return null
  })

  const conferenceEdition = useConferenceEditionListItem(() => {
    if (topResult?.type === SearchItemType.ConferenceEdition) {
      return topResult.id
    }

    return null
  })

  const legalEntity = useLegalEntity(
    topResult?.type === SearchItemType.Entity ? topResult.id : null
  )

  const to = useMemo(() => {
    switch (topResult?.type) {
      case SearchItemType.CuratedAsset:
      case SearchItemType.AutomatedAsset:
        return generateAssetPagePath({
          id: topResult.id,
          name: topResult.name,
        })

      case SearchItemType.Entity:
        return generateLegalEntityPagePath({
          id: topResult.id,
          title: topResult.name,
        })

      case SearchItemType.Industry:
        return generateIndustryPagePath({
          id: topResult.id,
          title: topResult.name,
        })

      case SearchItemType.Investor:
        return generateInvestorPagePath({
          investorId: topResult.id,
          investorName: topResult.name,
        })

      case SearchItemType.Advisor:
        return generateAdvisorPagePath({
          advisorId: topResult.id,
          advisorName: topResult.name,
        })

      case SearchItemType.ConferenceEdition:
        return generateConferencePagePath({
          conferenceId: topResult.id,
          conferenceName: topResult.name,
        })

      default:
        return '#'
    }
  }, [topResult])

  const loadingTopResult = useMemo(() => {
    switch (topResult?.type) {
      case SearchItemType.CuratedAsset:
      case SearchItemType.AutomatedAsset:
        return asset.loading || (asset.error && assetListItem.loading)

      case SearchItemType.Entity:
        return legalEntity.loading

      case SearchItemType.Industry:
        return industry.loading

      case SearchItemType.Investor:
        return investor.loading

      case SearchItemType.ConferenceEdition:
        return conferenceEdition.loading

      default:
        return false
    }
  }, [
    asset.error,
    asset.loading,
    assetListItem.loading,
    industry.loading,
    investor.loading,
    legalEntity.loading,
    conferenceEdition.loading,
    topResult,
  ])

  return useMemo(
    () => ({
      to,
      noResult:
        !searchingOther &&
        !loadingTopResult &&
        !asset.data &&
        !assetListItem.data &&
        !industry.data &&
        !legalEntity.data &&
        !investor.data &&
        !advisor.data &&
        !conferenceEdition.data,
      asset: !loadingTopResult ? asset.data : undefined,
      assetListItem: !loadingTopResult ? assetListItem.data || undefined : undefined,
      industry: !loadingTopResult ? industry.data : undefined,
      legalEntity: !loadingTopResult ? legalEntity.data : undefined,
      investor: !loadingTopResult ? investor.data : undefined,
      advisor: !loadingTopResult ? advisor.data : undefined,
      conferenceEdition: !loadingTopResult ? conferenceEdition.data : undefined,
      searchingOther,
    }),
    [
      to,
      loadingTopResult,
      searchingOther,
      asset.data,
      industry.data,
      legalEntity.data,
      investor.data,
      assetListItem.data,
      advisor.data,
      conferenceEdition.data,
    ]
  )
}

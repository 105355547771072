import GlobalStyles from '@mui/material/GlobalStyles'
import { Theme, useTheme } from '@mui/material/styles'
import { Typography, Variant } from '@mui/material/styles/createTypography'

const MAX_CONTENT_WIDTH = 720

function importantTypography(typography: Typography, key: Variant) {
  const properties = typography[key]

  return {
    fontFamily: `${typography.fontFamily} !important`,
    fontSize: `${properties.fontSize}px !important`,
    fontWeight: `${properties.fontWeight} !important`,
    lineHeight: `${properties.lineHeight} !important`,
    letterSpacing: `${properties.letterSpacing} !important`,
  }
}

function dialogStyle(theme: Theme) {
  return {
    boxShadow: theme.palette.shadow.level1a,
    [theme.breakpoints.up('md')]: {
      width: `${MAX_CONTENT_WIDTH}px !important`,
      borderRadius: '8px 8px 0 0 !important',
      margin: '0 auto !important',
      left: '0px !important',
      right: '0px !important',
      padding: `${theme.spacing(5)} !important`,
    },
    borderRadius: '0px !important',
    padding: `${theme.spacing(3)} !important`,
    zIndex: `${theme.zIndex.modal} !important`,
  }
}

/**
 * PlanhatNpsSurveyStyles defines global style overrides for the Planhat NPS
 * Survey dialog. A lot of the styling within this dialog is defined with
 * `style='...'` and it even uses !important there in certain cases. Hence, the
 * !important overrides in this component to have the dialog align with our
 * theme as much as possible.
 */
export default function PlanhatNpsSurveyStyles() {
  const theme = useTheme()

  return (
    <GlobalStyles
      styles={{
        // Select the root div of the dialog of the first page that shows the
        // actual NPS Survey form
        'div:has(div > form > div:nth-child(5) > div.scoreListBlockItem)': {
          ...importantTypography(theme.typography, 'body2'),

          '> div': {
            ...dialogStyle(theme),

            form: {
              // Introduction
              '> div:nth-child(3)': {
                paddingBottom: `${theme.spacing(1)} !important`,
                '> p': {
                  color: theme.palette.text.secondary,
                  ...importantTypography(theme.typography, 'body2'),
                },
              },

              // Title
              '> div:nth-child(4)': {
                paddingBottom: `${theme.spacing(3)} !important`,
                color: theme.palette.text.primary,
                ...importantTypography(theme.typography, 'h5'),
              },

              // Score container
              [theme.breakpoints.only('xs')]: {
                '> div:nth-child(5)': {
                  justifyContent: 'center !important',
                },
              },

              // Close button / Cross icon
              '.ph-exit-modal-btn:before, .ph-exit-modal-btn:after': {
                backgroundColor: `${theme.palette.grey[500]} !important`,
              },

              // NPS Score labels
              '> div:nth-child(6) > div': {
                color: `${theme.palette.text.secondary} !important`,
                ...importantTypography(theme.typography, 'body2'),
              },

              // Empty div
              '> div:nth-child(7)': {
                paddingBottom: '0 !important',
              },
            },
          },
        },

        // Select the root div of the dialog of the second page that shows a
        // thank-you message and allows the user to provide further feedback.
        'div:has(div > form > div:nth-child(4) > textarea)': {
          '> div': {
            ...dialogStyle(theme),

            // Close button
            '.ph-exit-modal-btn:before, .ph-exit-modal-btn:after': {
              backgroundColor: `${theme.palette.grey[500]} !important`,
            },

            '> form': {
              maxWidth: MAX_CONTENT_WIDTH,

              // Title
              '> div:nth-child(3) > h2': {
                color: theme.palette.text.primary,
                ...importantTypography(theme.typography, 'h5'),
              },

              // Thank you message
              '> div:nth-child(3) > p': {
                color: `${theme.palette.text.secondary} !important`,
                ...importantTypography(theme.typography, 'body2'),
              },

              // Feedback form container
              '> div:nth-child(4)': {
                borderTopWidth: '0 !important',
                marginTop: '0 !important',
                paddingTop: '0 !important',
                textAlign: 'right',
              },

              // Feedback form title
              '> div:nth-child(4) > div:nth-child(1)': {
                color: theme.palette.text.primary,
                ...importantTypography(theme.typography, 'h5'),
              },

              // Textarea
              '> div:nth-child(4) > textarea': {
                height: '88px !important',
                ...importantTypography(theme.typography, 'body2'),
                borderRadius: '8px !important',
                '&:focus-visible': {
                  outline: `2px solid ${theme.palette.primary.light}`,
                },
              },

              // Submit button
              '> div:nth-child(4) > div:nth-child(3)': {
                borderRadius: '8px !important',
                padding: `${theme.spacing(0.75, 1)} !important`,
                backgroundColor: `${theme.palette.primary.main} !important`,
                ...importantTypography(theme.typography, 'button'),
              },
            },
          },
        },

        // Select the root div of the dialog of the third page that shows a
        // final thank-you message.
        'div:has(> div > .ph-exit-modal-btn)': {
          ...importantTypography(theme.typography, 'body2'),

          '> div': {
            ...dialogStyle(theme),

            // Close button
            '> .ph-exit-modal-btn:before, .ph-exit-modal-btn:after': {
              backgroundColor: `${theme.palette.grey[500]} !important`,
            },

            // Actual content
            '> div:nth-child(3)': {
              maxWidth: MAX_CONTENT_WIDTH,
              margin: '0 auto',
              marginBottom: '0px !important',

              // Title
              '> h2': {
                color: `${theme.palette.text.primary} !important`,
                ...importantTypography(theme.typography, 'h5'),
              },

              // Message
              '> p': {
                color: `${theme.palette.text.secondary} !important`,
                ...importantTypography(theme.typography, 'body2'),
              },
            },
          },
        },
      }}
    />
  )
}

import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'

import { Rating, RatingOption, ratingOption } from '../rating-model'

export type RatingEsgKey = keyof Pick<
  IndustryMarketSegmentListItem,
  'ratingEnvironmental' | 'ratingSocial' | 'ratingOverall'
>

export const ratingEsgOptions: RatingOption[] = [
  ratingOption(0.5, 'Very high'),
  ratingOption(1, 'Very high/High'),
  ratingOption(1.5, 'High'),
  ratingOption(2, 'High/Medium'),
  ratingOption(2.5, 'Medium'),
  ratingOption(3, 'Medium/Low'),
  ratingOption(3.5, 'Low'),
  ratingOption(4, 'Low/Very low'),
  ratingOption(4.5, 'Very low'),
  ratingOption(5, 'No risk'),
]

export const ratingEsg: Omit<Rating<RatingEsgKey>, 'label' | 'key'> = {
  options: ratingEsgOptions,
}

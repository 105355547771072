import { useIndustryList } from '@gain/api/app/hooks'
import { listSort } from '@gain/rpc/utils'
import { useCallback } from 'react'

export function useHomeIndustryList(limit = 4) {
  return useIndustryList(
    useCallback(() => {
      return {
        sort: [listSort('date', 'desc')],
        limit: limit,
      }
    }, [limit])
  )
}

import { useTheme } from '@mui/material/styles'
import { useEffect, useRef } from 'react'

import {
  Circle,
  GoogleMap,
  useGoogleMapsApiLoader,
} from '../../../../common/google-maps/google-maps'

const containerStyle = {
  width: '100%',
  height: '266px',
}

export interface FilterGeoPointMapProps {
  lat?: number
  lng?: number
  radius?: number
}

function InnerFilterGeoPointMap(props: Required<FilterGeoPointMapProps>) {
  const { lat, lng, radius } = props
  const theme = useTheme()
  const mapRef = useRef<google.maps.Map | null>(null)
  const circleRef = useRef<google.maps.Circle | null>(null)

  // focusMap ensures that the radius circle is fully visible in the viewport of the map
  const centerRadiusCircle = () => {
    if (!mapRef.current || !circleRef.current) {
      return
    }

    const bounds = circleRef.current?.getBounds()

    if (!bounds) {
      return
    }

    mapRef.current?.fitBounds(bounds, 5)
    mapRef.current?.panToBounds(bounds, 5)
  }

  // make sure the radius circle is fully visible when the radius changes
  useEffect(() => {
    centerRadiusCircle()
  }, [radius])

  return (
    <GoogleMap
      center={{ lat, lng }}
      clickableIcons={false}
      mapContainerStyle={containerStyle}
      onLoad={(map) => {
        mapRef.current = map
      }}
      options={{
        disableDefaultUI: true,
        noClear: true,
        panControl: false,
        zoomControl: false,
        scrollwheel: false,
        gestureHandling: 'none',
        keyboardShortcuts: false,
      }}>
      <Circle
        center={{ lat, lng }}
        onLoad={(circle) => {
          circleRef.current = circle
          centerRadiusCircle()
        }}
        options={{
          fillColor: theme.palette.primary.main,
          strokeColor: theme.palette.primary.main,
          strokeWeight: 1.5,
          clickable: false,
        }}
        radius={radius}></Circle>
    </GoogleMap>
  )
}

// FilterGeoPointMap loads the google maps script and when loaded, renders the map
export default function FilterGeoPointMap(props: FilterGeoPointMapProps) {
  const { lat, lng, radius } = props
  const { isLoaded } = useGoogleMapsApiLoader()

  if (lat === undefined || lng === undefined || radius === undefined) {
    return null
  }

  return isLoaded ? (
    <InnerFilterGeoPointMap
      lat={lat}
      lng={lng}
      radius={radius}
    />
  ) : null
}

import { styled } from '@mui/material/styles'

import { Shareholder } from './asset-shareholder-util'
import ListItemAssetShareholder from './list-item-asset-shareholder'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export interface ListAssetShareholderProps {
  shareholders: Shareholder[]
}

export default function ListAssetShareholder({ shareholders }: ListAssetShareholderProps) {
  return (
    <StyledRoot>
      {shareholders.map((shareholder) => (
        <ListItemAssetShareholder
          key={shareholder.id}
          shareholder={shareholder}
        />
      ))}
    </StyledRoot>
  )
}

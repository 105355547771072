export interface FormatDecimalOptions {
  suffix?: string
}

export function formatDecimal(
  value: number | null | undefined,
  { suffix = '' }: FormatDecimalOptions = {}
) {
  if (typeof value !== 'number') {
    return '-'
  }

  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(value)

  return `${formatted}${suffix}`
}

import { TransitionProps } from '@mui/material/transitions'
import clsx from 'clsx'
import React, { forwardRef, ReactElement } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { dialogTransitionClasses, dialogTransitionProps } from '../mui-dialog'

type DialogTransitionProps = Omit<TransitionProps, 'children' | 'addEndListener' | 'timeout'> & {
  children?: ReactElement
}

export const DialogTransition = forwardRef<HTMLElement, DialogTransitionProps>(
  ({ children, ...props }, ref) => {
    return (
      <Transition
        {...props}
        timeout={{
          enter: dialogTransitionProps.duration + dialogTransitionProps.delay,
          exit: dialogTransitionProps.duration,
        }}>
        {(state: TransitionStatus, childProps) =>
          React.cloneElement(children as ReactElement, {
            className: clsx(children?.props.className, dialogTransitionClasses[state]),
            ref,
            ...childProps,
          })
        }
      </Transition>
    )
  }
)

import { formatNumberInternal } from './format-number-internal'

export interface FormatPercentageOptions {
  round?: number | 'auto' | 'smart'
  suffix?: string
  emptyValue?: string
}

export function formatPercentage(
  number: number | null | undefined,
  { round = 'auto', suffix = '%', emptyValue = '-' }: FormatPercentageOptions = {}
) {
  let roundDecimals = 0

  switch (round) {
    case 'auto':
      roundDecimals = number && (number > 10 || number < -10) ? 0 : 1
      break
    case 'smart':
      // Don't show 0% while value is > 0, so we display <1% instead
      if (number && number > 0 && number < 1) {
        return '<1%'
      }
      break
    default:
      roundDecimals = round
  }

  // Avoid showing 0.00%
  if (number === 0) {
    roundDecimals = 0
  }

  return formatNumberInternal(number, { round: roundDecimals, suffix, emptyValue })
}

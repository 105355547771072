import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useMemo } from 'react'

import Loading from '../../../common/loading'
import SimilarAssetDealsCard from '../../../features/valuation/similar-asset-deals-card'
import ValuationCard from '../../../features/valuation/valuation-card'
import { useValuationAssets } from '../../../features/valuation/valuation-hooks'
import AssetValuationEstimatesCard from './asset-valuation-estimates-card/asset-valuation-estimates-card'

interface PrivateAssetValuationProps {
  asset: Asset
}

export function PrivateAssetValuation({ asset }: PrivateAssetValuationProps) {
  const filters = useMemo(() => {
    // Select listed companies similar to the given asset ID that have at least
    // one of the following ratios: EV/EBITDA, EV/EBIT, EV/Sales
    return listFilters<AssetListItem>(
      listFilter('relevanceRank', '=', asset.id),
      listFilter('ownership', '=', AssetOwnershipType.Listed),
      listFilter(
        '',
        'or',
        listFilters(
          listFilter('enterpriseValueEbitdaRatio', '>', 0),
          listFilter('enterpriseValueEbitRatio', '>', 0),
          listFilter('enterpriseValueRevenueRatio', '>', 0)
        )
      )
    )
  }, [asset.id])

  const [initialSort, swrAssets] = useValuationAssets(filters, 10, [
    listSort('relevanceRank', 'asc'),
  ])

  if (swrAssets.loading) {
    return <Loading />
  }

  const hasValuationEstimates =
    asset.financialPredictions.length > 0 &&
    asset.listedSecurities.length === 0 &&
    !asset.financialResults.some((result) => result.isForecast)

  return (
    <Grid
      spacing={2}
      container>
      <Grid
        xs={12}
        item>
        <ValuationCard
          assets={swrAssets.data.items}
          initialSort={initialSort}
          similarToAssetName={asset.generalInfo?.name}
          title={'Similar public companies'}
        />
      </Grid>

      <Grid
        xs={12}
        item>
        <Box
          // This box is a container with a minimum height around the similar deals card to reserve
          // space on the page and prevent the page from collapsing too much when the table
          // size is small. Without this reserved space the page will scroll upwards when
          // the table size changes when changing filters and loading, which is a UX annoyance.
          // NOTE: This solution is not needed when there is a card below the table.
          minHeight={!hasValuationEstimates ? 530 : undefined}>
          <SimilarAssetDealsCard asset={asset} />
        </Box>
      </Grid>

      {hasValuationEstimates && (
        <Grid
          xs={12}
          item>
          <AssetValuationEstimatesCard asset={asset} />
        </Grid>
      )}
    </Grid>
  )
}

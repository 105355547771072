import { AssetFinancialPrediction, AssetRating } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatBuyAndBuild, formatOrganicGrowth } from '@gain/utils/investment-criteria'
import { formatDecimal, formatMultiple } from '@gain/utils/number'
import { useMemo } from 'react'

function formatSumDecimal(value: number | null | undefined) {
  if (typeof value !== 'number') {
    return '-'
  }

  const prefix = value > 0 ? '+' : ''
  return [prefix, formatDecimal(value)].join('')
}

type FinancialPredictionValueFormatterFn = (
  details: AssetFinancialPrediction,
  rating: AssetRating | null
) => string

interface FinancialPredictionGroupItem {
  label: string
  formatter: FinancialPredictionValueFormatterFn
  divider?: boolean
}

export type FinancialPredictionGroupValueType = 'amount' | 'multiple'

interface FinancialPredictionGroup {
  label: string
  type: FinancialPredictionGroupValueType
  formatter: FinancialPredictionValueFormatterFn
  resultFormatter: FinancialPredictionValueFormatterFn
  resultLabel?: string
  items: FinancialPredictionGroupItem[]
}

export function useFinancialPredictionGroups(dataCurrency?: string | null) {
  const formatCurrency = useFormatCurrencyCallback(dataCurrency)

  return useMemo((): FinancialPredictionGroup[] => {
    return [
      {
        label: 'EBITDA',
        type: 'amount',
        formatter: (prediction) => formatCurrency(prediction.ebitda),
        resultFormatter: (prediction) => formatCurrency(prediction.ebitda),
        items: [
          {
            label: 'Base',
            formatter: (prediction) => formatCurrency(prediction.details?.ebitdaBase),
          },
          {
            label: 'Base year',
            formatter: (prediction) => prediction.details?.ebitdaYear.toString(10) || '-',
          },
          {
            label: 'Revenue organic',
            formatter: (_, rating) => formatOrganicGrowth(rating?.organicGrowth),
          },
          {
            label: 'Buy-and-build',
            formatter: (_, rating) => formatBuyAndBuild(rating?.buyAndBuild),
          },
        ],
      },
      {
        label: 'EV multiple',
        type: 'multiple',
        formatter: (prediction) => formatMultiple(prediction.multiple, { round: 0 }),
        resultFormatter: (prediction) =>
          formatDecimal(prediction.details?.multipleResultNotRounded),
        items: [
          {
            label: 'Market multiple',
            formatter: (prediction) => formatDecimal(prediction?.details?.multipleMarketBase),
            divider: true,
          },
          {
            label: 'Sector',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleSectorCorrection),
          },
          {
            label: 'Size',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleSizeCorrection),
          },
          {
            label: 'Organic growth',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleGrowthCorrection),
          },
          {
            label: 'Gross margin',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleThinBusinessModelCorrection),
          },
          {
            label: 'Profitability',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleProfitabilityCorrection),
          },
          {
            label: 'Cash conversion',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleCashConversionCorrection),
          },
          {
            label: 'Resilience',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleResilienceCorrection),
          },
          {
            label: 'Profile',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleProfileCorrection),
          },
          {
            label: 'Outlier cap',
            formatter: (prediction) =>
              formatSumDecimal(prediction?.details?.multipleOutlierCapCorrection),
          },
        ],
      },
      {
        label: 'EV',
        type: 'amount',
        formatter: (prediction) => formatCurrency(prediction.enterpriseValue),
        resultFormatter: (prediction) => formatCurrency(prediction.enterpriseValue),
        items: [
          {
            label: 'EBITDA',
            formatter: (prediction) => formatCurrency(prediction.ebitda),
          },
          {
            label: 'EV multiple',
            formatter: (prediction) =>
              formatMultiple(prediction.details?.multipleResultNotRounded, { round: 1 }),
          },
        ],
      },
      {
        label: 'Debt multiple',
        type: 'multiple',
        formatter: (prediction) => formatMultiple(prediction.debtMultiple, { round: 1 }),
        resultFormatter: (prediction) => formatDecimal(prediction.debtMultiple, { suffix: 'x' }),
        resultLabel: 'Lower of above',
        items: [
          {
            label: '50% cushion',
            formatter: (prediction) =>
              formatDecimal(prediction.details?.debtMultipleCushion, { suffix: 'x' }),
          },
          {
            label: 'Resilience-based',
            formatter: (prediction) =>
              formatDecimal(prediction.details?.debtMultipleResilienceMax || 0, { suffix: 'x' }),
          },
          {
            label: 'Market maximum',
            formatter: (prediction) =>
              formatDecimal(prediction.details?.debtMultipleCeiling, { suffix: 'x' }),
          },
        ],
      },
      {
        label: 'Senior debt',
        type: 'amount',
        formatter: (prediction) => formatCurrency(prediction.debtQuantum),
        resultFormatter: (prediction) => formatCurrency(prediction.debtQuantum),
        items: [
          {
            label: 'EBITDA',
            formatter: (prediction) => formatCurrency(prediction.ebitda),
          },
          {
            label: 'Debt multiple',
            formatter: (prediction) => formatDecimal(prediction.debtMultiple, { suffix: 'x' }),
          },
        ],
      },
      {
        label: 'Equity ticket',
        type: 'amount',
        formatter: (prediction) => formatCurrency(prediction.equityTicket),
        resultFormatter: (prediction) => formatCurrency(prediction.equityTicket),
        items: [
          {
            label: 'EV',
            formatter: (prediction) => formatCurrency(prediction.enterpriseValue),
          },
          {
            label: 'Senior debt',
            formatter: (prediction) => formatCurrency(prediction.debtQuantum),
          },
        ],
      },
    ]
  }, [formatCurrency])
}

export function formatFinancialPredictionValue(
  formatter: FinancialPredictionValueFormatterFn,
  prediction: AssetFinancialPrediction | null,
  rating: AssetRating | null
) {
  if (prediction === null || prediction === undefined) {
    return '-'
  }

  return formatter(prediction, rating)
}

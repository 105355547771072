import Fade from '@mui/material/Fade'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { alpha, styled } from '@mui/material/styles'
import clsx from 'clsx'
import { HierarchyRectangularNode } from 'd3'
import { MouseEvent, useCallback, useRef, useState } from 'react'

import { TreeLeaf, TreeNode } from './treemap-chart-model'
import TreemapLeaf, { treemapLeafClasses } from './treemap-leaf'

const treemapGroupClasses = generateUtilityClasses('TreemapGroup', ['active', 'inactive'])

const StyledRoot = styled('g')(({ theme }) => ({
  pointerEvents: 'all',
  [`& .${treemapLeafClasses.labelContainer}`]: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    opacity: 0,
  },
  [`&.${treemapGroupClasses.active} .${treemapLeafClasses.labelContainer}`]: {
    opacity: 1,
  },
}))

const StyledGroupRect = styled('rect')(({ theme }) => ({
  transition: theme.transitions.create(['fill', 'stroke-width', 'stroke-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
}))

const StyledLeafContainer = styled('g')(({ theme }) => ({
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
}))

const StyledLabelContainer = styled('foreignObject')(({ theme }) => ({
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
}))

const StyledLabel = styled('p')(({ theme }) => ({
  ...theme.typography.button,
  color: theme.palette.common.white,
  alignmentBaseline: 'hanging',
  textAnchor: 'start',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

export enum TreemapGroupState {
  Active,
  Inactive,
  Default,
}

export interface TreemapGroupProps<NodeData, LeafData> {
  node: HierarchyRectangularNode<TreeNode<NodeData, LeafData>>
  onMouseOver: () => void
  onMouseLeave: () => void
  color: string
  valueFormatter: (value: number | null, item: LeafData) => string
  state: TreemapGroupState
  onLeafClick?: (d: LeafData, event: MouseEvent) => void
}

export default function TreemapGroup<NodeData, LeafData>({
  node,
  onMouseOver,
  onMouseLeave,
  color,
  valueFormatter,
  state,
  onLeafClick,
}: TreemapGroupProps<NodeData, LeafData>) {
  const rootRef = useRef<SVGGElement>(null)
  const [hoverLeaf, setHoverLeaf] = useState<number | null>(null)

  const height = node.y1 - node.y0
  const width = node.x1 - node.x0

  const showLabel = width > 44 && height >= 44

  const backgroundColor = alpha(color, 0.7)

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const { clientX, clientY } = event
      const rootEl = rootRef.current

      if (!node.children || !rootEl) {
        return
      }

      const { left, top } = rootEl.getBoundingClientRect()
      const x = clientX - left + node.x0
      const y = clientY - top + node.y0

      const hoverLeafIndex = node.children.findIndex((child) => {
        return x > child.x0 && x < child.x1 && y > child.y0 && y < child.y1
      })

      if (hoverLeafIndex > -1) {
        setHoverLeaf(hoverLeafIndex)
      } else {
        setHoverLeaf(null)
      }
    },
    [node.children, node.x0, node.y0]
  )

  const handleMouseLeave = useCallback(() => {
    setHoverLeaf(null)
    if (onMouseLeave) {
      onMouseLeave()
    }
  }, [onMouseLeave])

  return (
    <StyledRoot
      ref={rootRef}
      className={clsx({
        [treemapGroupClasses.active]: state === TreemapGroupState.Active,
      })}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onMouseOver={onMouseOver}>
      <StyledGroupRect
        fill={backgroundColor}
        height={height}
        style={{ pointerEvents: 'all' }}
        width={width}
        x={node.x0}
        y={node.y0}
      />
      <StyledLeafContainer>
        {node.children?.map((child, index) => {
          const leaf = child as unknown as HierarchyRectangularNode<TreeLeaf<LeafData>>
          return (
            <TreemapLeaf
              key={index}
              color={color}
              data={leaf.data.data}
              highlight={hoverLeaf === index}
              name={leaf.data.name}
              onClick={onLeafClick}
              value={valueFormatter(child.data.value, leaf.data.data)}
              x0={child.x0}
              x1={child.x1}
              y0={child.y0}
              y1={child.y1}
            />
          )
        })}
      </StyledLeafContainer>
      {showLabel && (
        <Fade
          in={state !== TreemapGroupState.Active}
          unmountOnExit>
          <StyledLabelContainer
            height={20}
            width={width - 2 * 10}
            x={node.x0 + 10}
            y={node.y0 + 12}>
            <StyledLabel>{node.data.name}</StyledLabel>
          </StyledLabelContainer>
        </Fade>
      )}
    </StyledRoot>
  )
}

import { Components, Theme } from '@mui/material/styles'

export const MuiAccordion = (theme: Theme): Components['MuiAccordion'] => ({
  defaultProps: {
    disableGutters: true,
    elevation: 0,
  },
  styleOverrides: {
    root: {
      borderRadius: 8,
      backgroundColor: theme.palette.grey[100],
      overflow: 'hidden',
    },
    rounded: {
      '&:last-of-type': {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },

      '&:first-of-type': {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
    },
  },
})

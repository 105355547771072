import { ChevronDownIcon } from '@gain/components/icons'
import { LegalEntity } from '@gain/rpc/app-model'
import { useAnnualReportFileEntries } from '@gain/utils/annual-report'
import Accordion, { AccordionProps } from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import Hidden from '@mui/material/Hidden'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import AccordionList from '../../../../common/accordion/accordion-list'
import { ExportButton } from '../../../../features/export/export-button'
import DownloadAnnualReport from './download-annual-report'

export interface AnnualReportContainer extends Pick<AccordionProps, 'expanded' | 'onChange'> {
  legalEntity: LegalEntity
}

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, 1.5),

  [`& .${accordionSummaryClasses.content}`]: {
    alignItems: 'center',
    margin: 0,
  },

  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[600],
  },
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}))

export const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
})

export default function LegalEntityDownloadAccordion({
  legalEntity,
  onChange,
  expanded,
}: AnnualReportContainer) {
  // Get all downloadable files from all annual reports
  const annualReportFiles = useAnnualReportFileEntries(legalEntity.annualReports)

  // Bail out if there are no rows
  if (annualReportFiles.length === 0) {
    return null
  }

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={onChange}>
      <StyledAccordionSummary expandIcon={<ChevronDownIcon />}>
        <Stack
          direction={'row'}
          flexGrow={1}
          gap={1}>
          <Typography variant={'body2'}>{legalEntity?.name}</Typography>

          <Hidden smDown>
            <Typography
              color={'text.secondary'}
              variant={'body2'}>
              {`${annualReportFiles.length} annual report${
                annualReportFiles.length > 1 ? 's' : ''
              }`}
            </Typography>
          </Hidden>
        </Stack>

        {legalEntity && (
          <ExportButton
            method={'data.downloadLegalEntityAnnualReportFiles'}
            nrOfExports={annualReportFiles.length}
            params={{
              legalEntityId: legalEntity.id,
              filename: `${legalEntity.name} - Gain.pro.zip`,
            }}
            showMaxExportCount
          />
        )}
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <AccordionList>
          {annualReportFiles.map((row) => (
            <DownloadAnnualReport
              key={row.arFile.id}
              arFile={row.arFile}
              report={row.report}
            />
          ))}
        </AccordionList>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

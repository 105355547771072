import { InfoIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { Asset } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { useDialogState } from '@gain/utils/dialog'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

import { AlertDialog } from '../../../common/dialog'

const StyledContainer = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(0.5),
  cursor: 'pointer',
  color: theme.palette.error.main,
  ...theme.typography.body2,
}))

interface NotMaintainedMessageProps {
  asset: Asset
  children: ReactNode
}

export default function NotMaintainedMessage({ asset, children }: NotMaintainedMessageProps) {
  const [isOpen, open, close] = useDialogState()

  return (
    <>
      <StyledContainer onClick={open}>
        <InfoIcon
          color={'error'}
          fontSize={'inherit'}
          style={{ cursor: 'pointer' }}
        />
        {children}
      </StyledContainer>
      <AlertDialog
        message={
          <span>
            The profile of
            <Typography
              component={'span'}
              fontSize={'inherit'}
              variant={'subtitle2'}>
              &nbsp;{asset.generalInfo?.name}&nbsp;
            </Typography>
            is no longer maintained because it
            {asset.generalInfo?.ownership === AssetOwnershipType.Bankrupt
              ? ' went bankrupt'
              : ' is a subsidiary'}
            .
            <br />
            <br />
            Please look at the linked legal entities for the latest financials.
            {asset.generalInfo?.ownership === AssetOwnershipType.Subsidiary &&
              ' Alternatively, check out the profile of the Topco.'}
          </span>
        }
        onClose={close}
        open={isOpen}
        title={'Profile no longer maintained'}
      />
    </>
  )
}

import { useSearch } from '@gain/api/app/hooks'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useTrackEvent } from '../google-analytics'
import {
  SearchResultsContext,
  useSearchFilterQueryParam,
  useSearchQueryParam,
} from './search-hooks'

export default function SearchProvider({ children }) {
  const trackEvent = useTrackEvent()
  const [searchTerm, setSearchQueryParam] = useSearchQueryParam()
  const [searchFilter, setSearchFilter] = useSearchFilterQueryParam()
  const debounceRef = useRef<number | null>(null)
  const lastActiveFilterRef = useRef<string>('all')

  const results = useSearch(() => {
    if (!searchTerm) {
      return null
    }

    return {
      query: searchTerm,
      assets: ['all', 'assets'].includes(searchFilter || lastActiveFilterRef.current),
      industries: ['all', 'industries'].includes(searchFilter || lastActiveFilterRef.current),
      investors: ['all', 'investors'].includes(searchFilter || lastActiveFilterRef.current),
      entities: ['all', 'entities'].includes(searchFilter || lastActiveFilterRef.current),
      advisors: ['all', 'advisors'].includes(searchFilter || lastActiveFilterRef.current),
      conferenceEditions: ['all', 'conferenceEditions'].includes(
        searchFilter || lastActiveFilterRef.current
      ),
      limit: 31,
    }
  })

  const handleSetSearchQuery = useCallback(
    (query: string) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      if (query.trim().length === 0) {
        if (searchTerm?.length > 0) {
          setSearchQueryParam(null, 'replaceIn')
        }
        return
      }

      // to avoid bashing our search API with single or two character
      // searches, we debounce search with 1 or two characters by 1
      // second, after that it becomes 500ms
      let timeout = 500
      if (query.trim().length >= 1 && query.trim().length <= 2) {
        timeout = 1000
      }

      debounceRef.current = window.setTimeout(async () => {
        setSearchQueryParam(query, 'replaceIn')
      }, timeout)
    },
    [searchTerm?.length, setSearchQueryParam]
  )

  const handleSetSearchFilter = useCallback(
    (newFilter: string) => {
      lastActiveFilterRef.current = newFilter

      setSearchFilter(newFilter, 'replaceIn')
    },
    [setSearchFilter]
  )

  const apiValue = useMemo(() => {
    const searchingOther = !!searchTerm && results.loading
    const items = [...(results.data || [])]

    const result = {
      topResult: searchingOther ? undefined : items.length > 0 ? items.shift() : undefined,
      items: searchingOther ? [] : items,
    }

    return {
      setSearchQuery: handleSetSearchQuery,
      setSearchFilter: handleSetSearchFilter,
      searchTerm,
      searchFilter: searchFilter || lastActiveFilterRef.current,
      searching: searchingOther,
      searchingOther: searchingOther,
      hasResults: !!result.topResult || result.items.length > 0,
      result,
    }
  }, [handleSetSearchQuery, handleSetSearchFilter, searchTerm, searchFilter, results])

  useEffect(() => {
    if (!apiValue.searching && !!apiValue.searchTerm) {
      trackEvent('search', apiValue.searchFilter, undefined, undefined, {
        search_term: apiValue.searchTerm,
        search_count: apiValue.result.items.length,
      })
    }
  }, [trackEvent, apiValue])

  return <SearchResultsContext.Provider value={apiValue}>{children}</SearchResultsContext.Provider>
}

import { useListItems, useUserList } from '@gain/api/app/hooks'
import { UserListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import { countBy } from 'lodash'
import { useMemo } from 'react'

export default function useRecommendedUsers(search: string, excludedUserIds: number[]) {
  // Select users with which user has shared other lists with
  const lists = useListItems()
  const sharedWith = useMemo(() => {
    const result = countBy(lists.reduce((acc, val) => [...acc, ...val.userIds], [] as number[]))
    for (const excludedId of excludedUserIds) {
      delete result[excludedId]
    }
    return result
  }, [excludedUserIds, lists])
  const sharedWithIds = Object.keys(sharedWith).map((elm) => parseInt(elm))

  // Fetch users by search or, if no search is set, retrieve users that were shared with before
  const results = useUserList(() => ({
    filter: listFilters<UserListItem>(
      listFilter('status', '!=', 'deactivated'),
      search === '' && sharedWithIds.length > 0 && listFilter('userId', '=', sharedWithIds),
      listFilter('userId', '!=', excludedUserIds)
    ),
    sort: [listSort('firstName', 'asc')],
    limit: 100,
    search,
  }))

  // Order results by "Most shared with"
  if (search === '') {
    results.data.items.sort((a, b) => {
      return sharedWith[b.userId] - sharedWith[a.userId]
    })
  }

  return results
}

import ContentLink from '../../../common/content-link'
import { generateArticlePagePath } from '../../../routes/utils'

export interface ArticleContentLinkProps {
  title: string | null
  id: number
}

export default function ArticleContentLink({ title, id }: ArticleContentLinkProps) {
  return (
    <ContentLink
      href={generateArticlePagePath({
        articleTitle: title || '-',
        articleId: id,
        preview: false,
      })}
      label={title || '-'}
    />
  )
}

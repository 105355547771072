import Typography from '@gain/components/typography'
import { formatYearAndQuarter } from '@gain/utils/date'
import { formatMultiple } from '@gain/utils/number'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import React from 'react'

export interface MultipleTableCellProps {
  value: number | null
  year?: number | null
  quarter?: number | null
  precision?: number

  // Displays 'n.m.' if the multiple is negative
  enableNotMeaningful?: boolean
}

export default function MultipleTableCell({
  value,
  year,
  quarter,
  precision = 0,
  enableNotMeaningful = false,
}: MultipleTableCellProps) {
  if (!value) {
    return '-'
  }

  // Multiples are not meaningful if they are negative
  const isNotMeaningful = enableNotMeaningful && value < 0
  const chipLabel = formatYearAndQuarter(year, quarter)

  return (
    <Stack
      alignItems={'center'}
      display={'inline-flex'}
      flexDirection={'row'}
      gap={1}>
      <Typography
        variant={'inherit'}
        noWrap>
        {isNotMeaningful ? 'n.m.' : formatMultiple(value, { round: precision })}
      </Typography>

      {chipLabel && (
        <Chip
          label={chipLabel}
          size={'small'}
        />
      )}
    </Stack>
  )
}

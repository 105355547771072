import { useLegalEntities } from '@gain/api/app/hooks'
import { AnnualReport, AnnualReportFile, LegalEntity } from '@gain/rpc/app-model'
import { isUS } from '@gain/utils/countries'
import { useMemo } from 'react'

export function hasAnnualReportFileExtension(file: AnnualReportFile, extension: string) {
  return file.filename && file.filename.endsWith(extension)
}

function hasFileWithExtension(extensions: string[]) {
  return (annualReport: AnnualReport) => {
    return annualReport.files.some((file) =>
      extensions.some((extension) => hasAnnualReportFileExtension(file, extension))
    )
  }
}

/**
 * Checks whether the legal entity has annual reports that are either:
 * 1. A PDF file
 * 2. A HTML file and is a US entity
 */
function hasAnnualReports(legalEntity: LegalEntity) {
  const allowedFileExtensions = ['.pdf']

  if (isUS(legalEntity.region)) {
    allowedFileExtensions.push('.html')
  }

  return legalEntity.annualReports.findIndex(hasFileWithExtension(allowedFileExtensions)) > -1
}

export function useLegalEntitiesWithAnnualReports(ids: number[]) {
  const legalEntities = useLegalEntities(ids)

  return useMemo(() => legalEntities.data.filter(hasAnnualReports), [legalEntities.data])
}

import { Components, Theme } from '@mui/material/styles'

export const MuiListSubheader = (theme: Theme): Components['MuiListSubheader'] => ({
  defaultProps: {
    disableSticky: true,
  },
  styleOverrides: {
    root: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      marginTop: 16,
      boxSizing: 'border-box',
      minHeight: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:first-child': {
        marginTop: 18,
      },
    },
    gutters: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
})

import Typography from '@gain/components/typography'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import YearChip from '../../../../common/year-chip'

const StyledRoot = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
})

export interface FundingMetricProps {
  label: string
  value?: string | null
  year?: number | null
}

export default function FundingMetric({ label, value, year }: FundingMetricProps) {
  if (!value) {
    return null
  }

  return (
    <StyledRoot
      sm={6}
      xs={12}
      item>
      <Typography
        color={'text.secondary'}
        variant={'body2'}
        noWrap>
        {label}
      </Typography>
      <Stack
        alignItems={'center'}
        direction={'row'}
        spacing={1}>
        <Typography
          color={'text.primary'}
          variant={'body2'}
          noWrap>
          {value}
        </Typography>
        <YearChip year={year} />
      </Stack>
    </StyledRoot>
  )
}

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ProfileTab } from './profile-info-and-tabs-model'

export function useActiveTab(tabs: ProfileTab[]): string | undefined {
  const location = useLocation()

  return useMemo(() => {
    if (tabs.length === 0) {
      return undefined
    }

    const currentTab = location.pathname.split('/').pop()

    if (currentTab && tabs.map((tab) => tab.value).includes(currentTab)) {
      return currentTab
    }

    return tabs[0].value
  }, [location, tabs])
}

import { ArrowLeftIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { useNavigateBackOrHomeCallback } from '@gain/utils/router'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import clsx from 'clsx'
import { ReactElement } from 'react'

export const mobilePageHeaderClasses = generateUtilityClasses('MobilePageHeader', [
  'variantDefault',
  'variantList',
  'title',
  'listTitle',
  'bar',
  'listHeaderInvisible',
])

export const MOBILE_PAGE_HEADER_HEIGHT = 56

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'contents',
  [`&.${mobilePageHeaderClasses.variantDefault}`]: {
    [`& .${mobilePageHeaderClasses.bar}`]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.palette.shadow.level1d,
    },
  },
  [`&.${mobilePageHeaderClasses.variantList}`]: {
    [`& .${mobilePageHeaderClasses.title}`]: {
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    [`& .${mobilePageHeaderClasses.bar}`]: {
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      transition: theme.transitions.create(['border-bottom', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    [`& .${mobilePageHeaderClasses.listTitle}`]: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    [`&.${mobilePageHeaderClasses.listHeaderInvisible}`]: {
      [`& .${mobilePageHeaderClasses.title}`]: {
        opacity: 1,
      },
      [`& .${mobilePageHeaderClasses.bar}`]: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: theme.palette.shadow.level1d,
      },
      [`& .${mobilePageHeaderClasses.listTitle}`]: {
        opacity: 0,
      },
    },
  },
}))

const StyledBar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.appBar,
  position: 'sticky',
  top: 0,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  height: MOBILE_PAGE_HEADER_HEIGHT,
  padding: theme.spacing(0, 2),
  minWidth: 0,
}))

const StyledButtonContainer = styled('div')({
  width: 36,
  minWidth: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledIconButton = styled(IconButton)({
  fontSize: 24,
})

const StyledTitleContainer = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 0,
})

const StyledListHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 3),
}))

export type MobilePageTitleVariant = 'default' | 'list'

export interface MobilePageHeaderProps {
  className?: string
  title?: string | null
  actions?: ReactElement
  variant?: MobilePageTitleVariant
  disableBackButton?: boolean
}

export default function MobilePageHeader({
  className,
  title,
  actions,
  variant = 'default',
  disableBackButton,
}: MobilePageHeaderProps) {
  const handleClick = useNavigateBackOrHomeCallback()
  const trigger = useScrollTrigger({
    threshold: 32,
    disableHysteresis: true,
  })

  return (
    <StyledRoot
      className={clsx(className, {
        [mobilePageHeaderClasses.variantDefault]: variant === 'default',
        [mobilePageHeaderClasses.variantList]: variant === 'list',
        [mobilePageHeaderClasses.listHeaderInvisible]: trigger,
      })}>
      <StyledBar className={mobilePageHeaderClasses.bar}>
        <StyledButtonContainer>
          {!disableBackButton && (
            <StyledIconButton onClick={handleClick}>
              <ArrowLeftIcon
                fontSize={'inherit'}
                strokeWidth={2}
              />
            </StyledIconButton>
          )}
        </StyledButtonContainer>
        <StyledTitleContainer>
          <Typography
            className={mobilePageHeaderClasses.title}
            variant={'h5'}
            noWrap>
            {title}
          </Typography>
        </StyledTitleContainer>
        <StyledButtonContainer>{actions}</StyledButtonContainer>
      </StyledBar>
      {variant === 'list' && (
        <StyledListHeader>
          <Typography
            className={mobilePageHeaderClasses.listTitle}
            color={'text.primary'}
            variant={'h2'}>
            {title}
          </Typography>
        </StyledListHeader>
      )}
    </StyledRoot>
  )
}

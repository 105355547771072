import { FormatCurrencyOptions, useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { ComponentType } from 'react'

import { FilterCurrencyRangeValue } from '../filter-config/filter-config-model'
import { FilterValueLabelValueProps } from './filter-value-label-value'

export interface FilterValueRangeCurrencyValueProps {
  label: string
  value: FilterCurrencyRangeValue
  ValueLabelComponent: ComponentType<FilterValueLabelValueProps>
  format?: FormatCurrencyOptions['format']
}

/**
 * Renders a currency range value, e.g.:
 *
 *  Revenue: €1-10m
 *  Revenue: €1-10bn
 *  Revenue: >€10m
 *  Revenue: <€10m
 */
export default function FilterValueRangeCurrencyValue({
  value,
  label,
  format,
  ValueLabelComponent,
}: FilterValueRangeCurrencyValueProps) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  if (value === null) {
    return null
  }

  const rangeValues = Array.isArray(value) ? [...value] : value

  return (
    <ValueLabelComponent
      label={label}
      value={formatCurrencyRange(rangeValues[0], rangeValues[1], {
        round: 'significant',
        format,
      })}
    />
  )
}

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { ComponentType, MouseEventHandler, ReactElement } from 'react'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.625, 1),
  '&:hover': {
    backgroundColor: theme.palette.grey['100'],
  },
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.grey['600'],
  fontSize: 16,
  padding: 0,
}))

const StyledListItemText = styled(ListItemText)({
  marginTop: 0,
  marginBottom: 0,
  [`& .${listItemTextClasses.primary}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export interface DropdownMenuItemProps {
  label: string | ReactElement
  className?: string
  icon?: ComponentType
  onClick?: MouseEventHandler<HTMLDivElement>
}

export default function DropdownMenuItem({
  className,
  label,
  icon: IconComponent,
  onClick,
}: DropdownMenuItemProps) {
  return (
    <StyledListItem
      className={className}
      disablePadding>
      <StyledListItemButton onClick={onClick}>
        <StyledListItemIcon>{IconComponent && <IconComponent />}</StyledListItemIcon>
        <StyledListItemText>{label}</StyledListItemText>
      </StyledListItemButton>
    </StyledListItem>
  )
}

import { useMemo } from 'react'

import { getKeyValue, Key, KeyValue, KeyValueData } from './key-value-grid-utils'

export function useKeyValueGridKeys<Data extends KeyValueData>(data: Data, keys: Key<Data>[]) {
  return useMemo(
    () =>
      keys.reduce<KeyValue[]>((formattedKeys, key) => {
        const keyValue = getKeyValue<Data>(data, key)

        if (keyValue) {
          formattedKeys.push(keyValue)
        }

        return formattedKeys
      }, []),
    [keys, data]
  )
}

import {
  LIST_FILTER_BOOLEAN_OPERATORS,
  LIST_FILTER_NON_BOOLEAN_OPERATORS,
  ListFilter,
  ListFilterBoolean,
  ListFilterBooleanOperator,
  ListFilterNonBoolean,
} from './rpc-list-model'

export function isBooleanListFilter<Item extends object>(
  filter: ListFilter<Item>
): filter is ListFilter<Item, ListFilterBooleanOperator> {
  return LIST_FILTER_BOOLEAN_OPERATORS.includes(filter.operator)
}

export function isOrListFilter<Item extends object>(
  filter: ListFilter<Item>
): filter is ListFilter<Item, 'or'> {
  return filter.operator === 'or'
}

export function isAndListFilter<Item extends object>(
  filter: ListFilter<Item>
): filter is ListFilter<Item, 'and'> {
  return filter.operator === 'and'
}

export function isNotListFilter<Item extends object>(
  filter: ListFilter<Item>
): filter is ListFilter<Item, 'not'> {
  return filter.operator === 'not'
}

export function isEqualsListFilter<Item extends object>(
  filter: ListFilter<Item>
): filter is ListFilter<Item, '='> {
  return filter.operator === '='
}

export function isNonBooleanListFilter<Item extends object>(
  filter: ListFilter<Item>
): filter is ListFilterNonBoolean<Item> {
  return LIST_FILTER_NON_BOOLEAN_OPERATORS.includes(filter.operator)
}

export function flattenListFilters<Item extends object>(
  filters: ListFilter<Item>[]
): ListFilter<Item>[] {
  return filters.reduce((acc, current) => {
    if (isBooleanListFilter(current)) {
      return acc.concat(flattenListFilters<Item>(current.value))
    }

    return acc.concat(current)
  }, new Array<ListFilter<Item>>())
}

export function findFilterByField<Item extends object>(
  filter: ListFilterBoolean<Item>,
  field: string
): ListFilter<Item> | null {
  for (const child of filter.value) {
    if (isBooleanListFilter(child) && findFilterByField<Item>(child, field)) {
      const found = findFilterByField<Item>(child, field)
      if (found) {
        return found
      }
    }
    if (child.field === field) {
      return child
    }
  }

  return null
}

import { GiftIcon, XIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { useLocalStorage } from '@gain/utils/storage'
import Chip, { chipClasses } from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper, { PaperProps } from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import React, { useCallback } from 'react'

import Spacer from '../../../common/spacer'
import { BEAMER_BUTTON_ID } from '../../../features/beamer/beamer-provider'
import { useLatestBeamerPost } from './beamer-feature-promo-hooks'

const StyledCard = styled(Paper)(({ theme }) => ({
  boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.08)',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 0,
  padding: theme.spacing(2),
  lineHeight: 1,
}))

const StyledChip = styled(Chip)({
  [`& .${chipClasses.label}`]: {
    textTransform: 'capitalize',
  },
})

const StyledContent = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
})

const StyledLearnMoreButton = styled(Typography)({
  cursor: 'pointer',
})

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  top: 10,
  right: 10,
})

export default function BeamerFeaturePromo(props: PaperProps) {
  const [closedId, setClosedId] = useLocalStorage<number | null>('BEAMER_PROMO_CLOSED_ID', null)
  const { loading, data } = useLatestBeamerPost()

  const handleLearnMoreClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])
  const handleCloseClick = useCallback(() => {
    if (!data?.id) {
      return
    }

    setClosedId(data.id)
  }, [setClosedId, data])

  if (loading || !data) {
    return null
  }

  return (
    <Collapse
      in={data.id !== closedId}
      unmountOnExit>
      <StyledCard
        {...props}
        variant={'outlined'}>
        <StyledChip
          color={'success'}
          icon={<GiftIcon />}
          label={data.category}
          size={'small'}
        />
        <StyledIconButton onClick={handleCloseClick}>
          <XIcon />
        </StyledIconButton>
        <Spacer
          direction={'vertical'}
          xs={1}
        />
        <Typography
          color={'textPrimary'}
          variant={'h6'}>
          {data.title}
        </Typography>
        <Spacer
          direction={'vertical'}
          xs={0.25}
        />
        <StyledContent
          color={'text.secondary'}
          variant={'overline'}>
          {data.content}
        </StyledContent>
        <Spacer
          direction={'vertical'}
          xs={1}
        />
        <StyledLearnMoreButton
          className={BEAMER_BUTTON_ID}
          color={'primary'}
          onClick={handleLearnMoreClick}
          variant={'overline'}>
          Learn more
        </StyledLearnMoreButton>
      </StyledCard>
    </Collapse>
  )
}

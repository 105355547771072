import { CompanyIcon } from '@gain/components/icons'
import Chip, { ChipProps } from '@mui/material/Chip'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { generateAssetPagePath } from '../../../routes/utils'
import TooltipAsset, { TooltipAssetProps } from '../asset-tooltip'

export type AssetListItemChipProps = Pick<ChipProps, 'clickable' | 'onClick' | 'icon'> & {
  id: number
  name?: string | null
  TooltipProps?: Omit<TooltipAssetProps, 'children' | 'asset'>
}

export default function AssetListItemChip({
  id,
  name,
  icon = <CompanyIcon color={'inherit'} />,
  clickable = true,
  TooltipProps,
  onClick,
  ...props
}: AssetListItemChipProps) {
  const history = useHistory()

  return (
    <TooltipAsset
      id={id}
      {...TooltipProps}>
      <Chip
        clickable={clickable}
        icon={icon}
        label={name || ''}
        onClick={(event) => {
          if (onClick) {
            return onClick(event)
          }
          event.preventDefault()
          event.stopPropagation()
          history.push(
            generateAssetPagePath({
              id: id,
              name: name || '',
            })
          )
        }}
        sx={{ minWidth: 0 }}
        {...props}
      />
    </TooltipAsset>
  )
}

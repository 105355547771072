import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'
import React, { PropsWithChildren } from 'react'

import TooltipTypography from './tooltip-typography'

export type TypographyProps<C extends React.ElementType = React.ElementType> = MuiTypographyProps<
  C,
  { component?: C }
> & {
  disableTooltip?: boolean
  maxLines?: number
}

const Typography = React.forwardRef<HTMLParagraphElement, PropsWithChildren<TypographyProps>>(
  function Typography({ children, disableTooltip, noWrap, maxLines, ...props }, ref) {
    if ((noWrap || typeof maxLines === 'number') && !disableTooltip) {
      return (
        <TooltipTypography
          ref={ref}
          maxLines={maxLines}
          noWrap={noWrap}
          {...props}>
          {children}
        </TooltipTypography>
      )
    }

    return (
      <MuiTypography
        ref={ref}
        noWrap={noWrap}
        {...props}>
        {children}
      </MuiTypography>
    )
  }
)

export default Typography

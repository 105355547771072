import { useUserCurrency } from '@gain/api/app/hooks'
import { Deal, DealListItem } from '@gain/rpc/app-model'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatMultiple, formatNumber } from '@gain/utils/number'
import { ReactElement } from 'react'

import { DealMetricConfig, dealMetricConfig, DealMetricKey } from './deal-metrics-config'

const MAX_NUMBER_OF_METRICS = 2

const dealMetricKeys: DealMetricKey[] = [
  'fundingRoundAmountRaised',
  'fundingRoundPostMoneyValuation',
  'ev',
  'equity',
  'evRevenueMultiple',
  'evEbitdaMultiple',
  'evEbitMultiple',
]

const targetOfDealMetricKeys: DealMetricKey[] = ['revenue', 'ebitda', 'ebit', 'totalAssets', 'fte']

interface DealMetricProps {
  configKey: DealMetricKey
  dealCurrency: string | null
  value: number | null
  emptyValue?: string
}

function DealMetric({ configKey, dealCurrency, value }: DealMetricProps): ReactElement {
  const userCurrency = useUserCurrency()
  const formatCurrency = useFormatCurrencyCallback()

  if (value === null) {
    return <>-</>
  }

  const config = dealMetricConfig[configKey] as DealMetricConfig

  // Format value according to config format
  let formattedValue: string | number
  switch (config.format) {
    case 'millions':
      formattedValue = formatCurrency(value, {
        // Some currencies like "NOK" and "SEK" don't have a currency symbol (e.g. $ or €) which
        // causes them to take up too much room. In these cases, we hide the currency prefix.
        disablePrefix: userCurrency.display === CurrencyDisplayType.Code,
        dataCurrency: dealCurrency ?? undefined,
      })
      break
    case 'multiple':
      formattedValue = formatMultiple(value)
      break
    case 'number':
      formattedValue = formatNumber(value)
      break
    default:
      throw new Error(`Unknown currency format: ${config.format}`)
  }

  return (
    <>
      {config.shortLabel ?? config.label}: {formattedValue}
    </>
  )
}

function isDealListItem(deal: Deal | DealListItem): deal is DealListItem {
  return 'highlightedBuyerType' in deal
}

interface DealMetricsProps {
  deal: Deal | DealListItem

  // Display metrics for target of deal instead of the deal itself
  showTargetOfDeal?: boolean
}

export function DealMetrics({ deal, showTargetOfDeal = false }: DealMetricsProps): ReactElement {
  const metricKeys = showTargetOfDeal ? targetOfDealMetricKeys : dealMetricKeys
  const metrics = metricKeys.filter((key) => deal[key] !== null).slice(0, MAX_NUMBER_OF_METRICS)

  if (metrics.length === 0) {
    return <>-</>
  }

  const isListItem = isDealListItem(deal)

  return (
    <>
      {metrics.map((key, i) => [
        i > 0 && ', ',
        <DealMetric
          key={key}
          configKey={key}
          dealCurrency={deal.currency}
          value={isListItem ? deal[key] || null : deal[key]?.value || null}
        />,
      ])}
    </>
  )
}

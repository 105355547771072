import { AssetPricePositioningType } from '@gain/rpc/shared-model'
import { isDefined } from '@gain/utils/common'

export interface FormatAssetPricePositioningLabelOptions {
  emptyValue?: string
}

export const formatAssetPricePositioningLabel = (
  value: AssetPricePositioningType | null | undefined,
  { emptyValue = '-' }: FormatAssetPricePositioningLabelOptions = {}
): string => {
  if (!isDefined(value)) {
    return emptyValue
  }

  switch (value) {
    case AssetPricePositioningType.Premium:
      return 'Premium'
    case AssetPricePositioningType.Discount:
      return 'Discount'
  }
}

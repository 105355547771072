import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListArgs, ListItemKey } from '@gain/rpc/list-model'
import { listFilter } from '@gain/rpc/utils'
import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { SwrParams, useAppListSwr, UseAppListSwrResult } from './use-app-list-swr'
import useMergeOptions from './use-merge-options'

export type UseAppListItemSwrValue<Value> = Value | null | (() => Partial<Value> | null)

function useParams<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  Key extends ListItemKey<FilterItem>,
  Value extends UseAppListItemSwrValue<FilterItem[Key]>
>(field: Key, id: Value): SwrParams<Pick<ListArgs<FilterItem>, 'filter' | 'limit'>> {
  return useMemo(() => {
    const formattedId = typeof id === 'function' ? id() : id

    if (formattedId !== null) {
      return {
        filter: [listFilter<FilterItem>(field, '=', formattedId as any)],
        limit: 1,
      }
    }

    return null
  }, [field, id])
}

export function createUseAppListItemSwr<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  Key extends ListItemKey<FilterItem>,
  Value extends UseAppListItemSwrValue<FilterItem[Key]>
>(method: Method, field: Key, defaultOptions?: SWRConfiguration) {
  return (
    id: Value,
    options?: SWRConfiguration
  ): Omit<UseAppListSwrResult<Item | null>, 'empty' | 'mutate'> => {
    const params = useParams<Method, Item, FilterItem, Key, Value>(field, id)
    const mergedOptions = useMergeOptions(defaultOptions, options)
    const { data, loading, error } = useAppListSwr<Method, Item, FilterItem>(
      method,
      params,
      mergedOptions
    )

    return useMemo(() => {
      return {
        error,
        loading: loading,
        data: data?.items[0] || null,
      }
    }, [error, data, loading])
  }
}

import List from '@mui/material/List'
import { listItemClasses } from '@mui/material/ListItem'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { styled } from '@mui/material/styles'

const AccordionList = styled(List)(({ theme }) => ({
  padding: 0,

  [`& .${listItemClasses.root}`]: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 'none',
    },
  },

  [`& .${listItemIconClasses.root}`]: {
    fontSize: 24,
    minWidth: 'fit-content !important',
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(0.5),
  },
}))

export default AccordionList

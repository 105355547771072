import Flag from '@gain/components/flag'
import { DealListItem } from '@gain/rpc/app-model'
import { formatMonthYear } from '@gain/utils/date'
import { DealMetrics, formatDealFundingSeriesLabel } from '@gain/utils/deal'
import { formatMultiple, formatNumber } from '@gain/utils/number'
import { formatSubsector } from '@gain/utils/sector'

import { createVirtualTableColumns } from '../../../../common/virtual-table'
import {
  NameVirtualTableHeaderCell,
  TagsVirtualTableCell,
} from '../../../../common/virtual-table/cells'
import AssetContentLink from '../../../asset/asset-content-link'
import FinancialValue from '../../../financial/financial-value'
import DealNameTableCell from './deal-name-table-cell.component'
import DealShareholderTableCell from './deal-shareholder-table-cell'
import DealStatusTableCell from './deal-status-table-cell.component'

export const dealTableColumns = createVirtualTableColumns<DealListItem>(
  {
    field: 'announcementDate',
    headerName: 'Deal',
    align: 'left',
    width: 272,
    sticky: true,
    flex: 1,
    sortFields: ['announcementDate', 'publicationDate'],
    renderCell: ({ row }) => <DealNameTableCell deal={row} />,
    renderHeader: NameVirtualTableHeaderCell,
  },
  {
    field: 'announcementDate',
    headerName: 'Date',
    align: 'right',
    width: 120,
    sortFields: ['announcementDate', 'publicationDate'],
    valueFormatter: ({ row }) =>
      formatMonthYear(row.announcementDateMonth, row.announcementDateYear),
  },
  {
    field: 'linkedAssetDescription',
    headerName: 'Target business description',
    width: 264,
  },
  {
    field: 'ev',
    headerName: 'Deal metrics',
    width: 264,
    renderCell: ({ row }) => <DealMetrics deal={row} />,
    sortable: false,
  },
  {
    field: 'revenue',
    headerName: 'Target at time of deal',
    width: 264,
    renderCell: ({ row }) => (
      <DealMetrics
        deal={row}
        showTargetOfDeal
      />
    ),
    sortable: false,
  },
  {
    field: 'highlightedBuyerName',
    headerName: 'Buyers',
    width: 248,
    renderCell: ({ row }) => (
      <DealShareholderTableCell
        row={row}
        type={'buyer'}
      />
    ),
  },
  {
    field: 'highlightedSellerName',
    headerName: 'Sellers',
    width: 248,
    renderCell: ({ row }) => (
      <DealShareholderTableCell
        row={row}
        type={'seller'}
      />
    ),
  },
  {
    field: 'asset',
    headerName: 'Target',
    width: 272,
    renderCell: ({ row }) =>
      row.linkedAssetId ? (
        <AssetContentLink
          id={row.linkedAssetId}
          logoFileUrl={row.assetLogoFileUrl}
          name={row.asset}
        />
      ) : (
        <>-</>
      ),
  },
  {
    field: 'dealStatus',
    headerName: 'Status',
    width: 152,
    renderCell: (params) => <DealStatusTableCell {...params} />,
  },
  {
    field: 'region',
    headerName: 'Company HQ',
    align: 'center',
    width: 111,
    renderCell: (params) => (params.row.region ? <Flag code={params.row.region} /> : <>-</>),
  },
  {
    field: 'subsector',
    headerName: 'Sector',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) => formatSubsector(value),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    align: 'left',
    width: 408,
    renderCell: (params) => (
      <TagsVirtualTableCell
        idField={'tagIds'}
        row={params.row}
        valueField={'tags'}
      />
    ),
  },
  {
    field: 'fundingRoundType',
    headerName: 'Funding round',
    width: 152,
    valueFormatter: ({ value }) => formatDealFundingSeriesLabel(value),
  },
  {
    field: 'fundingRoundAmountRaisedEur',
    headerName: 'Money raised',
    width: 152,
    align: 'right',
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'fundingRoundPreMoneyValuationEur',
    headerName: 'Pre-money valuation',
    width: 192,
    align: 'right',
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'fundingRoundPostMoneyValuationEur',
    headerName: 'Post-money valuation',
    width: 192,
    align: 'right',
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'evEur',
    headerName: 'EV',
    align: 'right',
    width: 136,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'equityEur',
    headerName: 'Equity value',
    align: 'right',
    width: 136,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'evRevenueMultiple',
    headerName: 'Revenue multiple',
    align: 'right',
    width: 192,
    valueFormatter: ({ value }) => formatMultiple(value),
  },
  {
    field: 'evEbitdaMultiple',
    headerName: 'EBITDA multiple',
    align: 'right',
    width: 192,
    valueFormatter: ({ value }) => formatMultiple(value),
  },
  {
    field: 'evEbitMultiple',
    headerName: 'EBIT multiple',
    align: 'right',
    width: 192,
    valueFormatter: ({ value }) => formatMultiple(value),
  },
  {
    field: 'revenueEur',
    headerName: 'Revenue',
    align: 'right',
    width: 136,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'ebitdaEur',
    headerName: 'EBITDA',
    align: 'right',
    width: 136,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'ebitEur',
    headerName: 'EBIT',
    align: 'right',
    width: 136,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'totalAssetsEur',
    headerName: 'Total assets',
    align: 'right',
    width: 136,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'fte',
    headerName: 'FTEs',
    align: 'right',
    width: 136,
    valueFormatter: ({ value }) => formatNumber(value),
  }
)

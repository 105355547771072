import { useTrackEvent } from '@app/features/google-analytics'
import { ListSort } from '@gain/rpc/list-model'
import { deserializeListSort, serializeListSort } from '@gain/rpc/utils'
import { useCallback, useMemo } from 'react'
import { QueryParamConfig } from 'serialize-query-params/lib/types'
import { decodeDelimitedArray, encodeDelimitedArray, useQueryParam } from 'use-query-params'

export const SortQueryParam: QueryParamConfig<ListSort<any>[]> = {
  encode: (value) => {
    if (value.length === 0) {
      return undefined
    }
    return encodeDelimitedArray(value.map(serializeListSort), ',')
  },
  decode: (value) => {
    const result = decodeDelimitedArray(value, ',') || []
    return result.filter(Boolean).map((item) => deserializeListSort(item!))
  },
}

export const createSortQueryParamConfig = <T extends object>() =>
  SortQueryParam as QueryParamConfig<ListSort<T>[]>

type TUpdateType = 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined

export const useSortQueryParam = <T extends Record<string, any>>(
  defaultValue: ListSort<T>[] = [],
  name = 'sort'
): [ListSort<T>[], (newValue: ListSort<T>[], updateType?: TUpdateType) => void] => {
  const trackEvent = useTrackEvent()

  const [sort, setSort] = useQueryParam<ListSort<T>[], ListSort<T>[]>(name, SortQueryParam as any)

  const handleSort = useCallback(
    (newValue, updateType: TUpdateType) => {
      setSort(newValue, updateType)
      trackEvent('sort', 'SORT', newValue.map((item) => item.field).join(', '))
    },
    [setSort, trackEvent]
  )

  const value: ListSort<T>[] = useMemo(
    () => (sort.length === 0 ? defaultValue : sort),
    [sort, defaultValue]
  )

  return [value, handleSort]
}

import { CloseIcon } from '@gain/components/icons'
import { useNavigateBackOrHomeCallback } from '@gain/utils/router'
import { buttonClasses } from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { alpha, styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import TextField from '@mui/material/TextField'
import { stringify } from 'query-string'
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { encodeQueryParams, StringParam } from 'use-query-params'

import { SEARCH_PAGE_PATH } from '../../routes/utils'
import SearchDropdown from './dropdown'
import SearchFilter from './search-filter'
import { useSearchAPI } from './search-hooks'

const StyledForm = styled('form')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledSearchInput = styled(TextField)(({ theme }) => ({
  flex: 1,

  [theme.breakpoints.up('sm')]: {
    maxWidth: 464,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 664,
  },

  [`& .${inputBaseClasses.root}`]: {
    backgroundColor: theme.palette.grey['100'],
    marginLeft: '0 !important',
    // paddingLeft: theme.spacing(1.5),

    [`& .${inputBaseClasses.input}`]: {
      padding: theme.spacing(0, 1.5),

      [`&.${inputBaseClasses.inputAdornedStart}`]: {
        marginLeft: 0,
      },

      [`&.${inputAdornmentClasses.root}`]: {
        fontSize: 16,
      },
    },

    [`&.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: 3,
      borderColor: theme.palette.grey['100'],
      borderRadius: 8,

      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      }),
    },

    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: theme.palette.background.paper,

      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${theme.palette.primary.light} !important`,
      },

      [`& .${buttonClasses.root}, .${buttonClasses.endIcon} svg`]: {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const StyledResetButton = styled(IconButton)(({ theme }) => ({
  width: 16,
  height: 16,
  backgroundColor: alpha(theme.palette.text.primary, 0.2),
  borderRadius: '50%',
  fontSize: 12,

  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}))

export function SearchInput() {
  const handleNavigateBackOrHome = useNavigateBackOrHomeCallback()
  const { setSearchQuery, setSearchFilter, searchTerm, searchFilter } = useSearchAPI()
  const searchInputRef = useRef<HTMLDivElement>(null)
  const textFieldRef = useRef<HTMLInputElement>()
  const match = useRouteMatch(SEARCH_PAGE_PATH)
  const [initialQuery] = useState(match ? searchTerm : null)
  const [initialFilter] = useState(match ? searchFilter : null)
  const history = useHistory()
  const [showDropdown, setShowDropdown] = useState(false)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)

      if (event.target.value.length === 0) {
        setShowDropdown(true)
      }

      if (!match && event.target.value) {
        history.push(
          `${generatePath(SEARCH_PAGE_PATH)}?${stringify(
            encodeQueryParams(
              {
                q: StringParam,
                f: StringParam,
              },
              {
                q: event.target.value,
                f: searchFilter,
              }
            )
          )}`
        )
      } else if (match && !event.target.value) {
        handleNavigateBackOrHome()
      }
    },
    [handleNavigateBackOrHome, history, match, setSearchQuery, searchFilter]
  )

  const handleClick = useCallback(() => {
    if (match || !textFieldRef.current?.value) {
      setShowDropdown(true)
      return
    }

    history.push(
      `${generatePath(SEARCH_PAGE_PATH)}?${stringify(
        encodeQueryParams(
          {
            q: StringParam,
            f: StringParam,
          },
          {
            q: textFieldRef.current?.value,
            f: searchFilter,
          }
        )
      )}`
    )
  }, [history, match, searchFilter])

  const handleClearInput = useCallback(
    (event: MouseEvent) => {
      setShowDropdown(true)
      event.stopPropagation()
      setSearchQuery('')

      if (textFieldRef.current) {
        textFieldRef.current.value = ''
      }

      if (match) {
        handleNavigateBackOrHome()
      }

      textFieldRef.current?.focus()
    },
    [setSearchQuery, match, handleNavigateBackOrHome]
  )

  const handleCloseDropdown = () => {
    if (document.activeElement !== textFieldRef.current) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    if (initialQuery) {
      setSearchQuery(initialQuery)
    }

    if (initialFilter) {
      setSearchFilter(initialFilter)
    }
  }, [])

  useEffect(() => {
    if (showDropdown && searchTerm.length > 0) {
      setShowDropdown(false)
    }
  }, [showDropdown, searchTerm])

  return (
    <StyledForm onSubmit={(event) => event.preventDefault()}>
      <StyledSearchInput
        ref={searchInputRef}
        autoComplete={'off'}
        defaultValue={searchTerm}
        InputProps={{
          startAdornment: <SearchFilter />,
          endAdornment: (
            <Fade in={!!searchTerm}>
              <StyledResetButton onClick={handleClearInput}>
                <CloseIcon fontSize={'inherit'} />
              </StyledResetButton>
            </Fade>
          ),
          inputProps: {
            spellCheck: false,
          },
        }}
        inputRef={textFieldRef}
        onChange={handleChange}
        onClick={handleClick}
        placeholder={'Search'}
        variant={'outlined'}
        fullWidth
      />
      <SearchDropdown
        anchorEl={searchInputRef.current}
        onClose={handleCloseDropdown}
        open={showDropdown}
      />
    </StyledForm>
  )
}

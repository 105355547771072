import { Rating as ICRating } from '@gain/utils/investment-criteria'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import React from 'react'

import InfoButton from '../../../../common/info-button'

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& button': {
    padding: 2,
    marginLeft: theme.spacing(0.5),

    [`& .${svgIconClasses.root}`]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}))

export interface InvestmentCriteriaTableHeaderTitleProps {
  rating: ICRating<string>
}

export default function InvestmentCriteriaTableHeaderTitle({
  rating,
}: InvestmentCriteriaTableHeaderTitleProps) {
  return (
    <Root>
      {rating.label}
      {rating.explainer && (
        <InfoButton
          dialogMessage={rating.explainer}
          dialogTitle={rating.label}
        />
      )}
    </Root>
  )
}

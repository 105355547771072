import { SlotHome, SlotName } from '@gain/components/slot'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ConditionalWrapper from '../../common/conditional-wrapper'
import MainContainer from '../../common/main-container'
import { TabPageHeaderContainer } from '../../common/tab-page-header-container'
import { TabPageTabContainer } from '../../common/tab-page-tab-container'
import TabPageTitle from '../../common/tab-page-title'
import MobilePageContainer from '../../layout/mobile-page-container'
import { INVESTOR_FUND_LIST_PATH, INVESTOR_LIST_PATH } from '../utils'
import InvestorListPageFundTab from './investor-list-page-fund-tab'
import InvestorListPageInvestorTab from './investor-list-page-investor-tab'
import { InvestorListPageTabs } from './investor-list-page-tabs'

export default function InvestorListPage() {
  const isXs = useIsXs()
  return (
    <ConditionalWrapper
      condition={!isXs}
      wrapper={(children) => (
        <MainContainer fluid>
          <TabPageTitle>Investors</TabPageTitle>
          <TabPageHeaderContainer>
            <TabPageTabContainer>
              <InvestorListPageTabs />
              <SlotHome slotName={SlotName.Tabs} />
            </TabPageTabContainer>
          </TabPageHeaderContainer>
          {children}
        </MainContainer>
      )}
      wrapperWhenFalse={(children) => <MobilePageContainer>{children}</MobilePageContainer>}>
      <Switch>
        <Route
          component={InvestorListPageInvestorTab}
          path={INVESTOR_LIST_PATH}
          exact
        />
        <Route
          component={InvestorListPageFundTab}
          path={INVESTOR_FUND_LIST_PATH}
          exact
        />
      </Switch>
    </ConditionalWrapper>
  )
}

import Badge from '@mui/material/Badge'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import React, { ElementType } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

const mobileNavigationItemClasses = generateUtilityClasses('MobileNavigationItem', [
  'active',
  'icon',
  'badge',
])

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  textDecoration: 'none',

  [`&.${mobileNavigationItemClasses.active}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${mobileNavigationItemClasses.icon}`]: {
    height: 20,
    width: 20,
    marginTop: 7,
    marginBottom: 4,
  },

  [`& .${mobileNavigationItemClasses.badge}`]: {
    top: 10,
    right: 4,
  },
}))

const StyledLabel = styled('span')({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: '15px',
})

interface MobileNavigationItemProps extends Omit<NavLinkProps, 'activeClassName'> {
  label: string
  icon: ElementType
  updates?: number
}

export default function MobileNavigationItem({
  label,
  icon: Icon,
  updates,
  ...rest
}: MobileNavigationItemProps) {
  return (
    <StyledNavLink
      activeClassName={mobileNavigationItemClasses.active}
      {...rest}>
      <Badge
        classes={{
          anchorOriginTopRightRectangular: mobileNavigationItemClasses.badge,
        }}
        color={'error'}
        invisible={!updates}
        max={99}
        variant={'dot'}>
        <Icon className={mobileNavigationItemClasses.icon} />
      </Badge>
      <StyledLabel>{label}</StyledLabel>
    </StyledNavLink>
  )
}

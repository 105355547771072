import { genericMemo } from '@gain/utils/react'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { lighten, styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import React, { MouseEvent, useCallback, useMemo, useRef } from 'react'

export const treemapLeafClasses = generateUtilityClasses('TreemapLeaf', [
  'root',
  'clickable',
  'rect',
  'labelContainer',
  'labelName',
  'labelValue',
  'labelVisible',
  'hover',
])

interface StyledRootProps {
  color: string
}

const StyledRoot = styled('g', {
  shouldForwardProp: (prop) => prop !== 'color',
})<StyledRootProps>(({ color }) => ({
  pointerEvents: 'all',
  [`&.${treemapLeafClasses.clickable}`]: {
    cursor: 'pointer',
    [`&.${treemapLeafClasses.hover}`]: {
      [`& .${treemapLeafClasses.rect}`]: {
        stroke: lighten(color, 0.4),
      },
    },
  },
}))

const StyledRect = styled('rect')(({ theme }) => ({
  pointerEvents: 'all',
  strokeWidth: 2,
  clipPath: 'inset(0px)',
  transition: theme.transitions.create('stroke', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shortest,
  }),
}))

const StyledLabelContainer = styled('div')({
  height: 30,
  display: 'flex',
  flexDirection: 'column',
})

const StyledNameLabel = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '15px',
}))

const StyledValueLabel = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '15px',
}))

export interface TreemapLeafProps<LeafData> {
  y0: number
  y1: number
  x0: number
  x1: number
  color: string
  value: string
  onClick?: (d: LeafData, event: MouseEvent) => void
  highlight?: boolean
  data: LeafData
  name: string
}

const TreemapLeaf = genericMemo(function TreemapLeaf<D>({
  y0,
  y1,
  x0,
  x1,
  color,
  value,
  onClick,
  highlight,
  data,
  name,
}: TreemapLeafProps<D>) {
  const rootRef = useRef<SVGGElement>(null)

  const labelNameRef = useRef<HTMLParagraphElement>(null)
  const labelValueRef = useRef<HTMLParagraphElement>(null)
  const width = useMemo(() => x1 - x0, [x0, x1])
  const height = useMemo(() => y1 - y0, [y0, y1])

  const isLabelVisible = useMemo(() => width >= 42 && height >= 42, [width, height])

  const handleLeafClick = useCallback(
    (event: MouseEvent) => {
      if (onClick) {
        event.preventDefault()
        event.stopPropagation()
        onClick(data, event)
      }
    },
    [data, onClick]
  )

  return (
    <Tooltip
      open={highlight}
      title={`${name} - ${value}`}>
      <StyledRoot
        ref={rootRef}
        className={clsx(treemapLeafClasses.root, {
          [treemapLeafClasses.clickable]: !!onClick,
          [treemapLeafClasses.hover]: highlight,
        })}
        color={color}
        onClick={handleLeafClick}
        transform={`translate(${x0},${y0})`}>
        <StyledRect
          className={treemapLeafClasses.rect}
          fill={color}
          height={height}
          width={width}
        />
        {isLabelVisible && (
          <foreignObject
            height={30}
            width={width - 2 * 6}
            x={6}
            y={6}>
            <StyledLabelContainer
              className={clsx(treemapLeafClasses.labelContainer, {
                [treemapLeafClasses.labelVisible]: isLabelVisible,
              })}>
              <StyledNameLabel
                ref={labelNameRef}
                className={treemapLeafClasses.labelName}>
                {name}
              </StyledNameLabel>
              <StyledValueLabel
                ref={labelValueRef}
                className={treemapLeafClasses.labelValue}>
                {value}
              </StyledValueLabel>
            </StyledLabelContainer>
          </foreignObject>
        )}
      </StyledRoot>
    </Tooltip>
  )
})

export default TreemapLeaf

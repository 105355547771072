import { GlobalUltimateOwnerSubsidiary, LegalEntityShareholder } from '@gain/rpc/app-model'
import { isNotEmpty } from '@gain/utils/typescript'
import React from 'react'

import { createTableColumns } from '../../../common/table'
import FinancialValue from '../../../features/financial/financial-value'
import ContentLinkLegalEntity from '../../../features/legal-entity/legal-entity-content-link'

export const legalEntityShareholdersColumns = createTableColumns<LegalEntityShareholder>(
  {
    field: 'name',
    headerName: 'Name',
    width: '55%',
    renderCell: ({ row }) => {
      if (row.linkedLegalEntityId) {
        return (
          <ContentLinkLegalEntity
            id={row.linkedLegalEntityId}
            name={row.name}
            region={row.region}
          />
        )
      }
      return row.name
    },
  },
  {
    field: 'shareholderExternalId',
    headerName: 'Entity number',
    align: 'right',
    renderCell: ({ row }) =>
      isNotEmpty(row.shareholderExternalId) ? row.shareholderExternalId : '-',
  },
  {
    field: 'percentageShareMin',
    headerName: 'Ownership %',
    align: 'right',
    valueFormatter: ({ row }) => formatSharePercentage(row),
  }
)

export function formatSharePercentage(shareholder: LegalEntityShareholder) {
  if (
    typeof shareholder.percentageShareMin === 'number' &&
    typeof shareholder.percentageShareMax === 'number'
  ) {
    return `${shareholder.percentageShareMin}-${shareholder.percentageShareMax}%`
  }

  if (typeof shareholder.percentageShareExact === 'number') {
    return `${shareholder.percentageShareExact}%`
  }

  return '-'
}

export const ultimateOwnerColumns = createTableColumns<GlobalUltimateOwnerSubsidiary>(
  {
    headerName: 'Subsidiary',
    field: 'name',
    width: '100%',
    renderCell: ({ row }) => (
      <div style={{ width: 'fit-content' }}>
        <ContentLinkLegalEntity
          id={row.legalEntityId}
          name={row.name}
          region={row.region}
        />
      </div>
    ),
  },
  {
    field: 'revenueEur',
    headerName: 'Revenue',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <FinancialValue
        amount={row.revenueEur}
        year={row.revenueYear}
      />
    ),
  },
  {
    field: 'ebitdaEur',
    headerName: 'EBITDA',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <FinancialValue
        amount={row.ebitdaEur}
        year={row.ebitdaYear}
      />
    ),
  }
)

import { useRpcClient } from '@gain/api/swr'
import { GeoPolygon, RpcMethodMap } from '@gain/rpc/app-model'
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { formLabelClasses } from '@mui/material/FormLabel'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { useCallback, useEffect, useState } from 'react'
import { Control, FieldPath, useController } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { useDebouncedCallback } from 'use-debounce'

import { MUI_AUTOCOMPLETE_RESET_REASONS } from '../../../../theme/components/mui-autocomplete'

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& > .${inputBaseClasses.root}`]: { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)' },
  [`& .${formLabelClasses.root}`]: {
    ...theme.typography.body2,
  },
}))

function isOptionEqualToValue(option: GeoPolygon, value: GeoPolygon) {
  return value.placeId === option.placeId
}

interface FilterGeoPolygonAutocompleteProps<Values extends FieldValues> {
  value?: GeoPolygon
  control: Control<Values>
  onBlur?: () => void
}

export default function FilterGeoPolygonAutocomplete<Values extends FieldValues>(
  props: FilterGeoPolygonAutocompleteProps<Values>
) {
  const placeId = useController<Values>({
    control: props.control,
    name: 'placeId' as FieldPath<Values>,
  })
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Array<GeoPolygon>>(
    placeId.field.value ? [placeId.field.value] : []
  )
  const fetcher = useRpcClient<RpcMethodMap>()

  // Finds and stores a placeId for a specific place id in the database
  const getPolygonByPlaceId = useCallback(
    (search: string) => {
      return fetcher({
        method: 'data.getGeometryForPlaceId',
        params: { placeId: search },
      })
    },
    [fetcher]
  )

  const handleInputValueChanged = useDebouncedCallback(
    async (_, newValue, reason: AutocompleteInputChangeReason) => {
      if (newValue === '' || MUI_AUTOCOMPLETE_RESET_REASONS.includes(reason)) {
        // When the input is empty the only visible option is the currently selected option
        setOptions(props.value ? [props.value] : [])
      } else {
        if (newValue && newValue.length > 2) {
          // When there is an input value we merge the selected option with the suggested options
          const nextOptions = await fetcher({
            method: 'data.suggestLocality',
            params: {
              input: newValue,
              limit: 6,
            },
          })

          let newOptions = new Array<GeoPolygon>()

          if (props.value) {
            newOptions = [props.value]
          }

          if (nextOptions) {
            newOptions = [...newOptions, ...nextOptions]
          }

          setOptions(newOptions)
          setOpen(newOptions.length > 0)
        }
      }
    },
    500
  )

  const handleAutocompleteOptionSelected = useCallback(
    async (_, newValue: GeoPolygon | null, reason: AutocompleteChangeReason) => {
      if (reason === 'clear') {
        setOptions([])
        // form onChange does not accept null, so we do as never
        placeId.field.onChange(null as never)
        return
      }

      if (newValue === null) {
        // When the new value equals null, we set the place id to null
        // form onChange does not accept null, so we do as never
        placeId.field.onChange(null as never)
      } else {
        // When the new value is a suggested place we fetch the address details,
        // update or insert it, and store the id in the linked address id path
        try {
          const result = await getPolygonByPlaceId(newValue.placeId)
          setOptions([result])
          placeId.field.onChange({ placeId: result.placeId, formatted: result.formatted } as never)
        } catch (e) {
          setOptions([])
        }
      }

      setOpen(false)
    },
    [getPolygonByPlaceId, placeId.field, setOpen]
  )

  useEffect(() => {
    if (options.length === 0) {
      setOpen(false)
    }
  }, [options, setOpen])

  return (
    <Autocomplete
      filterOptions={(x) => x}
      forcePopupIcon={false}
      getOptionLabel={(option) => option?.formatted || ''}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={'No location found'}
      onChange={handleAutocompleteOptionSelected}
      onInputChange={handleInputValueChanged}
      open={open}
      options={options}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={''}
          placeholder={'Enter any city, state or region'}
          fullWidth
        />
      )}
      renderOption={(optionProps, option) => (
        <li
          {...optionProps}
          key={option.placeId}>
          {option.formatted}
        </li>
      )}
      size={'small'}
      value={props.value}
      autoComplete
      autoHighlight
      disableClearable
      filterSelectedOptions
      fullWidth
      includeInputInList
    />
  )
}

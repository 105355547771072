import { useUpdateUserProfileCallback } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import { noop } from '@gain/utils/common'
import { COUNTRY_CODE_MAP, getCountryCodes, isCountryCode, REGIONS } from '@gain/utils/countries'
import { SECTORS } from '@gain/utils/sector'
import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'

import {
  CheckboxList,
  CheckboxListGroup,
  CheckboxListOption,
  CheckboxListValue,
} from '../../common/checkbox-list'
import DialogHeader from '../../common/dialog-header'
import Spacer from '../../common/spacer'

export type NewsPreferencesDialogProps = Omit<DialogProps, 'children'>

export default function NewsPreferencesDialog({
  onClose = noop,
  maxWidth = 'sm',
  fullWidth = true,
  ...props
}: NewsPreferencesDialogProps) {
  const userProfile = useUserProfileContext()
  const updateUserProfile = useUpdateUserProfileCallback()

  const handleChangeRegions = (value: CheckboxListValue<string>) => {
    updateUserProfile({ recommendRegions: value || [] })
  }

  const handleChangeSubsector = (value: CheckboxListValue<string>) => {
    return updateUserProfile({ recommendSubsectors: value || [] })
  }

  return (
    <Dialog
      {...props}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}>
      <DialogHeader
        onCloseClick={(event) => onClose(event, 'backdropClick')}
        title={'Filter news feed'}
      />
      <DialogContent>
        <Typography variant={'subtitle2'}>Limit to regions</Typography>
        <Spacer
          direction={'vertical'}
          xs={1}
        />
        <Paper variant={'outlined'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            paddingX={2}
            paddingY={1}>
            <CheckboxList
              onChange={handleChangeRegions}
              value={userProfile.recommendRegions || []}>
              {REGIONS.map((region) => (
                <CheckboxListGroup
                  key={region.name}
                  label={region.name}
                  values={getCountryCodes(region)}>
                  {region.options.map((option) =>
                    isCountryCode(option) ? (
                      <CheckboxListOption
                        key={option}
                        label={COUNTRY_CODE_MAP[option]}
                        value={option}
                      />
                    ) : (
                      <CheckboxListGroup
                        key={option.name}
                        label={option.name}
                        values={getCountryCodes(option)}>
                        {option.options.map((countryCode) => (
                          <CheckboxListOption
                            key={countryCode}
                            label={COUNTRY_CODE_MAP[countryCode]}
                            value={countryCode}
                          />
                        ))}
                      </CheckboxListGroup>
                    )
                  )}
                </CheckboxListGroup>
              ))}
            </CheckboxList>
          </Box>
        </Paper>

        <Spacer
          direction={'vertical'}
          xs={3}
        />
        <Typography variant={'subtitle2'}>Limit to sectors</Typography>
        <Spacer
          direction={'vertical'}
          xs={1}
        />

        <Paper variant={'outlined'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            paddingX={2}
            paddingY={1}>
            <CheckboxList
              onChange={handleChangeSubsector}
              value={userProfile.recommendSubsectors || []}>
              {SECTORS.map((sector) => (
                <CheckboxListGroup
                  key={sector.name}
                  label={sector.title}
                  values={sector.subsectors.map((subsector) => subsector.name)}>
                  {sector.subsectors.map((subsector) => (
                    <CheckboxListOption
                      key={subsector.name}
                      label={subsector.title}
                      value={subsector.name}
                    />
                  ))}
                </CheckboxListGroup>
              ))}
            </CheckboxList>
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  )
}

import { LogOutIcon, Trash2Icon, UsersIcon } from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import React from 'react'

import { MenuDivider } from '../../../common/menu-divider'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: theme.spacing(1.75),
}))

const StyledRemoveMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover, &:focus': {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },
}))

export type CloseActions = 'remove' | 'transfer' | 'cancel'

interface ShareCustomListMenuProps {
  userId: number
  originalOwnerUserId: number
  ownerUserId: number
  onClose: (userId: number, action: CloseActions) => void
  anchorEl: Element
  open: boolean
}

export function BookmarksShareMenu({
  userId,
  originalOwnerUserId,
  ownerUserId,
  onClose,
  anchorEl,
  open,
}: ShareCustomListMenuProps) {
  const userProfile = useUserProfileContext()
  const isOwner = ownerUserId === userId
  const isLeave = userId === userProfile?.id
  const canTransferOwnership = userProfile?.id === originalOwnerUserId && !isOwner

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={() => onClose(userId, 'cancel')}
      open={open}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}>
      {/* Wrap with div because fragment is not allowed */}
      {canTransferOwnership && (
        <div>
          <StyledMenuItem onClick={() => onClose(userId, 'transfer')}>
            <UsersIcon /> Transfer ownership
          </StyledMenuItem>
          <MenuDivider />
        </div>
      )}
      <StyledRemoveMenuItem onClick={() => onClose(userId, 'remove')}>
        {isLeave ? (
          <>
            <LogOutIcon color={'error'} /> Leave shared list
          </>
        ) : (
          <>
            <Trash2Icon color={'error'} /> Remove from list
          </>
        )}
      </StyledRemoveMenuItem>
    </Menu>
  )
}

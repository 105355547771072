export function downloadUrl(url: string): void {
  // For the browser extension we open the download in a new tab, this prevents the download
  // from being blocked by CSP restrictings
  if (process.env['NX_PUBLIC_TASK_TARGET'] === 'browser-extension') {
    window.open(url, '_blank', 'noopener,noreferrer')
  } else {
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', 'download')
    a.click()
    a.remove()
  }
}

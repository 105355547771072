import { styled } from '@mui/material/styles'

import { grey } from '../../colors/grey'

export const RadioIcon = styled('span')({
  backgroundColor: '#FFFFFF',
  borderRadius: '50%',
  fontSize: 0,
  border: `1px solid ${grey['300']}`,
  display: 'block',
  width: 16,
  height: 16,
  minWidth: 16,
  minHeight: 16,
  boxSizing: 'border-box',
})

import { useCallback, useEffect } from 'react'

import useGaLocation from './google-analytics-utils'

/**
 * Tracks page views in Google Analytics.
 */
export function usePageTracking() {
  const location = useGaLocation()

  useEffect(() => {
    gtag('event', 'page_view', {
      page_location: location,
    })
  }, [location])
}

/**
 * Returns a callback function that can be used to track events in Google
 * Analytics.
 *
 * For debugging see: https://support.google.com/analytics/answer/7201382?hl=en#zippy=%2Cgoogle-tag-gtagjs
 */
export function useTrackEvent() {
  const location = useGaLocation()

  return useCallback(
    (
      action: string,
      category?: string,
      label?: string,
      value?: number,
      customParameters?: Record<string, unknown>
    ) => {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
        location: location,
        ...customParameters,
      })
    },
    [location]
  )
}

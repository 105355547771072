import { styled } from '@mui/material/styles'

export const TooltipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  minWidth: 320,
  width: 320,
  gap: theme.spacing(2),
}))

import Tooltip from '@gain/components/tooltip'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const StyledAvatar = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey['200']}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

interface UserAvatarProps {
  firstName: string
  lastName: string
  isOwner?: boolean
}

export default function UserAvatar({ firstName, lastName, isOwner = false }: UserAvatarProps) {
  return (
    <StyledAvatar>
      <Tooltip
        placement={'top'}
        title={isOwner ? `Owned by ${firstName} ${lastName}` : ''}
        disableInteractive>
        <Typography
          color={'textPrimary'}
          variant={'body2'}
          noWrap>
          {firstName.charAt(0).toUpperCase()}
          {lastName.charAt(0).toUpperCase()}
        </Typography>
      </Tooltip>
    </StyledAvatar>
  )
}

import { styled } from '@mui/material/styles'
import { useEffect } from 'react'

import { FilterValueLabelValueProps } from '../../filter/filter-bar'

const StyledValue = styled('span')({
  fontWeight: 'bold',
  fontSize: 14,
})

const StyledLabel = styled('span')<{ isOrFilter?: boolean }>(({ theme, isOrFilter }) => ({
  ...theme.typography.body2,
  ...(isOrFilter ? { fontWeight: 'bold' } : { color: theme.palette.text.secondary }),
}))

export interface RecentFilterValueLabelProps extends FilterValueLabelValueProps {
  isOrFilter: boolean
  onChange?: (label: string, value: string | null) => void
}

export default function RecentFilterValueLabel({
  label,
  value,
  isOrFilter,
  onChange,
}: RecentFilterValueLabelProps) {
  useEffect(() => {
    onChange?.(label, value)
  }, [label, onChange, value])

  if (!value) {
    return null
  }

  return (
    <>
      <StyledLabel isOrFilter={isOrFilter}>{label}: </StyledLabel>
      <StyledValue>{value}</StyledValue>
    </>
  )
}

import { ShuffleIcon } from '@gain/components/icons'
import Chip, { ChipProps } from '@mui/material/Chip'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import TooltipDeal, { TooltipDealProps } from '../../../features/deal/deal-tooltip'
import { generateDealPagePath } from '../../utils'

export interface DealChipProps extends Omit<ChipProps, 'label'> {
  dealId: number
  dealName: string
  TooltipProps?: Omit<TooltipDealProps, 'children' | 'asset'>
}

export function DealChip({
  dealId,
  icon = <ShuffleIcon color={'inherit'} />,
  color = 'info',
  TooltipProps,
  clickable = true,
  onClick,
  dealName,
  ...props
}: DealChipProps) {
  const history = useHistory()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (onClick) {
        return onClick(event)
      }
      event.stopPropagation()
      event.preventDefault()
      history.push(
        generateDealPagePath({
          id: dealId,
          name: dealName || '',
        })
      )
    },
    [dealId, dealName, history, onClick]
  )

  return (
    <TooltipDeal
      id={dealId}
      {...TooltipProps}>
      <Chip
        color={color}
        icon={icon}
        label={dealName}
        {...props}
        clickable={clickable}
        onClick={handleClick}
      />
    </TooltipDeal>
  )
}

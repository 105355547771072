import { AssetListItem } from '@gain/rpc/app-model'
import {
  Rating,
  RatingInvestmentCriteriaKeyWithOverall,
  ratingInvestmentCriteriaMap,
  RatingKeys,
} from '@gain/utils/investment-criteria'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'

import {
  DEFAULT_X_AXIS_LABEL_SPACE,
  DEFAULT_Y_AXIS_LABEL_SPACE,
} from '../use-company-bubble-echart-option'
import { Axis, AxisConfig } from './axis-config'
import { clamp } from './axis-linear'

export interface AxisRatingConfig {
  id: RatingKeys<AssetListItem>
  assets: AssetListItem[]
}

/**
 * Create a new rating axis.
 *
 * A rating axis is a discrete axis where the values are predefined. The axis
 * values are offset to avoid overlapping bubbles.
 */
export function axisRatingConfig({ id, assets }: AxisRatingConfig): AxisConfig {
  const getValue = (asset: AssetListItem) => asset[id] ?? null
  const rating = ratingInvestmentCriteriaMap[id]
  const scaleMax = Math.max(...rating.options.map((option) => option.value))

  return {
    id,
    label: rating.label,
    scaleType: 'value',
    calculateAxisValue: (asset: AssetListItem, axis: Axis, otherAxisConfig: AxisConfig) =>
      calculateAxisValue(assets, asset, getValue, rating, axis, otherAxisConfig),
    getValue,
    scaleMin: 0,
    scaleMax,
    formatTooltip: (asset: AssetListItem) => {
      const value = getValue(asset) ?? ''
      return rating.options.find((option) => option.value === value)?.label ?? `${value}`
    },
    generateLabels: (axis: Axis) => generateBucketLabelsOption(axis, rating),
    calculateLabelSpace: (axis: Axis) => calculateLabelSpace(axis, rating),
  }
}

/**
 * Generates the EChart labels for a rating axis.
 *
 * Because axis ratings are discrete, and we want to represent them as buckets
 * (i.e. similar to the bucket axis config), we use a "category" axis type.
 *
 * E.g. if the possible ratings are [1,2,3] we render a bucket lanes for [0,1,2,3]
 * and position the labels in the middle of each lane (i.e. in the middle of
 * [0,1], [1,2], and [2,3]).
 *
 * The bubbles themselves are offset within those lanes to avoid overlap.
 */
function generateBucketLabelsOption<T extends string>(
  axis: Axis,
  rating: Rating<T>
): CartesianAxisOption {
  return {
    splitLine: { show: true },
    axisTick: { show: false },
    axisLine: { show: false },
    type: 'category',
    data: rating.options.map((option) => option.label),
    position: axis === Axis.X ? 'bottom' : 'left',
  }
}

/**
 * Calculates the space required for the labels of a rating axis. We only need
 * to adjust the Y-axis when text is getting too long.
 */
function calculateLabelSpace<T extends string>(axis: Axis, rating: Rating<T>): number {
  if (axis === Axis.X) {
    return DEFAULT_X_AXIS_LABEL_SPACE // Always sufficient
  }

  // This calculation is a bit crude; it works for all current labels
  const maxLabelLength = Math.max(...rating.options.map((option) => option.label.length))
  return Math.max(DEFAULT_Y_AXIS_LABEL_SPACE, maxLabelLength * 7)
}

// This map contains the configuration of predefined patterns for bubbles that
// are close to each other.
//
// The map key is the number of bubbles that are close to each other, and the
// values are the x/y-offsets for each bubble within that group of nearby assets.
//
// If the number of nearby bubbles is not in this map, we simply apply a random jitter
// to the bubble.
const bubblePatterns = new Map([
  // In the middle
  [1, [{ x: 0.5, y: 0.5 }]],

  // A horizontal line
  [
    2,
    [
      { x: 0.3, y: 0.5 },
      { x: 0.7, y: 0.5 },
    ],
  ],

  // A triangle
  [
    3,
    [
      { x: 0.5, y: 0.33 },
      { x: 0.25, y: 0.66 },
      { x: 0.75, y: 0.66 },
    ],
  ],

  // A square
  [
    4,
    [
      { x: 0.3, y: 0.3 },
      { x: 0.3, y: 0.7 },
      { x: 0.7, y: 0.3 },
      { x: 0.7, y: 0.7 },
    ],
  ],

  // Like a 5 on a die
  [
    5,
    [
      { x: 0.25, y: 0.25 },
      { x: 0.25, y: 0.75 },
      { x: 0.5, y: 0.5 },
      { x: 0.75, y: 0.25 },
      { x: 0.75, y: 0.75 },
    ],
  ],
])

/**
 * Calculates the value of an asset on a rating axis. Ratings are discrete values
 * and a lot of assets will have the same value, hence we adjust their position
 * to avoid too much overlap.
 *
 * The value adjustments are based on the value of the other axis. If the x/y
 * value is considered close enough we offset them according to:
 *
 * - 1-5 bubbles close to each other: a predefined pattern + a tiny random jitter.
 * - 6+ bubbles close to each other: A random jitter with some room to avoid the edges.
 */
function calculateAxisValue(
  assets: AssetListItem[],
  asset: AssetListItem,
  getValue: AxisConfig['getValue'],
  rating: Rating<RatingInvestmentCriteriaKeyWithOverall>,
  axis: Axis,
  otherAxisConfig: AxisConfig
) {
  // Get the value of the asset on this rating axis.
  const axisValue = getValue(asset)
  if (axisValue === null) {
    return null
  }

  // Calculate the min/max of the other axis; this is used later to determine
  // the closeness of the bubbles. The other axis can be any type of scale.
  const min = otherAxisConfig.scaleMin
  const max = otherAxisConfig.scaleMax
  const otherValue = otherAxisConfig.getValue(asset)
  if (otherValue === null) {
    return null
  }
  const otherAxisValue = clamp(otherValue, min, max)

  // Range is the distance between option values. So 1 for [0, 1, 2, 3], and 0.5
  // for [0, 0.5, 1, 1.5].
  const range = rating.options[1].value - rating.options[0].value

  // Find other assets that are close by.
  const nearbyAssets = findNearbyAssets(
    assets,
    getValue,
    axisValue,
    otherAxisValue,
    otherAxisConfig
  )

  // Add random jitter to offset the bubbles. We seed the random number generator
  // with the asset ID so that we get the same random jitter for the same bubble
  // each time. Whe use a different seed for the x-axis to ensure that the x and y
  // axes get a different (but deterministic) jitter.
  let seed = asset.id
  if (axis === Axis.X) {
    seed += assets.length
  }
  const getRand = mulberry32(seed)

  // Check if there is a pre-defined pattern for the number of nearby assets.
  const offsetPattern = bubblePatterns.get(nearbyAssets.length)
  const index = nearbyAssets.indexOf(asset)
  if (offsetPattern && index > -1) {
    const offset = offsetPattern[index][axis]

    // Even though we have a pattern, we still add a tiny jitter to make it look
    // more natural if there are more than 1 bubbles of the same value. The
    // constants used here are arbitrary and based on some experimentation.
    let tinyJitter = 0
    if (nearbyAssets.length > 1) {
      tinyJitter = 0.04 - getRand() * range * 0.08
    }

    // A ratings value is always at upper bound of the range, so subtract the
    // pattern offset and jitter.
    return axisValue - range * offset - tinyJitter
  }

  // If there is no pattern we just subtract a random jitter. We keep 15% of
  // the range as a buffer to avoid the bubbles hugging the edges.
  return axisValue - getRand() * range * 0.7 - 0.15 * range
}

/**
 * Finds assets that overlap on the current axis and that are close on the other
 * axis.
 */
function findNearbyAssets(
  assets: AssetListItem[],
  getValue: AxisConfig['getValue'],
  axisValue: number,
  otherAxisValue: number,
  otherAxisConfig: AxisConfig
): AssetListItem[] {
  const min = otherAxisConfig.scaleMin
  const max = otherAxisConfig.scaleMax

  return assets.filter((asset) => {
    // For this axis the value on the same axis must be identical. We know the
    // current axis is a rating axis so the values are discrete.
    if (getValue(asset) !== axisValue) {
      return false
    }

    // Get the value of the other axis.
    const otherValue2 = otherAxisConfig.getValue(asset)
    if (otherValue2 === null) {
      return false
    }
    const otherAxisValue2 = clamp(otherValue2, min, max)

    // The other axis can be any type of scale; we simply check if it's within
    // 10% of the current bubble. This is a bit arbitrary, but it works well in
    // practice.
    const distance = Math.abs(otherAxisValue - otherAxisValue2)
    const maxDistance = Math.abs(max - min)
    return distance / maxDistance < 0.1
  })
}

/**
 * A random number generator that is seeded. It's used to generate a
 * deterministic jitter value for a given asset ID.
 *
 * See: https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
 */
function mulberry32(a: number): () => number {
  return () => {
    let t = (a += 0x6d2b79f5)
    t = Math.imul(t ^ (t >>> 15), t | 1)
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296
  }
}

import { RatingGroup, RatingMap } from './rating-model'

export function hasGroupRatings<Key extends string>(
  group: RatingGroup<Key>,
  rating: Partial<RatingMap<Key>> | null | undefined
) {
  if (rating === null || rating === undefined) {
    return false
  }

  for (const item of group.ratings) {
    if (rating[item.key] !== null && rating[item.key] !== undefined) {
      return true
    }
  }

  return false
}

import { Dispatch, SetStateAction, useMemo } from 'react'
import {
  useLocalStorage as useLocalStorageReactUse,
  useSessionStorage as useSessionStorageReactUse,
} from 'react-use'

export const STORAGE_KEY_PREFIX = '__GP_'

function formatLocalStorageKey(key: string) {
  return [STORAGE_KEY_PREFIX, key].join('').toUpperCase()
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T | undefined>>, () => void] {
  const formattedKey = formatLocalStorageKey(key)

  const [state, set, remove] = useLocalStorageReactUse<T>(formattedKey, initialValue)
  const parsedState = useMemo(() => {
    if (state === undefined) {
      return initialValue
    }

    return state
  }, [state, initialValue])

  return [parsedState, set, remove]
}

export function useSessionStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  const formattedKey = formatLocalStorageKey(key)

  const [state, set] = useSessionStorageReactUse<T>(formattedKey, initialValue)
  const parsedState = useMemo(() => {
    if (state === undefined) {
      return initialValue
    }

    return state
  }, [state, initialValue])

  return [parsedState, set]
}

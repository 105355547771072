import { ArticleListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

// Used to store investor id and name together and is later on used to display the investor chips.
interface InvestorItem {
  id: number
  name: string
}

// Combines the linked investor buyers ids and names from an article list item into an array of
// InvestorItem's
function getBuyerInvestors(article: ArticleListItem) {
  if (article.linkedInvestorBuyerIds === null) {
    return []
  }

  return article.linkedInvestorBuyerIds.reduce((acc, id, index) => {
    if (id === 0) {
      return acc
    }

    return acc.concat({
      id: id,
      name:
        article.linkedInvestorBuyerNames !== null ? article.linkedInvestorBuyerNames[index] : '',
    })
  }, new Array<InvestorItem>())
}

// Combines the linked investor seller ids and names from an article list item into an array of
// InvestorItem's
function getSellerInvestors(article: ArticleListItem) {
  if (article.linkedInvestorSellerIds === null) {
    return []
  }

  return article.linkedInvestorSellerIds.reduce((acc, current, index) => {
    if (current === 0) {
      return acc
    }

    return acc.concat({
      id: current,
      name:
        article.linkedInvestorSellerNames !== null ? article.linkedInvestorSellerNames[index] : '',
    })
  }, new Array<InvestorItem>())
}

// Combines all article buyer and seller investor id's and names into a deduplicated array of
// InvestorItem's
export function useArticleInvestors(article: ArticleListItem) {
  return useMemo(() => {
    return [...getBuyerInvestors(article), ...getSellerInvestors(article)].reduce(
      (acc, current) => {
        if (acc.find((item) => item.id === current.id)) {
          return acc
        }

        return acc.concat(current)
      },
      new Array<InvestorItem>()
    )
  }, [article])
}

import { useUserProfileContext } from '@gain/modules/user'
import { BookmarksFilteredList, CustomAssetList } from '@gain/rpc/app-model'
import { useCallback, useState } from 'react'

export interface useUpdateShareSettingsProps {
  onClose: () => void
  onSubmit: () =>
    | Promise<CustomAssetList | BookmarksFilteredList | null>
    | CustomAssetList
    | BookmarksFilteredList
  onShare: (listId: number, userIds: number[]) => Promise<void>
  onLeave: (listId: number) => Promise<void>
  onUnshare: (listId: number, userIds: number[]) => Promise<void>
  onTransfer: (listId: number, newOwnerUserId: number) => Promise<void>
}

export default function useUpdateShareSettings({
  onClose,
  onSubmit,
  onShare,
  onLeave,
  onUnshare,
  onTransfer,
}: useUpdateShareSettingsProps) {
  const userProfile = useUserProfileContext()
  const [isLoading, setIsLoading] = useState(false)

  const save = useCallback(
    async (usersToAdd: number[], usersToRemove: number[], owner: number, originalOwner: number) => {
      setIsLoading(true)
      try {
        const list = await onSubmit()
        if (!list) {
          return
        }

        // Add new users to the list
        if (usersToAdd.length > 0) {
          await onShare(list.id, usersToAdd)
        }

        // Transfer ownership
        if (owner !== originalOwner) {
          await onTransfer(list.id, owner)
        }

        // Remove users from the list (except your own which is not allowed)
        const otherUsersToRemove = usersToRemove.filter((id) => id !== userProfile.id)
        if (otherUsersToRemove.length > 0) {
          await onUnshare(list.id, otherUsersToRemove)
        }

        // Leave list
        if (usersToRemove.includes(userProfile.id)) {
          await onLeave(list.id)
        }
      } finally {
        setIsLoading(false)
        onClose()
      }
    },
    [userProfile, onSubmit, onShare, onTransfer, onUnshare, onLeave, onClose]
  )

  return { save, isLoading }
}

import { JsonRpcRequest, JsonRpcResponse } from '@gain/jsonrpc'

import { RpcErrorCode } from './rpc-error'
import { isJsonRpcError, validateRpcResponse } from './rpc-util'

export interface RpcRequestOptions {
  signal?: AbortSignal
}

/**
 * RpcClient is the main interface for executing RPC requests
 * to our backend. If you're working in the React context, you
 * can use `useRpcClient` to perform RPC calls.
 */
export class RpcClient {
  protected credentials: RequestCredentials = 'include'

  private onUnauthorizedHandler: null | (() => void) = null
  private onUnknownErrorHandler: null | (() => void) = null
  private onOnlineHandler: null | (() => void) = null
  private hasUnknownErrors = false
  private unknownErrorCount = 0
  private isOnline: boolean

  constructor(private readonly baseUrl: string) {
    // Window is not defined in serviceworker of the Chrome extension
    if (typeof window !== 'undefined') {
      window.addEventListener('online', () => {
        this.isOnline = true
      })

      window.addEventListener('offline', () => {
        this.isOnline = false
      })

      this.isOnline = window.navigator.onLine
    } else {
      this.isOnline = true
    }
  }

  /**
   * Set a handler for the case we encounter an unauthorized
   * error from a request.
   */
  public onUnauthorized(handler: () => void): void {
    this.onUnauthorizedHandler = handler
  }

  /**
   * Set a handler for the case when the API is receiving unknown errors
   */
  public onUnknownErrors(handler: () => void): void {
    this.onUnknownErrorHandler = handler
  }

  /**
   * Execute a single RPC request. The RPC response is automatically
   * validated and an RpcError on failure.
   */
  public async rpc<T>(body: JsonRpcRequest, options?: RpcRequestOptions) {
    try {
      const response = await this.rpcRequest<T>(`${this.baseUrl}?${body.method}`, body, options)

      // Only call the handler if we were offline
      this.hasUnknownErrors && this.onOnlineHandler?.()

      // Set that we are not offline
      this.hasUnknownErrors = false
      this.unknownErrorCount = 0

      return validateRpcResponse(response)
    } catch (error) {
      // In case the error is an unauthorized error, call our handler
      if (
        isJsonRpcError(error) &&
        error.code === RpcErrorCode.InvalidToken &&
        this.onUnauthorizedHandler != null
      ) {
        this.onUnauthorizedHandler()
      } else if (!isJsonRpcError(error) && this.isOnline) {
        this.unknownErrorCount++

        // Set API in offline mode when the unknownErrorCount reaches above the threshold
        if (this.unknownErrorCount > 2) {
          !this.hasUnknownErrors && this.onUnknownErrorHandler?.()
          this.hasUnknownErrors = true
        }
      }

      // Propagate the error
      throw error
    }
  }

  /**
   * Used in the Chrome extension to add the authorization header
   */
  protected async getRpcRequestHeaders(): Promise<object> {
    return {}
  }

  /**
   * _rpcRequest executes a single, or batch of, RPC method(s)
   * on a given URL with provided options. Don't use this
   * method, rather use `rpc` or `rpcArray`.
   */
  private async rpcRequest<T>(
    url: string,
    body: unknown,
    { signal }: RpcRequestOptions = {}
  ): Promise<JsonRpcResponse<T>> {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...(await this.getRpcRequestHeaders()),
      },
      body: JSON.stringify(body),
      credentials: this.credentials,
      signal,
    })
    return response.json()
  }
}

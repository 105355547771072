import { RowType } from '@gain/utils/table'
import TableSortLabel from '@mui/material/TableSortLabel'

import { ListItemCountChip } from '../../../common/list-item-count-chip'
import Spacer from '../../spacer'
import { RenderHeaderParams } from '../../virtual-table'

type NameTableHeaderCellProps<Row extends RowType, Field extends keyof Row> = RenderHeaderParams<
  Row,
  Field
> & {
  maxLimit?: number
  maxLimitTooltip?: string
}

export function NameVirtualTableHeaderCell<Row extends RowType, Field extends keyof Row>({
  colDef,
  api,
  maxLimit,
  maxLimitTooltip,
}: NameTableHeaderCellProps<Row, Field>) {
  return (
    <>
      <TableSortLabel
        active={api.handleSortActive(colDef)}
        direction={api.handleSortDirection(colDef)}
        onClick={api.handleSort(colDef)}>
        {colDef.headerName}
      </TableSortLabel>
      <Spacer
        direction={'horizontal'}
        variant={'flex'}
        xs={1}
      />
      <ListItemCountChip
        hide={api.hideTotalSize}
        maxLimit={maxLimit}
        maxLimitTooltip={maxLimitTooltip}
        total={api.totalSize}
      />
    </>
  )
}

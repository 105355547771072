import { AdvisorDealListItem, DealListItem } from '@gain/rpc/app-model'
import { DealReason, DealSideType } from '@gain/rpc/shared-model'
import { isDefined, valueToEnum } from '@gain/utils/common'

import { DealListItemParty } from './deal-party.model'

export function partiesFromDealListItem(
  deal: DealListItem | AdvisorDealListItem,
  key: 'buyer' | 'seller'
): DealListItemParty[] {
  return deal[`${key}Names`].map((name, index) => ({
    name: name !== '' ? name : null,
    type: valueToEnum(DealSideType, deal[`${key}Types`][index]) || null,
    reason: valueToEnum(DealReason, deal[`${key}Reasons`][index]) || null,
    share: isDefined(deal[`${key}Shares`][index]) ? deal[`${key}Shares`][index] : null,
    sharePct: deal[`${key}SharePcts`][index] !== 0 ? deal[`${key}SharePcts`][index] : null,
    linkedId: deal[`${key}LinkedIds`][index] !== 0 ? deal[`${key}LinkedIds`][index] : null,
    logoFileUrl:
      deal[`${key}LogoFileUrls`][index] !== '' ? deal[`${key}LogoFileUrls`][index] : null,
    leading: deal[`${key}LeadingParties`][index],
  }))
}

import { AssetFinancialFootnote, DealNote } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'

import Card, { CardContent, CardHeader } from '../card'

export interface CardNotesProps {
  notes: (AssetFinancialFootnote | DealNote)[]
}

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
})

const StyledNote = styled('li')(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2, 0),
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

export default function CardNotes({ notes }: CardNotesProps) {
  return (
    <Card>
      <CardHeader title={'Notes'} />
      <StyledCardContent>
        {notes.map((note, index) => (
          <StyledNote key={index}>{note.text}</StyledNote>
        ))}
      </StyledCardContent>
    </Card>
  )
}

import { nanoid } from '@reduxjs/toolkit'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface JsonRpcRequest<Params = any | undefined> {
  Jsonrpc: string
  id: string
  method: string
  params?: Params
}

export interface BaseJsonRpcResponse {
  id: string
  jsonrpc: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface JsonRpcError<Data = any | undefined> {
  code: number
  message: string
  data: Data
}

export interface JsonRpcErrorResponse extends BaseJsonRpcResponse {
  error: JsonRpcError
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface JsonRpcSuccessResponse<Result = any> extends BaseJsonRpcResponse {
  result: Result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JsonRpcResponse<Response = any> =
  | JsonRpcErrorResponse
  | JsonRpcSuccessResponse<Response>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createMethod = <Params = any | undefined | never>(
  method: string,
  params?: Params
): JsonRpcRequest<Params> => ({
  Jsonrpc: '2.0',
  id: nanoid(),
  method,
  params,
})

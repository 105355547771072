import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { SWRConfiguration } from 'swr'

import { AppSwrInfiniteResponse, useAppInfiniteListSwr } from './use-app-infinite-list-swr'
import { AppListParams } from './use-app-list-swr'
import useMergeOptions from './use-merge-options'

export function createUseAppListInfiniteSwr<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>
>(method: Method, defaultOptions?: SWRConfiguration) {
  return (
    params?: AppListParams<Method, FilterItem>,
    options?: SWRConfiguration
  ): AppSwrInfiniteResponse<Item, FilterItem> => {
    const mergedOptions = useMergeOptions(defaultOptions, options)
    return useAppInfiniteListSwr<Method, Item, FilterItem>(method, params, mergedOptions)
  }
}

import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useField, useForm } from 'react-final-form'

import { FilterConfig } from '../filter-config/filter-config-model'
import { filterPath } from '../filter-utils'

interface FilterFieldContextType {
  filterIdPath: string
  filterValuePath: string
  config: FilterConfig
  resetValue: () => void
}

const FilterFieldContext = createContext<FilterFieldContextType | null>(null)

export function useFilterFieldContext() {
  const context = useContext(FilterFieldContext)

  if (!context) {
    throw new Error('FilterFieldContext is not provided')
  }

  return context
}

export function useFilterField<FieldValue>() {
  const { filterValuePath } = useFilterFieldContext()
  return useField<FieldValue>(filterValuePath, {})
}

interface FilterFieldContextProviderProps {
  groupIndex: number
  itemIndex: number
  config: FilterConfig
  children: ReactNode
}

export default function FilterFieldContextProvider({
  itemIndex,
  groupIndex,
  config,
  children,
}: FilterFieldContextProviderProps) {
  const form = useForm()

  const value = useMemo((): FilterFieldContextType => {
    const valuePath = filterPath(groupIndex, itemIndex, 'value')

    return {
      config: config,
      filterIdPath: filterPath(groupIndex, itemIndex, 'filterId'),
      filterValuePath: valuePath,
      resetValue: () => {
        form.change(valuePath, null)
      },
    }
  }, [config, groupIndex, itemIndex, form])

  return <FilterFieldContext.Provider value={value}>{children}</FilterFieldContext.Provider>
}

let canvas: HTMLCanvasElement | null = null

export function getTextWidth(
  text: string,
  fontFamily: string | undefined,
  fontSize: number | string | undefined,
  fontWeight: number | string | undefined = 'normal'
) {
  if (fontFamily === undefined) {
    throw new Error('fontFamily must be defined')
  }

  if (fontSize === undefined) {
    throw new Error('fontSize must be defined')
  }

  const canvasFont = getCanvasFont(fontFamily, fontSize, fontWeight)
  return getCanvasTextWidth(text, canvasFont)
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getCanvasTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  if (canvas === null) {
    canvas = document.createElement('canvas')
  }
  const context = canvas.getContext('2d')
  if (context === null) {
    throw new Error('2D rendering context not supported')
  }
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

function getCanvasFont(fontFamily: string, fontSize: number | string, fontWeight: number | string) {
  return `${fontWeight} ${typeof fontSize === 'number' ? `${fontSize}px` : fontSize} ${fontFamily}`
}

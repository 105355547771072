import { RowType } from '@gain/utils/table'
import { useCallback } from 'react'

import { ColumnFieldConfig, VirtualSort } from './virtual-table-model'

export type UseSortActiveCallbackFn<Row extends RowType> = <Field extends keyof Row>(
  config: ColumnFieldConfig<Row, Field>
) => boolean

export function useSortActiveCallback<Row extends RowType>(
  sort: VirtualSort<Row>[]
): UseSortActiveCallbackFn<Row> {
  return useCallback(
    <Field extends keyof Row>(config: ColumnFieldConfig<Row, Field>) => {
      const sortField =
        config.sortFields && config.sortFields.length ? config.sortFields[0] : config.field

      return sort.length > 0 && sortField === sort[0].field
    },
    [sort]
  )
}

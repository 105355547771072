import { styled } from '@mui/material/styles'

export interface PanelContentProps {
  dense?: boolean
}

export const PanelContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dense',
})<PanelContentProps>(({ theme, dense }) => ({
  padding: dense ? theme.spacing(1, 2) : theme.spacing(2, 3),
}))

import { ArrowUpRightIcon } from '@gain/components/icons'
import Link, { LinkProps } from '@mui/material/Link'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

export interface ListItemUrlProps {
  url: string
  label?: string | ReactNode
}

const StyledUrlSpan = styled('span')({
  display: 'flex',
  alignItems: 'center',

  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& svg': {
    fontSize: 16,
  },
})

export default function ListItemUrl({ url, label = 'Visit website' }: ListItemUrlProps) {
  return (
    <ListItemText
      slotProps={{
        primary: {
          component: Link,
          target: '_blank',
          rel: 'noopener noreferrer',
          href: url,
        } as LinkProps,
      }}>
      <StyledUrlSpan>
        {label} <ArrowUpRightIcon />
      </StyledUrlSpan>
    </ListItemText>
  )
}

import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import {
  default as MuiTableContainer,
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material/TableContainer'

interface TableContainerProps {
  absolute?: boolean
}

export const TableContainer = styled(
  (props: MuiTableContainerProps) => (
    <MuiTableContainer
      component={Paper}
      variant={'outlined'}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'absolute',
  }
)<TableContainerProps>(({ absolute }) => ({
  maxHeight: '100%',
  minHeight: 0,
  minWidth: 0,
  display: 'flex',
  flex: 1,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
  ...(absolute && {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
}))

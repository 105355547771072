import { useUpdateUserProfileCallback, useUserProfile } from '@gain/api/app/hooks'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, { useState } from 'react'

import NewsPreferencesDialog from '../../../routes/home/news-preferences-dialog'
import { SettingContainer } from './setting-container'
import { SettingDescription } from './setting-description'
import { SettingDivider } from './setting-divider'
import { SettingLink } from './setting-link'
import { SettingTitle } from './setting-title'

export default function NewsUpdates() {
  const { data: profile } = useUserProfile()
  const updateProfile = useUpdateUserProfileCallback()
  const [newsDialogOpen, setNewsDialogOpen] = useState(false)

  return (
    <SettingContainer>
      <SettingTitle>News updates</SettingTitle>
      <SettingDescription>
        <NewsPreferencesDialog
          onClose={() => setNewsDialogOpen(false)}
          open={newsDialogOpen}
        />
        Receive a weekly news update tailored to your{' '}
        <SettingLink onClick={() => setNewsDialogOpen(true)}>news preferences</SettingLink>.
      </SettingDescription>
      <SettingDivider />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!profile?.emailSourcingUpdates}
            onChange={(event) => updateProfile({ emailSourcingUpdates: event.target.checked })}
          />
        }
        label={'Email me a weekly news update'}
      />
    </SettingContainer>
  )
}

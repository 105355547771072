import {
  BriefcaseIcon,
  CompanyIcon,
  FlagIcon,
  GlobeIcon,
  LeafIcon,
  MoneyBagIcon,
  ShuffleIcon,
  StarOutlinedIcon,
} from '@gain/components/icons'
import { FilterableInvestorListItem } from '@gain/rpc/app-model'
import { ratingEsgMap, ratingInvestmentCriteriaMap } from '@gain/utils/investment-criteria'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  advisor,
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  fteRangeCategory,
  rangeCurrency,
  rangeNumber,
  rangeYear,
  rating,
  region,
  sector,
  tag,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const INVESTOR_FILTER_FIELDS = [
  'operationalHqCountryCode',
  'fteRangeCategory',

  'assetsFiltered',
  'assetRegions',
  'assetSubsectors',
  'advisorIds',
  'asset.tagIds',

  'asset.ebitdaEur',
  'assetEbitdaMedianEur',
  'dryPowderMinEur',

  'asset.ratingGrowth',
  'asset.ratingOrganicGrowth',
  'asset.ratingGrossMargin',
  'asset.ratingEbitda',
  'asset.ratingConversion',
  'asset.ratingNonCyclical',
  'asset.ratingContracted',
  'asset.ratingLeader',
  'asset.ratingMultinational',
  'asset.ratingBuyAndBuild',

  'fundsRaisedLastFiveYearsEur',
  'liveFundsCount',
  'liveFundSizeEur',
  'flagshipFundSizeEur',
  'flagshipFundVintageYear',

  'dealsEntriesTotalLastFiveYears',
  'dealsExitTotalLastFiveYears',
  'dealsAddOnsTotalLastFiveYears',
  'dealsTotalLastFiveYears',

  'assetsMedianMarketSegmentRatingOverall',
  'assetsMedianMarketSegmentRatingEnvironmental',
  'assetsMedianMarketSegmentRatingSocial',
] as const

export type InvestorFilterField = (typeof INVESTOR_FILTER_FIELDS)[number]

export const INVESTOR_FILTER_MAP = createFilterMap<
  FilterableInvestorListItem,
  typeof INVESTOR_FILTER_FIELDS
>(
  region('operationalHqCountryCode', 'Investor HQ'),
  fteRangeCategory('fteRangeCategory', 'FTEs'),

  rangeNumber('assetsFiltered', 'Number of companies', {
    icon: CompanyIcon,
    hint: 'The number of companies of the investor portfolio that must match the region, sector and EBITDA range filter.',
  }),
  region('assetRegions', 'Active in region', { icon: GlobeIcon }),
  sector('assetSubsectors', 'Active in sector'),
  advisor('advisorIds', 'Advisors'),

  tag('asset.tagIds', 'Tags'),

  rangeCurrency('asset.ebitdaEur', 'EBITDA range'),
  rangeCurrency('assetEbitdaMedianEur', 'Median EBITDA'),
  rangeCurrency('dryPowderMinEur', 'Drypowder estimate', { maxFilterField: 'dryPowderMaxEur' }),

  // Investment criteria
  rating('asset.ratingGrowth', ratingInvestmentCriteriaMap.ratingGrowth),
  rating('asset.ratingOrganicGrowth', ratingInvestmentCriteriaMap.ratingOrganicGrowth),
  rating('asset.ratingGrossMargin', ratingInvestmentCriteriaMap.ratingGrossMargin),
  rating('asset.ratingEbitda', ratingInvestmentCriteriaMap.ratingEbitda),
  rating('asset.ratingConversion', ratingInvestmentCriteriaMap.ratingConversion),
  rating('asset.ratingNonCyclical', ratingInvestmentCriteriaMap.ratingNonCyclical),
  rating('asset.ratingContracted', ratingInvestmentCriteriaMap.ratingContracted),
  rating('asset.ratingLeader', ratingInvestmentCriteriaMap.ratingLeader),
  rating('asset.ratingMultinational', ratingInvestmentCriteriaMap.ratingMultinational),
  rating('asset.ratingBuyAndBuild', ratingInvestmentCriteriaMap.ratingBuyAndBuild),

  rangeCurrency('fundsRaisedLastFiveYearsEur', 'Fundraising (L5Y)', { icon: MoneyBagIcon }),
  rangeNumber('liveFundsCount', 'Live funds'),
  rangeCurrency('liveFundSizeEur', 'Live fund size'),
  rangeCurrency('flagshipFundSizeEur', 'Flagship fund size'),
  rangeYear('flagshipFundVintageYear', 'Flagship fund vintage date'),

  rangeNumber('dealsEntriesTotalLastFiveYears', 'Entries (L5Y)'),
  rangeNumber('dealsExitTotalLastFiveYears', 'Exists (L5Y)'),
  rangeNumber('dealsAddOnsTotalLastFiveYears', 'Add-ons (L5Y)'),
  rangeNumber('dealsTotalLastFiveYears', 'Deals (L5Y)'),

  rating('assetsMedianMarketSegmentRatingOverall', ratingEsgMap.ratingOverall),
  rating('assetsMedianMarketSegmentRatingEnvironmental', ratingEsgMap.ratingEnvironmental),
  rating('assetsMedianMarketSegmentRatingSocial', ratingEsgMap.ratingSocial)
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const INVESTOR_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<FilterableInvestorListItem, InvestorFilterField>
> = [
  INVESTOR_FILTER_MAP.assetsFiltered,
  INVESTOR_FILTER_MAP.fundsRaisedLastFiveYearsEur,
  dropdownMenuGroup('flagshipFund', 'Funds', {
    icon: FlagIcon,
    children: [
      INVESTOR_FILTER_MAP.flagshipFundSizeEur,
      INVESTOR_FILTER_MAP.dryPowderMinEur,
      INVESTOR_FILTER_MAP.liveFundsCount,
      INVESTOR_FILTER_MAP.liveFundSizeEur,
    ],
  }),
  dropdownMenuGroup('portfolio', 'Portfolio', {
    icon: BriefcaseIcon,
    children: [
      INVESTOR_FILTER_MAP['asset.ebitdaEur'],
      INVESTOR_FILTER_MAP.assetEbitdaMedianEur,
      INVESTOR_FILTER_MAP.assetRegions,
      INVESTOR_FILTER_MAP.assetSubsectors,
      INVESTOR_FILTER_MAP['asset.tagIds'],
    ],
  }),
  dropdownMenuGroup('deals', 'Deals', {
    icon: ShuffleIcon,
    children: [
      INVESTOR_FILTER_MAP.dealsTotalLastFiveYears,
      INVESTOR_FILTER_MAP.dealsEntriesTotalLastFiveYears,
      INVESTOR_FILTER_MAP.dealsExitTotalLastFiveYears,
      INVESTOR_FILTER_MAP.dealsAddOnsTotalLastFiveYears,
    ],
  }),
  INVESTOR_FILTER_MAP.operationalHqCountryCode,
  INVESTOR_FILTER_MAP.fteRangeCategory,
  dropdownMenuGroup('assessment', 'Assessment', {
    icon: StarOutlinedIcon,
    children: [
      INVESTOR_FILTER_MAP['asset.ratingGrowth'],
      INVESTOR_FILTER_MAP['asset.ratingOrganicGrowth'],
      INVESTOR_FILTER_MAP['asset.ratingGrossMargin'],
      INVESTOR_FILTER_MAP['asset.ratingEbitda'],
      INVESTOR_FILTER_MAP['asset.ratingConversion'],
      INVESTOR_FILTER_MAP['asset.ratingNonCyclical'],
      INVESTOR_FILTER_MAP['asset.ratingContracted'],
      INVESTOR_FILTER_MAP['asset.ratingLeader'],
      INVESTOR_FILTER_MAP['asset.ratingMultinational'],
      INVESTOR_FILTER_MAP['asset.ratingBuyAndBuild'],
    ],
  }),
  dropdownMenuGroup('esg', 'ESG', {
    icon: LeafIcon,
    children: [
      INVESTOR_FILTER_MAP['assetsMedianMarketSegmentRatingOverall'],
      INVESTOR_FILTER_MAP['assetsMedianMarketSegmentRatingEnvironmental'],
      INVESTOR_FILTER_MAP['assetsMedianMarketSegmentRatingSocial'],
    ],
  }),
  INVESTOR_FILTER_MAP.advisorIds,
]

export const INVESTOR_DEFAULT_FILTERS: FilterModel<
  FilterableInvestorListItem,
  InvestorFilterField
> = [
  filterValueGroup(filterValueItem('asset.tagIds')),
  filterValueGroup(filterValueItem('operationalHqCountryCode')),
  filterValueGroup(filterValueItem('assetRegions')),
  filterValueGroup(filterValueItem('assetSubsectors')),
  filterValueGroup(filterValueItem('asset.ebitdaEur')),
  filterValueGroup(filterValueItem('assetsFiltered')),
]

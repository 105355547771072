import { AssetLegalEntity } from '@gain/rpc/app-model'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import { useAssetLegalEntities } from '../../use-asset-legal-entities'
import AssetLegalEntitiesTable from './asset-legal-entities-table'

interface AssetLegalEntitiesCardProps extends CardProps {
  legalEntities: AssetLegalEntity[]
  processedAnnualReportFileIds: number[]
}

export default function AssetLegalEntitiesCard({
  legalEntities: assetLegalEntities,
  processedAnnualReportFileIds,
  ...cardProps
}: AssetLegalEntitiesCardProps) {
  const legalEntities = useAssetLegalEntities(assetLegalEntities)

  if (!legalEntities.data) {
    return null
  }

  return (
    <Card {...cardProps}>
      <CardHeader title={'Legal entities'} />
      <AssetLegalEntitiesTable legalEntities={legalEntities.data} />
    </Card>
  )
}

import { BOOKMARKS_LIST_PATH } from '@app/routes/utils'
import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { CustomAssetList } from '@gain/rpc/app-model'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useCheckBookmarksListError } from './bookmarks-hooks'
import { useMutateBookmarksList } from './bookmarks-list-hooks'

async function getAssetName(fetcher: ReturnType<typeof useRpcClient>, assetIds: number[]) {
  if (assetIds.length === 1) {
    try {
      const data = await fetcher({
        method: 'data.getAsset',
        params: { assetId: assetIds[0], preview: false },
      })
      if (data?.generalInfo) {
        return data.generalInfo.name
      }
    } catch (err) {
      // Can safely ignore any errors
    }
  }

  return `${assetIds.length} companies`
}

const SNACKBAR_KEY = 'bookmarks-list-add-assets'

function useShowSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  const fetcher = useRpcClient()
  const history = useHistory()

  return useCallback(
    async (list: CustomAssetList, assetIds: number[]) => {
      const path = generatePath(BOOKMARKS_LIST_PATH, { listId: list.id })
      const subject = await getAssetName(fetcher, assetIds)

      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            action={{
              title: 'View',
              onClick: () => history.push(path),
            }}
            id={SNACKBAR_KEY}
            message={`${subject} added to “${list.title}”`}
            variant={'success'}
          />
        ),
        preventDuplicate: true,
      })
    },
    [enqueueSnackbar, fetcher, history]
  )
}

export function useBookmarkListAddAssets() {
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()
  const fetcher = useRpcClient()
  const showSnackbar = useShowSnackbar()

  return useCallback(
    async (list: CustomAssetList, assetIds: number[], disableSnackbar = false) => {
      try {
        const updatedList = await fetcher({
          method: 'lists.customAssetListAddAssets',
          params: { id: list.id, assetIds },
        })

        if (!disableSnackbar) {
          showSnackbar(list, assetIds)
        }

        mutateList(list.id, () => updatedList)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher, showSnackbar]
  )
}

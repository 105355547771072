import { PaletteOptions } from '@mui/material/styles'

import { grey } from './colors/grey'

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

export const palette: PaletteOptions = {
  primary: {
    main: '#2054F5', // Tint color
    light: '#768CFB',
  },
  error: {
    main: '#ED5565', // Support color 4
    dark: '#BF0014',
  },
  warning: {
    main: '#EDBD2E', // Support color 3
    light: '#FCF1D8',
    dark: '#B68900',
  },
  info: {
    main: '#3B85D8', // Support color 1
  },
  success: {
    main: '#45BFA2', // Support color 2
  },
  grey: {
    main: grey[300],
    dark: grey[400],
  },
  text: {
    primary: '#11142D',
    secondary: '#71829C',
  },
  background: {
    default: '#F7F9FA', // Background color 2
    paper: '#FFFFFF', // Background color 1
  },
  divider: '#E7E9EA', // Support color 5,
  chart: [
    '#748DFA',
    '#67CFBC',
    '#F5DD90',
    '#D573B4',
    '#9873D5',
    '#BADFAD',
    '#66A373',
    '#5D7B64',
    '#4F553F',
    '#8D8D8D',
  ],
  cards: {
    asset: '#D8DFE7',
    owner: '#CEDEEF',
  },
  action: {
    hoverOpacity: 0.1,
  },
  shadow: {
    level1a: '0px 8px 24px rgba(0, 0, 0, 0.06)',
    level1b: '0px 5px 16px rgba(0, 0, 0, 0.06)',
    level1c: '0px 1px 3px rgba(0, 0, 0, 0.06)',
    level1d: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  },
  mode: 'light',
}

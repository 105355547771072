import InfoButton from '../info-button'
import type { RowConfig } from './horizontal-table-utils'

export interface HorizontalHeaderCellProps<Row> {
  row: Row
  rowConfig: RowConfig<Row>
}

export default function HorizontalHeaderCell<Row>({
  row,
  rowConfig,
}: HorizontalHeaderCellProps<Row>) {
  const label = rowConfig.getHeaderLabel(row)
  const description = rowConfig.getHeaderDescription?.(row)

  return (
    <>
      {label}
      {description && (
        <InfoButton
          dialogMessage={description}
          dialogTitle={typeof label === 'string' ? label : undefined}
        />
      )}
    </>
  )
}

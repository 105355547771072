import { DealListItem } from '@gain/rpc/app-model'
import * as Shared from '@gain/rpc/shared-model'
import { useEffect, useState } from 'react'

import { DealFilterField } from '../../../deal/deal-filter-bar'
import { useFilterContext } from '../../../filter/filter-bar'

/**
 * useDealTypeFilterValues uses the filter context to return the selected deal
 * types from the filters.
 */
export default function useDealTypeFilterValues() {
  const filterApi = useFilterContext<DealListItem, DealFilterField>()
  const [dealTypeFilterValues, setDealTypeFilterValues] = useState<Shared.DealReason[]>([])

  // Pick up changes in the deal type filter
  useEffect(() => {
    if (!filterApi) {
      return
    }

    const unsubscribe = filterApi.subscribe((model) => {
      // Find a group that contains the reasons filter
      const reasonsFilterGroup = model.find((filterGroup) =>
        filterGroup.value.some((filter) => filter.filterId === 'reasons')
      )
      // Now find the actual filter and take it's value
      const reasonsFilter = reasonsFilterGroup?.value.find(
        (filter) => filter.filterId === 'reasons'
      )?.value

      if (reasonsFilter) {
        setDealTypeFilterValues(reasonsFilter)
      } else {
        setDealTypeFilterValues([])
      }
    })

    return () => {
      unsubscribe()
    }
  }, [filterApi])

  return dealTypeFilterValues
}

import { Components, Theme } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableSortLabelClasses } from '@mui/material/TableSortLabel'

export const TABLE_HEAD_CELL_HEIGHT = 48
export const TABLE_BODY_CELL_HEIGHT = 64
export const TABLE_HEAD_CELL_SMALL_HEIGHT = 48
export const TABLE_BODY_CELL_SMALL_HEIGHT = 36

export const MuiTableCell = (theme: Theme): Components['MuiTableCell'] => ({
  styleOverrides: {
    root: ({ ownerState }) => ({
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      padding: theme.spacing(0, 3),
      borderBottom: `1px solid ${theme.palette.divider}`,
      height: TABLE_BODY_CELL_HEIGHT,
      maxHeight: TABLE_BODY_CELL_HEIGHT,
      minHeight: TABLE_BODY_CELL_HEIGHT,
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      boxSizing: 'border-box',
      color: theme.palette.text.primary,

      ...(ownerState.size === 'small' && {
        padding: theme.spacing(0, 1.25),
        height: TABLE_BODY_CELL_SMALL_HEIGHT,
        maxHeight: TABLE_BODY_CELL_SMALL_HEIGHT,
        minHeight: TABLE_BODY_CELL_SMALL_HEIGHT,
        [`&.${tableCellClasses.body}`]: {
          borderBottomWidth: 0,
        },
        '&:first-child': {
          paddingLeft: theme.spacing(3),
        },
        '&:last-child': {
          paddingRight: theme.spacing(3),
        },
      }),

      [`&.${tableCellClasses.alignRight}`]: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
    }),
    head: ({ ownerState }) => ({
      height: TABLE_HEAD_CELL_HEIGHT,
      maxHeight: TABLE_HEAD_CELL_HEIGHT,
      minHeight: TABLE_HEAD_CELL_HEIGHT,
      backgroundColor: theme.palette.grey['100'],
      color: theme.palette.text.secondary,

      ...(ownerState.size !== 'small' && {
        fontWeight: 'bold',
        fontSize: '14px',
      }),

      ...(ownerState.size === 'small' && {
        marginBottom: 8,
        height: TABLE_HEAD_CELL_SMALL_HEIGHT - 8,
        maxHeight: TABLE_HEAD_CELL_SMALL_HEIGHT - 8,
        minHeight: TABLE_HEAD_CELL_SMALL_HEIGHT - 8,
        ...theme.typography.overline,
      }),

      [`&.${tableCellClasses.alignRight} .${tableSortLabelClasses.root}`]: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
    }),
    alignCenter: {
      justifyContent: 'center',
    },
  },
})

import { styled } from '@mui/material/styles'

const StyledBarContainer = styled('div')({
  maxWidth: 200,
})

const StyledBar = styled('div')({
  height: 8,
  borderRadius: 2,
})

export interface BarProps {
  color: string
  percentage: number | null
}

export default function Bar({ color, percentage }: BarProps) {
  if (percentage === null) {
    return null
  }

  return (
    <StyledBarContainer>
      <StyledBar sx={{ backgroundColor: color, width: `${percentage}%` }} />
    </StyledBarContainer>
  )
}

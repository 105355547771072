import { useAppSwr } from '@gain/api/swr'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { isDefined } from '@gain/utils/common'
import React, { PropsWithChildren, useCallback, useState } from 'react'

import TooltipLegalEntityTitle from './tooltip-legal-entity-title.component'

export type TooltipLegalEntityProps = PropsWithChildren<
  Omit<TooltipProps, 'title' | 'open' | 'id'> & {
    id: number | null
    open?: boolean
  }
>

export default function TooltipLegalEntity({
  id,
  variant = 'preview',
  disablePadding = true,
  children,
  onOpen,
  open = false,
  ...props
}: TooltipLegalEntityProps) {
  const [shouldOpen, setShouldOpen] = useState<boolean>(open)
  const legalEntity = useAppSwr('data.getLegalEntity', shouldOpen && isDefined(id) ? { id } : null)
  const handleOpen = useCallback(() => setShouldOpen(true), [])
  const handleClose = useCallback(() => setShouldOpen(false), [])
  const isOpen = shouldOpen && isDefined(legalEntity.data)

  return (
    <Tooltip
      disablePadding={disablePadding}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      title={
        legalEntity.data ? (
          <TooltipLegalEntityTitle
            legalEntity={legalEntity.data}
            onClick={(event) => event.stopPropagation()}
          />
        ) : (
          ''
        )
      }
      variant={variant}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  )
}

import { ChevronRightIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { ArticleListItem } from '@gain/rpc/app-model'
import { formatDate } from '@gain/utils/date'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import { generateArticlePagePath } from '../../../routes/utils'

const StyledContainer = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(1),
  },
}))

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.grey['600'],
  fontSize: 16,
}))

export interface ListAssetNewsItemProps {
  article: ArticleListItem
}

export function ListAssetNewsItem({ article }: ListAssetNewsItemProps) {
  return (
    <StyledContainer
      href={generateArticlePagePath({
        articleId: article.id,
        articleTitle: article.title || '-',
        preview: false,
      })}
      underline={'none'}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        spacing={1}>
        <Stack direction={'column'}>
          <Typography
            color={'text.secondary'}
            variant={'body2'}>
            {formatDate(article.date, { format: 'monthYear' })}
          </Typography>
          <Typography
            color={'text.primary'}
            variant={'body2'}>
            {article.title}
          </Typography>
        </Stack>
        <StyledChevronRightIcon />
      </Stack>
    </StyledContainer>
  )
}

export default ListAssetNewsItem

import ListSubheader from '@mui/material/ListSubheader'
import { alpha, styled } from '@mui/material/styles'
import React, { ReactElement } from 'react'

const StyledListSubheader = styled(ListSubheader, {
  shouldForwardProp: (propName) => propName !== 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  boxSizing: 'border-box',
  ...(isOpen && {
    height: 32,
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0)}`,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create(
        ['width', 'border-bottom', 'margin-top', 'margin-bottom', 'color', 'height', 'min-height'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }
      ),
    },
  }),
  ...(!isOpen && {
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginTop: 8,
      marginBottom: 8,
      color: 'rgba(0, 0, 0, 0)',
      minHeight: 0,
      height: 0,
      overflow: 'hidden',
      transition: theme.transitions.create(
        ['width', 'border-bottom', 'margin-top', 'margin-bottom', 'color', 'height', 'min-height'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
      ),
    },
  }),
}))

const StyledActions = styled('span', {
  shouldForwardProp: (propName) => propName !== 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  boxSizing: 'border-box',
  ...(isOpen && {
    [theme.breakpoints.up('md')]: {
      opacity: 1,
      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  ...(!isOpen && {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
}))

export interface MenuSubHeaderProps {
  title: string
  isOpen: boolean
  actions?: ReactElement | null | false
}

export function MenuSubHeader({ title, actions, isOpen }: MenuSubHeaderProps) {
  return (
    <StyledListSubheader isOpen={isOpen}>
      {title}
      {actions && <StyledActions isOpen={isOpen}>{actions}</StyledActions>}
    </StyledListSubheader>
  )
}

import { useIndustryMarketSegmentList } from '@gain/api/app/hooks'
import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { ColumnVisibilityModel } from '@gain/utils/table'
import React from 'react'
import { generatePath } from 'react-router-dom'

import ContentLink, { ContentLinkCellContainer } from '../../../../common/content-link'
import { ColumnConfig } from '../../../../common/table'
import { RatingTableCell } from '../../../../features/asset/asset-list-item-table/asset-list-item-table-cell-rating'
import ContentLinkIndustry from '../../../../features/industry/industry-content-link'
import { ROUTE_SEGMENT_PATH } from '../../../utils'

export function useAssetIndustryMarketSegments(assetId: number) {
  return useIndustryMarketSegmentList({
    filter: [listFilter<IndustryMarketSegmentListItem>('linkedAssetIds', '=', assetId)],
    limit: 20,
    sort: [
      {
        field: 'name',
        direction: 'desc',
      },
    ],
  })
}

export const columnVisibility: ColumnVisibilityModel<IndustryMarketSegmentListItem> = {
  ratingMedianConversion: 1200,
  ratingMedianEbitda: 1000,
  ratingMedianOrganicGrowth: 800,
  name: 600,
}

export const assetIndustryMarketColumns: Array<ColumnConfig<IndustryMarketSegmentListItem>> = [
  {
    headerName: 'Industry',
    field: 'industryId',
    renderCell: ({ row }) => (
      <ContentLinkCellContainer>
        <ContentLinkIndustry
          id={row.industryId}
          logoFileUrl={row.industrySmallImageFileUrl}
          title={row.industryTitle}
        />
      </ContentLinkCellContainer>
    ),
  },
  {
    headerName: 'Segment',
    field: 'name',
    align: 'left',
    width: 200,
    renderCell: ({ value, row }) => (
      <ContentLink
        href={generatePath(ROUTE_SEGMENT_PATH, {
          id: row.id,
          title: row.name || '',
        })}
        label={value}
      />
    ),
  },
  {
    headerName: 'Organic growth',
    field: 'ratingMedianOrganicGrowth',
    align: 'left',
    width: 200,
    renderCell: (params) => (
      <RatingTableCell
        rating={params.value}
        type={'ratingOrganicGrowth'}
      />
    ),
  },
  {
    headerName: 'EBITDA margin',
    field: 'ratingMedianEbitda',
    align: 'left',
    width: 200,
    renderCell: (params) => (
      <RatingTableCell
        rating={params.value}
        type={'ratingEbitda'}
      />
    ),
  },
  {
    headerName: 'Cash conversion',
    field: 'ratingMedianConversion',
    align: 'left',
    width: 200,
    renderCell: (params) => (
      <RatingTableCell
        rating={params.value}
        type={'ratingConversion'}
      />
    ),
  },
  {
    headerName: 'Region',
    field: 'industryScope',
    align: 'right',
    width: 120,
  },
]

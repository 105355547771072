import { DealListItem } from '@gain/rpc/app-model'
import { DealStatus } from '@gain/rpc/shared-model'
import { formatDealStatus } from '@gain/utils/deal'
import { RenderCellParams } from '@gain/utils/table'
import Chip from '@mui/material/Chip'

export default function DealStatusTableCell({
  value,
}: RenderCellParams<DealListItem, 'dealStatus'>) {
  if (value === null) {
    return <>-</>
  }

  const status = value as DealStatus
  return (
    <Chip
      color={status === DealStatus.Aborted ? 'error' : 'warning'}
      label={formatDealStatus(status)}
    />
  )
}

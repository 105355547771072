import { AdvisorDealListItem, DealListItem } from '@gain/rpc/app-model'
import { formatDealListItemTitle, getDealReasonColor } from '@gain/utils/deal'

import { NameVirtualTableCell } from '../../../../common/virtual-table/cells'
import useDealTypeFilterValues from './use-deal-type-filter-values'

interface DealDescriptionTableCellProps {
  deal: DealListItem | AdvisorDealListItem
}

export default function DealNameTableCell({ deal }: DealDescriptionTableCellProps) {
  const dealTypeFilterValues = useDealTypeFilterValues()

  return (
    <NameVirtualTableCell
      description={formatDealListItemTitle(deal, dealTypeFilterValues)}
      logoDot={getDealReasonColor(deal.dealStatus)}
      logoFileUrl={deal.assetLogoFileUrl}
      logoRegion={deal.region}
      name={deal.asset}
    />
  )
}

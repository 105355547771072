import { DealFactFloat } from '@gain/rpc/app-model'
import React from 'react'

import { KeyValueListItem } from '../../../common/key-value/key-value-list'
import DealMetric from './deal-metric.component'

export interface DealMetricListItem {
  label: string
  formatter: (value: number) => string
  value: DealFactFloat | null
}

export default function DealMetricListItem({ label, formatter, value }: DealMetricListItem) {
  if (value === null) {
    return null
  }

  return (
    <KeyValueListItem
      label={label}
      value={
        <DealMetric
          formatter={formatter}
          value={value}
        />
      }
    />
  )
}

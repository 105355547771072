import { ListItemKey } from '@gain/rpc/list-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { isNull } from '@gain/utils/typescript'
import Stack from '@mui/material/Stack'
import { useCallback, useMemo } from 'react'
import { useField } from 'react-final-form'

import {
  FilterConfigRange,
  FilterConfigRangeCurrency,
  FilterCurrencyRangeValue,
  FilterRangeValue,
  FilterRangeValuePart,
} from '../filter-config/filter-config-model'
import { formatRangeFilterValue } from '../filter-label/filter-value-formatter'
import { isFilterConfigRange } from '../from-filter-model'
import { useTrackFilterEvent } from '../use-track-filter-event'
import FilterRangeCurrencyInput from './filter-range-currency-input'
import FilterRangeDateInput from './filter-range-date-input'
import FilterRangeNumberInput from './filter-range-number-input'
import FilterRangeSelect from './filter-range-select'

export interface FilterRangeProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigRange<Item, FilterField> | FilterConfigRangeCurrency<Item, FilterField>
}

export default function FilterRange<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterRangeProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()
  const formatCurrencyRange = useFormatCurrencyRangeCallback()
  const field = useField<FilterRangeValue>(path)

  const [min, max] = useMemo(() => {
    if (Array.isArray(field.input.value)) {
      return field.input.value
    }

    return [null, null]
  }, [field.input.value])

  const handleChange = useCallback(
    (part: 'min' | 'max') => (value: FilterRangeValuePart) => {
      const newValue: FilterRangeValue | FilterCurrencyRangeValue =
        part === 'min' ? [value, max] : [min, value]
      field.input.onChange(newValue.every(isNull) ? null : newValue)

      let eventLabel: string | null = null
      if (isFilterConfigRange(filter)) {
        eventLabel = formatRangeFilterValue(newValue, filter)
      } else if (typeof newValue[0] !== 'string' && typeof newValue[1] !== 'string') {
        eventLabel = formatCurrencyRange(newValue[0], newValue[1], { round: 'significant' })
      }

      if (eventLabel) {
        trackEvent(`Filter ${filter.label} value change`, eventLabel, null, 500)
      }
    },
    [max, min, field.input, filter, formatCurrencyRange, trackEvent]
  )

  const getPlaceHolder = useCallback(
    (part: 'min' | 'max') => {
      if (typeof filter.placeholder === 'string') {
        return filter.placeholder
      }
      if (Array.isArray(filter.placeholder)) {
        return filter.placeholder[part === 'min' ? 0 : 1]
      }
      return filter.placeholder
    },
    [filter]
  )

  if (filter.type === 'range-currency') {
    return (
      <Stack
        direction={'row'}
        gap={0.375}>
        <FilterRangeCurrencyInput
          isMillions={filter.isMillions}
          onChange={handleChange('min')}
          placeholder={getPlaceHolder('min')}
          value={min as number | null}
          autoFocus
        />
        <FilterRangeCurrencyInput
          isMillions={filter.isMillions}
          onChange={handleChange('max')}
          placeholder={getPlaceHolder('max')}
          value={max as number | null}
        />
      </Stack>
    )
  }

  if (filter.options) {
    if (filter.valueType === 'month') {
      throw new Error('Options are not supported for valueType: "month"')
    }

    return (
      <Stack
        direction={'column'}
        gap={1}>
        <FilterRangeSelect
          label={'Min'}
          onChange={handleChange('min')}
          options={filter.options}
          value={min as FilterRangeValuePart<'number'>}
          autoFocus
        />
        <FilterRangeSelect
          label={'Max'}
          onChange={handleChange('max')}
          options={filter.options}
          value={max as FilterRangeValuePart<'number'>}
        />
      </Stack>
    )
  }

  if (filter.valueType === 'month' || filter.valueType === 'date') {
    return (
      <Stack
        direction={'row'}
        gap={0.375}>
        <FilterRangeDateInput
          filter={filter}
          onChange={handleChange('min')}
          type={filter.valueType}
          value={min as FilterRangeValuePart<'month'>}
          autoFocus
        />
        <FilterRangeDateInput
          filter={filter}
          onChange={handleChange('max')}
          type={filter.valueType}
          value={max as FilterRangeValuePart<'month'>}
        />
      </Stack>
    )
  }

  return (
    <Stack
      direction={'row'}
      gap={0.375}>
      <FilterRangeNumberInput
        filter={filter}
        onChange={handleChange('min')}
        placeholder={getPlaceHolder('min')}
        value={min as FilterRangeValuePart<'number'>}
        autoFocus
      />
      <FilterRangeNumberInput
        filter={filter}
        onChange={handleChange('max')}
        placeholder={getPlaceHolder('max')}
        value={max as FilterRangeValuePart<'number'>}
      />
    </Stack>
  )
}

import { BriefcaseMoneyIcon } from '@gain/components/icons'
import { formatAssetShareholderShareLabel } from '@gain/utils/asset'

import ListItem from '../../../../common/list/list-item'
import { generateInvestorPagePath } from '../../../utils'
import { Shareholder } from './asset-shareholder-util'

export interface ListItemAssetShareholderProps {
  shareholder: Shareholder
}

export default function ListItemAssetShareholder({ shareholder }: ListItemAssetShareholderProps) {
  return (
    <ListItem
      avatarProps={{
        src: shareholder.investor?.logoFileUrl || undefined,
        children: <BriefcaseMoneyIcon />,
      }}
      href={generateInvestorPagePath({
        investorId: shareholder.investorId,
        investorName: shareholder.investor?.name || '-',
      })}
      primary={shareholder.investor?.name || ''}
      secondary={formatAssetShareholderShareLabel(shareholder.share)}
    />
  )
}

import Typography from '@gain/components/typography'
import { isDefined, isNotDefined } from '@gain/utils/common'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns/format'
import { parseJSON } from 'date-fns/parseJSON'
import React, { useMemo } from 'react'

export interface TableCellFinancialAmountProps {
  amount: number | null
  date: string | null
  formatAmount: (value: number | null) => string
}

const StyledRoot = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
})

export function TableCellFinancialAmount({
  amount,
  date,
  formatAmount,
}: TableCellFinancialAmountProps) {
  const dateLabel = useMemo(() => {
    if (!isDefined(amount) || !isDefined(date)) {
      return null
    }

    const parsed = parseJSON(date)
    // prettier-ignore
    // eslint-disable-next-line no-useless-escape
    return format(parsed, 'MM \'\'yy')
  }, [amount, date])

  if (isNotDefined(amount)) {
    return <>-</>
  }

  return (
    <StyledRoot>
      <Typography
        color={'textPrimary'}
        variant={'body2'}
        noWrap>
        {formatAmount(amount)}
      </Typography>
      {dateLabel && (
        <>
          &nbsp;
          <Chip
            label={dateLabel}
            size={'small'}
          />
        </>
      )}
    </StyledRoot>
  )
}

import { ChevronRightIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'

import { AvatarProps } from '../../avatar'
import Logo, { LogoProps } from '../../logo'

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  color: theme.palette.text.primary,
}))

const StyledChevron = styled(ChevronRightIcon)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.grey['600'],
}))

interface ListItemProps {
  primary: string
  secondary?: string
  href?: string | false | null
  avatarProps?: AvatarProps
  action?: ReactElement
  region?: LogoProps['region']
}

export default function ListItem({
  primary,
  secondary,
  href,
  avatarProps = {},
  action,
  region,
}: ListItemProps) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      justifyContent={'space-between'}
      spacing={1.5}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        minWidth={0} // Allow contained text to truncate
        spacing={1.5}>
        {(!!avatarProps.src || !!avatarProps?.children) && (
          <Logo
            avatarProps={{
              imagePadding: 0.5,
              ...avatarProps,
            }}
            defaultIcon={avatarProps.children}
            region={region}
            size={24}
            src={avatarProps?.src}
            variant={'rounded'}
          />
        )}
        <Stack
          direction={'column'}
          minWidth={0}
          spacing={0}>
          {!href && (
            <Typography
              color={'text.primary'}
              variant={'body2'}
              noWrap>
              {primary}
            </Typography>
          )}
          {!!href && (
            <StyledLink href={href}>
              <Typography
                color={'text.primary'}
                variant={'body2'}
                noWrap>
                {primary}
              </Typography>
              <StyledChevron />
            </StyledLink>
          )}
          {secondary && (
            <Typography
              color={'text.secondary'}
              variant={'body2'}
              noWrap>
              {secondary}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        alignItems={'center'}
        flexDirection={'row'}
        gap={0.5}>
        {action}
      </Stack>
    </Stack>
  )
}

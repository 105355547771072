import {
  useArticleList,
  useAssetConferenceEditions,
  useAssetRelevantIndustries,
  useIndustryList,
} from '@gain/api/app/hooks'
import { ArticleListItem, Asset, IndustryListItem } from '@gain/rpc/app-model'
import { ListArgs } from '@gain/rpc/list-model'
import { ArticleType, AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { useCallback } from 'react'

function useListAssetIndustries(assetId: number | null) {
  return useIndustryList(
    useCallback((): Partial<ListArgs<IndustryListItem>> | null => {
      if (assetId === null) {
        return null
      }

      return {
        filter: [listFilter<IndustryListItem>('linkedAssetIds', '=', assetId)],
        sort: [listSort('date', 'desc')],
        limit: 5,
      }
    }, [assetId])
  )
}

export function useAssetIndustries(asset: Asset) {
  const industries = useListAssetIndustries(
    asset.profileType === AssetProfileType.Automated ? null : asset.id
  )

  const automatedIndustries = useAssetRelevantIndustries(
    asset.profileType === AssetProfileType.Automated ? { assetId: asset.id } : null
  )

  if (asset.profileType === AssetProfileType.Automated) {
    return automatedIndustries.data
  }

  return industries.data
}

export function useAssetConferences(asset: Asset) {
  const conferenceEditions = useAssetConferenceEditions(asset.id)

  return conferenceEditions.data
}

export function useListAssetArticles(assetId: number) {
  return useArticleList(
    useCallback(
      (): Partial<ListArgs<ArticleListItem>> => ({
        filter: [
          listFilter<ArticleListItem>('linkedAssetIds', '=', assetId),
          listFilter<ArticleListItem>('type', '=', ArticleType.InTheNews),
        ],
        sort: [listSort('date', 'desc')],
        limit: 100,
      }),
      [assetId]
    )
  )
}

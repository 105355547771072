import Head from '@gain/modules/head'
import { DealListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import React from 'react'

import { useInvestorPageParams } from '../../utils'
import InvestorDealsList from '../investor-deals-list/investor-deals-list'

export default function RouteEntries() {
  const { investorId, investorName } = useInvestorPageParams()
  const defaultFilters = [listFilter<DealListItem>('buyerInvestorIds', '=', investorId)]

  return (
    <>
      <Head>
        <title>{investorName} - Entries</title>
      </Head>
      <InvestorDealsList defaultFilters={defaultFilters} />
    </>
  )
}

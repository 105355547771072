import { alpha, useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

/**
 * Returns the Gain.pro ECharts theme. This theme sets global styling for ECharts
 * to prevent that each chart has to set up the basic styling again.
 *
 * Always consider if a style should be local to a chart or if it is actually a
 * global style that should apply to all charts. In the latter case, add it here.
 */
export default function useEChartsTheme() {
  const theme = useTheme()

  return useMemo(
    () => ({
      backgroundColor: 'transparent',
      textColor: theme.palette.text.primary,
      textStyle: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 100,
      },
      color: theme.palette.chart,
      line: {
        itemStyle: {
          borderWidth: 1,
        },
        lineStyle: {
          width: 1,
        },
        symbolSize: 8,
        symbol: 'emptyCircle',
        smooth: false,
      },
      timeAxis: {
        axisLine: {
          show: true,
          lineStyle: {
            color: theme.palette.text.secondary,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: theme.palette.text.secondary,
        },
        splitLine: {
          show: false,
        },
        splitArea: {
          show: false,
        },
      },
      tooltip: {
        axisPointer: {
          lineStyle: {
            color: theme.palette.grey[800],
            type: [6, 6],
          },
          label: {
            borderRadius: 16,
            backgroundColor: theme.palette.info.dark,
            padding: [3, 6],
            color: theme.palette.common.white,
            fontSize: 10,
            borderWidth: 0,
          },
          crossStyle: {
            color: theme.palette.grey[800],
            type: [6, 6],
          },
        },
        backgroundColor: alpha(theme.palette.grey[900], 0.9),
        borderWidth: 0,
        borderRadius: 4,
        padding: theme.spacing(1),
        textStyle: {
          color: theme.palette.common.white,
        },
      },
    }),
    [theme]
  )
}

import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import { addMonths } from 'date-fns/addMonths'
import { format } from 'date-fns/format'
import React, { useCallback } from 'react'

import { AlertDialog } from '../../../common/dialog'
import { useZendesk } from '../../zendesk'

export interface DialogLimitReachedProps {
  open: boolean
  onClose: (event: React.MouseEvent) => void
  message?: string
}

const StyledLink = styled(Link)({
  cursor: 'pointer !important',
})

export default function ExportDialogLimitReached({
  open = false,
  onClose,
  message,
}: DialogLimitReachedProps) {
  const resetMonth = addMonths(new Date(), 1)
  const { enableChat } = useZendesk()

  const handleOnSupportClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      enableChat()
      onClose(event)
    },
    [enableChat, onClose]
  )

  return (
    <AlertDialog
      buttonText={'Ok'}
      message={
        <span>
          {message}. On the 1st of {format(resetMonth, 'MMMM')} the limit will be reset. Please{' '}
          <StyledLink onClick={handleOnSupportClick}>contact support</StyledLink> for additional
          exports.
        </span>
      }
      onClose={onClose}
      open={open}
      title={'Export limit reached'}
    />
  )
}

import { InfoIcon } from '@gain/components/icons'
import { FinancialResultAmountType } from '@gain/rpc/shared-model'
import Chip, { chipClasses } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import CircleIcon from './circle-icon'

const StyledChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.white}`,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.common.white,

  [`&.${chipClasses.clickable}:hover`]: {
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
  },

  [`& .${chipClasses.label}`]: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
    gap: theme.spacing(0.5),
  },
}))

interface FinancialLegendChipProps {
  label: string
  amountType?: FinancialResultAmountType
  handleOpen?: () => void
  isForecast?: boolean
}

export default function FinancialLegendChip({
  label,
  amountType,
  handleOpen,
  isForecast,
}: FinancialLegendChipProps) {
  return (
    <StyledChip
      clickable={!!handleOpen}
      label={
        <>
          <CircleIcon
            amountType={amountType}
            isForecast={isForecast}
          />
          <Typography variant={'overline'}>{label}</Typography>
          {!!handleOpen && (
            <InfoIcon
              color={'inherit'}
              fontSize={'inherit'}
            />
          )}
        </>
      }
      onClick={handleOpen}
    />
  )
}

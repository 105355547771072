import { useUserCurrency } from '@gain/api/app/hooks'
import { currencySymbol, useConvertCurrencyCallback } from '@gain/utils/currency'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'

import CustomOutlinedInput from '../../../../common/form/custom-outlined-input'

const StyledFilterInput = styled(CustomOutlinedInput)({
  flex: 1,
  [`& .${outlinedInputClasses.input}[type=number]`]: {
    textAlign: 'right',
  },
})

export interface FilterRangeCurrencyInputProps {
  autoFocus?: boolean
  value: number | null
  placeholder?: string
  onChange: (value: number | null) => void

  // The vast majority of our financial values are defined millions, but for some ratios
  // (e.g. Revenue / FTE) the value is raw EUR. This flag ensures the correct conversion
  // is applied. Defaults to true.
  isMillions?: boolean
}

/**
 * A filter input for min and max monetary values. The input is in the user's
 * currency and the value is converted to EUR for the backend.
 */
export default function FilterRangeCurrencyInput({
  value,
  onChange,
  placeholder,
  autoFocus,
  isMillions = true,
}: FilterRangeCurrencyInputProps) {
  const userCurrency = useUserCurrency()
  const convertCurrency = useConvertCurrencyCallback()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let newValue = event.currentTarget.value !== '' ? Number(event.currentTarget.value) : null

      // Convert the user's currency back to EUR for the backend
      if (newValue !== null) {
        newValue = convertCurrency(newValue, userCurrency.name, 'EUR')
      }

      // If the value is not millions we use input "1k" instead of "1000"
      if (newValue !== null && !isMillions) {
        newValue *= 1_000
      }

      onChange(newValue)
    },
    [convertCurrency, isMillions, onChange, userCurrency.name]
  )

  // If the filter is a currency filter, we want to show the currency
  // symbol as the prefix and the 'm' or 'bn' suffix
  const prefix = currencySymbol(userCurrency)

  // The parameter value is in EUR, so we need to convert it to
  // the user's currency
  let convertedValue = convertCurrency(value, 'EUR', userCurrency.name)

  // If the value is not millions we use input "1k" instead of "1000"
  if (!isMillions && convertedValue) {
    convertedValue /= 1_000
  }

  return (
    <StyledFilterInput
      autoFocus={autoFocus}
      endAdornment={isMillions ? 'm' : 'k'}
      onChange={handleChange}
      placeholder={placeholder}
      startAdornment={prefix}
      type={'number'}
      value={typeof convertedValue === 'number' ? convertedValue : ''}
    />
  )
}

import { RowType } from '@gain/utils/table'
import { useCallback } from 'react'

import {
  ColumnFieldConfig,
  VirtualSort,
  VirtualSortDirection,
  VirtualSortHandler,
} from './virtual-table-model'

export type UseSortCallbackFn<Row extends RowType> = <Field extends keyof Row>(
  config: ColumnFieldConfig<Row, Field>
) => () => void

function getSortDirection<Row extends RowType>(
  sort: VirtualSort<Row>[],
  defaultSortDirection: VirtualSortDirection,
  firstField: keyof Row
): VirtualSortDirection {
  if (sort.length > 0 && sort[0].field === firstField) {
    return sort[0].direction === 'asc' ? 'desc' : 'asc'
  }

  return defaultSortDirection || 'desc'
}

export const useSortCallback = <Row extends RowType>(
  sort: VirtualSort<Row>[],
  onSort: VirtualSortHandler<Row>
): UseSortCallbackFn<Row> =>
  useCallback(
    <Field extends keyof Row>(config: ColumnFieldConfig<Row, Field>) =>
      () => {
        const fields =
          config.sortFields && config.sortFields.length ? config.sortFields : [config.field]

        const [first] = fields

        const direction = getSortDirection(sort, config.defaultSortDirection || 'desc', first)

        const newSort = fields.map(
          (field) =>
            ({
              direction,
              field: field,
            } as VirtualSort<Row>)
        )

        onSort(newSort)
      },
    [onSort, sort]
  )

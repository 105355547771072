import Typography from '@mui/material/Typography'
import Debug from 'debug'
import { Component, ErrorInfo, ReactNode } from 'react'

import { cloudErrorReporter } from './'

const debug = Debug('gain-pro:error')

interface State {
  hasError: boolean
}

interface ErrorBoundaryProps {
  children: ReactNode
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public override readonly state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    debug('boundary', error, errorInfo)

    cloudErrorReporter.report(error)
  }

  public override render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <Typography variant={'h3'}>An Error occurred</Typography>
    }

    return children
  }
}

import { isDefined } from './is-defined'
import { isTruthy } from './is-truthy'

export function toUrl(url: string | null | undefined) {
  if (!isDefined(url)) {
    return null
  }

  try {
    return new URL(url)
  } catch (e) {
    return null
  }
}

export function addHttpsIfMissing(url: string) {
  if (url.startsWith('http')) {
    return url
  }

  return `https://${url}`
}

export function toHttpsUrl(url: string | null | undefined) {
  if (!isDefined(url)) {
    return null
  }

  return toUrl(addHttpsIfMissing(url))
}

function getHostname(url: URL | string): string {
  if (typeof url === 'string') {
    try {
      const parsed = new URL(url)
      return parsed.hostname
    } catch (e) {
      return url.toLowerCase()
    }
  }

  return url.hostname
}

export const formatUrlHostname = (
  url: URL | string | null | undefined,
  stripWww?: boolean
): string | null | undefined => {
  if (!isDefined(url)) {
    return url
  }

  const hostname = getHostname(url)

  if (stripWww && hostname.startsWith('www.')) {
    return hostname.substring(4)
  }

  return hostname
}

/**
 * Validates the url is valid, if not makes it so
 */
export const validateUrl = (url: URL | string | null | undefined): string | undefined => {
  if (!isTruthy(url)) {
    return undefined
  }

  if (typeof url === 'string') {
    return addHttpsIfMissing(url)
  }

  return url.toString()
}

import { ArticleListItem } from '@gain/rpc/app-model'

import ArticleNewsListItem from './list-asset-news-item.component'

export interface ListAssetNewsProps {
  articles: ArticleListItem[]
}

export function ListAssetNews({ articles }: ListAssetNewsProps) {
  return (
    <>
      {articles.map((article) => (
        <ArticleNewsListItem
          key={article.id}
          article={article}
        />
      ))}
    </>
  )
}

export default ListAssetNews

import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import { alpha, Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export const MuiListItemButton = (theme: Theme): Components['MuiListItemButton'] => ({
  styleOverrides: {
    root: {
      borderRadius: 4,
      [`&.${listItemButtonClasses.selected}`]: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
        [`& .${svgIconClasses.root}, & + .${listItemSecondaryActionClasses.root}`]: {
          color: theme.palette.primary.main,
        },
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      [`&.${listItemButtonClasses.selected}:hover`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
      transition: 'unset',
    },
    gutters: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [`&.${listItemButtonClasses.dense}`]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    dense: {
      ...theme.typography.button,
      minHeight: 32,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 2,
      paddingBottom: 2,
      [`& .${listItemIconClasses.root}`]: {
        minWidth: 32,
        fontSize: 16,
      },
    },
  },
})

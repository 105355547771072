import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { useSplitList } from '@gain/utils/list'
import Stack from '@mui/material/Stack'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { ContentLinkCellContainer } from '../../common/content-link'
import ViewMoreButton from '../../common/view-more-button'
import VirtualTable, { createVirtualTableColumns, VirtualSort } from '../../common/virtual-table'
import AssetContentLink from '../asset/asset-content-link'
import MultipleTableCell from '../asset/asset-list-item-table/table-cell/multiple-table-cell'
import useSortedAssets from '../benchmarking/use-sorted-assets'
import FinancialValue from '../financial/financial-value'

export type ValuationPeriod =
  | 'LastFiscalYear'
  | 'LastTwelveMonths'
  | 'CurrentFiscalYear'
  | 'NextTwelveMonths'
  | 'NextFiscalYear'

function useColumns(similarToAssetName: string | undefined, period: ValuationPeriod) {
  return useMemo(() => {
    return createVirtualTableColumns<AssetListItem>(
      {
        headerName: 'Name',
        field: 'name',
        width: 208,
        sticky: true,
        defaultSortDirection: 'asc',
        renderCell: ({ row }) => (
          <ContentLinkCellContainer>
            <AssetContentLink
              asset={row}
              id={row.id}
              logoFileUrl={row.logoFileUrl}
              name={row.name}
            />
          </ContentLinkCellContainer>
        ),
      },
      {
        field: 'description',
        headerName: 'Business description',
        width: 200,
        flex: 1,
        sortable: false,
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'region',
        headerName: 'HQ',
        align: 'right',
        width: 56,
        renderCell: ({ value }) => <Flag code={value} />,
      },
      // Don't show the relevance rank if the data is not filtered by a similar company
      !!similarToAssetName && {
        field: 'relevanceRank',
        headerName: 'Rank',
        headerExplainer: `The rank of how similar a public company is to ${similarToAssetName} when compared to all companies in our database.`,
        align: 'right',
        width: 88,
        defaultSortDirection: 'asc',
        valueFormatter: ({ value }) => `#${value}`,
      },
      {
        field: 'enterpriseValueEur',
        headerName: 'Current EV',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        field: `enterpriseValueEbitdaRatio${period}`,
        headerName: 'EV / EBITDA',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            enableNotMeaningful
          />
        ),
      },
      {
        field: `enterpriseValueEbitRatio${period}`,
        headerName: 'EV / EBIT',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            enableNotMeaningful
          />
        ),
      },
      {
        field: `enterpriseValueRevenueRatio${period}`,
        headerName: 'EV / Sales',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            enableNotMeaningful
          />
        ),
      }
    )
  }, [similarToAssetName, period])
}

interface AssetValuationTableProps {
  assets: AssetListItem[]
  similarToAssetName?: string // Enables the relevance rank column when set
  initialSort: VirtualSort<AssetListItem>[]
  period: ValuationPeriod
}

const EMPTY_ARRAY = []

export default function AssetValuationTable({
  assets,
  similarToAssetName,
  initialSort,
  period,
}: AssetValuationTableProps) {
  const prevPeriod = usePrevious(period)
  const columns = useColumns(similarToAssetName, period)

  const [sortedAssets, sort, onSort] = useSortedAssets(
    assets,
    EMPTY_ARRAY,
    EMPTY_ARRAY,
    initialSort
  )

  const [initialRows, otherRows] = useSplitList(sortedAssets, 10)
  const [showAll, setShowAll] = useState(!!otherRows.length)

  // Update sort accordingly when the period changes
  useEffect(() => {
    if (!!prevPeriod && period !== prevPeriod) {
      const nextSort = sort.map((item) => {
        const field = item.field.replace(prevPeriod, period) as keyof AssetListItem
        return {
          ...item,
          field,
        }
      })

      onSort(nextSort)
    }
  }, [onSort, period, prevPeriod, sort])

  return (
    <Stack>
      <VirtualTable
        columns={columns}
        onSort={onSort}
        RowComponentProps={() => ({
          hover: false,
        })}
        rows={!showAll ? initialRows : sortedAssets}
        sort={sort}
        variant={'inline'}
        disablePaddingStart
      />
      {!showAll && (
        <ViewMoreButton
          amount={otherRows.length}
          onClick={() => setShowAll(true)}
          variant={'chevron-down'}
        />
      )}
    </Stack>
  )
}

import { FinancialResultAmount } from '@gain/rpc/app-model'
import { useConvertCurrencyCallback } from '@gain/utils/currency'
import { useMemo } from 'react'

import { FinancialResult } from './financial-result-model'

export function useConvertFinancialResults(
  financialResults: FinancialResult[] | undefined,
  dataCurrency: string | null | undefined, // If empty is assumed to be EUR
  toCurrency: string
): FinancialResult[] {
  const convertCurrency = useConvertCurrencyCallback()

  return useMemo(() => {
    if (!financialResults || !dataCurrency) {
      return []
    }

    // Converts financial results to the given currency
    const financialResultAmountToCurrency = (
      amount: FinancialResultAmount | null
    ): FinancialResultAmount | null => {
      if (amount === null) {
        return null
      }

      const convertedAmount = convertCurrency(amount.amount, dataCurrency, toCurrency)
      if (convertedAmount === null) {
        return null
      }

      return {
        ...amount,
        amount: convertedAmount,
      }
    }

    return financialResults.map((result) => ({
      year: result.year,
      periodicity: result.periodicity,
      isForecast: result.isForecast,
      revenue: financialResultAmountToCurrency(result.revenue),
      revenueYoyGrowthPct: result.revenueYoyGrowthPct,
      grossMargin: financialResultAmountToCurrency(result.grossMargin),
      ebitda: financialResultAmountToCurrency(result.ebitda),
      ebit: financialResultAmountToCurrency(result.ebit),
      consolidatedNetIncome: financialResultAmountToCurrency(result.consolidatedNetIncome),
      consolidatedNetIncomeYoyGrowthPct: result.consolidatedNetIncomeYoyGrowthPct,
      earningsPerShare: financialResultAmountToCurrency(result.earningsPerShare),
      earningsPerShareYoyGrowthPct: result.earningsPerShareYoyGrowthPct,
      totalAssets: financialResultAmountToCurrency(result.totalAssets),
      capex: financialResultAmountToCurrency(result.capex),
      freeCashFlow: financialResultAmountToCurrency(result.freeCashFlow),
      freeCashFlowYoyGrowthPct: result.freeCashFlowYoyGrowthPct,
      cashConversionCycle: result.cashConversionCycle,
      debt: financialResultAmountToCurrency(result.debt),
      cash: financialResultAmountToCurrency(result.cash),
      netDebt: financialResultAmountToCurrency(result.netDebt),
      inventory: financialResultAmountToCurrency(result.inventory),
      receivables: financialResultAmountToCurrency(result.receivables),
      payables: financialResultAmountToCurrency(result.payables),
      capital: financialResultAmountToCurrency(result.capital),
      fte: result.fte,
      fteGrowthPct: result.fteGrowthPct,
      revenueFteRatio: convertCurrency(result.revenueFteRatio, dataCurrency, toCurrency),
      netDebtEbitdaRatio: result.netDebtEbitdaRatio,
      ebitdaMinusCapex: convertCurrency(result.ebitdaMinusCapex, dataCurrency, toCurrency),
      returnOnAssets: result.returnOnAssets,
      returnOnEquity: result.returnOnEquity,
      returnOnCapitalEmployed: result.returnOnCapitalEmployed,
    }))
  }, [financialResults, dataCurrency, convertCurrency, toCurrency])
}

import { Source } from '@gain/utils/source'

export const sortSourcesOnDate = (sourceOne: Source, sourceTwo: Source) => {
  if (sourceOne.publicationYear === null) {
    return -1
  }

  if (sourceTwo.publicationYear === null) {
    return 1
  }

  const sourceOnePublication = `${
    sourceOne.publicationYear
  }${`00${sourceOne.publicationMonth}`.substr(-2)}`
  const sourceTwoPublication = `${
    sourceTwo.publicationYear
  }${`00${sourceTwo.publicationMonth}`.substr(-2)}`

  if (sourceOnePublication < sourceTwoPublication) {
    return 1
  }

  if (sourceOnePublication > sourceTwoPublication) {
    return -1
  }

  if (sourceOne.title && sourceTwo.title) {
    if (sourceOne.title > sourceTwo.title) {
      return 1
    }

    if (sourceOne.title < sourceTwo.title) {
      return -1
    }
  }

  return 0
}

import { Asset } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'

export function shouldShowFinancialsTab(asset?: Asset) {
  if (!asset) {
    return false
  }

  if (asset.financialResults.length > 0) {
    return true
  }

  if (asset.financialFootnotes && asset.financialFootnotes.length > 0) {
    return true
  }

  return asset.legalEntities.length > 0 && asset.profileType !== AssetProfileType.Automated
}

import React, { useCallback, useState } from 'react'

type Direction = 'horizontal' | 'vertical'

export const useTooltipOnEllipsis = (direction: Direction = 'horizontal') => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleMouseOver = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const el = event.currentTarget

      if (direction === 'horizontal' && el.scrollWidth > el.clientWidth) {
        setTooltipOpen(true)
      } else if (direction === 'vertical' && el.scrollHeight > el.clientHeight) {
        setTooltipOpen(true)
      }
    },
    [setTooltipOpen, direction]
  )

  const handleMouseOut = useCallback(() => {
    setTooltipOpen(false)
  }, [setTooltipOpen])

  return {
    tooltipOpen,
    handleMouseOver,
    handleMouseOut,
  }
}

import { AssetListItem } from '@gain/rpc/app-model'
import { isEqual } from 'lodash'

import {
  ChartGroup,
  ChartGroupByConfig,
  ChartGroupValueType,
  getVisibleChartGroups,
} from '../../../common/chart/chart-groups'
import { Bubble } from './chart-bubble'

export interface ChartState {
  availableGroups: ChartGroup<AssetListItem>[] // All visible and hidden groups
  visibleGroups: ChartGroup<AssetListItem>[] // The groups that are currently visible
  highlightedAssets: AssetListItem[] // Assets currently highlighted
  visibleAssets: AssetListItem[] // Assets that are currently visible
  originalVisibleAssets: AssetListItem[] // Assets that were visible before highlighting
  allVisible: boolean // Are all assets currently visible?
  bubbles: Bubble[]
}

type ChartAction =
  | {
      type: 'changeGroups'
      groups: ChartGroup<AssetListItem>[]
      visibleGroupId?: ChartGroupValueType
    }
  | { type: 'changeGroupBy'; visibleAssets: AssetListItem[] }
  | { type: 'changeVisibleGroups'; visibleGroups: ChartGroup<AssetListItem>[] }
  | { type: 'mouseEnterGroup'; group: ChartGroup<AssetListItem> }
  | { type: 'updateBubbles'; bubbles: Bubble[] }
  | { type: 'mouseLeaveGroup' }

export const initialState: ChartState = {
  availableGroups: [],
  visibleGroups: [],
  highlightedAssets: [],
  visibleAssets: [],
  originalVisibleAssets: [],
  bubbles: [],
  allVisible: false,
}

/**
 * This reducer is responsible for managing the state of the chart.
 *
 * The ChartLegend and EChart bubbles are completely independent of each other.
 * Because the logic what to highlight, show or hide is quite complex a reducer
 * was the easiest way to manage and debug that state.
 */
export const chartReducer = (state: ChartState, action: ChartAction): ChartState => {
  switch (action.type) {
    case 'changeGroups':
      return {
        ...initialState,
        availableGroups: action.groups,
        visibleGroups: getVisibleChartGroups(action.groups, action.visibleGroupId),
        allVisible: true,
      }
    case 'changeGroupBy':
      return {
        ...state,
        // Avoid unnecessary re-renders, they are quite heavy.
        visibleAssets: isEqual(state.visibleAssets, action.visibleAssets)
          ? state.visibleAssets
          : action.visibleAssets,
        originalVisibleAssets: [],
      }
    case 'changeVisibleGroups':
      return {
        ...state,
        visibleGroups: action.visibleGroups,
      }
    case 'mouseEnterGroup': {
      const highlightedAssets = action.group?.items ?? []
      return {
        ...state,

        // Ensure that highlighted assets are visible even if their group is not;
        // this happens when hovering over a group legend and another group is
        // already selected.
        originalVisibleAssets: state.visibleAssets,
        visibleAssets: [...state.visibleAssets, ...highlightedAssets],
        highlightedAssets,
      }
    }
    case 'mouseLeaveGroup':
      return {
        ...state,

        // Hide assets that are no longer highlighted. If the user clicked the
        // group the originalVisibleAssets will be empty in which case we don't
        // have to revert to the original state.
        visibleAssets:
          state.originalVisibleAssets.length > 0
            ? state.originalVisibleAssets
            : state.visibleAssets,
        originalVisibleAssets: [],

        // Reset highlighted assets after leaving the group.
        highlightedAssets: [],
      }
    case 'updateBubbles': {
      // Avoid unnecessary re-renders, they are quite heavy.
      if (isEqual(state.bubbles, action.bubbles)) {
        return state
      }

      return {
        ...state,
        bubbles: action.bubbles,
        allVisible: action.bubbles.every((bubble) =>
          state.visibleGroups.some(({ items }) => items.some(({ id }) => id === bubble.assetId))
        ),
      }
    }
    default:
      return state
  }
}

// Action creators
export function changeGroups(
  groups: ChartGroup<AssetListItem>[],
  visibleGroupId?: ChartGroupValueType
): ChartAction {
  return { type: 'changeGroups', groups, visibleGroupId }
}

export function changeGroupBy(
  assets: AssetListItem[],
  visibleGroups: ChartGroup<AssetListItem>[],
  groupBy: ChartGroupByConfig<AssetListItem>
): ChartAction {
  const temp = visibleGroups.map((group) => group?.value)
  const visibleAssets = assets.filter((asset) => {
    return temp.includes(groupBy.getValue(asset))
  })

  return { type: 'changeGroupBy', visibleAssets }
}

export function changeVisibleGroups(visibleGroups: ChartGroup<AssetListItem>[]): ChartAction {
  return { type: 'changeVisibleGroups', visibleGroups }
}

export function mouseEnterGroup(group: ChartGroup<AssetListItem>): ChartAction {
  return { type: 'mouseEnterGroup', group }
}

export function mouseLeaveGroup(): ChartAction {
  return { type: 'mouseLeaveGroup' }
}

export function updateBubbles(bubbles: Bubble[]): ChartAction {
  return { type: 'updateBubbles', bubbles }
}

import { SUBSECTORS } from './subsectors'

export function getSectorForSubsector(subsector?: string | null) {
  if (subsector === undefined || subsector === null) {
    return null
  }

  const result = SUBSECTORS.find((item) => item.name === subsector)

  if (!result) {
    return null
  }

  return result.sector
}

import { ExcelIcon, PdfIcon } from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import { startOfNextMonth } from '@gain/utils/date'
import ListItem, { listItemClasses } from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import AccordionList from '../../../common/accordion/accordion-list'
import { ExportMaxCount, methodConfigMap } from '../../../features/export/export-button'

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [`& .${listItemTextClasses.primary}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))

interface LimitConfig {
  icon: 'excel' | 'pdf'
  name: string
  method: keyof typeof methodConfigMap
}

const limitsConfig: LimitConfig[] = [
  {
    icon: 'excel',
    name: 'Company financials',
    method: 'data.exportAssetFinancials',
  },
  {
    icon: 'excel',
    name: 'Companies in Excel',
    method: 'data.exportAssets',
  },
  {
    icon: 'excel',
    name: 'Investor lists',
    method: 'data.exportInvestors',
  },
  {
    icon: 'excel',
    name: 'Advisor lists',
    method: 'data.exportAdvisors',
  },
  {
    icon: 'excel',
    name: 'Deal lists',
    method: 'data.exportDeals',
  },
  {
    icon: 'pdf',
    name: 'Company profiles',
    method: 'data.exportFactsheet',
  },
  {
    icon: 'pdf',
    name: 'Company annual reports',
    method: 'data.exportAnnualReportFile',
  },
  {
    icon: 'pdf',
    name: 'Industry reports',
    method: 'data.exportIndustryProfile',
  },
]

// Compared to a normal accordion list we want spacing between each row
const StyledAccordionList = styled(AccordionList)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 2, 2, 2),

  [`& .${listItemClasses.root}`]: {
    borderTop: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 0.75),
  },
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderRadius: 8,
  padding: 0,
  margin: 0,
}))

export default function ExportLimits() {
  const userProfile = useUserProfileContext()
  return (
    <Paper variant={'outlined'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        padding={2}>
        <Typography variant={'subtitle1'}>My organization's export limits</Typography>
        <Typography>Limits reset on the {startOfNextMonth()}</Typography>
      </Stack>
      <StyledAccordionList>
        {limitsConfig.map(({ name, method, icon }) => {
          const { maxItems } = methodConfigMap[method].getExportLimits(userProfile)
          return (
            <StyledListItem
              key={`${method}-list-item`}
              disableGutters>
              <ListItemIcon>{icon === 'excel' ? <ExcelIcon /> : <PdfIcon />}</ListItemIcon>

              <StyledListItemText
                primary={
                  <>
                    <span>{name}</span>
                    {maxItems && (
                      <Typography
                        color={'text.secondary'}
                        component={'span'}
                        variant={'inherit'}>
                        ({maxItems} entries per download)
                      </Typography>
                    )}
                  </>
                }
              />

              <ExportMaxCount
                method={method}
                disableTooltip
              />
            </StyledListItem>
          )
        })}
      </StyledAccordionList>
    </Paper>
  )
}

import type { FinancialResult } from '@gain/utils/financials'
import { formatNumber, formatPercentage } from '@gain/utils/number'

export const MAX_REVENUE_PERCENTAGE = 100
export const MIN_REVENUE_PERCENTAGE = -500

export function formatAmount(value: number | null | undefined, estimate: boolean) {
  return formatNumber(value, { round: estimate ? 0 : 1 })
}

export function formatGrowth(value: number | null | undefined, estimate: boolean) {
  return formatPercentage(value, { round: estimate ? 0 : 1 })
}

export function formatPercentageOfRevenue(value: number | null | undefined, estimate: boolean) {
  if (value && (value < MIN_REVENUE_PERCENTAGE || value > MAX_REVENUE_PERCENTAGE)) {
    return 'n.m.'
  }

  return formatPercentage(value, { round: estimate ? 0 : 1 })
}

/**
 * Returns true if the given index matches the first forecasted financial results
 * while disregarding periodicity (NTM or Annual)
 */
export function isFirstForecastedYear(financials: FinancialResult[], index: number): boolean {
  return financials.findIndex((result) => result.isForecast) === index
}

import { AssetListItem } from '@gain/rpc/app-model'

export enum BenchmarkingItemType {
  Target = 0,
  Added = 1,
  Curated = 2,
  Similar = 3,
}

export interface BenchmarkingItem extends AssetListItem {
  type?: BenchmarkingItemType
}

export function addBenchmarkingItemType(
  assets: AssetListItem[],
  type: keyof typeof BenchmarkingItemType
) {
  return assets.map((asset) => ({
    ...asset,
    type: BenchmarkingItemType[type],
  }))
}

export function compareBenchmarkRank(a: BenchmarkingItem, b: BenchmarkingItem) {
  // Sort by type as listed in the enum.
  if (a.type !== b.type) {
    return (a.type ?? Infinity) - (b.type ?? Infinity)
  }

  // If types are the same, sort by relevanceRank
  return (a.relevanceRank ?? Infinity) - (b.relevanceRank ?? Infinity)
}

export function processInitialAssets(assets: BenchmarkingItem[]) {
  const similarCompaniesById: Record<number, BenchmarkingItem> = {}
  const curatedCompaniesById: Record<number, BenchmarkingItem> = {}

  assets.forEach((asset) => {
    if (asset.type === BenchmarkingItemType.Similar) {
      similarCompaniesById[asset.id] = asset
    }
    if (asset.type === BenchmarkingItemType.Curated) {
      curatedCompaniesById[asset.id] = asset
    }
  })

  return (
    assets
      .filter(
        (asset) =>
          // If a similar company is also curated, we don't include it in the list for a second time.
          !(asset.type === BenchmarkingItemType.Similar && curatedCompaniesById[asset.id])
      )
      .map((asset, i) => {
        if (asset.type === BenchmarkingItemType.Curated) {
          const similarCompany = similarCompaniesById[asset.id]

          if (similarCompany) {
            // Use the same relevance rank as the similar company
            return {
              ...asset,
              relevanceRank: similarCompany.relevanceRank,
            }
          }

          // Use a relevance rank that is always higher than the similar companies,
          // but preserve backend order by means of adding i.
          return { ...asset, relevanceRank: 10000 + i }
        }

        return asset
      })
      // Pre-sort the assets by rank, this is needed so that benchmark size filtering
      // can be applied correctly.
      .sort(compareBenchmarkRank)
  )
}

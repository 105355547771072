import { Components } from '@mui/material/styles'

export const MuiInputBase = (): Components['MuiInputBase'] => ({
  styleOverrides: {
    sizeSmall: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})

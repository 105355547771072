import { AssetFinancialResult } from '@gain/rpc/app-model'

export const FINANCIAL_RESULT_AMOUNT_KEYS = [
  'revenue',
  'grossMargin',
  'ebitda',
  'ebit',
  'consolidatedNetIncome',
  'earningsPerShare',
  'totalAssets',
  'capex',
  'freeCashFlow',
  'cashConversionCycle',
  'debt',
  'cash',
  'netDebt',
  'inventory',
  'receivables',
  'payables',
  'capital',
  'fte',
  'fteGrowthPct',
] as const

export type FinancialResultAmountKeys = (typeof FINANCIAL_RESULT_AMOUNT_KEYS)[number]

type FinancialResultGrowthKeys = keyof Pick<
  AssetFinancialResult,
  | 'revenueYoyGrowthPct'
  | 'consolidatedNetIncomeYoyGrowthPct'
  | 'earningsPerShareYoyGrowthPct'
  | 'freeCashFlowYoyGrowthPct'
>

type FinancialResultRatioKeys = keyof Pick<
  AssetFinancialResult,
  | 'returnOnAssets'
  | 'returnOnEquity'
  | 'returnOnCapitalEmployed'
  | 'revenueFteRatio'
  | 'netDebtEbitdaRatio'
  | 'ebitdaMinusCapex'
>

export type FinancialResult = Pick<
  AssetFinancialResult,
  | 'year'
  | 'periodicity'
  | 'isForecast'
  | FinancialResultAmountKeys
  | FinancialResultGrowthKeys
  | FinancialResultRatioKeys
>

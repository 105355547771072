import { ConferenceIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { TooltipContainer } from '@gain/components/tooltip'
import { ConferenceEditionListItem } from '@gain/rpc/app-model'
import { formatCountry } from '@gain/utils/countries'
import { formatDateRange } from '@gain/utils/date'
import React from 'react'

import KeyValueList, { KeyValueListItem } from '../../../common/key-value/key-value-list'

export interface ConferenceTooltipTitleProps {
  conferenceEdition: ConferenceEditionListItem
}

export default function ConferenceEditionTooltipTitle({
  conferenceEdition,
}: ConferenceTooltipTitleProps) {
  return (
    <TooltipContainer>
      <TooltipHeader
        defaultIcon={<ConferenceIcon />}
        logoSrc={conferenceEdition.logoFileUrl}
        subtitle={'Conference'}
        title={conferenceEdition.name}
      />
      <KeyValueList
        spacing={0.5}
        dense>
        <KeyValueListItem
          label={'Country'}
          value={formatCountry(conferenceEdition.venueCountryCode)}
        />

        <KeyValueListItem
          label={'City'}
          value={conferenceEdition.venueCity}
        />

        <KeyValueListItem
          label={'Date'}
          value={formatDateRange(conferenceEdition.startDate, conferenceEdition.endDate)}
        />

        <KeyValueListItem
          label={'# of exhibitors'}
          value={conferenceEdition.exhibitorsCount}
        />
      </KeyValueList>
    </TooltipContainer>
  )
}

import { formControlLabelClasses } from '@mui/material/FormControlLabel'
import { Components, Theme } from '@mui/material/styles'

export const MuiFormControlLabel = (theme: Theme): Components['MuiFormControlLabel'] => ({
  styleOverrides: {
    root: {
      marginLeft: -9,
      minWidth: 0,
    },
    label: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginLeft: 2,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  variants: [
    {
      props: { color: 'filter' },
      style: {
        [`& .${formControlLabelClasses.label}`]: {
          color: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
    },
  ],
})

import { ListItemBase } from '@gain/rpc/list-model'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Paper, { PaperProps } from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import React from 'react'

import Table from '../table'
import VirtualTable from '../virtual-table'
import useBreakpointVariant, { BreakpointVariantMap } from './use-breakpoint-variant'

export interface BreakpointVariantProps<Item extends ListItemBase>
  extends BreakpointVariantMap<Item> {
  rows: Item[]
  loading?: boolean
}

export const Container = styled('div')({
  position: 'relative',
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

export const TablePaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'tableVariant',
})<PaperProps & { tableVariant: 'virtual-table' | 'inline-table' }>(({ theme, tableVariant }) => ({
  maxHeight: '100%',
  minHeight: 0,
  minWidth: 0,
  display: 'flex',
  flex: 1,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',

  ...(tableVariant === 'virtual-table' && {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingBottom: 0,
  }),

  ...(tableVariant === 'inline-table' && {
    width: '100%',
    paddingBottom: theme.spacing(1),
  }),
}))

export function BreakpointVariant<Item extends ListItemBase>({
  xs,
  sm,
  md,
  lg,
  xl,
  rows,
  loading = false,
}: BreakpointVariantProps<Item>) {
  const breakpointVariant = useBreakpointVariant({
    xs,
    sm,
    md,
    lg,
    xl,
  })

  return (
    <Container>
      {breakpointVariant.variant === 'virtual-table' && (
        <TablePaper tableVariant={breakpointVariant.variant}>
          {(loading || rows.length > 0) && (
            <VirtualTable
              rows={rows}
              variant={'default'}
              {...breakpointVariant.VariantProps}
            />
          )}

          {breakpointVariant.children}
        </TablePaper>
      )}

      {breakpointVariant.variant === 'inline-table' && (
        <Table
          rows={rows}
          {...breakpointVariant.VariantProps}
        />
      )}

      {breakpointVariant.variant === 'list' && (
        <List {...breakpointVariant.VariantProps.ListProps}>
          {rows.map((item, index, items) => (
            <React.Fragment key={item?.id || index}>
              {index > 0 && <Divider component={'li'} />}

              {breakpointVariant.VariantProps.renderListItem(item, index, items)}
            </React.Fragment>
          ))}

          {breakpointVariant.children}
        </List>
      )}
    </Container>
  )
}

export default BreakpointVariant

// [date, sharePrice], where the share price can be null if there's no data for
// a given date.
export type PriceSeriesData = Array<[number, number | null]>

export interface MarketDataChartData {
  sharePrice: PriceSeriesData
  brokerRecommendations: number[][]
}

export interface BrokerRecommendation {
  startX: number
  endX: number
  buy: number
  hold: number
  sell: number
  total: number
}

export interface SharePriceDatapoint {
  date: number
  sharePrice: number | null
}

// Dimensions / array indices of the Buy / Hold / Sell values within the broker
// recommendation data type
export const DIMENSION_BUY = 2
export const DIMENSION_HOLD = 3
export const DIMENSION_SELL = 4
export const DIMENSION_TOTAL = 5

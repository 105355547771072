import { AssetListItem } from '@gain/rpc/app-model'
import { assetUpdateType, useUpdateTypeColor } from '@gain/utils/asset'
import Chip from '@mui/material/Chip'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { alpha } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

const assetUpdateTypeClasses = generateUtilityClasses('AssetUpdateType', [
  'colorInfo',
  'colorSuccess',
])

const StyledChip = styled(Chip)(({ theme }) => ({
  [`&.${assetUpdateTypeClasses.colorInfo}`]: {
    backgroundColor: alpha(theme.palette.info.main, 0.14),
    color: theme.palette.info.main,
  },
  [`&.${assetUpdateTypeClasses.colorSuccess}`]: {
    backgroundColor: alpha(theme.palette.success.main, 0.14),
    color: theme.palette.success.main,
  },
}))

export interface AssetUpdateType {
  asset: AssetListItem
}

export default function AssetUpdateType({ asset }: AssetUpdateType) {
  const update = assetUpdateType(asset)
  const color = useUpdateTypeColor(update, asset)

  if (!update) {
    return null
  }

  return (
    <StyledChip
      className={clsx({
        [assetUpdateTypeClasses.colorInfo]: color === 'info',
        [assetUpdateTypeClasses.colorSuccess]: color === 'success',
      })}
      label={update}
    />
  )
}

import { useUserCurrency } from '@gain/api/app/hooks'
import { InvestorFund } from '@gain/rpc/app-model'
import { useConvertCurrencyCallback, useFormatCurrencyCallback } from '@gain/utils/currency'
import { useElementWidthEffect } from '@gain/utils/dom'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import { chartColorSet } from '../../../../common/chart/chart-colors'
import { MeasureDimensions } from '../../../../common/responsive'
import { StackedBarChart } from '../../../../features/chart'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_FUNDS_PATH,
  useInvestorPageParams,
} from '../../../utils'

export interface InvestorFundraisingCardProps {
  funds: InvestorFund[]
}

export default function InvestorFundraisingCard({ funds }: InvestorFundraisingCardProps) {
  const params = useInvestorPageParams()
  const userCurrency = useUserCurrency()
  const convertCurrency = useConvertCurrencyCallback()
  const formatCurrency = useFormatCurrencyCallback()
  const cardRef = useRef<HTMLDivElement>(null)
  const [maxNrOfYears, setMaxNrOfYears] = useState(5)

  const mergedFunds = useMemo(() => {
    return funds.reduce((acc, fund) => {
      if (!fund.vintageDate || !fund.fundSizeEur) {
        return acc
      }

      const index = acc.findIndex((item) => item.year === fund.vintageDate?.year)
      if (index > -1) {
        acc[index].fundSizeEur += fund.fundSizeEur
      } else {
        acc.push({
          year: fund.vintageDate.year,
          fundSizeEur: fund.fundSizeEur,
        })
      }

      return acc
    }, new Array<{ year: number; fundSizeEur: number }>())
  }, [funds])

  const visibleFunds = useMemo(() => {
    const maxYear = Math.max(...mergedFunds.map((fund) => fund.year))
    const minYear = maxYear - maxNrOfYears

    return mergedFunds.filter((item) => {
      return item.year > minYear
    })
  }, [mergedFunds, maxNrOfYears])

  const href = useMemo(() => {
    if (funds.length > 0) {
      return generateInvestorPagePath(params, INVESTOR_PAGE_FUNDS_PATH)
    }
    return undefined
  }, [funds.length, params])

  // updates the max visible years based on card width to make sure the x-axis doesn't to crowded
  const handleWidthChange = useCallback(() => {
    const width = cardRef.current?.getBoundingClientRect().width
    if (!width) {
      return
    }
    const maxYears = Math.floor((width - 200) / 30)
    setMaxNrOfYears(maxYears)
  }, [])

  // track width changes so we can update the number of visible axes accordingly
  useElementWidthEffect(cardRef, handleWidthChange)

  return (
    <Card
      ref={cardRef}
      href={href}
      sx={{ minHeight: 288 }}
      fullHeight>
      <CardHeader title={'Fundraising'} />
      <CardContent sx={{ flexGrow: 1 }}>
        <MeasureDimensions>
          {({ width, height }) => (
            <StackedBarChart
              data={visibleFunds}
              getColor={() => chartColorSet[0]}
              getHighlightGroup={() => 1}
              height={height}
              width={width}
              xScaleConfig={{
                label: 'Year',
                getLabel: (value) => value.toString(),
                getValue: (item) => item.year,
              }}
              yScaleConfig={{
                label: 'Total',
                getLabel: (value) => formatCurrency(value, { dataCurrency: userCurrency.name }),
                getAxisLabel: (value) => {
                  if (value === 0) {
                    return '0m'
                  }

                  return formatCurrency(value, {
                    dataCurrency: userCurrency.name,
                    disablePrefix: true,
                    round: 'auto',
                  })
                },
                getValue: (item) => convertCurrency(item.fundSizeEur, 'EUR', userCurrency.name),
              }}
            />
          )}
        </MeasureDimensions>
      </CardContent>
    </Card>
  )
}

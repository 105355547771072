import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { AppBatchSwrRequest, useAppBatchSwr } from './use-app-batch-swr'

type ParamTransformer<InputParams, OutputParams> = (params: InputParams) => OutputParams

function buildRequest<InputParams, RequestParams>(
  method: string,
  transformProps: ParamTransformer<InputParams, RequestParams>
) {
  return function (params: InputParams): AppBatchSwrRequest<RequestParams> {
    return {
      method,
      params: transformProps(params),
    }
  }
}

export function createUseAppBatchSwr<ReturnType, InputParams, RequestParams>(
  method: string,
  transformProps: ParamTransformer<InputParams, RequestParams>
) {
  return (inputParamsArray: InputParams[], options?: SWRConfiguration) => {
    const requests = useMemo(() => {
      return inputParamsArray.map(buildRequest(method, transformProps))
    }, [inputParamsArray])

    return useAppBatchSwr<ReturnType[]>(requests, options)
  }
}

import { styled } from '@mui/material/styles'

export const ObjectListItemText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant?: 'default' | 'top-result'
}>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  borderRadius: 5,
  minWidth: 0,
  flex: 1,
  gap: theme.spacing(variant === 'top-result' ? 0 : 1),
  overflow: 'hidden',
  color: theme.palette.text.secondary,

  ...(variant === 'top-result' && {
    flexDirection: 'column',
    justifyContent: 'center',
  }),

  ...(variant !== 'top-result' && {
    alignItems: 'center',
  }),

  '& p': {
    minWidth: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

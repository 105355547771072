import { AssetListItem, BookmarksFilteredList } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { usePrevious } from 'react-use'

import DialogHeader from '../../../common/dialog-header'
import {
  ASSET_DEFAULT_FILTERS,
  ASSET_FILTER_MAP,
  ASSET_FILTER_MENU,
  AssetFilterField,
} from '../../asset/asset-filter-bar'
import FilterBar, {
  FilterApi,
  FilterModel,
  fromFilterModel,
  isEmpty,
  toFilterModel,
} from '../../filter/filter-bar'

const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 652,
  },
})

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1.5),
}))

interface BookmarksListFiltersDialogProps extends Omit<DialogProps, 'onSubmit'> {
  open: boolean
  onClose: () => void
  onSubmit: (filters: ListFilter<AssetListItem>[] | null) => Promise<void> | void
  dialogTitle: string
  list?: BookmarksFilteredList
}

export default function BookmarksListFiltersDialog({
  dialogTitle,
  onClose,
  onSubmit,
  open,
  list,
}: BookmarksListFiltersDialogProps) {
  const filterApiRef = useRef<FilterApi<AssetListItem, AssetFilterField> | null>(null)
  const prevOpen = usePrevious(open)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const [filterModel, setFilterModel] = useState(
    !list ? ASSET_DEFAULT_FILTERS : toFilterModel(list.filters, ASSET_FILTER_MAP)
  )

  // When the list filters get updated or the dialog is reopened reset the form
  useEffect(() => {
    if (open && !prevOpen) {
      const newFilterModel = !list
        ? ASSET_DEFAULT_FILTERS
        : toFilterModel(list.filters, ASSET_FILTER_MAP)
      setFilterModel(newFilterModel)
      filterApiRef.current?.reset(newFilterModel)
    }
  }, [list, open, prevOpen, filterApiRef])

  // Update disabled state of save button according to filters
  useEffect(() => {
    setIsSaveDisabled(isEmpty(filterModel))
  }, [filterModel])

  // Manage loading state, submit the new model and close the dialog
  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await onSubmit(fromFilterModel(filterModel, ASSET_FILTER_MAP))
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  // Keep track of the FilterBar state until we submit or cancel the dialog
  const handleFilterChange = (newFilterModel: FilterModel<AssetListItem, AssetFilterField>) => {
    setFilterModel(newFilterModel)
  }

  // Clear filters, remove all
  const handleClearFilters = () => {
    filterApiRef.current?.reset([])
  }

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        // Mounting by default causes issues in iOS 17 mobile Safari
        mountOnEnter: true,
      }}
      fullWidth
      keepMounted>
      <DialogHeader
        onCloseClick={onClose}
        title={dialogTitle}
      />

      <StyledDialogContent>
        <FilterBar
          ref={filterApiRef}
          addFilterMenu={ASSET_FILTER_MENU}
          defaultFilterModel={ASSET_DEFAULT_FILTERS}
          filterConfigMap={ASSET_FILTER_MAP}
          gaCategory={'Asset'}
          onFilterChange={handleFilterChange}
          disableClear
          disablePadding
        />
      </StyledDialogContent>

      <DialogActions>
        <Button
          color={'primary'}
          onClick={handleClearFilters}
          variant={'outlined'}
          fullWidth>
          Clear filters
        </Button>

        <LoadingButton
          color={'primary'}
          disabled={isSaveDisabled}
          loading={isLoading}
          onClick={handleSubmit}
          type={'button'}
          variant={'contained'}
          fullWidth>
          Save
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  )
}

import Typography from '@gain/components/typography'
import { AnnualReportFileEntry, formatAnnualReportDisplayName } from '@gain/utils/annual-report'
import Stack from '@mui/material/Stack'
import React from 'react'

import { ExportButton } from '../../../../features/export/export-button'

interface AnnualReportsTooltipTitleProps {
  annualReportFileEntries: AnnualReportFileEntry[]
}

export function AnnualReportsTooltipTitle({
  annualReportFileEntries: annualReportFiles,
}: AnnualReportsTooltipTitleProps) {
  return (
    <Stack
      direction={'column'}
      spacing={1}
      sx={{ p: 3, minWidth: 320 }}>
      {annualReportFiles.map(({ report, arFile }) => (
        <Stack
          key={arFile.id}
          alignItems={'center'}
          direction={'row'}
          justifyContent={'space-between'}
          spacing={1}
          sx={{ flex: 1 }}>
          <Stack
            direction={'column'}
            spacing={0}
            sx={{ minWidth: 0 }}>
            <Typography
              variant={'body2'}
              noWrap>
              {formatAnnualReportDisplayName(report, arFile)}
            </Typography>
          </Stack>

          <ExportButton
            color={'primary'}
            method={'data.exportAnnualReportFile'}
            params={{ id: arFile.id }}
            tooltipMode={'always'}
          />
        </Stack>
      ))}
    </Stack>
  )
}

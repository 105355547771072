import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import { generatePath } from 'react-router-dom'

import { INDUSTRY_LIST_PATH } from '../../utils'
import {
  StyledIndustryListButton,
  StyledIndustryListItemContainer,
  StyledIndustryListTitleContainer,
} from './industry-list'

const StyledIndustryItem = styled(Skeleton)(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export interface IndustryListSkeletonProps {
  nrOfIndustries: number
}

export default function IndustryListSkeleton({ nrOfIndustries }: IndustryListSkeletonProps) {
  const range = Array.from({ length: nrOfIndustries })
  return (
    <div>
      <StyledIndustryListTitleContainer>
        <Typography
          color={'textPrimary'}
          variant={'subtitle2'}>
          Latest industry reports
        </Typography>
        <StyledIndustryListButton
          color={'primary'}
          {...{ component: Link, href: generatePath(INDUSTRY_LIST_PATH) }}
          variant={'text'}>
          View all
        </StyledIndustryListButton>
      </StyledIndustryListTitleContainer>
      <StyledIndustryListItemContainer>
        {range.map((_, index) => (
          <StyledIndustryItem
            key={index}
            height={168}
            sx={{ borderRadius: 2 }}
            variant={'rounded'}
          />
        ))}
      </StyledIndustryListItemContainer>
    </div>
  )
}

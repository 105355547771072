import { useDealList } from '@gain/api/app/hooks'
import { Industry } from '@gain/rpc/app-model'
import { listSourceFilter } from '@gain/rpc/utils'
import { median } from 'd3'
import { useMemo } from 'react'

export function useIndustryEsgOverallRiskMedian(industry?: Industry) {
  return useMemo(() => {
    const ratingsOverall =
      industry?.market?.segments.reduce((acc, segment) => {
        if (typeof segment.ratingOverall === 'number') {
          return acc.concat(segment.ratingOverall)
        }

        return acc
      }, new Array<number>()) || []

    return median(ratingsOverall)
  }, [industry])
}

export function useIndustryDealCount(industryId: number | null) {
  const { data } = useDealList({
    filter: [
      listSourceFilter('id', {
        source: 'industryDeals',
        id: industryId,
      }),
    ],
    limit: 0,
  })

  return data.counts.filtered || null
}

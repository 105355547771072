export const assetFteRangeCategories: { label: string; value: number }[] = [
  { value: 1, label: '1' },
  { value: 2, label: '1-10' },
  { value: 3, label: '11-50' },
  { value: 4, label: '51-200' },
  { value: 5, label: '201-500' },
  { value: 6, label: '501-1,000' },
  { value: 7, label: '1,001-5,000' },
  { value: 8, label: '5,001-10,000' },
  { value: 9, label: '10,001+' },
]

import Typography from '@gain/components/typography'
import { useSplitList } from '@gain/utils/list'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

import { Shareholder } from './asset-shareholder-util'
import TableAssetShareholder from './table-asset-shareholder'

const StyledMoreContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
}))

export interface TableAssetShareholderContainerProps {
  shareholders: Shareholder[]
  maxNrOfInitialItems?: number
}

export default function TableAssetShareholderContainer({
  shareholders,
  maxNrOfInitialItems = 5,
}: TableAssetShareholderContainerProps) {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [initialShareholders, additionalShareholders] = useSplitList(
    shareholders,
    maxNrOfInitialItems
  )

  return (
    <>
      <TableAssetShareholder
        shareholders={
          showAll ? initialShareholders.concat(additionalShareholders) : initialShareholders
        }
      />
      {additionalShareholders.length > 0 && !showAll && (
        <StyledMoreContainer>
          <Typography
            color={'primary'}
            onClick={() => setShowAll(true)}
            sx={{ cursor: 'pointer' }}
            variant={'body2'}>
            + {additionalShareholders.length} more
          </Typography>
        </StyledMoreContainer>
      )}
    </>
  )
}

import { styled } from '@mui/material/styles'

export const TabPageHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0, 0, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 2),
  },
  minWidth: 0,
}))

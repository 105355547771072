import { UserStatus } from './lib/rpc-shared-model'
import { ChipOption } from './option'

export const USER_STATUS_OPTIONS: ReadonlyArray<ChipOption<UserStatus>> = [
  {
    label: 'Active',
    value: UserStatus.Active,
    color: 'success',
  },
  {
    label: 'Invited',
    value: UserStatus.Invited,
    color: 'info',
  },
  {
    label: 'Deactivated',
    value: UserStatus.Deactivated,
    color: 'error',
  },
  {
    label: 'Not invited',
    value: UserStatus.NotInvited,
    color: 'warning',
  },
]

import { CompanyIcon, Trash2Icon } from '@gain/components/icons'
import { SearchResultItem } from '@gain/rpc/app-model'
import Collapse from '@mui/material/Collapse'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

import { ObjectListItem } from '../object-list-item'

const StyledCollapse = styled(Collapse)({
  overflow: 'auto',
})

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0.5, 0.5),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 'inherit',
  color: theme.palette.text.secondary,
  fontSize: 16,
  padding: theme.spacing(1, 0.5),
  marginLeft: 'auto',
}))

interface AddAssetsDialogListProps {
  items: SearchResultItem[]
  onRemove: (item: SearchResultItem) => void
}

export default function AddAssetsDialogList({ items, onRemove }: AddAssetsDialogListProps) {
  // To ensure a seamless collapse animation, we display and hide the container
  // when the first item is added or the last item is removed. This approach
  // prevents the border and paddings of the container from abruptly appearing
  // or disappearing.
  const [containerVisible, setContainerVisible] = useState(false)
  const [visibleItems, setVisibleItems] = useState<SearchResultItem[]>(items)

  useEffect(() => {
    setVisibleItems(items)
    setContainerVisible(items.length > 0)
  }, [items])

  const handleRemove = (item: SearchResultItem) => {
    if (items.length === 1) {
      setContainerVisible(false)
    }
    setVisibleItems(visibleItems.filter((visibleItem) => visibleItem !== item))
  }

  return (
    <StyledCollapse
      in={containerVisible}
      onExited={() => onRemove(items[0])}
      mountOnEnter
      unmountOnExit>
      <StyledDialogContent>
        {items.map((item) => (
          <Collapse
            key={item.id}
            in={visibleItems.includes(item)}
            mountOnEnter={items.length > 1}
            onExited={() => onRemove(item)}
            unmountOnExit>
            <ObjectListItem
              key={item.id}
              description={item.description}
              icon={<CompanyIcon />}
              imageUrl={item.imageUrl}
              name={item.name}
              region={item.regions[0]}
              type={'asset'}>
              <StyledIconButton
                color={'inherit'}
                onClick={() => handleRemove(item)}
                size={'small'}>
                <Trash2Icon color={'inherit'} />
              </StyledIconButton>
            </ObjectListItem>
          </Collapse>
        ))}
      </StyledDialogContent>
    </StyledCollapse>
  )
}

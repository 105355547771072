import { AssetCustomerBaseType } from '@gain/rpc/shared-model'

export const formatAssetCustomerBaseLabel = (value: AssetCustomerBaseType): string => {
  switch (value) {
    case AssetCustomerBaseType.BusinessToBusiness:
      return 'B2B'
    case AssetCustomerBaseType.BusinessToConsumer:
      return 'B2C'
    case AssetCustomerBaseType.BusinessToGovernment:
      return 'B2G'
  }
}

import { AssetShareholder } from '@gain/rpc/app-model'
import { AssetShareholderShare } from '@gain/rpc/shared-model'

export const sortAssetShareholdersByShare = (a: AssetShareholder, b: AssetShareholder) => {
  if (a.share === AssetShareholderShare.Majority) {
    return -1
  }
  if (b.share === AssetShareholderShare.Majority) {
    return 1
  }

  return 0
}

import { CompanyIcon } from '@gain/components/icons'
import { SearchResultItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import React from 'react'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { generateAssetPagePath } from '../../../utils'

export interface SearchResultListItemAssetProps {
  item: SearchResultItem
}

export default function SearchResultListItemAsset({ item }: SearchResultListItemAssetProps) {
  return (
    <MobileListItem key={`${item.type}-${item.id}`}>
      <MobileListItemButton
        as={Link}
        {...{ href: generateAssetPagePath({ id: item.id, name: item.name, preview: false }) }}>
        <MobileListItemIcon>
          <Logo
            defaultIcon={<CompanyIcon />}
            region={item.regions[0]}
            size={40}
            src={item.imageUrl}
          />
        </MobileListItemIcon>
        <MobileListItemText
          primary={item.name}
          secondary={item.description}
        />
      </MobileListItemButton>
    </MobileListItem>
  )
}

import { isTruthy } from '@gain/utils/common'
import { formatMonthYear } from '@gain/utils/date'

import { formatManagerPosition } from './format-manager-position'

export function formatManagerPositionSince(
  position: string | null | undefined,
  joinYear: number | null | undefined,
  joinMonth: number | null | undefined
) {
  return [
    formatManagerPosition(position, { disableEnumCheck: true }),
    joinYear ? formatMonthYear(joinMonth, joinYear) : null,
  ]
    .filter(isTruthy)
    .join(' since ')
}

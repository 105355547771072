export function isLocal() {
  return window.location.hostname === 'localhost'
}

export function isExp() {
  return window.location.hostname.startsWith('exp.')
}

export function isTest() {
  return window.location.hostname.startsWith('test.')
}

export function isAcc() {
  return window.location.hostname.startsWith('acc.')
}

export function isProduction() {
  return ['app.gain.pro', 'cms.gain.pro'].includes(window.location.hostname)
}

export type Environment = 'local' | 'exp' | 'test' | 'acc' | 'prod'

export function getEnvironment(): Environment {
  switch (true) {
    case isLocal():
      return 'local'

    case isExp():
      return 'exp'

    case isTest():
      return 'test'

    case isAcc():
      return 'acc'

    case isProduction():
    default:
      return 'prod'
  }
}

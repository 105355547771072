import { useFindCurrencyCallback } from '@gain/api/app/hooks'
import { isDefined } from '@gain/utils/common'
import { round } from 'lodash'
import { useCallback } from 'react'

/**
 * Converts a monetary value from one currency to another, using the EUR
 * as the intermediary currency.
 *
 * Given a value of 3000 USD with the following exchange rates:
 *
 * USD → EUR conversion = 0.92 EUR
 * YEN → EUR conversion = 0.0061 EUR
 *
 * To convert 3000 USD to YEN with EUR as the intermediary currency:
 *
 * 3000 * 0.92 = 2760 EUR
 * 2760 * (1/0.0061) = 452459 YEN
 *
 * All in one step:
 * 3000 * 0.92 * (1/0,0061) = 452459 YEN
 */
export function useConvertCurrencyCallback() {
  const findCurrency = useFindCurrencyCallback()

  return useCallback(
    (
      value: number | null | undefined,
      fromCurrency: string | null | undefined,
      toCurrency: string | null | undefined
    ): number | null => {
      if (!isDefined(value)) {
        return null
      }

      // If neither was set fallback to 1 and assume it's EUR.
      const fromExchangeRate = findCurrency(fromCurrency)?.toEur ?? 1
      const toExchangeRate = 1 / (findCurrency(toCurrency)?.toEur ?? 1)

      // Rough rounding to avoid floating point issues
      return round(value * fromExchangeRate * toExchangeRate, 8)
    },
    [findCurrency]
  )
}

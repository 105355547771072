import { IndustryIcon } from '@gain/components/icons'
import { IndustryListItem } from '@gain/rpc/app-model'

import ContentLink from '../../../common/content-link'
import { generateIndustryPagePath } from '../../../routes/utils'
import TooltipIndustry from '../industry-tooltip'

export interface ContentLinkIndustryProps {
  id: number
  title: string | null
  logoFileUrl?: string | null
  industry?: IndustryListItem | null
}

export default function ContentLinkIndustry({
  id,
  logoFileUrl,
  industry,
  ...props
}: ContentLinkIndustryProps) {
  const title = props.title || ''

  return (
    <TooltipIndustry
      id={id}
      industry={industry}>
      <ContentLink
        avatarProps={{
          src: logoFileUrl || undefined,
          disableBorder: true,
          imagePadding: 0,
          children: !logoFileUrl && <IndustryIcon />,
        }}
        href={generateIndustryPagePath({
          id: id,
          title: title,
          preview: false,
        })}
        label={title}
      />
    </TooltipIndustry>
  )
}

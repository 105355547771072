import { PopoverProps } from '@mui/material/Popover'
import { useEffect, useRef } from 'react'

function getElement(anchorEl: PopoverProps['anchorEl']) {
  return typeof anchorEl === 'function' ? null : anchorEl
}

function getOffset(anchorEl: PopoverProps['anchorEl']): [number | null, number | null] {
  const element = getElement(anchorEl)

  if (!element) {
    return [null, null]
  }

  const bottom = element.getBoundingClientRect().bottom + 4
  const top = element.getBoundingClientRect().top - 4

  return [bottom, top]
}

/**
 * Returns the offset if the popover is shown below the anchor element and
 * the offset when shown above.
 */
export default function useAnchorOffset(
  anchorEl: PopoverProps['anchorEl']
): [anchorBottom: number | null, anchorTop: number | null] {
  const element = getElement(anchorEl)
  const [anchorBottom, anchorTop] = getOffset(anchorEl)
  const prevAnchor = useRef({ anchorBottom, anchorTop })

  // Update the prevOffset reference whenever the "element" changes and still
  // exists. This ensures that when the element is no longer present, we retain
  // the latest offset value which helps maintain smooth animation and
  // prevents changes in maxHeight.
  useEffect(() => {
    if (element) {
      prevAnchor.current = { anchorBottom, anchorTop }
    }
  }, [element, anchorTop, anchorBottom])

  return element
    ? [anchorBottom, anchorTop]
    : [prevAnchor.current.anchorBottom, prevAnchor.current.anchorTop]
}

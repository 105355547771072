export function isChildOfDescendantAnchorWithHref(target: Element, container: Element) {
  let parent = target.parentElement
  while (parent !== container && parent !== null) {
    if (parent instanceof HTMLAnchorElement && parent.hasAttribute('href')) {
      return true
    }
    parent = parent.parentElement
  }
  return false
}

import { ListItemKey } from '@gain/rpc/list-model'
import { ComponentType, useMemo } from 'react'

import {
  mapValuesToOptions,
  useAutocompleteOptions,
} from '../filter/filter-autocomplete/filter-autocomplete-utils'
import {
  AutocompleteOption,
  FilterAutocompleteValue,
  FilterConfigAutocomplete,
} from '../filter-config/filter-config-model'
import { FilterValueLabelValueProps } from './filter-value-label-value'

function formatOptionGroup(options: AutocompleteOption[], maxItems: number) {
  const labels = options.map((option) => option.label)
  const visible = labels.slice(0, maxItems)
  const remaining = labels.slice(maxItems)

  if (remaining.length === 0) {
    return visible.join(', ')
  }

  return `${visible.join(', ')} +${remaining.length}`
}

export interface FilterValueAutocompleteLabelValueProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  label: string
  value: FilterAutocompleteValue
  filter: FilterConfigAutocomplete<Item, FilterField>
  ValueLabelComponent: ComponentType<FilterValueLabelValueProps>
}

export default function FilterValueAutocompleteLabelValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({
  label,
  value,
  filter,
  ValueLabelComponent,
}: FilterValueAutocompleteLabelValueProps<Item, FilterField>) {
  const values = useMemo(
    () => [...(value?.include.value || []), ...(value?.exclude.value || [])],
    [value]
  )

  const options = useAutocompleteOptions(null, values, filter.fetchOptions)

  const formattedValue = useMemo(() => {
    const include = mapValuesToOptions(value?.include.value, options.value)
    const exclude = mapValuesToOptions(value?.exclude.value, options.value)

    if (value == null || options.loading) {
      return ''
    } else if (include.length > 0) {
      if (exclude.length === 0) {
        return formatOptionGroup(include, 3)
      }
      return `${formatOptionGroup(include, 2)}  |  NOT ${formatOptionGroup(exclude, 2)}`
    }
    return `NOT ${formatOptionGroup(exclude, 2)}`
  }, [options, value])

  return (
    <ValueLabelComponent
      label={label}
      value={formattedValue}
    />
  )
}

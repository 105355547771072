import { COUNTRY_CODE_MAP } from '@gain/utils/countries'
import { styled } from '@mui/material/styles'
import React, { Fragment } from 'react'

interface AssetsPerRegionProps {
  regionNames: string[]
  regionCounts: number[]
  disableCounts?: boolean
}

const StyledRoot = styled('div', {
  shouldForwardProp: (propName) => propName !== 'disableCounts',
})<{ disableCounts: boolean }>(({ theme, disableCounts }) => ({
  ...theme.typography.body2,
  display: 'grid',
  gridTemplateColumns: 'minmax(100px, 1fr) 40px',
  rowGap: theme.spacing(1.25),
  columnGap: theme.spacing(1),
  ...(disableCounts && {
    gridTemplateColumns: '1fr',
  }),
}))

const StyledCount = styled('span')({
  textAlign: 'right',
})

export function AssetsPerRegion({
  regionNames,
  regionCounts,
  disableCounts = false,
}: AssetsPerRegionProps) {
  return (
    <StyledRoot disableCounts={disableCounts}>
      {regionNames.map((region, index) => (
        <Fragment key={index}>
          <span>{COUNTRY_CODE_MAP[region]}</span>
          {!disableCounts && <StyledCount>{regionCounts[index]}</StyledCount>}
        </Fragment>
      ))}
    </StyledRoot>
  )
}

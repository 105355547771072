import { styled } from '@mui/material/styles'

const Panel = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.divider}`,
  boxSizing: 'border-box',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
}))

export default Panel

import { useRpcClient } from '@gain/api/swr'
import PublicPage from '@gain/components/public-page'
import { useStartSession } from '@gain/modules/auth'
import { isJsonRpcError, isNotAuthorized, isValidationIssueArray } from '@gain/rpc/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Debug from 'debug'
import { parse } from 'query-string'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { AlertDialog } from '../../common/dialog'
import { FormError, FullWidthButton, FullWidthForm, TextInput } from '../../common/form'
import { useAutoAuthRedirect } from '../../features/auth/use-auth-redirect'
import { useTrackEvent } from '../../features/google-analytics'
import { HOME_PATH, OnboardingRouteParams } from '../utils'

const debug = Debug('gain-pro:onboarding')

interface OnboardingFormValues {
  password: string
  passwordRepeat: string
}

enum PageStatus {
  Fresh,
  Loading,
  InputError,
  TokenError,
  UnknownError,
}

/**
 * RouteOnboarding renders the onboarding page and allows a user
 * to fill in a new password before signing in for the first time.
 */
export default function RouteOnboarding() {
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Fresh)
  const [responseError, setResponseError] = useState<string | undefined>()
  const { token } = useParams<OnboardingRouteParams>()
  const location = useLocation()

  const fetcher = useRpcClient()
  const history = useHistory()
  const trackEvent = useTrackEvent()
  const startSession = useStartSession()

  // Automatically redirect user if already signed in
  useAutoAuthRedirect()

  // Define onboarding form
  const form = useForm<OnboardingFormValues>({
    defaultValues: { password: '', passwordRepeat: '' },
    resolver: yupResolver(
      yup.object({
        password: yup.string().trim().required(),
        passwordRepeat: yup
          .string()
          .trim()
          .required('Please repeat your password for confirmation')
          .oneOf([yup.ref('password')], 'Passwords should match'),
      })
    ),
  })

  // On form submit, send request for reset password
  const onSubmit: SubmitHandler<OnboardingFormValues> = async (formData) => {
    setPageStatus(PageStatus.Loading)
    try {
      // Ask backend to activate this account
      await fetcher({
        method: 'account.activateAccount',
        params: { password: formData.password, token },
      })

      // Perform client side login
      startSession()

      // Track success and move on to home page
      trackEvent('Onboarding success', 'Auth')
      history.push(HOME_PATH)
    } catch (error) {
      debug('activate account request failed', error)
      trackEvent('Onboarding failed', 'Auth')

      // Analyse server error and set proper page status
      if (isJsonRpcError(error)) {
        if (isValidationIssueArray(error.data)) {
          setResponseError(error.data.reduce((agg, item) => `${agg} ${item.message}`, ''))
          setPageStatus(PageStatus.InputError)
        } else if (isNotAuthorized(error.code)) {
          setPageStatus(PageStatus.TokenError)
        }
      } else {
        setPageStatus(PageStatus.UnknownError)
      }
    }
  }

  // Determine which texts to show
  let title: string | undefined
  let message = 'Enter a password to create your account'

  // Don't decode search params due to an ISO-8859-1 conversion in some email clients (e.g. ?firstName=Th%E9odore)
  const searchParams = parse(location.search, { decode: false })
  if (pageStatus === PageStatus.TokenError) {
    title = 'Invitation expired'
    message =
      'Invitation expired, please ask your teams administrator to send you a new invitation.'
  } else if (typeof searchParams.firstName === 'string') {
    // Do a best effort attempt to extract the user's name. Ignore any parse errors; the name
    // is only used for display purposes, so it's fine to omit.
    try {
      const firstName = decodeURIComponent(searchParams.firstName)
      title = `Welcome ${firstName}`
    } catch (e) {
      // Ignore
    }
  }

  return (
    <PublicPage
      message={message}
      title={title}>
      {/* Error dialog for unexpected server errors */}
      <AlertDialog
        message={'An error occurred please try again later'}
        onClose={() => {
          setPageStatus(PageStatus.Fresh)
        }}
        open={pageStatus === PageStatus.UnknownError}
        title={'Server Error'}
      />

      {/* Show onboarding/password form */}
      {pageStatus !== PageStatus.TokenError && (
        <FormProvider {...form}>
          <FullWidthForm onSubmit={form.handleSubmit(onSubmit)}>
            <TextInput
              autoComplete={'off'}
              autoFocus={true}
              name={'password'}
              placeholder={'Password'}
              type={'password'}
              disableHelperText
            />
            <TextInput
              autoComplete={'off'}
              name={'passwordRepeat'}
              placeholder={'Repeat password'}
              type={'password'}
              disableHelperText
            />

            <FormError>{form.formState.errors.passwordRepeat?.message ?? responseError}</FormError>

            <div>
              <FullWidthButton
                loading={pageStatus === PageStatus.Loading}
                sx={{ mt: 1 }}
                type={'submit'}>
                Sign in
              </FullWidthButton>
            </div>
          </FullWidthForm>
        </FormProvider>
      )}

      {/* On token error, send to home page */}
      {pageStatus === PageStatus.TokenError && <FullWidthButton to={HOME_PATH}>Ok</FullWidthButton>}

      {/* Go to login if user already has account */}
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        Already have an account?
        <Button
          color={'primary'}
          component={Link}
          to={HOME_PATH}
          variant={'text'}>
          Sign in
        </Button>
      </Typography>
    </PublicPage>
  )
}

import { useMemo } from 'react'

import useUserContext from './use-user-context'

export const useProjectCode = () => {
  const context = useUserContext()

  return useMemo(
    () => ({
      projectCode: context.userProfile.sessionTrackingProjectCode,
      openProjectCodeDialog: context.openProjectCodeDialog,
    }),
    [context.userProfile.sessionTrackingProjectCode, context.openProjectCodeDialog]
  )
}

export default useProjectCode

import { AssetListItem } from '@gain/rpc/app-model'
import { useContext } from 'react'

import { AssetUpdateContext } from './asset-update-context'
import { AssetUpdateType } from './asset-update-type'

export type UpdateTypeColor = 'default' | 'success' | 'info' | 'warning'

export function useUpdateTypeColor(type: AssetUpdateType, asset: AssetListItem): UpdateTypeColor {
  const ctx = useContext(AssetUpdateContext)

  if (type === AssetUpdateType.ProcessingNewData) {
    return 'warning'
  }

  const hasUpdates = ctx && ctx.hasUpdates(asset)

  if (!hasUpdates || type === AssetUpdateType.None) {
    return 'default'
  }

  switch (type) {
    case AssetUpdateType.Financials:
      return 'success'
    case AssetUpdateType.New:
      return 'info'
  }
}

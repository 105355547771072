import { useCallback, useMemo, useState } from 'react'

export type HandleShowAll = () => void

export function useVisibleListItems<Item>(
  initialItems: Item[],
  additionalItems: Item[]
): [Item[], boolean, HandleShowAll] {
  const [showAll, setShowAll] = useState<boolean>(false)

  const rows = useMemo(() => {
    if (showAll) {
      return initialItems.concat(additionalItems)
    }

    return initialItems
  }, [additionalItems, initialItems, showAll])

  const handleShowAll = useCallback(() => {
    setShowAll(true)
  }, [])

  return [rows, showAll, handleShowAll]
}

import {
  BookOpenIcon,
  HelpCircleIcon,
  MessageCircleIcon,
  MonitorIcon,
  PhoneIcon,
} from '@gain/components/icons'
import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { MouseEvent, useState } from 'react'

import { StyledDialog } from '../../../common/dialog/styled-dialog'
import { useZendesk, ZENDESK_HELP_CENTER_URL } from '../../zendesk'
import MenuListItem from '../menu-list-item'
import { getSupportHours } from './get-support-hours'

const StyledCallDialog = styled(StyledDialog)<DialogProps>({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 380,
  },
})

export default function HelpMenuOptions() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)
  const [dialogOpen, handleDialogOpen, handleDialogClose] = useDialogState()

  const { enableChat, isLoading: isChatLoading } = useZendesk()

  const handleMenuClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const today = new Date()

  // Get the user timezone, e.g. 'Europe/London'
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Get the user timezone formatted name, e.g. 'CET' or 'EST'. It uses the nl-NL locale to get the
  // correct abbreviations, because en-US doesn't have the correct abbreviations for Europe.
  const timeZoneAbbreviation =
    new Intl.DateTimeFormat('nl-NL', { timeZoneName: 'short' })
      .formatToParts(today)
      .find((part) => part.type === 'timeZoneName')?.value || ''

  const [startTime, endTime] = getSupportHours(today, userTimeZone)
  const supportHoursText = `on weekdays from ${startTime} to ${endTime} ${timeZoneAbbreviation}`

  return (
    <>
      <MenuListItem
        icon={<HelpCircleIcon fontSize={'inherit'} />}
        label={'Help'}
        onClick={handleMenuClick}
      />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleMenuClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}>
        <MenuItem
          component={Link}
          href={ZENDESK_HELP_CENTER_URL}
          rel={'noopener noreferrer'}
          target={'_blank'}>
          <ListItemIcon>
            <BookOpenIcon />
          </ListItemIcon>
          <ListItemText primary={'Help Center'} />
        </MenuItem>

        <MenuItem
          disabled={isChatLoading}
          onClick={enableChat}>
          <ListItemIcon>
            <MessageCircleIcon />
          </ListItemIcon>
          <ListItemText primary={'Message support'} />
        </MenuItem>

        <MenuItem onClick={handleDialogOpen}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={'Call support'} />
        </MenuItem>

        <MenuItem
          component={Link}
          href={'https://marketing.gain.pro/user.webinars'}
          rel={'noopener noreferrer'}
          target={'_blank'}>
          <ListItemIcon>
            <MonitorIcon />
          </ListItemIcon>
          <ListItemText primary={'Webinars'} />
        </MenuItem>
      </Menu>

      <StyledCallDialog
        onClose={handleDialogClose}
        open={dialogOpen}>
        <DialogTitle>Call Support</DialogTitle>

        <DialogContent sx={{ mt: 2 }}>
          <Typography
            align={'center'}
            color={'text.secondary'}
            variant={'body2'}>
            Do you need assistance? Our team is available to support {supportHoursText}.
          </Typography>

          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={1}
            justifyContent={'center'}
            mb={1}
            mt={3}>
            <PhoneIcon />
            <Typography variant={'subtitle2'}>+44 20 3974 2133</Typography>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            color={'primary'}
            onClick={handleDialogClose}
            sx={{ minWidth: 120 }}
            variant={'contained'}>
            Close
          </Button>
        </DialogActions>
      </StyledCallDialog>
    </>
  )
}

import { Deal } from '@gain/rpc/app-model'
import { formatDealTitle } from '@gain/utils/deal'
import { useMemo } from 'react'

import ContentLink from '../../../common/content-link'
import { generateDealPagePath } from '../../../routes/utils'
import TooltipDeal from '../deal-tooltip'

export interface ContentLinkDealProps {
  id: number
  deal: Deal
}

export default function ContentLinkDeal({ id, deal }: ContentLinkDealProps) {
  const title = useMemo(() => formatDealTitle(deal), [deal])
  return (
    <TooltipDeal id={id}>
      <ContentLink
        href={generateDealPagePath({
          id,
          name: title,
        })}
        label={title}
        labelTypographyProps={{
          disableTooltip: Boolean(id),
        }}
      />
    </TooltipDeal>
  )
}

import { useInvestorDealsPerTypeByYear } from '@gain/api/app/hooks'
import { SpinnerIcon } from '@gain/components/icons'
import { Investor, InvestorDealsPerTypeByYear } from '@gain/rpc/app-model'
import { useOpenLink } from '@gain/utils/router'
import React, { MouseEvent, Suspense, useCallback } from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import CardDealsSummary from '../../../../features/deal/deals-summary-card'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_ADD_ONS_PATH,
  INVESTOR_PAGE_ENTRIES_PATH,
  INVESTOR_PAGE_EXISTS_PATH,
  useInvestorPageParams,
} from '../../../utils'

const INVESTOR_GROUP_CONFIG = [
  { label: 'Entries', key: 'entry' },
  { label: 'Exits', key: 'exit' },
  { label: 'Add-ons', key: 'add-on' },
]

const GROUP_TYPE_TO_TAB_MAP = {
  entry: INVESTOR_PAGE_ENTRIES_PATH,
  exit: INVESTOR_PAGE_EXISTS_PATH,
  'add-on': INVESTOR_PAGE_ADD_ONS_PATH,
}

function InnerInvestorDealsCard({ investor }: InvestorDealsCardProps) {
  const params = useInvestorPageParams()
  const openLink = useOpenLink()
  const { data } = useInvestorDealsPerTypeByYear(
    {
      investorId: investor.id,
      maxYears: 6,
    },
    {
      suspense: true,
    }
  )

  const handleGroupClick = useCallback(
    (group: InvestorDealsPerTypeByYear, event: MouseEvent) => {
      openLink(generateInvestorPagePath(params, GROUP_TYPE_TO_TAB_MAP[group.type]), event)
    },
    [openLink, params]
  )

  return (
    <CardDealsSummary
      data={data}
      groupConfig={INVESTOR_GROUP_CONFIG}
      onClick={handleGroupClick}
    />
  )
}

export interface InvestorDealsCardProps {
  investor: Investor
}

export default function InvestorDealsCard({ investor }: InvestorDealsCardProps) {
  return (
    <Card
      sx={{ minHeight: 288 }}
      fullHeight>
      <CardHeader title={'Deals'} />
      <CardContent sx={{ flexGrow: 1 }}>
        <Suspense fallback={<SpinnerIcon />}>
          <InnerInvestorDealsCard investor={investor} />
        </Suspense>
      </CardContent>
    </Card>
  )
}

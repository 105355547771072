import { DealSideType, DealStatus } from '@gain/rpc/shared-model'
import { DealPartyType, formatDealShareLabel } from '@gain/utils/deal'
import { DealParty } from '@gain/utils/deal'
import { useMemo } from 'react'

import ContentLink from '../../../common/content-link'
import AssetContentLink from '../../asset/asset-content-link'
import InvestorContentLink from '../../investor/investor-content-link'

export interface ContentLinkDealPartyProps {
  party: DealParty
  dealStatus: DealStatus | null
  type: DealPartyType
  disableDescription?: boolean
  disableTooltip?: boolean
}

export default function ContentLinkDealParty({
  party,
  dealStatus,
  type,
  disableDescription,
  disableTooltip,
}: ContentLinkDealPartyProps) {
  const description = useMemo(() => {
    if (disableDescription) {
      return null
    }
    return formatDealShareLabel(party.share?.value, party.sharePct?.value, dealStatus, type)
  }, [party, dealStatus, type, disableDescription])

  switch (party.type) {
    case DealSideType.Investor:
      return (
        <InvestorContentLink
          description={description}
          disableTooltip={disableTooltip}
          id={party.linkedInvestorId}
          logoFileUrl={party.investor?.logoFileUrl}
          name={party.investor?.name || party.name}
        />
      )
    case DealSideType.Asset:
      return (
        <AssetContentLink
          description={description}
          disableTooltip={disableTooltip}
          id={party.linkedAssetId}
          logoFileUrl={party.asset?.logoFileUrl}
          name={party.name || party.asset?.name}
        />
      )
    default:
      return (
        <ContentLink
          description={description}
          label={party.name}
        />
      )
  }
}

import { TZDate } from '@date-fns/tz'
import { addHours } from 'date-fns/addHours'
import { format } from 'date-fns/format'

export function getSupportHours(today: Date, userTimeZone: string) {
  const startTime = new TZDate(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    9,
    'Europe/Amsterdam'
  ).withTimeZone(userTimeZone)
  const endTime = addHours(startTime, 17 - 9) // Until 17:00

  return [format(startTime, 'HH:mm'), format(endTime, 'HH:mm')] as const
}

import { Components, Theme } from '@mui/material/styles'

export const MuiAvatar = (theme: Theme): Components['MuiAvatar'] => ({
  styleOverrides: {
    root: {
      border: `1px solid ${theme.palette.divider}`,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
    },
    img: {
      objectFit: 'contain',
      width: '100%',
      padding: theme.spacing(),
    },
    colorDefault: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
    },
    rounded: {
      borderRadius: 8,
    },
  },
})

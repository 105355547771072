import { TooltipProps } from '@mui/material/Tooltip'
import * as echarts from 'echarts/core'
import { useCallback, useState } from 'react'

interface Rect {
  width: number
  height: number
  x: number
  y: number
}

type Transform = number[]

interface Data {
  date: string
  value: number
}

/**
 * ActiveRatio is the point that is being hovered upon with its position,
 * dimension, transform and data within the chart. This is used to allow
 * us to display a MUI tooltip next to a hovered point within the EChart
 * (canvas) line chart.
 */
export interface ActiveRatio {
  rect: Rect
  transform: Transform
  data: Data
  placement: TooltipProps['placement']
}

/**
 * useRatiosChartHover returns the currently hovered ratio data point and mouse
 * event handlers that convert EChart event state to an ActiveRatio. This
 * enables us to plot a tooltip next to a hovered data point.
 */
export default function useRatiosChartHover() {
  const [activeRatio, setActiveRatio] = useState<ActiveRatio | null>(null)

  const handleSetActiveRatio = useCallback((options: ActiveRatio) => {
    // Set the active ratio but only if the actual data point has changed
    // to prevent unnecessary state changes
    setActiveRatio((prev) => {
      if (prev?.data.date !== options.data.date) {
        return {
          ...options,
          rect: {
            ...options.rect,
            // Compensate chart offsets and make sure the tooltip
            // is shown directly next to a plotted data point.
            x: options.rect.x + 24,
            y: options.rect.y + 36,
          },
        }
      }

      return prev
    })
  }, [])

  const handleMouseMove = useCallback(
    (event: echarts.ECElementEvent) => {
      if (
        !event.event ||
        event.data == null ||
        typeof event.data !== 'object' ||
        !event.event.topTarget
      ) {
        return
      }

      if (
        event.componentType === 'markPoint' &&
        // Validate
        'name' in event.data &&
        typeof event.data.name === 'string' &&
        'value' in event.data &&
        typeof event.data.value === 'number'
      ) {
        const rect = event.event.topTarget.getBoundingRect()
        const transform = event.event.topTarget.transform
        const data = {
          date: event.data.name,
          value: event.data.value,
        }
        let placement: TooltipProps['placement'] = 'top'
        if (data.value > 0) {
          placement = 'bottom'
        }

        handleSetActiveRatio({
          rect,
          transform,
          data,
          placement,
        })
      }

      if (
        event.componentType === 'series' &&
        'value' in event.data &&
        Array.isArray(event.data.value) &&
        event.data.value.length === 2
      ) {
        // The bounding rect is mostly used for dimensions. The actual position
        // only makes sense when used together with the transform.
        const rect = event.event.topTarget.getBoundingRect()
        const transform = event.event.topTarget.transform
        const [date, value] = event.data.value

        // Validate event data
        if (typeof date !== 'string' || typeof value !== 'number') {
          return
        }

        handleSetActiveRatio({
          rect,
          transform,
          data: { date, value },
          placement: 'left',
        })
      }
    },
    [handleSetActiveRatio]
  )

  // Resets the state on mouse out.
  const handleMouseOut = useCallback(() => {
    setActiveRatio(null)
  }, [])

  return [activeRatio, handleMouseMove, handleMouseOut] as const
}

import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import React from 'react'
import { generatePath } from 'react-router-dom'

import ChartsAssetAnalysis from '../../../features/asset/asset-analysis'
import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../../features/investor-strategy/investor-strategy-contants'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import { NAV_BAR_HEIGHT } from '../../../layout/nav-bar'
import {
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../route-strategy-path'

export interface RouteAnalysisProps {
  investor: Investor
}

export default function RouteAnalysis({ investor }: RouteAnalysisProps) {
  const params = useInvestorStrategyPageParams()

  useTrackPageView('investor_strategy', {
    id: params.strategyId,
    tab: 'analysis',
    requireTab: true,
  })

  return (
    <>
      <Head>
        <title>
          {investor?.name || ''} -{' '}
          {params.strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL} - Analysis
        </title>
      </Head>

      <ChartsAssetAnalysis
        benchmarkingPath={generatePath(INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH, params)}
        filter={[listFilter('strategyIds', '=', params.strategyId)]}
        spacingTop={NAV_BAR_HEIGHT + 16}
      />
    </>
  )
}

import TooltipInvestor, { TooltipInvestorProps } from './tooltip-investor.component'

export type TooltipInvestorWrapperProps = Omit<TooltipInvestorProps, 'id'> & {
  id?: TooltipInvestorProps['id'] | null
  disableTooltip?: boolean
}

export default function TooltipInvestorWrapper({
  id,
  children,
  disableTooltip,
  ...tooltipProps
}: TooltipInvestorWrapperProps) {
  if (typeof id === 'number' && !disableTooltip) {
    return (
      <TooltipInvestor
        id={id}
        {...tooltipProps}>
        {children}
      </TooltipInvestor>
    )
  }

  return children
}

import { DealListItem, RelatedDealAdvisor } from '@gain/rpc/app-model'
import { getAdvisoryActivityLabel } from '@gain/utils/advisor'
import { useSplitList } from '@gain/utils/list'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { MouseEvent, useCallback, useMemo, useRef, useState } from 'react'

import ContentLink, { ContentLinkCellContainer } from '../../common/content-link'
import EbitdaValuesRange from '../../common/ebitda-values-range'
import Table, { createTableColumns } from '../../common/table'
import ViewMoreButton from '../../common/view-more-button'
import AdvisorContentLink from '../advisor/advisor-content-link'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../filter/filter-bar'
import DealAdvisorAssets from './deal-advisor-assets'

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
})

export interface RelatedDealAdvisorsTableProps {
  dealsPath: string | ((advisor: RelatedDealAdvisor) => string)
  items: RelatedDealAdvisor[]
  onMoreClick?: (event: MouseEvent) => void
  nrOfVisibleRows?: number
}

export default function RelatedDealAdvisorsTable({
  dealsPath,
  items,
  onMoreClick,
  nrOfVisibleRows = 20,
}: RelatedDealAdvisorsTableProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const [initialRows, otherRows] = useSplitList(items, nrOfVisibleRows)
  const [showAll, setShowAll] = useState(false)

  const columns = useMemo(() => {
    return createTableColumns<RelatedDealAdvisor>(
      {
        field: 'advisorId',
        headerName: 'Name',
        renderCell: ({ row }) => (
          <ContentLinkCellContainer>
            <AdvisorContentLink
              advisorId={row.advisorId}
              advisorName={row.advisorName}
              logoFileUrl={row.advisorLogoFileUrl}
            />
          </ContentLinkCellContainer>
        ),
      },
      {
        field: 'dealIds',
        headerName: 'No. of deals advised',
        align: 'right',
        width: 164,
        renderCell: ({ row }) => {
          // Generate URL to the deals path with the advisor filter
          // Determine href; if dealsPath is a string add the filter ourselves,
          // otherwise let the callback generate it.
          let href: string
          if (typeof dealsPath === 'string') {
            const advisorFilterQueryString = filterModelToQueryString<DealListItem, 'advisorIds'>([
              filterValueGroup(
                filterValueItem('advisorIds', autocompleteIncludeFilterValue([row.advisorId]))
              ),
            ])
            href = `${dealsPath}?${advisorFilterQueryString}`
          } else {
            href = dealsPath(row)
          }

          return (
            <Stack
              direction={'row'}
              display={'flex'}
              justifyContent={'flex-end'}>
              <ContentLink
                href={href}
                label={`${row.dealIds.length}`}
              />
            </Stack>
          )
        },
      },
      {
        field: 'dealEbitdasEur',
        headerName: 'Deal EBITDA range',
        align: 'right',
        width: 180,
        paddingLeft: 2,
        renderCell: ({ value }) => <EbitdaValuesRange ebitdaValues={value} />,
      },
      {
        field: 'assets',
        headerName: 'Companies advised',
        align: 'left',
        width: 240,
        paddingLeft: 2,
        renderCell: ({ value }) => <DealAdvisorAssets assets={value} />,
      },
      {
        field: 'coreFocus',
        headerName: 'Core focus',
        width: 180,
        valueFormatter: ({ value }) => (value ? getAdvisoryActivityLabel(value) : '-'),
      }
    )
  }, [dealsPath])

  const columnVisibility = useMemo((): ColumnVisibilityModel<RelatedDealAdvisor> => {
    return {
      dealEbitdasEur: 1020,
      assets: 840,
      coreFocus: 580,
    }
  }, [])

  const handleMoreClick = useCallback(
    (event: MouseEvent) => {
      if (onMoreClick) {
        onMoreClick(event)
      } else {
        setShowAll(true)
      }
    },
    [onMoreClick]
  )

  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  return (
    <StyledRoot>
      <Table
        ref={tableRef}
        amountOfPlaceholders={4}
        columns={visibleColumns}
        rows={showAll ? items : initialRows}
        disablePaddingBottomOnLastRow
      />

      {!showAll && (
        <ViewMoreButton
          amount={otherRows.length}
          onClick={handleMoreClick}
          variant={'chevron-down'}
        />
      )}
    </StyledRoot>
  )
}

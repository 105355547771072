import { Environment, getEnvironment } from '@gain/utils/environment'

interface Config {
  apiKey: string
  projectId: string
}

// API keys are restricted to only allow gain pro domains and error reporting API
const ERROR_REPORTER_CONFIG: Readonly<Record<Environment, Config | null>> = {
  // Reporting is disabled on localhost
  local: null,

  // The exp key is also used for the test browser extension
  // See: https://console.cloud.google.com/apis/credentials/key/bca16674-9cb6-4607-8734-dc58b67fa8c7?hl=en&project=gain-exp
  exp: {
    apiKey: 'AIzaSyBMBTGm53qsNkI-slPFSh2nr9KBdsBil1w',
    projectId: 'gain-exp',
  },

  // See: https://console.cloud.google.com/apis/credentials/key/6d0f08d2-0560-46c1-b779-c0fff06d9f34?hl=en&project=gain-test-414309
  test: {
    apiKey: 'AIzaSyCugg5htIy-dXRDvbGK1unKZbtOxRm0A2Q',
    projectId: 'gain-test-414309',
  },

  // See: https://console.cloud.google.com/apis/credentials/key/d232ba38-22b1-4765-b344-f4b684a010e0?hl=en&project=gain-acc
  acc: {
    apiKey: 'AIzaSyBnhrUhQyYeRYP5xmLFi3nebQqR3p71V_c',
    projectId: 'gain-acc',
  },

  // See: https://console.cloud.google.com/apis/credentials/key/a0a23b97-ccc0-4c38-8bbd-a148199b0b16?hl=en&project=gain-prod-414309
  prod: {
    apiKey: 'AIzaSyDvJn5rC-fOLddf6X_3IzM_UfrBQKTXkhU',
    projectId: 'gain-prod-414309',
  },
}

export function getErrorReporterConfig() {
  return ERROR_REPORTER_CONFIG[getEnvironment()]
}

import { DownloadIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import LoadingButton from '@mui/lab/LoadingButton'
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton'
import { styled } from '@mui/material/styles'
import React from 'react'

import ConditionalWrapper from '../../../common/conditional-wrapper'
import { ExportContainerApi } from './export-container'
import { UNLIMITED_EXPORTS } from './export-method-config'
import { exportsRemainingMessage } from './export-utils'

const StyledLoadingButton = styled(LoadingButton)({
  // The spinner is 34x34, avoid jumping content when the spinner is shown
  minWidth: 34,
  minHeight: 34,
})

export interface ExportLoadingButtonProps {
  color?: LoadingButtonProps['color']
  variant?: 'icon' | 'text' | 'contained' | 'icon-contained'
  text?: string
  className?: string
  fullWidth?: boolean
  tooltipMode?: 'never' | 'max-only' | 'always'
}

type ExportLoadingButtonPropsWithApi = ExportLoadingButtonProps & ExportContainerApi

export default function ExportLoadingButton({
  color,
  className,
  variant = 'icon',
  disabled = false,
  maxExports,
  currentExports,
  tooltipMode = 'max-only',
  loading,
  download,
  fullWidth,
  text = 'Export',
}: ExportLoadingButtonPropsWithApi) {
  const isIcon = variant === 'icon' || variant === 'icon-contained'

  // Determine whether to show the tooltip based on the tooltipMode
  let showTooltip = tooltipMode === 'always'
  if (tooltipMode === 'max-only') {
    showTooltip = maxExports !== UNLIMITED_EXPORTS && currentExports >= maxExports
  }

  return (
    <ConditionalWrapper
      condition={showTooltip}
      wrapper={(children) => (
        <Tooltip
          placement={'top'}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10], // Because of button styling it looks better if the tooltip is a bit closer
                },
              },
            ],
          }}
          title={exportsRemainingMessage(currentExports, maxExports)}>
          {/* Without <span /> the tooltip won't show when the button is disabled */}
          <div style={{ width: fullWidth ? '100%' : 'fit-content' }}>{children}</div>
        </Tooltip>
      )}>
      <StyledLoadingButton
        className={className}
        color={color}
        disabled={disabled}
        fullWidth={fullWidth}
        loading={loading}
        loadingPosition={isIcon ? undefined : 'start'}
        onClick={download}
        startIcon={isIcon ? undefined : <DownloadIcon />}
        variant={variant === 'icon-contained' ? 'contained' : variant}>
        {isIcon ? <DownloadIcon /> : text}
      </StyledLoadingButton>
    </ConditionalWrapper>
  )
}

import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

export const SettingTitle = styled((props: TypographyProps) => (
  <Typography
    variant={'subtitle1'}
    {...props}
  />
))({
  marginBottom: 2,
})

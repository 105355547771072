import { LegalEntity } from '@gain/rpc/app-model'
import { getAnnualReportYear } from '@gain/utils/annual-report'
import { isTruthy } from '@gain/utils/common'
import { styled } from '@mui/material/styles'
import { uniq } from 'lodash'

import { ExportButton } from '../../../../features/export/export-button'

const StyledExportButton = styled(ExportButton)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
}))

interface AssetLegalEntitiesTableAllAnnualReportsCellProps {
  legalEntity: LegalEntity
}

export default function AssetLegalEntitiesTableAllAnnualReportsCell({
  legalEntity,
}: AssetLegalEntitiesTableAllAnnualReportsCellProps) {
  const years = legalEntity.annualReports.map(getAnnualReportYear).filter(isTruthy)
  const min = Math.min(...years)
  const max = Math.max(...years)

  if (years.length === 0) {
    return <>-</>
  }

  return (
    <StyledExportButton
      color={'primary'}
      method={'data.downloadLegalEntityAnnualReportFiles'}
      params={{
        legalEntityId: legalEntity.id,
        filename: `${legalEntity.name} (${legalEntity.region}) - Annual reports.zip`,
      }}
      text={uniq([min, max]).join('-')}
      tooltipMode={'always'}
      variant={'text'}
    />
  )
}

import { isDefined } from '@gain/utils/common'
import React, { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

export type LinkBehaviorProps = Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }

const gainProHosts = ['gain.pro', 'acc.gain.pro', 'test.gain.pro', 'exp.gain.pro', 'localhost:3000']

const isExternalLink = (href: RouterLinkProps['to']) => {
  try {
    const url = new URL(href.toString())
    return !gainProHosts.includes(url.hostname)
  } catch (_) {
    return false
  }
}

const LinkBehavior = forwardRef<never, LinkBehaviorProps>((props, ref) => {
  const { href, ...other } = props

  if (!isDefined(href) || isExternalLink(href)) {
    return (
      <a
        {...other}
        ref={ref}
        href={href}
      />
    )
  }

  return (
    <RouterLink
      ref={ref}
      to={href}
      {...other}
    />
  )
})

export default LinkBehavior

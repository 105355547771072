import { isGermany } from '@gain/utils/countries'

import { LEGAL_ENTITY_EXTERNAL_ID_GERMAN_SEPARATOR } from './legal-entity-constants'

export function formatExternalId(externalId: string, region: string | null) {
  if (isGermany(region)) {
    const { 1: type = '', 2: id = '' } = externalId.split(LEGAL_ENTITY_EXTERNAL_ID_GERMAN_SEPARATOR)
    return `${type} ${id}`
  }

  return externalId
}

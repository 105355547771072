import { useMemo } from 'react'

export default function useMergeOptions<T>(defaultOptions: T, options: T) {
  return useMemo(
    () => ({
      ...defaultOptions,
      ...options,
    }),
    [defaultOptions, options]
  )
}

import { useRpcClient } from '@gain/api/swr'
import PublicPage from '@gain/components/public-page'
import { useStartSession } from '@gain/modules/auth'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { AuthenticationType, GetAuthenticationTypeResponse } from '@gain/rpc/app-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import MUILink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Debug from 'debug'
import { parse } from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router'
import * as yup from 'yup'

import { FormError, FullWidthButton, FullWidthForm, TextInput } from '../../common/form'
import {
  useAuthRedirect,
  useAuthRedirectUrl,
  useAutoAuthRedirect,
  useIsCmsRedirect,
} from '../../features/auth/use-auth-redirect'
import { useTrackEvent } from '../../features/google-analytics'
import { useZendesk } from '../../features/zendesk'
import { FORGOT_PASSWORD_PATH } from '../utils'

const debug = Debug('gain-pro:login')

export interface RouteLoginProps {
  handleSSOLogin?: (url: string) => void
  handlePasswordLogin?: (token: string) => void
}

interface LoginFormValues {
  username: string
  password: string
}

enum PageStatus {
  Fresh,
  Loading,
  Error,
}

/**
 * RouteLogin renders the login page. It enables both SSO login
 * and conventional credential login. On blur on the username
 * field, it will check whether that user should sign in on an
 * SSO redirect URL, or using their normal email/password. In
 * this last scenario, the password part of the form is shown.
 */
export default function RouteLogin({ handleSSOLogin, handlePasswordLogin }: RouteLoginProps) {
  const [pageStatus, setPageStatus] = useState(PageStatus.Fresh)
  const [responseError, setResponseError] = useState<string | undefined>()
  const [showPassword, setShowPassword] = useState(false)
  const passwordFieldRef = useRef<HTMLInputElement | undefined>()
  const isBrowserExtension = useIsBrowserExtension()

  const { enableChat } = useZendesk()
  const trackEvent = useTrackEvent()
  const fetcher = useRpcClient()
  const location = useLocation()
  const startSession = useStartSession()
  const redirectUser = useAuthRedirect()
  const redirectUrl = useAuthRedirectUrl()
  const isCmsRedirect = useIsCmsRedirect()

  // Automatically redirect user if already signed in
  useAutoAuthRedirect()

  // Define schema for validating the login form
  const schema = yup.object({
    username: yup.string().trim().email().required(),
    password: showPassword ? yup.string().trim().required() : yup.string(),
  })

  // Login form, validate on blur to go with SSO login type check
  const form = useForm<LoginFormValues>({
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  // Keep watch on username, so we can validate before asking server
  const username = form.watch('username')

  // Directly hide password field if username is emptied
  useEffect(() => {
    if (showPassword && (username == null || username.trim() === '')) {
      setShowPassword(false)
    }
  }, [showPassword, username])

  // Check login type on blur (email / SSO)
  const checkLoginType = async () => {
    // Validate and sanitize username as valid email address
    let cleanUsername: string
    try {
      // Unfortunately the type of `validateAt` is not correct
      cleanUsername = (await schema.validateAt('username', { username })) as unknown as string
    } catch (error) {
      setShowPassword(false)
      return
    }

    // Perform server request to get login type
    setPageStatus(PageStatus.Loading)
    let result: GetAuthenticationTypeResponse | undefined
    try {
      result = await fetcher({
        method: 'account.getAuthenticationType',
        params: { email: cleanUsername, redirectUrl: redirectUrl ?? null },
      })
    } catch (error) {
      setShowPassword(false)
    }

    // If login type is SSO, redirect to provided URL
    if (result?.type === AuthenticationType.Sso && result?.url != null) {
      setShowPassword(false)

      if (handleSSOLogin) {
        handleSSOLogin(result.url)
      } else {
        window.location.replace(result.url)
      }
      return
    }

    // Login type is email, show and focus  password field
    setShowPassword(true)
    setPageStatus(PageStatus.Fresh)
    if (passwordFieldRef.current != null) {
      setTimeout(() => {
        passwordFieldRef.current?.focus()
      }, 100)
    }
  }

  // Form submit, try to login with the provided credentials
  const onSubmit: SubmitHandler<LoginFormValues> = async (formData) => {
    // Check login type if password field isn't visible
    if (!showPassword) {
      await checkLoginType()
      return
    }

    // Init server side login
    setPageStatus(PageStatus.Loading)
    try {
      // Perform server side credential login
      const token = await fetcher({
        method: 'account.login',
        params: {
          username: formData.username,
          password: formData.password,
        },
      })

      // Perform client side login
      startSession()

      trackEvent('Login success', 'Auth')

      // Redirect user
      if (handlePasswordLogin) {
        handlePasswordLogin(token)
      } else {
        redirectUser()
      }
    } catch (error) {
      // Determine which error to show
      if (isJsonRpcError(error)) {
        setResponseError(error.message)
      } else {
        setResponseError('An unexpected error occurred, please try again later or contact support')
      }
      setPageStatus(PageStatus.Error)

      debug('login request failed', error)
      trackEvent('Login failed', 'Auth')
    }
  }

  // Don't decode search params due to an ISO-8859-1 conversion in some email clients (e.g. ?firstName=Th%E9odore)
  const searchParams = parse(location.search, { decode: false })
  let name: string | null = null
  if (typeof searchParams.firstName === 'string') {
    // Do a best effort attempt to extract the user's name. Ignore any parse errors; the name
    // is only used for display purposes, so it's fine to omit.
    try {
      name = decodeURIComponent(searchParams.firstName)
    } catch (e) {
      // Ignore
    }
  }

  return (
    <PublicPage
      logoSubView={
        isCmsRedirect && (
          <Chip
            color={'warning'}
            label={'CMS'}
          />
        )
      }
      message={'Please sign in using your email address'}
      title={name !== null ? `Welcome ${name}` : undefined}>
      {/* Always show login form */}
      <FormProvider {...form}>
        <FullWidthForm onSubmit={form.handleSubmit(onSubmit)}>
          <TextInput
            autoComplete={'username'}
            autoFocus={true}
            name={'username'}
            onBlur={() => checkLoginType()}
            placeholder={'Email address'}
            disableHelperText
          />

          {/* Password section, only show when not SSO */}
          <Collapse in={showPassword}>
            <TextInput
              autoComplete={'current-password'}
              inputRef={passwordFieldRef}
              name={'password'}
              placeholder={'Password'}
              type={'password'}
              disableHelperText
            />

            <MUILink
              href={FORGOT_PASSWORD_PATH}
              variant={'body2'}>
              Forgot password?
            </MUILink>
          </Collapse>

          <FullWidthButton
            loading={pageStatus === PageStatus.Loading}
            type={'submit'}>
            Sign in
          </FullWidthButton>

          <FormError>{responseError}</FormError>
        </FullWidthForm>
      </FormProvider>

      {/* Support footer */}
      {!isBrowserExtension && (
        <Typography
          color={'text.secondary'}
          textAlign={'center'}
          variant={'body2'}>
          Having trouble signing in?
          <Button
            color={'primary'}
            onClick={enableChat}
            variant={'text'}>
            Contact support
          </Button>
        </Typography>
      )}
    </PublicPage>
  )
}

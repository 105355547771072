import { ListItemKey } from '@gain/rpc/list-model'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'

import CustomOutlinedInput from '../../../../common/form/custom-outlined-input'
import { FilterConfigRange } from '../filter-config/filter-config-model'

const StyledFilterInput = styled(CustomOutlinedInput)({
  flex: 1,
  [`& .${outlinedInputClasses.input}[type=number]`]: {
    textAlign: 'right',
  },
})

export interface FilterRangeNumberInputProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filter: FilterConfigRange<Item, FilterField>
  autoFocus?: boolean
  value: number | null
  placeholder?: string
  onChange: (value: number | null) => void
}

export default function FilterRangeNumberInput<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({
  value,
  onChange,
  filter,
  placeholder,
  autoFocus,
}: FilterRangeNumberInputProps<Item, FilterField>) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value !== '' ? Number(event.currentTarget.value) : null
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <StyledFilterInput
      autoFocus={autoFocus}
      endAdornment={filter.suffix}
      inputProps={{
        min: filter.min,
        max: filter.max,
        step: filter.step,
      }}
      onChange={handleChange}
      placeholder={placeholder}
      startAdornment={filter.prefix}
      type={'number'}
      value={typeof value === 'number' ? value : ''}
    />
  )
}

import Tooltip from '@gain/components/tooltip'
import { styled } from '@mui/material/styles'

interface StyledDealMetricValueProps {
  confidence: string
}

const StyledDealMetricValue = styled('span', {
  shouldForwardProp: (prop) => prop !== 'confidence',
})<StyledDealMetricValueProps>(({ theme, confidence }) => ({
  ...(confidence === 'estimate' && {
    color: theme.palette.info.main,
  }),
}))

export interface DealMetricValueProps {
  confidence: string
  children: string
}

export default function DealMetricValue({ confidence, children }: DealMetricValueProps) {
  if (confidence === 'estimate') {
    return (
      <Tooltip title={'Estimate'}>
        <StyledDealMetricValue confidence={confidence}>{children}</StyledDealMetricValue>
      </Tooltip>
    )
  }

  return <StyledDealMetricValue confidence={confidence}>{children}</StyledDealMetricValue>
}

import Typography from '@gain/components/typography'
import { InvestorListItem } from '@gain/rpc/app-model'
import { AssetShareholderShare } from '@gain/rpc/shared-model'
import { formatAssetShareholderShareLabel } from '@gain/utils/asset'
import { styled } from '@mui/material/styles'

import InvestorContentLink from '../../../../features/investor/investor-content-link'

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 36,
})

interface AssetShareholderListItemProps {
  investor: InvestorListItem | null
  share: AssetShareholderShare
}

export default function AssetShareholderListItem({
  investor,
  share,
}: AssetShareholderListItemProps) {
  if (!investor) {
    return null
  }

  return (
    <StyledContainer>
      <InvestorContentLink
        id={investor.id}
        logoFileUrl={investor.logoFileUrl}
        name={investor.name}
      />
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        {formatAssetShareholderShareLabel(share)}
      </Typography>
    </StyledContainer>
  )
}

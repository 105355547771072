import { UserProfile } from '@gain/rpc/app-model'
import { useCallback } from 'react'
import useReactHotjar from 'react-use-hotjar'

export const useHotjar = () => {
  const { initHotjar } = useReactHotjar()

  return useCallback(
    (user: UserProfile | null | undefined) => {
      // Don't load Hotjar for Gain pro users
      if (user && !user.username.endsWith('@gain.pro')) {
        initHotjar(3074072, 6)
      }
    },
    [initHotjar]
  )
}

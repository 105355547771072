import { inputBaseClasses } from '@mui/material/InputBase'
import OutlinedInput, { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { alpha, styled } from '@mui/material/styles'

export interface CustomOutlinedInputProps {
  disableShadow?: boolean
}

/**
 * CustomOutlinedInput is a custom styled input field that is used in the filter
 * bar and dropdown menu search. Ideally we convert this to some MUI variant in
 * the future.
 */
const CustomOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== 'disableShadow',
})<CustomOutlinedInputProps>(({ theme, disableShadow }) => ({
  [`&.${outlinedInputClasses.root}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.75, 1),
    transition: 'none',
    color: theme.palette.text.secondary,
    ...theme.typography.body2,

    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: 2,
      borderColor: 'rgba(32, 85, 245, 0.6)',
    },

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: alpha(theme.palette.text.primary, 0.2),
      borderRadius: 4,
      ...(!disableShadow && { boxShadow: theme.palette.shadow.level1c }),
    },

    [`& .${outlinedInputClasses.input}`]: {
      color: theme.palette.text.primary,
      height: 'auto',
      padding: 0,

      [`&.${inputBaseClasses.inputAdornedStart}`]: {
        marginLeft: theme.spacing(1),
      },

      [`&.${inputBaseClasses.inputAdornedEnd}`]: {
        marginRight: theme.spacing(1),
      },
    },
  },
}))

export default CustomOutlinedInput

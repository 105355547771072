import { AssetListItem } from '@gain/rpc/app-model'
import { scaleSqrt } from 'd3'
import { sampleSkewness } from 'simple-statistics'

import { Axis, AxisConfig } from './axis/axis-config'

// Min and max sizes for the bubbles
export const BUBBLE_MIN_SIZE = 4
export const BUBBLE_MAX_SIZE = 34

/**
 * Represents a bubble on the chart. We create a separate data structure to
 * store the x, y and size values of each bubble, as well as its visibility.
 * In addition, we apply size transformations to the data.
 */
export interface Bubble {
  assetId: number
  x: number
  y: number
  size: number
  isVisible: boolean
}

/**
 * Creates the bubbles for the chart by scaling the x, y and size values of each
 * asset.
 */
export function createBubbles(
  visibleAssets: AssetListItem[],
  assets: AssetListItem[],
  getSize: (asset: AssetListItem) => number | null,
  xAxisConfig: AxisConfig,
  yAxisConfig: AxisConfig
): Bubble[] {
  // Returns true if the item is visible because the group is active or
  // because it's being highlighted.
  const isVisible = (assetId: number) => visibleAssets.some(({ id }) => id === assetId)

  // Pre-process the data to determine the x,y and size values of each bubble.
  const bubbles = assets
    .map((asset) => ({
      assetId: asset.id,
      x: xAxisConfig.calculateAxisValue(asset, Axis.X, yAxisConfig),
      y: yAxisConfig.calculateAxisValue(asset, Axis.Y, xAxisConfig),
      size: getSize(asset),
      isVisible: isVisible(asset.id),
    }))
    .filter((bubble) => bubble.x !== null && bubble.y !== null && bubble.size !== null) as Bubble[]

  // Apply bubble size transformations to the data.
  return transformBubbleSize(bubbles)
}

// If the skewness is below this threshold we apply a transformation to make
// the distribution more even.
const POWER_TRANSFORM_SKEWNESS_THRESHOLD = 0.5

// If the total area of the bubbles exceeds this threshold we apply a power
// transformation to reduce the size of the largest bubbles.
const POWER_TRANSFORM_AREA_THRESHOLD = 2000

/**
 * We scale bubbles using a square-root based scale function. This ensures the
 * bubble area scales linearly with the data value.
 *
 * However, in some cases we apply additional transformations:
 *
 * - If there are more large than small bubbles (i.e. negative skewness), we apply
 *   a power transformation to make the distribution more even.
 * - If the total size of the bubbles becomes too large, we apply a power
 *   transformation to reduce the size of the largest bubbles even more.
 *
 * Both transformations can be applied on the same graph. Despite all this you can
 * still end up with a very dense graph, we might want to experiment with logarithmic
 * power transformations or ranking in the future.
 */
export function transformBubbleSize(bubbles: Bubble[]): Bubble[] {
  let visibleBubbles = bubbles.filter(({ isVisible }) => isVisible)

  // We'll modify the result in subsequent steps
  let result = bubbles

  // Correct negative skew. Skewness requires at least 3 data points but for it to
  // be meaningful we apply it only if there is a sizeable number of bubbles.
  if (visibleBubbles.length >= 20) {
    const sizes = visibleBubbles.map(({ size }) => size ?? 0)
    const skewness = sampleSkewness(sizes)

    // If the skewness is negative it means there are more large than small
    // bubbles. If it's lower than the threshold we apply a transformation to
    // make the distribution more even.
    if (skewness < POWER_TRANSFORM_SKEWNESS_THRESHOLD) {
      // The power transformation works well for most cases when between 2 and 5.
      // The more skewed the data the higher we want the exponent to be.
      const skewnessModifier = Math.abs(Math.max(skewness, 0)) / 2
      const exponent = Math.min(skewnessModifier + 2, 5)

      const transformFn = (value: number) => raiseToPower(value, exponent)
      result = applySizeTransformation(result, transformFn)
    }
  }

  visibleBubbles = result.filter(({ isVisible }) => isVisible)

  // Scale bubbles using a square-root based scale function
  const maxSize = Math.max(...visibleBubbles.map(({ size }) => size ?? 0))
  result = applySizeTransformation(result, bubbleScaleSqrtFn(maxSize))

  // If after the square-root transformation the total size of the bubbles is
  // too large we apply power transformation to reduce the size of the largest
  // bubbles even more.
  const totalSize = result.reduce((acc, { size }) => acc + (size ?? 0), 0)
  if (totalSize > POWER_TRANSFORM_AREA_THRESHOLD) {
    // Determine exponent based on how much the total size exceeds the threshold
    const exponent = Math.min((totalSize / POWER_TRANSFORM_AREA_THRESHOLD) * 2, 5)
    const transformFn = (value: number) => raiseToPower(value, exponent)
    result = applySizeTransformation(result, transformFn)

    // Use the square-root scale function again to normalize the sizes.
    const newMaxSize = Math.max(...result.map(({ size }) => size ?? 0))
    result = applySizeTransformation(result, bubbleScaleSqrtFn(newMaxSize))
  }

  return result
}

/**
 * Returns a square-root scale function. For bubble sizes in scatter plot this
 * is the most common type of scale function as it ensures the bubble area
 * scales linearly with the data value.
 */
function bubbleScaleSqrtFn(maxSize: number) {
  return scaleSqrt()
    .domain([BUBBLE_MIN_SIZE, maxSize])
    .range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE])
    .clamp(true)
}

/**
 * Applies a transformation function to the size of the bubbles.
 */
function applySizeTransformation(
  bubbles: Bubble[],
  transform: (value: number) => number
): Bubble[] {
  return bubbles.map(({ assetId, isVisible, size, ...other }) => {
    let newSize: number

    if (!isVisible || size === 0) {
      // If the bubble is not visible or has no size, we remove it
      newSize = 0
    } else if (size === BUBBLE_MIN_SIZE) {
      // If the bubble is forced to minimize or already at the minimum size, no need to change
      newSize = BUBBLE_MIN_SIZE
    } else {
      // Apply the transformation
      newSize = transform(size)
    }

    return {
      ...other,
      assetId,
      size: newSize,
      isVisible,
    }
  })
}

/**
 * Raises a value to a power. If the value is negative, the result is negated.
 */
function raiseToPower(value: number, exponent: number): number {
  if (value < 0) {
    return -Math.pow(Math.abs(value), exponent)
  }

  return Math.pow(value, exponent)
}

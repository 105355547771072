import { ListItemKey } from '@gain/rpc/list-model'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import {
  FilterConfigMap,
  FilterValueGroup,
  flattenFilters,
  fromFilterModel,
} from '../filter/filter-bar'

export default function useActiveFilterColumns<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filterModel: FilterValueGroup<Item, FilterField>[] | null,
  filterConfigMap: FilterConfigMap<Item, FilterField>,
  tableColumnMap: Array<keyof Item>,
  filterToItemTableColumnMapping?: Partial<Record<keyof Item, Array<keyof Item>>>
) {
  return useMemo(() => {
    // Get filter keys (e.g. "ownership", "tag") from the active filter model
    const filters = fromFilterModel(filterModel, filterConfigMap)
    const filterKeys = uniq(flattenFilters(filters))

    // Return all columns whose "field" map 1-to-1 with a filter key
    const result = filterKeys.filter((filterName) => {
      return tableColumnMap.includes(filterName)
    })

    // Some columns do not map 1-to-1 with a filter key, so we need to manually add them
    if (filterToItemTableColumnMapping) {
      for (const [key, value] of Object.entries(filterToItemTableColumnMapping)) {
        if (filterKeys.includes(key as keyof Item)) {
          result.push(...(value as Array<keyof Item>))
        }
      }
    }

    return result
  }, [filterConfigMap, filterModel, filterToItemTableColumnMapping, tableColumnMap])
}

import { FinancialResultAmount, LegalEntityFinancialResult } from '@gain/rpc/app-model'
import { FinancialResultAmountType, FinancialResultPeriodicityType } from '@gain/rpc/shared-model'
import { FinancialResult } from '@gain/utils/financials'
import { useMemo } from 'react'

function toFinancialResultAmount(
  amount: number | null,
  pctRevenue: number | null = null
): FinancialResultAmount | null {
  if (amount !== null) {
    return {
      amount: amount,
      estimated: false,
      amountType: FinancialResultAmountType.Actual,
      pctRevenue,
    }
  }

  return null
}

function toFinancialResult(result: LegalEntityFinancialResult): FinancialResult {
  return {
    year: result.year,
    periodicity: FinancialResultPeriodicityType.Annual,
    isForecast: false,
    revenue: toFinancialResultAmount(result.revenue),
    revenueYoyGrowthPct: result.revenueYoyGrowthPct,
    grossMargin: toFinancialResultAmount(result.grossMargin, result.grossMarginPctRevenue),
    ebitda: toFinancialResultAmount(result.ebitda, result.ebitdaPctRevenue),
    ebit: toFinancialResultAmount(result.ebit, result.ebitPctRevenue),
    consolidatedNetIncome: null,
    consolidatedNetIncomeYoyGrowthPct: null,
    earningsPerShare: null,
    earningsPerShareYoyGrowthPct: null,
    totalAssets: toFinancialResultAmount(result.totalAssets),
    capex: toFinancialResultAmount(result.capex),
    freeCashFlow: null,
    freeCashFlowYoyGrowthPct: null,
    cashConversionCycle: null,
    debt: toFinancialResultAmount(result.debt),
    cash: toFinancialResultAmount(result.cash),
    netDebt: toFinancialResultAmount(result.netDebt),
    inventory: toFinancialResultAmount(result.inventory),
    receivables: toFinancialResultAmount(result.receivables),
    payables: toFinancialResultAmount(result.payables),
    capital: toFinancialResultAmount(result.capital),
    fte: toFinancialResultAmount(result.fte, result.fteGrowthPct),
    fteGrowthPct: toFinancialResultAmount(result.fteGrowthPct),
    revenueFteRatio: null,
    netDebtEbitdaRatio: null,
    ebitdaMinusCapex: null,
    returnOnAssets: null,
    returnOnEquity: null,
    returnOnCapitalEmployed: null,
  }
}

export function useLegalEntityFinancialResults(results: LegalEntityFinancialResult[] | undefined) {
  return useMemo(() => {
    if (results === undefined) {
      return []
    }

    return results.map(toFinancialResult).sort((resultA, resultB) => resultA.year - resultB.year)
  }, [results])
}

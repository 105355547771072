import { SearchIcon, XIcon } from '@gain/components/icons'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import { alpha, styled } from '@mui/material/styles'
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

const StyledRoot = styled('div')<{ showInput: boolean }>(({ theme, showInput }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: showInput ? theme.palette.divider : 'transparent',
  '&:focus-within': {
    borderColor: theme.palette.primary.light,
    outline: `1px solid ${theme.palette.primary.light}`,
  },
}))

const StyledInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledInputInput = styled('input')({
  border: 'none',
  backgroundColor: 'transparent',
  '&:focus': {
    outline: 'none',
  },
})

const StyledClearButton = styled('button')(({ theme }) => ({
  width: 16,
  height: 16,
  backgroundColor: alpha(theme.palette.text.primary, 0.2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 24,
  color: theme.palette.common.white, // Make the X blend with the background
  border: 'none',
  marginRight: theme.spacing(1),
  cursor: 'pointer',
}))

const StyledXIcon = styled(XIcon)({
  width: 12,
  height: 12,
})

export interface FilterBarSearchApi {
  setOpen: (open: boolean) => void
}

interface SearchInputProps {
  onChange: (value: string | null) => void
  initialValue: string | null
}

const SearchInput = forwardRef(function SearchInput(
  { onChange, initialValue }: SearchInputProps,
  ref: ForwardedRef<FilterBarSearchApi | null | undefined>
) {
  const initialValueRef = useRef(initialValue)
  const [value, setValue] = useState(initialValue)
  const [showInput, setShowInput] = useState(!!initialValue)
  const showClear = useMemo(() => !!value, [value])
  const inputRef = useRef<HTMLInputElement>(null)

  const handleShowInput = useCallback(() => setShowInput((prev) => !prev), [])
  const handleClear = useCallback(() => {
    setValue(null)
    inputRef.current?.focus()
  }, [])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value || null)
  }, [])

  const handleBlur = useCallback(() => {
    if (!value) {
      setShowInput(false)
    }
  }, [value])

  useEffect(() => {
    onChange(value)
  }, [value, onChange])

  useImperativeHandle(ref, () => ({
    setOpen: (open: boolean) => {
      setShowInput(open)
    },
  }))

  useEffect(() => {
    if (initialValueRef.current) {
      setShowInput(true)
    }
  }, [])

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus()
    }
  }, [showInput])

  return (
    <StyledRoot showInput={showInput}>
      <IconButton
        color={'primary'}
        disabled={showInput}
        onClick={handleShowInput}>
        <SearchIcon fontSize={'inherit'} />
      </IconButton>

      <Collapse
        in={showInput}
        orientation={'horizontal'}>
        <StyledInputContainer>
          <StyledInputInput
            ref={inputRef}
            autoComplete={'off'}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value || ''}
          />

          <Fade in={showClear}>
            <StyledClearButton onClick={handleClear}>
              <StyledXIcon />
            </StyledClearButton>
          </Fade>
        </StyledInputContainer>
      </Collapse>
    </StyledRoot>
  )
})

export default SearchInput

import { ExternalLinkIcon } from '@gain/components/icons'
import { InvestorFund } from '@gain/rpc/app-model'
import Button from '@mui/material/Button'
import React from 'react'

import { ColumnConfig } from '../../../common/virtual-table'
import FinancialValue from '../../financial/financial-value'

export const investorFundTableColumns: ColumnConfig<InvestorFund>[] = [
  {
    field: 'name',
    headerName: 'Fund',
    align: 'left',
    width: 264,
    flex: 1,
  },
  {
    field: 'fundSizeEur',
    headerName: 'Size',
    align: 'right',
    width: 112,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'vintageDate',
    headerName: 'Vintage date',
    align: 'right',
    width: 130,
    valueFormatter: ({ value }) => value?.year || '-',
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 114,
    sortable: false,
    renderCell: ({ value }) => (
      <Button
        color={'primary'}
        component={'a'}
        endIcon={<ExternalLinkIcon />}
        href={value}
        target={'_blank'}
        variant={'text'}>
        View
      </Button>
    ),
  },
]

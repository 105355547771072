import { PropsWithChildren } from 'react'

import useZendeskApi from './use-zendesk-api'
import { ZendeskContext } from './zendesk-context'

export function ZendeskProvider({ children }: PropsWithChildren) {
  const api = useZendeskApi()

  return <ZendeskContext.Provider value={api}>{children}</ZendeskContext.Provider>
}

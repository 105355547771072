import Typography, { TypographyProps } from '@gain/components/typography'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { ReactElement } from 'react'

export const keyValueListItemClasses = generateUtilityClasses('KeyValueListItem', [
  'root',
  'key',
  'value',
])

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 36,
  minWidth: 0,
  [`& .${keyValueListItemClasses.key}`]: {
    marginRight: theme.spacing(2),
    whiteSpace: 'noWrap',
  },
  [`& .${keyValueListItemClasses.value}`]: {
    marginLeft: theme.spacing(1),
  },
}))

const StyledValue = styled(Typography)(() => ({
  minWidth: 0,
  overflow: 'hidden',
}))

const StyledDiv = styled('div')(() => ({
  minWidth: 0,
}))

export interface KeyValueListItemProps {
  label: string | ReactElement
  value: string | number | ReactElement
  className?: string
  keyTypographyProps?: TypographyProps
  valueTypographyProps?: TypographyProps
}

const defaultKeyTypographyProps: TypographyProps = {
  variant: 'body2',
  color: 'text.primary',
  noWrap: false,
}

const defaultValueTypographyProps: TypographyProps = {
  variant: 'body2',
  color: 'text.primary',
  noWrap: true,
}

const PRIMITIVES = ['string', 'number']

export default function KeyValueListItem({
  className,
  label,
  value,
  keyTypographyProps,
  valueTypographyProps,
}: KeyValueListItemProps) {
  return (
    <StyledContainer className={clsx(keyValueListItemClasses.root, className)}>
      <Typography
        {...defaultKeyTypographyProps}
        {...keyTypographyProps}
        className={keyValueListItemClasses.key}>
        {label}
      </Typography>
      {PRIMITIVES.includes(typeof value) ? (
        <StyledValue
          {...defaultValueTypographyProps}
          {...valueTypographyProps}
          className={keyValueListItemClasses.value}>
          {value}
        </StyledValue>
      ) : (
        <StyledDiv>{value}</StyledDiv>
      )}
    </StyledContainer>
  )
}

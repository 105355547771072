import { DealStatus } from '@gain/rpc/shared-model'
import { isTruthy } from '@gain/utils/common'
import { formatDealStatus, getDealReasonColor, parseDealStatus } from '@gain/utils/deal'
import Chip, { ChipProps } from '@mui/material/Chip'

export type ChipDealStatusProps = Omit<ChipProps, 'label' | 'color'> & {
  dealStatus?: DealStatus | string | null
}

export default function ChipDealStatus({
  dealStatus,
  size = 'small',
  ...chipProps
}: ChipDealStatusProps) {
  const parsed = parseDealStatus(dealStatus)

  if (!isTruthy(parsed)) {
    return null
  }

  const color = getDealReasonColor(parsed)

  return (
    <Chip
      color={color}
      label={formatDealStatus(parsed)}
      size={size}
      {...chipProps}
    />
  )
}

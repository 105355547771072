import { useJsApiLoader } from '@react-google-maps/api'

export { Circle, GoogleMap, Polygon } from '@react-google-maps/api'

export const useGoogleMapsApiLoader = () =>
  useJsApiLoader({
    // API key is restricted to only allow gain.pro domains and the maps API.
    // This key is safe to be exposed in the client side code.
    googleMapsApiKey: 'AIzaSyBF9aGLklDfsehBOZTyxIJtT3pnlPIM-h8',
  })

import { Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export const MuiListItemIcon = (theme: Theme): Components['MuiListItemIcon'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      '&&&': {
        minWidth: 32,
      },
      color: theme.palette.grey['600'],
      fontSize: 16,
      [`&& .${svgIconClasses.root}`]: {
        fontSize: 'inherit',
      },
    },
  },
})

import { SECTORS } from './sectors'
import { SUBSECTORS } from './subsectors'

export function formatSubsector(subsector?: string | null) {
  if (!subsector) {
    return null
  }

  return SUBSECTORS.find((item) => item.name === subsector)?.title || null
}

export function formatSector(sector: string | null) {
  if (sector === null) {
    return null
  }

  return SECTORS.find((item) => item.name === sector)?.title
}

import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

export interface MobilePageContainerProps {
  children?: ReactNode
  className?: string
}

export default function MobilePageContainer({ children, className }: MobilePageContainerProps) {
  return <StyledRoot className={className}>{children}</StyledRoot>
}

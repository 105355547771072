import { useAssetListItem, useBookmarksListByAssetId } from '@gain/api/app/hooks'
import { BookmarkFilledIcon, BookmarkIcon } from '@gain/components/icons'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'

import ResourceOfflinePage from '../../../common/resource-not-available'
import { BookmarksListAddAssetMenu } from '../../../features/bookmarks-list/bookmarks-list-add-assets/bookmarks-list-add-asset-menu'
import { useTrackEvent } from '../../../features/google-analytics'
import AssetDownloads from '../asset-header/asset-downloads-dialog/asset-downloads'

export interface AssetOfflineProps {
  id: number
  title: string | null
}

export default function AssetOffline({ id, title }: AssetOfflineProps) {
  const asset = useAssetListItem(id)
  const [assetListMenuAnchor, setAssetListMenuAnchor] = useState<HTMLButtonElement | null>(null)
  const bookmarksListByAssetId = useBookmarksListByAssetId(id)

  const trackEvent = useTrackEvent()

  useEffect(() => {
    if (!asset.data) {
      return
    }
    trackEvent(
      'Resource offline',
      'Asset detail page',
      asset.data.name || asset.data.id.toString(10)
    )
  }, [trackEvent, asset.data])

  const isAlreadyBookmarked = bookmarksListByAssetId.length > 0

  if (!asset.data) {
    return null
  }

  return (
    <ResourceOfflinePage
      actions={
        <>
          <BookmarksListAddAssetMenu
            anchorEl={assetListMenuAnchor}
            assetIds={[asset.data.id]}
            onClose={() => setAssetListMenuAnchor(null)}
            open={Boolean(assetListMenuAnchor)}
          />

          <Stack
            alignItems={'center'}
            gap={2}
            width={'100%'}>
            {asset.data.previousFactsheetFileId && (
              <AssetDownloads
                asset={asset.data}
                excelFileId={asset.data.previousExcelFileId}
                preview={false}
                withDeals={false}
              />
            )}

            <div>
              <Button
                color={'primary'}
                onClick={(event) => setAssetListMenuAnchor(event.currentTarget)}
                startIcon={isAlreadyBookmarked ? <BookmarkFilledIcon /> : <BookmarkIcon />}
                variant={'contained'}>
                {isAlreadyBookmarked ? 'Bookmarked' : 'Add to bookmarks'}
              </Button>
            </div>
          </Stack>
        </>
      }
      avatarSrc={asset.data.logoFileUrl}
      message={
        asset.data.previousFactsheetFileId ? (
          <>
            We found new data points and are processing them. In the meantime you can download the
            latest information we have available. Please add <strong>{asset.data.name}</strong> to
            one of your lists to read the updated profile when it is published.
          </>
        ) : (
          <>
            We found new data points and are processing them. Please add{' '}
            <strong>{asset.data.name}</strong> to one of your lists to read it later.
          </>
        )
      }
      subtitle={asset.data.description}
      title={asset.data.name || title || '-'}
    />
  )
}

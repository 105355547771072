import { useRatingColor } from '@gain/utils/investment-criteria'
import { default as MuiRating, RatingProps } from '@mui/material/Rating'
import React, { useMemo } from 'react'

export type { RatingProps }

export default function Rating({
  value,
  readOnly = true,
  precision = 0.5,
  style,
  ...props
}: RatingProps) {
  const color = useRatingColor(value)
  const styleWithColor = useMemo(() => ({ ...style, color }), [style, color])

  return (
    <MuiRating
      precision={precision}
      readOnly={readOnly}
      style={styleWithColor}
      value={value}
      {...props}
    />
  )
}

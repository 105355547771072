import { AssetListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { range } from 'lodash'

import { Axis, AxisConfig } from './axis-config'

// These bucket boundaries are for growth % axes, e.g. gross margin % and ebitda %.
// These bucket sizes are not uniform to avoid having too many datapoints in the
// middle of the chart.
//
// There are three options; the one that fits the dataset the best is selected.
export const growthPctBucketOptions = [
  [-30, -10, -5, 0, 2, 5, 10, 15, 25, 50, 100],
  [-50, -20, -10, -5, 0, 1, 2, 3, 4, 5, 7, 10, 12, 15, 20, 25, 30, 40, 50, 75, 100, 150],
  [
    -100, -50, -40, -30, -20, -15, -10, -5, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16,
    18, 20, 22, 24, 26, 28, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250,
  ],
]

// The bucket boundaries are for financial % axes. While they are fairly uniform,
// we want to display them as bucket ranges to make them easier to read and
// consistent with other graphs.
//
// There are three options; the one that fits the dataset the best is selected.
export const financialPctBucketOptions = [
  range(-40, 101, 20), // Every 20% between -40 and 100
  range(-100, 101, 10), // Every 10% between -100 and 100
  range(-100, 101, 5), // Every 5% between -100 and 100
]

// Each bucket translates to a range of 10 on a linear scale.
const BUCKET_SCALE_SIZE = 10

// The maximum percentage of outliers in the data set. These outliers will appear
// at the edge of the chart.
const BUCKET_OUTLIERS_PCT = 0.03

export interface AxisBucketConfig extends AxisConfig {
  buckets: number[][]
  assets: AssetListItem[]
}

/**
 * Configuration for a bucket axis.
 *
 * A bucket axis plots data points on a completely arbitrary scale. This is not
 * supported by ECharts, so we plot a basic "value" axis and translate the datapoints
 * to this value scale.
 *
 * You specify the bucket ranges using a bucket configuration, which is an array
 * of bucket boundary values. Two boundaries represent one bucket. For example,
 * a configuration of three boundaries [0, 2, 5] defines two buckets: [0-2] and [2-5].
 *
 * Each bucket has the same visual size and is mapped to a linear scale of 0-10.
 * For instance, a bucket configuration of [0, 2, 5] is mapped to a linear scale
 * as follows:
 *
 * - [0,2] => [0,10]
 * - [2,5] => [10,20]
 *
 * The total axis is now [0,20] and each bucket is 10 units wide.
 *
 * After this remapping, plotting a value becomes straightforward. A data point
 * of 1 falls in the first bucket [0,2], which is remapped to [0,10]. On the value
 * scale, it's shown at position 5 (i.e., 50% in the range of 0-10).
 *
 * Buckets at the far left or right are hidden if they contain zero data points.
 * This could result in only 1, 2, or 3 buckets being shown, which may render
 * the axis less useful. To mitigate this, you can specify up to three different
 * bucket configurations, each more granular than the previous one. The most
 * suitable bucket configuration for the data is then automatically selected.
 */
export function axisBucketConfig({
  id,
  buckets,
  getValue,
  assets,
  ...props
}: Pick<
  AxisBucketConfig,
  'id' | 'icon' | 'label' | 'buckets' | 'getValue' | 'formatTooltip' | 'assets'
>): AxisConfig {
  const values = assets.map(getValue).filter((v) => v !== null) as number[]
  const validBuckets = getValidBuckets(values, buckets)
  const [scaleMin, scaleMax, nrOfBuckets] = calculateBucketScale(validBuckets)

  return {
    id,
    calculateAxisValue: (asset: AssetListItem) =>
      convertValueToBucketScale(getValue(asset), validBuckets, scaleMin, scaleMax, nrOfBuckets),
    generateLabels: (axis: Axis) => generateBucketLabelsOption(axis, validBuckets),
    scaleType: 'value',
    scaleMin,
    scaleMax,
    getValue,
    ...props,
  }
}

/**
 * From the provided bucket ranges (max 3) returns a bucket range that best fits
 * the data.
 *
 * If the data matches only 2 or 3 buckets in the first range, the second range
 * is used instead.
 *
 * If the data matches only 1 bucket from the first range, the third range is used.
 */
export function getValidBuckets(values: number[], bucketRanges: number[][]) {
  const validBoundaries = getBucketsWithData(values, bucketRanges[0], BUCKET_OUTLIERS_PCT)

  if (bucketRanges.length === 3 && validBoundaries.length <= 2) {
    // 2 boundaries represents 1 bucket
    return getBucketsWithData(values, bucketRanges[2], BUCKET_OUTLIERS_PCT)
  }

  if (bucketRanges.length > 1 && validBoundaries.length <= 4) {
    return getBucketsWithData(values, bucketRanges[1], BUCKET_OUTLIERS_PCT)
  }

  return validBoundaries
}

/**
 * Returns the first bucket that has sufficient data and includes all
 * subsequent buckets up to the last one that has sufficient data.
 */
export function getBucketsWithData(
  values: number[],
  buckets: number[],
  outliersPct: number
): number[] {
  if (values.length === 0) {
    return buckets // Just a default value that looks natural
  }

  const result: number[] = []

  for (let i = 0; i < buckets.length; i++) {
    // If no buckets have been added yet, check if the first bucket has enough values
    if (result.length === 0) {
      // Percentage of values below the current bucket
      const below = values.filter((v) => v < buckets[i]).length / values.length

      // Skip the first buckets that are mostly outliers
      if (below < outliersPct) {
        continue
      }

      // If this is not the first bucket, add the previous bucket
      if (i > 0) {
        result.push(buckets[i - 1])
      }
    }

    // Add the current bucket
    result.push(buckets[i])

    // Percentage of values above the current bucket
    const above = values.filter((v) => v > buckets[i]).length / values.length

    // Stop adding buckets once the upper threshold is reached
    if (above < outliersPct) {
      break
    }
  }

  return result
}

/**
 * Calculates the scale min/max based on the provided bucket boundaries.
 *
 * Each bucket translates to a range of BUCKET_SCALE_SIZE on a linear scale.
 * If buckets are negative, the scale will be negative too to align the datapoint
 * left or below the zero point of the x/y-axis.
 *
 * For example:
 * [-30, -10, 0,  2,  5, 50] => { min: -20, max: 30, nrOfBuckets: 5}
 */
export function calculateBucketScale(
  buckets: number[]
): [scaleMin: number, scaleMax: number, nrOfBuckets: number] {
  const nrOfBuckets = buckets.length - 1
  let nrOfNegativeBuckets = buckets.filter((x) => x < 0).length
  let nrOfPositiveBuckets = nrOfBuckets - nrOfNegativeBuckets

  // If the last boundary is still in the negative range, subtract one since the
  // last boundary is open-ended.
  if (nrOfPositiveBuckets < 0) {
    nrOfNegativeBuckets -= 1
    nrOfPositiveBuckets = 0
  }

  // Calculate min and max values of the buckets
  let scaleMin = nrOfNegativeBuckets * -BUCKET_SCALE_SIZE
  if (Object.is(scaleMin, -0)) {
    scaleMin = 0
  }
  const scaleMax = nrOfPositiveBuckets * BUCKET_SCALE_SIZE

  return [scaleMin, scaleMax, nrOfBuckets]
}

/**
 * The bucket boundaries can be of arbitrary size and are not uniform. This
 * function maps a data value to a scale value based on the bucket boundaries.
 *
 * See tests for examples.
 */
export function convertValueToBucketScale(
  value: number | null,
  buckets: number[],
  scaleMin: number,
  scaleMax: number,
  nrOfBuckets: number
): number | null {
  if (!isDefined(value)) {
    return null
  }

  // The datapoint is out of bounds, return the upper or lower bound
  if (value < buckets[0]) {
    return scaleMin
  }
  if (value > buckets[buckets.length - 1]) {
    return scaleMax
  }

  // Find the bucket index for the given datapoint
  let bucketIndex = nrOfBuckets - 1 // Assume the last bucket since it is open-ended
  for (let i = 0; i < nrOfBuckets - 1; i++) {
    if (value < buckets[i + 1]) {
      bucketIndex = i
      break
    }
  }

  // Calculate the minimum scale value of the bucket this datapoint falls into
  const scaleBucketStart = scaleMin + bucketIndex * BUCKET_SCALE_SIZE

  // Calculate how much the datapoint has progressed into the bucket. We treat
  // the bucket range as a linear scale from 0 to BUCKET_SCALE_SIZE.
  const bucketMin = buckets[bucketIndex]
  const bucketMax = buckets[bucketIndex + 1]
  const bucketRange = bucketMax - bucketMin
  const scaleProgressInBucket = ((value - bucketMin) / bucketRange) * BUCKET_SCALE_SIZE

  // Return the scale value
  return scaleBucketStart + scaleProgressInBucket
}

/**
 * Generates EChart label configuration for a bucket axis.
 *
 * Because bucket ranges are completely arbitrary, we use a separate axis
 * with type "category" to display the bucket labels. The category's "data" is
 * generated based on the bucket boundaries.
 *
 * In essence, we're abusing the category-type to display the bucket labels.
 */
export function generateBucketLabelsOption(axis: Axis, buckets: number[]): CartesianAxisOption {
  return {
    splitLine: { show: true },
    axisTick: { show: false },
    axisLine: { show: false },
    type: 'category',
    data: generateBucketLabels(buckets),
    position: axis === Axis.X ? 'bottom' : 'left',
  }
}

/**
 * Generates labels for the buckets based on the bucket boundaries. It yields the
 * following label styles:
 *
 * - "< -20"
 * - "-20 to -10"
 * - "-10 - 0"
 * - "0 – 2"
 * - "> 2"
 *
 * We don't add a dash symbol for negative ranges to avoid confusion with
 * negative values, e.g. "-20 to -10" instead of "-20 - -10".
 */
export function generateBucketLabels(buckets: number[]): string[] {
  const labels: string[] = []

  for (let i = 1; i < buckets.length; i++) {
    if (i === 1) {
      labels.push(`< ${buckets[i]}`)
    } else if (i === buckets.length - 1) {
      labels.push(`> ${buckets[i - 1]}`)
    } else if (buckets[i - 1] < 0 && buckets[i] <= 0) {
      labels.push(`${buckets[i - 1]} to ${buckets[i]}`)
    } else {
      labels.push(`${buckets[i - 1]} – ${buckets[i]}`)
    }
  }

  return labels
}

import { useDealList } from '@gain/api/app/hooks'
import { DealListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { compareDealListItemByDateDesc } from '@gain/utils/deal'
import { useMemo } from 'react'

const dealSort: ListSort<DealListItem>[] = [
  listSort('announcementDateYear', 'desc'),
  listSort('announcementDateMonth', 'desc'),
]

export function useAssetDealHistoryDeals(assetId: number, limit?: number) {
  return useDealList({
    filter: [listFilter('linkedAssetId', '=', assetId)],
    sort: dealSort,
    limit,
  })
}

export function useAcquisitionsAndDivestmentsDeals(assetId: number, limit?: number) {
  const sellerDeals = useDealList({
    filter: [listFilter<DealListItem>('sellerAssetIds', '=', assetId)],
    sort: dealSort,
    limit,
  })
  const buyerDeals = useDealList({
    filter: [listFilter<DealListItem>('buyerAssetIds', '=', assetId)],
    sort: dealSort,
    limit,
  })
  return useMemo(() => {
    return {
      loading: sellerDeals.loading || buyerDeals.loading,
      error: sellerDeals.error || buyerDeals.error,
      data: [...sellerDeals.data.items, ...buyerDeals.data.items].sort(
        compareDealListItemByDateDesc
      ),
    }
  }, [
    sellerDeals.loading,
    sellerDeals.error,
    sellerDeals.data.items,
    buyerDeals.loading,
    buyerDeals.error,
    buyerDeals.data.items,
  ])
}

import { AssetListItem } from '@gain/rpc/app-model'
import { formatThousands } from '@gain/utils/number'
import { RenderCellParams, useTableRowHoverContext } from '@gain/utils/table'
import React from 'react'

import Sparkline, { mapSparklineValues } from '../../../common/sparkline'
import AssetFte from '../asset-fte'

export function AssetListItemTableCellFte({ row }: RenderCellParams<AssetListItem, 'fte'>) {
  const hover = useTableRowHoverContext()

  if (hover) {
    const ftes = mapSparklineValues(row.fteYears, row.fteResults)
    if (ftes && row.fte) {
      return (
        <Sparkline
          label={formatThousands(row.fte)}
          values={ftes}
        />
      )
    }
  }

  return (
    <AssetFte
      fte={row.fte}
      range={row.fteRange}
      year={row.fteYear}
    />
  )
}

import { InvestorFund } from '@gain/rpc/app-model'

type InvestorFundExplanationKey = keyof Pick<
  InvestorFund,
  'netIrr' | 'grossIrr' | 'twr' | 'tvpi' | 'moic' | 'dpi' | 'rvpi'
>

export const investorFundExplanations: Record<InvestorFundExplanationKey, string> = {
  netIrr:
    '"Net internal rate of return". IRR net of management fees and carried interest of the fund.',
  grossIrr:
    '"Gross internal rate of return". IRR before any management fees and carried interest of the fund.',
  twr: '"Time-weighted rate of return". TWR is often used to compare investment manager returns because it eliminates the distorting effects on growth rates created by cash flows.',
  tvpi: '"Total value to paid-in". TVPI represents the total value (both realised and unrealised value) of a fund relative to the total paid in capital.',
  moic: '"Multiple on invested capital". MOIC represents the total value (both realised and unrealised value) of a fund relative to the committed capital.',
  dpi: '"Distributed to paid-in capital". The ratio of the total value of all distributed capital to date, relative to the capital that has been called to date.',
  rvpi: '"Residual value to paid-in capital". The ratio of the total value of the market value of all remaining investments in the fund, relative to the capital that has been called to date.',
}

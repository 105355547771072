import { createMethod } from '@gain/jsonrpc'
import { RpcMethodMap as AppRpcMethodMap } from '@gain/rpc/app-model'
import { RpcClient } from '@gain/rpc/utils'
import { useCallback } from 'react'
import { useSWRConfig } from 'swr'

// RequestMap defines a type that abstracts over both the App
// and CMS RpcMethodMap
type RequestMap = Record<string, { params: any; result: any }>

export type RpcClientParams<
  MethodMap extends RequestMap = AppRpcMethodMap,
  Method extends Extract<keyof MethodMap, string> = Extract<keyof MethodMap, string>,
  Params extends MethodMap[Method]['params'] = MethodMap[Method]['params']
> = {
  method: Method
  endpoint?: string
  signal?: AbortSignal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & ([Params] extends [never] ? { params?: any } : { params?: Params })

export type RpcClientResult<
  MethodMap extends RequestMap = AppRpcMethodMap,
  Method extends Extract<keyof MethodMap, string> = Extract<keyof MethodMap, string>
> = Promise<MethodMap[Method]['result']>

export type RpcClientFetcher<MethodMap extends RequestMap = AppRpcMethodMap> = <
  Method extends Extract<keyof MethodMap, string> = Extract<keyof MethodMap, string>,
  Params extends MethodMap[Method]['params'] = MethodMap[Method]['params']
>(
  params: RpcClientParams<MethodMap, Method, Params>
) => RpcClientResult<MethodMap, Method>

export default function useRpcClient<MethodMap extends RequestMap = AppRpcMethodMap>(
  rpcClient?: RpcClient
): RpcClientFetcher<MethodMap> {
  const config = useSWRConfig()

  // Take the provided RPC client, or get from SWR config
  if (rpcClient == null && config?.fetcher == null) {
    throw new Error('Fetcher not provided')
  }

  return useCallback(
    <
      Method extends Extract<keyof MethodMap, string> = Extract<keyof MethodMap, string>,
      Params extends MethodMap[Method]['params'] = MethodMap[Method]['params'],
      Response extends MethodMap[Method]['result'] = MethodMap[Method]['result']
    >(
      params: RpcClientParams<MethodMap, Method, Params>
    ): RpcClientResult<MethodMap, Method> => {
      // Use RPC client directly if available
      if (rpcClient != null) {
        return rpcClient.rpc<Response>(createMethod(params.method, params.params), {
          signal: params.signal,
        })
      }

      // Fall back to the configured fetcher for SWR
      const fetcher = config.fetcher as RpcClientFetcher<MethodMap>
      return fetcher<Method, Params>(params)
    },
    [rpcClient, config.fetcher]
  )
}

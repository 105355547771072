import OverflowContainer from '@gain/components/overflow-container'
import { ListItemKey } from '@gain/rpc/list-model'
import { RowType } from '@gain/utils/table'
import Chip from '@mui/material/Chip'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  addIncludeToAutocompleteValue,
  FilterAutocompleteValue,
  useFilterContext,
} from '../../../features/filter/filter-bar'

interface Tag {
  name: string
  id: number
}

export interface TagsTableCellProps<Row extends RowType> {
  row: Row
  valueField: ListItemKey<Row>
  idField: ListItemKey<Row>
  onTagClick?: (tagId: number) => void
}

export function TagsVirtualTableCell<Row extends RowType>({
  row,
  valueField,
  idField,
  onTagClick,
}: TagsTableCellProps<Row>) {
  const filterApi = useFilterContext<Row, ListItemKey<Row>>()
  const [activeTagIds, setActiveTagIds] = useState<number[]>([])

  const tags = useMemo(() => {
    return row[valueField].map(
      (tag: string, index: number): Tag => ({
        name: tag,
        id: row[idField][index],
      })
    )
  }, [idField, row, valueField])

  const handleOnTagClick = useCallback(
    (tagId: number) => (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()

      // Use callback function when provided
      if (onTagClick) {
        onTagClick(tagId)
        return
      }

      // Fallback to updating the filter API when available
      if (filterApi) {
        filterApi.addOrUpdate(
          idField,
          (value: FilterAutocompleteValue): FilterAutocompleteValue => {
            return addIncludeToAutocompleteValue(value, tagId)
          }
        )
      }
    },
    [filterApi, idField, onTagClick]
  )

  useEffect(() => {
    if (!filterApi) {
      return
    }

    const unsubscribe = filterApi.subscribe((model) => {
      setActiveTagIds((prev) => {
        const active = tags
          .filter((tag: Tag) =>
            model.some((group) =>
              group.value.some((item) => {
                if (item.filterId === idField && item.value) {
                  const value: FilterAutocompleteValue = item.value
                  return value?.include.value.includes(tag.id)
                }

                return false
              })
            )
          )
          .map((tag: Tag) => tag.id)

        if (JSON.stringify(active) === JSON.stringify(prev)) {
          return prev
        }

        return active
      })
    })

    return () => unsubscribe()
  }, [filterApi, idField, tags])

  return (
    <OverflowContainer
      chipSize={'medium'}
      lineHeight={24}
      maxLines={1}
      disableObserver>
      {tags.map((tag: Tag) => (
        <Chip
          key={tag.id}
          color={activeTagIds.includes(tag.id) ? 'info' : undefined}
          label={tag.name}
          onClick={handleOnTagClick(tag.id)}
        />
      ))}
    </OverflowContainer>
  )
}

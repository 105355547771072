import Chip, { chipClasses } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

const FilterBarChip = styled(Chip)(({ theme }) => ({
  minWidth: 0,

  [`& .${chipClasses.label}`]: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
    gap: theme.spacing(0.5),
    minWidth: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...theme.typography.overline,
  },
}))

export default FilterBarChip

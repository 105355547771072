import { styled } from '@mui/material/styles'

import cross from './x.svg'

export const ChipDeleteIcon = styled('span')({
  backgroundColor: 'currentColor',
  width: '1em',
  height: '1em',
  borderRadius: '1em',
  '&:before': {
    display: 'block',
    width: '1em',
    height: '1em',
    backgroundColor: '#FFFFFF',
    mask: `url(${cross}) no-repeat 0 0/100% 100%`,
    content: '""',
  },
})

export function compareNumberDesc(a: number | null | undefined, b: number | null | undefined) {
  if (typeof a !== 'number' && typeof b === 'number') {
    return 1
  } else if (typeof a === 'number' && typeof b !== 'number') {
    return -1
  } else if (typeof a === 'number' && typeof b === 'number') {
    return b - a
  }
  return 0
}

export function compareNumberAsc(a: number | null | undefined, b: number | null | undefined) {
  return compareNumberDesc(b, a)
}

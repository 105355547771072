import Tooltip from '@gain/components/tooltip'
import { DealStatus } from '@gain/rpc/shared-model'
import {
  compareDealPartyByShareAndOrderDesc,
  DealParty,
  DealPartyType,
  formatDealShareLabel,
} from '@gain/utils/deal'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

import ContentLinkDealParty from '../../../../features/deal-party/deal-party-content-link'
import DealPartiesTooltipTitle from './deal-parties-tooltip-title'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(1),
  minWidth: 0,
  alignItems: 'center',
}))

export interface TableCellDealPartiesProps {
  parties: DealParty[]
  dealStatus: DealStatus | null
  type: DealPartyType
}

export default function TableCellDealParties({
  parties,
  dealStatus,
  type,
}: TableCellDealPartiesProps) {
  if (parties.length === 0) {
    return <>-</>
  }

  const [firstParty, ...otherParties] = parties.slice().sort(compareDealPartyByShareAndOrderDesc)
  const firstPartyShareLabel = formatDealShareLabel(
    firstParty.share?.value,
    firstParty.sharePct?.value,
    dealStatus,
    type,
    {
      format: 'short',
    }
  )

  return (
    <StyledRoot>
      <ContentLinkDealParty
        dealStatus={dealStatus}
        party={firstParty}
        type={type}
        disableDescription
      />
      {firstPartyShareLabel && (
        <Chip
          label={firstPartyShareLabel}
          size={'small'}
        />
      )}
      {otherParties.length > 0 && (
        <Tooltip
          title={
            <DealPartiesTooltipTitle
              dealStatus={dealStatus}
              parties={otherParties}
              type={type}
            />
          }
          variant={'preview'}>
          <Chip
            label={`+${otherParties.length}`}
            size={'small'}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </StyledRoot>
  )
}

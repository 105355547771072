import Debug from 'debug'
import { Middleware, SWRHook } from 'swr'

const debug = Debug('gain-pro:swr')

export function swrLogMiddleware(useSWRNext: SWRHook): ReturnType<Middleware> {
  return (key, fetcher, config) => {
    debug(key, config)
    return useSWRNext(key, fetcher, config)
  }
}

import { useAssetList } from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListArgs } from '@gain/rpc/list-model'
import { listFilter } from '@gain/rpc/utils'
import { ComponentType, useMemo } from 'react'

import { FilterSimilarToValue } from '../filter-config/filter-config-model'
import { FilterValueLabelValueProps } from './filter-value-label-value'

export interface FilterValueAssetLabelValueProps {
  label: string
  value: FilterSimilarToValue
  ValueLabelComponent: ComponentType<FilterValueLabelValueProps>
}

export default function FilterValueAssetLabelValue({
  label,
  value,
  ValueLabelComponent,
}: FilterValueAssetLabelValueProps) {
  const assets = useAssetList(() => {
    if (value == null) {
      return null
    }

    return {
      filter: [listFilter<AssetListItem>('id', '=', value)],
    } as Partial<ListArgs<AssetListItem>>
  })

  const formattedValue = useMemo(() => {
    if (value == null) {
      return ''
    } else {
      return assets.data.items.map((asset) => `${asset.name} (${asset.region})`).join('')
    }
  }, [assets.data.items, value])

  return (
    <ValueLabelComponent
      label={label}
      value={formattedValue}
    />
  )
}

import { ShuffleIcon } from '@gain/components/icons'
import { DealListItem } from '@gain/rpc/app-model'
import { listFilter, listSort, serializeListSort } from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'
import { formatMonthYear } from '@gain/utils/date'
import { useIsXs } from '@gain/utils/responsive'
import { useOpenLink } from '@gain/utils/router'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemSecondaryValue,
} from '../../../common/list-mobile'
import Logo from '../../../common/logo'
import { useMeasureMinWidth } from '../../../common/responsive'
import { generateDealPagePath } from '../../../routes/utils'
import {
  DEAL_DEFAULT_FILTERS,
  DEAL_FILTER_MAP,
  DEAL_FILTER_MENU,
  DealFilterField,
} from '../../deal/deal-filter-bar'
import { ExportListButton } from '../../export/export-button'
import ListView, { ListViewApi, ListViewProps } from '../core'
import ListViewFooter from '../list-view-footer'
import DealNameMobileListItemText from './deal-table/deal-name-mobile-list-item-text.component'
import { dealTableColumns } from './deal-table/deal-table-columns'

export const LIST_VIEW_DEAL_DEFAULT_SORT = [
  listSort('announcementDate', 'desc'),
  listSort('publicationDate', 'desc'),
]

export default function ListViewDeal({
  defaultFilter,
  apiRef,
}: Pick<
  ListViewProps<'data.listDeals', DealListItem, DealListItem, DealFilterField>,
  'defaultFilter' | 'apiRef'
>) {
  const [listFooterRef, isLargeFooter] = useMeasureMinWidth<HTMLDivElement>(600)
  const openLink = useOpenLink()
  const isXs = useIsXs()

  const renderPageActions = useCallback(
    (api: ListViewApi<'data.listDeals', DealListItem, DealListItem, DealFilterField>) =>
      [
        !isXs && (
          <ExportListButton
            method={'data.exportDeals'}
            params={{
              columns: [],
              filename: 'Deal export - Gain.pro.xlsx',
              filter: api.queryParams.filter,
              sort: api.sort.map(serializeListSort),
            }}
            tooltipMode={'always'}
            variant={'text'}
          />
        ),
      ].filter(isTruthy),
    [isXs]
  )

  const handleRenderBulkActions = useCallback(
    (api: ListViewApi<'data.listDeals', DealListItem, DealListItem, DealFilterField>) => (
      <ListViewFooter
        ref={listFooterRef}
        api={api}
        exportMethod={'data.exportDeals'}
        itemNamePlural={'deals'}
        itemNameSingular={'deals'}>
        <ExportListButton
          color={'primary'}
          method={'data.exportDeals'}
          params={{
            columns: [],
            filename: 'Deal export - Gain.pro.xlsx',
            filter: [
              listFilter<DealListItem>(
                'id',
                '=',
                api.selectedRows.map(({ id }) => id)
              ),
            ],
            sort: api.sort.map(serializeListSort),
          }}
          tooltipMode={'never'}
          variant={isLargeFooter ? 'contained' : 'icon-contained'}
        />
      </ListViewFooter>
    ),
    [listFooterRef, isLargeFooter]
  )

  return (
    <ListView
      addFilterMenu={DEAL_FILTER_MENU}
      apiRef={apiRef}
      defaultFilter={defaultFilter}
      defaultFilterModel={DEAL_DEFAULT_FILTERS}
      defaultSort={LIST_VIEW_DEAL_DEFAULT_SORT}
      filterBarSearchLabel={'Target name'}
      filterBarSearchPlaceholder={'E.g. Visma'}
      filterConfigMap={DEAL_FILTER_MAP}
      inline={isXs}
      method={'data.listDeals'}
      renderBulkActions={handleRenderBulkActions}
      renderPageActions={renderPageActions}
      sm={{
        variant: 'virtual-table',
        VariantProps: {
          columns: dealTableColumns,
          onRowClick({ row }, event) {
            openLink(
              generateDealPagePath({
                id: row.id,
                name: row.asset || undefined,
              }),
              event
            )
          },
        },
      }}
      xs={{
        variant: 'list',
        VariantProps: {
          headerProps: {
            title: 'Name',
            secondaryTitle: 'Date',
          },
          renderListItem: (item) => (
            <MobileListItem
              key={item.id}
              disableDivider>
              <MobileListItemButton
                as={Link}
                {...{
                  to: generateDealPagePath({
                    id: item.id,
                    name: item.asset || undefined,
                  }),
                }}>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<ShuffleIcon />}
                    region={item.region}
                    size={40}
                    src={item.assetLogoFileUrl}
                  />
                </MobileListItemIcon>
                <DealNameMobileListItemText deal={item} />
                <MobileListItemSecondaryValue>
                  {formatMonthYear(item.announcementDateMonth, item.announcementDateYear)}
                </MobileListItemSecondaryValue>
              </MobileListItemButton>
            </MobileListItem>
          ),
        },
      }}
      checkboxSelection
    />
  )
}

import { RpcMethodMap } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import useRpcClient from './use-rpc-client'

type Request<Method extends keyof RpcMethodMap> = {
  method: Method
} & ([RpcMethodMap[Method]['params']] extends [never]
  ? { params?: never }
  : { params: RpcMethodMap[Method]['params'] })

export interface AppBatchFetcherParams<Method extends keyof RpcMethodMap> {
  requests: Request<Method>[]
  token: string | null
  endpoint?: string
  signal?: AbortSignal
}

export function useAppBatchFetcher() {
  const fetcher = useRpcClient()

  return useCallback(
    <Method extends keyof RpcMethodMap = keyof RpcMethodMap>({
      requests,
      ...options
    }: AppBatchFetcherParams<Method>) => {
      return Promise.all(
        requests.map((request) =>
          fetcher<Method, RpcMethodMap[Method]['params']>({
            ...request,
            ...options,
          })
        )
      )
    },
    [fetcher]
  )
}

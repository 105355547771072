import { ListItemBase } from '@gain/rpc/list-model'
import { isDefined } from '@gain/utils/common'
import { ListProps } from '@mui/material/List'
import { useTheme } from '@mui/material/styles'
import { Breakpoint } from '@mui/system/createBreakpoints/createBreakpoints'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { ListHeaderProps } from '../../features/list-view/core/list-header'
import { TableProps } from '../table'
import { VirtualTableProps } from '../virtual-table'

export type VariantVirtualTableProps<Item extends object> = Omit<VirtualTableProps<Item>, 'rows'>

export interface VariantVirtualTableType<Item extends object> {
  variant: 'virtual-table'
  VariantProps: VariantVirtualTableProps<Item>
  children?: ReactElement | ReactElement[]
}

export interface VariantInlineTableType<Item extends object> {
  variant: 'inline-table'
  VariantProps: Omit<TableProps<Item>, 'rows'>
}

export type VariantListProps<Item extends object> = {
  title?: string
  ListProps?: ListProps
  renderListItem: (item: Item, index: number, items: Item[]) => ReactElement
  filterListItem?: (item: Item) => boolean
  headerProps?: ListHeaderProps | false
}

export type VariantListType<Item extends object> = {
  variant: 'list'
  version?: number
  VariantProps: VariantListProps<Item>
  children?: ReactElement | ReactElement[]
}

export type Variant<Item extends object> =
  | VariantVirtualTableType<Item>
  | VariantListType<Item>
  | VariantInlineTableType<Item>

export interface BreakpointVariantMap<Item extends object> {
  xs: Variant<Item>
  sm?: Variant<Item>
  md?: Variant<Item>
  lg?: Variant<Item>
  xl?: Variant<Item>
}

function useMatchesBreakpointCallback() {
  const theme = useTheme()

  return useCallback(
    (breakpoint: Breakpoint) => {
      if (theme.breakpoints.values) {
        return window.matchMedia(`(min-width: ${theme.breakpoints.values[breakpoint]}px)`).matches
      }

      return false
    },
    [theme.breakpoints.values]
  )
}

export default function useBreakpointVariant<Item extends ListItemBase>(
  props: BreakpointVariantMap<Item>
): Variant<Item> {
  const { xs, sm, md, lg, xl } = props
  const variants = useMemo(() => [xl, lg, md, sm, xs], [xs, sm, md, lg, xl])
  const matchesBreakpoint = useMatchesBreakpointCallback()

  const handleResize = useCallback(() => {
    return [
      matchesBreakpoint('xl'),
      matchesBreakpoint('lg'),
      matchesBreakpoint('md'),
      matchesBreakpoint('sm'),
      matchesBreakpoint('xs'),
    ].findIndex((matches) => matches)
  }, [matchesBreakpoint])

  const [currentBreakpointIndex, setCurrentBreakpointIndex] = useState(handleResize())

  useEffect(() => {
    const listener = () => {
      setCurrentBreakpointIndex(handleResize())
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [handleResize, setCurrentBreakpointIndex])

  return useMemo(() => {
    for (let i = currentBreakpointIndex; i < variants.length; i++) {
      const variant = variants[i]
      if (isDefined(variant)) {
        return variant
      }
    }

    return xs
  }, [xs, currentBreakpointIndex, variants])
}

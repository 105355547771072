import Accordion, { accordionClasses, AccordionProps } from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'

export const CheckboxAccordion = styled(({ children, ...props }: AccordionProps) => (
  <Accordion
    slotProps={{
      transition: {
        unmountOnExit: true,
      },
    }}
    {...props}>
    {children}
  </Accordion>
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',
  minHeight: 32,
  paddingLeft: 1,
  [`&.${accordionClasses.expanded}`]: {
    marginBottom: 0,
    marginTop: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

import { FunctionComponent, useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import { HelmetProps } from 'react-helmet'
// eslint-disable-next-line no-restricted-imports
import { Helmet } from 'react-helmet-async'

import { useHeadContext } from './head.context'

export type HeadProps = HelmetProps

export const Head: FunctionComponent<HeadProps> = ({ children, ...props }) => {
  const defaultProps = useHeadContext()
  const mergedProps = useMemo(
    () => ({
      ...defaultProps,
      ...props,
    }),
    [defaultProps, props]
  )

  return <Helmet {...mergedProps}>{children}</Helmet>
}

export default Head

import { AssetListItem } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'

export enum AssetUpdateType {
  New = 'New',
  Financials = 'Financials',
  ProcessingNewData = 'Processing new data',
  None = '',
}

export function assetUpdateType(asset: AssetListItem): AssetUpdateType {
  if (
    asset.publishedAt === null ||
    (asset.financialsAt === null && asset.profileType === AssetProfileType.Automated)
  ) {
    return AssetUpdateType.None
  }

  if (!asset.profileLive) {
    return AssetUpdateType.ProcessingNewData
  }

  // If financialsAt and publishedAt dates are identical, the asset is
  // considered newly added. This is a bit of a hack used in multiple places.
  if (asset.publishedAt === asset.financialsAt) {
    return AssetUpdateType.New
  }

  return AssetUpdateType.Financials
}

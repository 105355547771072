import { ReactNode } from 'react'
// eslint-disable-next-line no-restricted-imports
import { HelmetProvider } from 'react-helmet-async'

import { HeadContext, HeadContextType } from './head.context'

export type DefaultHeadProps = HeadContextType

export type HeadProviderProps = {
  defaultHeadProps: DefaultHeadProps
  children: ReactNode
}

export function HeadProvider({ children, defaultHeadProps }: HeadProviderProps) {
  return (
    <HelmetProvider>
      <HeadContext.Provider value={defaultHeadProps}>{children}</HeadContext.Provider>
    </HelmetProvider>
  )
}

import { ColumnVisibilityModel, RowType } from '@gain/utils/table'
import { useMemo } from 'react'

import useColumnPicker, { ColumnPickerConfigType } from '../column-picker/use-column-picker'
import { useSearchQueryParam } from './list-view-search'

export default function useListViewColumnVisibilityModel<ItemType extends RowType>(
  columnsType: ColumnPickerConfigType,
  availableColumnNames: (keyof ItemType)[],
  activeFilterColumns: (keyof ItemType)[],
  activeSearchColumns: string[] = []
): ColumnVisibilityModel<ItemType> {
  const [search] = useSearchQueryParam()

  // Select visible columns
  const columnPicker = useColumnPicker(columnsType)

  // Construct the visibility model which is a map of column names to booleans
  return useMemo(() => {
    // When a search is active, add full text search headline column
    const searchColumns: (keyof ItemType)[] = search ? activeSearchColumns : []

    const all = [...columnPicker.visibleColumns, ...searchColumns]
    if (columnPicker.showColumnsForActiveFilters) {
      all.push(...activeFilterColumns)
    }

    return availableColumnNames.reduce(
      (acc, col) => ({
        ...acc,
        [col]: all.includes(col),
      }),
      {}
    )
  }, [
    activeFilterColumns,
    activeSearchColumns,
    availableColumnNames,
    columnPicker.showColumnsForActiveFilters,
    columnPicker.visibleColumns,
    search,
  ])
}

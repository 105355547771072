import { useLocalStorage } from '@gain/utils/storage'
import { createContext, ReactNode, useContext } from 'react'

import { ColumnPickerConfigType } from './use-column-picker'

/**
 * Column picker state that will be stored in local storage per type (e.g. 'asset')
 */
export interface ColumnPickerTypeState {
  showColumnsForActiveFilters: boolean
  visibleColumns: string[]
}

/**
 * Entire Column picker state for all types that will be stored in the local
 * storage entry for the column picker
 */
type ColumnPickerState = Partial<Record<ColumnPickerConfigType, ColumnPickerTypeState>>

/**
 * ColumnPickerContextType exposes a getter and setter to read and mutate the
 * column picker state in the local storage
 */
type ColumnPickerContextType = ReturnType<typeof useLocalStorage<ColumnPickerState>>

const ColumnPickerContext = createContext<ColumnPickerContextType | null>(null)

export function useColumnPickerContext(): ColumnPickerContextType {
  const context = useContext(ColumnPickerContext)

  if (context === null) {
    throw new Error('ColumnPickerContext is not provided')
  }

  return context
}

export interface ColumnPickerProviderProps {
  children: ReactNode
}

/**
 * ColumnPickerProvider ensures the same ColumnPickerState is available
 * throughout the entire application. It should wrap at least the private
 * routes where the column picker is used.
 */
export default function ColumnPickerProvider({ children }: ColumnPickerProviderProps) {
  const context = useLocalStorage<ColumnPickerState>('COLUMN_PICKER', {})

  return <ColumnPickerContext.Provider value={context}>{children}</ColumnPickerContext.Provider>
}

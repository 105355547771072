import * as Shared from '@gain/rpc/shared-model'
import { FinancialResult } from '@gain/utils/financials'
import { styled } from '@mui/material/styles'

import { isFirstForecastedYear } from '../financial-utils'
import FinancialCell from './financial-cell'
import { useFinancialResultsTableContext } from './financial-results-table-hooks'
import type { RowConfig } from './financial-results-table-utils'

interface StyledRowHeaderProps {
  width: number
  paddingX: number
  divider?: boolean
}

const StyledRowHeader = styled('th', {
  shouldForwardProp: (prop) => !['width', 'paddingX', 'divider', 'sx'].includes(prop.toString()),
})<StyledRowHeaderProps>(({ theme, width, paddingX, divider }) => ({
  textAlign: 'left',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  height: 22,
  position: 'sticky',
  minWidth: width,
  maxWidth: width,
  width: width,
  left: 0,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0, paddingX),
  ...(divider && {
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.09)',
    clipPath: 'inset(0px -15px 0px 0px)',
  }),
}))

interface StyledRowProps {
  bold?: boolean
  thickBorders?: boolean
}

const StyledRow = styled('tr', {
  shouldForwardProp: (prop) => !['bold', 'thickBorders'].includes(prop.toString()),
})<StyledRowProps>(({ theme, bold, thickBorders }) => ({
  ...(bold && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),

  ...(!bold && {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  }),

  '&:first-of-type': {
    '& td, & th': {
      borderTop: `${thickBorders ? 4 : 1}px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(1.5),
    },
  },

  '&:last-of-type': {
    '& td, & th': {
      paddingBottom: theme.spacing(1.5),
    },
  },
}))

export interface FinancialRowProps extends StyledRowProps, RowConfig {
  financials: FinancialResult[]
  divider?: boolean
}

export default function FinancialRow({
  label,
  labelLetterSpacing,
  formatFn,
  valueFn,
  amountTypeFn,
  financials,
  divider,
  bold,
  ...styledProps
}: FinancialRowProps) {
  const { labelCellWidth, cellPaddingX, leftCellPaddingLeft } = useFinancialResultsTableContext()

  const formatFinancialValue = (financial: FinancialResult) => {
    if (!formatFn || !valueFn) {
      return null
    }

    const isEstimate = amountTypeFn?.(financial) !== Shared.FinancialResultAmountType.Actual

    return formatFn(valueFn(financial), isEstimate)
  }

  return (
    <StyledRow
      bold={bold}
      {...styledProps}>
      <StyledRowHeader
        divider={divider}
        paddingX={cellPaddingX}
        sx={{ pl: leftCellPaddingLeft, letterSpacing: labelLetterSpacing }}
        width={labelCellWidth}>
        {label}
      </StyledRowHeader>

      {financials.map((financial, index) => (
        <FinancialCell
          key={`${financial.year}-${financial.periodicity}`}
          amountType={amountTypeFn?.(financial)}
          bold={bold}
          borderLeft={isFirstForecastedYear(financials, index)}
          isForecast={financial.isForecast}>
          {formatFinancialValue(financial)}
        </FinancialCell>
      ))}
    </StyledRow>
  )
}

import { ListItemKey } from '@gain/rpc/list-model'
import { ElementType } from 'react'

import { FilterConfig } from '../filter-config/filter-config-model'
import { filterPath } from '../filter-utils'
import ZoomInFilterHeader from '../zoom-in/zoom-in-filter-header'
import FilterAutocomplete from './filter-autocomplete/filter-autocomplete'
import FilterCheckbox from './filter-checkbox'
import FilterCheckboxList from './filter-checkbox/filter-checkbox-list'
import FilterCity from './filter-city'
import FilterGeoPoint from './filter-geo-point'
import FilterGeoPolygon from './filter-geo-polygon'
import FilterRange from './filter-range'
import FilterSimilarTo from './filter-similar-to'
import FilterSwitch from './filter-switch'
import FilterTabs from './filter-tabs'
import FilterText from './filter-text'

const filterTypeToComponentMap: Record<FilterConfig['type'], ElementType> = {
  autocomplete: FilterAutocomplete,
  checkbox: FilterCheckbox,
  'checkbox-list': FilterCheckboxList,
  range: FilterRange,
  'range-currency': FilterRange,
  text: FilterText,
  'geo-point': FilterGeoPoint,
  'geo-polygon': FilterGeoPolygon,
  city: FilterCity,
  'similar-to': FilterSimilarTo,
}

export interface FilterProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  groupIndex: number
  itemIndex: number
  filter: FilterConfig<Item, FilterField>
  onClear: () => void
}

export default function Filter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ groupIndex, itemIndex, filter, onClear }: FilterProps<Item, FilterField>) {
  const path = filterPath(groupIndex, itemIndex, 'value')

  if (filter.tabs) {
    return (
      <FilterTabs
        filter={filter}
        groupIndex={groupIndex}
        itemIndex={itemIndex}
        onClear={onClear}
      />
    )
  } else if (filter.switch) {
    return (
      <FilterSwitch
        filter={filter}
        groupIndex={groupIndex}
        itemIndex={itemIndex}
        onClear={onClear}
      />
    )
  }

  const Component = filterTypeToComponentMap[filter.type]

  return (
    <>
      {/* The ZoomInFilterHeader allows the user to navigate back from a
       "zoomed-in" filter. This is only used to support filtering on US-states
       and should not be reused for anything else. */}
      <ZoomInFilterHeader zoomInConfig={filter.zoomIn} />
      <Component
        filter={filter}
        path={path}
      />
    </>
  )
}

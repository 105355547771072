import { DealListItem } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'

import ConditionalWrapper from '../../../common/conditional-wrapper'
import { ListViewDeal } from '../../../features/list-view'
import MobilePageListViewContainer from '../../../layout/mobile-page-list-view-container'

export interface InvestorPageDealsTabProps {
  defaultFilters: ListFilter<DealListItem>[]
}

export default function InvestorDealsList({ defaultFilters }: InvestorPageDealsTabProps) {
  const isXs = useIsXs()
  return (
    <ConditionalWrapper
      condition={isXs}
      wrapper={(children) => <MobilePageListViewContainer>{children}</MobilePageListViewContainer>}>
      <ListViewDeal defaultFilter={defaultFilters} />
    </ConditionalWrapper>
  )
}

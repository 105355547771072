import { AssetListItem, Investor } from '@gain/rpc/app-model'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React, { useCallback } from 'react'

import DialogHeader from '../../../common/dialog-header'
import InvestorDownloads from './investor-downloads'

export interface InvestorDownloadsDialogProps extends Pick<DialogProps, 'open' | 'onClose'> {
  investor: Investor
  assets: AssetListItem[]
}

export default function InvestorDownloadsDialog({
  open,
  onClose,
  investor,
  assets,
}: InvestorDownloadsDialogProps) {
  const handleClose = useCallback(
    (event) => {
      if (onClose) {
        onClose(event, 'backdropClick')
      }
    },
    [onClose]
  )

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={handleClose}
        title={'Downloads'}
      />
      <DialogContent>
        <InvestorDownloads
          assets={assets}
          investor={investor}
        />
      </DialogContent>
    </Dialog>
  )
}

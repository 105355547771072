import { ArrowRightIcon, ArrowUpIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React from 'react'

import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  Axis,
  AxisConfig,
  AxisConfigId,
  findAxisConfigById,
} from '../../chart/company-bubble-echart/axis/axis-config'

const StyledRoot = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.primary, // make icons black
  svg: {
    height: 14,
    width: 14,
  },
}))

interface ChartLibraryButtonAxisProps {
  axis: Axis
  axisConfig: DropdownMenuOptions<AxisConfig>
  configId: AxisConfigId
}

export function ChartToggleButtonAxis({ axis, axisConfig, configId }: ChartLibraryButtonAxisProps) {
  const config = findAxisConfigById(configId, axisConfig)

  // This is always a programmer error
  if (!config) {
    throw new Error(`Could not find axis config with id ${configId}`)
  }

  return (
    <StyledRoot
      alignItems={'center'}
      direction={'row'}
      gap={0.5}>
      {axis === Axis.X ? <ArrowRightIcon /> : <ArrowUpIcon />}
      <Typography
        color={'text.secondary'}
        variant={'overline'}>
        {config.label}
      </Typography>
    </StyledRoot>
  )
}

import Typography, { TypographyProps } from '@mui/material/Typography'

/**
 * FormError conditionally renders an error text. If the error
 * text is null, it will bail out of rendering.
 */
export default function FormError(props: TypographyProps) {
  const { children, ...restProps } = props

  // Bail out if no error is provided as child
  if (children == null) {
    return null
  }

  return (
    <Typography
      color={'error'}
      textAlign={'center'}
      variant={'body2'}
      {...restProps}>
      {children}
    </Typography>
  )
}

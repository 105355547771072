import { ListItemKey } from '@gain/rpc/list-model'

import { FilterModel } from './filter-model'

/**
 * Returns true if the given filter model contains item values that are not null, false otherwise
 */
export function hasValues<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filterModel: FilterModel<Item, FilterField> | null) {
  return filterModel && filterModel.some((group) => group.value.some((item) => item.value !== null))
}

/**
 * Returns true if the given filter model only contains item values that are null, false otherwise
 */
export function isEmpty<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filterModel: FilterModel<Item, FilterField> | null) {
  return !hasValues(filterModel)
}

export function filterPath(groupIndex: number, itemIndex: number, type?: 'value' | 'filterId') {
  const path = `groups[${groupIndex}].value[${itemIndex}]`
  return [path, type].filter(Boolean).join('.')
}

import { RowType } from '@gain/utils/table'
import { useCallback } from 'react'

import { VirtualTableBodyRowProps } from './virtual-table-body-row'

export type RowComponentProps = Omit<VirtualTableBodyRowProps, 'width' | 'top' | 'ref' | 'selected'>

export type UseGetRowComponentPropsCallbackRowParams<T extends RowType> = {
  row: T
}

export type UseGetRowComponentPropsCallbackParams<T extends RowType> =
  | ((params: UseGetRowComponentPropsCallbackRowParams<T>) => RowComponentProps)
  | RowComponentProps

const defaults: RowComponentProps = {
  hover: true,
}

export type UseGetRowComponentPropsCallbackReturn<Row extends RowType> = (
  rowParams: UseGetRowComponentPropsCallbackRowParams<Row>
) => RowComponentProps

export function useGetRowComponentPropsCallback<Row extends RowType>(
  params?: UseGetRowComponentPropsCallbackParams<Row>
) {
  return useCallback(
    (rowParams: UseGetRowComponentPropsCallbackRowParams<Row>) => {
      if (params === undefined) {
        return defaults
      }
      if (typeof params === 'function') {
        return {
          ...defaults,
          ...params(rowParams),
        }
      }
      return {
        ...defaults,
        ...params,
      }
    },
    [params]
  )
}

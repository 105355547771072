import { Sector, Subsector } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import { useSectorList, useSubsectorList } from './app-api-hooks'

/**
 * Returns a function that finds a sector by name or undefined when not found.
 */
export function useFindSectorCallback(): (name: string) => undefined | Sector {
  // use useSectors hook without params to prevent fetching each sector separately
  const { data } = useSectorList()

  return useCallback(
    (name: string | null | undefined) => {
      if (!name || !data.items) {
        return undefined
      }

      return data.items.find((item) => item.name === name)
    },
    [data]
  )
}

/**
 * Returns the sub sector by its name or undefined when not found.
 */
export function useSubsector(name: string | null): undefined | Subsector {
  // use useSubsectorList hook without params to prevent fetching each sub sector separately
  const { data } = useSubsectorList()
  if (!name || !data.items) {
    return undefined
  }

  return data.items.find((item) => item.name === name)
}

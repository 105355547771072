import { HtmlIcon, PdfIcon } from '@gain/components/icons'
import { AnnualReport, AnnualReportFile } from '@gain/rpc/app-model'
import { formatAnnualReportDisplayName } from '@gain/utils/annual-report'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'

import { ExportButton } from '../../../../features/export/export-button'
import { hasAnnualReportFileExtension } from './asset-downloads-dialog.hooks'

export type AnnualReportDownloadButtonProps = {
  report: AnnualReport
  arFile: AnnualReportFile
}

export default function DownloadAnnualReport({ report, arFile }: AnnualReportDownloadButtonProps) {
  return (
    <ListItem disableGutters>
      <ListItemIcon>
        {hasAnnualReportFileExtension(arFile, '.pdf') && <PdfIcon />}

        {hasAnnualReportFileExtension(arFile, '.html') && <HtmlIcon />}
      </ListItemIcon>

      <ListItemText primary={formatAnnualReportDisplayName(report, arFile)} />

      <ExportButton
        method={'data.exportAnnualReportFile'}
        params={{ id: arFile.id }}
      />
    </ListItem>
  )
}

import { FinancialResultPeriodicityType } from '@gain/rpc/shared-model'
import { FinancialResult } from '@gain/utils/financials'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useContext, useMemo } from 'react'

import { FinancialResultsTableContext } from './financial-results-table-context'

const CELL_PADDING_MD_UP = 3
const CELL_PADDING_XS = 1

const VALUE_CELL_MIN_WIDTH_SM_UP = 72 + 8 * CELL_PADDING_MD_UP * 2
const VALUE_CELL_MIN_WIDTH_XS = 72 + 8 * CELL_PADDING_XS * 2

const LABEL_CELL_MIN_WIDTH_SM_UP = 208
const LABEL_CELL_MIN_WIDTH_XS = 144

export interface FinancialResultsTableContextType {
  labelCellWidth: number
  minValueCellWidth: number
  cellPaddingX: number
  leftCellPaddingLeft: number
}

export function useFinancialResultsTableContextType(): FinancialResultsTableContextType {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return useMemo((): FinancialResultsTableContextType => {
    return {
      labelCellWidth: isXs ? LABEL_CELL_MIN_WIDTH_XS : LABEL_CELL_MIN_WIDTH_SM_UP,
      minValueCellWidth: isXs ? VALUE_CELL_MIN_WIDTH_XS : VALUE_CELL_MIN_WIDTH_SM_UP,
      cellPaddingX: isXs ? CELL_PADDING_XS : CELL_PADDING_MD_UP,
      leftCellPaddingLeft: isXs ? 2 : CELL_PADDING_MD_UP,
    }
  }, [isXs])
}

export function useFinancialResultsTableContext() {
  const context = useContext(FinancialResultsTableContext)

  if (!context) {
    throw new Error('FinancialResultsTableContext not provided')
  }

  return context
}

/**
 * Find the index of the last reported financial results with annual periodicity
 * (so LTM is excluded)
 */
export function useLastReportedFinancialYearIndex(financials: FinancialResult[]) {
  // Find last index of reported annual financials
  const index = financials
    .slice()
    .reverse()
    .findIndex(
      (item) => !item.isForecast && item.periodicity === FinancialResultPeriodicityType.Annual
    )

  if (index === -1) {
    return index
  }

  return financials.length - index - 1
}

/**
 * Returns the financial results that should be displayed in the financial results
 * table. Forecasts are limited to a 3-year lookahead of the last reported financial
 * results.
 */
export function useVisibleFinancials(financials: FinancialResult[]): FinancialResult[] {
  const lastReportedIndex = useLastReportedFinancialYearIndex(financials)

  return useMemo(() => {
    // When there are not reported financials (should not happen),
    // fallback to remaining financials.
    if (lastReportedIndex === -1) {
      return financials
    }

    // We can get additional forecasted years in the future, these are capped to the
    // last reported year + 3
    const maxYear = financials[lastReportedIndex].year + 3

    return financials.filter((results) => results.year <= maxYear)
  }, [financials, lastReportedIndex])
}

import LinkBehavior from '@gain/shared/components/link-behavior'
import { LinkProps } from '@mui/material/Link'
import { Components, Theme } from '@mui/material/styles'

export const MuiLink = (_: Theme): Components['MuiLink'] => ({
  defaultProps: {
    component: LinkBehavior,
    underline: 'hover',
  } as LinkProps,
  styleOverrides: {
    root: {
      '&:not([href])': {
        cursor: 'inherit',
      },
    },
  },
})

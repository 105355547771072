import { useElementWidthEffect } from '@gain/utils/dom'
import { RefObject, useCallback, useState } from 'react'

import { RowType } from './table-model'

interface ColumnFieldConfig<Row extends RowType, Field extends keyof Row> {
  field: Field
}

type ColumnConfigWithType<Row extends RowType> = {
  [Field in keyof Row]-?: ColumnFieldConfig<Row, Field>
}[keyof Row]

type ColumnConfig<Row extends RowType> = ColumnConfigWithType<Row>

/**
 * A model that toggles the visibility of columns. Each column is defined by the
 * field name and can have one of the following types:
 *
 * - Boolean: simply show/hides the column.
 * - Number: show the column if the width is less than the table's width.
 *
 * Any columns not defined in the visibility model are always shown.
 */
export type ColumnVisibilityModel<Row extends RowType> = Partial<
  Record<keyof Row, boolean | number>
>

/**
 * Returns the visible columns based on the width of the ref element.
 */
export default function useVisibleColumns<
  E extends HTMLElement,
  Row extends RowType,
  Config extends ColumnConfig<Row>
>(
  ref: RefObject<E | null>,
  columns: Config[],
  columnVisibilityModel: ColumnVisibilityModel<Row>
): Config[] {
  const [visibleColumns, setVisibleColumns] = useState<Config[]>([])

  const handleWidthChange = useCallback(
    (width: number) => {
      setVisibleColumns(
        columns.filter((column) => {
          const row = columnVisibilityModel[column.field]

          // If there is no row column visibility config always show the column.
          if (row === undefined) {
            return true
          }

          // If type is a number check if the width is less than the current width.
          if (typeof row === 'number') {
            return row < width
          }

          // If type is a boolean show the column if the value is true.
          return row
        })
      )
    },
    [columns, columnVisibilityModel]
  )

  useElementWidthEffect(ref, handleWidthChange)

  return visibleColumns
}

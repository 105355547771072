import { AssetListItemRatings } from '@gain/utils/asset'
import {
  formatContracted,
  formatEsgOverallRating,
  formatGrowth,
  formatNonCyclical,
  formatOrganicGrowth,
} from '@gain/utils/investment-criteria'
import { formatNumber } from '@gain/utils/number'
import React, { useMemo } from 'react'

import Card from '../../card/card'
import CardMetrics, { createMetrics } from './card-metrics'

interface CardMetricsSummaryProps {
  assessmentPath: string
  companiesPath: string
  dealCount: number
  dealsPath: string
  esgOverallRiskMedian?: number
  esgPath?: string
  nrOfCompanies: number
  ratings: AssetListItemRatings
}

/**
 * CardMetricsSummary is a default card for displaying a set of asset metrics
 * on the summary page.
 */
export function CardMetricsSummary({
  assessmentPath,
  companiesPath,
  dealCount,
  dealsPath,
  esgOverallRiskMedian,
  esgPath,
  nrOfCompanies,
  ratings,
}: CardMetricsSummaryProps) {
  const metrics = useMemo(() => {
    return createMetrics(
      {
        href: companiesPath,
        label: 'Companies',
        value: formatNumber(nrOfCompanies),
      },
      {
        href: dealsPath,
        label: 'Deals',
        value: formatNumber(dealCount || 0),
      },
      {
        href: assessmentPath,
        label: 'Revenue growth',
        value: formatGrowth(ratings.growth),
      },
      {
        href: assessmentPath,
        label: 'Organic growth',
        value: formatOrganicGrowth(ratings.organicGrowth),
      },
      {
        href: assessmentPath,
        label: 'Cyclicality',
        value: formatNonCyclical(ratings.nonCyclical),
      },
      !!esgPath && {
        href: esgPath,
        label: 'ESG risk',
        value: formatEsgOverallRating(esgOverallRiskMedian),
      },
      !esgPath && {
        href: assessmentPath,
        label: 'Revenue visibility',
        value: formatContracted(ratings.contracted),
      }
    )
  }, [
    assessmentPath,
    companiesPath,
    dealCount,
    dealsPath,
    esgOverallRiskMedian,
    esgPath,
    nrOfCompanies,
    ratings,
  ])

  return (
    <Card sx={{ pb: 0 }}>
      <CardMetrics metrics={metrics} />
    </Card>
  )
}

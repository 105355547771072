import { useListUsersByIds } from '@gain/api/app/hooks'
import { MoreVerticalIcon } from '@gain/components/icons'
import { UserListItem as UserListItemType } from '@gain/rpc/app-model'
import { useResizeObserver } from '@gain/utils/dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import React, {
  createRef,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { usePrevious } from 'react-use'

import { useOpenDialog } from '../../../common/dialog'
import DialogHeader from '../../../common/dialog-header'
import { BookmarksShareMenu, CloseActions } from './bookmarks-share-menu'
import useUpdateShareSettings, { useUpdateShareSettingsProps } from './use-update-share-settings'
import UserListItem from './user-list-item/user-list-item'
import ShareUserSearch from './user-search/share-user-search'

const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 518,
    maxHeight: 'min(542px, calc(100% - 64px))',
  },
})

const StyledList = styled(List)(({ theme }) => ({
  paddingBlock: theme.spacing(0.25),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
})

const StyledListHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey['50'],
  fontSize: 12,
  color: theme.palette.text.secondary,
}))

function sortUser(a: UserListItemType, b: UserListItemType) {
  let sortResult = a.firstName.localeCompare(b.firstName)
  if (sortResult === 0) {
    sortResult = a.lastName.localeCompare(b.lastName)
  }
  if (sortResult === 0) {
    sortResult = a.email.localeCompare(b.email)
  }
  return sortResult
}

function useHasOverflow<T extends HTMLElement>(ref: RefObject<T>) {
  const [isOverflow, setIsOverflow] = useState(false)

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    setIsOverflow(ref.current.scrollHeight > ref.current.clientHeight)
    return
  }, [ref])

  useResizeObserver(ref, handleResize)

  return isOverflow
}

export type BookmarksShareDialogProps = useUpdateShareSettingsProps &
  DialogProps & {
    userIds: number[]
    ownerUserId: number
    open: boolean
    variant?: 'update' | 'create'
  }

export default function BookmarksShareDialog({
  userIds,
  ownerUserId,
  open,
  onClose,
  variant = 'update',
  onShare,
  onLeave,
  onUnshare,
  onSubmit,
  onTransfer,
  ...props
}: BookmarksShareDialogProps) {
  const contentRef = createRef<HTMLElement>()
  const bottomEl = useRef<HTMLDivElement>(null)
  const prevOpen = usePrevious(open)
  const fetchUsers = useListUsersByIds()
  const [shareMenuAnchor, setShareMenuAnchor] = useState<Element | null>(null)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [shareMenuUserId, setShareMenuUserId] = useState<number>()
  const [owner, setOwner] = useState(ownerUserId)
  const openDialog = useOpenDialog()
  const hasOverflow = useHasOverflow(contentRef)
  const [selected, setSelected] = useState<UserListItemType[]>([])
  const [visibleItems, setVisibleItems] = useState<UserListItemType[]>([])
  const prevVisibleItems = usePrevious(visibleItems)
  const { save, isLoading } = useUpdateShareSettings({
    onClose,
    onSubmit,
    onShare,
    onLeave,
    onUnshare,
    onTransfer,
  })

  useEffect(() => {
    if (open) {
      setOwner(ownerUserId)
    }
  }, [open, ownerUserId])

  const selectedUserIds = useMemo(() => selected.map(({ userId }) => userId), [selected])

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  const isCreate = variant === 'create'
  const usersToAdd = selectedUserIds.filter((id) => !userIds.includes(id))
  const usersToRemove = userIds.filter((id) => !selectedUserIds.includes(id))
  const hasChanges =
    isCreate || usersToAdd.length > 0 || usersToRemove.length > 0 || owner !== ownerUserId

  useEffect(() => {
    if (prevVisibleItems && prevVisibleItems.length < visibleItems.length) {
      scrollToBottom()
    }
  }, [prevVisibleItems, visibleItems])

  // Refetch users when opening the modal
  useEffect(() => {
    if (open && prevOpen !== open) {
      fetchUsers(userIds).then((result) => {
        setSelected(result.sort(sortUser))
        setVisibleItems(result)
      })
    }
  }, [prevOpen, open, fetchUsers, userIds])

  const handleClose = useCallback(
    (event) => {
      event.stopPropagation()

      if (hasChanges) {
        openDialog({
          title: 'Discard changes',
          message: 'The changes have not been saved, are you sure you want to discard them?',
          confirmText: 'Discard changes',
          cancelText: 'Cancel',
          onConfirm: onClose,
        })
      } else {
        onClose()
      }
    },
    [hasChanges, onClose, openDialog]
  )

  const handleSave = () => {
    if (hasChanges) {
      save(usersToAdd, usersToRemove, owner, ownerUserId)
    } else {
      onClose()
    }
  }

  const handleSkipSharing = () => {
    save(userIds, [], ownerUserId, ownerUserId)
  }

  useEffect(() => {
    setVisibleItems(selected) // Trigger change in next render to ensure `in` changes from false to true
  }, [selected])

  useEffect(() => {
    setShareDialogOpen(Boolean(shareMenuAnchor)) // Change open=true for animation
  }, [shareMenuAnchor])

  const handleAdd = useCallback(
    (user: UserListItemType) => {
      setSelected((prev) => [...prev, user])

      // Disable animation when scrolling down, we use `scrollTo` for those instead
      if (hasOverflow) {
        setVisibleItems((prev) => [...prev, user])
      }
    },
    [hasOverflow]
  )

  const handleRemove = (user: UserListItemType) => {
    setSelected((prev) => prev.filter((item) => item !== user))
  }

  const handleCloseMenu = useCallback((userId: number, action: CloseActions) => {
    switch (action) {
      case 'remove':
        setVisibleItems((prev) => prev.filter((item) => item.userId !== userId))
        break
      case 'transfer':
        setOwner(userId)
        break
    }
    setShareMenuAnchor(null)
  }, [])

  const handleOpenMenu = useCallback(
    (userId: number) => (event: MouseEvent) => {
      setShareMenuUserId(userId)
      setShareMenuAnchor(event.currentTarget)
      event.stopPropagation()
      event.preventDefault()
    },
    []
  )

  return (
    <StyledDialog
      maxWidth={'sm'}
      onClose={handleClose}
      open={open}
      fullWidth
      {...props}>
      <DialogHeader
        onCloseClick={handleClose}
        title={'Share list with your team'}
      />

      <ShareUserSearch
        excludedUserIds={selectedUserIds}
        onSelect={handleAdd}
      />

      <StyledListHeader>People with access</StyledListHeader>

      <StyledDialogContent ref={contentRef}>
        <StyledList
          dense
          disablePadding>
          {selected?.map((user) => {
            const isOwner = owner === user.userId
            const hasMenuOptions = selected.length > 1 && !isOwner
            return (
              <Collapse
                key={user.userId}
                in={visibleItems.includes(user)}
                onExited={() => handleRemove(user)}
                mountOnEnter
                unmountOnExit>
                <UserListItem
                  isOwner={owner === user.userId}
                  secondaryAction={
                    hasMenuOptions && (
                      <StyledIconButton
                        onClick={handleOpenMenu(user.userId)}
                        size={'small'}>
                        <MoreVerticalIcon />
                      </StyledIconButton>
                    )
                  }
                  user={user}
                />
              </Collapse>
            )
          })}
        </StyledList>
        <div ref={bottomEl}></div>
      </StyledDialogContent>

      <DialogActions>
        {isCreate && (
          <Button
            color={'primary'}
            disabled={isLoading}
            onClick={handleSkipSharing}
            variant={'outlined'}
            fullWidth>
            Skip sharing
          </Button>
        )}
        <LoadingButton
          color={'primary'}
          loading={isLoading}
          onClick={handleSave}
          variant={'contained'}
          fullWidth>
          Save
        </LoadingButton>
      </DialogActions>

      {shareMenuAnchor && shareMenuUserId && (
        <BookmarksShareMenu
          anchorEl={shareMenuAnchor}
          onClose={handleCloseMenu}
          open={shareDialogOpen}
          originalOwnerUserId={ownerUserId}
          ownerUserId={owner}
          userId={shareMenuUserId}
        />
      )}
    </StyledDialog>
  )
}

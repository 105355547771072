import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { isDefined } from '@gain/utils/common'
import Debug from 'debug'

declare global {
  interface Window {
    zE?: ZendeskWidget
  }
}

export interface ZendeskWidget {
  (type: 'messenger' | 'messenger:on', command: string, payload?: unknown): void
}

const debug = Debug('gain-pro:zendesk')

export const zendeskCommand: ZendeskWidget = (type, command, payload) => {
  if (!window.zE) {
    throw new Error('Zendesk has not been initialized, was the widget implemented?')
  }

  debug.apply(
    debug,
    [
      `zendesk command "${type}" "${command}"`,
      typeof payload === 'function' ? undefined : payload,
    ].filter(isDefined) as never
  )

  try {
    return window.zE(type, command, payload)
  } catch (error) {
    const message = `caught zendesk error while executing command "${type}" "${command}"`
    // eslint-disable-next-line no-console
    console.error(message, error)

    cloudErrorReporter.report(error as never, {
      reportLocation: {
        functionName: `"${type}" "${command}"`,
      },
    })
  }
}

import {
  isCustomAssetList,
  useDeleteBookmarksFilteredList,
  useDeleteBookmarksList,
  useLeaveBookmarksFilteredList,
  useLeaveBookmarksList,
} from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import { BookmarksFilteredList, CustomAssetList } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import { ConfirmDialogProps, useOpenDialog } from '../../../common/dialog'

const createDeleteSharedListConfirm = (listTitle: string): ConfirmDialogProps => ({
  title: 'Delete list for all members',
  message: `The list “${listTitle}” will be deleted for all members. Are you sure you want delete it? `,
  confirmText: 'Yes, delete',
  cancelText: 'Cancel',
  color: 'error',
})

const createDeleteListConfirm = (listTitle: string): ConfirmDialogProps => ({
  title: 'Delete list',
  message: `Are you sure you want to delete “${listTitle}”?`,
  confirmText: 'Yes, delete',
  cancelText: 'Cancel',
  color: 'error',
})

const createConfirm = (userIds: number[], listTitle: string) => {
  if (userIds.length > 1) {
    return createDeleteSharedListConfirm(listTitle)
  }
  return createDeleteListConfirm(listTitle)
}

const createLeaveListConfirm = (listTitle: string): ConfirmDialogProps => ({
  title: 'Leave shared list',
  message: `Are you sure you want to leave “${listTitle}”?`,
  confirmText: 'Yes, leave',
  cancelText: 'Cancel',
  color: 'error',
})

export default function useDeleteOrLeaveCustomList() {
  const deleteQueryList = useDeleteBookmarksFilteredList()
  const deleteAssetList = useDeleteBookmarksList()
  const leaveAssetList = useLeaveBookmarksList()
  const leaveQueryList = useLeaveBookmarksFilteredList()
  const openDialog = useOpenDialog()
  const profile = useUserProfileContext()

  return useCallback(
    async (list: CustomAssetList | BookmarksFilteredList) => {
      const isOwner = list?.ownerUserId === profile?.id

      if (isOwner) {
        openDialog({
          ...createConfirm(list.userIds, list.title),
          onConfirm: async () => {
            if (isCustomAssetList(list)) {
              deleteAssetList(list.id)
            } else {
              deleteQueryList(list.id)
            }
          },
        })
      } else {
        openDialog({
          ...createLeaveListConfirm(list.title),
          onConfirm: async () => {
            if (isCustomAssetList(list)) {
              leaveAssetList(list.id)
            } else {
              leaveQueryList(list.id)
            }
          },
        })
      }
    },
    [profile.id, openDialog, deleteAssetList, deleteQueryList, leaveAssetList, leaveQueryList]
  )
}

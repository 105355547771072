import { styled } from '@mui/material/styles'

import { Manager } from './asset-management-util'
import ManagementListItem from './management-list-item'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

interface ManagementListProps {
  managers: Manager[]
}

export default function ManagementList({ managers }: ManagementListProps) {
  return (
    <StyledRoot>
      {managers.map((manager) => (
        <ManagementListItem
          key={manager.id}
          manager={manager}
        />
      ))}
    </StyledRoot>
  )
}

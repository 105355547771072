import { Components, Theme } from '@mui/material/styles'

export const MuiBadge = (theme: Theme): Components['MuiBadge'] => ({
  styleOverrides: {
    standard: {
      height: 16,
      minWidth: 16,
      padding: '0 5px',
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      lineHeight: theme.typography.caption.lineHeight,
      [theme.breakpoints.only('xs')]: {
        height: 20,
        lineHeight: '20px !important',
        padding: '0 7px',
        borderRadius: 12,
      },
    },
  },
})

import { Components, Theme } from '@mui/material/styles'

import { MuiAccordion } from './mui-accordion'
import { MuiAlert } from './mui-alert'
import { MuiAppBar } from './mui-app-bar'
import { MuiAutocomplete } from './mui-autocomplete'
import { MuiAvatar } from './mui-avatar'
import { MuiBackdrop } from './mui-backdrop'
import { MuiBadge } from './mui-badge'
import { MuiBreadcrumbs } from './mui-breadcrumbs'
import { MuiButton } from './mui-button'
import { MuiCheckbox } from './mui-checkbox'
import { MuiChip } from './mui-chip'
import { MuiContainer } from './mui-container'
import { MuiCssBaseline } from './mui-css-baseline'
import { MuiDialog } from './mui-dialog'
import { MuiDialogActions } from './mui-dialog-actions'
import { MuiDialogContent } from './mui-dialog-content'
import { MuiDialogContentText } from './mui-dialog-content-text'
import { MuiDialogTitle } from './mui-dialog-title'
import { MuiFilledInput } from './mui-filled-input'
import { MuiFormControlLabel } from './mui-form-control-label'
import { MuiFormHelperText } from './mui-form-helper-text'
import { MuiFormLabel } from './mui-form-label'
import { MuiIconButton } from './mui-icon-button'
import { MuiInputAdornment } from './mui-input-adornment'
import { MuiInputBase } from './mui-input-base'
import { MuiInputLabel } from './mui-input-label'
import { MuiLink } from './mui-link'
import { MuiList } from './mui-list'
import { MuiListItem } from './mui-list-item'
import { MuiListItemButton } from './mui-list-item-button'
import { MuiListItemIcon } from './mui-list-item-icon'
import { MuiListItemSecondaryAction } from './mui-list-item-secondary-action'
import { MuiListItemText } from './mui-list-item-text'
import { MuiListSubheader } from './mui-list-subheader'
import { MuiLoadingButton } from './mui-loading-button'
import { MuiMenu } from './mui-menu'
import { MuiMenuItem } from './mui-menu-item'
import { MuiOutlinedInput } from './mui-outlined-input'
import { MuiPaper } from './mui-paper'
import { MuiRadio } from './mui-radio'
import { MuiRating } from './mui-rating'
import { MuiSlider } from './mui-slider'
import { MuiSvgIcon } from './mui-svg-icon'
import { MuiSwitch } from './mui-switch'
import { MuiTab } from './mui-tab'
import { MuiTable } from './mui-table'
import { MuiTableBody } from './mui-table-body'
import { MuiTableCell } from './mui-table-cell'
import { MuiTableFooter } from './mui-table-footer'
import { MuiTableHead } from './mui-table-head'
import { MuiTableRow } from './mui-table-row'
import { MuiTableSortLabel } from './mui-table-sort-label'
import { MuiTabs } from './mui-tabs'
import { MuiToggleButton } from './mui-toggle-button'
import { MuiToggleButtonGroup } from './mui-toggle-button-group'
import { MuiTooltip } from './mui-tooltip'
import { MuiTypography } from './mui-typography'
import { MuiUseMediaQuery } from './mui-use-media-query'

const components = {
  MuiAccordion,
  MuiAlert,
  MuiAppBar,
  MuiAutocomplete,
  MuiAvatar,
  MuiBackdrop,
  MuiBadge,
  MuiBreadcrumbs,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiContainer,
  MuiCssBaseline,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
  MuiIconButton,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListItemSecondaryAction,
  MuiListItemText,
  MuiListSubheader,
  MuiLoadingButton,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaper,
  MuiRadio,
  MuiRating,
  MuiSlider,
  MuiSwitch,
  MuiSvgIcon,
  MuiTab,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableFooter,
  MuiTableHead,
  MuiTableRow,
  MuiTableSortLabel,
  MuiTabs,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTooltip,
  MuiTypography,
  MuiUseMediaQuery,
}

export const createComponents = (theme: Theme): Components =>
  Object.keys(components).reduce(
    (acc, current) => ({
      ...acc,
      [current]: components[current as keyof typeof components](theme),
    }),
    {}
  )

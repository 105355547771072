import { createEmptyFinancialResult } from './create-empty-financial-result'
import { FinancialResult } from './financial-result-model'

export function addMissingFinancialYears(financials: FinancialResult[]) {
  return financials.reduce((acc, current) => {
    if (acc.length > 0 && current.year - 1 !== acc[acc.length - 1].year) {
      for (let i = acc[acc.length - 1].year + 1; i < current.year; i++) {
        acc.push(createEmptyFinancialResult(i))
      }
    }
    acc.push(current)
    return acc
  }, new Array<FinancialResult>())
}

import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { isCmdClickEvent } from '@gain/utils/event'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled, SxProps } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import ChartAxisTitle from '../../../common/chart/chart-axis-title'
import { chartColorSet } from '../../../common/chart/chart-colors'
import {
  ChartGroup,
  ChartGroupByConfig,
  ChartGroupBySelect,
  getPreferredChartGroupBy,
  useChartGroups,
} from '../../../common/chart/chart-groups'
import ChartLegend from '../../../common/chart/chart-legend'
import { ChartSizeTypeConfig, ChartSizeTypeSelect } from '../../../common/chart/chart-select'
import { MeasureDimensions } from '../../../common/responsive'
import { generateAssetPagePath } from '../../../routes/utils'
import { AssetChartSizeType, useAssetChartSizeTypes } from '../chart-utils/asset-chart-size-types'
import { AssetChartGroupType } from '../chart-utils/asset-group-by-configs'
import useGroupByOptions from '../chart-utils/use-group-by-options'
import BubbleChart from '../charts/bubble-chart/bubble-chart'
import useCompanyAxisConfig, { CompanyAxisKey } from './use-company-axis-config'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 3, 0),
}))

export interface CompanyBubbleChartProps {
  assets: AssetListItem[]
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorProfileStrategy[]
  xScale: CompanyAxisKey
  yScale: CompanyAxisKey
  disableGroupBySelect?: boolean
  fixedHeight?: number
  defaultSizeType?: AssetChartSizeType
  defaultGroupTypeId?: AssetChartGroupType
  disableXAxisLines?: boolean
  sx?: SxProps
}

/**
 * Deprecated: Use CompanyBubbleEChart instead
 */
function CompanyBubbleChart({
  assets,
  industrySegments,
  investorStrategies,
  xScale,
  yScale,
  disableGroupBySelect,
  fixedHeight = 240,
  defaultSizeType = AssetChartSizeType.Revenue,
  defaultGroupTypeId,
  disableXAxisLines,
  sx,
}: CompanyBubbleChartProps) {
  const assetSizeTypes = useAssetChartSizeTypes()
  const [sizeType, setSizeType] = useState<ChartSizeTypeConfig<AssetListItem>>(
    assetSizeTypes.find(({ id }) => id === defaultSizeType) || assetSizeTypes[0]
  )
  const groupByOptions = useGroupByOptions(industrySegments, investorStrategies, assets)
  const [groupBy, setGroupBy] = useState<ChartGroupByConfig<AssetListItem>>(
    getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId)
  )

  const groups = useChartGroups(assets, groupBy)
  const [visibleGroups, setVisibleGroups] = useState<ChartGroup<AssetListItem>[]>(groups)
  const history = useHistory()

  const xAxis = useCompanyAxisConfig(xScale)
  const yAxis = useCompanyAxisConfig(yScale)

  const handleGetId = useCallback((d) => d.id, [])
  const handleGetColor = useCallback(
    (item: AssetListItem) =>
      groups.find((group) => group.value === groupBy.getValue(item))?.color || chartColorSet[0],
    [groups, groupBy]
  )

  const filteredAssets = useMemo(() => {
    const temp = visibleGroups.map((group) => group.value)
    return assets.filter((asset) => temp.includes(groupBy.getValue(asset)))
  }, [assets, groupBy, visibleGroups])

  useEffect(() => {
    setGroupBy(getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId))
  }, [groupByOptions, defaultGroupTypeId])

  useEffect(() => {
    setVisibleGroups(groups)
  }, [groups])

  const handleBubbleClick = useCallback(
    (item: AssetListItem, event: MouseEvent) => {
      const assetPagePath = generateAssetPagePath({
        id: item.id,
        name: item.name,
      })

      if (isCmdClickEvent(event)) {
        window.open(history.createHref({ pathname: assetPagePath }))
      } else {
        history.push(assetPagePath)
      }
    },
    [history]
  )

  return (
    <StyledRoot sx={sx}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={2}
        justifyContent={'space-between'}
        sx={{ pb: 1 }}>
        <ChartAxisTitle
          axis={'y'}
          explainer={yAxis.explainer}
          label={yAxis.label}
        />

        <Stack
          direction={'row'}
          gap={2}
          justifyContent={'space-between'}>
          <ChartSizeTypeSelect
            onChange={setSizeType}
            options={assetSizeTypes}
            value={sizeType}
          />
          {!disableGroupBySelect && (
            <ChartGroupBySelect
              onChange={setGroupBy}
              options={groupByOptions}
              value={groupBy}
            />
          )}
        </Stack>
      </Stack>

      <MeasureDimensions fixedHeight={fixedHeight}>
        {({ width, height }) => (
          <BubbleChart
            data={filteredAssets}
            disableXAxisLines={disableXAxisLines}
            getBubbleValue={sizeType.getValue}
            getColor={handleGetColor}
            getId={handleGetId}
            height={height}
            onBubbleClick={handleBubbleClick}
            renderBubble={(item, node) => (
              <Tooltip
                key={item.id}
                title={`${item.name} • ${sizeType.formatter(sizeType.getValue(item), item)}`}
                disableInteractive>
                {node}
              </Tooltip>
            )}
            width={width}
            xScaleConfig={xAxis}
            yScaleConfig={yAxis}
          />
        )}
      </MeasureDimensions>

      <ChartAxisTitle
        axis={'x'}
        explainer={xAxis.explainer}
        label={xAxis.label}
      />

      <Divider sx={{ mx: -3 }} />

      <ChartLegend
        groups={groups}
        height={47}
        onChange={setVisibleGroups}
        value={visibleGroups}
      />
    </StyledRoot>
  )
}

export default memo(CompanyBubbleChart)

import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { ListItemKey } from '@gain/rpc/list-model'
import { styled } from '@mui/material/styles'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useField } from 'react-final-form'
import { FormProvider, useForm } from 'react-hook-form'
import { DeepPartial } from 'react-hook-form/dist/types/utils'
import { array, InferType, mixed, number, object, string } from 'yup'

import {
  AutocompleteMatchMode,
  AutocompleteOption,
  FilterAutocompleteOptionsValue,
  FilterAutocompleteValue,
  FilterConfigAutocomplete,
} from '../../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../../use-track-filter-event'
import FilterAutocompleteInput from './filter-autocomplete-input'
import FilterAutocompleteOptions from './filter-autocomplete-options'
import FilterAutocompleteSuggestions from './filter-autocomplete-suggestions'
import { useFetchAutocompleteOptions } from './filter-autocomplete-utils'

const StyledRoot = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}))

export type AutocompleteIncludeMode = 'include' | 'exclude'

const AutocompleteOptionSchema = object({
  label: string().required(),
  value: number().required(),
  count: number(),
})

type AutocompleteOptionSchemaType = InferType<typeof AutocompleteOptionSchema>

const AutocompleteOptionsSchema = object({
  value: array().of(AutocompleteOptionSchema.required()).required(),
  mode: mixed<AutocompleteMatchMode>()
    .oneOf(['any', 'all'] as const)
    .required(),
})

type AutocompleteOptionsSchemaType = InferType<typeof AutocompleteOptionsSchema>

const AutocompleteFormSchema = object({
  include: AutocompleteOptionsSchema,
  exclude: AutocompleteOptionsSchema,
  mode: mixed<AutocompleteIncludeMode>()
    .oneOf(['include', 'exclude'] as const)
    .required(),
})

export type AutocompleteFormSchemaType = InferType<typeof AutocompleteFormSchema>

function formOptionsToFilterOptions(
  options: AutocompleteOptionsSchemaType
): FilterAutocompleteOptionsValue {
  return {
    value: options.value.map((option) => option.value),
    mode: options.mode,
  }
}

function formValueToFilterValue(
  values: DeepPartial<AutocompleteFormSchemaType>
): FilterAutocompleteValue {
  try {
    const value = AutocompleteFormSchema.validateSync(values)

    if (value.include.value.length === 0 && value.exclude.value.length === 0) {
      return null
    }

    return {
      include: formOptionsToFilterOptions(value.include),
      exclude: formOptionsToFilterOptions(value.exclude),
    }
  } catch (e) {
    return null
  }
}

function optionValuesToOptions(
  options: FilterAutocompleteOptionsValue,
  optionsLookup: Record<number, AutocompleteOption>
): AutocompleteOptionsSchemaType {
  return {
    value: options.value.reduce((acc, current) => {
      if (optionsLookup[current]) {
        return acc.concat({
          value: current,
          label: optionsLookup[current].label,
          count: optionsLookup[current].count,
        })
      }

      return acc
    }, new Array<AutocompleteOptionSchemaType>()),
    mode: options.mode,
  }
}

function useFilterValueToFormValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filter: FilterConfigAutocomplete<Item, FilterField>) {
  const fetch = useFetchAutocompleteOptions(filter.fetchOptions)

  return useCallback(
    async (
      value: FilterAutocompleteValue,
      includeMode: AutocompleteIncludeMode
    ): Promise<DeepPartial<AutocompleteFormSchemaType>> => {
      const defaultValue = {
        ...getDefaultFormValue(),
        mode: includeMode,
      }

      if (!value || (value.include.value.length === 0 && value.exclude.value.length === 0)) {
        return defaultValue
      }

      const values = [...value.include.value, ...value.exclude.value]

      try {
        const options = await fetch(null, values)
        const optionLookup = options.reduce(
          (acc, current) => ({
            [current.value]: current,
            ...acc,
          }),
          {} as Record<number, AutocompleteOption>
        )

        return {
          mode: includeMode,
          include: optionValuesToOptions(value.include, optionLookup),
          exclude: optionValuesToOptions(value.exclude, optionLookup),
        }
      } catch (error) {
        cloudErrorReporter.report(error)
        return defaultValue
      }
    },
    [fetch]
  )
}

function getDefaultFormValue(): AutocompleteFormSchemaType {
  return {
    include: {
      value: [],
      mode: 'any',
    },
    exclude: {
      value: [],
      mode: 'any',
    },
    mode: 'include',
  }
}

export interface FilterAutocompleteProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigAutocomplete<Item, FilterField>
}

export default function FilterAutocomplete<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterAutocompleteProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()
  const filterValueToFormValue = useFilterValueToFormValue(filter)
  const isUpdatingRef = useRef(false)

  // Use react-final-form to manage the filter bar form
  const field = useField<FilterAutocompleteValue>(path)

  // Use react-hook-form for internal state of this filter
  const form = useForm<AutocompleteFormSchemaType>({
    defaultValues: getDefaultFormValue(),
    mode: 'all',
  })

  const handleAddOption = useCallback(
    (option: AutocompleteOption) => {
      const mode = form.getValues().mode
      const currentValue = form.getValues()[mode].value
      if (currentValue.findIndex((item) => item.value === option.value) === -1) {
        form.setValue(`${mode}.value`, currentValue.concat(option))
      }
    },
    [form]
  )

  // Update internal state on external change
  useEffect(() => {
    const currentValue = formValueToFilterValue(form.getValues())

    if (!isEqual(field.input.value, currentValue)) {
      isUpdatingRef.current = true
      filterValueToFormValue(field.input.value, form.getValues().mode).then((nextValue) => {
        form.reset(nextValue)
        isUpdatingRef.current = false
      })
    }
  }, [field.input.value, form, filterValueToFormValue])

  // Update external state on internal change
  useEffect(() => {
    const subscription = form.watch((value: DeepPartial<AutocompleteFormSchemaType>) => {
      if (isUpdatingRef.current) {
        return
      }

      const filterValue = formValueToFilterValue(value)
      if (!isEqual(filterValue, field.input.value)) {
        field.input.onChange(filterValue)
        trackEvent(`Filter ${filter.label} value change`, 'TODO: label', null)
      }
    })

    return () => subscription.unsubscribe()
  }, [field.input, filter.label, form, trackEvent])

  return (
    <FormProvider {...form}>
      <StyledRoot>
        <FilterAutocompleteInput
          filter={filter}
          onSelectOption={handleAddOption}
        />
        <FilterAutocompleteOptions
          filter={filter}
          label={'Include'}
          name={'include'}
        />
        <FilterAutocompleteOptions
          filter={filter}
          label={'Exclude'}
          name={'exclude'}
        />
        <FilterAutocompleteSuggestions
          fetchSuggestions={filter.fetchSuggestions}
          label={`Related ${filter.pluralName}`}
          onSelectOption={handleAddOption}
        />
      </StyledRoot>
    </FormProvider>
  )
}

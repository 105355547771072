import { formatMonthYear } from '@gain/utils/date'
import { formatDealTitle } from '@gain/utils/deal'

import ListItem from '../../../../common/list/list-item'
import { generateDealPagePath } from '../../../utils'
import { DealRow } from './table-deals-columns'

export interface ListItemDealProps {
  deal: DealRow
}

export default function ListItemDeal({ deal }: ListItemDealProps) {
  return (
    <ListItem
      href={generateDealPagePath({
        id: deal.id,
        name: formatDealTitle(deal),
      })}
      primary={formatDealTitle(deal)}
      secondary={formatMonthYear(deal.announcementDate?.month, deal.announcementDate?.year)}
    />
  )
}

import { DealStatus } from '@gain/rpc/shared-model'
import { DealParty, DealPartyType } from '@gain/utils/deal'
import Stack from '@mui/material/Stack'

import ContentLinkDealParty from '../../../../features/deal-party/deal-party-content-link'

export interface DealPartiesTooltipTitleProps {
  parties: DealParty[]
  dealStatus: DealStatus | null
  type: DealPartyType
}

export default function DealPartiesTooltipTitle({
  parties,
  dealStatus,
  type,
}: DealPartiesTooltipTitleProps) {
  return (
    <Stack
      direction={'column'}
      gap={1.5}>
      {parties.map((party, index) => (
        <ContentLinkDealParty
          key={index}
          dealStatus={dealStatus}
          party={party}
          type={type}
          disableTooltip
        />
      ))}
    </Stack>
  )
}

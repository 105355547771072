import { MutableRefObject, useCallback, useLayoutEffect, useState } from 'react'

import { getAbsoluteOffsetTop } from './get-absolute-offset-top'

export function useScrollSpy(
  elementsRef: MutableRefObject<Array<HTMLElement | null>>,
  marginTop = 0
) {
  const [activeElementIndex, setActiveElementIndex] = useState<number>(-1)

  const handleScroll = useCallback(() => {
    if (window.scrollY === 0) {
      // If the scroll position equals zero, always mark the first element as active
      setActiveElementIndex(0)
    } else if (
      window.scrollY > 0 &&
      window.scrollY < document.body.scrollHeight - window.innerHeight
    ) {
      // If the scroll position is between 0 and the max scroll top, find the element that is intersecting with the
      // middle of the screen
      const middle = window.scrollY + window.innerHeight / 2 + marginTop

      const intersectingElementIndex = elementsRef.current.findIndex((el) => {
        if (!el) {
          return false
        }

        const top = getAbsoluteOffsetTop(el)
        return top < middle && top + el.getBoundingClientRect().height > middle
      })

      // If no match was found (middle is between two elements), keep using the previous value
      // otherwise use the intersecting element index
      if (intersectingElementIndex > -1) {
        setActiveElementIndex(intersectingElementIndex)
      }
    } else {
      // If the scroll position is at the end of the scroll container, always use the last element
      setActiveElementIndex(elementsRef.current.length - 1)
    }
  }, [elementsRef, marginTop])

  useLayoutEffect(() => {
    // Determine initial value
    handleScroll()

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return activeElementIndex
}

import { ListItemKey } from '@gain/rpc/list-model'
import React from 'react'

import { CheckboxListGroup, CheckboxListOption } from '../../../../../common/checkbox-list'
import {
  FilterCheckboxListOption,
  FilterConfigCheckboxList,
  OptionValueType,
} from '../../filter-config/filter-config-model'
import { isOptionGroup } from '../../filter-config/filter-config-util'
import ZoomInChip from '../../zoom-in/zoom-in-chip'
import TextHighlight from './text-highlight'

/**
 * Get all values from a checkbox list option recursively.
 */
function getOptionValues<ValueType extends OptionValueType>(
  option: FilterCheckboxListOption<ValueType>
): ValueType[] {
  if (isOptionGroup(option)) {
    return option.options.flatMap((opt) => getOptionValues(opt))
  }

  return [option.value]
}

interface FilterCheckboxOptionProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends OptionValueType = OptionValueType
> {
  option: FilterCheckboxListOption<ValueType>
  highlightText?: string
  filter: FilterConfigCheckboxList<Item, FilterField, ValueType>
}

/**
 * Renders a checkbox option or group of options recursively.
 */
export default function FilterCheckboxOption<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends OptionValueType = OptionValueType
>({ option, highlightText, filter }: FilterCheckboxOptionProps<Item, FilterField, ValueType>) {
  if (isOptionGroup(option)) {
    return (
      <CheckboxListGroup
        key={option.label}
        expanded={option.expanded}
        label={option.label}
        values={getOptionValues(option)}>
        {option.options.map((child) => (
          <FilterCheckboxOption
            key={child.label}
            filter={filter}
            highlightText={highlightText}
            option={child}
          />
        ))}
      </CheckboxListGroup>
    )
  }

  // This supports filtering on US-states from the region filter. It should not
  // be reused for anything else.
  const zoomInOption = filter.zoomIn?.options.find((item) => {
    return item.value === option.value
  })

  return (
    <CheckboxListOption
      key={option.value}
      explainerTitle={option.label}
      label={
        <TextHighlight
          highlightText={highlightText}
          text={option.label}
        />
      }
      secondaryAction={zoomInOption && <ZoomInChip<Item> zoomInOption={zoomInOption} />}
      value={option.value}
    />
  )
}

import { AssetListItem } from '@gain/rpc/app-model'
import {
  RatingEsgKey,
  RatingInvestmentCriteriaKey,
  RatingKeys,
} from '@gain/utils/investment-criteria'
import { median } from 'd3-array'
import { useMemo } from 'react'

type AssetListItemEsgRatingKeys = keyof Pick<
  AssetListItem,
  'latestIndustryRatingSocial' | 'latestIndustryRatingEnvironmental' | 'latestIndustryRatingOverall'
>

type AssetListItemRatingKeys = RatingKeys<AssetListItem> | AssetListItemEsgRatingKeys

function average(assets: AssetListItem[], id: AssetListItemRatingKeys) {
  return median(assets, (d) => d[id]) || 0
}

function round(assets: AssetListItem[], id: AssetListItemRatingKeys) {
  return Math.round(average(assets, id))
}

function roundHalf(assets: AssetListItem[], id: AssetListItemRatingKeys) {
  return Math.round(average(assets, id) * 2) / 2
}

export type AssetListItemRatings = Omit<
  Record<RatingInvestmentCriteriaKey, number | null>,
  'overall'
>

export function getMedianAssetRatings(assets: AssetListItem[]): AssetListItemRatings {
  return {
    contracted: round(assets, 'ratingContracted'),
    conversion: roundHalf(assets, 'ratingConversion'),
    ebitda: roundHalf(assets, 'ratingEbitda'),
    buyAndBuild: round(assets, 'ratingBuyAndBuild'),
    growth: roundHalf(assets, 'ratingGrowth'),
    leader: round(assets, 'ratingLeader'),
    multinational: round(assets, 'ratingMultinational'),
    grossMargin: roundHalf(assets, 'ratingGrossMargin'),
    nonCyclical: round(assets, 'ratingNonCyclical'),
    organicGrowth: roundHalf(assets, 'ratingOrganicGrowth'),
  }
}

export function useMedianAssetRatings(assets: AssetListItem[]): AssetListItemRatings {
  return useMemo(() => {
    return getMedianAssetRatings(assets)
  }, [assets])
}

export type AssetListItemEsgRatings = Omit<Record<RatingEsgKey, number>, 'overall'>

export function getMedianAssetEsgRatings(assets: AssetListItem[]): AssetListItemEsgRatings {
  return {
    ratingSocial: roundHalf(assets, 'latestIndustryRatingSocial'),
    ratingEnvironmental: roundHalf(assets, 'latestIndustryRatingEnvironmental'),
    ratingOverall: roundHalf(assets, 'latestIndustryRatingOverall'),
  }
}

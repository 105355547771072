import { styled } from '@mui/material/styles'

const StyledValue = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
})

export interface FilterValueLabelValueProps {
  label: string
  value: string | null
}

export default function FilterValueLabelValue({ label, value }: FilterValueLabelValueProps) {
  return (
    <>
      <span>{label}:</span> <StyledValue>{value}</StyledValue>
    </>
  )
}

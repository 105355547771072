// Search and the recent visits both use this component for display.
// Their types are slightly different, however, hence the CombinedSearchType.
import {
  BriefcaseMoneyIcon,
  CompanyIcon,
  ConferenceIcon,
  IndustryIcon,
  UsersIcon,
} from '@gain/components/icons'
import { ObjectType, SearchItemType } from '@gain/rpc/app-model'
import React from 'react'

import { generateConferencePagePath } from '../../routes/conference/conference-path'
import {
  generateAdvisorPagePath,
  generateAssetPagePath,
  generateIndustryPagePath,
  generateInvestorPagePath,
  generateLegalEntityPagePath,
} from '../../routes/utils'

export type CombinedSearchType = `${SearchItemType}` | `${ObjectType}` | undefined

export function correctItemDescription(
  type: CombinedSearchType,
  description?: string | null,
  regions?: string[]
) {
  switch (type) {
    case SearchItemType.Industry:
      return regions?.length ? regions[0] : null

    case SearchItemType.Entity:
      return 'Legal entity'

    case SearchItemType.Investor:
      return 'Investor'

    case SearchItemType.Advisor:
      return 'Advisor'

    case SearchItemType.ConferenceEdition:
      return 'Conference'

    case ObjectType.Asset:
    case SearchItemType.CuratedAsset:
    case SearchItemType.AutomatedAsset:
      return description ?? 'Company'

    default:
      return description ?? null
  }
}

export function correctItemName(type: CombinedSearchType, name?: string) {
  if (type === SearchItemType.Industry) {
    return `${name} industry`
  }

  return name
}

export function correctItemRegion(type: CombinedSearchType, regions?: string[]) {
  if (type === SearchItemType.Industry) {
    return null
  } else if (regions && regions?.length > 0) {
    const region = regions[0]

    if (region !== 'NULL') {
      return region
    }
  }

  return null
}

export function correctItemIcon(type: CombinedSearchType, imageUrl?: string | null) {
  if (!imageUrl) {
    switch (type) {
      case SearchItemType.Industry:
        return <IndustryIcon />

      case SearchItemType.Investor:
        return <BriefcaseMoneyIcon />

      case SearchItemType.Advisor:
        return <UsersIcon />

      case SearchItemType.ConferenceEdition:
        return <ConferenceIcon />

      default:
        return <CompanyIcon />
    }
  }

  return null
}

export function correctItemTo(type: CombinedSearchType, id?: number, name?: string) {
  if (id === undefined || name === undefined) {
    return '#'
  }

  switch (type) {
    case ObjectType.Asset:
    case SearchItemType.CuratedAsset:
    case SearchItemType.AutomatedAsset:
      return generateAssetPagePath({
        id,
        name: name || undefined,
      })
    case SearchItemType.Industry:
      return generateIndustryPagePath({
        id,
        title: name || undefined,
      })
    case SearchItemType.Entity:
      return generateLegalEntityPagePath({
        id,
        name: name || undefined,
      })
    case SearchItemType.Investor:
      return generateInvestorPagePath({
        investorId: id,
        investorName: name || '',
      })
    case SearchItemType.Advisor:
      return generateAdvisorPagePath({
        advisorId: id,
        advisorName: name,
      })

    case SearchItemType.ConferenceEdition:
      return generateConferencePagePath({
        conferenceId: id,
        conferenceName: name,
      })
    default:
      return '#'
  }
}

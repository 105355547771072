import { useMemo } from 'react'

import { addMissingFinancialYears } from './add-missing-financial-years'
import { FinancialResult } from './financial-result-model'

export function useFinancialsWithMissingYears(financials: FinancialResult[]) {
  return useMemo(() => {
    return addMissingFinancialYears(financials)
  }, [financials])
}

import { useAppMutateSwr, useRpcClient } from '@gain/api/swr'
import { CustomAssetList } from '@gain/rpc/app-model'
import { useCallback, useMemo } from 'react'

import { useCustomAssetLists } from './app-api-hooks'

/**
 * This updates the state of the SWR bookmarks list. You have the option
 * to provide a callback function to manually modify the cache. If no
 * callback is provided, the function retrieves the latest version of
 * the list from the backend.
 */
export function useMutateBookmarksList() {
  const fetcher = useRpcClient()
  const mutate = useAppMutateSwr('lists.getCustomAssetLists')

  return useCallback(
    (
      listId: number,
      mutator?: (list: CustomAssetList | undefined) => CustomAssetList | undefined
    ) => {
      return mutate(
        async (lists: CustomAssetList[] | undefined) => {
          const filtered = lists?.filter((item) => item.id !== listId)

          // When a callback is update the result.
          if (mutator) {
            const updatedList = mutator(lists?.find((item) => item.id === listId))
            if (!updatedList) {
              return filtered // List was removed
            }
            return [...(filtered ?? []), updatedList]
          }

          // When no callback is provided do a plain update.
          const data = await fetcher({
            method: 'lists.getCustomAssetList',
            params: { id: listId },
          })

          return [...(filtered ?? []), data]
        },
        { revalidate: false }
      )
    },
    [fetcher, mutate]
  )
}

/**
 * Returns a bookmarks list with specified id or undefined when not found.
 */
export function useBookmarksList(id: number) {
  const { data, loading } = useCustomAssetLists()
  const list = data?.find((item) => item.id === id)
  return { list, loading }
}

/**
 * Returns all bookmark lists that contain the specified assetId or an
 * empty array when none were found.
 */
export function useBookmarksListByAssetId(assetId: number) {
  const lists = useCustomAssetLists()
  return useMemo(
    () => lists?.data?.filter((list) => list.assetIds.includes(assetId)) ?? [],
    [lists, assetId]
  )
}

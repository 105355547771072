import { ChevronRightIcon } from '@gain/components/icons'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Card, { CardProps } from '../../../../common/card/card'
import Skeleton from '../../../../common/skeleton'
import { useSearchAPI } from '../../search-hooks'
import SearchResultItem from './search-result-item'

const MAX_SEARCH_RESULTS = 6

export const StyledContainer = styled(Card)<CardProps>(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: 'none',
}))

export default function SearchResults() {
  const [showAll, setShowAll] = useState(false)

  const searchApi = useSearchAPI()

  const handleShowMore = useCallback(() => {
    setShowAll(true)
  }, [])

  useEffect(() => {
    if (searchApi.searchingOther) {
      setShowAll(false)
    }
  }, [searchApi.searchingOther])

  const finalItems = useMemo(() => {
    if (searchApi.searchingOther) {
      return Array.from(new Array(MAX_SEARCH_RESULTS).fill(null))
    }

    return searchApi.result.items.slice(
      0,
      showAll ? searchApi.result.items.length : MAX_SEARCH_RESULTS
    )
  }, [searchApi.result.items, searchApi.searchingOther, showAll])

  return (
    finalItems.length > 0 && (
      <StyledContainer>
        <Skeleton enabled={searchApi.searchingOther}>
          <Typography
            sx={{ mb: 2 }}
            variant={'h5'}>
            More results
          </Typography>
        </Skeleton>

        {finalItems.map((item, index) => (
          <SearchResultItem
            key={item?.id || index}
            item={item}
          />
        ))}

        <Fade in={!searchApi.searchingOther}>
          <Stack sx={{ pt: 1 }}>
            {!showAll && searchApi.result.items.length > MAX_SEARCH_RESULTS && (
              <Skeleton enabled={searchApi.searchingOther}>
                <Button
                  color={'primary'}
                  endIcon={<ChevronRightIcon sx={{ ml: -1 }} />}
                  onClick={handleShowMore}
                  size={'small'}>
                  View more
                </Button>
              </Skeleton>
            )}
          </Stack>
        </Fade>
      </StyledContainer>
    )
  )
}

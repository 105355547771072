import { InvestorFundConfidence, InvestorFundFundraisingStatus } from './lib/rpc-shared-model'
import { Option } from './option'

export const INVESTOR_FUND_FUNDRAISING_STATUS_OPTIONS: ReadonlyArray<
  Option<InvestorFundFundraisingStatus>
> = [
  {
    label: 'Launched',
    value: InvestorFundFundraisingStatus.Launched,
  },
  {
    label: 'First close',
    value: InvestorFundFundraisingStatus.FirstClose,
  },
  {
    label: 'Final close',
    value: InvestorFundFundraisingStatus.FinalClose,
  },
  {
    label: 'Closed',
    value: InvestorFundFundraisingStatus.Closed,
  },
]

export const INVESTOR_FUND_CONFIDENCE_OPTIONS: ReadonlyArray<Option<InvestorFundConfidence>> = [
  {
    label: 'Reported',
    value: InvestorFundConfidence.Reported,
  },
  {
    label: 'Estimated',
    value: InvestorFundConfidence.Estimate,
  },
]

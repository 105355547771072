import { Components, Theme } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableRowClasses } from '@mui/material/TableRow'

export const MuiTableRow = (theme: Theme): Components['MuiTableRow'] => ({
  styleOverrides: {
    root: {
      [`&.${tableRowClasses.selected}, &.${tableRowClasses.selected} .${tableCellClasses.root}`]: {
        backgroundColor: '#f8fafe',
        [`&.${tableRowClasses.hover}:hover, &:hover .${tableCellClasses.root}`]: {
          backgroundColor: '#F1F5FF !important',
        },
      },
      [`&.${tableRowClasses.hover}`]: {
        cursor: 'pointer',
        [`&:hover, &:hover .${tableCellClasses.root}`]: {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    hover: {
      cursor: 'pointer',
      [`&:hover, &:hover .${tableCellClasses.root}`]: {
        backgroundColor: theme.palette.background.default,
      },
    },
    head: {
      backgroundColor: theme.palette.grey['100'],
    },
  },
})

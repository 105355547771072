import { AssetListItem } from '@gain/rpc/app-model'
import { Theme, useTheme } from '@mui/material/styles'
import {
  CustomSeriesRenderItemAPI,
  CustomSeriesRenderItemParams,
  CustomSeriesRenderItemReturn,
  XAXisOption,
  YAXisOption,
} from 'echarts/types/dist/shared'
import { keyBy } from 'lodash'
import { useMemo } from 'react'

import { ECOption } from '../../../common/echart/echart'
import { Axis, AxisConfig } from './axis/axis-config'
import { Bubble } from './chart-bubble'

const BUBBLE_OPACITY = 0.75
const BUBBLE_BLUR_OPACITY = 0.125
const BUBBLE_HIGHLIGHT_OPACITY = 0.925
export const DEFAULT_Y_AXIS_LABEL_SPACE = 56
export const DEFAULT_X_AXIS_LABEL_SPACE = 20

const Z_INDEX = {
  AXIS_LINES: -10,
  SPOTLIGHT_RIPPLES: -5,
  BUBBLES: 0,
  CLIPPING_RECTS: 4000,
  AXIS_LABELS: 5000,
}

/**
 * Generates the ECharts "option" configuration for the company bubble chart.
 *
 * The peculiarities of the chart config is explained in comments, but basic
 * knowledge of ECharts is required. If you're unfamiliar with the ECharts,
 * please refer to the ECharts documentation, particularly:
 * https://echarts.apache.org/en/option.html#series-custom.
 */
export default function useCompanyBubbleEChartOption(
  assets: AssetListItem[],
  bubbles: Bubble[],
  getColor: (asset: AssetListItem) => string,
  allVisible: boolean,
  highlightedAssets: AssetListItem[],
  xAxisConfig: AxisConfig,
  yAxisConfig: AxisConfig,
  height: number,
  spotlightAssetIds: number[]
) {
  const theme = useTheme()

  return useMemo((): ECOption => {
    // Get the color for a specific asset. The data of a custom series does not
    // allow us to pass the entire asset object, so we need to look up the color
    // based on the asset ID.
    const assetsById = keyBy(assets, 'id') // Create a map for O(1) lookup
    const spotlightAssetIdsSet = new Set(spotlightAssetIds) // Create a set for O(1) lookup

    const getColorByAssetId = (assetId: number) => {
      const asset = assetsById[assetId]
      return asset ? getColor(asset) : theme.palette.grey[400]
    }

    const getSpotlightColorByAssetId = (assetId: number) => {
      let assetColor = getColorByAssetId(assetId)
      if (assetColor === '#F5DD90') {
        assetColor = '#F2C42E' // slightly darker color for more contrast
      }

      return assetColor
    }

    // Prepare the data for the chart with the scaled size. We need the "name"
    // in Echarts data for it to recognize which bubble to animate (i.e. which
    // changed or were added).
    //
    // The "leaveTo" animation doesn't seem to work for series of type "custom",
    // so instead we render all bubbles and simply change the size to zero for
    // the ones that are not visible.
    const data = bubbles.map(({ assetId, size, x, y }) => ({
      name: `${assetId}`,
      value: [x, y, size, assetId],
    }))

    // Prepare the data for the spotlight effect.
    const spotlightData = bubbles
      .filter(({ assetId }) => spotlightAssetIdsSet.has(assetId))
      .map(({ x, y, size, assetId }) => ({
        name: `${assetId}`,
        value: [x, y, size, assetId],
      }))

    // Calculate the maximum size of the bubbles.
    const maxSize = Math.max(...bubbles.map(({ size }) => size ?? 0))

    // Calculate bottom and left padding for the grid based on the axis labels.
    const bottomOffset = xAxisConfig.calculateLabelSpace?.(Axis.X) ?? DEFAULT_X_AXIS_LABEL_SPACE
    const leftOffset = yAxisConfig.calculateLabelSpace?.(Axis.Y) ?? DEFAULT_Y_AXIS_LABEL_SPACE

    return {
      backgroundColor: theme.palette.background.paper, // For "Save as image" to avoid transparent background
      grid: {
        top: 0,
        right: 1, // 1 to avoid the border being clipped
        bottom: bottomOffset,
        left: leftOffset,
      },
      xAxis: [
        {
          // We want to lay out the x-axis as a numerical axis. We'll pre-process
          // the data on a scale of 0 to 100 to position the bubbles.
          type: xAxisConfig.scaleType,
          min: xAxisConfig.scaleMin,
          max: xAxisConfig.scaleMax,
          splitNumber: -1, // -1 removes the x-axis labels
          axisLine: {
            // Only show the axis line if the scale crosses the 0 point.
            show: yAxisConfig.scaleMin < 0 && yAxisConfig.scaleMax > 0,
            lineStyle: {
              color: theme.palette.grey[500],
            },
          },
          axisLabel: { show: false },
          axisTick: { show: false },
        } as XAXisOption, // Cast "as" to fix TS issue with dynamic xScaleType

        // Add labels as a separate axis, this gives us more control over the
        // positioning of the labels. In particular for bucket labels which are
        // aligned between axis ticks instead of directly on them.
        ...splitAxisForClipping(xAxisConfig.generateLabels(Axis.X) as XAXisOption),
      ],
      yAxis: [
        {
          type: yAxisConfig.scaleType,
          min: yAxisConfig.scaleMin,
          max: yAxisConfig.scaleMax,
          splitNumber: -1, // -1 removes the x-axis labels
          axisLine: {
            // Only show the axis line if the scale crosses the 0 point.
            show: xAxisConfig.scaleMin < 0 && xAxisConfig.scaleMax > 0,
            lineStyle: {
              color: theme.palette.grey[500],
            },
          },
          axisTick: { show: false },
          axisLabel: { show: false },
        } as YAXisOption, // Cast "as" to fix TS issue with dynamic xScaleType

        // Add labels as a separate axis, this gives us more control over the
        // positioning of the labels. In particular for bucket labels which are
        // aligned between axis ticks instead of directly on them.
        ...splitAxisForClipping(yAxisConfig.generateLabels(Axis.Y) as YAXisOption),
      ],
      series: [
        // Plot the actual data as bubbles. We use a custom series to draw the
        // bubbles, as the built-in scatter series does not support clipping.
        {
          data: data,
          type: 'custom',
          cursor: 'initial',
          clip: true,
          renderItem: (
            _: CustomSeriesRenderItemParams,
            api: CustomSeriesRenderItemAPI
          ): CustomSeriesRenderItemReturn => {
            const xValue = api.value(0)
            const yValue = api.value(1)
            const sizeValue = api.value(2)
            const assetId = api.value(3)
            const [x, y] = api.coord([xValue, yValue])

            // Make typescript happy, although this should never happen
            if (typeof assetId !== 'number' || typeof sizeValue !== 'number') {
              return null
            }

            // I spent a day to get this working with "downplay" and "highlight"
            // EChart actions, but there are persistent issues with the chart
            // not updating correctly or animating weirdly (especially for opacity).
            //
            // Doing it through React is less efficient but at least it works.
            const isHighlighted = highlightedAssets.some(({ id }) => id === assetId)
            const isBlurred = allVisible && highlightedAssets.length > 0 && !isHighlighted

            // Smaller bubbles are always on top of larger bubbles, otherwise they
            // can't be hovered/clicked upon. Additionally, highlighted bubbles are
            // on top of all other bubbles.
            let zIndex = Z_INDEX.BUBBLES + maxSize - sizeValue
            if (allVisible && isHighlighted) {
              zIndex += assets.length
            }

            // Determine color
            const color = getColorByAssetId(assetId)

            // Determine opacity
            let opacity: number
            if (sizeValue === 0) {
              opacity = 0
            } else if (isBlurred) {
              opacity = BUBBLE_BLUR_OPACITY
            } else {
              opacity = BUBBLE_OPACITY
            }

            return {
              type: 'circle',
              shape: {
                cx: x,
                cy: y,
                r: sizeValue,
              },
              style: {
                fill: color,
                opacity,
                stroke: spotlightAssetIdsSet.has(assetId) ? 'white' : undefined,
                lineWidth: spotlightAssetIdsSet.has(assetId) ? 1.5 : undefined,
              },

              // Sets the z-index of the bubble
              z2: zIndex,

              // On highlight, set the opacity to 100%
              emphasis: {
                style: {
                  opacity: BUBBLE_HIGHLIGHT_OPACITY,
                  fill: color, // Prevent ECharts from lightening the color
                },
              },

              // Settings to blur other bubbles when a bubble is highlighted.
              focus: 'self',
              blur: {
                style: {
                  opacity: BUBBLE_BLUR_OPACITY,
                },
              },

              // Don't use the animation for the initial render as it is too heavy
              // and only partially completes before the chart is visible.

              // Update size animation.
              transition: ['shape', 'style'],
              updateAnimation: {
                duration: 250,
              },
            }
          },
        },

        ...spotlightData.map((item) => ({
          type: 'effectScatter' as const,
          id: item.name,
          data: [item.value],
          symbolSize: 2 * item.value[2], // size value is radius, symbolSize is diameter
          itemStyle: {
            color: getSpotlightColorByAssetId(item.value[3]),
            opacity: 0, // Hide the item, only the ripple effect is shown
          },
          rippleEffect: {
            number: 1,
            period: 2,
            brushType: 'fill' as const,
            // The scale is exponential based on the size of the bubble, but with
            // a lower maximum to prevent the ripples from being too large.
            scale: 1 + 2.5 * Math.exp((-2 * item.value[2]) / maxSize),
          },
          z: Z_INDEX.SPOTLIGHT_RIPPLES, // behind all bubbles (see z-index reference at the top)
          silent: true, // Disable any mouse interaction
        })),

        // Fix clipping issue, see https://github.com/apache/echarts/issues/19993
        // Also, draw large rects behind the axis labels to clip the ripple effects
        drawClippingRect(theme, leftOffset - 1000, 0, 1000, height),
        drawClippingRect(theme, leftOffset, height - bottomOffset + 1, 10000, bottomOffset),
      ],
    }
  }, [
    xAxisConfig,
    yAxisConfig,
    height,
    bubbles,
    theme,
    assets,
    getColor,
    highlightedAssets,
    allVisible,
    spotlightAssetIds,
  ])
}

function drawClippingRect(theme: Theme, x: number, y: number, width: number, height: number) {
  return {
    data: [1],
    type: 'custom' as const,
    renderItem: (): CustomSeriesRenderItemReturn => ({
      type: 'rect',
      shape: {
        x,
        y,
        width,
        height,
      },
      silent: true, // Not clickable
      z2: Z_INDEX.CLIPPING_RECTS, // above all bubbles and ripples (see z-index reference at the top)
      style: {
        fill: theme.palette.background.paper,
      },
      blur: {
        style: {
          opacity: 1,
        },
      },
    }),
  }
}

/**
 * Splits the axis into two parts.
 * This is necessary because the axis labels are drawn on top of the bubbles
 * and clipping rects, but the axis lines should be behind everything.
 */
function splitAxisForClipping<T extends XAXisOption | YAXisOption>(config: T): T[] {
  return [
    {
      ...config,
      splitLine: { ...config.splitLine, show: true },
      axisLabel: { ...config.axisLabel, show: false },
      z: Z_INDEX.AXIS_LINES, // behind everything (see z-index reference at the top)
    },
    {
      ...config,
      splitLine: { ...config.splitLine, show: false },
      axisLabel: { ...config.axisLabel, show: true },
      z: Z_INDEX.AXIS_LABELS, // above clipping rects (see z-index reference at the top)
    },
  ]
}

import Typography from '@gain/components/typography'
import { getRatingColor } from '@gain/utils/investment-criteria'
import { alpha, styled } from '@mui/material/styles'

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'rating',
})<{ rating: number }>(({ theme, rating }) => {
  const backgroundColor = getRatingColor(rating, theme, 'background')
  const textColor = getRatingColor(rating, theme, 'foreground')

  return {
    display: 'inline-block',
    ...theme.typography.body2,
    lineHeight: '26px',
    color: textColor,
    backgroundColor: alpha(backgroundColor, 0.08),
    height: 26,
    padding: theme.spacing(0, 0.75),
    borderRadius: 4,
    maxWidth: '100%',
    boxSizing: 'border-box',
  }
})

export interface RatingLabelProps {
  rating: number
  children: string
}

export default function RatingLabel({ rating, children }: RatingLabelProps) {
  return (
    <StyledTypography
      rating={rating}
      noWrap>
      {children}
    </StyledTypography>
  )
}

import { isDefined } from '@gain/utils/common'
import { useTableRowHoverContext } from '@gain/utils/table'
import React, { useMemo } from 'react'

import RatingComponent from '../../../../apps/app/src/common/rating'
import { Rating } from './rating-model'

export interface RatingTableCellProps<Key extends string> {
  rating: number | null
  criteria: Pick<Rating<Key>, 'options' | 'suffix'>
  withHalfs?: boolean
}

// TODO: util libs should not contain components, move to components
export default function RatingTableCell<Key extends string>({
  rating,
  criteria,
  withHalfs = false,
}: RatingTableCellProps<Key>) {
  const hover = useTableRowHoverContext()

  const description = useMemo(() => {
    if (!rating) {
      return null
    }

    const label = criteria.options
      .filter(
        (item) => item.value === (withHalfs ? Math.round(rating * 2) / 2 : Math.round(rating))
      )
      .map((item) => item.label)
      .pop()

    return [label, criteria.suffix].filter(Boolean).join(' ')
  }, [rating, criteria.options, criteria.suffix, withHalfs])

  if (!isDefined(rating)) {
    return <>-</>
  }

  if (!hover) {
    return <RatingComponent value={rating} />
  }

  return <>{description}</>
}

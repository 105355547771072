import { ArticleListItem } from '@gain/rpc/app-model'
import { formatDate } from '@gain/utils/date'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import React, { FunctionComponent, useMemo } from 'react'

import VirtualTable, {
  createVirtualTableColumns,
  VirtualTableProps,
} from '../../../common/virtual-table'
import ArticleContentLink from '../../article/article-content-link'
import AssetContentLink from '../asset-content-link'

const tableAssetNewsClasses = generateUtilityClasses('TableAssetNews', ['date'])

const StyledVirtualTable = styled(
  VirtualTable as FunctionComponent<VirtualTableProps<ArticleListItem>>
)(({ theme }) => ({
  [`& .${tableAssetNewsClasses.date}`]: {
    color: theme.palette.text.secondary,
  },
}))

export interface TableAssetNewsProps {
  articles: ArticleListItem[]
  showCompanyColumn?: boolean
}

export function TableAssetNews({ articles, showCompanyColumn }: TableAssetNewsProps) {
  const columns = useMemo(
    () =>
      createVirtualTableColumns<ArticleListItem>(
        {
          headerName: 'Date',
          field: 'date',
          sortable: false,
          width: 108,
          cellClassName: tableAssetNewsClasses.date,
          valueFormatter: ({ value }) => formatDate(value, { format: 'monthYear' }),
        },
        {
          field: 'title',
          headerName: 'Title',
          width: 0,
          flex: 1,
          sortable: false,
          renderCell: ({ row }) => (
            <ArticleContentLink
              id={row.id}
              title={row.title}
            />
          ),
        },
        showCompanyColumn && {
          headerName: 'Company',
          field: 'highlightedAssetName',
          sortable: false,
          width: 280,
          renderCell: ({ row }) => (
            <AssetContentLink
              id={row.highlightedAssetId}
              logoFileUrl={row.highlightedAssetLogoFileUrl}
              name={row.highlightedAssetName}
            />
          ),
        }
      ),
    [showCompanyColumn]
  )

  return (
    <StyledVirtualTable
      columns={columns}
      RowComponentProps={{ hover: false }}
      rows={articles}
      variant={'inline'}
    />
  )
}

export default TableAssetNews

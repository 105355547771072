import { useCurrency } from '@gain/api/app/hooks'
import { AssetGeneralInfo, AssetListItem } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatDealFundingSeriesLabel } from '@gain/utils/deal'
import Grid from '@mui/material/Grid'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import FundingMetric from './funding-metric'

const fundingMetricKeys: (keyof AssetListItem)[] = [
  'totalDealFundingRaisedEur',
  'latestDealRoundType',
  'latestDealRoundSizeEur',
  'latestDealPreMoneyValuationEur',
  'latestDealPostMoneyValuationEur',
]

export function hasFundingMetrics(asset: AssetListItem) {
  return fundingMetricKeys.findIndex((key) => asset[key] !== null) > -1
}

export interface CardAssetFundingProps extends CardProps {
  asset: AssetListItem
  totalFunding?: AssetGeneralInfo['totalFunding']
  totalFundingCurrency?: AssetGeneralInfo['totalFundingCurrency']
}

export default function CardAssetFunding({
  asset,
  totalFunding,
  totalFundingCurrency,
  ...cardProps
}: CardAssetFundingProps) {
  const formatCurrency = useFormatCurrencyCallback()

  // The totalDealFundingRaisedEur is based on the deals we have.
  // Occasionally this totalDealFundingRaisedEur value may be clearly
  // inaccurate, yet we lack the necessary deal data for correction.
  // In such cases, analysts can use totalFunding as a manual override.
  const currency = useCurrency(totalFundingCurrency)
  const fundingOverride = totalFunding && currency ? totalFunding * currency.toEur : null

  return (
    <Card {...cardProps}>
      <CardHeader title={'Funding'} />
      <CardContent sx={{ pt: 1, pb: 2 }}>
        <Grid
          spacing={1.5}
          container>
          <FundingMetric
            label={'Total funding'}
            value={formatCurrency(fundingOverride || asset.totalDealFundingRaisedEur, {
              round: 0,
              emptyValue: '',
            })}
          />
          <FundingMetric
            label={'Latest funding round'}
            value={formatDealFundingSeriesLabel(asset.latestDealRoundType, {
              emptyValue: '',
              withUnknown: false,
            })}
            year={asset.latestDealRoundYear}
          />
          <FundingMetric
            label={'Latest round size'}
            value={formatCurrency(asset.latestDealRoundSizeEur, {
              round: 0,
              emptyValue: '',
            })}
            year={asset.latestDealRoundYear}
          />
          <FundingMetric
            label={'Latest pre-money valuation'}
            value={formatCurrency(asset.latestDealPreMoneyValuationEur, {
              round: 0,
              emptyValue: '',
            })}
            year={asset.latestDealPreMoneyValuationYear}
          />
          <FundingMetric
            label={'Latest post-money valuation'}
            value={formatCurrency(asset.latestDealPostMoneyValuationEur, {
              round: 0,
              emptyValue: '',
            })}
            year={asset.latestDealPostMoneyValuationYear}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

import { useAssetListItem } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { Asset } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import React, { useMemo } from 'react'

import NotMaintainedMessage from './not-maintained-message'
import ParentButton from './parent-button'

export function getSubsidiaryParentId(subsidiaryPath?: number[] | null) {
  if (!subsidiaryPath || subsidiaryPath.length < 2) {
    return null
  }

  return subsidiaryPath[subsidiaryPath.length - 2]
}

const noLongerMaintainedOwnerships = [AssetOwnershipType.Subsidiary, AssetOwnershipType.Bankrupt]

export function isNoLongerMaintained(asset?: Asset) {
  if (!asset || !asset.generalInfo) {
    return false
  }

  return noLongerMaintainedOwnerships.includes(asset.generalInfo.ownership)
}

export function useHasSubtitle(asset?: Asset) {
  return useMemo(() => {
    if (!asset) {
      return false
    } else if (getSubsidiaryParentId(asset?.subsidiaryPath)) {
      return true
    } else if (isNoLongerMaintained(asset)) {
      return true
    } else if (asset.description?.short) {
      return true
    }

    return false
  }, [asset])
}

interface AssetHeaderSubtitleProps {
  asset: Asset
}

export default function AssetHeaderSubtitle({ asset }: AssetHeaderSubtitleProps) {
  const parentId = getSubsidiaryParentId(asset.subsidiaryPath)
  const notMaintained = isNoLongerMaintained(asset)
  const swrParentAsset = useAssetListItem(parentId)
  const isBrowserExtension = useIsBrowserExtension()

  // Delay rendering until parent asset is loaded
  if (swrParentAsset.loading) {
    return null
  }

  // In the browser-extension, always show a basic message when the profile is
  // a subsidiary or no longer maintained. On other devices only show this
  // message when the profile is no longer maintained.
  if ((parentId && isBrowserExtension) || (!parentId && notMaintained)) {
    return <NotMaintainedMessage asset={asset}>No longer maintained.</NotMaintainedMessage>
  }

  // Show this message when the profile is a subsidiary
  if (parentId) {
    return (
      <NotMaintainedMessage asset={asset}>
        {notMaintained && 'No longer maintained. '}
        This is a subsidiary of <ParentButton parent={swrParentAsset.data} />
      </NotMaintainedMessage>
    )
  }

  // Fallback to the short description
  if (asset.description?.short) {
    return (
      <Typography
        color={'text.secondary'}
        variant={'body2'}
        noWrap>
        {asset.description.short}
      </Typography>
    )
  }

  return null
}

import { ChevronDownIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'

const MenuExpandIcon = styled(ChevronDownIcon, {
  shouldForwardProp: (prop) => prop !== 'flip',
})<{ open: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
  }),
  ...(open && {
    transform: 'rotate(180deg)',
  }),
}))

export default MenuExpandIcon

import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { MOBILE_SEARCH_EMPTY_PATH } from '../home-mobile'

export function useSearchActive() {
  const searchMatch = useRouteMatch(MOBILE_SEARCH_EMPTY_PATH)

  return useMemo(() => {
    return !!searchMatch
  }, [searchMatch])
}

import { BarChart2Icon, CompanyIcon, UsersIcon } from '@gain/components/icons'
import { AdvisorDealListItem, DealListItem } from '@gain/rpc/app-model'
import { ADVISOR_ADVISED_OPTIONS } from '@gain/utils/advisor'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  advisedOnFilter,
  advisor,
  checkboxList,
  createFilterMap,
  dealRoundType,
  dealStatus,
  dealType,
  FilterConfigArray,
  FilterModel,
  FilterTabConfig,
  filterValueGroup,
  filterValueItem,
  investor,
  option,
  rangeCurrency,
  rangeMonth,
  rangeMultiple,
  rangeNumber,
  region,
  sector,
  tag,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const DEAL_FILTER_FIELDS = [
  'announcementDate',
  'tagIds',

  // Deal target
  'region',
  'subsector',
  'revenueEur',
  'ebitdaEur',
  'ebitEur',
  'totalAssetsEur',
  'fte',

  // Deal metrics
  'fundingRoundType',
  'fundingRoundAmountRaised',
  'fundingRoundPreMoneyValuation',
  'fundingRoundPostMoneyValuation',
  'evEur',
  'equityEur',
  'evRevenueMultiple',
  'evEbitdaMultiple',
  'evEbitMultiple',

  'reasons',
  'dealStatus',
  'investorIds',
  'buyerInvestorIds',
  'sellerInvestorIds',
  'advisorIds',
  'buyerAdvisorIds',
  'sellerAdvisorIds',
] as const

export const DEAL_ADVISOR_FILTER_FIELDS = [...DEAL_FILTER_FIELDS, 'advised', 'advisedOn'] as const

export type DealFilterField = (typeof DEAL_FILTER_FIELDS)[number]
export type DealAdvisorFilterField = (typeof DEAL_ADVISOR_FILTER_FIELDS)[number]

const INVESTOR_TABS: FilterTabConfig<DealListItemWithoutUrls, DealFilterField> = {
  keepValueBetweenTabs: true,
  options: [
    { id: 'investorIds', label: 'Any' },
    { id: 'buyerInvestorIds', label: 'Buyer' },
    { id: 'sellerInvestorIds', label: 'Seller' },
  ],
}

const ADVISOR_TABS: FilterTabConfig<DealListItemWithoutUrls, DealFilterField> = {
  keepValueBetweenTabs: true,
  options: [
    { id: 'advisorIds', label: 'Any' },
    { id: 'buyerAdvisorIds', label: 'Buy-side' },
    { id: 'sellerAdvisorIds', label: 'Sell-side' },
  ],
}

// contains a few additional properties but lacks urls. Because we don't filter
// on urls we can simply omit them.
type DealListItemWithoutUrls = Omit<
  DealListItem,
  'webUrl' | 'gainProUrl' | 'assetWebUrl' | 'assetGainProUrl' | 'relevanceRank'
>

const DEFAULT_FILTERS: FilterConfigArray<DealListItemWithoutUrls, typeof DEAL_FILTER_FIELDS> = [
  rangeMonth('announcementDate', 'Date range'),

  tag('tagIds', 'Tags'),

  // Deal target
  region('region', 'Target region'),
  sector('subsector', 'Target sector'),
  rangeCurrency('revenueEur', 'Target revenue'),
  rangeCurrency('ebitdaEur', 'Target EBITDA'),
  rangeCurrency('ebitEur', 'Target EBIT'),
  rangeCurrency('totalAssetsEur', 'Target total assets'),
  rangeNumber('fte', 'Target FTEs'),

  // Deal metrics
  dealRoundType('fundingRoundType', 'Funding round'),
  rangeCurrency('fundingRoundAmountRaised', 'Money raised'),
  rangeCurrency('fundingRoundPreMoneyValuation', 'Pre-money valuation'),
  rangeCurrency('fundingRoundPostMoneyValuation', 'Post-money valuation'),
  rangeCurrency('evEur', 'EV'),
  rangeCurrency('equityEur', 'Equity value'),
  rangeMultiple('evRevenueMultiple', 'Revenue multiple'),
  rangeMultiple('evEbitdaMultiple', 'EBITDA multiple'),
  rangeMultiple('evEbitMultiple', 'EBIT multiple'),

  // Deal type
  dealType('reasons', 'Deal type'),

  // Deal type
  dealStatus('dealStatus', 'Status'),

  // Investors
  investor('investorIds', 'Investor', { tabs: INVESTOR_TABS }),
  investor('buyerInvestorIds', 'Investor (buyer)', { tabs: INVESTOR_TABS }),
  investor('sellerInvestorIds', 'Investor (seller)', { tabs: INVESTOR_TABS }),

  // Advisors
  advisor('advisorIds', 'Advisors', { tabs: ADVISOR_TABS }),
  advisor('buyerAdvisorIds', 'Advisor (buy-side)', { tabs: ADVISOR_TABS }),
  advisor('sellerAdvisorIds', 'Advisor (sell-side)', { tabs: ADVISOR_TABS }),
]

export const DEAL_FILTER_MAP = createFilterMap<DealListItem, typeof DEAL_FILTER_FIELDS>(
  ...DEFAULT_FILTERS
)

export const DEAL_ADVISOR_FILTER_MAP = createFilterMap<
  AdvisorDealListItem,
  typeof DEAL_ADVISOR_FILTER_FIELDS
>(
  ...DEFAULT_FILTERS,
  checkboxList<AdvisorDealListItem, 'advised'>(
    'advised',
    'Party advised',
    ADVISOR_ADVISED_OPTIONS.map((advisorAdvisedOption) =>
      option(advisorAdvisedOption.label, advisorAdvisedOption.value)
    ),
    { icon: UsersIcon }
  ),
  advisedOnFilter('advisedOn', 'Advisory activity')
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const DEAL_FILTER_MENU: DropdownMenuOptions<FilterConfig<DealListItem, DealFilterField>> = [
  DEAL_FILTER_MAP.announcementDate,
  DEAL_FILTER_MAP.tagIds,
  dropdownMenuGroup('financials', 'Deal metrics', {
    icon: BarChart2Icon,
    children: [
      DEAL_FILTER_MAP.fundingRoundType,
      DEAL_FILTER_MAP.fundingRoundAmountRaised,
      DEAL_FILTER_MAP.fundingRoundPreMoneyValuation,
      DEAL_FILTER_MAP.fundingRoundPostMoneyValuation,
      DEAL_FILTER_MAP.evEur,
      DEAL_FILTER_MAP.equityEur,
      DEAL_FILTER_MAP.evRevenueMultiple,
      DEAL_FILTER_MAP.evEbitdaMultiple,
      DEAL_FILTER_MAP.evEbitMultiple,
    ],
  }),
  dropdownMenuGroup('target', 'Deal target', {
    icon: CompanyIcon,
    children: [
      DEAL_FILTER_MAP.region,
      DEAL_FILTER_MAP.subsector,
      DEAL_FILTER_MAP.revenueEur,
      DEAL_FILTER_MAP.ebitdaEur,
      DEAL_FILTER_MAP.ebitEur,
      DEAL_FILTER_MAP.totalAssetsEur,
      DEAL_FILTER_MAP.fte,
    ],
  }),
  DEAL_FILTER_MAP.reasons,
  DEAL_FILTER_MAP.investorIds,
  DEAL_FILTER_MAP.advisorIds,
  DEAL_FILTER_MAP.dealStatus,
]

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const DEAL_ADVISOR_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<AdvisorDealListItem, DealAdvisorFilterField>
> = [
  DEAL_ADVISOR_FILTER_MAP.advised,
  DEAL_ADVISOR_FILTER_MAP.advisedOn,
  ...(DEAL_FILTER_MENU as DropdownMenuOptions<FilterConfig<AdvisorDealListItem, DealFilterField>>),
]

export const DEAL_DEFAULT_FILTERS: FilterModel<DealListItem, DealFilterField> = [
  filterValueGroup(filterValueItem('region')),
  filterValueGroup(filterValueItem('subsector')),
  filterValueGroup(filterValueItem('announcementDate')),
  filterValueGroup(filterValueItem('reasons')),
  filterValueGroup(filterValueItem('tagIds')),
]

export const DEAL_ADVISOR_DEFAULT_FILTERS: FilterModel<
  AdvisorDealListItem,
  DealAdvisorFilterField
> = [
  filterValueGroup(filterValueItem('advised')),
  filterValueGroup(filterValueItem('advisedOn')),
  filterValueGroup(filterValueItem('region')),
  filterValueGroup(filterValueItem('subsector')),
  filterValueGroup(filterValueItem('announcementDate')),
  filterValueGroup(filterValueItem('reasons')),
]

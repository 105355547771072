import { Asset } from '@gain/rpc/app-model'

export const hasBusiness = (asset: Asset) => {
  const hasCharts = !!asset.segmentation?.charts.length
  const hasDescription = !!asset.description?.atAGlance
  const hasFurtherInformation = !!asset.description?.furtherInformation
  const hasSegments = !!asset?.segmentation?.segments?.length

  return hasCharts || hasDescription || hasFurtherInformation || hasSegments
}

import { ListItemKey } from '@gain/rpc/list-model'
import { ChangeEvent, useCallback } from 'react'
import { useField } from 'react-final-form'

import CustomOutlinedInput from '../../../../common/form/custom-outlined-input'
import { FilterConfigText, FilterTextValue } from '../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../use-track-filter-event'

export interface FilterTextProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigText<Item, FilterField>
}

export default function FilterText<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterTextProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()

  const field = useField<FilterTextValue, HTMLInputElement, string>(path, {
    parse: (value) => (value.trim() === '' ? null : value),
    format: (value) => (value === null ? '' : value),
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      field.input.onChange(event)

      if (event.target.value) {
        trackEvent(`Filter ${filter.label} value change`, event.target.value, null, 500)
      }
    },
    [field.input, filter.label, trackEvent]
  )

  return (
    <CustomOutlinedInput
      onChange={handleChange}
      placeholder={filter.placeholder}
      value={field.input.value}
      autoFocus
    />
  )
}

import { UserListItem as UserListItemType } from '@gain/rpc/app-model'
import Autocomplete, {
  autocompleteClasses,
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete'
import React, { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import DialogSearch from '../../../../common/dialog/dialog-search'
import { MUI_AUTOCOMPLETE_RESET_REASONS } from '../../../../theme/components/mui-autocomplete'
import UserListItem from '../user-list-item/user-list-item'
import useRecommendedUsers from './use-recommended-users'

const StyledSearchContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 1.5),
}))

const StyledPaperComponent = styled('div')(({ theme }) => ({
  [`&.${autocompleteClasses.paper}`]: {
    backgroundColor: theme.palette.background.paper,

    [`& .${autocompleteClasses.option}`]: {
      backgroundColor: 'transparent',
      '&.Mui-focused': {
        backgroundColor: theme.palette.grey['100'],
      },
    },
  },
}))

interface ShareUserSearchProps {
  onSelect: (user: UserListItemType) => void
  excludedUserIds: number[]
}

export default function ShareUserSearch({ onSelect, excludedUserIds }: ShareUserSearchProps) {
  const [search, setSearch] = useState('')
  const data = useRecommendedUsers(search, excludedUserIds)
  const [text, setText] = useState('')
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)

  const resetState = () => {
    setAutocompleteOpen(false)
    setText('')
    setSearch('')
  }

  const handleChange = useCallback(
    (_, newValue: UserListItemType | null, reason: AutocompleteChangeReason) => {
      if (reason === 'selectOption' && newValue) {
        resetState()
        onSelect(newValue)
      }
    },
    [onSelect]
  )

  const debouncedSetSearch = useDebouncedCallback((newSearch: string) => {
    setSearch(newSearch)
  }, 250)

  const handleInputChange = useCallback(
    (_, newValue: string, reason: AutocompleteInputChangeReason) => {
      if (MUI_AUTOCOMPLETE_RESET_REASONS.includes(reason)) {
        return
      }
      setText(newValue)
      debouncedSetSearch(newValue)
    },
    [debouncedSetSearch]
  )

  return (
    <StyledSearchContainer>
      <Autocomplete<UserListItemType, false, false>
        filterOptions={(values) => values}
        forcePopupIcon={false}
        getOptionLabel={(user) => `${user.userId}`}
        inputValue={text}
        isOptionEqualToValue={(user, currentValue) => user.userId === currentValue.userId}
        loading={data.loading}
        noOptionsText={'No results found'}
        onChange={handleChange}
        onClose={() => setAutocompleteOpen(false)}
        onInputChange={handleInputChange}
        onOpen={() => setAutocompleteOpen(true)}
        open={autocompleteOpen}
        openOnFocus={true}
        options={data.data.items}
        renderInput={(props) => (
          <DialogSearch
            loading={data.loading}
            placeholder={'Enter name or email'}
            {...props}
          />
        )}
        renderOption={({ key, ...props }, user) => (
          <UserListItem
            key={user.userId}
            user={user}
            {...props}
          />
        )}
        renderTags={() => null}
        slots={{
          paper: StyledPaperComponent,
        }}
        value={null}
        autoSelect
        fullWidth
      />
    </StyledSearchContainer>
  )
}

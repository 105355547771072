import { useRpcClient } from '@gain/api/swr'
import { noop } from '@gain/utils/common'
import { isEqual, uniq } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DeepPartial } from 'react-hook-form/dist/types/utils'

import {
  AutocompleteFetchOptionsFn,
  AutocompleteFetchSuggestionsFn,
  AutocompleteMatchMode,
  AutocompleteOption,
  AutocompleteOptionsFetcherParams,
  FilterAutocompleteValue,
} from '../../filter-config/filter-config-model'
import { autocompleteFilterValue } from '../../to-filter-model'

export function useFetchAutocompleteOptions(fetchOptions: AutocompleteFetchOptionsFn) {
  const fetcher = useRpcClient()

  return useCallback(
    (input: string | null, value: number[] | null, signal?: AbortSignal) => {
      const optionsFetcher = (params: AutocompleteOptionsFetcherParams) =>
        fetcher({
          ...params,
          signal,
        })

      return fetchOptions(optionsFetcher, input, value)
    },
    [fetchOptions, fetcher]
  )
}

export function useAutocompleteOptions(
  input: string | null,
  value: number[] | null,
  fetchOptions: AutocompleteFetchOptionsFn
) {
  const fetch = useFetchAutocompleteOptions(fetchOptions)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const previousValue = useRef<{
    input: string | null
    value: number[] | null
  }>({ input: null, value: null })

  useEffect(() => {
    if (
      input === '' ||
      isEqual(previousValue.current, {
        input,
        value,
      })
    ) {
      return
    }
    setLoading(true)

    const controller = new AbortController()

    const timeoutId = setTimeout(() => {
      fetch(input, value, controller.signal)
        .then((response) => {
          setOptions(response)
          // trackEvent(`Filter ${filter.label} autocomplete search`, text, response.length, 500)
        })
        .catch((error) => {
          if (error instanceof DOMException) {
            return
          }
          setOptions([])
        })
        .finally(() => {
          setLoading(false)
          previousValue.current = {
            input: input,
            value: value,
          }
        })
    }, 250)

    return () => {
      setLoading(false)
      controller.abort()
      clearTimeout(timeoutId)
    }
  }, [input, setOptions, fetchOptions, fetch, value])

  return {
    value: options,
    loading,
  }
}

export function useAutocompleteSuggestions(
  value: number[],
  fetchSuggestions?: AutocompleteFetchSuggestionsFn
) {
  const fetcher = useRpcClient()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<AutocompleteOption[]>([])

  useEffect(() => {
    if (value.length === 0 || !fetchSuggestions) {
      setOptions([])
      return noop
    }

    setLoading(true)

    const controller = new AbortController()
    const signal = controller.signal

    const timeoutId = setTimeout(() => {
      const optionsFetcher = (params: AutocompleteOptionsFetcherParams) =>
        fetcher({
          ...params,
          signal,
        })

      fetchSuggestions(optionsFetcher, value)
        .then((response) => {
          setOptions(response)
        })
        .catch(() => {
          setOptions([])
        })
        .finally(() => {
          setLoading(false)
        })
    }, 250)

    return () => {
      setLoading(false)
      clearTimeout(timeoutId)
      controller.abort()
    }
  }, [fetcher, value, fetchSuggestions])

  return {
    value: options,
    loading,
  }
}

export function formatAutocompleteOptionLabel(option: AutocompleteOption) {
  if (typeof option.count === 'number') {
    return `${option.label} ${option.count}`
  }

  return option.label
}

export function mapValuesToOptions(
  values: number[] | undefined,
  options: AutocompleteOption[]
): AutocompleteOption[] {
  if (!values) {
    return []
  }

  return values.reduce((acc, current) => {
    const option = options.find((item) => item.value === current)
    if (!option) {
      return acc
    }

    return acc.concat(option)
  }, new Array<AutocompleteOption>())
}

export function autocompleteValue(
  partial: DeepPartial<FilterAutocompleteValue>
): FilterAutocompleteValue {
  return {
    include: Object.assign({ value: [], mode: 'all' }, partial?.include),
    exclude: Object.assign({ value: [], mode: 'all' }, partial?.exclude),
  }
}

/**
 * Returns an autocomplete filter value with the given valueToInclude
 * added to the include part of the value
 */
export function addIncludeToAutocompleteValue(
  filterValue: FilterAutocompleteValue | null,
  valueToInclude: number
): FilterAutocompleteValue {
  // If the autocomplete filter value is defined, add the item as included value
  if (filterValue) {
    return {
      ...filterValue,
      include: {
        ...filterValue.include,
        value: uniq(filterValue.include.value.concat(valueToInclude)),
      },
    }
  }

  // Otherwise create an autocomplete filter
  return {
    include: {
      value: [valueToInclude],
      mode: 'all',
    },
    exclude: {
      value: [],
      mode: 'all',
    },
  }
}

/**
 * This helper function creates an autocomplete filter. It's only purpose is to
 * reduce the boilerplate code.
 */
export function autocompleteIncludeFilterValue(
  value: number[] | undefined,
  mode: AutocompleteMatchMode = 'all'
): FilterAutocompleteValue {
  return autocompleteFilterValue({
    include: {
      value,
      mode,
    },
  })
}

import { autocompleteClasses, AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { filledInputClasses } from '@mui/material/FilledInput'
import { inputBaseClasses } from '@mui/material/InputBase'
import { listItemTextClasses } from '@mui/material/ListItemText'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { alpha, Components, Theme } from '@mui/material/styles'

import { palette } from '../palette'

export const MUI_AUTOCOMPLETE_RESET_REASONS: ReadonlyArray<AutocompleteInputChangeReason> = [
  'reset',
  'blur',
  'selectOption',
  'removeOption',
]

export const MuiAutocomplete = (theme: Theme): Components['MuiAutocomplete'] => ({
  styleOverrides: {
    root: {
      [`& .${filledInputClasses.root}`]: {
        paddingTop: 0,
        paddingLeft: 14,
        paddingRight: 14,
      },
      [`& .${outlinedInputClasses.root}.${inputBaseClasses.root}`]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
      [`& .${outlinedInputClasses.input}.${inputBaseClasses.inputSizeSmall} `]: {
        height: 32,
      },
    },
    noOptions: {
      ...theme.typography.body2,
      fontWeight: 'bold',
      padding: theme.spacing(2, 3),
    },
    loading: {
      ...theme.typography.body2,
      fontWeight: 'bold',
      padding: theme.spacing(2, 3),
    },
    listbox: {
      [`& .${autocompleteClasses.option}`]: {
        ...theme.typography.body2,
        paddingTop: 6,
        paddingRight: 8,
        paddingBottom: 6,
        paddingLeft: 8,
        [`&.${autocompleteClasses.focused}`]: {
          backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        borderRadius: 4,
        [`& > .${listItemTextClasses.root}`]: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      padding: theme.spacing(1),
    },
    paper: {
      margin: theme.spacing(1, 0),
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
      borderRadius: 8,
      border: `1px solid ${palette.divider}`,
      backgroundColor: theme.palette.grey[100],
    },
  },
})

import {
  createFilterModelQueryRoute,
  FilterModel,
  FilterValueGroup,
  filterValueGroup,
  filterValueItem,
} from '@app/features/filter/filter-bar'
import { AdvisorDealListItem } from '@gain/rpc/app-model'
import { addYears } from 'date-fns/addYears'
import { format } from 'date-fns/format'

// five years ago
const fiveYearsAgoWithNoDate = format(addYears(new Date(), -5), 'yyyy-MM')

export function createDealAdvisorFilterGroupForPastFiveYears(): FilterValueGroup<
  AdvisorDealListItem,
  'announcementDate'
> {
  return filterValueGroup(filterValueItem('announcementDate', [fiveYearsAgoWithNoDate]))
}

/**
 * Creates an advisor filter with the provided filters and adding one on the "announcementDate" to only include
 * data from the past 5 years
 */
export function createAdvisorDealListItemFilterModelForPastFiveYears(
  path: string,
  filters: FilterModel<AdvisorDealListItem> = []
): string {
  return createFilterModelQueryRoute<
    AdvisorDealListItem,
    'announcementDate' | keyof AdvisorDealListItem
  >(path, [...filters, createDealAdvisorFilterGroupForPastFiveYears()])
}

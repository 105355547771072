import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { Asset } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useRef } from 'react'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { ASSET_PAGE_BUSINESS_PATH, generateAssetPagePath, useAssetPageParams } from '../../../utils'
import { hasBusiness } from '../../asset-business'
import AssetBusinessDescription from './asset-business-description'
import AssetBusinessDetails from './asset-business-details'
import AssetTags from './asset-tags'

const StyledBrowserExtensionAssetBusinessDetails = styled(AssetBusinessDetails)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  columnGap: theme.spacing(2),
}))

interface AssetBusinessCardProps extends CardProps {
  asset: Asset
}

export default function AssetBusinessCard({ asset, sx, ...rest }: AssetBusinessCardProps) {
  const params = useAssetPageParams()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const isBrowserExtension = useIsBrowserExtension()

  const href = generateAssetPagePath(
    { id: asset.id, name: asset.generalInfo?.name, preview: params.preview },
    ASSET_PAGE_BUSINESS_PATH
  )

  const isHorizontalLayout = width > 560 && !!asset.description?.atAGlance

  return (
    <Card
      {...rest}
      ref={ref}
      href={hasBusiness(asset) ? href : undefined}
      sx={sx}>
      <CardHeader title={'Business'} />
      <CardContent
        sx={{
          flex: asset.description?.atAGlance ? 1 : 'initial',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}>
        <Stack
          direction={isHorizontalLayout ? 'row' : 'column'}
          spacing={isHorizontalLayout ? 5 : 0}
          sx={{ flex: 1, minHeight: 0, pb: isHorizontalLayout ? 2 : 0 }}>
          {!isBrowserExtension && (
            <>
              <AssetBusinessDescription
                asset={asset}
                disableAutoMaxLines={isHorizontalLayout || isXs}
                sx={{ mt: 0, flex: asset.description?.atAGlance ? 1 : 0 }}
              />

              <AssetBusinessDetails
                asset={asset}
                sx={{ py: isHorizontalLayout ? 0.5 : 2, flex: isHorizontalLayout ? 1 : 0 }}
              />
            </>
          )}

          {isBrowserExtension && <StyledBrowserExtensionAssetBusinessDetails asset={asset} />}
        </Stack>

        {!isBrowserExtension && asset.tags && asset.tags.length > 0 && (
          <AssetTags assetTags={asset.tags} />
        )}
      </CardContent>
    </Card>
  )
}

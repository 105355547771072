import { TextInput } from '@app/common/form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { noop } from 'lodash'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    minHeight: 0,
    maxWidth: 320,
  },
})

interface FormData {
  projectCode: string
}

const FormSchema = Yup.object().shape({
  projectCode: Yup.string()
    .min(1)
    .transform((value) => (typeof value === 'string' ? value.trim() : value))
    .required('Project code field is required')
    .default(null),
})

export type ProjectCodeDialogProps = DialogProps & {
  projectCode: string | null | undefined
  onProjectCodeChange?: (projectCode: string | null) => void
}

export default function ProjectCodeDialog({
  projectCode,
  onProjectCodeChange = noop,
  ...rest
}: ProjectCodeDialogProps) {
  const form = useForm<FormData>({
    defaultValues: {
      projectCode: projectCode || '',
    },
    resolver: yupResolver(FormSchema),
  })

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    const parsed = FormSchema.cast(formData)
    onProjectCodeChange(parsed.projectCode)
  }

  return (
    <FormProvider {...form}>
      <StyledDialog {...rest}>
        <DialogContent>
          <Stack
            alignItems={'center'}
            component={'form'}
            direction={'column'}
            onSubmit={form.handleSubmit(onSubmit)}
            spacing={3}>
            <Stack
              alignItems={'center'}
              direction={'column'}
              spacing={0.75}>
              <Typography variant={'h4'}>Project code</Typography>
              <Typography
                textAlign={'center'}
                variant={'body2'}>
                Enter your project code below to link this session to a specific project
              </Typography>
            </Stack>
            <TextInput
              name={'projectCode'}
              placeholder={'Enter code'}
              variant={'outlined'}
              disableHelperText
            />
            <Button
              color={'primary'}
              disabled={!form.formState.isDirty || !form.formState.isValid}
              type={'submit'}
              variant={'contained'}>
              {!projectCode ? 'Start session' : 'Update session'}
            </Button>
          </Stack>
        </DialogContent>
      </StyledDialog>
    </FormProvider>
  )
}

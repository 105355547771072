import { isDefined } from '@gain/utils/common'

import { formatYear } from './format-year'

export function formatYearAndQuarter(
  year: number | null | undefined,
  quarter: number | null | undefined
) {
  if (!isDefined(year)) {
    return null
  }

  if (isDefined(quarter) && quarter !== 4) {
    return [formatYear(year, 'short'), `Q${quarter}`].join(' ')
  }

  return formatYear(year, 'short')
}

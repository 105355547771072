import Typography from '@gain/components/typography'
import * as Shared from '@gain/rpc/shared-model'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

import useFinancialTextColor from '../use-financial-variant-color'
import { useFinancialResultsTableContext } from './financial-results-table-hooks'

export const StyledFinancialCell = styled('td', {
  shouldForwardProp: (prop) => !['paddingX', 'borderLeft'].includes(prop.toString()),
})<{ paddingX: number; borderLeft?: boolean }>(({ theme, paddingX, borderLeft }) => ({
  textAlign: 'right',
  height: 22,
  padding: theme.spacing(0, paddingX),

  ...(borderLeft && {
    borderLeft: `1px dashed ${theme.palette.divider}`,
  }),
}))

export interface FinancialCellProps {
  children: ReactNode
  amountType?: Shared.FinancialResultAmountType
  isForecast: boolean
  borderLeft?: boolean
  bold?: boolean
}

export default function FinancialCell({
  children,
  amountType,
  isForecast,
  borderLeft,
  bold,
}: FinancialCellProps) {
  const { cellPaddingX } = useFinancialResultsTableContext()
  const color = useFinancialTextColor(amountType, isForecast, bold)

  return (
    <StyledFinancialCell
      borderLeft={borderLeft}
      paddingX={cellPaddingX}>
      <Typography
        color={color}
        variant={'inherit'}
        noWrap>
        {children}
      </Typography>
    </StyledFinancialCell>
  )
}

import { useUpdateUserProfileCallback } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, { useState } from 'react'

import NewsPreferencesDialog from '../../../routes/home/news-preferences-dialog'
import { SettingContainer } from './setting-container'
import { SettingDescription } from './setting-description'
import { SettingDivider } from './setting-divider'
import { SettingLink } from './setting-link'
import { SettingTitle } from './setting-title'

export default function IndustryResearchUpdates() {
  const profile = useUserProfileContext()
  const updateProfile = useUpdateUserProfileCallback()
  const [newsDialogOpen, setNewsDialogOpen] = useState(false)

  return (
    <SettingContainer>
      <SettingTitle>Industry research updates</SettingTitle>
      <SettingDescription>
        <NewsPreferencesDialog
          onClose={() => setNewsDialogOpen(false)}
          open={newsDialogOpen}
        />
        Get notified about new industry reports{' '}
        <SettingLink onClick={() => setNewsDialogOpen(true)}>news preferences</SettingLink>.
      </SettingDescription>
      <SettingDivider />
      <FormControlLabel
        control={
          <Checkbox
            checked={profile.emailResearchUpdates}
            onChange={(event) => updateProfile({ emailResearchUpdates: event.target.checked })}
          />
        }
        label={'Email me new industry reports'}
      />
    </SettingContainer>
  )
}

import { RpcClientFetcher } from '@gain/api/swr'
import { AuthContextValue } from '@gain/modules/auth'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

import { LOGIN_PATH } from '../../routes/utils'
import { useTrackEvent } from '../google-analytics'

/**
 * useLogout returns a function that can be used to trigger a
 * client-side logout. It will reset auth tokens, localstorage
 * and redirect back to the login page.
 * By default, a server-side logout is not executed, use the
 * second argument to accomplish this.
 */
export default function useLogout(authContext: AuthContextValue, rpcClient: RpcClientFetcher) {
  const history = useHistory()
  const location = useLocation()
  const trackEvent = useTrackEvent()

  return useCallback(
    async (serverSideLogout = false) => {
      // Only perform server side logout when explicitly stated
      if (serverSideLogout) {
        try {
          await rpcClient({ method: 'account.logout' })
          trackEvent('Logout success', 'Auth')
        } catch (error) {
          trackEvent('Logout failed', 'Auth')
          throw error
        }
      }

      // Only perform client side logout when we're actually logged in
      // to prevent unnecessary logouts and redirects
      if (authContext.isAuthenticated()) {
        // Then execute a client-side logout
        authContext.stopSession()

        // Clear storage
        window.localStorage.clear()
        window.sessionStorage.clear()

        // Redirect user to login path if we aren't already there
        if (location.pathname !== LOGIN_PATH) {
          history.push(LOGIN_PATH, { from: location })
        }
      }
    },
    [authContext, location, rpcClient, trackEvent, history]
  )
}

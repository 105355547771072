import Button from '@mui/material/Button'
import { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { StyledDialog } from '../../common/dialog/styled-dialog'
import DialogHeader from '../../common/dialog-header'

const StyledMoveDialog = styled(StyledDialog)<DialogProps>({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 494,
  },
})

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}))

export interface BenchmarkingMovedInfoDialogProps {
  open: boolean
  onClose: () => void
  benchmarkingUrl: string
}

export default function BenchmarkingMovedInfoDialog({
  open,
  onClose,
  benchmarkingUrl,
}: BenchmarkingMovedInfoDialogProps) {
  const history = useHistory()

  const handleClick = () => {
    history.push(benchmarkingUrl)
    onClose()
  }

  return (
    <StyledMoveDialog
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={onClose}
        title={'New - Benchmarking'}
      />

      <DialogContent sx={{ mt: 2 }}>
        <Typography
          variant={'body2'}
          gutterBottom>
          Our new benchmarking feature allows you compare performance of companies, browsing through
          standard charts or creating custom charts using 50+ metrics. This can save you days of
          effort!
        </Typography>

        <Typography variant={'body2'}>
          A collection of frequently used charts has been made available through our "Chart
          library". Should you wish to explore further, you can set the x- and y axes to one of over
          50 metrics, giving you full control over your analysis. This allows you to leverage all of
          Gain.pro's data to create tailored insights and spot opportunities without having to spend
          days on identifying companies, finding and cleaning their financials, and creating a wide
          variety of views.
        </Typography>
      </DialogContent>

      <StyledDialogActions>
        <Button
          color={'info'}
          onClick={onClose}
          sx={{ width: 100 }}
          variant={'outlined'}>
          Close
        </Button>
        <Button
          color={'primary'}
          onClick={handleClick}
          sx={{ width: 130 }}
          variant={'contained'}>
          Try it now
        </Button>
      </StyledDialogActions>
    </StyledMoveDialog>
  )
}

import { isGermany } from '@gain/utils/countries'

import { LEGAL_ENTITY_EXTERNAL_ID_GERMAN_SEPARATOR } from './legal-entity-constants'

export function formatCourt(externalId: string, region: string) {
  if (isGermany(region)) {
    return externalId.split(LEGAL_ENTITY_EXTERNAL_ID_GERMAN_SEPARATOR)[0]
  }

  return ''
}

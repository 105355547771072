import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

const multiLineChartPointClasses = generateUtilityClasses('MultiLineChartPoint', ['visible'])

const StyledRoot = styled('g')(({ theme }) => ({
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
  }),
  opacity: 0,
  [`&.${multiLineChartPointClasses.visible}`]: {
    opacity: 1,
  },
}))

const StyledCircle = styled('circle', {
  shouldForwardProp: (prop) => prop !== 'estimate',
})<{ estimate?: boolean }>(({ theme, estimate }) => ({
  r: 4,
  strokeWidth: 1,

  ...(estimate && {
    fill: theme.palette.background.paper,
  }),
}))

const StyledPointLabel = styled('text')(({ theme }) => ({
  ...theme.typography.overline,
  fill: theme.palette.text.primary,
}))

export interface MultiLineChartPointProps {
  tooltipVisible?: boolean
  x: number
  y: number
  color: string
  label?: string
  tooltip?: string
  estimate?: boolean
}

export default function MultiLineChartPoint({
  tooltipVisible,
  x,
  y,
  color,
  label,
  estimate,
  tooltip,
}: MultiLineChartPointProps) {
  return (
    <StyledRoot className={multiLineChartPointClasses.visible}>
      <Tooltip
        open={tooltipVisible}
        placement={'top'}
        title={tooltip}>
        <StyledCircle
          cx={x}
          cy={y}
          estimate={estimate}
          fill={color}
          stroke={color}
        />
      </Tooltip>
      {label && (
        <StyledPointLabel
          dx={x}
          dy={y - 12}
          textAnchor={'middle'}>
          {label}
        </StyledPointLabel>
      )}
    </StyledRoot>
  )
}

import { BookmarksFilteredList } from '@gain/rpc/app-model'
import Badge, { badgeClasses } from '@mui/material/Badge'
import { styled, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'

const StyledBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    position: 'initial',
    transform: 'none',
  },
})

interface RecentFilterListItemUpdatesProps {
  list: BookmarksFilteredList | null
}
export default function RecentFilterListUpdatesBadge({ list }: RecentFilterListItemUpdatesProps) {
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (!list || list.updatedAssetCount === 0) {
    return null
  }

  if (isLgDown) {
    return (
      <StyledBadge
        badgeContent={list.updatedAssetCount}
        color={'error'}
        max={99}
      />
    )
  }

  return (
    <StyledBadge
      badgeContent={`${list.updatedAssetCount >= 100 ? '99+' : list.updatedAssetCount} updates`}
      color={'error'}
    />
  )
}

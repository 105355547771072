import { Components, Theme } from '@mui/material/styles'

export const MuiFormHelperText = (theme: Theme): Components['MuiFormHelperText'] => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      marginTop: theme.spacing(0.5),
      fontStyle: 'italic',
    },
    contained: {
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
})

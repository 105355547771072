import { ChevronLeftIcon } from '@gain/components/icons'
import { ListItemKey } from '@gain/rpc/list-model'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'

import { useFilterFieldContext } from '../filter/filter-field-context-provider'
import { FilterZoomInConfig } from '../filter-config/filter-config-model'

const StyledContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))

interface ZoomInFilterHeader<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  zoomInConfig?: FilterZoomInConfig<Item, FilterField>
}

/**
 * The ZoomInFilterHeader is used to allow the user to navigate back from a
 * "zoomed-in" filter. It is used to enable filtering on US-states from the
 * region filter. IT SHOULD NOT BE REUSED FOR ANYTHING ELSE!!!
 */
export default function ZoomInFilterHeader<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ zoomInConfig }: ZoomInFilterHeader<Item, FilterField>) {
  const form = useForm()
  const { resetValue, filterIdPath, config } = useFilterFieldContext()
  const isZoomInOptionActive = zoomInConfig?.options.some((option) => {
    return option.id === config.id
  })

  const handleClick = useCallback(() => {
    if (!zoomInConfig) {
      return
    }

    resetValue()
    form.change(filterIdPath, zoomInConfig.backId)
  }, [zoomInConfig, resetValue, form, filterIdPath])

  if (!zoomInConfig || !isZoomInOptionActive) {
    return null
  }

  return (
    <StyledContainer>
      <Button
        onClick={handleClick}
        startIcon={<ChevronLeftIcon color={'inherit'} />}
        sx={{ paddingLeft: 0 }}>
        {zoomInConfig.backLabel}
      </Button>
    </StyledContainer>
  )
}

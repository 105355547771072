import { useContext } from 'react'

import { HorizontalTableContext } from './horizontal-table-context'

export function useHorizontalTableContext() {
  const context = useContext(HorizontalTableContext)

  if (!context) {
    throw new Error('HorizontalTableContext not provided')
  }

  return context
}

import { isDefined } from '@gain/utils/common'

export interface FormatNumberLocaleOptions {
  round?: number
  emptyValue?: string
}

export function formatNumber(
  number: number | null | undefined,
  { emptyValue = '-', round = 0 }: FormatNumberLocaleOptions = {}
) {
  if (!isDefined(number)) {
    return emptyValue
  }

  return number.toLocaleString('en-EN', {
    minimumFractionDigits: round,
    maximumFractionDigits: round,
  })
}

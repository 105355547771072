import { Components, Theme } from '@mui/material/styles'

export const MuiDialogContent = (theme: Theme): Components['MuiDialogContent'] => ({
  styleOverrides: {
    root: {
      padding: theme.spacing(3),
    },
    dividers: {
      padding: 24,
    },
  },
})

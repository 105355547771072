import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { useUserProfileContext } from '@gain/modules/user'
import { RpcMethodMap } from '@gain/rpc/app-model'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'

import { useZendesk } from '../../zendesk'
import ExportContainer, { ExportContainerApi, ExportContainerProps } from './export-container'
import { ExportMethodMap, methodConfigMap, UNLIMITED_EXPORTS } from './export-method-config'

export type ExportListContainerMethod = keyof Pick<
  ExportMethodMap,
  | 'data.exportDeals'
  | 'data.exportInvestors'
  | 'data.exportAssets'
  | 'data.exportAdvisorDeals'
  | 'data.exportAdvisors'
>

export default function ExportListContainer<
  Method extends ExportListContainerMethod,
  Params extends RpcMethodMap[Method]['params']
>({ method, params, children, ...props }: ExportContainerProps<Method, Params>) {
  const userProfile = useUserProfileContext()
  const apiFetcher = useRpcClient()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { enableChat } = useZendesk()

  const config = methodConfigMap[method]

  const handleContactSupport = useCallback(() => {
    enableChat()
  }, [enableChat])

  const handleCheckMaxItemsPerExport = useCallback(
    (api: ExportContainerApi) => async (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()

      setLoading(true)

      const nrOfItems = await config.getNrOfItemsInExport?.(params, apiFetcher)

      setLoading(false)

      const downloadComplete = await api.download(event)

      const { maxItems } = config.getExportLimits(userProfile)
      if (
        downloadComplete &&
        maxItems &&
        nrOfItems &&
        maxItems > UNLIMITED_EXPORTS &&
        maxItems < nrOfItems
      ) {
        enqueueSnackbar(undefined, {
          key: 'export-max-items-per-export',
          content: () => (
            <Snackbar
              action={{
                onClick: handleContactSupport,
                title: 'Contact support',
              }}
              id={'export-max-items-per-export'}
              message={`The first ${maxItems} ${methodConfigMap[
                method
              ]?.pluralName.toLowerCase()} have been exported. Please contact us for additional export options.`}
            />
          ),
          preventDuplicate: true,
        })
      }

      return downloadComplete
    },
    [userProfile, apiFetcher, config, enqueueSnackbar, handleContactSupport, method, params]
  )

  // Check if the user has reached the export limit
  const { current, max } = config.getExportLimits(userProfile)
  const hasMaxExports = max !== UNLIMITED_EXPORTS && current >= max

  return (
    <ExportContainer
      method={method}
      params={params}
      {...props}>
      {(api) => (
        <>
          {children({
            loading: api.loading || loading,
            currentExports: current,
            maxExports: max,
            disabled: hasMaxExports,
            download: handleCheckMaxItemsPerExport(api),
          })}
        </>
      )}
    </ExportContainer>
  )
}

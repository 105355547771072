import Avatar, { avatarClasses, AvatarProps } from '@mui/material/Avatar'
import { alpha, styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

import { CombinedSearchType } from './object-list-item-formatters'

export const ObjectListItemAvatar = styled(Avatar)<
  AvatarProps & {
    type?: CombinedSearchType
    size?: 'default' | 'big'
  }
>(({ theme, type, size, src }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 8,
  width: size === 'big' ? 56 : 32,
  height: size === 'big' ? 56 : 32,

  ...(!src && {
    backgroundColor: theme.palette.grey[100],
    border: 'none',
  }),

  [`& .${svgIconClasses.root}`]: {
    color: alpha(theme.palette.text.secondary, 0.6),
    width: size === 'big' ? 20 : 15,
    height: size === 'big' ? 20 : 15,
  },

  ...(type === 'industry' && {
    border: 'none',

    [`& .${avatarClasses.img}`]: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      padding: 0,
    },
  }),
}))

import { FinancialResultPeriodicityType } from '@gain/rpc/shared-model'

import { FinancialResult } from './financial-result-model'

export function createEmptyFinancialResult(year: number): FinancialResult {
  return {
    year,
    periodicity: FinancialResultPeriodicityType.Annual,
    isForecast: false,
    revenue: null,
    revenueYoyGrowthPct: null,
    grossMargin: null,
    ebitda: null,
    ebit: null,
    consolidatedNetIncome: null,
    consolidatedNetIncomeYoyGrowthPct: null,
    earningsPerShare: null,
    earningsPerShareYoyGrowthPct: null,
    totalAssets: null,
    capex: null,
    freeCashFlow: null,
    freeCashFlowYoyGrowthPct: null,
    cashConversionCycle: null,
    debt: null,
    cash: null,
    netDebt: null,
    inventory: null,
    receivables: null,
    payables: null,
    capital: null,
    fte: null,
    fteGrowthPct: null,
    revenueFteRatio: null,
    netDebtEbitdaRatio: null,
    ebitdaMinusCapex: null,
    returnOnAssets: null,
    returnOnEquity: null,
    returnOnCapitalEmployed: null,
  }
}

import { DealListItem } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'

import ListItemDeal from './list-item-deal'
import { useAssetDealRows } from './table-deals-utils'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export interface ListDealsProps {
  deals: DealListItem[]
}

export default function ListDeals({ deals }: ListDealsProps) {
  const assetDeals = useAssetDealRows(deals)

  return (
    <StyledRoot>
      {assetDeals.data.map((assetDeal) => (
        <ListItemDeal
          key={assetDeal.id}
          deal={assetDeal}
        />
      ))}
    </StyledRoot>
  )
}

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import React, { useCallback } from 'react'

import { NAV_BAR_HEIGHT } from '../../layout/nav-bar'
import { PROFILE_TAB_BAR_STICKY_HEIGHT } from '../profile-tab-bar'

export interface ListNavigationProps {
  items: string[]
  activeItemIndex?: number
  onItemClick?: (index: number) => void
  spacingTop?: number
}

export const LIST_NAVIGATION_TOP_SPACING = NAV_BAR_HEIGHT + PROFILE_TAB_BAR_STICKY_HEIGHT + 16

const StyledListContainer = styled('div')({
  width: 200,
  minWidth: 200,
})

const StyledList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'spacingTop',
})<{ spacingTop: number }>(({ spacingTop }) => ({
  position: 'sticky',
  top: spacingTop,
}))

export default function ListNavigation({
  items,
  onItemClick,
  activeItemIndex,
  spacingTop = LIST_NAVIGATION_TOP_SPACING,
}: ListNavigationProps) {
  const handleOnClick = useCallback(
    (index: number) => () => {
      if (onItemClick) {
        onItemClick(index)
      }
    },
    [onItemClick]
  )

  return (
    <StyledListContainer>
      <StyledList
        spacingTop={spacingTop}
        dense
        disablePadding>
        {items.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={handleOnClick(index)}
            selected={activeItemIndex === index}
            sx={{ py: '1px' }}>
            <ListItemText>{item}</ListItemText>
          </ListItemButton>
        ))}
      </StyledList>
    </StyledListContainer>
  )
}

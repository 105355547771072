export function hasArrayItems<T>(items: Array<T> | undefined | null): items is Array<T> {
  if (items === undefined || items === null) {
    return false
  }

  return items.length > 0
}

// Sometimes we need a stable empty array as default value. This const can be
// used in that case.
export const EMPTY_ARRAY = []

import { createContext } from 'react'

export interface HorizontalTableContext {
  labelCellWidth: number
  minValueCellWidth: number
  maxValueCellWidth?: number
  valueCellHeight?: number
  cellPaddingX: number
  leftCellPaddingLeft: number
}

export const HorizontalTableContext = createContext<HorizontalTableContext | null>(null)

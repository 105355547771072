import { inputLabelClasses } from '@mui/material/InputLabel'
import { Components, Theme } from '@mui/material/styles'

export const MuiInputLabel = (theme: Theme): Components['MuiInputLabel'] => ({
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    outlined: {
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
      lineHeight: theme.typography.subtitle2.lineHeight,
      transform: 'unset',
      color: theme.palette.text.primary,
      [`&.${inputLabelClasses.shrink}`]: {
        transform: 'unset',
      },
      [`&.${inputLabelClasses.focused}:not(.${inputLabelClasses.error})`]: {
        color: theme.palette.text.primary,
      },
      [`&.${inputLabelClasses.disabled}`]: {
        color: theme.palette.text.primary,
      },
      marginTop: 0,
    },
    formControl: {
      position: 'initial',
    },
  },
})

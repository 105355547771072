import { format } from 'date-fns/format'
import { isSameDay } from 'date-fns/isSameDay'
import { isSameMonth } from 'date-fns/isSameMonth'
import { parseJSON } from 'date-fns/parseJSON'

const START_DATE_FORMAT = 'MMM d'
const END_DATE_FORMAT = 'd, yyyy'
const END_DATE_DIFFERENT_MONTH_FORMAT = `MMM ${END_DATE_FORMAT}`

export function formatDateRange(start: string | Date, end: string | Date): string {
  const startDate = start instanceof Date ? start : parseJSON(start)
  const endDate = end instanceof Date ? end : parseJSON(end)

  if (isSameDay(startDate, endDate)) {
    return format(endDate, END_DATE_DIFFERENT_MONTH_FORMAT)
  }

  const sameMonth = isSameMonth(startDate, endDate)
  const joinSeparator = sameMonth ? '-' : ' - '

  return [
    format(startDate, START_DATE_FORMAT),
    format(endDate, sameMonth ? END_DATE_FORMAT : END_DATE_DIFFERENT_MONTH_FORMAT),
  ].join(joinSeparator)
}

import { useCallback, useRef } from 'react'

import { useTrackEvent } from '../../google-analytics'
import { useFilterBarContext } from './filter-bar/filter-bar-context'

export function useTrackFilterEvent() {
  const context = useFilterBarContext()
  const trackEvent = useTrackEvent()
  const timeoutIdRef = useRef<number | undefined>()

  return useCallback(
    (action: string, label: string, value?: number | null, debounceMs?: number) => {
      const handleTrackEvent = () =>
        trackEvent(action, context.gaCategory, label, value !== null ? value : undefined)

      if (debounceMs) {
        clearTimeout(timeoutIdRef.current)
        timeoutIdRef.current = window.setTimeout(handleTrackEvent, debounceMs)
        return
      }

      handleTrackEvent()
    },
    [context.gaCategory, trackEvent]
  )
}

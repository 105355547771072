import { isObject } from 'lodash'
import { ComponentType } from 'react'

export interface DropdownMenuOption {
  id: string
  label: string
  searchAliases?: string[]
  icon?: ComponentType
}

export interface DropdownMenuGroupType<T extends DropdownMenuOption>
  extends Omit<DropdownMenuOption, 'searchAliases'> {
  children: (T | DropdownMenuGroupType<T>)[]
}

/**
 * A dropdown menu option can either be a single item or a group of items.
 */
export type DropdownMenuOptionType<T extends DropdownMenuOption> = T | DropdownMenuGroupType<T>

export type DropdownMenuOptions<T extends DropdownMenuOption> = DropdownMenuOptionType<T>[]

export function dropdownMenuGroup<T extends DropdownMenuOption>(
  id: string,
  label: string,
  options: Omit<DropdownMenuGroupType<T>, 'id' | 'label' | 'searchAliases'>
): DropdownMenuGroupType<T> {
  return { id, label, ...options }
}

export function isDropdownMenuGroup<T extends DropdownMenuOption>(
  item: DropdownMenuOptionType<T>
): item is DropdownMenuGroupType<T> {
  return isObject(item) && 'children' in item
}

export function isDropdownMenuItem<T extends DropdownMenuOption>(
  item: DropdownMenuOptionType<T>
): item is T {
  return !isDropdownMenuGroup(item)
}

import { useSessionId } from '@gain/modules/auth'
import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { List } from '@gain/rpc/list-model'
import { useCallback, useMemo } from 'react'
import { SWRConfiguration } from 'swr'
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite'

import { AppListParams, useFormatAppListSwrParams } from './use-app-list-swr'

export interface AppSwrInfiniteResponse<Item extends object, FilterItem extends Item, Error = any>
  extends SWRInfiniteResponse<List<Item, FilterItem>, Error> {
  isLoadingInitial: boolean
  isLoadingMore: boolean
  isEmpty: boolean
  isReachingEnd: boolean
  fetchMore: () => void
}

export function useAppInfiniteListSwr<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  Error = any
>(
  method: Method,
  params?: AppListParams<Method, FilterItem>,
  options?: SWRConfiguration
): AppSwrInfiniteResponse<Item, FilterItem, Error> {
  const token = useSessionId()
  const formattedParams = useFormatAppListSwrParams(params)

  const getKey = useCallback(
    (pageIndex: number, previousPageData: List<ListMethodMap[Method]>) => {
      if (previousPageData && !previousPageData.items.length) {
        return null // reached the end
      }

      return {
        method,
        token,
        params: {
          ...formattedParams,

          page: pageIndex,
        },
      }
    },
    [method, formattedParams, token]
  )

  const result = useSWRInfinite<List<Item, FilterItem>, Error>(getKey, {
    revalidateFirstPage: false, // If true it refreshes the first page on fetchMore
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
    ...options,
  })

  return useMemo(() => {
    const { data, size, error } = result
    const isLoadingInitial = !data && !error
    const isLoadingMore =
      isLoadingInitial || (size > 0 && !!data && typeof data[size - 1] === 'undefined')
    const isEmpty = data?.[0]?.items.length === 0

    const nrOfItems = data ? data[0]?.counts.filtered : 0
    const nrOfLoadedItems = data ? data.reduce((acc, item) => acc + item.items.length, 0) : 0
    const isReachingEnd = isEmpty || nrOfLoadedItems >= nrOfItems

    const fetchMore = async () => {
      if (!isLoadingMore && !isReachingEnd) {
        await result.setSize(result.size + 1)
      }
    }

    return {
      ...result,
      isLoadingInitial,
      isLoadingMore,
      isEmpty,
      isReachingEnd,
      fetchMore,
    }
  }, [result])
}

import { ReactElement, ReactNode } from 'react'

export interface ConditionalWrapperProps {
  condition: boolean | undefined
  wrapper: (children: ReactNode) => ReactElement
  wrapperWhenFalse?: (children: ReactNode) => ReactElement
  children: ReactElement | ReactNode
}

export default function ConditionalWrapper({
  condition,
  wrapper,
  wrapperWhenFalse,
  children,
}: ConditionalWrapperProps) {
  if (condition) {
    return wrapper(children)
  } else if (wrapperWhenFalse) {
    return wrapperWhenFalse(children)
  }

  return children
}

import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import { ReactNode } from 'react'

export interface VirtualTableHeadRowProps {
  width: number
  children: ReactNode
}

export const VirtualTableHeadRow = styled(
  (props) => (
    <TableRow
      component={'div'}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => !['width'].includes(prop as string),
  }
)<VirtualTableHeadRowProps>(({ width }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: width,
  minWidth: width,
  maxWidth: width,
}))

import Typography from '@gain/components/typography'
import * as Shared from '@gain/rpc/shared-model'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

import useFinancialVariantColor from '../../../../features/financial/use-financial-variant-color'
import { ValueFormatterFn } from './use-financial-rows'

function financialVariantTooltip(
  amountType: Shared.FinancialResultAmountType,
  isForecast: boolean
) {
  if (isForecast) {
    return 'Forecast'
  }

  switch (amountType) {
    case Shared.FinancialResultAmountType.Estimated:
      return 'Estimate'
    case Shared.FinancialResultAmountType.Ai:
      return 'AI estimate'
    default:
      return ''
  }
}

interface FinancialCellProps {
  value: number | null
  amountType: Shared.FinancialResultAmountType
  isForecast: boolean
  formatFn: ValueFormatterFn
}

export default function FinancialCell({
  value,
  amountType,
  isForecast,
  formatFn,
}: FinancialCellProps) {
  const color = useFinancialVariantColor(amountType, isForecast)

  if (value === null) {
    return <span style={{ color }}>-</span>
  }

  return (
    <Tooltip
      title={financialVariantTooltip(amountType, isForecast)}
      disableInteractive>
      <Typography
        color={color}
        variant={'inherit'}
        noWrap>
        {formatFn(value, amountType !== Shared.FinancialResultAmountType.Actual)}
      </Typography>
    </Tooltip>
  )
}

import Typography from '@gain/components/typography'
import { isNotDefined } from '@gain/utils/common'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { styled } from '@mui/material/styles'
import React from 'react'

export interface FinancialRangeValueProps {
  min?: number | null
  max?: number | null
  dataCurrency?: string | null
}

const StyledRoot = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 6,
})

export default function FinancialRangeValue({ min, max, dataCurrency }: FinancialRangeValueProps) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  if (isNotDefined(min) || isNotDefined(max)) {
    return <>-</>
  }

  return (
    <StyledRoot>
      <Typography
        color={'text.primary'}
        variant={'body2'}
        noWrap>
        {formatCurrencyRange(min, max, { dataCurrency })}
      </Typography>
    </StyledRoot>
  )
}

import { Route, Switch } from 'react-router-dom'

import RouteForgotPassword from './forgot-password'
import RouteLogin from './login'
import RouteOnboarding from './onboarding'
import { PrivateRoute } from './private-route'
import { PrivateRoutes } from './private-routes'
import RouteResetPassword from './reset-password'
import RouteSsoCallback from './sso-callback'
import RouteUnsubscribe from './unsubscribe'
import {
  EMAIL_UNSUBSCRIBE_PATH,
  FORGOT_PASSWORD_PATH,
  FORGOT_PASSWORD_RESET_PATH,
  LOGIN_FULL_PATH,
  ONBOARDING_PATH,
  SSO_CALLBACK_PATH,
} from './utils'

export function MainRoutes() {
  return (
    <Switch>
      <Route
        component={RouteOnboarding}
        path={ONBOARDING_PATH}
        exact
      />
      <Route
        component={RouteForgotPassword}
        path={FORGOT_PASSWORD_PATH}
        exact
      />
      <Route
        component={RouteResetPassword}
        path={FORGOT_PASSWORD_RESET_PATH}
        exact
      />
      <Route
        component={RouteUnsubscribe}
        path={EMAIL_UNSUBSCRIBE_PATH}
        exact
      />
      <Route
        component={RouteLogin}
        path={LOGIN_FULL_PATH}
        exact
      />
      <Route
        component={RouteSsoCallback}
        path={SSO_CALLBACK_PATH}
        exact
      />
      <PrivateRoute
        component={PrivateRoutes}
        path={'/'}
      />
    </Switch>
  )
}

import AssetTooltip, { AssetTooltipProps } from './asset-tooltip'

export type AssetTooltipWrapperProps = Omit<AssetTooltipProps, 'id'> & {
  id?: AssetTooltipProps['id'] | null
  disableTooltip?: boolean
}

export default function AssetTooltipWrapper({
  id,
  disableTooltip,
  children,
  ...tooltipProps
}: AssetTooltipWrapperProps) {
  if (typeof id === 'number' && !disableTooltip) {
    return (
      <AssetTooltip
        id={id}
        {...tooltipProps}>
        {children}
      </AssetTooltip>
    )
  }

  return children
}

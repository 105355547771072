import { isDefined } from '@gain/utils/common'

interface FormatSecondsOptions {
  emptyValue?: string
}

export const formatSeconds = (
  value: number | null,
  { emptyValue = '-' }: FormatSecondsOptions = {}
): string => {
  if (!isDefined(value)) {
    return emptyValue
  }

  // Remove fractional part
  const secondsInteger = Math.trunc(value)

  const hours = Math.floor(secondsInteger / 3600)
  const minutes = Math.floor(secondsInteger / 60) % 60
  const seconds = secondsInteger % 60

  return [hours, minutes, seconds].map((part) => part.toString(10).padStart(2, '0')).join(':')
}

import { FinancialResultPeriodicityType } from '@gain/rpc/shared-model'
import { FinancialResult } from '@gain/utils/financials'

import InfoButton from '../../../common/info-button'

export interface FinancialResultsHeaderCellProps {
  financial: FinancialResult
}

export default function FinancialResultsHeaderCell({ financial }: FinancialResultsHeaderCellProps) {
  let label = financial.year.toString(10)
  let description: string | null = null

  // Add 'F' when result is a forecast (e.g. '2023F')
  if (financial.isForecast) {
    label += 'F'
  }

  if (financial.periodicity === FinancialResultPeriodicityType.LastTwelveMonths) {
    // Overwrite label with LTM and description when periodicity is last twelve months
    label = 'LTM'
    description = 'Quarterly rolling'
  } else if (financial.periodicity === FinancialResultPeriodicityType.NextTwelveMonths) {
    // Overwrite label with NTM and description when periodicity is next twelve months
    label = 'NTM'
    description = 'Updated weekly based on latest broker estimates'
  }

  // Render label, show info button with description when provided and
  // add a "Last deal" chip below when applicable
  return (
    <>
      {label}
      {description !== null && (
        <InfoButton
          dialogMessage={description}
          dialogTitle={label}
        />
      )}
    </>
  )
}

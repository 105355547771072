import { DealReason, DealStatus } from '@gain/rpc/shared-model'

import { dealReasons } from './deal-reasons'

export interface DealPrefix {
  type: 'buyer' | 'seller'
  text: 'Acquired' | 'Sold'
  rumor: 'Share'
}

export const BUY_PREFIX: DealPrefix = {
  type: 'buyer',
  text: 'Acquired',
  rumor: 'Share',
}
export const SELL_PREFIX: DealPrefix = {
  type: 'seller',
  text: 'Sold',
  rumor: 'Share',
}

export type DealPartyType = 'sell' | 'buy'

export function isDealLive(status: DealStatus | null) {
  return status === 'live'
}

export function getDealReasonLabel(value: DealReason | null) {
  return dealReasons.find((type) => type.value === value)?.label || null
}

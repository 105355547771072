import { InvestorFundNote } from '@gain/rpc/app-model'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'

import DialogHeader from '../../../common/dialog-header'

const StyledUl = styled('ul')({
  margin: 0,
  padding: '0 1em',
})

const StyledLi = styled('li')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(1),
  ':first-of-type': {
    paddingTop: 0,
  },
}))

export interface FundNotesDialogProps {
  open: boolean
  onClose: () => void
  notes: InvestorFundNote[]
}

export default function FundNotesDialog({ open, onClose, notes }: FundNotesDialogProps) {
  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={onClose}
        title={'Key takeaways'}
      />
      <DialogContent>
        <StyledUl>
          {notes.map((note, index) => (
            <StyledLi key={index}>{note.text}</StyledLi>
          ))}
        </StyledUl>
      </DialogContent>
    </Dialog>
  )
}

import { RpcClientResult } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/app-model'
import { ListFilterOperators, ListItemKey } from '@gain/rpc/list-model'
import { ComponentType, ReactNode } from 'react'

export type OptionValueType = string | number

export interface Option<ValueType extends OptionValueType> {
  type: 'option'
  label: string
  value: ValueType
  explainer?: string
}

export interface OptionGroup<ValueType extends OptionValueType> {
  type: 'option-group'
  label: string
  expanded?: boolean
  options: Array<Option<ValueType> | OptionGroup<ValueType>>
}

/**
 * FilterSelectConfig allows grouping a number of distinct filters together and
 * visually in a dropdown.
 */
export interface FilterSelectConfig<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  header: string
  options: FilterOption<Item, FilterField>[]
}

/**
 * FilterTabConfig allows grouping a number of distinct filters together and
 * visually toggle them with tabs.
 */
export interface FilterTabConfig<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  // keepValueBetweenTabs copies the value from the previous tab to the new filter.
  // This only works if all tabs have the same filter type.
  keepValueBetweenTabs?: boolean
  options: FilterOption<Item, FilterField>[]
}

/**
 * FilterSwitchConfig allows grouping a 2 distinct filters together and
 * visually in a toggle slider.
 */
export interface FilterSwitchConfig<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  header: string
  explainer?: ReactNode
  options: FilterOption<Item, FilterField>[]
}

export interface FilterOption<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  label: string
  id: FilterField
  checked?: boolean
}

/**
 * FilterZoomInConfig allows zooming in and out on filters together visually
 * in a forward and back link. This functionality supports a one time hacky way
 * to enable filtering on US-states from the region filter. THIS FUNCTIONALITY
 * SHOULD NOT BE REUSED!!!
 */
export interface FilterZoomInConfig<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  backId: string
  backLabel: string
  options: FilterZoomInOption<Item, FilterField>[]
}

export interface FilterZoomInOption<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterOption<Item, FilterField> {
  value?: string
}

interface FilterConfigBase<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  id: FilterField
  type: string
  label: string
  icon?: ComponentType
  hint?: string
  disableDelete?: boolean

  // Facilitates switching between filters via a tab bar. Make sure
  // each filter in the tab bar uses the same config.
  tabs?: FilterTabConfig<Item, ListItemKey<Item>>

  // Facilitates zooming in on filters via a link and a back link to zoom out.
  // Make sure each filter in the forwards uses the same config.
  // This is a one time hack to enable filtering on US-states from the region
  // filter. THIS FUNCTIONALITY SHOULD NOT BE REUSED!!!
  zoomIn?: FilterZoomInConfig<Item, ListItemKey<Item>>

  // Facilitates switching between filters via a dropdown. Make sure
  // each filter in the dropdown uses the same config.
  select?: FilterSelectConfig<Item, ListItemKey<Item>>

  // Facilitates switching between filters via a toggle slider. Make sure
  // each filter in the dropdown uses the same config and that there are only 2 filters
  switch?: FilterSwitchConfig<Item, ListItemKey<Item>>
}

export type AutocompleteOptionsFetcherParams<
  Method extends keyof RpcMethodMap = keyof RpcMethodMap,
  Params extends RpcMethodMap[Method]['params'] = RpcMethodMap[Method]['params']
> = {
  method: Method
  params: Params
}

export type AutocompleteOptionsFetcher = <Method extends keyof RpcMethodMap = keyof RpcMethodMap>(
  options: AutocompleteOptionsFetcherParams<Method>
) => RpcClientResult<RpcMethodMap, Method>

export interface AutocompleteOption {
  label: string
  value: number
  count?: number
}

export type AutocompleteFetchOptionsFn = (
  fetcher: AutocompleteOptionsFetcher,
  search: string | null,
  value: number[] | null
) => Promise<AutocompleteOption[]>

export type AutocompleteFetchSuggestionsFn = (
  fetcher: AutocompleteOptionsFetcher,
  value: number[]
) => Promise<AutocompleteOption[]>

export interface FilterConfigAutocomplete<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'autocomplete'
  singularName: string
  pluralName: string
  fetchOptions: AutocompleteFetchOptionsFn
  fetchSuggestions?: AutocompleteFetchSuggestionsFn
  disableToggleIncludeMode?: boolean
  disableToggleMatchMode?: boolean
}

/**
 * There are two matching modes for the autocomplete filter:
 * - 'all': Only items matching all specified values will be included.
 * - 'any': Items matching any one of the specified values will be included.
 */
export type AutocompleteMatchMode = 'any' | 'all'

export interface FilterAutocompleteOptionsValue {
  value: number[]
  mode: AutocompleteMatchMode
}

export type FilterAutocompleteValue = {
  include: FilterAutocompleteOptionsValue
  exclude: FilterAutocompleteOptionsValue
} | null

export interface FilterConfigCheckbox<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'checkbox'
  hint?: string
}

export type FilterCheckboxValue = boolean | null

export type FilterCheckboxListOption<ValueType extends OptionValueType> =
  | Option<ValueType>
  | OptionGroup<ValueType>

export type FilterCheckboxListOptions<ValueType extends OptionValueType> = Array<
  FilterCheckboxListOption<ValueType>
>

export interface FilterConfigCheckboxList<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends OptionValueType = OptionValueType
> extends FilterConfigBase<Item, FilterField> {
  type: 'checkbox-list'
  options: FilterCheckboxListOptions<ValueType>
  searchable?: boolean
}

export type FilterCheckboxListValue<ValueType extends OptionValueType = OptionValueType> =
  | ValueType[]
  | null

export type FilterRangeOperator = '>=' | '<='
export const FILTER_RANGE_MIN_OPERATOR = '>='
export const FILTER_RANGE_MAX_OPERATOR = '<='
export const FILTER_RANGE_OPERATORS = new Array<ListFilterOperators>(
  FILTER_RANGE_MIN_OPERATOR,
  FILTER_RANGE_MAX_OPERATOR
)

export type FilterRangeValueType = 'number' | 'month' | 'date'

export interface FilterConfigRange<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends FilterRangeValueType = FilterRangeValueType
> extends FilterConfigBase<Item, FilterField> {
  type: 'range'
  valueType: FilterRangeValueType
  maxFilterField?: ValueType extends 'date' ? ListItemKey<Item> : never
  enableReverseMinMax?: boolean
  placeholder?: ValueType extends 'number' ? string | [string, string] : never
  prefix?: ValueType extends 'number' ? string : never
  suffix?: ValueType extends 'number' | 'currency' ? string : never
  min?: ValueType extends 'number' ? number : never
  max?: ValueType extends 'number' ? number : never
  step?: ValueType extends 'number' ? number : never
  options?: ValueType extends 'number' ? Array<Option<number>> : never
}

export type FilterRangeValuePart<ValueType extends FilterRangeValueType = FilterRangeValueType> =
  ValueType extends 'number' ? number | null : string | null
export type FilterRangeValue<ValueType extends FilterRangeValueType = FilterRangeValueType> =
  | [FilterRangeValuePart<ValueType>, FilterRangeValuePart<ValueType>]
  | null

export interface FilterConfigRangeCurrency<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'range-currency'
  placeholder?: [string, string]
  isMillions?: boolean
  maxFilterField?: ListItemKey<Item>
  enableReverseMinMax?: boolean
}

export type FilterCurrencyRangeValue = [number | null, number | null] | null

export interface FilterConfigText<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'text'
  placeholder?: string
}

export type FilterTextValue = string | null

export interface FilterConfigGeoPoint<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'geo-point'
}

export type FilterGeoPointValue = {
  lon: number
  lat: number
  distance: number
  location: string
  googlePlaceId: string
} | null

export interface FilterConfigGeoPolygon<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'geo-polygon'
}

export type FilterGeoPolygonValue = {
  formatted: string
  placeId: string
}

export interface FilterConfigCity<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'city'
  regionField: ListItemKey<Item> | null
  countryCodeField: ListItemKey<Item>
}

export interface FilterCityItem {
  city: string
  region: string | null
  countryCode: string
}

export type FilterCityValue = Array<FilterCityItem> | null

export interface FilterConfigSimilarTo<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterConfigBase<Item, FilterField> {
  type: 'similar-to'
}

export type FilterSimilarToValue = number | null

export type FilterConfig<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> =
  | FilterConfigAutocomplete<Item, FilterField>
  | FilterConfigCheckbox<Item, FilterField>
  | FilterConfigCheckboxList<Item, FilterField>
  | FilterConfigRange<Item, FilterField>
  | FilterConfigRangeCurrency<Item, FilterField>
  | FilterConfigText<Item, FilterField>
  | FilterConfigGeoPoint<Item, FilterField>
  | FilterConfigGeoPolygon<Item, FilterField>
  | FilterConfigCity<Item, FilterField>
  | FilterConfigSimilarTo<Item, FilterField>

export interface FilterValueMap {
  autocomplete: FilterAutocompleteValue
  checkbox: FilterCheckboxValue
  'checkbox-list': FilterCheckboxListValue
  range: FilterRangeValue
  'range-currency': FilterCurrencyRangeValue
  text: FilterTextValue
  'geo-point': FilterGeoPointValue
  'geo-polygon': FilterGeoPolygonValue
  city: FilterCityValue
  'similar-to': FilterSimilarToValue
}

export type FilterConfigMap<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> = Record<FilterField, FilterConfig<Item, FilterField>>

export type FilterConfigArray<
  Item extends object = object,
  FilterFields extends readonly ListItemKey<Item>[] = readonly ListItemKey<Item>[]
> = {
  [Index in keyof FilterFields]: FilterConfig<Item, FilterFields[Index]>
}

import { useCustomAssetListUrlImportTaskStatus, useMutateBookmarksList } from '@gain/api/app/hooks'
import { SpinnerIcon } from '@gain/components/icons'
import Snackbar from '@gain/components/snackbar'
import Stack from '@mui/material/Stack'
import { useSnackbar } from 'notistack'
import { forwardRef, useEffect } from 'react'

import BookmarkListUrlImportStatusOverviewSnackbar from './bookmark-list-url-import-status-overview-snackbar'

interface BookmarkListUrlImportStatusSnackbarProps {
  snackbarId: string
  taskId: number
}

/**
 * Displays a snackbar that show the progress of importing URLs to a bookmark list
 */
const BookmarkListUrlImportStatusSnackbar = forwardRef<
  HTMLDivElement,
  BookmarkListUrlImportStatusSnackbarProps
>(({ snackbarId, taskId }, ref) => {
  const mutateList = useMutateBookmarksList()
  const snackbar = useSnackbar()

  // Poll for status updates
  const status = useCustomAssetListUrlImportTaskStatus(taskId, {
    refreshInterval: 200,
  })

  useEffect(() => {
    // When the import has finished, close this snackbar and show the snackbar
    // displaying the results of the import
    if (typeof status.data?.completedAt === 'string') {
      mutateList(status.data.customAssetListId)
      const snackbarKey = `bookmark-list-url-import-success-${taskId}`
      snackbar.enqueueSnackbar(undefined, {
        key: snackbarKey,
        content: () => (
          <BookmarkListUrlImportStatusOverviewSnackbar
            snackbarId={snackbarKey}
            taskId={taskId}
          />
        ),
        persist: true,
      })

      snackbar.closeSnackbar(snackbarId)
    }
  }, [
    mutateList,
    snackbar,
    snackbarId,
    status.data?.completedAt,
    status.data?.customAssetListId,
    taskId,
  ])

  return (
    <Snackbar
      ref={ref}
      id={snackbarId}
      message={
        <Stack
          alignItems={'center'}
          direction={'row'}
          display={'flex'}
          gap={1}>
          <SpinnerIcon />
          Importing {status.data?.urlsChecked}/{status.data?.urlsTotal} companies to a bookmark
          <strong>You can continue using the app</strong>
        </Stack>
      }
      variant={'info'}
    />
  )
})

export default BookmarkListUrlImportStatusSnackbar

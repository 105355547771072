import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap, SuggestCityResponse } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { formLabelClasses } from '@mui/material/FormLabel'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { v4 as uuidv4 } from 'uuid'

import {
  FilterCityItem,
  FilterCityValue,
  FilterConfigCity,
} from '../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../use-track-filter-event'

type AutocompleteOption = FilterCityItem | SuggestCityResponse

const StyledRoot = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& > .${inputBaseClasses.root}`]: { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)' },
  [`& .${formLabelClasses.root}`]: {
    ...theme.typography.body2,
  },
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  maxWidth: '100%',
  '&:not(:last-child)': {
    marginRight: theme.spacing(0.5),
  },
}))

function isSuggestCityResponse(value: AutocompleteOption): value is SuggestCityResponse {
  return !!value && 'description' in value
}

function isFilterCityItem(value: AutocompleteOption): value is FilterCityItem {
  return !!value && !('description' in value)
}

function isFilterCityItemEqual(a: FilterCityItem, b: FilterCityItem) {
  return a.city === b.city && a.region === b.region && a.countryCode === b.countryCode
}

function isOptionEqualToValue(option: AutocompleteOption, value: AutocompleteOption) {
  if (
    isSuggestCityResponse(option) &&
    isSuggestCityResponse(value) &&
    value.googlePlaceId === option.googlePlaceId
  ) {
    return true
  }

  return isFilterCityItem(option) && isFilterCityItem(value) && isFilterCityItemEqual(option, value)
}

function formatCity(city: FilterCityItem) {
  return `${city.city} (${[city.region, city.countryCode].filter(Boolean).join(', ')})`
}

export interface FilterCityProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigCity<Item, FilterField>
}

export default function FilterCity<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterCityProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()

  // We still use react-final-form to manage the filter bar form
  const field = useField<FilterCityValue>(path)

  const [inputValue, setInputValue] = useState('')
  const selectedOptions = useMemo(
    () => (Array.isArray(field.input.value) ? field.input.value : []),
    [field.input.value]
  )
  const [options, setOptions] = useState<Array<AutocompleteOption>>(selectedOptions)
  const [loadingSuggestions, setLoadingSuggestions] = useState(false)
  const fetcher = useRpcClient<RpcMethodMap>()
  const sessionTokenRef = useRef(uuidv4())

  // Returns an address for the given googlePlaceId in the locale of the given countryCode
  const getCityByPlaceId = useCallback(
    (googlePlaceId: string) => {
      return fetcher({
        method: 'data.getCityByPlaceId',
        params: {
          googlePlaceId: googlePlaceId,
        },
      })
    },
    [fetcher]
  )

  const handleInputValueChanged = useCallback(
    async (event, newInputValue) => {
      setInputValue(newInputValue)
      if (newInputValue === '') {
        // When the input is empty the only visible option is the currently selected option
        setOptions(selectedOptions)
      } else {
        setLoadingSuggestions(true)

        // When there is an input value we merge the selected option with the suggested options
        const nextOptions = await fetcher({
          method: 'data.suggestCity',
          params: {
            input: newInputValue,
            sessionToken: sessionTokenRef.current,
          },
        })

        let newOptions = new Array<AutocompleteOption>()

        if (selectedOptions) {
          newOptions = selectedOptions.slice()
        }

        if (nextOptions) {
          newOptions = [...newOptions, ...nextOptions]
        }

        setOptions(newOptions)
        setLoadingSuggestions(false)
      }
    },
    [fetcher, selectedOptions]
  )

  const handleAutocompleteOptionSelected = useCallback(
    async (_, newOptions: AutocompleteOption[], reason: AutocompleteChangeReason) => {
      if (reason === 'clear') {
        setOptions([])
        field.input.onChange(null)
        return
      }

      if (newOptions.length === 0) {
        field.input.onChange(null)
        sessionTokenRef.current = uuidv4()
      } else {
        const newOption = newOptions.find(isSuggestCityResponse)

        if (newOption) {
          // When the new value is a suggested address we fetch the address details,
          try {
            const result = await getCityByPlaceId(newOption.googlePlaceId)
            const newSelectedOptions = selectedOptions.concat(result)
            // the selected option must be available within the options array
            // since we cannot control when the selectedOptions(field.input) and
            // options are updated, there is a slight moment of inconsistency
            // causing a warning in the console.
            setOptions(newSelectedOptions)
            field.input.onChange(newSelectedOptions)
            trackEvent(
              `Filter ${filter.label} value change`,
              newSelectedOptions.map(formatCity).join(', '),
              null
            )
            sessionTokenRef.current = uuidv4()
          } catch (e) {
            setOptions([])
          }
        }
      }
    },
    [field.input, filter.label, getCityByPlaceId, selectedOptions, trackEvent]
  )

  const handleDelete = useCallback(
    (option: AutocompleteOption) => {
      if (!Array.isArray(field.input.value)) {
        return
      }

      const copy = field.input.value.slice()
      const index = copy.findIndex(
        (value) => isFilterCityItem(option) && isFilterCityItemEqual(value, option)
      )
      if (index !== -1) {
        copy.splice(index, 1)
        field.input.onChange(copy.length > 0 ? copy : null)
      }
    },
    [field.input]
  )

  const handleFilterOptions = useCallback((availableOptions: AutocompleteOption[]) => {
    return availableOptions.filter(isSuggestCityResponse)
  }, [])

  return (
    <StyledRoot>
      <Autocomplete
        filterOptions={handleFilterOptions}
        forcePopupIcon={false}
        getOptionLabel={(option) => {
          return isSuggestCityResponse(option) ? option.description : formatCity(option)
        }}
        inputValue={inputValue}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={loadingSuggestions}
        noOptionsText={'No locations'}
        onChange={handleAutocompleteOptionSelected}
        onInputChange={handleInputValueChanged}
        open={!!inputValue}
        options={options}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={''}
            placeholder={'Enter city'}
            fullWidth
          />
        )}
        renderOption={(optionProps, option) => (
          <li {...optionProps}>
            {isSuggestCityResponse(option)
              ? option.description
              : `${option.city}, ${option.countryCode}`}
          </li>
        )}
        renderTags={() => null}
        size={'small'}
        value={selectedOptions}
        autoComplete
        autoHighlight
        autoSelect
        blurOnSelect
        disableClearable
        filterSelectedOptions
        fullWidth
        multiple
      />
      {Array.isArray(selectedOptions) && selectedOptions.length > 0 && (
        <Box
          component={'div'}
          sx={{ mt: 1 }}>
          {selectedOptions.map((value, index) => (
            <StyledChip
              key={index}
              clickable={false}
              color={'info'}
              label={formatCity(value)}
              onDelete={() => handleDelete(value)}
              size={'medium'}
            />
          ))}
        </Box>
      )}
    </StyledRoot>
  )
}

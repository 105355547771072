import { useInvestorList } from '@gain/api/app/hooks'
import { AssetManager, InvestorListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'
import { uniq } from 'lodash'
import { useMemo } from 'react'

function getAssetManagerInvestorIds(managers: AssetManager[]) {
  return uniq(managers.map((manager) => manager.person?.investorId).filter(isTruthy))
}

function useAssetManagerInvestors(managers: AssetManager[]) {
  return useInvestorList(() => {
    const investorIds = getAssetManagerInvestorIds(managers)
    if (investorIds.length === 0) {
      return null
    }

    return {
      filter: [listFilter('id', '=', investorIds)],
    }
  })
}

export interface Manager extends AssetManager {
  investor: InvestorListItem | null
}

const createManager = (investors: InvestorListItem[]) =>
  function (manager: AssetManager): Manager {
    return {
      ...manager,
      investor: investors.find((investor) => investor.id === manager.person?.investorId) || null,
    }
  }

export function useManagers(managers: AssetManager[]) {
  const investors = useAssetManagerInvestors(managers)

  return useMemo(() => {
    return {
      ...investors,
      data: managers.map(createManager(investors.data.items)),
    }
  }, [investors, managers])
}

import { useAccountProjectCode } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import { useUserProfileContext } from '@gain/modules/user'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import MenuExpandIcon from '../../common/menu-expand-icon'
import BlockHotjar from '../../features/hotjar'
import { ProfileAvatar } from './profile-avatar'
import { SettingsMenu } from './settings-menu'

const StyledButton = styled(Button)({
  '&:hover': {
    background: 'none',
  },
})

const StyledAvatar = styled(ProfileAvatar)({
  width: 32,
  height: 32,
  cursor: 'pointer',
})

const StyledProjectCode = styled(Typography)({
  maxWidth: '100%',
})

export function ProfileButton() {
  const profile = useUserProfileContext()
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const fullname = [profile.firstName, profile.lastName].filter(Boolean).join(' ')
  const projectCode = useAccountProjectCode()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const location = useLocation()

  useEffect(() => {
    setAnchorEl(null)
  }, [location, setAnchorEl])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {isMdDown ? (
        <StyledAvatar onClick={handleClick} />
      ) : (
        <StyledButton
          aria-controls={'settings-menu'}
          aria-haspopup={'true'}
          endIcon={<MenuExpandIcon open={Boolean(anchorEl)} />}
          onClick={handleClick}
          startIcon={<StyledAvatar />}>
          <Stack
            alignItems={'start'}
            direction={'column'}
            minWidth={0}>
            <BlockHotjar>{fullname}</BlockHotjar>
            {projectCode.data && (
              <StyledProjectCode
                color={'text.secondary'}
                variant={'overline'}
                noWrap>
                <BlockHotjar>Project: {projectCode.data}</BlockHotjar>
              </StyledProjectCode>
            )}
          </Stack>
        </StyledButton>
      )}
      <SettingsMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      />
    </>
  )
}

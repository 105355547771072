import { UserProfile } from '@gain/rpc/app-model'
import { UserUnitSystem } from '@gain/rpc/shared-model'
import { DeepPartial } from 'react-hook-form/dist/types/utils'
import * as yup from 'yup'

import { FilterGeoPointValue } from '../filter-config/filter-config-model'

export const FilterGeoPointFormSchema = yup.object({
  point: yup
    .object({
      location: yup.string().required(),
      googlePlaceId: yup.string().required(),
      lon: yup.number().required(),
      lat: yup.number().required(),
    })
    .required(),
  distance: yup
    .number()
    .transform((value) => (isNaN(value) || value === null ? null : value))
    .required(),
})

export type GeoPointValue = Pick<
  NonNullable<FilterGeoPointValue>,
  'lat' | 'lon' | 'location' | 'googlePlaceId'
>

export interface FilterGeoPointFormValues {
  point: GeoPointValue | null
  distance: number
}

export const distanceToMeters = {
  km: 1000,
  miles: 1609,
}

export function formatRadius(radius: number, userProfile: UserProfile) {
  return `+${radius.toString(10)}${userProfile.unitSystem === UserUnitSystem.Miles ? ' ' : ''}${
    userProfile.unitSystem
  }`
}

/**
 * Extracts LatLon from a FilterGeoPointValue when valid, returns null otherwise.
 */
function filterValueToGeoPointValue(value: FilterGeoPointValue): GeoPointValue | null {
  if (value !== null && typeof value === 'object') {
    return {
      lat: value.lat,
      lon: value.lon,
      googlePlaceId: value.googlePlaceId,
      location: value.location,
    }
  }

  return null
}

function filterValueToDistance(value: FilterGeoPointValue, convertToMeters = 1000) {
  if (typeof value?.distance === 'number') {
    // Display distance in meters from either km or miles
    return Math.round(value.distance / convertToMeters)
  }

  return 5
}

export function geoPointFilterValueToFormValue(
  value: FilterGeoPointValue,
  convertToMeters: number
): FilterGeoPointFormValues {
  return {
    distance: filterValueToDistance(value, convertToMeters),
    point: filterValueToGeoPointValue(value),
  }
}

export function formValueToFilterValue(
  values: DeepPartial<FilterGeoPointFormValues>,
  convertToUnitSystem = 1000
): FilterGeoPointValue {
  try {
    const value = FilterGeoPointFormSchema.validateSync(values)
    return {
      // Convert distance in meters to km or miles
      distance: Math.round(value.distance * convertToUnitSystem),
      lat: value.point.lat,
      lon: value.point.lon,
      googlePlaceId: value.point.googlePlaceId,
      location: value.point.location,
    }
  } catch (e) {
    return null
  }
}

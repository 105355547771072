import { UsersIcon } from '@gain/components/icons'
import { ConferenceEditionListItem, SearchItemType } from '@gain/rpc/app-model'
import Stack from '@mui/material/Stack'
import React from 'react'

import ConferenceDetailsList from '../../../../../routes/conference/route-summary/conference-details-card/conference-details-list'
import { ObjectListItemAvatar, ObjectListItemText } from '../../../../object-list-item'
import { useSearchAPI } from '../../../search-hooks'
import SearchTopResultSideInfo from './search-top-result-side-info'
import { StyledSubTitle, TopResultLoadingSideInfo } from './search-top-result-skeleton'
import SearchTopResultTags from './search-top-result-tags'
import SearchTopResultTitle from './search-top-result-title'

export interface SearchTopResultConferenceEditionProps {
  conferenceEdition?: ConferenceEditionListItem | null
}

export default function SearchTopResultConferenceEdition({
  conferenceEdition,
}: SearchTopResultConferenceEditionProps) {
  const {
    result: { topResult },
  } = useSearchAPI()

  return (
    <Stack
      direction={'row'}
      gap={4}>
      <Stack
        flex={1}
        minWidth={0}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={2}>
          <ObjectListItemAvatar
            size={'big'}
            src={topResult?.imageUrl ?? undefined}
            type={SearchItemType.ConferenceEdition}
            variant={'rounded'}>
            <UsersIcon />
          </ObjectListItemAvatar>
          <ObjectListItemText variant={'top-result'}>
            <SearchTopResultTitle />
            <StyledSubTitle>Conference</StyledSubTitle>
          </ObjectListItemText>
        </Stack>

        {conferenceEdition && <SearchTopResultTags tags={conferenceEdition.exhibitorTags} />}
      </Stack>

      <SearchTopResultSideInfo>
        {!conferenceEdition && <TopResultLoadingSideInfo />}
        {conferenceEdition && <ConferenceDetailsList conferenceEdition={conferenceEdition} />}
      </SearchTopResultSideInfo>
    </Stack>
  )
}

import { theme } from '@app/theme'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { match } from 'css-mediaquery'

export function isXs() {
  return match(theme.breakpoints.only('xs').slice(7), {
    width: document.documentElement.clientWidth + 'px',
  })
}

export function useIsXs() {
  const { breakpoints } = useTheme()
  return useMediaQuery(breakpoints.only('xs'))
}

import { ExcelIcon, PdfIcon } from '@gain/components/icons'
import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { buildAssetFileName } from '@gain/utils/asset'
import Accordion from '@mui/material/Accordion'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

import AccordionList from '../../../../common/accordion/accordion-list'
import { ExportButton } from '../../../../features/export/export-button'
import { StyledAccordionDetails } from './legal-entity-download-accordion'

export interface AssetDownloadsProps {
  asset: Asset | AssetListItem
  assetName?: string | null
  excelFileId?: number | null
  preview: boolean
  withDeals?: boolean
}

export default function AssetDownloads({
  asset,
  assetName,
  excelFileId,
  preview,
  withDeals = true,
}: AssetDownloadsProps) {
  return (
    <>
      {assetName && (
        <Stack
          alignItems={'center'}
          direction={'row'}
          justifyContent={'space-between'}>
          <Typography variant={'h6'}>{assetName}</Typography>
        </Stack>
      )}

      <Stack
        mt={1.5}
        width={'100%'}>
        <Accordion expanded>
          <StyledAccordionDetails>
            <AccordionList>
              <ListItem disableGutters>
                <ListItemIcon>
                  <PdfIcon />
                </ListItemIcon>

                <ListItemText primary={'Company profile in PDF'} />

                <ExportButton
                  method={'data.exportFactsheet'}
                  params={{ id: asset.id, preview }}
                  showMaxExportCount
                />
              </ListItem>

              {excelFileId && (
                <ListItem disableGutters>
                  <ListItemIcon>
                    <ExcelIcon />
                  </ListItemIcon>

                  <ListItemText primary={'Financials in Excel'} />

                  <ExportButton
                    method={'data.exportAssetFinancials'}
                    params={{ id: asset.id }}
                    showMaxExportCount
                  />
                </ListItem>
              )}

              {withDeals && (
                <ListItem disableGutters>
                  <ListItemIcon>
                    <ExcelIcon />
                  </ListItemIcon>

                  <ListItemText primary={'Deals in Excel'} />

                  <ExportButton
                    method={'data.exportAssetDeals'}
                    params={{
                      assetId: asset.id,
                      filename: buildAssetFileName(asset, 'deals.xlsx'),
                    }}
                    showMaxExportCount
                  />
                </ListItem>
              )}
            </AccordionList>
          </StyledAccordionDetails>
        </Accordion>
      </Stack>
    </>
  )
}

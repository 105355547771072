import { RowType } from '@gain/utils/table'
import { iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import TableSortLabel, { tableSortLabelClasses } from '@mui/material/TableSortLabel'
import React from 'react'

import InfoButton from '../info-button'
import { VirtualTableVariant } from './virtual-table'
import { RenderHeaderParams } from './virtual-table-model'

const StyledTableSortLabel = styled(TableSortLabel, {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant?: VirtualTableVariant }>(({ theme, variant }) => ({
  [`& .${iconButtonClasses.root}`]: {
    color: theme.palette.text.secondary,
  },

  [`&:hover .${iconButtonClasses.root}`]: {
    color: theme.palette.text.primary,
  },

  ...(variant === 'inline' && {
    fontWeight: theme.typography.fontWeightRegular,

    [`&.${tableSortLabelClasses.active}`]: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
}))

interface VirtualTableHeadCellContentProps<Row extends RowType, Field extends keyof Row>
  extends RenderHeaderParams<Row, Field> {
  variant?: VirtualTableVariant
}

export function VirtualTableHeadCellContent<Row extends RowType, Field extends keyof Row>({
  colDef,
  api,
  variant,
}: VirtualTableHeadCellContentProps<Row, Field>) {
  return (
    <StyledTableSortLabel
      active={api.handleSortActive(colDef as never)}
      direction={api.handleSortDirection(colDef as never)}
      onClick={api.handleSort(colDef as never)}
      variant={variant}>
      {colDef.headerName}
      {colDef.headerExplainer && (
        <InfoButton
          dialogMessage={colDef.headerExplainer}
          dialogTitle={colDef.headerName ?? ''}
        />
      )}
    </StyledTableSortLabel>
  )
}

import { useLatestFeature } from '@gain/api/app/hooks'
import { useMemo } from 'react'

interface BeamerPost {
  id: number
  category: string
  title: string
  content: string
}

export function useLatestBeamerPost() {
  const { loading, data } = useLatestFeature(undefined, { suspense: false })

  const post = useMemo(() => {
    if (!data) {
      return null
    }

    if (!data) {
      return null
    }

    const translation = data?.translations.find((item) => item.language === 'EN')

    if (!translation) {
      return null
    }

    return {
      id: data.id,
      title: translation.title,
      category: data.category,
      content: translation.content,
    } as BeamerPost
  }, [data])

  return {
    loading,
    data: post,
  }
}

import { ListedSecurityValuationRatios } from '@gain/rpc/app-model'
import { formatYearAndQuarter } from '@gain/utils/date'
import { useMemo } from 'react'

/**
 * RatioKey is a union type that contains all keys of a
 * ListedSecurityValuation that can be displayed as a ratio
 */
export type RatioKey = keyof Pick<
  ListedSecurityValuationRatios,
  | 'enterpriseValueRevenueRatio'
  | 'enterpriseValueEbitdaRatio'
  | 'enterpriseValueEbitRatio'
  | 'enterpriseValueEbitdaMinusCapexRatio'
  | 'enterpriseValueInvestedCapitalRatio'
  | 'enterpriseValueFreeCashFlowRatio'
>

/**
 * RatioItemConfig is used to map a key to a label for display purposes.
 */
interface RatioItemConfig {
  key: RatioKey
  label: string
}

/**
 * RATIO_CONFIG defines the ratio keys that can be displayed together
 * with their display label.
 */
const RATIO_CONFIG: RatioItemConfig[] = [
  {
    key: 'enterpriseValueEbitdaRatio',
    label: 'EV / EBITDA',
  },
  {
    key: 'enterpriseValueEbitRatio',
    label: 'EV / EBIT',
  },
  {
    key: 'enterpriseValueRevenueRatio',
    label: 'EV / Sales',
  },
  {
    key: 'enterpriseValueEbitdaMinusCapexRatio',
    label: 'EV / (EBITDA - CAPEX)',
  },
  {
    key: 'enterpriseValueInvestedCapitalRatio',
    label: 'EV / Invested Capital',
  },
  {
    key: 'enterpriseValueFreeCashFlowRatio',
    label: 'EV / Free Cash Flow',
  },
]

function isMeaningfulRatio(ratio: number | null) {
  return ratio !== null && ratio > 0
}

/**
 * findActiveRationOptionIndex returns the index of the first ratio option that
 * has positive values.
 */
export function findActiveRationOptionIndex(
  ratioOptions: RatioOption[],
  valuations: ListedSecurityValuationRatios[]
): number {
  return ratioOptions.findIndex((option) => {
    return valuations.some((valuation) => {
      return isMeaningfulRatio(valuation[option.key])
    })
  })
}

/**
 * useHasValuationRatios returns true if the given valuations contain
 * ratios that can be displayed, false otherwise.
 */
export function hasValuationRatios(valuations?: ListedSecurityValuationRatios[]) {
  if (!Array.isArray(valuations) || valuations.length === 0) {
    return false
  }

  return valuations.some((valuation) => {
    return RATIO_CONFIG.some((config) => {
      return isMeaningfulRatio(valuation[config.key])
    })
  })
}

/**
 * RatioOption is used to display the available ratio options in tabs
 * or dropdown menu.
 */
export interface RatioOption {
  label: string
  key: RatioKey
  ratio: number | null
  chipLabel: string | null
}

/**
 * formatChipLabel returns "LTM" if the valuation is live (latest rolling
 * valuation). When not live it returns a sensible formatting for the fiscal
 * year and quarter (e.g. "'23 Q2", "'19").
 */
function formatChipLabel(valuation?: ListedSecurityValuationRatios): string | null {
  if (!valuation) {
    return null
  }

  if (valuation.financialResultPeriodicity) {
    return 'LTM'
  }

  return formatYearAndQuarter(
    valuation.financialResultFiscalYear,
    valuation.financialResultFiscalQuarter
  )
}

/**
 * useRatioOptions returns the `RatioOption`s that should be displayed
 * given the ratios as defined in the valuations' parameter.
 */
export function useRatioOptions(valuations: ListedSecurityValuationRatios[] | undefined) {
  return useMemo(() => {
    // Bail out if there are no validations
    if (!Array.isArray(valuations) || valuations.length === 0) {
      return []
    }

    return RATIO_CONFIG.reduce((acc, itemConfig) => {
      const latestDefinedValuation = valuations.find(
        (valuation) => valuation[itemConfig.key] !== null
      )
      const value = latestDefinedValuation?.[itemConfig.key] ?? null

      return acc.concat({
        label: itemConfig.label,
        key: itemConfig.key,

        // value can be null, in that case the tab or menu item displaying
        // the label will be disabled
        ratio: value,
        chipLabel: formatChipLabel(latestDefinedValuation),
      })
    }, new Array<RatioOption>())
  }, [valuations])
}

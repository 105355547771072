import { DownloadIcon, SpinnerIcon } from '@gain/components/icons'
import { admin, ExportUserSessionsParams, UserSessionListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listSort } from '@gain/rpc/utils'
import { formatDate } from '@gain/utils/date'
import { formatSeconds } from '@gain/utils/date'
import { downloadUrl } from '@gain/utils/download'
import Button from '@mui/material/Button'
import React, { useCallback, useState } from 'react'

import { rpcClient } from '../../api/rpc-client'
import { ColumnConfig } from '../../common/virtual-table'
import ListView, { ListViewApi } from '../../features/list-view'
import {
  USER_SESSION_DEFAULT_FILTER,
  USER_SESSION_FILTER_MAP,
  USER_SESSION_FILTER_MENU,
  UserSessionFilterField,
} from '../../features/user-session/user-session-filter-bar'

const columns: ColumnConfig<UserSessionListItem>[] = [
  {
    field: 'firstName',
    headerName: 'First name',
    width: 136,
    flex: 1,
    align: 'left',
    sticky: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 136,
    flex: 1,
    align: 'left',
    sticky: true,
  },
  {
    field: 'email',
    headerName: 'Email address',
    width: 232,
    flex: 1,
    align: 'left',
  },
  {
    field: 'sessionProjectCode',
    headerName: 'Project code',
    width: 152,
    flex: 1,
    align: 'left',
  },
  {
    field: 'sessionStartedAt',
    headerName: 'Start session',
    width: 192,
    flex: 1,
    align: 'left',
    valueFormatter: (params) => formatDate(params.value, { format: 'dateTime' }),
  },
  {
    field: 'sessionDuration',
    headerName: 'Duration',
    width: 192,
    flex: 1,
    align: 'left',
    valueFormatter: (params) => formatSeconds(params.value),
  },
]

const defaultSort: ListSort<UserSessionListItem>[] = [listSort('sessionStartedAt', 'desc')]

export function UserSessionsView() {
  const [isExporting, setIsExporting] = useState<boolean>(false)

  const handleExportUserSessions = useCallback(
    async (params: ExportUserSessionsParams) => {
      setIsExporting(true)
      const url = await rpcClient.rpc<string>(admin.exportUserSessions(params))
      downloadUrl(url)
      setIsExporting(false)
    },
    [setIsExporting]
  )

  const renderBulkActions = useCallback(
    (
      api: ListViewApi<
        'admin.listUserSessions',
        UserSessionListItem,
        UserSessionListItem,
        UserSessionFilterField
      >
    ) => {
      return (
        <Button
          disabled={isExporting}
          onClick={() =>
            handleExportUserSessions({
              filter: api.queryParams.filter,
              sort: api.sort.map((item) => `${item.direction === 'desc' ? '-' : ''}${item.field}`),
              search: '',
              limit: api.data.totalSize,
              page: 0,
            })
          }
          startIcon={isExporting ? <SpinnerIcon /> : <DownloadIcon />}>
          Export
        </Button>
      )
    },
    [handleExportUserSessions, isExporting]
  )

  return (
    <ListView
      addFilterMenu={USER_SESSION_FILTER_MENU}
      defaultFilterModel={USER_SESSION_DEFAULT_FILTER}
      defaultSort={defaultSort}
      filterBarSearchLabel={'Name'}
      filterBarSearchPlaceholder={'E.g. William Davis'}
      filterConfigMap={USER_SESSION_FILTER_MAP}
      method={'admin.listUserSessions'}
      renderPageActions={renderBulkActions}
      xs={{
        variant: 'virtual-table',
        VariantProps: {
          columns,
          RowComponentProps: {
            hover: false,
          },
        },
      }}
    />
  )
}

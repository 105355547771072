import { ListItemKey } from '@gain/rpc/list-model'
import { createContext, useContext } from 'react'

import { DropdownMenuOptions } from '../../../../common/dropdown-menu'
import { GaCategory } from '../../../google-analytics'
import { FilterConfig, FilterConfigMap } from '../filter-config/filter-config-model'

export interface FilterContextType<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  addFilterMenu: DropdownMenuOptions<FilterConfig<Item, FilterField>>
  filterConfigMap: FilterConfigMap<Item, FilterField>
  gaCategory: `${GaCategory} filters`
  disableOrFilter: boolean
}

export const FilterBarContext = createContext<FilterContextType | null>(null)

export function useFilterBarContext<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>() {
  const context = useContext(FilterBarContext)

  if (!context) {
    throw new Error('FilterContext not provided')
  }

  return context as unknown as FilterContextType<Item, FilterField>
}

export function useFilterConfigMap<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>() {
  const context = useFilterBarContext<Item, FilterField>()
  return context.filterConfigMap
}

export function useFilterConfig<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField) {
  const context = useFilterBarContext<Item, FilterField>()
  return context.filterConfigMap[id]
}

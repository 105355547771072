import { useOpenLink } from '@gain/utils/router'
import React, { ReactElement } from 'react'
import { matchPath, useLocation } from 'react-router'

import MenuListItem, { MenuListItemProps } from './menu-list-item'

export type NavMenuListItemProps = MenuListItemProps & {
  label: string
  icon: ReactElement
  secondaryAction?: ReactElement | false
  to: string

  // Used in addition to the to prop to determine if the item is active or not
  activeRoutes?: string[]
  exact?: boolean
}

export function NavMenuListItem({ to, exact, activeRoutes = [], ...props }: NavMenuListItemProps) {
  const openLink = useOpenLink()
  const location = useLocation()

  const isSelected = [to, ...activeRoutes].some(
    (path) => !!matchPath(location.pathname, { path, exact })
  )

  return (
    <MenuListItem
      onClick={(event) => openLink(to, event)}
      selected={isSelected}
      {...props}
    />
  )
}

import { objectKeys } from '@gain/utils/typescript'
import { styled } from '@mui/material/styles'
import { capitalize } from '@mui/material/utils'
import { Breakpoint } from '@mui/system'

export interface SpacerProps {
  direction: 'vertical' | 'horizontal'
  variant?: 'spacing' | 'flex'
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

const Spacer = styled('span')<SpacerProps>(
  ({ theme, direction, variant = 'spacing', xs, sm, md, lg, xl }) => {
    const breakpoints = { xs, sm, md, lg, xl }

    if (variant === 'spacing') {
      const prop = direction === 'horizontal' ? 'width' : 'height'
      const display = direction === 'horizontal' ? 'inline-block' : 'block'

      const sizes = Object.keys(breakpoints)
        .filter((key) => breakpoints[key] !== undefined)
        .reduce(
          (acc, current) => ({
            ...acc,
            [theme.breakpoints.up(current as Breakpoint)]: {
              [prop]: theme.spacing(breakpoints[current]),
              [`min${capitalize(prop)}`]: theme.spacing(breakpoints[current]),
              [`max${capitalize(prop)}`]: theme.spacing(breakpoints[current]),
            },
          }),
          {} as Record<Breakpoint, number>
        )

      return {
        ...sizes,
        display,
      }
    }

    const sizes = objectKeys(breakpoints)
      .filter((key) => breakpoints[key] !== undefined)
      .reduce(
        (acc, current) => ({
          ...acc,
          [theme.breakpoints.up(current)]: {
            flex: breakpoints[current],
          },
        }),
        {}
      )

    return {
      display: 'flex',
      flexDirection: direction === 'horizontal' ? 'row' : 'column',
      ...sizes,
    }
  }
)

export default Spacer

import { Components } from '@mui/material/styles'

export const MuiDialogContentText = (): Components['MuiDialogContentText'] => ({
  styleOverrides: {
    root: {
      overflowWrap: 'break-word',
      '&:not(:last-child)': {
        marginBottom: '1em',
      },
    },
  },
})

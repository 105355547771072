import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { MouseEvent, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react'

import InfoButton from '../info-button'
import { CheckboxListValueType } from './checkbox-list'
import { useCheckboxList } from './checkbox-list-context'

const StyledFormControlLabel = styled(FormControlLabel)({
  display: 'flex',
  marginLeft: -8,
  minHeight: 32,
  alignItems: 'center',
})

export interface CheckboxListOptionProps<ValueType extends CheckboxListValueType> {
  label: string | ReactNode
  value: ValueType
  icon?: ReactNode
  explainer?: string
  explainerTitle?: string
  secondaryAction?: ReactNode
}

export default function CheckboxListOption<ValueType extends CheckboxListValueType>({
  label,
  value,
  icon,
  explainer,
  explainerTitle,
  secondaryAction,
}: CheckboxListOptionProps<ValueType>) {
  const context = useCheckboxList<ValueType>()
  const onChange = useCallback(
    (_event: SyntheticEvent<Element>, checked: boolean) => {
      if (checked) {
        context.addCheckboxValue(value)
      } else {
        context.removeCheckboxValue(value)
      }
    },
    [context, value]
  )

  const checked = useMemo(
    () => context.value !== null && context.value.includes(value),
    [context.value, value]
  )

  // Prevent the group accordion from expanding/collapsing when clicking the checkbox.
  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      justifyContent={'space-between'}>
      <StyledFormControlLabel
        checked={checked}
        control={<Checkbox />}
        label={
          <>
            {label}

            {icon}

            {explainer && (
            <InfoButton
              dialogMessage={explainer}
              dialogTitle={explainerTitle ? explainerTitle : typeof label === 'string' ? label : ''}
              sx={{ ml: 0.5 }}
              />
            )}
          </>
        }
        onChange={onChange}
        onClick={handleClick}
      />
      {secondaryAction}
    </Stack>
  )
}

import { isDefined } from '@gain/utils/common'
import { format as formatDateFns } from 'date-fns/format'

export type DateFormat =
  | 'date'
  | 'dateAbbreviated'
  | 'dateTime'
  | 'monthYear'
  | 'month'
  | 'monthYearAbbreviated'
  | 'year'

// Juggling around with constants as ESLint and Prettier can not agree upon using
// single quotes or double quotes in combination with this escaped quote ('').
const ABBREVIATED_MONTH = 'MMM'
const MONTH_YEAR_ABBREVIATED = `${ABBREVIATED_MONTH} ''yy`

interface FormatDateOptions {
  format?: DateFormat
  emptyValue?: string
}

export function formatDate(
  value: string | Date | number | null | undefined,
  { format = 'date', emptyValue = '-' }: FormatDateOptions = {}
): string {
  if (!isDefined(value)) {
    return emptyValue
  }

  switch (format) {
    case 'year':
      return formatDateFns(value, 'yyyy')
    case 'month':
      return formatDateFns(value, 'MMM')
    case 'monthYear':
      return formatDateFns(value, 'MMM, yyyy')
    case 'monthYearAbbreviated':
      return formatDateFns(value, MONTH_YEAR_ABBREVIATED)
    case 'date':
      return formatDateFns(value, 'MMM d, yyyy')
    case 'dateAbbreviated':
      return formatDateFns(value, 'dd/MM/yyyy')
    case 'dateTime':
      return formatDateFns(value, 'd MMM yyyy, HH:mm')
  }
}

import { useSessionId } from '@gain/modules/auth'
import { useMemo } from 'react'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'

export type UseRpcParams<Params> =
  | Partial<Params>
  | null
  | (() => Partial<Params> | null)
  | undefined

export interface UseRpcResponse<Response, Error = unknown>
  extends Pick<SWRResponse<Response, Error>, 'data' | 'error' | 'mutate'> {
  loading: boolean
}

export function useFormatRpcParams<Params>(
  params: UseRpcParams<Params>
): Partial<Params> | null | undefined {
  return useMemo(() => {
    if (params === null) {
      return null
    } else if (typeof params === 'function') {
      const result = params()
      return result !== null && result !== undefined ? result : null
    } else {
      return params
    }
  }, [params])
}

export function useRpcSwrKey<Params>(method: string, params: Params, token: string | null) {
  return useMemo(() => {
    if (params === null) {
      return null
    }

    return {
      method,
      token,
      params,
    }
  }, [method, params, token])
}

export function useRpc<Params, Response, Error = unknown>(
  method: string,
  params?: UseRpcParams<Params>,
  options?: SWRConfiguration
): UseRpcResponse<Response> {
  const token = useSessionId()
  const formattedParams = useFormatRpcParams(params)
  const key = useRpcSwrKey(method, formattedParams, token)

  const { data, error, mutate, isLoading } = useSWR<Response, Error>(key, options)

  return useMemo(() => {
    return {
      loading: isLoading,
      data,
      error,
      mutate,
    }
  }, [data, error, mutate, isLoading])
}

import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export const MuiOutlinedInput = (theme: Theme): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.background?.paper,
      borderRadius: 8,
      transition: theme.transitions.create(['box-shadow']),
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      [`&.${outlinedInputClasses.focused}`]: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderWidth: 1,
        },
        [`&:not(.${outlinedInputClasses.error}) .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.grey['300'],
        },
      },
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.grey['300'],
      },
      [`&.${outlinedInputClasses.disabled},&.${inputBaseClasses.readOnly}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.primary,
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderWidth: 0,
        },
      },
      [`&.${outlinedInputClasses.sizeSmall}`]: {
        borderRadius: 4,
      },
    },
    notchedOutline: {
      top: 0,
      borderWidth: 1,
      borderColor: theme.palette.grey['300'],
      '& legend': {
        display: 'none',
      },
    },
    input: {
      padding: theme.spacing(1.25, 2),
      height: 40,
      boxSizing: 'border-box',
      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 1 /* Firefox */,
      },
      [`&.${outlinedInputClasses.inputSizeSmall}:first-child`]: {
        padding: `${theme.spacing(0, 1)} !important`,
        ...theme.typography.body2,
      },
      '&[readonly]:not(textarea + textarea[readonly])': {
        borderRadius: 8,
        backgroundColor: theme.palette.divider,
        [`& + .${outlinedInputClasses.notchedOutline}, & + textarea + .${outlinedInputClasses.notchedOutline}`]:
          {
            borderWidth: 0,
          },
      },
      [`&.${outlinedInputClasses.inputAdornedStart}`]: {
        marginLeft: theme.spacing(2),
      },
      [`&.${outlinedInputClasses.inputAdornedEnd}`]: {
        marginRight: theme.spacing(2),
      },
    },
    multiline: {
      padding: 0,
      '& > textarea': {
        padding: theme.spacing(1, 2),
      },
    },
    adornedStart: {
      paddingLeft: theme.spacing(2),
      [`& .${svgIconClasses.root}`]: {
        color: theme.palette.text.secondary,
      },
    },
    adornedEnd: {
      paddingRight: theme.spacing(2),
      [`& .${svgIconClasses.root}`]: {
        color: theme.palette.text.secondary,
      },
    },
  },
})

import { IndustryIcon } from '@gain/components/icons'
import { SearchResultItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import React from 'react'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { generateIndustryPagePath } from '../../../utils'

export interface SearchResultListItemIndustryProps {
  item: SearchResultItem
}

export default function SearchResultListItemIndustry({ item }: SearchResultListItemIndustryProps) {
  return (
    <MobileListItem key={`${item.type}-${item.id}`}>
      <MobileListItemButton
        as={Link}
        {...{ href: generateIndustryPagePath({ id: item.id, title: item.name, preview: false }) }}>
        <MobileListItemIcon>
          <Logo
            defaultIcon={<IndustryIcon />}
            size={40}
            src={item.imageUrl}
            fullSize
          />
        </MobileListItemIcon>
        <MobileListItemText
          primary={item.name}
          secondary={`In ${item.regions}`}
        />
      </MobileListItemButton>
    </MobileListItem>
  )
}

import { containerClasses } from '@mui/material/Container'
import { Components, Theme } from '@mui/material/styles'

export const MuiContainer = (theme: Theme): Components['MuiContainer'] => ({
  styleOverrides: {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      [`&.${containerClasses.maxWidthLg}`]: {
        maxWidth: theme.breakpoints.values['lg'] + parseInt(theme.spacing(10), 10),
      },
    },
  },
})

import { useArticleListInfinite, useUserProfile } from '@gain/api/app/hooks'
import { ArticleListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import { useCallback } from 'react'

export function useHomeArticleListInfinite() {
  const userProfile = useUserProfile()
  return useArticleListInfinite(
    useCallback(() => {
      if (!userProfile.data) {
        return null
      }

      return {
        filter: listFilters<ArticleListItem>(
          listFilter('type', '=', 'inTheNews'),
          userProfile.data.recommendRegions.length > 0 &&
            listFilter('regions', '=', userProfile.data.recommendRegions),
          userProfile.data.recommendSubsectors.length > 0 &&
            listFilter('subsector', '=', userProfile.data.recommendSubsectors)
        ),
        sort: [listSort('date', 'desc')],
        limit: 50,
      }
    }, [userProfile.data])
  )
}

import { useUserCurrency } from '@gain/api/app/hooks'
import { Investor, InvestorFund, InvestorProfileStrategy } from '@gain/rpc/app-model'
import { compareNumberDesc } from '@gain/utils/common'
import { useConvertCurrencyCallback, useFormatCurrencyCallback } from '@gain/utils/currency'
import { useElementWidthEffect } from '@gain/utils/dom'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import { chartColorSet } from '../../../common/chart/chart-colors'
import { ChartGroup } from '../../../common/chart/chart-groups'
import ChartLegend from '../../../common/chart/chart-legend'
import { MeasureDimensions } from '../../../common/responsive'
import { StackedBarChart } from '../../../features/chart'

interface FundraisingByStrategy {
  strategyId: number
  year: number
  fundSizeEur: number
}

// returns the fundraising per year per strategy, if the fund isn't linked to a strategy the
// strategy id will default to 0 since we still want to show these funds in the chart
function useFundraisingByStrategy(funds: InvestorFund[]) {
  return useMemo(() => {
    return funds.reduce((acc, fund) => {
      // skip fund if there is no vintageDate or fundSize
      if (!fund.vintageDate || !fund.fundSizeEur) {
        return acc
      }

      // find index for fundraising by strategy and year
      const index = acc.findIndex(
        (item) =>
          item.strategyId === (fund.investorStrategyId || 0) && item.year === fund.vintageDate?.year
      )

      // when found, add fundSizeEur
      if (index > -1) {
        acc[index].fundSizeEur += fund.fundSizeEur || 0
      } else {
        // if not found create a new item
        acc.push({
          strategyId: fund.investorStrategyId || 0,
          fundSizeEur: fund.fundSizeEur,
          year: fund.vintageDate.year,
        })
      }

      return acc
    }, new Array<FundraisingByStrategy>())
  }, [funds])
}

type FundraisingByStrategiesGroup = ChartGroup<FundraisingByStrategy, number>
type FundraisingByStrategiesGroupWithoutColor = Omit<FundraisingByStrategiesGroup, 'color'>

function compareStrategyByItemCount(
  groupA: FundraisingByStrategiesGroupWithoutColor,
  groupB: FundraisingByStrategiesGroupWithoutColor
) {
  return compareNumberDesc(groupA.items.length, groupB.items.length)
}

function useStrategyGroups(
  fundraisingByStrategies: FundraisingByStrategy[],
  strategies: InvestorProfileStrategy[]
) {
  return useMemo(() => {
    const result = strategies
      // map strategy to group with fundraising by strategy as value, skip strategies without companies
      .reduce((acc, strategy) => {
        return acc.concat({
          value: strategy.id,
          label: strategy.name,
          items: fundraisingByStrategies.filter((fund) => fund.strategyId === strategy.id),
        })
      }, new Array<Omit<ChartGroup<FundraisingByStrategy, number>, 'color'>>())
      .sort(compareStrategyByItemCount)
      // assign colors after sorting to make sure the color behaviour stays consistent with other investor charts
      .map((group, index) => {
        return {
          ...group,
          color: chartColorSet[index % chartColorSet.length],
        } as ChartGroup<FundraisingByStrategy, number>
      })
      // Remove all the groups that have no items
      .filter((group) => group.items.length > 0)

    // if there are funds included without a linked strategy, create a group for that category
    if (fundraisingByStrategies.some((item) => item.strategyId === 0)) {
      result.push({
        value: 0,
        items: fundraisingByStrategies.filter((item) => item.strategyId === 0),
        // if this is the only "strategy" we show it as "Single strategy", show as "Unknown" if the
        // investor has other strategies,
        label: strategies.length > 0 ? 'Unknown' : 'Single strategy',
        color: chartColorSet[result.length % chartColorSet.length],
      })
    }

    // filter groups without fundraising by strategy, this is done as a last step to make sure the colors are still
    // consistent with group coloring in other pages
    return result.filter((group) => group.items.length > 0)
  }, [fundraisingByStrategies, strategies])
}

const StyledCard = styled(Card)({
  paddingBottom: 0,
})

export interface InvestorFundraisingCardProps {
  investor: Investor
  strategies: InvestorProfileStrategy[]
}

export default function InvestorFundraisingCard({
  investor,
  strategies,
}: InvestorFundraisingCardProps) {
  const userCurrency = useUserCurrency()
  const formatCurrency = useFormatCurrencyCallback()
  const convertCurrency = useConvertCurrencyCallback()
  const cardRef = useRef<HTMLDivElement>(null)
  const [hoverGroup, setHoverGroup] = useState<ChartGroup<FundraisingByStrategy> | null>(null)
  const fundraisingByStrategies = useFundraisingByStrategy(investor.funds)
  const groups = useStrategyGroups(fundraisingByStrategies, strategies)
  const [visibleGroups, setVisibleGroups] =
    useState<ChartGroup<FundraisingByStrategy, number>[]>(groups)
  const [maxNrOfYears, setMaxNrOfYears] = useState(10)

  const visibleFundraisingByStrategies = useMemo(() => {
    const maxYear = Math.max(...fundraisingByStrategies.map((fund) => fund.year || 0))
    const minYear = maxYear - maxNrOfYears

    return fundraisingByStrategies.filter((item) => {
      if (item.year < minYear) {
        return false
      }

      return visibleGroups.some((group) => group.value === item.strategyId)
    })
  }, [fundraisingByStrategies, visibleGroups, maxNrOfYears])

  // get the fundraisingByStrategy color from related group
  const handleGetColor = useCallback(
    (item: FundraisingByStrategy) => {
      return groups.find((group) => group.value === item.strategyId)?.color || 'black'
    },
    [groups]
  )

  // updates the max visible years based on card width to make sure the x-axis doesn't to crowded
  const handleWidthChange = useCallback(() => {
    const width = cardRef.current?.getBoundingClientRect().width
    if (!width) {
      return
    }
    const maxYears = Math.floor((width - 200) / 30)
    setMaxNrOfYears(maxYears)
  }, [])

  // track width changes so we can update the number of visible axes accordingly
  useElementWidthEffect(cardRef, handleWidthChange)

  return (
    <StyledCard ref={cardRef}>
      <CardHeader title={'Fundraising by strategy'} />
      <CardContent>
        <MeasureDimensions fixedHeight={216}>
          {({ width, height }) => (
            <StackedBarChart
              data={visibleFundraisingByStrategies}
              getColor={handleGetColor}
              getHighlightGroup={(item) => item.strategyId || 0}
              height={height}
              highlightGroup={hoverGroup?.value}
              width={width}
              xScaleConfig={{
                label: 'Year',
                getLabel: (value) => value.toString(),
                getValue: (item) => item.year,
              }}
              yScaleConfig={{
                label: 'Total',
                getLabel: (value) => formatCurrency(value, { dataCurrency: userCurrency.name }),
                getAxisLabel: (value) => {
                  if (value === 0) {
                    return '0m'
                  }

                  return formatCurrency(value, {
                    dataCurrency: userCurrency.name,
                    disablePrefix: true,
                    round: 'auto',
                  })
                },
                getValue: (item) => convertCurrency(item.fundSizeEur, 'EUR', userCurrency.name),
              }}
            />
          )}
        </MeasureDimensions>

        <Divider sx={{ mx: -3 }} />

        <ChartLegend
          groups={groups}
          onChange={setVisibleGroups}
          onGroupHover={setHoverGroup}
          value={visibleGroups}
        />
      </CardContent>
    </StyledCard>
  )
}

import {
  useCreateBookmarksFilteredList,
  useRecentlyFilteredBookmarkLists,
  useSavedBookmarksFilteredLists,
  useSetBookmarksFilteredLastViewedAt,
} from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { ASSET_FILTER_MAP, AssetFilterField } from '../../../features/asset/asset-filter-bar'
import { fromFilterModel, useFilterModelQueryParam } from '../../../features/filter/filter-bar'
import { ASSET_LIST_PATH, BookmarksPathParams } from '../../utils'

/**
 * Automatically stores filters from the companies page when navigating away.
 */
export default function useSaveRecentlyFiltered() {
  const [filterModel] = useFilterModelQueryParam<AssetListItem, AssetFilterField>()
  const [filters, setFilters] = useState<ListFilter<AssetListItem>[]>([])
  const { lists: recentFilterLists } = useRecentlyFilteredBookmarkLists()
  const { lists: savedLists } = useSavedBookmarksFilteredLists()
  const updateLastViewedAt = useSetBookmarksFilteredLastViewedAt()
  const createList = useCreateBookmarksFilteredList()
  const location = useLocation()

  // Detect if we're on the companies page
  const match = useMemo(
    () =>
      matchPath<BookmarksPathParams>(location.pathname, {
        path: ASSET_LIST_PATH,
        exact: true,
      }),
    [location]
  )

  // Update filters when filter model changes
  useEffect(() => {
    if (match?.isExact) {
      setFilters(fromFilterModel(filterModel, ASSET_FILTER_MAP))
    }
  }, [createList, match?.isExact, filterModel])

  // Create list when navigation away from the assets page.
  useEffect(() => {
    if (!match?.isExact && filters.length > 0) {
      setFilters([]) // Always reset pending filters

      // Figure out if the list is already stored somewhere
      const savedList = savedLists.find((item) => isEqual(item.filters, filters))
      const recentFilterList = recentFilterLists.find((item) => {
        return isEqual(item.filters, filters)
      })

      if (!savedList && !recentFilterList) {
        // Create new list if the filters are not saved
        createList('', [], filters, true)
      } else if (recentFilterList) {
        // If the list has been recently filtered, update the timestamp
        // to ensure it appears at the top of the recently filtered list.
        updateLastViewedAt(recentFilterList.id)
      }
    }
  }, [
    createList,
    match?.isExact,
    filterModel,
    filters,
    savedLists,
    recentFilterLists,
    updateLastViewedAt,
  ])
}

import { Components, Theme } from '@mui/material/styles'

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    textSecondary: true
    grey600: true
  }
}

export const MuiSvgIcon = (theme: Theme): Components['MuiSvgIcon'] => ({
  variants: [
    {
      props: { color: 'textSecondary' },
      style: {
        color: theme.palette.text.secondary,
      },
    },
    {
      props: { color: 'grey600' },
      style: {
        color: theme.palette.grey['600'],
      },
    },
  ],
})

import LinkBehavior from '@gain/shared/components/link-behavior'
import { loadingButtonClasses } from '@mui/lab/LoadingButton'
import { buttonClasses } from '@mui/material/Button'
import { alpha } from '@mui/material/styles'
import { Components, Theme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }

  interface ButtonPropsVariantOverrides {
    icon: boolean
    inline: boolean
  }
}

export const MuiButton = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    disableElevation: true,
    size: 'small',
    disableRipple: true,
    variant: 'text',
    color: 'grey',
    LinkComponent: LinkBehavior,
  },
  styleOverrides: {
    root: {
      borderRadius: 8,
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      lineHeight: theme.typography.button.lineHeight,
      padding: '6px 8px',
      minWidth: 48,
      transition: 'color 1ms linear', // Fixes rerender bug in safari
      textTransform: 'none',
    },
    startIcon: {
      fill: 'currentColor',
      marginLeft: 0,
      [`&.${buttonClasses.iconSizeSmall}`]: {
        marginLeft: 0,
      },
    },
    iconSizeSmall: {
      '& > *:first-child': {
        fontSize: 16,
      },
    },
    contained: {
      padding: '6px 8px',
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: theme.palette.grey['300'],
        color: theme.palette.text.primary,
        opacity: 0.3,
      },
    },
    containedPrimary: {
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    containedSecondary: {
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    sizeSmall: {
      padding: '6px 8px',
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      lineHeight: theme.typography.button.lineHeight,
    },
    containedSizeSmall: {
      padding: '6px 8px',
    },
    outlinedSizeSmall: {
      padding: '5px 7px',
    },
    outlined: {
      padding: '6px 8px',
      boxShadow: theme.palette.shadow.level1c,
      borderColor: theme.palette.divider,
    },
    outlinedPrimary: {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    textSizeSmall: {
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      lineHeight: theme.typography.button.lineHeight,
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: 16,
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'grey' },
      style: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
      },
    },
    {
      props: { variant: 'outlined', color: 'grey' },
      style: {
        color: theme.palette.text.primary,
        borderColor:
          theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.action.disabledBackground}`,
        },
        '&:hover': {
          borderColor:
            theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
          backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
        },
      },
    },
    {
      props: { color: 'grey', variant: 'text' },
      style: {
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
        },
      },
    },
    {
      props: { color: 'error', variant: 'outlined' },
      style: {
        borderColor: theme.palette.divider,
        '&:hover': {
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.divider,
        },
      },
    },
    {
      props: { variant: 'icon' },
      style: {
        minWidth: 'fit-content',
        padding: 6,

        [`&.${loadingButtonClasses.root}>*:first-child`]: {
          fontSize: 20,
        },
      },
    },
    {
      props: { variant: 'icon', color: 'primary' },
      style: {
        color: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      },
    },
    {
      props: { variant: 'inline' },
      style: {
        display: 'inline-flex',
        minWidth: 'fit-content',
        padding: 0,
        fontSize: 'inherit',
        fontWeight: 'bold',
        '&:hover': {
          borderColor: 'unset',
          backgroundColor: 'unset',
          textDecoration: 'underline',
        },
        [`& .${buttonClasses.endIcon}`]: {
          marginLeft: 0,
        },
      },
    },
  ],
})

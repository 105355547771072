import { DealStatus } from '@gain/rpc/shared-model'

import { parseDealStatus } from './parse-deal-status'

export function formatDealStatus(status: DealStatus | string | null) {
  if (status === null) {
    return ''
  }

  const parsed = parseDealStatus(status)

  switch (parsed) {
    case 'live':
      return 'Live / rumoured'
    case 'aborted':
      return 'Aborted'
    default:
      return ''
  }
}

import { Components, Theme } from '@mui/material/styles'

export const MuiDialogActions = (theme: Theme): Components['MuiDialogActions'] => ({
  styleOverrides: {
    root: {
      padding: theme.spacing(1.5),
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.grey['50'],
    },
  },
})

import { ArrayElement } from '@gain/utils/typescript'

/**
 * Boolean operators determine the {ListFilter} field and value type
 */
export type ListFilterBooleanOperator = 'and' | 'or' | 'not'
export const LIST_FILTER_BOOLEAN_OPERATORS = ['and', 'or', 'not']

/**
 * Regular operators determine the {ListFilter} field and value type
 */
export type ListFilterNonBooleanOperator =
  | '='
  | '!='
  | '>'
  | '<'
  | '<='
  | '>='
  | 'in'
  | 'within'
  | '<@'
  | '@>'

export const LIST_FILTER_NON_BOOLEAN_OPERATORS = [
  '=',
  '!=',
  '>',
  '<',
  '<=',
  '>=',
  'in',
  'within',
  '<@',
  '@>',
]

/**
 * All available {ListFilter} operators
 */
export type ListFilterOperators = ListFilterBooleanOperator | ListFilterNonBooleanOperator

/**
 * @deprecated Use object instead
 */
export type ListItemBase = Record<string, any>

/**
 * Extracts the string property names of a type as a union.
 *
 * keyof <type extends object> returns a (string | number | symbol) union which causes issues when
 * you need string index access.
 *
 * @example
 *
 *   interface Car {
 *     speed: number
 *     doors: number
 *   }
 *
 *   ListItemKey<Car> // = 'speed' | 'doors'
 *
 */
export type ListItemKey<Item extends object> = Extract<keyof Item, string>

/**
 * Returns the {ListFilter} field type. For {ListFilterBooleanOperators} the type is an empty string
 * (e.g. ''), otherwise the type must match a key of the given {Item}.
 *
 * @example
 *
 *   interface Person {
 *     name: string
 *     age: number
 *   }
 *
 *   ListFilterFieldType<Person, '='> // = 'name' | 'age'
 *   ListFilterFieldType<Person, 'or'> // = ''
 *
 */
export type ListFilterFieldType<
  Item extends object,
  Operator extends ListFilterOperators
> = Operator extends ListFilterNonBooleanOperator ? ListItemKey<Item> : ''

/**
 * Returns a valid {ListFilter} value type given an {Item} and a {Field}
 *
 * @example
 *
 *   interface Person {
 *     name: string
 *     age: number
 *     children: string[]
 *   }
 *
 *   NonBooleanListFilterValue<Person, 'name'> // = string | string[]
 *   NonBooleanListFilterValue<Person, 'age'> // = number | number[]
 *   NonBooleanListFilterValue<Person, 'children'> // = string | string[]
 */
export type NonBooleanListFilterValue<
  Item extends object,
  Field extends ListItemKey<Item> = ListItemKey<Item>
> = Item[Field] extends Array<any>
  ? ArrayElement<Item[Field]> | Array<ArrayElement<Item[Field]>>
  : Item[Field] | Array<Item[Field]>

/**
 * Returns a valid {ListFilter} value type given an {Item}, {Operator} and a {Field}
 *
 * @example
 *
 *  interface Person {
 *     name: string
 *   }
 *
 *  ListFilterValue<Person, 'or', ''> // = ListFilter<Person, 'or', ''>[]
 *  ListFilterValue<Person, '=', 'name'> // = string | string[]
 *  ListFilterValue<Person, 'or', 'name'> // = error
 *  ListFilterValue<Person, '=', ''> // = error
 *
 */
export type ListFilterValue<
  Item extends object,
  Operator extends ListFilterOperators,
  Field extends ListFilterFieldType<Item, Operator>
> = Operator extends ListFilterBooleanOperator
  ? Array<ListFilter<Item>>
  : Field extends ListItemKey<Item>
  ? NonBooleanListFilterValue<Item, Field>
  : never

/**
 * Note that the top-level filters is an array of filters that is implicitly combined as an `and`-boolean.
 * To create a top-level `or`-filter, add it as the single top-level filter.
 */
export interface ListFilter<
  Item extends object = object,
  Operator extends ListFilterOperators = ListFilterOperators,
  Field extends ListFilterFieldType<Item, Operator> = ListFilterFieldType<Item, Operator>
> {
  /**
   * The property of the list item type that is filtered.
   * In case of a boolean filter (operator is `and`, `or` or `not`) this field must be empty.
   */
  field: Field

  /**
   * `=`, `!=`,`<`, `>`, `~`, `<=`, `>=`, `and`, `or`, `not`, `in` or `within`
   */
  operator: Operator

  /**
   * For non-boolean operators this value must be a value of the same type as the filtered property.
   * Arrays of values can be used for strings and number in combination with the `=` and `!=` operators.
   * For boolean operators (and, or, not) this value must be a `array(Filter)`.
   */
  value: ListFilterValue<Item, Operator, Field>
}

export type ListFilterBoolean<
  Item extends object = object,
  Operator extends ListFilterBooleanOperator = ListFilterBooleanOperator,
  Field extends ListFilterFieldType<Item, Operator> = ListFilterFieldType<Item, Operator>
> = ListFilter<Item, Operator, Field>

export type ListFilterNonBoolean<
  Item extends object = object,
  Operator extends ListFilterNonBooleanOperator = ListFilterNonBooleanOperator,
  Field extends ListFilterFieldType<Item, Operator> = ListFilterFieldType<Item, Operator>
> = ListFilter<Item, Operator, Field>

export type ListSortDirection = 'asc' | 'desc'

export interface ListSort<Item extends object> {
  direction: ListSortDirection
  field: ListItemKey<Item>
}

export interface ListArgs<Item extends object, FilterItem extends Item = Item> {
  search: string | null
  filter: ListFilter<FilterItem>[]
  sort: ListSort<Item>[]
  limit: number
  page: number
}

export interface ListParams<FilterItem extends object> {
  search: string
  filter: ListFilter<FilterItem>[]
  sort: string[]
  limit: number
  page: number
}

/**
 * ListCounts provides information about the total number of items in a list and the count
 * after applying filters.
 */
export interface ListCounts {
  total: number
  filtered: number
}

export interface List<Item extends object, FilterItem extends Item = Item> {
  items: Item[]
  counts: ListCounts
  args: ListParams<FilterItem>
}

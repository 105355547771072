import { formatAssetShareholderShareLabel } from '@gain/utils/asset'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import { useRef } from 'react'

import Table, { createTableColumns } from '../../../../common/table'
import InvestorContentLink from '../../../../features/investor/investor-content-link'
import InvestorStrategyContentLink from '../../../../features/investor-strategy/investor-strategy-content-link'
import { Shareholder } from './asset-shareholder-util'

const columns = createTableColumns<Shareholder>(
  {
    field: 'investor',
    headerName: 'Name',
    renderCell: ({ value }) => {
      if (value === null) {
        return '-'
      }

      return (
        <InvestorContentLink
          id={value.id}
          logoFileUrl={value.logoFileUrl}
          name={value.name}
        />
      )
    },
  },
  {
    field: 'share',
    headerName: 'Share',
    valueFormatter: ({ value }) => formatAssetShareholderShareLabel(value),
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    renderCell: ({ value }) => <InvestorStrategyContentLink strategy={value} />,
  }
)

const columnVisibility: ColumnVisibilityModel<Shareholder> = {
  strategy: 552,
}

export interface TableAssetShareholderProps {
  shareholders: Shareholder[]
}

export default function TableAssetShareholder({ shareholders }: TableAssetShareholderProps) {
  const ref = useRef<HTMLDivElement>(null)

  const visibleColumns = useVisibleColumns(ref, columns, columnVisibility)

  return (
    <Table
      ref={ref}
      columns={visibleColumns}
      rows={shareholders}
    />
  )
}

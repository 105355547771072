import { ChevronRightIcon } from '@gain/components/icons'
import { AnnualReport } from '@gain/rpc/app-model'
import { useAnnualReportFileEntries } from '@gain/utils/annual-report'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import AnnualReportsTooltip from './annual-reports-tooltip'

const StyledSpan = styled('span')({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.grey['600'],
  fontSize: 16,
}))

interface AssetLegalEntitiesTableEarlierAnnualReportsCellProps {
  annualReports: AnnualReport[]
}

export function AssetLegalEntitiesTableEarlierAnnualReportsCell({
  annualReports,
}: AssetLegalEntitiesTableEarlierAnnualReportsCellProps) {
  // Get hold of AR + ARFile pairs, bail out if we don't have any
  const annualReportFiles = useAnnualReportFileEntries(annualReports)
  if (annualReportFiles.length === 0) {
    return <>-</>
  }

  return (
    <AnnualReportsTooltip annualReportFileEntries={annualReportFiles}>
      <Stack
        alignItems={'center'}
        direction={'row'}>
        <StyledSpan>{annualReportFiles.length} more</StyledSpan>
        <StyledChevronRightIcon />
      </Stack>
    </AnnualReportsTooltip>
  )
}

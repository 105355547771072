import { ListItemKey } from '@gain/rpc/list-model'

import { FilterModel } from '../filter-model'
import { FilterValueGroup } from '../filter-value-model'

export interface FilterFormValues<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  groups: FilterValueGroup<Item, FilterField>[]
}

export function valuesToFilterModel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(values: FilterFormValues<Item, FilterField>): FilterModel<Item, FilterField> {
  return values.groups
}

export function filterModelToValues<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(values: FilterModel<Item, FilterField>): FilterFormValues<Item, FilterField> {
  return {
    groups: values,
  }
}

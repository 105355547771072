import {
  ASSET_PAGE_PATH,
  BOOKMARKS_LIST_PATH,
  BOOKMARKS_LIST_SUMMARY_PATH,
  BookmarksPathParams,
} from '@app/routes/utils'
import { useRpcClient } from '@gain/api/swr'
import { useIsAuthenticated } from '@gain/modules/auth'
import { useCallback, useEffect, useRef } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import { useMutateBookmarksList } from './bookmarks-list-hooks'

/**
 * The lastViewedAt timestamp is used to highlight assets that have new
 * financials since the user's last visit.
 */
export function useSetBookmarksListLastViewedAt(updateCache = true) {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()

  return useCallback(
    async (listId: number, lastViewedAt?: Date): Promise<boolean> => {
      try {
        const newDate = lastViewedAt ? lastViewedAt.toISOString() : new Date().toISOString()
        await fetcher({
          method: 'lists.setCustomAssetListLastViewedAt',
          params: {
            id: listId,
            lastViewedAt: newDate,
          },
        })

        if (!updateCache) {
          return true
        }

        // Refresh updatedAssetCount
        await mutateList(listId, (list) => {
          if (list) {
            return { ...list, lastViewedAt: newDate, updatedAssetCount: 0 }
          }
          return undefined
        })
        return true
      } catch (err) {
        return false // Swallow errors, this is on a best-effort basis
      }
    },
    [fetcher, updateCache, mutateList]
  )
}

/**
 * Automatically updates the lastViewedAt timestamp when:
 *
 * 1. User navigates to the /bookmarks/list/:listId page
 * 2. User navigates to any other page that is not a tab in the bookmarks page
 *    or an asset page.
 */
export function useUpdateBookmarksListLastViewedAt() {
  const isAuthenticated = useIsAuthenticated()
  const history = useHistory()
  const lastListId = useRef<number | null>(null)
  const updateLastViewedAt = useSetBookmarksListLastViewedAt()

  useEffect(() => {
    // Stop listener when component unmounts
    return history.listen(async (location) => {
      const listId = lastListId.current
      if (listId) {
        // Match on /bookmarks/list/:listId/*
        const inexactMatch = matchPath<BookmarksPathParams>(location.pathname, {
          path: BOOKMARKS_LIST_PATH,
        })

        // Don't reset lastViewedAt on /asset/:id/* page visits
        const excludeMatch = matchPath<BookmarksPathParams>(location.pathname, {
          path: ASSET_PAGE_PATH,
        })

        if (!excludeMatch && (!inexactMatch || parseInt(inexactMatch.params.listId) !== listId)) {
          lastListId.current = null

          // User could have logged out at this point
          if (isAuthenticated) {
            await updateLastViewedAt(listId)
          }
        }
      }

      // Only when visiting /bookmarks/list/:listId/summary exactly store ID
      const exactMatch = matchPath<BookmarksPathParams>(location.pathname, {
        path: BOOKMARKS_LIST_SUMMARY_PATH,
        exact: true,
      })
      if (exactMatch) {
        lastListId.current = parseInt(exactMatch.params.listId)
      }
    })
  }, [history, isAuthenticated, updateLastViewedAt])
}

import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      aria-labelledby={`full-width-tab-${index}`}
      id={`full-width-tabpanel-${index}`}
      role={'tabpanel'}
      {...other}>
      {children}
    </div>
  )
}

import { formatMonthYear } from '@gain/utils/date'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ChipDealStatus from '../../../../features/deal/deal-status-chip'
import { DealRow } from './table-deals-columns'

export interface TableCellDealAnnouncementDateProps {
  deal: DealRow
}

export default function TableCellDealAnnouncementDate({
  deal,
}: TableCellDealAnnouncementDateProps) {
  const label = formatMonthYear(deal.announcementDate?.month, deal.announcementDate?.year)

  if (!deal.dealStatus) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{label}</>
  }

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      spacing={1}>
      <Typography
        variant={'inherit'}
        noWrap>
        {label}
      </Typography>
      <ChipDealStatus dealStatus={deal.dealStatus} />
    </Stack>
  )
}

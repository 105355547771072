export interface Rating<Key extends string> {
  label: string
  key: Key
  options: RatingOption[]
  suffix?: string
  explainer?: string
}

export interface RatingOption {
  value: number
  label: string
}

export function ratingOption(value: number, label: string): RatingOption {
  return { value, label }
}

export interface RatingGroup<Key extends string> {
  label?: string
  ratings: Array<Rating<Key>>
}

export type RatingMap<Key extends string> = Record<Key, number | null>

import { FinancialResultAmountType } from '@gain/rpc/shared-model'
import { useMemo } from 'react'

import { FINANCIAL_RESULT_AMOUNT_KEYS, FinancialResult } from './financial-result-model'

export function isAmountEstimated(amountType?: FinancialResultAmountType) {
  return FinancialResultAmountType.Estimated === amountType
}

function hasAmountType(financials: FinancialResult[], type: FinancialResultAmountType) {
  return financials.some((result) =>
    FINANCIAL_RESULT_AMOUNT_KEYS.some((key) => {
      return result[key]?.amountType === type
    })
  )
}

export function useDefinedAmountTypes(financials: FinancialResult[]) {
  return useMemo(() => {
    return {
      hasForecasts: financials.some((result) => result.isForecast),
      hasActuals: hasAmountType(financials, FinancialResultAmountType.Actual),
      hasEstimates: hasAmountType(financials, FinancialResultAmountType.Estimated),
      hasAIEstimates: hasAmountType(financials, FinancialResultAmountType.Ai),
    }
  }, [financials])
}

import { Asset, AssetListItem } from '@gain/rpc/app-model'

export const buildAssetFileName = (asset: Asset | AssetListItem, file: string) => {
  if ('generalInfo' in asset) {
    return `${asset.generalInfo?.name}${
      asset.generalInfo?.headquarters ? ` (${asset.generalInfo?.headquarters})` : ''
    } - Gain.pro ${file}`
  }

  return `${asset.name} - Gain.pro ${file}`
}

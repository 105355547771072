import { useEffect, useState } from 'react'

import matchesSearchQuery from '../search/matches-search-query'
import {
  DropdownMenuOption,
  DropdownMenuOptions,
  DropdownMenuOptionType,
  isDropdownMenuGroup,
  isDropdownMenuItem,
} from './dropdown-menu-model'

/**
 * Hook to filter the dropdown items based on the search query.
 */
export function useActiveOptions<T extends DropdownMenuOption>(
  options: DropdownMenuOptions<T>,
  searchQuery: string | null
): DropdownMenuOptions<T> {
  const [activeOptions, setActiveOptions] = useState(options)

  useEffect(() => {
    if (!searchQuery) {
      setActiveOptions(options)
      return
    }

    // Recursively check items that match the search query. Parent group
    // labels are used to allow matching options by the label of the parent
    // group.
    const addToActiveOptions = (
      acc: DropdownMenuOptionType<T>[],
      option: DropdownMenuOptionType<T>,
      parentGroupLabels: string[] = []
    ) => {
      if (isDropdownMenuGroup(option)) {
        const matches = option.children.reduce((matchesAcc, childItem) => {
          return addToActiveOptions(matchesAcc, childItem, parentGroupLabels.concat(option.label))
        }, new Array<DropdownMenuOptionType<T>>())

        if (matches.length) {
          return acc.concat({
            ...option,
            children: matches,
          })
        }

        return acc
      }

      // Add the option to the results if it matches by label, search aliases
      // and/or parent group label.
      if (
        isDropdownMenuItem(option) &&
        matchesSearchQuery(
          option.label,
          searchQuery,
          parentGroupLabels.concat(option.searchAliases || [])
        )
      ) {
        return acc.concat(option)
      }

      return acc
    }

    // Update the active options list with the matched items.
    setActiveOptions(
      options.reduce(
        (acc, option) => addToActiveOptions(acc, option),
        new Array<DropdownMenuOptionType<T>>()
      )
    )
  }, [options, searchQuery])

  return activeOptions
}

import { DealListItem, ExportDealsParams } from '@gain/rpc/app-model'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { ExportListButton, ExportListButtonProps } from '../../../../features/export/export-button'
import ListDeals from './list-deals'
import TableDealsContainer from './table-deals'

export interface CardAssetDealsProps extends CardProps {
  title: string
  deals: DealListItem[]
  disableDealMetrics?: boolean
  disableDealTargetMetrics?: boolean
  exportContainerProps: Omit<ExportListButtonProps<'data.exportDeals', ExportDealsParams>, 'method'>
}

export default function CardAssetDeals({
  title,
  deals,
  disableDealMetrics,
  disableDealTargetMetrics,
  exportContainerProps,
  ...cardProps
}: CardAssetDealsProps) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Card {...cardProps}>
      <CardHeader
        actions={
          <ExportListButton
            color={'primary'}
            method={'data.exportDeals'}
            tooltipMode={'always'}
            variant={'icon'}
            {...exportContainerProps}
          />
        }
        title={title}
      />
      {!isXs && (
        <TableDealsContainer
          deals={deals}
          disableDealMetrics={disableDealMetrics}
          disableDealTargetMetrics={disableDealTargetMetrics}
        />
      )}
      {isXs && (
        <CardContent sx={{ pt: 1, pb: 2 }}>
          <ListDeals deals={deals} />
        </CardContent>
      )}
    </Card>
  )
}

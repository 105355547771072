import { CompanyIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { AssetListItem } from '@gain/rpc/app-model'
import { formatMonthYear } from '@gain/utils/date'
import { formatNumber } from '@gain/utils/number'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { useRef } from 'react'

import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import YearChip from '../../../common/year-chip'
import FinancialValue from '../../financial/financial-value'
import { formatAssetOwnershipType } from '../asset-ownership'

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

export type AssetTooltipTitleProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  asset: AssetListItem
}

export default function AssetTooltipTitle({ asset, ...props }: AssetTooltipTitleProps) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={ref}
      {...props}>
      {asset && (
        <StyledContainer
          direction={'column'}
          spacing={2}>
          <TooltipHeader
            defaultIcon={<CompanyIcon />}
            logoSrc={asset.logoFileUrl}
            region={asset.region}
            subtitle={asset.description}
            title={asset.name}
          />
          <KeyValueList
            spacing={0.5}
            dense>
            <KeyValueListItem
              label={'Revenue'}
              value={
                <FinancialValue
                  amount={asset.revenueEur}
                  year={asset.revenueYear}
                />
              }
            />

            <KeyValueListItem
              label={'EBITDA'}
              value={
                <FinancialValue
                  amount={asset.ebitdaEur}
                  year={asset.ebitdaYear}
                />
              }
            />

            <KeyValueListItem
              label={'FTEs'}
              value={
                <>
                  {formatNumber(asset.fte)}

                  <YearChip
                    sx={{ ml: 0.5 }}
                    year={asset.fteYears[asset.fteYears.length - 1]}
                  />
                </>
              }
            />
          </KeyValueList>

          <Divider />

          <KeyValueList
            spacing={0.5}
            dense>
            <KeyValueListItem
              label={'Ownership type'}
              value={formatAssetOwnershipType(asset.ownership)}
            />

            {asset.majorityOwnerName && asset.majorityOwnerId && (
              <KeyValueListItem
                label={'Majority investor'}
                value={asset.majorityOwnerName}
              />
            )}

            {asset.lastDealYear !== null && (
              <KeyValueListItem
                label={'Last deal'}
                value={formatMonthYear(asset.lastDealMonth, asset.lastDealYear)}
              />
            )}
          </KeyValueList>
        </StyledContainer>
      )}
    </div>
  )
}

import { PlusIcon } from '@gain/components/icons'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import React, { MouseEvent } from 'react'

const StyledChip = styled(Chip)({
  backgroundColor: 'transparent',
})

interface AddFilterButtonProps {
  onClick: (event: MouseEvent<HTMLDivElement>) => void
}

export default function AddFilterButton({ onClick }: AddFilterButtonProps) {
  return (
    <StyledChip
      color={'info'}
      icon={<PlusIcon />}
      label={'Add filter'}
      onClick={onClick}
      size={'medium'}
      variant={'outlined'}
      clickable
    />
  )
}

import Container, { ContainerProps } from '@mui/material/Container'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { forwardRef } from 'react'

export interface ProfileTabContainerProps extends ContainerProps {
  stickyVariant?: 'tabs' | 'no-tabs'
}

export const profileTabBarContainerClasses = generateUtilityClasses('ProfileTabBarContainer', [
  'root',
  'sticky',
  'stickyNoTabs',
])

export const ProfileTabContainer = styled(
  forwardRef<HTMLDivElement, ProfileTabContainerProps>(
    ({ className, stickyVariant, ...props }: ProfileTabContainerProps, ref) => (
      <Container
        ref={ref}
        className={clsx(className, profileTabBarContainerClasses.root, {
          [profileTabBarContainerClasses.sticky]: stickyVariant === 'tabs',
          [profileTabBarContainerClasses.stickyNoTabs]: stickyVariant === 'no-tabs',
        })}
        {...props}
      />
    )
  ),
  {
    shouldForwardProp: (prop) => prop !== 'disableGuttersTop',
  }
)<
  ProfileTabContainerProps & {
    disableGuttersTop?: boolean
  }
>(({ theme, disableGuttersTop }) => ({
  minWidth: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  marginTop: disableGuttersTop ? 0 : theme.spacing(4),
  paddingBottom: theme.spacing(2),
}))

import { AssetSegmentation } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import { useRef } from 'react'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import AssetSegmentationList from './asset-segmentation-list'
import AssetSegmentationTable from './asset-segmentation-table'

export interface AssetSegmentationDetailsCardProps extends CardProps {
  segmentation: AssetSegmentation
}

export default function AssetSegmentationCard({
  segmentation,
  ...cardProps
}: AssetSegmentationDetailsCardProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  return (
    <Card
      ref={ref}
      {...cardProps}>
      <CardHeader title={segmentation.title} />
      {width >= 624 && <AssetSegmentationTable segmentation={segmentation} />}
      {width < 624 && (
        <CardContent>
          <AssetSegmentationList
            segmentation={segmentation}
            sx={{ pt: 1, pb: 2 }}
          />
        </CardContent>
      )}
    </Card>
  )
}

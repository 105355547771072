import { useRpcClient } from '@gain/api/swr'
import { AssetListItem, RpcMethodMap } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { formatListArgs, listFilter } from '@gain/rpc/utils'
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { formLabelClasses } from '@mui/material/FormLabel'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import React, { useCallback, useState } from 'react'
import { useField } from 'react-final-form'
import { useDebouncedCallback } from 'use-debounce'

import { MUI_AUTOCOMPLETE_RESET_REASONS } from '../../../../theme/components/mui-autocomplete'
import { FilterConfigSimilarTo, FilterSimilarToValue } from '../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../use-track-filter-event'

type AutocompleteOption = {
  id: number
  name: string
  regions: string[]
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& > .${inputBaseClasses.root}`]: { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)' },
  [`& .${formLabelClasses.root}`]: {
    ...theme.typography.body2,
  },
}))

export interface FilterSimilarToProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigSimilarTo<Item, FilterField>
}

export default function FilterSimilarTo<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterSimilarToProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()

  // We still use react-final-form to manage the filter bar form
  const field = useField<FilterSimilarToValue>(path)
  const [open, setOpen] = useState(false)
  const selectedOption = field.input.value as AutocompleteOption | null
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const fetcher = useRpcClient<RpcMethodMap>()

  // Returns an asset by ID
  const getGetAssetById = useCallback(
    (id: number) => {
      return fetcher({
        method: 'data.listAssets',
        params: formatListArgs({
          limit: 1,
          filter: [listFilter<AssetListItem>('id', '=', id)],
        }),
      })
    },
    [fetcher]
  )

  const handleInputChange = useDebouncedCallback(
    async (_, newValue, reason: AutocompleteInputChangeReason) => {
      if (newValue.trim() === '' || MUI_AUTOCOMPLETE_RESET_REASONS.includes(reason)) {
        // When the input is empty the only visible option is the currently selected option
        setOptions([])
      } else {
        // When there is an input value we merge the selected option with the suggested options
        const nextOptions = await fetcher({
          method: 'data.search',
          params: {
            query: newValue,
            assets: true,
            industries: false,
            entities: false,
            investors: false,
            advisors: false,
            conferenceEditions: false,
            limit: 5,
          },
        })

        let newOptions = new Array<AutocompleteOption>()

        if (selectedOption) {
          newOptions = [selectedOption]
        }

        if (nextOptions) {
          newOptions = [...newOptions, ...nextOptions]
        }

        setOptions(newOptions)
        setOpen(true)
      }
    },
    350
  )

  const handleChange = useCallback(
    async (_, newValue: AutocompleteOption | null, reason: AutocompleteChangeReason) => {
      setOpen(false)

      if (reason === 'clear') {
        setOptions([])
        field.input.onChange(null as never)
        return
      }

      if (newValue === null) {
        field.input.onChange(null as never)
      } else {
        try {
          const result = await getGetAssetById(newValue.id)
          const option: AutocompleteOption = {
            id: result.items[0].id,
            name: result.items[0].name || '',
            regions: [result.items[0].region || ''],
          }
          // the selected option must be available within the options array
          // since we cannot control when the selectedOption(field.input) and
          // options are updated, there is a slight moment of inconsistency
          // causing a warning in the console.
          setOptions([option])
          field.input.onChange(result.items[0].id)
          trackEvent(`Filter ${filter.label} value change`, option.name || '')
        } catch (e) {
          setOptions([])
        }
      }
    },
    [field.input, filter.label, getGetAssetById, trackEvent]
  )

  return (
    <Autocomplete
      filterOptions={(availableOptions) =>
        availableOptions.filter((option) => selectedOption?.id !== option?.id)
      }
      forcePopupIcon={false}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, currentValue) => option?.id === currentValue?.id}
      noOptionsText={'No companies found'}
      onChange={handleChange}
      onInputChange={handleInputChange}
      open={open}
      options={options}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={''}
          placeholder={'Enter company name'}
          fullWidth
        />
      )}
      renderOption={(props, item) => (
        <li
          {...props}
          key={item.id}>
          {item.name} ({item.regions[0]})
        </li>
      )}
      size={'small'}
      value={selectedOption || undefined}
      disableClearable
      fullWidth
    />
  )
}

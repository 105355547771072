import { useIsXs } from '@gain/utils/responsive'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { Manager } from './asset-management-util'
import ManagementList from './management-list'
import ManagementTable from './management-table'

interface ManagementCardProps extends CardProps {
  managers: Manager[]
}

export default function ManagementCard({ managers, ...cardProps }: ManagementCardProps) {
  const isXs = useIsXs()

  return (
    <Card {...cardProps}>
      <CardHeader title={'Management'} />
      {!isXs && <ManagementTable managers={managers} />}
      {isXs && (
        <CardContent sx={{ pt: 1, pb: 2 }}>
          <ManagementList managers={managers} />
        </CardContent>
      )}
    </Card>
  )
}

import { useEffect } from 'react'
import { VirtualItem } from 'react-virtual'

export const useLoadMoreEffect = (
  currentSize: number,
  totalSize: number,
  isLoadingMore: boolean,
  onLoadMore: () => void,
  virtualItems: VirtualItem[]
) => {
  useEffect(() => {
    if (
      virtualItems.length > 0 &&
      virtualItems[virtualItems.length - 1].index >= currentSize - 20 &&
      currentSize < totalSize &&
      !isLoadingMore
    ) {
      onLoadMore()
    }
  }, [currentSize, totalSize, onLoadMore, isLoadingMore, virtualItems])
}

import {
  ListArgs,
  ListFilter,
  ListFilterFieldType,
  ListFilterOperators,
  ListFilterValue,
  ListParams,
  ListSort,
  ListSortDirection,
} from '@gain/rpc/list-model'
import { isTruthy } from '@gain/utils/common'
import { addYears } from 'date-fns/addYears'
import { format } from 'date-fns/format'

export function formatListArgs<Item extends object = object>({
  sort = [],
  filter = [],
  search = '',
  limit = 100,
  page = 0,
  ...rest
}: Partial<ListArgs<Item>> = {}): ListParams<Item> {
  return {
    sort: sort.map(serializeListSort),
    filter: filter as ListFilter<
      Item,
      ListFilterOperators,
      ListFilterFieldType<Item, ListFilterOperators>
    >[],
    limit,
    page,
    search: search || '',
    ...rest,
  }
}

function formatSortDirection(direction: ListSortDirection) {
  return direction === 'desc' ? '-' : ''
}

export function serializeListSort<T extends object>(sort: ListSort<T>): string {
  return `${formatSortDirection(sort.direction)}${sort.field}`
}

export function serializeListSortArray<Item extends object>(sort: ListSort<Item>[]): string[] {
  return sort.map(serializeListSort)
}

export function deserializeListSort(sort: string): ListSort<any> {
  if (sort.startsWith('-')) {
    return {
      direction: 'desc',
      field: sort.slice(1),
    }
  } else {
    return {
      direction: 'asc',
      field: sort,
    }
  }
}

/**
 * The in operator expects a type that does not directly map to the property type
 * which is how all the other filters work.
 * We need to have a look at the typings to solve this
 */
export function listSourceFilter<
  Item extends object,
  Operator extends ListFilterOperators = ListFilterOperators,
  Field extends ListFilterFieldType<Item, Operator> = ListFilterFieldType<Item, Operator>
>(
  field: Field,
  value: {
    source: string
    // eslint-disable-next-line @typescript-eslint/member-ordering
    [key: string]: string | number | null
  }
): ListFilter<Item, Operator, Field> {
  return listFilter<Item>(field, 'in', value as never) as ListFilter<Item, Operator, Field>
}

export const listFilter = <
  Item extends object,
  Operator extends ListFilterOperators = ListFilterOperators,
  Field extends ListFilterFieldType<Item, Operator> = ListFilterFieldType<Item, Operator>
>(
  field: Field,
  operator: Operator,
  value: ListFilterValue<Item, Operator, Field>
): ListFilter<Item, Operator, Field> => ({
  field,
  operator,
  value,
})

export function listOrFilter<Item extends object>(
  value: ListFilterValue<Item, 'or', ''>
): ListFilter<Item, 'or', ''> {
  return {
    field: '',
    operator: 'or',
    value,
  }
}

export function listAndFilter<Item extends object>(
  value: ListFilterValue<Item, 'and', ''>
): ListFilter<Item, 'and', ''> {
  return {
    field: '',
    operator: 'and',
    value,
  }
}

export function listFilters<
  Item extends object,
  Operator extends ListFilterOperators = ListFilterOperators,
  Field extends ListFilterFieldType<Item, Operator> = ListFilterFieldType<Item, Operator>
>(
  ...filters: Array<ListFilter<Item, Operator, Field> | null | false | undefined>
): Array<ListFilter<Item, Operator, Field>> {
  return filters.filter(isTruthy)
}

export function listSort<Item extends object>(
  field: ListSort<Item>['field'],
  direction: ListSort<Item>['direction']
): ListSort<Item> {
  return { field, direction }
}

export function listSortArray<Item extends object>(
  ...sorts: Array<ListSort<Item> | null | false | undefined>
): Array<ListSort<Item>> {
  return sorts.filter(isTruthy)
}

export function createListFilterForPastFiveYears<Item extends object>(
  key: Extract<keyof Item, string>
): ListFilter<Item> {
  return listFilter(key, '>=', format(addYears(new Date(), -5), 'yyyy-MM') as never)
}

import {
  CopyIcon,
  MailIcon,
  SlashIcon,
  ToolIcon,
  ToolOffIcon,
  Trash2Icon,
  UserCheckIcon,
} from '@gain/components/icons'
import { UserListItem } from '@gain/rpc/app-model'
import { UserStatus } from '@gain/rpc/shared-model'
import { isAdministrator } from '@gain/utils/user'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { alpha, styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'

import DialogHeader from '../../common/dialog-header'
import { formatTeamMemberStatus } from './format-team-member-status'
import {
  useActivateUserAccountCallback,
  useCopyInvitationLinkCallback,
  useDeactivateUserAccountCallback,
  useDeleteUserAccountCallback,
  useGiveAdminRoleCallback,
  useRemoveAdminRoleCallback,
  useResendInvitationCallback,
} from './team-member-hooks'

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
}))

const StyledButtonError = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover, &:focus': {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },
}))

export type TeamMemberDetailsDialogProps = MuiDialogProps & {
  user: UserListItem
  onUserUpdated?: () => void
}

export default function TeamMemberDetailsDialog({
  user,
  open,
  onClose,
  onUserUpdated,
  ...DialogProps
}: TeamMemberDetailsDialogProps) {
  const deactivateUser = useDeactivateUserAccountCallback()
  const activateUser = useActivateUserAccountCallback()
  const deleteUser = useDeleteUserAccountCallback()
  const resendInvitation = useResendInvitationCallback()
  const giveAdminRole = useGiveAdminRoleCallback()
  const removeAdminRole = useRemoveAdminRoleCallback()
  const copyInvitationLink = useCopyInvitationLinkCallback()

  return (
    <Dialog
      {...DialogProps}
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={(e) => onClose?.(e, 'backdropClick')}
        title={`${user?.firstName} ${user?.lastName}`}
        disableDivider
      />
      <DialogContent dividers>
        <Grid
          direction={'column'}
          spacing={2}
          container>
          <Grid item>
            <Alert severity={'info'}>
              Account status: {user && formatTeamMemberStatus(user.status)}
            </Alert>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <TextField
              label={'Name'}
              slotProps={{
                input: { readOnly: true },
              }}
              value={`${user?.firstName} ${user?.lastName}`}
              variant={'outlined'}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label={'Email address'}
              slotProps={{
                input: { readOnly: true },
              }}
              value={user?.email}
              variant={'outlined'}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid
            direction={'column'}
            spacing={1}
            container
            item>
            <Grid item>
              <Typography variant={'subtitle2'}>Options</Typography>
            </Grid>
            {user.status === UserStatus.Invited && (
              <Grid item>
                <StyledButton
                  onClick={() => resendInvitation(user.userId)}
                  startIcon={<MailIcon />}>
                  Resend invitation email
                </StyledButton>
              </Grid>
            )}
            {user.status === UserStatus.Invited && (
              <Grid item>
                <StyledButton
                  onClick={() => copyInvitationLink(user.userId)}
                  startIcon={<CopyIcon />}>
                  Copy invitation link
                </StyledButton>
              </Grid>
            )}
            {!isAdministrator(user.role) && (
              <Grid item>
                <StyledButton
                  onClick={() => giveAdminRole(user.userId, onUserUpdated)}
                  startIcon={<ToolIcon />}>
                  Give admin rights
                </StyledButton>
              </Grid>
            )}
            {isAdministrator(user.role) && (
              <Grid item>
                <StyledButton
                  onClick={() => removeAdminRole(user.userId, onUserUpdated)}
                  startIcon={<ToolOffIcon />}>
                  Remove admin rights
                </StyledButton>
              </Grid>
            )}
            {user.status === UserStatus.Deactivated && (
              <Grid item>
                <StyledButton
                  onClick={() => activateUser(user.userId, onUserUpdated)}
                  startIcon={<UserCheckIcon />}>
                  Activate account
                </StyledButton>
              </Grid>
            )}
            {user.status === UserStatus.Active && (
              <Grid item>
                <StyledButton
                  onClick={() => deactivateUser(user.userId, onUserUpdated)}
                  startIcon={<SlashIcon />}>
                  Deactivate account
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledButtonError
          onClick={() =>
            deleteUser(user.userId, onUserUpdated).then(() => onClose?.({}, 'backdropClick'))
          }
          startIcon={<Trash2Icon />}>
          Delete account
        </StyledButtonError>
      </DialogActions>
    </Dialog>
  )
}

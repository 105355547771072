import * as echarts from 'echarts/core'
import { ElementEvent } from 'echarts/types/dist/shared'
import { MutableRefObject, useEffect, useRef } from 'react'

// Echarts doesn't export the events names, but we do need them for TypeScript.
// Hence we'll just define them here. If you need more events, you can find them
// in the ECharts documentation.
type ElementEventName = 'click' | 'mouseout' | 'mousemove'
type NativeEChartsEventHandler = (event: echarts.ECElementEvent) => void
export type EChartsEventHandler = (event: echarts.ECElementEvent, eCharts: echarts.ECharts) => void

/**
 * useEChartsEventHandler is a hook that allows to easily register event listeners
 * on ECharts. It properly handles `on()` and `off()` calls for the given event.
 */
export function useEChartsEventHandler(
  eventName: ElementEventName,
  eChartsInstanceRef: MutableRefObject<echarts.ECharts | null>,
  eventHandler?: EChartsEventHandler
) {
  const previousEventHandler = useRef<NativeEChartsEventHandler>()

  useEffect(() => {
    // Bail out if there's no ECharts instance set
    if (!eChartsInstanceRef.current) {
      return
    }

    // Capture reference to the right ECharts reference
    const eChartsInstance = eChartsInstanceRef.current

    // Remove the previous event handler, if any
    if (previousEventHandler.current != null) {
      eChartsInstanceRef.current.off(eventName, previousEventHandler.current)
    }

    // If there's a new event handler, set it
    if (eventHandler) {
      const eventHandlerWithInstance = (event: echarts.ECElementEvent) =>
        eventHandler(event, eChartsInstance)
      eChartsInstanceRef.current.on(eventName, eventHandlerWithInstance)
      previousEventHandler.current = eventHandlerWithInstance
    }
  }, [eChartsInstanceRef, eventName, eventHandler])
}

type NativeEChartsZrEventHandler = (event: ElementEvent) => void
export type EChartsZrEventHandler = (event: ElementEvent, eCharts: echarts.ECharts) => void
export type EChartsMouseZrEventHandler = (event: MouseEvent, eCharts: echarts.ECharts) => void

/**
 * useEChartsZrEventHandler is a hook that allows to easily register Zr event listeners
 * on ECharts. It properly handles `on()` and `off()` calls for the given event.
 *
 * Zr events are triggered everywhere, whereas ECharts events are triggered only
 * at the graphic elements. Usually you want to use the non-Zr version of this hook.
 *
 * See also:
 * https://echarts.apache.org/en/tutorial.html#Events%20and%20Actions%20in%20ECharts
 */
export function useEChartsZrEventHandler(
  eventName: ElementEventName,
  eChartsInstanceRef: MutableRefObject<echarts.ECharts | null>,
  eventHandler?: EChartsZrEventHandler
) {
  const previousEventHandler = useRef<NativeEChartsZrEventHandler>()

  useEffect(() => {
    // Bail out if there's no ECharts instance set
    if (!eChartsInstanceRef.current) {
      return
    }

    // Capture reference to the right ECharts reference
    const eChartsInstance = eChartsInstanceRef.current

    // Remove the previous event handler, if any
    if (previousEventHandler.current != null) {
      eChartsInstanceRef.current.getZr().off(eventName, previousEventHandler.current)
    }

    // If there's a new event handler, set it
    if (eventHandler) {
      const eventHandlerWithInstance = (event: ElementEvent) => eventHandler(event, eChartsInstance)
      eChartsInstanceRef.current.getZr().on(eventName, eventHandlerWithInstance)
      previousEventHandler.current = eventHandlerWithInstance
    }
  }, [eChartsInstanceRef, eventName, eventHandler])
}

/**
 * useEChartsMouseEventHandler is very similar to useEChartsEventHandler, but it
 * doesn't register on ECharts, but on the DOM element that ECharts attaches to.
 * Thus, the event registration is different, and we're no longer working with
 * echarts, but regular mouse events.
 */
export function useEChartsMouseEventHandler(
  eventName: string,
  eChartsDivRef: MutableRefObject<HTMLElement | null>,
  eChartsInstanceRef: MutableRefObject<echarts.ECharts | null>,
  eventHandler?: EChartsMouseZrEventHandler
) {
  const previousEventHandler = useRef<(this: HTMLElement, ev: MouseEvent) => void>()

  useEffect(() => {
    // Bail out if there's no ECharts instance set
    if (!eChartsDivRef.current || !eChartsInstanceRef.current) {
      return
    }

    // Capture reference to the right ECharts reference
    const eChartsInstance = eChartsInstanceRef.current

    // Remove the previous event handler, if any
    if (previousEventHandler.current != null) {
      eChartsDivRef.current.removeEventListener('mouseout', previousEventHandler.current)
    }

    // If there's a new event handler, set it
    if (eventHandler) {
      const eventHandlerWithInstance = (event: MouseEvent) => eventHandler(event, eChartsInstance)
      eChartsDivRef.current.addEventListener('mouseout', eventHandlerWithInstance)
      previousEventHandler.current = eventHandlerWithInstance
    }
  }, [eChartsDivRef, eChartsInstanceRef, eventName, eventHandler])
}

import { ChevronRightIcon } from '@gain/components/icons'
import Link from '@mui/material/Link'
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

import ConditionalWrapper from '../conditional-wrapper'

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5, 3, 1.5),
  marginTop: theme.spacing(1.5),
  '&:first-child': {
    marginTop: theme.spacing(1.5),
  },
}))

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
})

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 20,
  strokeWidth: 2,
}))

export interface MobileListItemHeaderProps extends ListSubheaderProps {
  children: ReactNode
  href?: string
}

export default function MobileListSubheader({
  children,
  href,
  ...listSubheaderProps
}: MobileListItemHeaderProps) {
  return (
    <StyledListSubheader {...listSubheaderProps}>
      <ConditionalWrapper
        condition={!!href}
        wrapper={(innerChildren) => (
          <StyledLink href={href}>
            {innerChildren}
            <StyledChevronRightIcon />
          </StyledLink>
        )}>
        {children}
      </ConditionalWrapper>
    </StyledListSubheader>
  )
}

import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router'

import ConditionalWrapper from '../../../common/conditional-wrapper'
import { ListViewAsset } from '../../../features/list-view'
import MobilePageListViewContainer from '../../../layout/mobile-page-list-view-container'
import { INVESTOR_SHARE_PARAM } from '../../utils'
import { createInvestorPortfolioFilters } from '../investor-profile-util'

export interface RoutePortfolioProps {
  investor: Investor
}

export default function RoutePortfolio({ investor }: RoutePortfolioProps) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const share = searchParams.get(INVESTOR_SHARE_PARAM)
  const isXs = useIsXs()
  const theme = useTheme()
  const sortByName = useMediaQuery(theme.breakpoints.down('md'))
  const assetList = useAssetList(() => {
    if (!investor) {
      return null
    }
    return {
      page: 0,
      limit: 500,
      sort: [listSort('ebitdaEur', 'desc')],
      filter: createInvestorPortfolioFilters(investor),
    }
  })

  const shareAssetIds = useMemo(() => {
    if (!share) {
      return undefined
    }

    return assetList.data.items
      .filter((asset) => {
        const index = asset.ownerIds.indexOf(investor.id)
        if (index === -1) {
          return false
        }
        return asset.ownerShares[index] === share
      })
      .map(({ id }) => id)
  }, [assetList, investor, share])

  return (
    <>
      <Head>
        <title>{investor.name} - Portfolio</title>
      </Head>

      <ConditionalWrapper
        condition={isXs}
        wrapper={(children) => (
          <MobilePageListViewContainer>{children}</MobilePageListViewContainer>
        )}>
        <ListViewAsset
          defaultFilter={createInvestorPortfolioFilters(investor, shareAssetIds)}
          defaultSort={sortByName ? [listSort('name', 'asc')] : undefined}
        />
      </ConditionalWrapper>
    </>
  )
}

import { useFindSectorCallback } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import Stack from '@mui/material/Stack'
import React from 'react'

interface AssetsPerRegionProps {
  sectorNames: string[]
}

export function AssetsPerSector({ sectorNames }: AssetsPerRegionProps) {
  const findSector = useFindSectorCallback()

  return (
    <Stack
      display={'flex'}
      flexDirection={'column'}
      gap={1.25}>
      {sectorNames.map((sectorName) => (
        <Typography
          key={sectorName}
          variant={'body2'}>
          {findSector(sectorName)?.title}
        </Typography>
      ))}
    </Stack>
  )
}

import { useMemo } from 'react'

export function useColumnWidths(
  availableWidth: number | null,
  minWidth: number,
  columns: Array<{ width: number; flex?: number }>
) {
  return useMemo(() => {
    if (availableWidth === null || availableWidth < minWidth) {
      return columns.map((column) => column.width)
    }

    const flex = columns.reduce((total, column) => total + (column.flex || 0), 0)
    const rest = (availableWidth - minWidth) / flex

    return columns.map((column) => (column.flex ? column.width + rest * column.flex : column.width))
  }, [availableWidth, minWidth, columns])
}

import { isDefined } from '@gain/utils/common'
import { objectKeys } from '@gain/utils/typescript'
import { useMemo } from 'react'

import { FinancialResult, FinancialResultAmountKeys } from './financial-result-model'

function hasAmountForKeys(financial: FinancialResult, ...keys: FinancialResultAmountKeys[]) {
  return keys.some((key) => isDefined(financial[key]?.amount))
}

function hasDefinedKeyForFinancials(
  financials: FinancialResult[] | undefined,
  ...keys: Array<keyof FinancialResult>
) {
  if (financials === undefined) {
    return false
  }

  return financials.some((financial) => keys.some((key) => isDefined(financial[key])))
}

function hasAmountForFinancials(
  financials: FinancialResult[] | undefined,
  ...keys: FinancialResultAmountKeys[]
) {
  if (financials === undefined) {
    return false
  }

  return financials.some((financial) => hasAmountForKeys(financial, ...keys))
}

export function hasRevenue(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'revenue')
}

export function hasGrossMargin(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'grossMargin')
}

export function hasEbitda(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'ebitda')
}

export function hasEbit(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'ebit')
}

export function hasConsolidatedNetIncome(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'consolidatedNetIncome')
}

export function hasEarningsPerShare(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'earningsPerShare')
}

export function hasTotalAssets(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'totalAssets')
}

export function hasCapex(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'capex')
}

export function hasFreeCashFlow(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'freeCashFlow')
}

export function hasCashConversionCycle(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'cashConversionCycle')
}

export function hasNetDebt(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'debt', 'cash')
}

export function hasCapital(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'inventory', 'receivables', 'payables')
}

export function hasFte(financials?: FinancialResult[]) {
  return hasAmountForFinancials(financials, 'fte')
}

export function hasReturnRatios(financials?: FinancialResult[]) {
  return hasDefinedKeyForFinancials(
    financials,
    'returnOnAssets',
    'returnOnEquity',
    'returnOnCapitalEmployed'
  )
}

export function hasOtherRatios(financials?: FinancialResult[]) {
  return hasDefinedKeyForFinancials(
    financials,
    'revenueFteRatio',
    'netDebtEbitdaRatio',
    'ebitdaMinusCapex'
  )
}

export function useDefinedFinancials(financials?: FinancialResult[]) {
  return useMemo(() => {
    return {
      hasRevenue: hasRevenue(financials),
      hasGrossMargin: hasGrossMargin(financials),
      hasEbitda: hasEbitda(financials),
      hasEbit: hasEbit(financials),
      hasConsolidatedNetIncome: hasConsolidatedNetIncome(financials),
      hasEarningsPerShare: hasEarningsPerShare(financials),
      hasTotalAssets: hasTotalAssets(financials),
      hasCapex: hasCapex(financials),
      hasFreeCashFlow: hasFreeCashFlow(financials),
      hasCashConversionCycle: hasCashConversionCycle(financials),
      hasNetDebt: hasNetDebt(financials),
      hasCapital: hasCapital(financials),
      hasFte: hasFte(financials),
    }
  }, [financials])
}

export function useHasFinancials(financials?: FinancialResult[]) {
  const used = useDefinedFinancials(financials)
  return useMemo(() => {
    return objectKeys(used).some((key) => used[key])
  }, [used])
}

export function useDefinedRatios(financials?: FinancialResult[]) {
  return useMemo(() => {
    return {
      hasReturnRatios: hasReturnRatios(financials),
      hasOtherRatios: hasOtherRatios(financials),
    }
  }, [financials])
}

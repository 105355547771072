import { useUserCurrency } from '@gain/api/app/hooks'
import { Asset } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useRef } from 'react'

import Card, { CardContentEmpty, CardHeader, CardProps } from '../../../../common/card/card'
import VirtualTable from '../../../../common/virtual-table'
import {
  ASSET_PAGE_FINANCIALS_PATH,
  generateAssetPagePath,
  useAssetPageParams,
} from '../../../utils'
import { shouldShowFinancialsTab } from '../../asset-financials'
import {
  FINANCIAL_COLUMN_END_PADDING,
  FINANCIAL_COLUMN_WIDTH,
  LABEL_COLUMN_WIDTH,
  useColumns,
} from './use-columns'
import useFinancialRows from './use-financial-rows'
import useSummaryFinancials from './use-summary-financials'

interface AssetFinancialsCardProps extends CardProps {
  asset: Asset
}

export default function AssetFinancialsCard({ asset, ...rest }: AssetFinancialsCardProps) {
  const params = useAssetPageParams()
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const userCurrency = useUserCurrency()
  const nrOfVisibleColumns = Math.floor(
    (width - LABEL_COLUMN_WIDTH - FINANCIAL_COLUMN_END_PADDING) / FINANCIAL_COLUMN_WIDTH
  )
  const financials = useSummaryFinancials(asset, nrOfVisibleColumns)
  const financialRows = useFinancialRows(financials)
  const columns = useColumns(financials, userCurrency)
  const hasFinancialResults = shouldShowFinancialsTab(asset)
  const hasFinancials = financialRows.length > 0

  const href = generateAssetPagePath(
    {
      id: asset.id,
      name: asset.generalInfo?.name,
      preview: params.preview,
    },
    ASSET_PAGE_FINANCIALS_PATH
  )

  return (
    <Card
      {...rest}
      ref={ref}
      href={hasFinancialResults ? href : undefined}>
      <CardHeader title={'Financials'} />

      {!hasFinancials && (
        <CardContentEmpty message={'No financials available'}>
          {asset.financialFootnotes.length > 0 && (
            <Button
              color={'primary'}
              component={Link}
              href={href}
              size={'small'}
              variant={'outlined'}>
              View notes
            </Button>
          )}
        </CardContentEmpty>
      )}

      {hasFinancials && (
        <VirtualTable
          columns={columns}
          RowComponentProps={{
            hover: false,
          }}
          rows={financialRows}
          variant={'inline'}
        />
      )}
    </Card>
  )
}

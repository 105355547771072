import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

import RequestProfileDialog from '../../../request-profile'

export default function SearchResultsRequestProfile() {
  const [requestProfileDialogOpen, setRequestProfileDialogOpen] = useState(false)

  return (
    <>
      <RequestProfileDialog
        onClose={() => setRequestProfileDialogOpen(false)}
        open={requestProfileDialogOpen}
      />

      <Typography
        color={'text.secondary'}
        sx={{ mb: 1.5 }}
        variant={'body1'}>
        Didn’t find what you were looking for?
      </Typography>

      <Button
        color={'primary'}
        onClick={() => setRequestProfileDialogOpen(true)}
        size={'small'}
        sx={{ ml: 0.5 }}
        variant={'contained'}>
        Request a profile
      </Button>
    </>
  )
}

import { generatePath } from 'react-router-dom'

import {
  INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  RouteInvestorStrategyTabs,
  useInvestorStrategyPageParams,
} from './route-strategy-path'

export function useStrategyTabs() {
  const params = useInvestorStrategyPageParams()

  return [
    {
      label: 'Portfolio',
      path: generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Portfolio,
    },
    {
      label: 'Funds',
      path: generatePath(INVESTOR_STRATEGY_FUNDS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Funds,
    },
    {
      label: 'Analysis',
      path: generatePath(INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Analysis,
    },
    {
      label: 'Benchmarking',
      path: generatePath(INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Benchmarking,
    },
  ]
}

import { ChevronDownIcon } from '@gain/components/icons'
import { ListItemKey } from '@gain/rpc/list-model'
import { styled } from '@mui/material/styles'
import React, { MouseEvent, useCallback, useState } from 'react'
import { useFieldArray } from 'react-final-form-arrays'

import { useFilterConfigMap } from './filter-bar/filter-bar-context'
import FilterBarChip from './filter-bar-chip'
import FilterGroupMenu from './filter-group-menu'
import FilterValueLabel from './filter-label/filter-value-label'
import { FilterValueItem } from './filter-value-model'

const StyledChevronDownIcon = styled(ChevronDownIcon)({
  fontSize: 12,
})

export interface FilterChipProps {
  groupIndex: number
  onClick: (event: MouseEvent) => void
  open: boolean
  onClose: () => void
  onRemoveGroup: (groupIndex: number) => void
  readOnly?: boolean
  disableDelete?: boolean
}

export default function FilterChip<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({
  open = false,
  groupIndex,
  onClick,
  onClose,
  onRemoveGroup,
  readOnly,
  disableDelete,
}: FilterChipProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const field = useFieldArray<FilterValueItem<Item, FilterField>>(`groups[${groupIndex}].value`)
  const filterMap = useFilterConfigMap<Item, FilterField>()

  const hasValues = field.fields.value.some(({ value }) => value !== null)
  const hasConfigs = field.fields.value.some((item) => filterMap[item.filterId])

  const handleRemoveGroup = useCallback(() => {
    setAnchorEl(null)
    onRemoveGroup(groupIndex)
  }, [setAnchorEl, onRemoveGroup, groupIndex])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (readOnly) {
        return
      }

      onClick(event)
    },
    [onClick, readOnly]
  )

  if (!hasConfigs) {
    return null
  }

  return (
    <>
      <FilterBarChip
        ref={(element) => setAnchorEl(element)}
        clickable={!readOnly}
        color={hasValues ? 'info' : 'default'}
        label={
          <>
            {field.fields.value
              .filter((item) => filterMap[item.filterId])
              .map((item, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <strong>OR</strong>}
                  <FilterValueLabel
                    filter={filterMap[item.filterId]}
                    value={item.value}
                  />
                </React.Fragment>
              ))}{' '}
            {!readOnly && <StyledChevronDownIcon />}
          </>
        }
        onClick={handleClick}
        size={'medium'}
      />
      {anchorEl && (
        <FilterGroupMenu
          anchorEl={anchorEl}
          disableDelete={disableDelete}
          groupIndex={groupIndex}
          items={field.fields.value}
          onClose={onClose}
          onRemoveGroup={handleRemoveGroup}
          open={open}
        />
      )}
    </>
  )
}

import Typography, { TypographyProps } from '@gain/components/typography'
import { AssetSegmentation } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import {
  PROFILE_TAB_BAR_STICKY_HEIGHT,
  PROFILE_TAB_BAR_STICKY_NO_TABS_HEIGHT,
  profileTabBarContainerClasses,
} from '../../../../common/profile-tab-bar'
import { NAV_BAR_HEIGHT } from '../../../../layout/nav-bar'

const StyledTable = styled('table')({
  borderCollapse: 'separate',
  width: '100%',
})

interface StyledThProps {
  width?: number
}

const StyledThead = styled('thead')<StyledThProps>(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.palette.common.white,

  [`.${profileTabBarContainerClasses.sticky} &`]: {
    top: NAV_BAR_HEIGHT + PROFILE_TAB_BAR_STICKY_HEIGHT,
  },

  [`.${profileTabBarContainerClasses.stickyNoTabs} &`]: {
    top: NAV_BAR_HEIGHT + PROFILE_TAB_BAR_STICKY_NO_TABS_HEIGHT,
  },
}))

const StyledTh = styled(
  (props: TypographyProps) => (
    <Typography
      color={'text.secondary'}
      component={'th'}
      variant={'body2'}
      noWrap
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'width',
  }
)<StyledThProps>(({ theme, width }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  height: 40,
  textAlign: 'left',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0, 1),
  ...(width && {
    width,
    maxWidth: width,
  }),
  '&:first-of-type': {
    paddingLeft: theme.spacing(3),
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(3),
  },
}))

interface StyledTdProps {
  width?: number
}

const StyledTd = styled('td', {
  shouldForwardProp: (prop) => prop !== 'width',
})<StyledTdProps>(({ theme, width }) => ({
  verticalAlign: 'top',
  textAlign: 'left',
  color: theme.palette.text.primary,
  ...theme.typography.body2,
  height: 24,
  padding: theme.spacing(2, 1),
  ...(width && {
    width,
  }),
  '&:first-of-type': {
    paddingLeft: theme.spacing(3),
    ...theme.typography.subtitle2,
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(3),
  },
}))

const StyledTr = styled('tr')(({ theme }) => ({
  '&:not(:first-of-type) td': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

const DIMENSION_1_COLUMN_WIDTH = 152 + 4 * 8
const DIMENSION_2_COLUMN_WIDTH = 192 + 2 * 8

export interface AssetSegmentationTableProps {
  segmentation: AssetSegmentation
}

export default function AssetSegmentationTable({ segmentation }: AssetSegmentationTableProps) {
  const hasSecondaryDimension = useMemo(() => {
    return segmentation.segments.filter((segment) => isTruthy(segment.subtitle)).length > 0
  }, [segmentation.segments])

  return (
    <StyledTable>
      <StyledThead>
        <tr>
          <StyledTh width={DIMENSION_1_COLUMN_WIDTH}>{segmentation.title}</StyledTh>
          {hasSecondaryDimension && (
            <StyledTh width={DIMENSION_2_COLUMN_WIDTH}>
              <Typography
                variant={'body2'}
                noWrap>
                {segmentation.subtitle}
              </Typography>
            </StyledTh>
          )}
          <StyledTh>Description</StyledTh>
        </tr>
      </StyledThead>
      <tbody>
        {segmentation.segments.map((segment) => (
          <StyledTr key={segment.id}>
            <StyledTd width={DIMENSION_1_COLUMN_WIDTH}>{segment.title || '-'}</StyledTd>
            {hasSecondaryDimension && (
              <StyledTd width={DIMENSION_2_COLUMN_WIDTH}>{segment.subtitle || '-'}</StyledTd>
            )}
            <StyledTd>{segment.description || '-'}</StyledTd>
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  )
}

import { GeoPolygon } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import Stack from '@mui/material/Stack'
import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { useForm } from 'react-hook-form'
import { DeepPartial } from 'react-hook-form/dist/types/utils'

import { FilterConfigGeoPolygon, FilterGeoPolygonValue } from '../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../use-track-filter-event'
import FilterGeoPolygonAutocomplete from './filter-geo-polygon-autocomplete'
import FilterGeoPolygonMap from './filter-geo-polygon-map'

export interface FilterGeoPolygonProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigGeoPolygon<Item, FilterField>
}

export default function FilterGeoPolygon<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterGeoPolygonProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()

  // We still use react-final-form to manage the filter bar form
  const field = useField<FilterGeoPolygonValue>(path)

  // Use react-hook-form for internal state of this filter
  const { control, watch, formState, getValues } = useForm<FilterGeoPolygonValue>({
    defaultValues: {
      formatted: undefined,
      placeId: undefined,
    },
    mode: 'all',
  })

  // Update external state on internal change
  useEffect(() => {
    const subscription = watch((value: DeepPartial<FilterGeoPolygonValue>) => {
      const data = value?.placeId as unknown as FilterGeoPolygonValue
      if (data?.placeId) {
        field.input.onChange(data)
        trackEvent(`Filter ${filter.label} value change`, data.formatted || '', null)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, formState, field.input, getValues, trackEvent, filter.label])

  return (
    <Stack spacing={1}>
      <FilterGeoPolygonAutocomplete
        control={control}
        value={field.input.value as GeoPolygon}
      />
      <Stack>
        {field.input.value?.placeId && <FilterGeoPolygonMap placeId={field.input.value.placeId} />}
      </Stack>
    </Stack>
  )
}

import { useAdvisorListItem } from '@gain/api/app/hooks'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { isDefined } from '@gain/utils/common'
import React, { PropsWithChildren, useCallback, useState } from 'react'

import TooltipAdvisorTitle from './tooltip-advisor-title'

export type TooltipAdvisorProps = PropsWithChildren<
  Omit<TooltipProps, 'title' | 'id' | 'open'> & {
    id?: number
  }
>

export default function TooltipAdvisor({
  id,
  variant = 'preview',
  disablePadding = true,
  children,
  ...props
}: TooltipAdvisorProps) {
  const [fetchAdvisor, setFetchAdvisor] = useState(false)
  const swrAdvisor = useAdvisorListItem(fetchAdvisor && isDefined(id) ? id : null)
  const handleOpen = useCallback(() => setFetchAdvisor(true), [])

  return (
    <Tooltip
      key={id}
      disablePadding={disablePadding}
      onOpen={handleOpen}
      title={swrAdvisor.data ? <TooltipAdvisorTitle advisor={swrAdvisor.data} /> : ''}
      variant={variant}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  )
}

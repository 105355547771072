import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import isTouchDevice from 'is-touch-device'
import { PropsWithChildren, useMemo } from 'react'

export interface SiteContainerProps {
  showMenu?: boolean
}

export const siteContainerClasses = generateUtilityClasses('Gain-SiteContainer', [
  'root',
  'menuOpen',
  'menuClosed',
])

const StyledContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minHeight: '100vh',
  maxWidth: '100vw',
})

export default function SiteContainer({
  children,
  showMenu,
}: PropsWithChildren<SiteContainerProps>) {
  const isTouch = useMemo(() => isTouchDevice(), [])

  return (
    <StyledContainer
      className={clsx(siteContainerClasses.root, {
        [siteContainerClasses.menuOpen]: showMenu,
        [siteContainerClasses.menuClosed]: !showMenu,
      })}
      data-touch={isTouch}>
      {children}
    </StyledContainer>
  )
}

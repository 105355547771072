import Typography from '@gain/components/typography'
import { compareDateDesc, isDefined } from '@gain/utils/common'
import { formatFte } from '@gain/utils/financials'
import { calculateGrowthPercentage, isEvenNumber } from '@gain/utils/number'
import { objectKeys } from '@gain/utils/typescript'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import { addMonths } from 'date-fns/addMonths'
import { differenceInCalendarMonths } from 'date-fns/differenceInCalendarMonths'
import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import { startOfMonth } from 'date-fns/startOfMonth'
import { useCallback, useMemo } from 'react'

import InfoButton from '../../common/info-button'
import { KeyValueList, KeyValueListItem } from '../../common/key-value/key-value-list'
import { MeasureDimensions } from '../../common/responsive'
import { GrowthIndicator } from '../asset/asset-fte-card/card-asset-fte-growth-indicator'
import { MultiLineChart } from '../chart'

const StyledStatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: theme.spacing(0.5, 0),
}))

const StyledStat = styled(Typography)({
  display: 'flex',
  flexDirection: 'column',
})

/**
 * Returns the index of the first occurrence of any element in the
 * searchElements array within the value array. -1 otherwise.
 */
function indexOfFirstMatch(value: number[], searchElements: number[]) {
  for (let i = 0; i < searchElements.length; i++) {
    const index = value.indexOf(searchElements[0])
    if (index > -1) {
      return index
    }
  }

  return -1
}

// Allowed difference in months for each period.
const periodMonthDiffMap = {
  year: [12, 11, 13],
  halfYear: [6, 5, 7],
  month: [1],
}

/**
 * Calculates the growth percentages for each period based on the given
 * FteMeasurements.
 */
function useGrowthPercentages(fteMeasurements: FteMeasurement[]) {
  return useMemo(() => {
    if (fteMeasurements.length === 0) {
      return null
    }

    // Calculate difference in months against the most recent measurement
    const [last, ...other] = fteMeasurements.slice().sort((a, b) => {
      return compareDateDesc(parseISO(a.determinedAt), parseISO(b.determinedAt))
    })

    const lastDeterminedAt = parseISO(last.determinedAt)
    // Create an array of the difference in months between a measurement and
    // the date of the last measurement.
    const measurementsMonthDiff = other.map((measurement) => {
      return differenceInCalendarMonths(lastDeterminedAt, parseISO(measurement.determinedAt))
    })

    return objectKeys(periodMonthDiffMap).reduce((acc, period) => {
      // Find the index of the measurement for the given period
      const index = indexOfFirstMatch(measurementsMonthDiff, periodMonthDiffMap[period])
      const growthPercentage = calculateGrowthPercentage(
        other[index]?.employeeCount,
        last.employeeCount
      )

      return {
        ...acc,
        [period]: growthPercentage,
      }
    }, {} as Record<keyof typeof periodMonthDiffMap, number | null>)
  }, [fteMeasurements])
}

interface FteMeasurement {
  readonly id: number
  employeeCount: number
  determinedAt: string
}

export interface CardAssetFteLinkedinTabProps {
  companyFteRangeLabel?: string | null
  fteMeasurements: FteMeasurement[]
  showDivider?: boolean
}

export default function FteMeasurements({
  companyFteRangeLabel,
  fteMeasurements,
  showDivider,
}: CardAssetFteLinkedinTabProps) {
  const theme = useTheme()

  const data = useMemo(() => {
    if (fteMeasurements.length === 0) {
      return null
    }

    return [
      {
        data: fteMeasurements,
        color: theme.palette.info.main,
        id: 1,
      },
    ]
  }, [fteMeasurements, theme.palette.info.main])

  const latestFinancialResultWithFte = fteMeasurements?.slice().pop()
  const growthPercentages = useGrowthPercentages(fteMeasurements)

  const getXTicks = useCallback((domain: Date[], chartWith: number) => {
    if (domain.length !== 2) {
      return []
    }

    const rangeStart = domain[0]
    const rangeEnd = domain[1]

    // Get the difference in months (minus one for the rangeEnd)
    const monthsInBetween = differenceInCalendarMonths(rangeEnd, rangeStart) - 1
    const range = [] as Date[]
    // Each 100px we can place a tick, so based on the width of the chart get the max amount of ticks
    const maxTicks = Math.floor(chartWith / 100)
    // Get the distance between the ticks
    const monthsStep = Math.round(monthsInBetween / maxTicks)

    // If the months in between (+1 for the end month) is odd or the max ticks count is the same
    // as the months step, and we can't show all months we will only show start and end date
    if (
      (!isEvenNumber(monthsInBetween + 1) || maxTicks === monthsStep) &&
      monthsInBetween > maxTicks
    ) {
      return [rangeStart, rangeEnd]
    }

    // Get the amount of ticks we are going to place
    const tickCount = Math.floor(monthsInBetween / monthsStep)

    let lastLabel = new Date(rangeStart)
    for (let i = tickCount; i > 0; i--) {
      range.push((lastLabel = addMonths(lastLabel, monthsStep)))
    }

    const xTicks = [rangeStart, ...range]
    if (differenceInCalendarMonths(rangeEnd, xTicks[xTicks.length - 1]) < monthsStep) {
      // Remove the last item as the text could overlap in the UI with the range end one
      xTicks.pop()
    }

    // Add the range end
    xTicks.push(rangeEnd)

    return xTicks
  }, [])

  if (
    fteMeasurements.length === 0 || // No data available
    (fteMeasurements.length === 1 && fteMeasurements[0].employeeCount === 0) // Only one measurement and it's 0
  ) {
    return (
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ height: '100%', pb: 3 }}>
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          No data available
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <KeyValueList>
        {companyFteRangeLabel && (
          <KeyValueListItem
            label={
              <>
                FTEs according to company
                <InfoButton
                  dialogMessage={
                    'The number of FTEs according to the Company on social media (public web data only).'
                  }
                  dialogTitle={'FTEs according to company'}
                  sx={{ ml: 0.5 }}
                />
              </>
            }
            value={companyFteRangeLabel}
          />
        )}

        {data && data.length > 0 && (
          <>
            {showDivider && <Divider sx={{ my: 1 }} />}

            <KeyValueListItem
              label={
                <>
                  FTEs according to employees
                  <InfoButton
                    dialogMessage={
                      'Refers to the number of individuals that list this Company as their employer on social media (public web data only).'
                    }
                    dialogTitle={'FTEs according to employees'}
                    sx={{ ml: 0.5 }}
                  />
                </>
              }
              value={''}
            />
          </>
        )}
      </KeyValueList>

      {data && data.length > 0 && (
        <>
          <StyledStatsContainer>
            <StyledStat
              color={'text.secondary'}
              variant={'overline'}>
              Total FTEs
              <Typography
                color={'text.primary'}
                variant={'body2'}>
                {formatFte(latestFinancialResultWithFte?.employeeCount, false)}
              </Typography>
            </StyledStat>

            <Stack
              direction={'row'}
              gap={2}>
              {growthPercentages &&
                isDefined(growthPercentages.year) &&
                growthPercentages.year !== Infinity && (
                  <StyledStat
                    color={'text.secondary'}
                    variant={'overline'}>
                    1y growth
                    <GrowthIndicator
                      growthPct={growthPercentages.year}
                      iconPosition={'start'}
                      variant={'body2'}
                    />
                  </StyledStat>
                )}

              {growthPercentages &&
                isDefined(growthPercentages.halfYear) &&
                growthPercentages.halfYear !== Infinity && (
                  <StyledStat
                    color={'text.secondary'}
                    variant={'overline'}>
                    6m growth
                    <GrowthIndicator
                      growthPct={growthPercentages.halfYear}
                      iconPosition={'start'}
                      variant={'body2'}
                    />
                  </StyledStat>
                )}

              {growthPercentages &&
                isDefined(growthPercentages.month) &&
                growthPercentages.month !== Infinity && (
                  <StyledStat
                    color={'text.secondary'}
                    variant={'overline'}>
                    1m growth
                    <GrowthIndicator
                      growthPct={growthPercentages.month}
                      iconPosition={'start'}
                      variant={'body2'}
                    />
                  </StyledStat>
                )}
            </Stack>
          </StyledStatsContainer>

          <MeasureDimensions>
            {({ width, height }) => (
              <MultiLineChart
                data={data}
                getId={(financialResult) => financialResult.id}
                height={height}
                width={width}
                xScaleConfig={{
                  getLabel: (date) => format(date, 'MMM, yy'),
                  getValue: (fteMeasurement) =>
                    // Return the start of the month so the dot is placed nicer
                    startOfMonth(parseISO(fteMeasurement.determinedAt)),
                  getTooltip: (fteMeasurement) =>
                    `${format(parseISO(fteMeasurement.determinedAt), 'MMM, yy')}: ${formatFte(
                      fteMeasurement.employeeCount,
                      false
                    )}`,
                  ticks: (xScale) => getXTicks(xScale.domain(), width),
                  dateVariant: 'months',
                }}
                yScaleConfig={{
                  getLabel: (fteCount) => formatFte(fteCount, true),
                  getValue: (financialResult) => financialResult.employeeCount || null,
                }}
              />
            )}
          </MeasureDimensions>
        </>
      )}
    </Stack>
  )
}

import { useRpcClient } from '@gain/api/swr'
import PublicPage from '@gain/components/public-page'
import Button from '@mui/material/Button'
import Debug from 'debug'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { AlertDialog } from '../../common/dialog'
import { FullWidthButton } from '../../common/form'
import { useTrackEvent } from '../../features/google-analytics'
import { HOME_PATH, SETTINGS_EMAIL_PATH, UnsubscribeRouteParams } from '../utils'

const debug = Debug('gain-pro:unsubscribe')

// Lookup from email type to human readable string
const names: Record<string, string> = {
  'sourcing-update': 'News and sourcing update',
  'industry-research': 'Industry research updates',
  'asset-updates-notification': 'List updates',
}

enum PageStatus {
  Fresh,
  Loading,
  Success,
  Error,
}

/**
 * RouteUnsubscribe handles the page for unsubscribing from
 * email newsletters.
 */
export default function RouteUnsubscribe() {
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Fresh)
  const { name, token } = useParams<UnsubscribeRouteParams>()

  const fetcher = useRpcClient()
  const trackEvent = useTrackEvent()

  // Kick off unsubscribe call to backend
  const handleUnsubscribePress = async () => {
    setPageStatus(PageStatus.Loading)
    try {
      await fetcher({
        method: 'account.unsubscribeEmail',
        params: {
          token,
          unsubscribeEmailSourcingUpdates: name === 'sourcing-update',
          unsubscribeEmailResearchUpdates: name === 'industry-research',
          unsubscribeEmailAssetUpdatesNotification: name === 'asset-updates-notification',
        },
      })

      // Successful unsubscribe
      trackEvent('Unsubscribe', 'Email', name)
      setPageStatus(PageStatus.Success)
    } catch (error) {
      debug('unsubscribe request failed', error)
      setPageStatus(PageStatus.Error)
    }
  }

  // Reset the form when closing the error dialog
  const handleDialogClose = () => {
    setPageStatus(PageStatus.Fresh)
  }

  return (
    <PublicPage
      message={
        pageStatus === PageStatus.Success ? (
          <>
            You've successfully been unsubscribed from the <strong>{names[name]}</strong> email
          </>
        ) : (
          <>
            Please confirm that you want to unsubscribe from the <strong>{names[name]}</strong>{' '}
            email. You can always resubscribe later.
          </>
        )
      }
      title={
        pageStatus === PageStatus.Success
          ? 'You have been unsubscribed'
          : 'Do you want to unsubscribe?'
      }>
      {/* Dialog for unexpected error scenario */}
      <AlertDialog
        message={'An error occurred please try again later'}
        onClose={handleDialogClose}
        open={pageStatus === PageStatus.Error}
        title={'Server Error'}
      />

      {/* Button for unsubscribe trigger or redirect on success */}
      {pageStatus !== PageStatus.Success ? (
        <FullWidthButton
          loading={pageStatus === PageStatus.Loading}
          onClick={handleUnsubscribePress}>
          Unsubscribe
        </FullWidthButton>
      ) : (
        <FullWidthButton to={HOME_PATH}>Ok</FullWidthButton>
      )}

      {/* Redirect to email preferences */}
      <Button
        color={'primary'}
        component={Link}
        to={SETTINGS_EMAIL_PATH}
        variant={'text'}>
        Manage all email preferences
      </Button>
    </PublicPage>
  )
}

import { useEffect, useRef } from 'react'

import { useTrackEvent } from '../../features/google-analytics'
import { DropdownMenuOption, DropdownMenuOptions, isDropdownMenuGroup } from './dropdown-menu-model'

export function useTrackDropdownSearchEvent(
  gaCategory: string,
  searchQuery: string | null,
  activeOptions: DropdownMenuOptions<DropdownMenuOption>
) {
  const trackEvent = useTrackEvent()
  const timeoutIdRef = useRef<number | undefined>()

  useEffect(() => {
    if (!searchQuery) {
      return
    }

    const value = activeOptions.reduce((acc, item) => {
      if (isDropdownMenuGroup(item)) {
        return acc + item.children.length
      }

      return acc + 1
    }, 0)

    if (!value) {
      return
    }

    const handleTrackEvent = () =>
      trackEvent(
        'Dropdown menu search',
        gaCategory,
        searchQuery,
        value !== null ? value : undefined
      )

    clearTimeout(timeoutIdRef.current)
    timeoutIdRef.current = window.setTimeout(handleTrackEvent, 500)
  }, [activeOptions, gaCategory, searchQuery, trackEvent])
}

import { useGetUser } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import { Snackbar } from '@gain/components/snackbar'
import { UserRole } from '@gain/rpc/shared-model'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'

import { useAlertDialog, useConfirmDialog } from '../../common/dialog'

export function useDeactivateUserAccountCallback() {
  const showConfirmDialog = useConfirmDialog()
  const showAlertDialog = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()
  const getUser = useGetUser()

  return useCallback(
    async (userId: number, callback?: () => void) => {
      try {
        const user = await getUser(userId)
        const name = `${user.firstName} ${user.lastName}`

        if (user.hasSharedBookmarks) {
          const confirmed = await showConfirmDialog({
            title: 'Transfer shared lists',
            message: [
              `“${name}” has shared lists with other team members.`,
              `Please ask ${user.firstName} to transfer the list ownership to`,
              'other team members.\nIf you deactivate the account the list',
              'ownership can’t be transferred.',
            ].join(' '),
            confirmText: 'Deactivate',
            cancelText: 'Cancel',
            color: 'error',
          })

          if (!confirmed) {
            return
          }
        }

        await rpcClient({
          method: 'admin.deactivateUser',
          params: {
            userId: user.id,
          },
        })

        const snackbarKey = `deactivate-user-${user.id}`
        enqueueSnackbar(undefined, {
          key: snackbarKey,
          content: (
            <Snackbar
              id={snackbarKey}
              message={`The account of “${name}” is deactivated`}
            />
          ),
        })

        callback?.()
      } catch (error) {
        showAlertDialog({
          title: 'Something went wrong',
          message: 'The user account couldn’t be deactivated. Please try again.',
          buttonText: 'Ok',
        })
      }
    },
    [showAlertDialog, showConfirmDialog, enqueueSnackbar, rpcClient, getUser]
  )
}

export function useActivateUserAccountCallback() {
  const showAlertDialog = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()
  const getUser = useGetUser()

  return useCallback(
    async (userId: number, callback?: () => void) => {
      try {
        const user = await getUser(userId)

        await rpcClient({
          method: 'admin.reactivateUser',
          params: {
            userId,
          },
        })

        const snackbarKey = `activate-user-${user.id}`
        enqueueSnackbar(undefined, {
          key: snackbarKey,
          content: (
            <Snackbar
              id={snackbarKey}
              message={`The account of “${user.firstName} ${user.lastName}” is activated`}
            />
          ),
        })

        callback?.()
      } catch (error) {
        showAlertDialog({
          title: 'Something went wrong',
          message: 'The user account couldn’t be activated. Please try again.',
          buttonText: 'Ok',
        })
      }
    },
    [showAlertDialog, enqueueSnackbar, rpcClient, getUser]
  )
}

export function useDeleteUserAccountCallback() {
  const showConfirmDialog = useConfirmDialog()
  const showAlertDialog = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()
  const getUser = useGetUser()

  return useCallback(
    async (userId: number, callback?: () => void) => {
      try {
        const user = await getUser(userId)
        const name = `${user.firstName} ${user.lastName}`

        if (user.hasSharedBookmarks) {
          const confirmed = await showConfirmDialog({
            title: 'Delete shared lists',
            message: [
              `“${name}” has shared lists with other team members.`,
              `Please ask ${user.firstName} to transfer the list ownership`,
              'before deleting the account.\nIf you delete the account the',
              'shared lists will also be deleted.',
            ].join(' '),
            confirmText: 'Delete',
            cancelText: 'Cancel',
            color: 'error',
          })

          if (!confirmed) {
            return
          }
        }

        const deleteConfirmed = await showConfirmDialog({
          title: 'Delete account',
          message: `Are you sure you want to delete the account of “${name}”? This cannot be undone.`,
          confirmText: 'Delete',
          cancelText: 'Cancel',
          color: 'error',
        })

        if (!deleteConfirmed) {
          return
        }

        await rpcClient({
          method: 'admin.deleteUser',
          params: {
            id: user.id,
          },
        })

        const snackbarKey = `delete-user-${user.id}`
        enqueueSnackbar(undefined, {
          key: snackbarKey,
          content: (
            <Snackbar
              id={snackbarKey}
              message={`The account of “${name}” is deleted`}
            />
          ),
        })

        callback?.()
      } catch (error) {
        showAlertDialog({
          title: 'Something went wrong',
          message: 'The user account couldn’t be deleted. Please try again.',
          buttonText: 'Ok',
        })
      }
    },
    [showAlertDialog, showConfirmDialog, enqueueSnackbar, rpcClient, getUser]
  )
}

export function useResendInvitationCallback() {
  const showAlertDialog = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()
  const getUser = useGetUser()

  return useCallback(
    async (userId: number) => {
      try {
        const user = await getUser(userId)

        await rpcClient({
          method: 'admin.resendInvitation',
          params: {
            userId: user.id,
          },
        })

        const snackbarKey = `resend-invitation-${user.id}`
        enqueueSnackbar(undefined, {
          key: snackbarKey,
          content: (
            <Snackbar
              id={snackbarKey}
              message={`Invitation email sent to “${user.firstName} ${user.lastName}”`}
            />
          ),
        })
      } catch (error) {
        showAlertDialog({
          title: 'Something went wrong',
          message: 'The invitation couldn’t be sent. Please try again.',
          buttonText: 'Ok',
        })
      }
    },
    [showAlertDialog, enqueueSnackbar, rpcClient, getUser]
  )
}

export function useGiveAdminRoleCallback() {
  const showAlertDialog = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()
  const getUser = useGetUser()

  return useCallback(
    async (userId: number, callback?: () => void) => {
      try {
        const user = await getUser(userId)

        await rpcClient({
          method: 'admin.updateUser',
          params: {
            id: user.id,
            partial: {
              role: UserRole.Admin,
            },
          },
        })

        const snackbarKey = `give-admin-rights-${user.id}`
        enqueueSnackbar(undefined, {
          key: snackbarKey,
          content: (
            <Snackbar
              id={snackbarKey}
              message={`Admin rights added to “${user.firstName} ${user.lastName}”`}
            />
          ),
        })

        callback?.()
      } catch (error) {
        showAlertDialog({
          title: 'Something went wrong',
          message: 'The admin rights couldn’t be added. Please try again.',
          buttonText: 'Ok',
        })
      }
    },
    [showAlertDialog, enqueueSnackbar, rpcClient, getUser]
  )
}

export function useRemoveAdminRoleCallback() {
  const showAlertDialog = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()

  return useCallback(
    async (userId: number, callback?: () => void) => {
      try {
        const user = await rpcClient({
          method: 'admin.getUser',
          params: {
            id: userId,
          },
        })

        await rpcClient({
          method: 'admin.updateUser',
          params: {
            id: user.id,
            partial: {
              role: UserRole.User,
            },
          },
        })

        const snackbarKey = `remove-admin-rights-${user.id}`
        enqueueSnackbar(undefined, {
          key: snackbarKey,
          content: (
            <Snackbar
              id={snackbarKey}
              message={`Admin rights removed from “${user.firstName} ${user.lastName}”`}
            />
          ),
        })

        callback?.()
      } catch (error) {
        showAlertDialog({
          title: 'Something went wrong',
          message: 'The admin rights couldn’t be removed. Please try again.',
          buttonText: 'Ok',
        })
      }
    },
    [showAlertDialog, enqueueSnackbar, rpcClient]
  )
}

export function useCopyInvitationLinkCallback() {
  const { enqueueSnackbar } = useSnackbar()
  const rpcClient = useRpcClient()

  return useCallback(
    async (userId: number) => {
      const url = await rpcClient({
        method: 'admin.getUserInvitationLink',
        params: {
          userId,
        },
      })

      copy(url, {
        format: 'text/plain',
      })
      const snackbarKey = 'invitation-link-copied'
      enqueueSnackbar(undefined, {
        key: snackbarKey,
        content: (
          <Snackbar
            id={snackbarKey}
            message={'Invitation link copied to clipboard'}
          />
        ),
      })
    },
    [enqueueSnackbar, rpcClient]
  )
}

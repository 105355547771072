import { SpinnerIcon } from '@gain/components/icons'
import PublicPage from '@gain/components/public-page'
import { useOnMountEffect } from '@gain/utils/react'
import Button from '@mui/material/Button'
import MUILink from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { useAuthRedirect, useAutoAuthRedirect } from '../../features/auth/use-auth-redirect'
import { useZendesk } from '../../features/zendesk'
import { LOGIN_PATH } from '../utils'
import { useSSOApi } from './use-sso-api'

/**
 * RouteSsoCallback is the component that handles the route where
 * the user lands when WorkOS redirects the user back to our app.
 * It picks up the WorkOS token from the URL, exchanges that for a
 * Gain.pro access/session token and redirects the user.
 */
export default function RouteSsoCallback() {
  const [didError, setDidError] = useState(false)

  const { enableChat } = useZendesk()
  const redirectUser = useAuthRedirect()

  const ssoApi = useSSOApi()

  // Automatically redirect user if already signed in
  useAutoAuthRedirect()

  // Try to exchange WorkOS token for Gain.pro token
  useOnMountEffect(() => {
    ;(async () => {
      // Get and check code from URL
      const code = ssoApi.getCode()

      if (code && (await ssoApi.login(code))) {
        redirectUser()
      } else {
        setDidError(true)
      }
    })()
  })

  return (
    <PublicPage>
      <Stack
        alignItems={'center'}
        spacing={4}>
        {/* Signing in loader or failure message */}
        <Stack
          alignItems={'center'}
          direction={'row'}
          spacing={1}>
          {didError ? (
            <Typography variant={'body2'}>
              Failed to sign in,&nbsp;
              <MUILink
                href={LOGIN_PATH}
                variant={'body2'}>
                please try again
              </MUILink>
            </Typography>
          ) : (
            <>
              <SpinnerIcon fontSize={'inherit'} />
              <Typography variant={'body2'}>Signing in...</Typography>
            </>
          )}
        </Stack>

        {/* Support footer */}
        <Typography
          color={'text.secondary'}
          textAlign={'center'}
          variant={'body2'}>
          Having trouble signing in?
          <Button
            color={'primary'}
            onClick={enableChat}
            variant={'text'}>
            Contact support
          </Button>
        </Typography>
      </Stack>
    </PublicPage>
  )
}

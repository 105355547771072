import { useRpcClient } from '@gain/api/swr'
import PublicPage from '@gain/components/public-page'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Debug from 'debug'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { AlertDialog } from '../../common/dialog'
import { FullWidthButton, FullWidthForm, TextInput } from '../../common/form'
import { useAutoAuthRedirect } from '../../features/auth/use-auth-redirect'
import { useZendesk } from '../../features/zendesk'
import { HOME_PATH } from '../utils'

const debug = Debug('gain-pro:forgot-password')

interface ForgotPasswordFormValues {
  username: string
}

enum PageStatus {
  Fresh,
  Loading,
  Success,
  Error,
}

/**
 * RouteForgotPassword renders the forgot password page where a
 * user fills in their email address to start the reset password
 * workflow.
 */
export default function RouteForgotPassword() {
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Fresh)

  const { enableChat } = useZendesk()
  const fetcher = useRpcClient()

  // Automatically redirect user if already signed in
  useAutoAuthRedirect()

  // Define forgot password form
  const form = useForm<ForgotPasswordFormValues>({
    defaultValues: { username: '' },
    resolver: yupResolver(
      yup.object({
        username: yup.string().trim().email().required(),
      })
    ),
  })

  // On form submit, send request for forgot password
  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = async (formData) => {
    setPageStatus(PageStatus.Loading)
    try {
      await fetcher({
        method: 'account.forgotPassword',
        params: { username: formData.username },
      })

      // Successfully triggered the reset password flow
      setPageStatus(PageStatus.Success)
    } catch (error) {
      setPageStatus(PageStatus.Error)
      debug('forgot password request failed', error)
    }
  }

  return (
    <PublicPage
      message={
        pageStatus !== PageStatus.Success
          ? 'Enter your email address and we will send you an email with further instructions.'
          : 'We have sent an email with further instructions if an account exists for this email address. Please check your inbox.'
      }
      title={pageStatus === PageStatus.Success ? 'Password reset' : undefined}>
      {/* Error dialog for unexpected server errors */}
      <AlertDialog
        message={'An error occurred please try again later'}
        onClose={() => {
          setPageStatus(PageStatus.Fresh)
        }}
        open={pageStatus === PageStatus.Error}
        title={'Server Error'}
      />

      {/* Show email address form */}
      {pageStatus !== PageStatus.Success && (
        <FormProvider {...form}>
          <FullWidthForm onSubmit={form.handleSubmit(onSubmit)}>
            <TextInput
              autoComplete={'username'}
              autoFocus={true}
              name={'username'}
              placeholder={'Email address'}
              disableHelperText
            />

            <div>
              <FullWidthButton
                loading={pageStatus === PageStatus.Loading}
                sx={{ mt: 1 }}
                type={'submit'}>
                Reset password
              </FullWidthButton>
            </div>
          </FullWidthForm>
        </FormProvider>
      )}

      {/* Success, show button to go to home page */}
      {pageStatus === PageStatus.Success && <FullWidthButton to={HOME_PATH}>Ok</FullWidthButton>}

      {/* Support footer */}
      <Typography
        color={'text.secondary'}
        textAlign={'center'}
        variant={'body2'}>
        Having trouble signing in?
        <Button
          color={'primary'}
          onClick={enableChat}
          variant={'text'}>
          Contact support
        </Button>
      </Typography>
    </PublicPage>
  )
}

import { UserRole } from '@gain/rpc/shared-model'

const GAIN_PRO_CUSTOMER_ID = 1
const PLANHAT_TEST_CUSTOMER_ID = 984

interface UserProfileLike {
  customerId: number
}

export function isAdministrator(role?: UserRole | null): boolean {
  return role != null && (role === UserRole.Admin || role === UserRole.Superadmin)
}

export function isGainProUser(profile: UserProfileLike) {
  return profile.customerId === GAIN_PRO_CUSTOMER_ID
}

export function isPlanhatTestUser(profile: UserProfileLike) {
  return profile.customerId === PLANHAT_TEST_CUSTOMER_ID
}

export function getUserInitials(name: string): string {
  const nameParts = name.split(' ')
  return `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`.toUpperCase()
}

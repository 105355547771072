import { AssetManager } from '@gain/rpc/app-model'
import { isDefined, isTruthy } from '@gain/utils/common'

export interface FormatAssetManagerOptions {
  emptyValue?: string
}

export const formatAssetManager = (
  manager: AssetManager | null | undefined,
  { emptyValue = '-' }: FormatAssetManagerOptions = {}
) => {
  if (!isDefined(manager)) {
    return emptyValue
  }

  const name = [manager.person?.firstName, manager.person?.lastName].join(' ')

  const ageInYears =
    manager.person?.birthYear && isDefined(manager.person?.birthYear)
      ? `${new Date().getFullYear() - manager.person?.birthYear}yr`
      : null

  return [name, ageInYears].filter(isTruthy).join(', ')
}

import { styled } from '@mui/material/styles'

export const NAV_BAR_HEIGHT = 64

const NavBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  height: NAV_BAR_HEIGHT,
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '250px 1fr 250px',
  },
}))

export default NavBar

import { Components, Theme } from '@mui/material/styles'
import { toggleButtonClasses } from '@mui/material/ToggleButton'
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup'

export const MuiToggleButton = (theme: Theme): Components['MuiToggleButton'] => ({
  styleOverrides: {
    root: {
      ...theme.typography.body2,
      borderRadius: 6, // 2px less than parent due to padding
      border: 'none',
      borderWidth: 0,
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: theme.palette.common.white,
      },
      [`&.${toggleButtonGroupClasses.firstButton}, &.${toggleButtonGroupClasses.middleButton}, &.${toggleButtonGroupClasses.lastButton}`]:
        {
          borderRadius: 6,
        },
    },
    sizeSmall: {
      padding: '1px 6px',
    },
  },
  defaultProps: {
    disableRipple: true,
  },
})

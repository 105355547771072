import { useCurrency, useUserCurrency } from '@gain/api/app/hooks'
import { CurrencyListItem } from '@gain/rpc/app-model'
import { currencyDisplayedAs, currencySymbol } from '@gain/utils/currency'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import React, { useEffect, useState } from 'react'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  height: 30,
})

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  minWidth: theme.spacing(3),
}))

interface CurrencyToggleProps {
  value?: CurrencyListItem | null
  dataCurrency?: string | null
  symbolOnly?: boolean
  onChange: (newCurrency: CurrencyListItem) => void
}

/**
 * A toggle that allows the user to switch between the data currency and
 * the user currency. The default is always the user currency.
 */
export default function CurrencyToggle({
  dataCurrency,
  symbolOnly = false,
  onChange,
  value = null,
}: CurrencyToggleProps) {
  const userCurrency = useUserCurrency()
  const localCurrency = useCurrency(dataCurrency)
  const [currency, setCurrency] = useState<CurrencyListItem | null>(value)

  // Use the user currency as the selected option when it's available
  useEffect(() => {
    setCurrency(userCurrency)
    onChange(userCurrency)
  }, [onChange, userCurrency])

  // Update currency when the external value changes
  useEffect(() => {
    setCurrency((prevCurrency) => {
      if (value?.id !== prevCurrency?.id) {
        return value
      }

      return prevCurrency
    })
  }, [value])

  // When the dataCurrency and userCurrency are identical there's no need for a toggle
  if (dataCurrency === userCurrency.name || !localCurrency || !userCurrency) {
    return null
  }

  const handleCurrencyChange = (event: React.MouseEvent, newCurrency: CurrencyListItem | null) => {
    // Clicking the same button twice will result in a null value, we skip those
    if (newCurrency) {
      setCurrency(newCurrency)
      onChange(newCurrency)
    }
  }

  return (
    <StyledToggleButtonGroup
      onChange={handleCurrencyChange}
      size={'small'}
      value={currency}
      exclusive>
      <StyledToggleButton value={userCurrency}>
        {symbolOnly ? currencySymbol(userCurrency) : currencyDisplayedAs(userCurrency)}
      </StyledToggleButton>
      <StyledToggleButton value={localCurrency}>
        {symbolOnly ? currencySymbol(localCurrency) : currencyDisplayedAs(localCurrency)}
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  )
}

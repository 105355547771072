import { useRpcClient } from '@gain/api/swr'
import { useCallback } from 'react'

import { useCheckBookmarksListError, useNavigateToNextBookmarksList } from './bookmarks-hooks'
import { useMutateBookmarksList } from './bookmarks-list-hooks'

export function useShareBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (listId: number, userIds: number[]) => {
      try {
        await fetcher({
          method: 'lists.shareCustomAssetList',
          params: { id: listId, userIds },
        })

        await mutateList(listId)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher]
  )
}

export function useUnshareBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (listId: number, userIds: number[]) => {
      try {
        await fetcher({
          method: 'lists.unshareCustomAssetList',
          params: { id: listId, userIds },
        })

        await mutateList(listId)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher]
  )
}

export function useTransferBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (listId: number, newOwnerUserId: number) => {
      try {
        await fetcher({
          method: 'lists.transferCustomAssetList',
          params: { id: listId, newOwnerUserId },
        })

        await mutateList(listId)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher]
  )
}

export function useLeaveBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()
  const navigateAway = useNavigateToNextBookmarksList()

  return useCallback(
    async (listId: number) => {
      try {
        await fetcher({
          method: 'lists.leaveCustomAssetList',
          params: { id: listId },
        })

        // Remove list from cache
        await mutateList(listId, () => undefined)

        navigateAway(listId, 'customAssetList')
      } catch (err) {
        checkListError(err)
      }
    },
    [fetcher, navigateAway, mutateList, checkListError]
  )
}

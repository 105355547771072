import { createContext } from 'react'

export interface FinancialResultsTableContextType {
  labelCellWidth: number
  minValueCellWidth: number
  cellPaddingX: number
  leftCellPaddingLeft: number
}

export const FinancialResultsTableContext = createContext<FinancialResultsTableContextType | null>(
  null
)

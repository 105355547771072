import React from 'react'

import ExportContainer, { ExportContainerMethod, ExportContainerProps } from './export-container'
import ExportLoadingButton, { ExportLoadingButtonProps } from './export-loading-button'
import { ExportMethodMap } from './export-method-config'

type ExportButtonProps<
  Method extends ExportContainerMethod,
  Params extends ExportMethodMap[Method]['params']
> = Omit<ExportContainerProps<Method, Params>, 'children'> &
  ExportLoadingButtonProps & { disabled?: boolean }

export default function ExportButton<
  Method extends ExportContainerMethod,
  Params extends ExportMethodMap[Method]['params']
>({
  color,
  variant = 'icon',
  text,
  className,
  fullWidth,
  disabled,
  tooltipMode,
  ...props
}: ExportButtonProps<Method, Params>) {
  return (
    <ExportContainer {...props}>
      {(api) => (
        <ExportLoadingButton
          {...api}
          className={className}
          color={color}
          disabled={disabled ?? api.disabled}
          fullWidth={fullWidth}
          text={text}
          tooltipMode={tooltipMode}
          variant={variant}
        />
      )}
    </ExportContainer>
  )
}

import Typography from '@gain/components/typography'
import { InvestorListItem } from '@gain/rpc/app-model'
import { RenderCellParams } from '@gain/utils/table'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: theme.spacing(0.25),
}))

export function InvestorListItemFlagshipFundNameTableCell({
  value,
  row,
}: RenderCellParams<InvestorListItem, 'flagshipFundName'>) {
  const { flagshipFundVintageYear: year } = row

  if (value === null) {
    return (
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        Not Specified
      </Typography>
    )
  }

  return (
    <StyledContainer>
      <Typography
        color={'textPrimary'}
        variant={'subtitle2'}
        noWrap>
        {value}
      </Typography>
      {year && (
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          {year}
        </Typography>
      )}
    </StyledContainer>
  )
}

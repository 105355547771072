import { styled } from '@mui/material/styles'
import React from 'react'

import MenuListItem from './menu-list-item'

const StyledMenuListItem = styled(MenuListItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
  ...theme.typography.body2,
}))

export type EmptyListItemProps = {
  message: string
}

export function EmptyListItem(props: EmptyListItemProps) {
  return <StyledMenuListItem label={props.message} />
}

import {
  isCustomAssetList,
  useUpdateBookmarksFilteredList,
  useUpdateBookmarksList,
} from '@gain/api/app/hooks'
import { BookmarksFilteredList, CustomAssetList } from '@gain/rpc/app-model'
import React from 'react'

import BookmarksListTitleDialog from '../bookmarks-list-title-dialog/bookmarks-list-title-dialog'

interface BookmarksListRenameDialogProps {
  list: CustomAssetList | BookmarksFilteredList
  open: boolean
  onClose: () => void
}

export default function BookmarksListRenameDialog({
  list,
  onClose,
  open,
}: BookmarksListRenameDialogProps) {
  const updateAssetList = useUpdateBookmarksList()
  const updateFilteredList = useUpdateBookmarksFilteredList()

  const handleSubmit = async (title: string) => {
    if (isCustomAssetList(list)) {
      await updateAssetList(list.id, { title })
    } else {
      await updateFilteredList(list.id, { title })
    }
    onClose()
  }

  return (
    <BookmarksListTitleDialog
      dialogTitle={'Rename list'}
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      value={list.title}
    />
  )
}

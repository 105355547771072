import { useFindCurrencyCallback, useUserCurrency } from '@gain/api/app/hooks'
import { useCallback } from 'react'

import { formatCurrency, FormatCurrencyOptions } from './format-currency'
import { useConvertCurrencyCallback } from './use-convert-currency-callback'

export type FormatCurrencyCallbackOptions = Omit<FormatCurrencyOptions, 'currency'> & {
  dataCurrency?: string | null
}

/**
 * Convert and format a monetary value, e.g. "NOK -3.2m" and "-$123bn".
 *
 * It assumes the data value is in EUR but if not you should specify the
 * dataCurrency parameter. By default, it converts to the user's currency,
 * but in case you want to convert to another currency specify the
 * toCurrency parameter.
 */
export function useFormatCurrencyCallback(
  dataCurrency?: string | null,
  toCurrency?: string | null
) {
  const userCurrency = useUserCurrency()
  const findCurrency = useFindCurrencyCallback()
  const convertCurrency = useConvertCurrencyCallback()

  return useCallback(
    (
      value: number | null | undefined,
      { emptyValue, ...options }: FormatCurrencyCallbackOptions = {
        emptyValue: '-',
      }
    ): string => {
      // Determine which currency we are converting from and to. Options passed
      // into the callback override those passed into the hook.
      const from = options.dataCurrency || dataCurrency
      const to = toCurrency ? findCurrency(toCurrency) : userCurrency
      if (!to) {
        return emptyValue ?? ''
      }

      // If neither was set fallback to 1 and assume it's EUR.
      const convertedValue = convertCurrency(value, from, to.name)
      if (convertedValue === null) {
        return emptyValue ?? ''
      }

      return formatCurrency(convertedValue, { currency: to, ...options })
    },
    [convertCurrency, dataCurrency, findCurrency, toCurrency, userCurrency]
  )
}

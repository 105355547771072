import Button, { ButtonProps } from '@mui/material/Button'
import { alpha, styled } from '@mui/material/styles'
import React from 'react'

type ButtonColor = 'blue' | 'grey' | 'error'

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'color',
})<{ colorStyle?: ButtonColor }>(({ theme, colorStyle }) => ({
  color: theme.palette.background.paper,
  minWidth: 34,
  minHeight: 34,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  ...(colorStyle === 'blue' && {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.6),
    },
    '&:not(:first-child)': {
      marginLeft: theme.spacing(0),
    },
  }),
  ...(colorStyle === 'grey' && {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.4),
    },
  }),
  ...(colorStyle === 'error' && {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  }),
}))

type TableFooterButtonProps = Omit<ButtonProps, 'color'> & {
  color?: ButtonColor
  variant?: 'text' | 'icon'
}

export function TableFooterButton({ color, variant = 'text', ...props }: TableFooterButtonProps) {
  return (
    <StyledButton
      {...props}
      colorStyle={color}
      variant={variant}
    />
  )
}

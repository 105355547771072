import { formatCurrency } from '@gain/utils/currency'
import { formatNumber } from '@gain/utils/number'

export function formatFte(value: number | null | undefined, estimate: boolean) {
  // if the value is between 0 and 1, format it as 1
  const valueToFmt = value && value > 0 && value < 1 ? 1 : value

  // format the value as an integer
  return formatNumber(valueToFmt, { round: 0 })
}

export function formatRevenueFte(value: number | null | undefined) {
  return formatCurrency(value, { round: 'auto', format: 'thousands-or-millions' })
}

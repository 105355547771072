import { styled } from '@mui/material/styles'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import clsx from 'clsx'

import { virtualTableClasses } from './virtual-table'

export interface VirtualTableCellProps extends Omit<TableCellProps, 'width'> {
  width: number
  left: number
  position: 'absolute' | 'sticky'
  shadow: boolean
}

const VirtualTableCell = styled(
  ({ shadow, className, ...props }: VirtualTableCellProps) => {
    return (
      <TableCell
        className={clsx(className, shadow && virtualTableClasses.shadowCell)}
        component={'div'}
        {...props}
      />
    )
  },
  {
    shouldForwardProp: (propName) => {
      return !['width', 'left', 'position'].includes(propName as string)
    },
  }
)<VirtualTableCellProps>(({ width, left, position, shadow }) => ({
  alignItems: 'center',
  width: width,
  minWidth: width,
  maxWidth: width,
  overflow: 'hidden',
  left,
  position,

  ...(shadow && {
    boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.09)',
    clipPath: 'inset(0 -15px 0 0)',
  }),
}))

export default VirtualTableCell

import { stringify } from 'query-string'
import { useDebouncedCallback } from 'use-debounce'
import { encodeQueryParams, QueryParamConfig, useQueryParam } from 'use-query-params'

export const SearchParam: QueryParamConfig<string | null> = {
  encode: (value) => (typeof value === 'string' ? encodeURIComponent(value) : undefined),
  decode: (value) => (typeof value === 'string' ? decodeURIComponent(value) : null),
}

export const SEARCH_QUERY_PARAM_KEY = 'search'

export function useSearchQueryParam() {
  const [search, setSearch] = useQueryParam(SEARCH_QUERY_PARAM_KEY, SearchParam)

  const debouncedSetSearch = useDebouncedCallback((newSearch: string | null) => {
    if ((newSearch || '').trim().length > 0) {
      setSearch(newSearch)
    } else if (search) {
      setSearch(null)
    }
  }, 250)

  return [search, debouncedSetSearch] as const
}

export function encodeSearchQueryParams(value: string | null) {
  const encodedQuery = encodeQueryParams(
    {
      [SEARCH_QUERY_PARAM_KEY]: SearchParam,
    },
    {
      [SEARCH_QUERY_PARAM_KEY]: value,
    }
  )
  return stringify(encodedQuery)
}

import { useUserProfileContext } from '@gain/modules/user'
import { ListItemKey } from '@gain/rpc/list-model'
import { ComponentType } from 'react'

import { FilterConfig } from '../filter-config/filter-config-model'
import FilterValueAssetLabelValue from './filter-value-asset-label-value'
import FilterValueAutocompleteLabelValue from './filter-value-autocomplete-label-value'
import FilterValueRangeCurrencyValue from './filter-value-currency-value'
import { formatFilterValue } from './filter-value-formatter'
import FilterValueLabelValue, { FilterValueLabelValueProps } from './filter-value-label-value'

export interface FilterValueLabelProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filter: FilterConfig<Item, FilterField>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  ValueLabelComponent?: ComponentType<FilterValueLabelValueProps>
}

export default function FilterValueLabel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({
  filter,
  value,
  ValueLabelComponent = FilterValueLabelValue,
}: FilterValueLabelProps<Item, FilterField>) {
  const userProfile = useUserProfileContext()
  const { label } = filter

  if (value === null) {
    return label
  }

  switch (filter.type) {
    case 'autocomplete':
      return (
        <FilterValueAutocompleteLabelValue
          filter={filter}
          label={label}
          value={value}
          ValueLabelComponent={ValueLabelComponent}
        />
      )
    case 'range-currency':
      return (
        <FilterValueRangeCurrencyValue
          format={filter.isMillions === false ? 'thousands-or-millions' : undefined}
          label={label}
          value={value}
          ValueLabelComponent={ValueLabelComponent}
        />
      )
    case 'similar-to':
      return (
        <FilterValueAssetLabelValue
          label={label}
          value={value}
          ValueLabelComponent={ValueLabelComponent}
        />
      )
    default:
      return (
        <ValueLabelComponent
          label={label}
          value={formatFilterValue(value, filter, userProfile)}
        />
      )
  }
}

import { useRpcClient } from '@gain/api/swr'
import { useCallback } from 'react'

import { useMutateBookmarksFilteredList } from './bookmarks-filtered-hooks'
import { useCheckBookmarksListError, useNavigateToNextBookmarksList } from './bookmarks-hooks'

export function useShareBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (listId: number, userIds: number[]) => {
      try {
        await fetcher({
          method: 'lists.shareCustomAssetQuery',
          params: { id: listId, userIds },
        })

        await mutateList(listId)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher]
  )
}

export function useUnshareBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (listId: number, userIds: number[]) => {
      try {
        await fetcher({
          method: 'lists.unshareCustomAssetQuery',
          params: { id: listId, userIds },
        })

        await mutateList(listId)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher]
  )
}

export function useLeaveBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const checkListError = useCheckBookmarksListError()
  const navigateAway = useNavigateToNextBookmarksList()

  return useCallback(
    async (listId: number) => {
      try {
        await fetcher({
          method: 'lists.leaveCustomAssetQuery',
          params: { id: listId },
        })

        // Remove list from cache
        await mutateList(listId, () => undefined)

        navigateAway(listId, 'customAssetQueryList')
      } catch (err) {
        checkListError(err)
      }
    },
    [fetcher, navigateAway, mutateList, checkListError]
  )
}

export function useTransferBookmarksFilteredList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksFilteredList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (listId: number, newOwnerUserId: number) => {
      try {
        await fetcher({
          method: 'lists.transferCustomAssetQuery',
          params: { id: listId, newOwnerUserId },
        })

        await mutateList(listId)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher]
  )
}

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'

export function getValueCellWidth(
  availableWidth: number,
  nrOfColumns: number,
  minCellWidth: number,
  maxCellWidth: number | undefined,
  isXs: boolean,
  useMinWidthOnOverflow?: boolean
) {
  if (isXs) {
    return minCellWidth
  }

  const maxVisibleColumns = Math.floor(availableWidth / minCellWidth)

  if (maxVisibleColumns >= nrOfColumns) {
    return availableWidth / nrOfColumns
  }

  if (useMinWidthOnOverflow) {
    return minCellWidth
  }

  const cellMinWidth = availableWidth / maxVisibleColumns
  const cellWidth = cellMinWidth < minCellWidth ? minCellWidth : cellMinWidth

  if (maxCellWidth && cellWidth > maxCellWidth) {
    return maxCellWidth
  }

  return cellWidth
}

export function useValueCellWidth(
  availableWidth: number,
  nrOfColumns: number,
  minCellWidth: number,
  maxCellWidth?: number,
  useMinWidthOnOverflow?: boolean
) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  return useMemo(
    () =>
      getValueCellWidth(
        availableWidth,
        nrOfColumns,
        minCellWidth,
        maxCellWidth,
        isXs,
        useMinWidthOnOverflow
      ),
    [availableWidth, nrOfColumns, minCellWidth, maxCellWidth, isXs, useMinWidthOnOverflow]
  )
}

/* eslint-disable simple-import-sort/imports */
import './firebase'
import './error-reporting'

import * as ReactDOM from 'react-dom/client'
import ResizeObserver from 'resize-observer-polyfill'

// store must be imported before App

import { App } from './app'
import React, { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
)

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('load', () => {
    // https://felixgerschau.com/how-to-make-your-react-app-a-progressive-web-app-pwa/
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
    }
  })
}

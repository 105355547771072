import { InvestorFund } from '@gain/rpc/app-model'
import { formatMonthYear } from '@gain/utils/date'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'

import FinancialValue from '../../financial/financial-value'

export interface InvestorFundListProps {
  funds: InvestorFund[]
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

const StyledListItemText = styled(ListItemText)({
  [`& .${listItemTextClasses.secondary}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export function InvestorFundList({ funds }: InvestorFundListProps) {
  const sortedFunds = useMemo(
    () => funds.slice().sort((a, b) => (a.fundSizeEur || 0) - (b.fundSizeEur || 0)),
    [funds]
  )

  return (
    <List disablePadding>
      {sortedFunds.map((fund, index) => (
        <StyledListItem
          key={index}
          divider={index < sortedFunds.length - 1}>
          <StyledListItemText
            primary={fund.name}
            secondary={
              <>
                <span>
                  {fund.vintageDate &&
                    formatMonthYear(fund.vintageDate.month, fund.vintageDate.year)}
                </span>
                <span>
                  <FinancialValue amount={fund.fundSizeEur} />
                </span>
              </>
            }
          />
        </StyledListItem>
      ))}
    </List>
  )
}

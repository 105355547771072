import { ChevronRightIcon, LeafIcon } from '@gain/components/icons'
import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useRef } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import { generateIndustryPagePath, generateRouteSegmentPath } from '../../../routes/utils'
import IndustryEsgAssessmentList from './industry-esg-assessment-list'
import IndustryEsgAssessmentTable from './industry-esg-assessment-table'

const StyledCard = styled(Card)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

interface EsgAssessmentCardProps {
  description?: string
  segments: IndustryMarketSegmentListItem[]
  className?: string
  esgOutperformer?: boolean
}

export default function EsgAssessmentCard({
  description,
  segments,
  className,
  esgOutperformer,
}: EsgAssessmentCardProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  return (
    <StyledCard
      ref={ref}
      className={className}>
      <CardHeader title={'ESG assessment'} />

      <CardContent>
        {description && <Typography variant={'body2'}>{description}</Typography>}

        {esgOutperformer && (
          <Chip
            color={'success'}
            icon={<LeafIcon color={'inherit'} />}
            label={'ESG outperformer'}
          />
        )}

        {segments.length > 0 && (
          <>
            <Typography
              mt={3}
              variant={'h5'}>
              Industry assessment
            </Typography>
            <Stack
              mt={2}
              spacing={3}>
              {segments.map((segment) => (
                <Box key={segment.id}>
                  <Stack
                    alignItems={'center'}
                    direction={'row'}
                    mb={1}>
                    <Link
                      color={'text.secondary'}
                      href={generateIndustryPagePath({
                        id: segment.industryId,
                        title: segment.industryTitle,
                      })}
                      variant={'button'}>
                      {segment.industryTitle}
                    </Link>
                    <ChevronRightIcon />
                    <Link
                      color={'text.primary'}
                      href={generateRouteSegmentPath({
                        id: segment.id,
                        title: segment.name,
                      })}
                      variant={'subtitle2'}>
                      {segment.name}
                    </Link>
                  </Stack>
                  {width >= 888 && <IndustryEsgAssessmentTable segment={segment} />}
                  {width < 888 && <IndustryEsgAssessmentList segment={segment} />}
                </Box>
              ))}
            </Stack>
          </>
        )}
      </CardContent>
    </StyledCard>
  )
}

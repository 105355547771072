import { CloseIcon } from '@gain/components/icons'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import TextField from '@mui/material/TextField'
import React from 'react'

const StyledSearchInput = styled(TextField)(({ theme }) => ({
  [`& > .${inputBaseClasses.root}`]: {
    boxShadow: theme.palette.shadow.level1c,
  },
  [`& .${inputBaseClasses.input}`]: {
    height: 32,
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${iconButtonClasses.root}`]: {
    borderRadius: '50%',
    fontSize: 12,
    marginRight: theme.spacing(0),
    padding: theme.spacing(0.25),
  },

  [`& > .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}))

interface FilterCheckboxListSearchProps {
  searchQuery: string
  onChange: (searchQuery: string) => void
}

export default function FilterCheckboxListSearch({
  searchQuery,
  onChange,
}: FilterCheckboxListSearchProps) {
  return (
    <StyledSearchInput
      InputProps={{
        endAdornment: !!searchQuery && (
          <InputAdornment position={'end'}>
            <StyledIconButton
              edge={'end'}
              onClick={() => onChange('')}>
              <CloseIcon fontSize={'inherit'} />
            </StyledIconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => onChange(e.target.value)}
      placeholder={'Search'}
      size={'small'}
      value={searchQuery}
      fullWidth
    />
  )
}

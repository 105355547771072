import { ChevronDownIcon } from '@gain/components/icons'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import React, { useRef, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'

import { AutocompleteMatchMode } from '../../filter-config/filter-config-model'
import { AutocompleteFormSchemaType, AutocompleteIncludeMode } from './filter-autocomplete'

const StyledRoot = styled('div')(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.primary,
}))

const StyledButton = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
}))

interface FilterAutocompleteModeSelectProps {
  label: string
  name: AutocompleteIncludeMode
  readonly?: boolean
}

export default function FilterAutocompleteMatchModeSelect({
  label,
  name,
  readonly,
}: FilterAutocompleteModeSelectProps) {
  const buttonRef = useRef<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  const controller = useController<AutocompleteFormSchemaType, `${typeof name}.mode`>({
    name: `${name}.mode`,
  })

  const watch = useWatch<AutocompleteFormSchemaType, typeof name>({
    name,
  })

  const options: Array<{ label: string; value: AutocompleteMatchMode; menuSuffix: string }> = [
    { label: 'Any match', value: 'any', menuSuffix: 'OR' },
    {
      label: `${watch.value.length === 2 ? 'Both' : 'All'} match`,
      value: 'all',
      menuSuffix: 'AND',
    },
  ]

  const selectedOption = options.find((option) => option.value === watch.mode)

  const showMatchMode = watch.value.length > 1 && !readonly

  return (
    <StyledRoot>
      <span
        onClick={() => {
          if (showMatchMode) {
            setOpen(true)
          }
        }}>
        {label}
      </span>
      {showMatchMode && (
        <>
          :&nbsp;
          <StyledButton
            ref={buttonRef}
            onClick={() => setOpen(true)}>
            {selectedOption?.label}
            <ChevronDownIcon fontSize={'inherit'} />
          </StyledButton>
          <Menu
            anchorEl={buttonRef.current}
            onClose={() => setOpen(false)}
            open={open}
            transformOrigin={{
              vertical: -4,
              horizontal: 0,
            }}>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  controller.field.onChange(option.value)
                  setOpen(false)
                }}
                selected={watch.mode === option.value}>
                {option.label} ({option.menuSuffix})
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </StyledRoot>
  )
}

import { LegalEntity } from '@gain/rpc/app-model'
import { compareNumberDesc } from '@gain/utils/common'

import Card, { CardHeader } from '../../../../common/card/card'
import LegalStructuresAndShareholdersTable from './legal-structures-and-shareholders-table'

function compareLegalEntityByRevenueAndName(a: LegalEntity, b: LegalEntity) {
  const aRevenue = a.financialLatests?.revenue
  const bRevenue = b.financialLatests?.revenue

  const revenueCompare = compareNumberDesc(aRevenue, bRevenue)
  if (revenueCompare !== 0) {
    return revenueCompare
  }

  return a.name.localeCompare(b.name)
}

interface LegalStructuresAndShareholdersPropsCard {
  legalEntities: LegalEntity[]
}

export default function LegalStructuresAndShareholdersCard({
  legalEntities,
}: LegalStructuresAndShareholdersPropsCard) {
  const sortedEntities = legalEntities.sort(compareLegalEntityByRevenueAndName)

  return (
    <Card sx={{ pb: 0, overflow: 'hidden' }}>
      <CardHeader title={'Legal structure and shareholders'} />
      <LegalStructuresAndShareholdersTable legalEntities={sortedEntities} />
    </Card>
  )
}

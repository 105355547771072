import { LegalEntity } from '@gain/rpc/app-model'
import { getAnnualReportYear, getLastAnnualReport } from '@gain/utils/annual-report'
import { compareBooleanDesc, compareNumberDesc } from '@gain/utils/common'

export function compareLegalEntitiesByLastArYearDesc(a: LegalEntity, b: LegalEntity) {
  const aAr = getLastAnnualReport(a.annualReports)
  const bAr = getLastAnnualReport(b.annualReports)

  const yearCompare = compareNumberDesc(getAnnualReportYear(aAr), getAnnualReportYear(bAr))
  if (yearCompare !== 0) {
    return yearCompare
  }

  const processedCompare = compareBooleanDesc(!!aAr?.parsedAt, !!bAr?.parsedAt)
  if (processedCompare !== 0) {
    return processedCompare
  }

  return a.name.localeCompare(b.name)
}

import { Rating } from '../rating-model'
import {
  ratingBuyAndBuild,
  ratingContracted,
  ratingEbitda,
  ratingGrowth,
  ratingNonCyclical,
  ratingOrganicGrowth,
} from './rating-investment-criteria'

export interface FormatInvestmentCriteriaOptions {
  emptyValue?: string
  withSuffix?: boolean
}

export function formatInvestmentCriteria<D extends string>(
  value: number | null | undefined,
  rating: Rating<D>,
  { emptyValue = '-', withSuffix }: FormatInvestmentCriteriaOptions = {}
) {
  if (typeof value !== 'number') {
    return emptyValue
  }

  const option = rating.options.find((item) => item.value === value)

  if (option) {
    if (withSuffix) {
      return [option.label, rating.suffix].filter(Boolean).join(' ')
    } else {
      return option.label
    }
  }

  return emptyValue
}

export const formatGrowth = (
  value: number | null | undefined,
  options?: FormatInvestmentCriteriaOptions
) => formatInvestmentCriteria(value, ratingGrowth, options)

export const formatEbitda = (
  value: number | null | undefined,
  options?: FormatInvestmentCriteriaOptions
) => formatInvestmentCriteria(value, ratingEbitda, options)

export const formatOrganicGrowth = (
  value: number | null | undefined,
  options?: FormatInvestmentCriteriaOptions
) => formatInvestmentCriteria(value, ratingOrganicGrowth, options)

export const formatBuyAndBuild = (
  value: number | null | undefined,
  options?: FormatInvestmentCriteriaOptions
) => formatInvestmentCriteria(value, ratingBuyAndBuild, options)

export const formatNonCyclical = (
  value: number | null | undefined,
  options?: FormatInvestmentCriteriaOptions
) => formatInvestmentCriteria(value, ratingNonCyclical, options)

export const formatContracted = (
  value: number | null | undefined,
  options?: FormatInvestmentCriteriaOptions
) => formatInvestmentCriteria(value, ratingContracted, options)

import { LenderListItem } from '@gain/rpc/app-model'
import { useOnMountEffect } from '@gain/utils/react'
import React from 'react'

import ResourceOfflinePage from '../../common/resource-not-available'
import { useTrackEvent } from '../../features/google-analytics'

export interface LenderOfflineProps {
  lender: LenderListItem
}

export default function LenderOffline({ lender }: LenderOfflineProps) {
  const trackEvent = useTrackEvent()

  useOnMountEffect(() => {
    trackEvent('Resource offline', 'Lender detail page', lender.name)
  })

  return (
    <ResourceOfflinePage
      avatarSrc={lender?.logoFileUrl}
      message={
        <>
          We found new data points and are processing them. Please come back later to view{' '}
          <strong>{lender.name}</strong>.
        </>
      }
      title={lender.name}
    />
  )
}

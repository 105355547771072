import { useTheme } from '@mui/material/styles'

export default function useTeamMemberStatusColor(status: string) {
  const theme = useTheme()

  switch (status) {
    case 'active':
      return theme.palette.success.main
    case 'invited':
      return theme.palette.info.main
    case 'deactivated':
      return theme.palette.error.main
    case 'notInvited':
      return theme.palette.text.secondary
    default:
      return theme.palette.text.primary
  }
}

const CARD_HEIGHT = 140 // This excludes any rows
const CARD_GAP = 16
const ROW_HEIGHT = 38
const MIN_ROWS = 5
const MIN_CARD_HEIGHT = CARD_HEIGHT + MIN_ROWS * ROW_HEIGHT

export function calculateVisibleRows(containerHeight: number, nrOfCards = 1) {
  // If the container height is 0 return 0 which causes a loading animation
  if (containerHeight === 0) {
    return 0
  }

  let minContentHeight = nrOfCards * MIN_CARD_HEIGHT

  // Add a gap for each card
  minContentHeight += (nrOfCards - 1) * CARD_GAP

  // Check if there is room for additional rows
  if (containerHeight < minContentHeight) {
    return MIN_ROWS
  }

  // Calc remaining available space
  const remainingHeight = containerHeight - minContentHeight

  // Divide remaining space as rows over the tables
  return Math.floor(remainingHeight / ROW_HEIGHT / nrOfCards) + MIN_ROWS
}

import { styled } from '@mui/material/styles'
import React, { forwardRef, memo } from 'react'

type VirtualTableBodyProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'height'
> & {
  height: number
}

const StyledRoot = styled('div')({
  display: 'block',
  position: 'relative',
})

export const VirtualTableBody = memo(
  forwardRef<HTMLDivElement, VirtualTableBodyProps>(({ height, ...props }, ref) => {
    return (
      <StyledRoot
        ref={ref}
        sx={{
          flex: `1 0 ${height}px`,
        }}
        {...props}
      />
    )
  })
)

import { useAssetAdvisors } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import { calculateVisibleRows } from '../../../common/visible-rows'
import AssetAdvisorsTable from './asset-advisors-table'

interface AssetAdvisorsProps {
  asset: Asset
  availableHeight: number
}

export default function AssetAdvisorsTab({ asset, availableHeight }: AssetAdvisorsProps) {
  const swrAssetAdvisors = useAssetAdvisors(asset.id)

  const nrOfVisibleRows = calculateVisibleRows(availableHeight)

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Advisors</title>
      </Head>

      <Card sx={{ pb: 1, width: '100%' }}>
        <CardHeader title={'Deal advisors'} />
        {!isEmpty(swrAssetAdvisors.data) ? (
          <AssetAdvisorsTable
            asset={asset}
            items={swrAssetAdvisors.data ?? []}
            nrOfVisibleRows={nrOfVisibleRows}
          />
        ) : (
          <CardContentEmpty
            message={'There are no advisors associated with this company'}
            variant={'tall'}
          />
        )}
      </Card>
    </>
  )
}

import { useTheme } from '@mui/material/styles'
import { Variant } from '@mui/material/styles/createTypography'
import { useMemo } from 'react'

import { getTextWidth } from './text-width'

export function useMaxTextWidth(labels: string[], variant: Variant = 'overline') {
  const theme = useTheme()

  return useMemo(() => {
    const { fontFamily, fontSize, fontWeight } = theme.typography[variant]

    let maxWidth = 0

    labels.forEach((label) => {
      const width = getTextWidth(label, fontFamily, fontSize, fontWeight)

      if (width > maxWidth) {
        maxWidth = width
      }
    })

    return maxWidth
  }, [labels, theme.typography, variant])
}

import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import {
  default as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography'
import React, { PropsWithChildren } from 'react'

import { useTooltipOnEllipsis } from './use-tooltip-on-ellipsis.hook'

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: (propName) => propName !== 'maxLines',
})<{ maxLines?: number }>(({ maxLines }) => ({
  '&::after': {
    content: '""',
    display: 'block',
  },
  ...(typeof maxLines === 'number' &&
    maxLines > 1 && {
      '&': {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
    }),
}))

interface TooltipTypographyProps extends MuiTypographyProps {
  maxLines?: number
}

const TooltipTypography = React.forwardRef<
  HTMLParagraphElement,
  PropsWithChildren<TooltipTypographyProps>
>(function TooltipTypography({ title, children, noWrap, maxLines, ...props }, ref) {
  const direction = maxLines === 1 || noWrap ? 'horizontal' : 'vertical'
  const tooltip = useTooltipOnEllipsis(direction)

  return (
    <Tooltip
      open={tooltip.tooltipOpen}
      title={title || children || ''}
      disableInteractive>
      <StyledTypography
        {...props}
        ref={ref}
        maxLines={maxLines}
        noWrap={maxLines === 1 || noWrap}
        onMouseOut={tooltip.handleMouseOut}
        onMouseOver={tooltip.handleMouseOver}>
        {children}
      </StyledTypography>
    </Tooltip>
  )
})

export default TooltipTypography

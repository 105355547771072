import { Asset } from '@gain/rpc/app-model'
import { formatFte } from '@gain/utils/financials'
import { isAmountEstimated } from '@gain/utils/financials'
import { formatPercentage } from '@gain/utils/number'
import { useIsXs } from '@gain/utils/responsive'
import { useTheme } from '@mui/material/styles'
import { timeYear } from 'd3'
import { useMemo } from 'react'

import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import { MeasureDimensions } from '../../../common/responsive'
import { MultiLineChart } from '../../chart'
import { GrowthIndicator } from './card-asset-fte-growth-indicator'

export interface CardAssetFteGainProTabProps {
  asset: Asset
}

export default function CardAssetFteGainProTab({ asset }: CardAssetFteGainProTabProps) {
  const isXs = useIsXs()
  const theme = useTheme()

  const latestFinancialResultWithFte = asset.financialResults
    .slice()
    .reverse()
    .find((item) => item.fte !== null)

  const data = useMemo(() => {
    let financialResults = asset.financialResults.filter(({ fte }) => fte?.amount)

    if (financialResults.length > 5 && isXs) {
      financialResults = financialResults.reverse().slice(0, 5).reverse()
    }

    return [
      {
        data: financialResults,
        color: theme.palette.info.main,
        id: 1,
      },
    ]
  }, [theme.palette.info.main, asset.financialResults, isXs])

  return (
    <>
      <KeyValueList>
        {(latestFinancialResultWithFte?.fte?.amount || asset.generalInfo?.fte) && (
          <KeyValueListItem
            label={'FTEs'}
            value={formatFte(
              latestFinancialResultWithFte?.fte?.amount || asset.generalInfo?.fte,
              isAmountEstimated(latestFinancialResultWithFte?.fte?.amountType) ?? true
            )}
          />
        )}

        {latestFinancialResultWithFte?.fteGrowthPct?.amount !== undefined &&
          latestFinancialResultWithFte?.fteGrowthPct?.amount !== Infinity && (
            <KeyValueListItem
              label={'1y growth'}
              value={
                <GrowthIndicator
                  growthPct={latestFinancialResultWithFte?.fteGrowthPct.amount}
                  iconPosition={'start'}
                />
              }
            />
          )}
      </KeyValueList>

      <MeasureDimensions
        fixedHeight={'100%'}
        minHeight={232}>
        {({ width, height }) => (
          <MultiLineChart
            data={data}
            getId={(financialResult) => financialResult.id || financialResult.year}
            getIsEstimated={(financialResult) => isAmountEstimated(financialResult.fte?.amountType)}
            height={height}
            width={width}
            xScaleConfig={{
              getLabel: (date) => `${date.getFullYear()}`,
              getValue: (financialResult) => new Date(financialResult.year, 0, 1) || null,
              // Build the tooltip
              getTooltip: (financialResult) =>
                [
                  // If the FTE is an estimated prepend it
                  isAmountEstimated(financialResult.fte?.amountType) && 'Estimate:',
                  // Format the fte count
                  formatFte(
                    financialResult.fte?.amount,
                    isAmountEstimated(financialResult.fte?.amountType)
                  ),
                  // If we have a growth pct add it
                  financialResult.fteGrowthPct?.amount &&
                    `(${formatPercentage(financialResult.fteGrowthPct.amount)})`,
                ]
                  // Remove falsy values
                  .filter(Boolean)
                  .join(' '),
              ticks: (xScale) => xScale.ticks(timeYear),
              dateVariant: 'years',
            }}
            yScaleConfig={{
              getLabel: (fteCount) => formatFte(fteCount, true),
              getValue: (financialResult) => financialResult.fte?.amount || null,
            }}
          />
        )}
      </MeasureDimensions>
    </>
  )
}

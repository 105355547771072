import Typography from '@gain/components/typography'
import { DealFactFloat } from '@gain/rpc/app-model'
import { formatYear } from '@gain/utils/date'
import { formatMultiple } from '@gain/utils/number'
import Chip from '@mui/material/Chip'
import React from 'react'

import FinancialValue from '../../../../apps/app/src/features/financial/financial-value'

interface DealFactFloatProps {
  value: DealFactFloat | null
  type: 'float' | 'amount-float' | 'multiple-float'
  dataCurrency?: string | null
  toCurrency?: string | null
}

export function DealFactFloatFormat({ type, value, dataCurrency, toCurrency }: DealFactFloatProps) {
  if (value === null) {
    return null
  }

  const isEstimate = value.confidence === 'estimate'

  switch (type) {
    case 'float':
      return (
        <Typography
          alignItems={'center'}
          color={isEstimate ? 'info.main' : 'text.primary'}
          display={'flex'}
          variant={'body2'}
          noWrap>
          {value.value}
          {value.date && (
            <>
              &nbsp;
              <Chip
                label={formatYear(value.date.year, 'short')}
                size={'small'}
              />
            </>
          )}
        </Typography>
      )
    case 'amount-float':
      return (
        <FinancialValue
          amount={value.value}
          dataCurrency={dataCurrency}
          isEstimate={isEstimate}
          toCurrency={toCurrency}
          year={value.date ? value.date.year : undefined}
        />
      )
    case 'multiple-float':
      return (
        <Typography
          color={isEstimate ? 'info.main' : 'text.primary'}
          variant={'body2'}
          noWrap>
          {formatMultiple(value.value)}
        </Typography>
      )
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}

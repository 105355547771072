import Typography from '@gain/components/typography'
import {
  hasGroupRatings,
  ratingInvestmentCriteriaGroups,
  RatingInvestmentCriteriaKey,
  RatingInvestmentCriteriaMap,
} from '@gain/utils/investment-criteria'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { memo } from 'react'

import InvestmentCriteriaTableRow from './investment-criteria-table-row'

const StyledTable = styled('table', {
  shouldForwardProp: (prop) =>
    !['disableBorderBottom', 'disableBorderRadiusTop'].includes(prop as string),
})<{
  disableBorderBottom?: boolean
  disableBorderRadiusTop?: boolean
}>(({ theme, disableBorderBottom, disableBorderRadiusTop }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderCollapse: 'collapse',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,

  ...(disableBorderBottom && {
    borderBottom: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  }),

  ...(disableBorderRadiusTop && {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  }),
}))

const StyledTableBody = styled('tbody')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

export interface InvestmentCriteriaTableProps {
  rating: RatingInvestmentCriteriaMap
  variant?: 'default' | 'grouped'
}

const InvestmentCriteriaTable = memo<InvestmentCriteriaTableProps>(
  function InvestmentCriteriaTable({ rating: ratings, variant = 'default' }) {
    const visibleRatingGroups = React.useMemo(
      () =>
        ratingInvestmentCriteriaGroups.filter((group) =>
          hasGroupRatings<RatingInvestmentCriteriaKey>(group, ratings)
        ),
      [ratings]
    )

    return (
      <>
        {visibleRatingGroups.map((group, index) => (
          <Stack
            key={index}
            direction={'column'}
            spacing={1}>
            {variant === 'default' && <Typography variant={'subtitle2'}>{group.label}</Typography>}
            <StyledTable
              disableBorderBottom={index + 1 < visibleRatingGroups.length && variant === 'grouped'}
              disableBorderRadiusTop={index > 0 && variant === 'grouped'}>
              <StyledTableBody>
                {group.ratings
                  .filter((rating) => ratings[rating.key])
                  .map((rating) => (
                    <InvestmentCriteriaTableRow
                      key={rating.key}
                      rating={rating}
                      value={ratings[rating.key]}
                    />
                  ))}
              </StyledTableBody>
            </StyledTable>
          </Stack>
        ))}
      </>
    )
  }
)

export default InvestmentCriteriaTable

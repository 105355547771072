import { Deal } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { uniq } from 'lodash'
import { useMemo } from 'react'

export function getDealAssetIds(deal: Deal) {
  const investorIds = [...deal.buyers, ...deal.sellers]
    .map((party) => party.linkedAssetId)
    .filter(isDefined)
  return uniq(investorIds)
}

export function getDealsAssetIds(deals: Deal[]) {
  return uniq(deals.flatMap(getDealAssetIds))
}

export function useDealsAssetIds(deals: Deal[]) {
  return useMemo(() => getDealsAssetIds(deals), [deals])
}

import { ManagementPositionType } from '@gain/rpc/shared-model'
import { MANAGER_POSITION_OPTIONS } from '@gain/rpc/shared-model'
import { isDefined, valueToEnum } from '@gain/utils/common'
import { capitalize } from '@mui/material/utils'

export interface FormatManagerPositionOptions {
  emptyValue?: string
  disableEnumCheck?: boolean
}

export const formatManagerPosition = (
  position: string | null | undefined,
  { emptyValue = '-', disableEnumCheck = false }: FormatManagerPositionOptions = {}
) => {
  if (!isDefined(position)) {
    return emptyValue
  }

  const parsed = valueToEnum(ManagementPositionType, position)

  if (!isDefined(parsed)) {
    return disableEnumCheck ? capitalize(position) : emptyValue
  }

  return MANAGER_POSITION_OPTIONS.find((Option) => Option.value === parsed)?.label || ''
}

import { CurrencyListItem } from '@gain/rpc/app-model'
import { currencyDisplayedAs } from '@gain/utils/currency'
import { FinancialResult } from '@gain/utils/financials'
import { useMemo } from 'react'

import { ColumnConfig, ColumnFieldConfig } from '../../../../common/virtual-table'
import { FinancialResultsHeaderCell } from '../../../../features/financial/financial-results-table'
import FinancialCell from './financial-cell'
import { FinancialRow } from './use-financial-rows'

export const FINANCIAL_COLUMN_WIDTH = 88
export const FINANCIAL_COLUMN_END_PADDING = 20
export const LABEL_COLUMN_WIDTH = 112

function createFinancialColumnConfig(
  financialResult: FinancialResult,
  financialResultIndex: number,
  isLast: boolean
): ColumnFieldConfig<FinancialRow, 'years'> {
  return {
    headerName: `${financialResult.year} ${financialResult.isForecast}`,
    renderHeader: () => {
      return <FinancialResultsHeaderCell financial={financialResult} />
    },
    field: 'years',
    align: 'right',
    sortable: false,
    width: FINANCIAL_COLUMN_WIDTH + (isLast ? FINANCIAL_COLUMN_END_PADDING : 0),
    renderCell: ({ value, row }) => {
      const financialResultData = value.find((_, index) => index === financialResultIndex)

      if (!financialResultData) {
        return null
      }

      return (
        <FinancialCell
          amountType={financialResultData.amountType}
          formatFn={row.valueFormatter}
          isForecast={financialResult.isForecast}
          value={financialResultData.value}
        />
      )
    },
  }
}

export function useColumns(
  financialResults: FinancialResult[],
  currency: CurrencyListItem
): ColumnConfig<FinancialRow>[] {
  return useMemo(() => {
    const labelConfig: ColumnConfig<FinancialRow> = {
      headerName: currencyDisplayedAs(currency),
      field: 'label',
      sortable: false,
      width: LABEL_COLUMN_WIDTH,
      flex: 1,
    }

    const configPerFinancialResult = financialResults.map((financialResult, index) =>
      createFinancialColumnConfig(financialResult, index, index === financialResults.length - 1)
    )

    return [labelConfig, ...configPerFinancialResult]
  }, [currency, financialResults])
}

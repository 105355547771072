import { ArrowUpIcon } from '@gain/components/icons'
import { formatPercentage } from '@gain/utils/number'
import { styled, SxProps, Theme } from '@mui/material/styles'

export interface StyledFteGrowthIndicator {
  growthPct: number
}

const StyledGrowthIndicatorContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'growthPct',
})<StyledFteGrowthIndicator>(({ theme, growthPct }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.text.primary,
  ...theme.typography.body2,
  ...(growthPct > 0 && {
    color: theme.palette.success.main,
  }),
  ...(growthPct < 0 && {
    color: theme.palette.error.main,
  }),
}))

interface StyledArrowIconProps {
  direction: 'up' | 'down'
}

const StyledArrowIcon = styled(ArrowUpIcon, {
  shouldForwardProp: (prop) => prop !== 'direction',
})<StyledArrowIconProps>(({ direction }) => ({
  fontSize: 16,
  color: 'inherit',
  ...(direction === 'down' && {
    transform: 'rotate(180deg)',
  }),
}))

export interface GrowthIndicatorProps {
  growthPct: number
  sx?: SxProps<Theme>
}

export function GrowthIndicator({ growthPct, sx }: GrowthIndicatorProps) {
  return (
    <StyledGrowthIndicatorContainer
      growthPct={growthPct}
      sx={sx}>
      {growthPct !== 0 && <StyledArrowIcon direction={growthPct > 0 ? 'up' : 'down'} />}
      {formatPercentage(growthPct)}
    </StyledGrowthIndicatorContainer>
  )
}

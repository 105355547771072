import { Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid'
import React from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import ListSources from '../../../features/source/source-list'
import InvestorFundraisingCard from './investor-fundraising-card'
import InvestorFundsTable from './investor-funds-table'

export interface RouteFundsProps {
  investor: Investor
  strategies: InvestorProfileStrategy[]
}

export default function RouteFunds({ investor, strategies }: RouteFundsProps) {
  const hasFundPerformance = investor.funds.some((fund) => {
    return (
      fund.netIrr !== null ||
      fund.grossIrr !== null ||
      fund.twr !== null ||
      fund.tvpi !== null ||
      fund.moic !== null ||
      fund.dpi !== null ||
      fund.rvpi !== null
    )
  })

  return (
    <Grid
      spacing={2}
      container>
      <Grid
        xs={12}
        item>
        <InvestorFundraisingCard
          investor={investor}
          strategies={strategies}
        />
      </Grid>
      <Grid
        xs={12}
        item>
        <Card sx={{ pb: 0, overflow: 'clip' }}>
          <CardHeader title={'Funds'} />
          <InvestorFundsTable
            investor={investor}
            strategies={strategies}
          />
        </Card>
      </Grid>
      {hasFundPerformance && (
        <Grid
          xs={12}
          item>
          <ListSources
            sources={[
              {
                title:
                  'Fund performance data is collected through Freedom of Information Act (FOIA) requests made to public institutions in the US',
                publicationYear: null,
                publicationMonth: null,
                url: null,
                publisher: null,
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
  )
}

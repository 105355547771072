import { useOpenDialog } from '@app/common/dialog'
import { useRpcClient } from '@gain/api/swr'
import { formatListArgs, RpcError, RpcErrorCode } from '@gain/rpc/utils'
import { useCallback } from 'react'

export function useHandleRPCError() {
  const openDialog = useOpenDialog()

  return useCallback(
    (error: unknown) => {
      if (error instanceof RpcError) {
        switch (error.code) {
          case RpcErrorCode.InvalidToken:
            openDialog({
              title: 'Session expired',
              message: 'Your session has expired. Please sign in to continue.',
              buttonText: 'Ok',
            })
            return
          case RpcErrorCode.Unauthorized:
            openDialog({
              title: 'Forbidden',
              message: 'You are not allowed to perform this action.',
              buttonText: 'Ok',
            })
            return
        }
      }

      openDialog({
        title: 'Server Error',
        message: 'An error occurred please try again later',
        buttonText: 'Ok',
      })
    },
    [openDialog]
  )
}

export function useListUsersByIds() {
  const fetcher = useRpcClient()
  const handleError = useHandleRPCError()

  return useCallback(
    async (ids: number[]) => {
      try {
        const result = await fetcher({
          method: 'admin.listUsers',
          params: formatListArgs({
            filter: [
              {
                field: 'userId',
                operator: '=',
                value: ids,
              },
            ],
          }),
        })
        return result.items
      } catch (error) {
        handleError(error)
        return []
      }
    },
    [fetcher, handleError]
  )
}

import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginRight: theme.spacing(-3),
  marginLeft: theme.spacing(-3),
  width: `calc(100% + ${theme.spacing(6)})`,
}))

export interface MobilePageContainerProps {
  children?: ReactNode
  className?: string
}

export default function MobilePageListViewContainer({
  children,
  className,
}: MobilePageContainerProps) {
  return <StyledRoot className={className}>{children}</StyledRoot>
}

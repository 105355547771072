import { CompanyIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { isDefined } from '@gain/utils/common'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

import Logo, { LogoProps } from '../../logo'

const StyledContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
})

interface NameTableCellProps {
  name: string | null
  description?: ReactNode | string | null
  logoFileUrl?: string | null
  logoRegion?: string | null
  logoDot?: LogoProps['dot']
  defaultIcon?: ReactNode
  fullSize?: boolean
}

/**
 * Renders a table cell with a name (=required), logo and description. This is used as
 * the first column in a virtual table.
 */
export function NameVirtualTableCell({
  name,
  description,
  logoRegion,
  logoDot,
  logoFileUrl,
  defaultIcon = <CompanyIcon />,
  fullSize,
}: NameTableCellProps) {
  return (
    <>
      {logoFileUrl !== undefined && (
        <Box sx={{ mr: 1.5 }}>
          <Logo
            defaultIcon={defaultIcon}
            dot={logoDot}
            fullSize={fullSize}
            region={logoRegion}
            size={32}
            src={logoFileUrl}
          />
        </Box>
      )}
      <StyledContainer>
        <Typography
          color={'textPrimary'}
          variant={'subtitle2'}
          noWrap>
          {name}
        </Typography>

        {isDefined(description) && (
          <Typography
            color={'text.secondary'}
            variant={'body2'}
            noWrap>
            {description}
          </Typography>
        )}
      </StyledContainer>
    </>
  )
}

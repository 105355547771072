import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import MuiSkeleton, { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton'
import { SxProps, Theme } from '@mui/material/styles'

export interface SkeletonProps extends MuiSkeletonProps {
  enabled?: boolean
  inheritSize?: boolean
  inheritSx?: boolean
  boxSx?: SxProps<Theme>
}

export default function Skeleton({
  enabled = false,
  width,
  height,
  inheritSize,
  inheritSx,
  sx,
  boxSx,
  children,
  ...rest
}: SkeletonProps) {
  if (enabled) {
    return (
      <MuiSkeleton
        height={height}
        sx={sx}
        width={width}
        {...rest}>
        {children}
      </MuiSkeleton>
    )
  }

  if (!children) {
    return null
  }

  return (
    <Fade in>
      <Box
        height={inheritSize ? height : undefined}
        sx={inheritSx ? sx : boxSx}
        width={inheritSize ? width : undefined}>
        {children}
      </Box>
    </Fade>
  )
}

import { ListItemKey } from '@gain/rpc/list-model'
import Collapse, { collapseClasses } from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import { styled } from '@mui/material/styles'
import React, { useCallback, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'

import { FilterFormValues } from './filter-form-value'

const StyledClearButton = styled('button')(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  marginLeft: theme.spacing(2),
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

const StyledCollapse = styled(Collapse)({
  [`& .${collapseClasses.wrapperInner}`]: {
    display: 'flex',
    alignItems: 'center',
  },
})

interface FilterBarClearButtonProps<Item extends object, FilterField extends ListItemKey<Item>> {
  initialValues: FilterFormValues<Item, FilterField>
}

export default function FilterBarClearButton<
  Item extends object,
  FilterField extends ListItemKey<Item>
>({ initialValues }: FilterBarClearButtonProps<Item, FilterField>) {
  const [hasValues, setHasValues] = useState(false)
  const form = useForm()

  useFormState<FilterFormValues>({
    subscription: {
      values: true,
    },
    onChange: (formState) =>
      setHasValues(
        formState.values.groups.some((group) => group.value.some((item) => item.value !== null))
      ),
  })

  const handleClick = useCallback(() => {
    form.restart(initialValues)
  }, [form, initialValues])

  return (
    <StyledCollapse
      in={hasValues}
      orientation={'horizontal'}
      unmountOnExit>
      <Fade in={hasValues}>
        <StyledClearButton onClick={handleClick}>Clear</StyledClearButton>
      </Fade>
    </StyledCollapse>
  )
}

import { useLegalEntity } from '@gain/api/app/hooks'
import { Asset, AssetRating, LegalEntityRating } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useMemo } from 'react'

import { CardProps } from '../../../../common/card/card'
import CardAssessmentSummary from '../../../../features/assessment/assessment-summary-card'
import {
  ASSET_PAGE_ASSESSMENT_PATH,
  generateAssetPagePath,
  useAssetPageParams,
} from '../../../utils'

interface AssetAssessmentCardProps extends CardProps {
  asset: Asset
}

export default function AssetAssessmentCard({ asset, ...rest }: AssetAssessmentCardProps) {
  const params = useAssetPageParams()
  const legalEntity = useLegalEntity(
    asset.legalEntities.find(({ financials }) => financials)?.legalEntityId ?? null
  )

  const ratings: AssetRating | LegalEntityRating | null = useMemo(() => {
    if (legalEntity.data?.rating && asset.profileType === AssetProfileType.Automated) {
      return legalEntity.data.rating
    }
    return asset.rating
  }, [asset, legalEntity])

  const href = generateAssetPagePath(
    {
      id: asset.id,
      name: asset.generalInfo?.name,
      preview: params.preview,
    },
    ASSET_PAGE_ASSESSMENT_PATH
  )

  const hasProsOrCons = asset.pros.length > 0 || asset.cons.length > 0

  return (
    <CardAssessmentSummary
      nrOfRows={5}
      ratings={ratings}
      {...rest}
      href={ratings !== null || hasProsOrCons ? href : undefined}
      renderEmptyCard={() => {
        return (
          hasProsOrCons && (
            <Button
              color={'primary'}
              component={Link}
              href={href}
              size={'small'}
              variant={'outlined'}>
              View pros and cons
            </Button>
          )
        )
      }}
    />
  )
}

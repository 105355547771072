import Typography from '@gain/components/typography'
import { styled } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'
import clsx from 'clsx'
import React, { ReactNode } from 'react'

import { horizontalTableClasses } from './horizontal-table'
import { useHorizontalTableContext } from './horizontal-table-hooks'

export const StyledCell = styled('td', {
  shouldForwardProp: (prop) =>
    !['height', 'maxWidth', 'paddingX', 'borderLeft'].includes(prop.toString()),
})<{ width?: number; height?: number; maxWidth?: number; paddingX: number; borderLeft?: boolean }>(
  ({ theme, height, maxWidth, paddingX, borderLeft }) => ({
    height: height || 22,
    maxWidth,
    padding: theme.spacing(0, paddingX),

    ...(borderLeft && {
      borderLeft: `1px dashed ${theme.palette.divider}`,
    }),

    [`& .${typographyClasses.root}`]: {
      color: theme.palette.text.primary,
    },

    [`&.${horizontalTableClasses.cellEstimate} .${typographyClasses.root}`]: {
      color: theme.palette.info.main,
    },
  })
)

export interface HorizontalTableRowCellProps {
  children: ReactNode
  borderLeft?: boolean
  bold?: boolean
  estimated?: boolean
  className?: string
}

export default function HorizontalTableRowCell({
  children,
  borderLeft,
  bold,
  className,
}: HorizontalTableRowCellProps) {
  const { cellPaddingX, valueCellHeight, maxValueCellWidth } = useHorizontalTableContext()

  return (
    <StyledCell
      borderLeft={borderLeft}
      className={clsx(
        horizontalTableClasses.cell,
        bold && horizontalTableClasses.cellBold,
        className
      )}
      height={valueCellHeight}
      maxWidth={maxValueCellWidth}
      paddingX={cellPaddingX}>
      <Typography
        component={typeof children === 'string' ? 'p' : 'div'}
        variant={'inherit'}
        noWrap>
        {children}
      </Typography>
    </StyledCell>
  )
}

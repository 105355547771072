import {
  BusinessModelIcon,
  CompanyIcon,
  KeyIcon,
  LeafIcon,
  MoneyBagIcon,
  ShuffleIcon,
  SlidersIcon,
  StarOutlinedIcon,
  TrendingUpIcon,
} from '@gain/components/icons'
import {
  AssetCustomerBaseType,
  AssetPricePositioningType,
  AssetSalesChannelType,
} from '@gain/rpc/shared-model'
import {
  assetBusinessActivities,
  formatAssetBusinessActivityLabel,
  formatAssetCustomerBaseLabel,
  formatAssetPricePositioningLabel,
  formatAssetSalesChannelLabel,
} from '@gain/utils/asset'
import { SECTORS, SUBSECTORS } from '@gain/utils/sector'

import { assetListItemTableColumns } from '../asset/asset-list-item-table/asset-list-item-table-columns'
import { OWNERSHIP_TYPES } from '../asset/asset-ownership'
import { TopLevelColumnGroup } from './column-picker-model'
import { fromConfig, getRegionAndCountrySearchAliases } from './column-picker-util'

export const columnPickerConfigAsset = new Array<TopLevelColumnGroup>(
  {
    name: 'Key facts',
    Icon: CompanyIcon,
    columns: [
      fromConfig(assetListItemTableColumns, 'name', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(assetListItemTableColumns, 'fte', { visibleByDefault: true }),
      fromConfig(assetListItemTableColumns, 'sector', {
        searchAliases: SECTORS.map((value) => value.title),
      }),
      fromConfig(assetListItemTableColumns, 'subsector', {
        searchAliases: SUBSECTORS.map((value) => value.title),
      }),
      fromConfig(assetListItemTableColumns, 'tagIds'), // Tags
      fromConfig(assetListItemTableColumns, 'region', {
        visibleByDefault: true,
        searchAliases: getRegionAndCountrySearchAliases(),
      }), // Company HQ
      fromConfig(assetListItemTableColumns, 'headquartersCity'), // HQ city
      fromConfig(assetListItemTableColumns, 'yearFounded'), // Year founded
      fromConfig(assetListItemTableColumns, 'ceoAge'), // CEO age
      fromConfig(assetListItemTableColumns, 'updatedAt'), // Updates
      fromConfig(assetListItemTableColumns, 'publishedAt'), // Published
      fromConfig(assetListItemTableColumns, 'financialsAt'), // Latest financials
      fromConfig(assetListItemTableColumns, 'url'), // Company website
    ],
  },
  {
    name: 'Financials',
    Icon: MoneyBagIcon,
    columns: [
      fromConfig(assetListItemTableColumns, 'revenueWithAiGeneratedEur', {
        visibleByDefault: true,
      }), // Revenue
      fromConfig(assetListItemTableColumns, 'grossMarginEur'), // Gross margin
      fromConfig(assetListItemTableColumns, 'grossMarginPctRevenue'), // Gross margin %
      fromConfig(assetListItemTableColumns, 'ebitdaEur', { visibleByDefault: true }), // EBITDA
      fromConfig(assetListItemTableColumns, 'ebitdaPctRevenue', { visibleByDefault: true }), // EBITDA %
      fromConfig(assetListItemTableColumns, 'ebitEur'), // EBIT
      fromConfig(assetListItemTableColumns, 'ebitPctRevenue'), // EBIT %
      fromConfig(assetListItemTableColumns, 'ebitdaMinusCapexEur'), // EBITDA - CAPEX
      fromConfig(assetListItemTableColumns, 'totalAssetsEur'), // Total assets
      fromConfig(assetListItemTableColumns, 'netDebtEur'), // Net debt
      fromConfig(assetListItemTableColumns, 'debtEur'), // Interest-bearing debt
      fromConfig(assetListItemTableColumns, 'cashEur'), // Cash & Cash equivalents
      fromConfig(assetListItemTableColumns, 'capitalEur'), // Trade working capital
      fromConfig(assetListItemTableColumns, 'inventoryEur'), // Inventory
      fromConfig(assetListItemTableColumns, 'receivablesEur'), // Receivables
      fromConfig(assetListItemTableColumns, 'payablesEur'), // Payables
      fromConfig(assetListItemTableColumns, 'marketCapitalizationEur'), // Market cap
      fromConfig(assetListItemTableColumns, 'enterpriseValueEur'), // Enterprise value
    ],
  },
  {
    name: 'Ratios',
    Icon: SlidersIcon,
    groups: [
      {
        name: 'Return ratios',
        columns: [
          fromConfig(assetListItemTableColumns, 'returnOnAssets'), // Return on assets
          fromConfig(assetListItemTableColumns, 'returnOnEquity'), // Return on equity
          fromConfig(assetListItemTableColumns, 'returnOnCapitalEmployed'), // Return on capital employed
        ],
      },
      {
        name: 'Valuation ratios',
        columns: [
          fromConfig(assetListItemTableColumns, 'enterpriseValueEbitdaRatio'), // Net debt / EBITDA
          fromConfig(assetListItemTableColumns, 'enterpriseValueEbitRatio'), // EV / EBIT
          fromConfig(assetListItemTableColumns, 'enterpriseValueRevenueRatio'), // EV / Sales
          fromConfig(assetListItemTableColumns, 'enterpriseValueEbitdaMinusCapexRatio'), // EV / (EBITDA - CAPEX)
          fromConfig(assetListItemTableColumns, 'enterpriseValueInvestedCapitalRatio'), // EV / Invested Capital
          fromConfig(assetListItemTableColumns, 'enterpriseValueFreeCashFlowRatio'), // EV / Free Cash Flow
        ],
      },
    ],
    columns: [
      fromConfig(assetListItemTableColumns, 'revenueFteRatioEur'), // Revenue / FTE
      fromConfig(assetListItemTableColumns, 'netDebtEbitdaRatio'), // Net debt / EBITDA
    ],
  },
  {
    name: 'Growth',
    Icon: TrendingUpIcon,
    groups: [
      {
        name: 'FTE Growth',
        columns: [
          fromConfig(assetListItemTableColumns, 'fteGrowthPctThreeMonths'), // 3M FTE growth
          fromConfig(assetListItemTableColumns, 'fteGrowthPctSixMonths'), // 6M FTE growth
          fromConfig(assetListItemTableColumns, 'fteGrowthPctOneYear'), // 1Y FTE growth
          fromConfig(assetListItemTableColumns, 'fteCagrPctTwoYears'), // 2Y FTE CAGR
          fromConfig(assetListItemTableColumns, 'fteCagrPctThreeYears'), // 3Y FTE CAGR
        ],
      },
      {
        name: 'Revenue growth',
        columns: [
          fromConfig(assetListItemTableColumns, 'revenueGrowthPctOneYear'), // 1Y revenue growth
          fromConfig(assetListItemTableColumns, 'revenueCagrPctTwoYears'), // 2Y revenue CAGR
          fromConfig(assetListItemTableColumns, 'revenueCagrPctThreeYears'), // 3Y revenue CAGR
        ],
      },
      {
        name: 'Gross margin growth',
        columns: [
          fromConfig(assetListItemTableColumns, 'grossMarginGrowthPctOneYear'), // 1Y gross margin growth
          fromConfig(assetListItemTableColumns, 'grossMarginCagrPctTwoYears'), // 2Y gross margin CAGR
          fromConfig(assetListItemTableColumns, 'grossMarginCagrPctThreeYears'), // 3Y gross margin CAGR
        ],
      },
      {
        name: 'EBITDA growth',
        columns: [
          fromConfig(assetListItemTableColumns, 'ebitdaGrowthPctOneYear'), // 1Y EBITDA growth
          fromConfig(assetListItemTableColumns, 'ebitdaCagrPctTwoYears'), // 2Y EBITDA CAGR
          fromConfig(assetListItemTableColumns, 'ebitdaCagrPctThreeYears'), // 3Y EBITDA CAGR
        ],
      },
      {
        name: 'EBIT growth',
        columns: [
          fromConfig(assetListItemTableColumns, 'ebitGrowthPctOneYear'), // 1Y EBIT growth
          fromConfig(assetListItemTableColumns, 'ebitCagrPctTwoYears'), // 2Y EBIT CAGR
          fromConfig(assetListItemTableColumns, 'ebitCagrPctThreeYears'), // 3Y EBIT CAGR
        ],
      },
    ],
  },
  {
    name: 'Business model',
    Icon: BusinessModelIcon,
    columns: [
      fromConfig(assetListItemTableColumns, 'customerBase', {
        searchAliases: Object.values(AssetCustomerBaseType).map(formatAssetCustomerBaseLabel),
      }), // Customer base
      fromConfig(assetListItemTableColumns, 'businessActivity', {
        searchAliases: assetBusinessActivities.map((value) =>
          formatAssetBusinessActivityLabel(value)
        ),
      }), // Business activity
      fromConfig(assetListItemTableColumns, 'salesChannel', {
        searchAliases: Object.values(AssetSalesChannelType).map((value) =>
          formatAssetSalesChannelLabel(value)
        ),
      }), // Sales channel
      fromConfig(assetListItemTableColumns, 'pricePositioning', {
        searchAliases: Object.values(AssetPricePositioningType).map((value) =>
          formatAssetPricePositioningLabel(value)
        ),
      }), // Price positioning
    ],
  },
  {
    name: 'Ownership',
    Icon: KeyIcon,
    columns: [
      fromConfig(assetListItemTableColumns, 'ownership', {
        searchAliases: Object.values(OWNERSHIP_TYPES).map((value) => value.title),
      }), // Ownership type
      fromConfig(assetListItemTableColumns, 'majorityOwnerName', { visibleByDefault: true }), // Majority Investor
      fromConfig(assetListItemTableColumns, 'ownerNames'), // Minority investor
    ],
  },
  {
    name: 'Deals & Funding',
    Icon: ShuffleIcon,
    groups: [
      {
        name: 'Previous deals & funding',
        columns: [
          fromConfig(assetListItemTableColumns, 'lastDealYear', { visibleByDefault: true }), // Last platform deals
          fromConfig(assetListItemTableColumns, 'addOnDealCountL5Y'), // Add-ons (L5Y)
          fromConfig(assetListItemTableColumns, 'totalDealFundingRaisedEur'), // Total funding
          fromConfig(assetListItemTableColumns, 'latestDealRoundType'), // Latest round
          fromConfig(assetListItemTableColumns, 'latestDealRoundSizeEur'), // Latest round size
          fromConfig(assetListItemTableColumns, 'latestDealPreMoneyValuationEur'), // Latest pre-money valuation
          fromConfig(assetListItemTableColumns, 'latestDealPostMoneyValuationEur'), // Latest post-money valuation
        ],
      },
      {
        name: 'Predicted deals & funding',
        columns: [
          fromConfig(assetListItemTableColumns, 'predictedExitYear'), // Predicted exit year
          fromConfig(assetListItemTableColumns, 'predictedExitEbitdaEur'), // Predicted EBITDA
          fromConfig(assetListItemTableColumns, 'predictedExitMultiple'), // Predicted multiple
          fromConfig(assetListItemTableColumns, 'predictedExitEvEur'), // Predicted EV
        ],
      },
    ],
  },
  {
    name: 'Assessment',
    Icon: StarOutlinedIcon,
    columns: [
      fromConfig(assetListItemTableColumns, 'ratingGrowth'), // Revenue growth
      fromConfig(assetListItemTableColumns, 'ratingOrganicGrowth'), // Organic growth
      fromConfig(assetListItemTableColumns, 'ratingGrossMargin'), // Gross margin
      fromConfig(assetListItemTableColumns, 'ratingEbitda'), // EBITDA margin
      fromConfig(assetListItemTableColumns, 'ratingConversion'), // Cash conversion
      fromConfig(assetListItemTableColumns, 'ratingNonCyclical'), // Cyclicality
      fromConfig(assetListItemTableColumns, 'ratingContracted'), // Revenue visibility
      fromConfig(assetListItemTableColumns, 'ratingLeader'), // Market position
      fromConfig(assetListItemTableColumns, 'ratingMultinational'), // Multinational
      fromConfig(assetListItemTableColumns, 'ratingBuyAndBuild'), // Multinational
    ],
  },
  {
    name: 'ESG',
    Icon: LeafIcon,
    columns: [
      fromConfig(assetListItemTableColumns, 'esgOutperformer'), // ESG outperformer
      fromConfig(assetListItemTableColumns, 'latestIndustryRatingOverall'), // ESG overall
      fromConfig(assetListItemTableColumns, 'latestIndustryRatingEnvironmental'), // Environmental
      fromConfig(assetListItemTableColumns, 'latestIndustryRatingSocial'), // Social
    ],
  }
)

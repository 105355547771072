import { ArticleListItem } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import { useRef } from 'react'

import Card, { CardContentEmpty, CardHeader, CardProps } from '../../../../common/card/card'
import ListAssetNews from '../../../../features/asset/asset-news-list'
import TableAssetNews from '../../../../features/asset/asset-news-table'

interface AssetNewsCardProps extends CardProps {
  articles: ArticleListItem[]
}

export default function AssetNewsCard({ articles, ...rest }: AssetNewsCardProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  return (
    <Card
      {...rest}
      ref={ref}>
      <CardHeader title={'News'} />
      {width >= 544 && <TableAssetNews articles={articles} />}
      {width < 544 && <ListAssetNews articles={articles} />}
      {articles.length === 0 && <CardContentEmpty message={'No news articles available'} />}
    </Card>
  )
}

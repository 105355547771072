import { Components, Theme } from '@mui/material/styles'
import { tabClasses } from '@mui/material/Tab'
import { tabsClasses } from '@mui/material/Tabs'
import { tabScrollButtonClasses } from '@mui/material/TabScrollButton'

import TabScrollButton from './mui-tab-scroll-button'

export const MuiTabs = (theme: Theme): Components['MuiTabs'] => ({
  defaultProps: {
    indicatorColor: 'primary',
    textColor: 'primary',
    ScrollButtonComponent: TabScrollButton,
  },
  styleOverrides: {
    root: {
      minHeight: 40,
      position: 'relative',
      [`& .${tabScrollButtonClasses.root}`]: {
        position: 'absolute',
        opacity: 1,
        top: 0,
        bottom: 0,
        color: theme.palette.background.default,
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('opacity'),
        '&:first-of-type': {
          left: 0,
          zIndex: 2,
          '&::before': {
            display: 'block',
            position: 'absolute',
            zIndex: 100,
            left: '100%',
            top: 0,
            bottom: 0,
            content: '""',
            background: 'linear-gradient(to left, transparent 0%, currentColor 100%)',
            width: 8,
          },
        },
        '&:last-of-type': {
          right: 0,
          zIndex: 1,
          '&::after': {
            display: 'block',
            position: 'absolute',
            right: '100%',
            top: 0,
            bottom: 0,
            content: '""',
            color: 'inherit',
            background: 'linear-gradient(to right, transparent 0%, currentColor 100%)',
            width: 8,
          },
        },
        [`&.${tabsClasses.scrollButtonsHideMobile}`]: {
          [theme.breakpoints.only('xs')]: {
            width: 0,
            display: 'block',
            '& svg': {
              display: 'none',
            },
          },
        },
      },
      [`&:not(.${tabsClasses.vertical}) .${tabClasses.root}`]: {
        '&:first-of-type': {
          marginLeft: 0,
        },
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    scrollButtons: {
      opacity: 0.3,
    },
  },
})

import { DealFundingRoundType } from '@gain/rpc/shared-model'
import { isDefined, valueToEnum } from '@gain/utils/common'

export interface FormatDealFundingSeriesLabelOptions {
  withUnknown?: boolean
  emptyValue?: string
}

export function formatDealFundingSeriesLabel(
  fundingRoundType: DealFundingRoundType | null | string,
  { withUnknown = true, emptyValue = '-' }: FormatDealFundingSeriesLabelOptions = {}
) {
  if (!isDefined(fundingRoundType)) {
    return emptyValue
  }

  const e = valueToEnum(DealFundingRoundType, fundingRoundType)

  if (!isDefined(e)) {
    return emptyValue
  }

  switch (e) {
    case DealFundingRoundType.SeriesA:
      return 'Series A'
    case DealFundingRoundType.SeriesB:
      return 'Series B'
    case DealFundingRoundType.SeriesC:
      return 'Series C'
    case DealFundingRoundType.SeriesD:
      return 'Series D'
    case DealFundingRoundType.SeriesE:
      return 'Series E'
    case DealFundingRoundType.SeriesF:
      return 'Series F'
    case DealFundingRoundType.SeriesG:
      return 'Series G'
    case DealFundingRoundType.SeriesH:
      return 'Series H'
    case DealFundingRoundType.SeriesI:
      return 'Series I'
    case DealFundingRoundType.SeriesJ:
      return 'Series J'
    case DealFundingRoundType.Venture:
      return 'Venture'
    case DealFundingRoundType.PreSeed:
      return 'Pre-Seed/Seed'
    case DealFundingRoundType.OtherUnknown:
      return withUnknown ? 'Other/Unknown' : 'VC-round'
  }
}

import { Deal } from '@gain/rpc/app-model'

type DealMetrics = Pick<
  Deal,
  | 'fundingRoundAmountRaised'
  | 'fundingRoundPreMoneyValuation'
  | 'fundingRoundPostMoneyValuation'
  | 'ev'
  | 'equity'
  | 'evRevenueMultiple'
  | 'evEbitdaMultiple'
  | 'evEbitMultiple'
  | 'revenue'
  | 'ebitda'
  | 'ebit'
  | 'totalAssets'
  | 'fte'
>
export type DealMetricKey = keyof DealMetrics

type DealMetricFormat = 'millions' | 'number' | 'multiple'

export interface DealMetricConfig {
  label: string
  shortLabel?: string
  format: DealMetricFormat
}

type DealMetricConfigMap = {
  [Key in keyof DealMetrics]-?: DealMetricConfig
}

export const dealMetricConfig: DealMetricConfigMap = {
  fundingRoundAmountRaised: {
    label: 'Money raised',
    shortLabel: 'Raised',
    format: 'millions',
  },
  fundingRoundPreMoneyValuation: {
    label: 'Pre-money valuation',
    format: 'millions',
  },
  fundingRoundPostMoneyValuation: {
    label: 'Post-money valuation',
    shortLabel: 'Valuation',
    format: 'millions',
  },
  ev: {
    label: 'EV',
    format: 'millions',
  },
  equity: {
    label: 'Equity-value',
    shortLabel: 'Equity',
    format: 'millions',
  },
  evRevenueMultiple: {
    label: 'Revenue multiple',
    shortLabel: 'Revenue',
    format: 'multiple',
  },
  evEbitdaMultiple: {
    label: 'EBITDA-multiple',
    shortLabel: 'EBITDA',
    format: 'multiple',
  },
  evEbitMultiple: {
    label: 'EBIT multiple',
    shortLabel: 'EBIT',
    format: 'multiple',
  },
  revenue: {
    label: 'Revenue',
    format: 'millions',
  },
  ebitda: {
    label: 'EBITDA',
    format: 'millions',
  },
  ebit: {
    label: 'EBIT',
    format: 'millions',
  },
  totalAssets: {
    label: 'Total assets',
    format: 'millions',
  },
  fte: {
    label: 'FTEs',
    format: 'number',
  },
}

import Typography from '@gain/components/typography'
import { AssetSegmentation } from '@gain/rpc/app-model'
import { styled, SxProps, Theme } from '@mui/material/styles'

const StyledUl = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  margin: 0,
  padding: 0,
}))

const StyledLi = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.25),
}))

export interface AssetSegmentationListProps {
  segmentation: AssetSegmentation
  sx?: SxProps<Theme>
}

export default function AssetSegmentationList({ segmentation, sx }: AssetSegmentationListProps) {
  return (
    <StyledUl sx={sx}>
      {segmentation.segments.map((segment) => (
        <StyledLi key={segment.id}>
          <Typography
            color={'text.primary'}
            variant={'subtitle2'}>
            {segment.title}
          </Typography>
          {segment.subtitle && (
            <Typography
              color={'text.secondary'}
              variant={'body2'}>
              {segment.subtitle}
            </Typography>
          )}
          <Typography
            color={'text.primary'}
            variant={'body2'}>
            {segment.description}
          </Typography>
        </StyledLi>
      ))}
    </StyledUl>
  )
}

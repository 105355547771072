import { ListItemKey } from '@gain/rpc/list-model'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useController, useWatch } from 'react-hook-form'

import { FilterConfigAutocomplete } from '../../filter-config/filter-config-model'
import { AutocompleteFormSchemaType, AutocompleteIncludeMode } from './filter-autocomplete'
import FilterAutocompleteMatchModeSelect from './filter-autocomplete-match-mode-select'
import { formatAutocompleteOptionLabel } from './filter-autocomplete-utils'

const StyledRoot = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  maxWidth: '100%',
  '&:not(:last-child)': {
    marginRight: theme.spacing(0.5),
  },
}))

interface FilterAutocompleteOptionsProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  label: string
  name: AutocompleteIncludeMode
  filter: FilterConfigAutocomplete<Item, FilterField>
}

export default function FilterAutocompleteOptions<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ name, label, filter }: FilterAutocompleteOptionsProps<Item, FilterField>) {
  const controller = useController<AutocompleteFormSchemaType, `${typeof name}.value`>({
    name: `${name}.value`,
  })

  const watch = useWatch<AutocompleteFormSchemaType, typeof name>({
    name,
  })

  if (watch.value.length === 0) {
    return null
  }

  return (
    <StyledRoot>
      <div>
        <FilterAutocompleteMatchModeSelect
          label={label}
          name={name}
          readonly={filter.disableToggleMatchMode}
        />
      </div>
      <div>
        {watch.value.map((value, index) => (
          <StyledChip
            key={index}
            clickable={false}
            color={'info'}
            label={formatAutocompleteOptionLabel(value)}
            onDelete={() =>
              controller.field.onChange(
                watch.value.filter((option) => option.value !== value.value)
              )
            }
            size={'medium'}
          />
        ))}
      </div>
    </StyledRoot>
  )
}

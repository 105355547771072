import { Components } from '@mui/material/styles'

export const MuiCssBaseline = (): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    html: {
      WebkitFontSmoothing: 'auto',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: 'normal',

      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
    'h1,h2,h3,h4,h5,p': {
      margin: 0,
    },
    a: {
      WebkitTapHighlightColor: 'transparent',
    },
    '.beamer_icon': {
      display: 'none !important',
    },
  },
})

import { InvestorProfileStrategy } from '@gain/rpc/app-model'

export function sortStrategies(
  strategyA: InvestorProfileStrategy,
  strategyB: InvestorProfileStrategy
) {
  // If one of the strategies does not have any linked assets, push it back
  if (strategyA.assetCount !== 0 && strategyB.assetCount === 0) {
    return -1
  } else if (strategyB.assetCount !== 0 && strategyA.assetCount === 0) {
    return 1
  }

  // If one of the strategies does not have a fund size, push it back
  if (!strategyA.latestFundSizeEur) {
    return 1
  } else if (!strategyB.latestFundSizeEur) {
    return -1
  }

  // All above is not true, check which strategy has the biggest
  return strategyB.latestFundSizeEur > strategyA.latestFundSizeEur ? 1 : -1
}

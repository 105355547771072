import { CompanyIcon } from '@gain/components/icons'
import OverflowContainer from '@gain/components/overflow-container'
import { ArticleListItem } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

import { Link } from '../../../common/link'
import Logo from '../../../common/logo'
import Spacer from '../../../common/spacer'
import AssetListItemChip from '../../../features/asset/asset-list-item-chip/asset-list-item-chip'
import { InvestorListItemChip } from '../../../features/investor/investor-list-item-chip/investor-list-item-chip'
import { generateArticlePagePath } from '../../utils'
import { ArticleCategoryLabel } from './article-category-label'
import { DealChip } from './deal-chip'
import { useArticleInvestors } from './news-feed-item-article-hooks'

export const StyledNewsFeedItemArticleLink = styled(Link)(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3, 0),
  animation: `$enter ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
}))

export const StyledNewsFeedItemArticleAssetLogo = styled(Logo)(({ theme }) => ({
  marginRight: theme.spacing(3),
}))

export const StyledNewsFeedItemArticleTextContainer = styled('div')({
  flex: 1,
  minWidth: 0,
})

export interface NewsFeedItemArticleProps {
  article: ArticleListItem
}

export function NewsFeedItemArticle({ article }: NewsFeedItemArticleProps) {
  const investors = useArticleInvestors(article)

  const chips = useMemo(
    () => [
      article.linkedDealId && article.category && (
        <DealChip
          key={`deal-${article.linkedDealId}`}
          dealId={article.linkedDealId}
          dealName={ArticleCategoryLabel[article.category]}
        />
      ),
      ...article.linkedAssetIds.map((assetId, index) => (
        <AssetListItemChip
          key={`asset-${assetId}`}
          id={assetId}
          name={article.linkedAssetNames[index]}
          TooltipProps={{
            onClick: (event) => event.stopPropagation(),
          }}
        />
      )),
      ...investors.map((investor) => (
        <InvestorListItemChip
          key={`investor-${investor.id}`}
          id={investor.id}
          label={investor.name}
        />
      )),
    ],
    [article, investors]
  )

  return (
    <StyledNewsFeedItemArticleLink
      to={generateArticlePagePath({
        articleId: article.id,
        articleTitle: article.title || undefined,
      })}
      disableStyle>
      {article.linkedAssetIds.length > 0 && (
        <StyledNewsFeedItemArticleAssetLogo
          defaultIcon={<CompanyIcon />}
          region={article.linkedAssetRegions[0]}
          size={56}
          src={article.linkedAssetLogoFileUrls[0]}
        />
      )}
      <StyledNewsFeedItemArticleTextContainer>
        <Typography
          color={'textPrimary'}
          variant={'h5'}>
          {article.title}
        </Typography>
        {article.body && (
          <Typography
            color={'text.secondary'}
            sx={{ mt: 0.25 }}
            variant={'body2'}
            noWrap>
            {article.body}
          </Typography>
        )}
        <Spacer
          direction={'vertical'}
          xs={1.5}
        />
        <OverflowContainer
          chipSize={'medium'}
          lineHeight={24}
          maxLines={1}
          showAllOnClick>
          {chips}
        </OverflowContainer>
      </StyledNewsFeedItemArticleTextContainer>
    </StyledNewsFeedItemArticleLink>
  )
}

import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { GeneratePathParams, Params } from './path'

export const LEGAL_ENTITY_PAGE_PATH = '/entity/:id/:name?'

export interface GenerateLegalEntityPageParams extends GeneratePathParams {
  id: number | string
  name?: string
}

export function generateLegalEntityPagePath(params: GenerateLegalEntityPageParams) {
  return generatePath(LEGAL_ENTITY_PAGE_PATH, params)
}

export interface LegalEntityPageParams extends Params {
  id: string
  name?: string
}

export function useLegalEntityPageParams() {
  const params = useParams<LegalEntityPageParams>()
  return useMemo(
    () => ({
      ...params,
      id: parseInt(params.id, 10),
    }),
    [params]
  )
}

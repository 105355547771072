import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { useContext } from 'react'

import { FilterModel } from '../../filter/filter-bar'
import { ListViewContext } from './list-view-context'
import { ListViewApi, ListViewMethod } from './use-list-view-api'

export function useListView<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
>() {
  return useContext(ListViewContext) as {
    filterModel: FilterModel<FilterItem, FilterField> | null
    api: ListViewApi<Method, Item, FilterItem, FilterField>
    refreshGrid: () => Promise<void>
  }
}

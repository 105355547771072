import { AnnualReportType } from './annual-report-type'

export function formatAnnualReportType(type: string | null) {
  const lowerCaseType = type != null ? type.toLowerCase() : type
  switch (lowerCaseType) {
    case null:
    case '':
    case AnnualReportType.JAHRESABSCHLUSS:
      return 'Annual report'
    case AnnualReportType.KONZERNABSCHLUSS:
    case AnnualReportType.JAHRESABSCHLUSS_KONZERNABSCHLUSS:
      return 'Annual report - Consolidated'
    default:
      return type
  }
}

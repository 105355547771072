import Typography from '@gain/components/typography'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

const StyledRoot = styled('div')<{ variant: 'default' | 'tall' }>(({ theme, variant }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1),
  ...(variant === 'tall' && {
    minHeight: 180,
    paddingBottom: theme.spacing(2), // Offset from bottom to make the text appear in the middle
  }),
}))

interface CardContentEmptyProps {
  message: string
  variant?: 'default' | 'tall'
  children?: ReactNode
}

export default function CardContentEmpty({
  message,
  children,
  variant = 'default',
}: CardContentEmptyProps) {
  return (
    <StyledRoot variant={variant}>
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        {message}
      </Typography>
      {children}
    </StyledRoot>
  )
}

import { ArrowRightIcon, ArrowUpIcon } from '@gain/components/icons'
import Button, { buttonClasses } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import React, { useCallback, useRef, useState } from 'react'

import DropdownMenu, { DropdownMenuOptions } from '../../../../common/dropdown-menu'
import MenuExpandIcon from '../../../../common/menu-expand-icon'
import { Axis, AxisConfig } from './axis-config'

const StyledRoot = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: Axis }>(({ theme, variant: axis }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.text.secondary,

  ...(axis === 'x' && {
    justifyContent: 'end',
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
  }),

  [`& .${svgIconClasses.root}`]: {
    fontSize: 16,
  },
}))

const StyledMenuButton = styled(Button)(({ theme }) => ({
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },

  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
  },
}))

interface AxisDropdownMenuProps {
  activeAxis: AxisConfig
  axisConfig: DropdownMenuOptions<AxisConfig>
  axis: Axis
  onSelect: (axis: AxisConfig) => void
}

export default function AxisDropdownMenu({
  activeAxis,
  axis,
  onSelect,
  axisConfig,
}: AxisDropdownMenuProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleSelectAxis = useCallback(
    (selectedAxis: AxisConfig) => {
      onSelect(selectedAxis)
      setIsOpen(false)
    },
    [onSelect]
  )

  return (
    <StyledRoot variant={axis}>
      {axis === Axis.Y ? <ArrowUpIcon /> : <ArrowRightIcon />}

      <StyledMenuButton
        ref={buttonRef}
        color={'primary'}
        endIcon={<MenuExpandIcon open={isOpen} />}
        onClick={() => setIsOpen(true)}
        size={'small'}
        variant={'text'}>
        {activeAxis.label}
      </StyledMenuButton>

      <DropdownMenu
        anchorEl={buttonRef.current}
        gaCategory={'Chart select axis'}
        onClose={() => setIsOpen(false)}
        onSelect={handleSelectAxis}
        open={isOpen}
        options={axisConfig}
        searchPlaceholder={`Search metric ${axis.toUpperCase()}-axis`}
      />
    </StyledRoot>
  )
}

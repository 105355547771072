import { ListItemKey } from '@gain/rpc/list-model'
import { styled } from '@mui/material/styles'
import Tab, { tabClasses } from '@mui/material/Tab'
import Tabs, { tabsClasses, TabsProps } from '@mui/material/Tabs'
import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'

import { FilterConfig } from '../filter-config/filter-config-model'
import { filterPath } from '../filter-utils'
import Filter from './filter'

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  minHeight: 28,
  width: 330,
  marginBottom: theme.spacing(1.5),

  [`& .${tabsClasses.flexContainer}`]: {
    minHeight: '100%',
    display: 'grid',
    gridAutoColumns: 'minmax(0, 1fr)',
    gridAutoFlow: 'column',
    backgroundColor: theme.palette.grey['100'],
    borderRadius: theme.shape.borderRadius * 2,

    [`& .${tabClasses.root}`]: {
      minHeight: '100%',
      margin: 0,
    },

    [`& .${tabClasses.selected}`]: {
      color: theme.palette.text.primary,
    },
  },

  [`& .${tabsClasses.indicator}`]: {
    minHeight: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1.5px solid ${theme.palette.grey['100']}`,
  },
}))

export interface FilterTabsProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  groupIndex: number
  itemIndex: number
  filter: FilterConfig<Item, FilterField>
  onClear: () => void
}

export default function FilterTabs<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ groupIndex, itemIndex, filter, onClear }: FilterTabsProps<Item, FilterField>) {
  const form = useForm()

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, filterId: unknown) => {
      if (!filter.tabs?.keepValueBetweenTabs) {
        onClear()
      }

      form.change(filterPath(groupIndex, itemIndex, 'filterId'), filterId)
    },
    [filter.tabs?.keepValueBetweenTabs, onClear, form, groupIndex, itemIndex]
  )

  return (
    <>
      {filter.tabs && (
        <StyledTabs
          onChange={handleTabChange}
          value={filter.id}>
          {filter.tabs.options.map(({ id, label }) => (
            <Tab
              key={id}
              label={label}
              value={id}
            />
          ))}
        </StyledTabs>
      )}

      <Filter
        filter={{
          ...filter,
          tabs: undefined,
        }}
        groupIndex={groupIndex}
        itemIndex={itemIndex}
        onClear={onClear}
      />
    </>
  )
}

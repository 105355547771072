import { UserSessionListItem } from '@gain/rpc/app-model'

import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  rangeDate,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const USER_SESSION_FILTER_FIELDS = ['sessionStartedAt'] as const

export type UserSessionFilterField = (typeof USER_SESSION_FILTER_FIELDS)[number]

export const USER_SESSION_FILTER_MAP = createFilterMap<
  UserSessionListItem,
  typeof USER_SESSION_FILTER_FIELDS
>(rangeDate('sessionStartedAt', 'Start session'))

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const USER_SESSION_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<UserSessionListItem, UserSessionFilterField>
> = [USER_SESSION_FILTER_MAP.sessionStartedAt]

export const USER_SESSION_DEFAULT_FILTER: FilterModel<UserSessionListItem, UserSessionFilterField> =
  [filterValueGroup(filterValueItem('sessionStartedAt'))]

import { CreditReferenceRate, CreditSubtype, CreditType } from './lib/rpc-shared-model'
import { Option } from './option'

export const CREDIT_TYPE_OPTIONS: ReadonlyArray<Option<CreditType>> = [
  {
    label: 'Senior debt',
    value: CreditType.SeniorDebt,
  },
  {
    label: 'Subordinated debt',
    value: CreditType.SubordinatedDebt,
  },
  {
    label: 'Revolving credit',
    value: CreditType.RevolvingCredit,
  },
  {
    label: 'Hybrid financing',
    value: CreditType.HybridFinancing,
  },
  {
    label: 'Purpose-specific financing',
    value: CreditType.PurposeSpecificFinancing,
  },
  {
    label: 'Sustainability-linked financing',
    value: CreditType.SustainabilityLinkedFinancing,
  },
  {
    label: 'Corporate bond',
    value: CreditType.CorporateBond,
  },
  {
    label: 'Equity',
    value: CreditType.Equity,
  },
]

export const CREDIT_SUBTYPE_OPTIONS: ReadonlyArray<Option<CreditSubtype>> = [
  // Senior debt
  {
    label: 'First-lien',
    value: CreditSubtype.FirstLien,
  },
  {
    label: 'Second-lien',
    value: CreditSubtype.SecondLien,
  },
  {
    label: 'TLA',
    value: CreditSubtype.Tla,
  },
  {
    label: 'TLB',
    value: CreditSubtype.Tlb,
  },

  // Subordinated debt
  {
    label: 'Subordinated loan',
    value: CreditSubtype.SubordinatedLoan,
  },
  {
    label: 'Mezzanine',
    value: CreditSubtype.Mezzanine,
  },
  {
    label: 'Payment-in-kind',
    value: CreditSubtype.PaymentInKind,
  },

  // Hybrid financing
  {
    label: 'Unitranche debt',
    value: CreditSubtype.UnitrancheDebt,
  },
  {
    label: 'Convertible debt',
    value: CreditSubtype.ConvertibleDebt,
  },
  {
    label: 'Structured debt',
    value: CreditSubtype.StructuredDebt,
  },

  // Purpose-specific financing
  {
    label: 'Bridge loan',
    value: CreditSubtype.BridgeLoan,
  },
  {
    label: 'Debenture',
    value: CreditSubtype.Debenture,
  },

  // Equity
  {
    label: 'Common stock',
    value: CreditSubtype.CommonStock,
  },
  {
    label: 'Preferred stock',
    value: CreditSubtype.PreferredStock,
  },
  {
    label: 'Warrants',
    value: CreditSubtype.Warrants,
  },
]

export const CREDIT_SUB_TYPE_LOOKUP: Record<CreditType, CreditSubtype[]> = {
  [CreditType.SeniorDebt]: [
    CreditSubtype.FirstLien,
    CreditSubtype.SecondLien,
    CreditSubtype.Tla,
    CreditSubtype.Tlb,
  ],
  [CreditType.SubordinatedDebt]: [
    CreditSubtype.SubordinatedLoan,
    CreditSubtype.Mezzanine,
    CreditSubtype.PaymentInKind,
  ],
  [CreditType.RevolvingCredit]: [],
  [CreditType.HybridFinancing]: [
    CreditSubtype.UnitrancheDebt,
    CreditSubtype.ConvertibleDebt,
    CreditSubtype.StructuredDebt,
  ],
  [CreditType.PurposeSpecificFinancing]: [CreditSubtype.BridgeLoan, CreditSubtype.Debenture],
  [CreditType.SustainabilityLinkedFinancing]: [],
  [CreditType.CorporateBond]: [],
  [CreditType.Equity]: [
    CreditSubtype.CommonStock,
    CreditSubtype.PreferredStock,
    CreditSubtype.Warrants,
  ],
}

export const CREDIT_REFERENCE_RATE_OPTIONS: ReadonlyArray<Option<CreditReferenceRate>> = [
  {
    label: 'SONIA',
    value: CreditReferenceRate.Sonia,
  },
  {
    label: 'SOFR',
    value: CreditReferenceRate.Sofr,
  },
]

import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface EnhancedScrollTriggerOptions {
  offset?: number
  disableHysteresis?: boolean
  threshold?: number
}

/*
 * Hook to invoke a callback with new client height on window resize, debouncing the event to avoid rapid calls
 */
function useResizeHandlerClientHeight(callback: (newClientHeight: number) => void) {
  const debounced = useDebouncedCallback(() => callback(document.documentElement.clientHeight), 300)

  useEffect(() => {
    window.addEventListener('resize', debounced)
    return () => window.removeEventListener('resize', debounced)
  }, [callback, debounced])
}

/*
 * Hook to invoke a callback with new scroll height on dom changes, debouncing the event to avoid rapid calls
 */
function useScrollHeightObserver(callback: (newScrollHeight: number) => void) {
  const debounced = useDebouncedCallback((targetNode) => callback(targetNode.scrollHeight), 300)

  useEffect(() => {
    const targetNode = document.body
    const observerOptions = {
      childList: true,
      subtree: true,
    }

    const observer = new MutationObserver(() => debounced(targetNode))
    observer.observe(targetNode, observerOptions)
    return () => observer.disconnect()
  }, [callback, debounced])
}

/*
 * useEnhancedScrollTrigger - A custom hook that enhances MUI's useScrollTrigger
 * by adding a check for content length relative to the viewport size, preventing
 * triggering when the content isn't scrollable enough
 */
export function useEnhancedScrollTrigger({
  offset = 0,
  ...scrollTriggerOptions
}: EnhancedScrollTriggerOptions): boolean {
  const scrollTrigger = useScrollTrigger(scrollTriggerOptions)
  const [clientHeight, setClientHeight] = useState(document.documentElement.clientHeight)
  const [scrollHeight, setScrollHeight] = useState(document.documentElement.scrollHeight)
  const [shouldUseScrollTrigger, setShouldUseScrollTrigger] = useState(false)

  useResizeHandlerClientHeight(setClientHeight)
  useScrollHeightObserver(setScrollHeight)

  useEffect(
    () => setShouldUseScrollTrigger(scrollHeight > clientHeight + offset),
    [scrollHeight, clientHeight, offset]
  )

  return shouldUseScrollTrigger && scrollTrigger
}

import { ListItemKey } from '@gain/rpc/list-model'
import { useOnMountEffect } from '@gain/utils/react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'

import { FilterConfig } from '../filter-config/filter-config-model'
import { filterPath } from '../filter-utils'
import Filter from './filter'

const StyledContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(1, 0, -1, 0),
  // Applying a negative margin to counteract the bottom margin of the parent component. This ensures proper alignment and spacing within the layout. */
}))

export interface FilterSwitchProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  groupIndex: number
  itemIndex: number
  filter: FilterConfig<Item, FilterField>
  onClear: () => void
}

export default function FilterSwitch<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ groupIndex, itemIndex, filter, onClear }: FilterSwitchProps<Item, FilterField>) {
  const form = useForm()
  const [checked, setChecked] = React.useState(false)

  const handleChange: SwitchProps['onChange'] = useCallback(
    (_: React.SyntheticEvent, value: boolean) => {
      setChecked(value)

      const option = filter.switch?.options.find((item) => item.checked === value)
      if (option) {
        form.change(filterPath(groupIndex, itemIndex, 'filterId'), option.id)
      }
    },
    [filter, form, groupIndex, itemIndex]
  )

  useOnMountEffect(() => {
    const active = form.getState().values?.groups?.[groupIndex]?.value?.[itemIndex]?.filterId
    const option = filter.switch?.options.find((item) => item.id === active)
    if (option) {
      setChecked(!!option.checked)
    }
  })

  return (
    <>
      <Filter
        filter={{
          ...filter,
          switch: undefined,
        }}
        groupIndex={groupIndex}
        itemIndex={itemIndex}
        onClear={onClear}
      />

      <StyledContainer>
        {filter.switch?.explainer}
        {filter.switch && (
          <Switch
            checked={checked}
            color={'success'}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={handleChange}
            disableRipple
          />
        )}
      </StyledContainer>
    </>
  )
}

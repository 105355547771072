import { useRpcClient } from '@gain/api/swr'
import { UpdatePasswordParams, UserProfile } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { useCallback, useMemo } from 'react'

import { useUserProfile } from './app-api-hooks'

export type UpdateUserProfileParams = Partial<
  Pick<
    UserProfile,
    | 'firstName'
    | 'lastName'
    | 'recommendRegions'
    | 'recommendSubsectors'
    | 'emailSourcingUpdates'
    | 'emailResearchUpdates'
    | 'emailAssetUpdatesNotification'
  >
>

export function useUpdateUserProfileCallback() {
  const fetcher = useRpcClient()
  const { data: userProfile, mutate } = useUserProfile()
  return useCallback(
    async (partial: UpdateUserProfileParams) => {
      if (!userProfile) {
        return
      }

      await fetcher({
        method: 'account.updateUserProfile',
        params: {
          id: userProfile.id,
          partial,
        },
      })
      mutate({
        ...userProfile,
        ...partial,
      })
    },
    [fetcher, mutate, userProfile]
  )
}

export function useAccountProjectCode() {
  const userProfile = useUserProfile()
  const fetcher = useRpcClient()

  const update = useCallback(
    async (projectCode: string | null) => {
      if (!isDefined(userProfile.data)) {
        return
      }

      await fetcher({
        method: 'account.updateUserProfile',
        params: {
          id: userProfile.data.id,
          partial: {
            sessionTrackingProjectCode: projectCode,
          },
        },
      })

      await userProfile.mutate({
        ...userProfile.data,
        sessionTrackingProjectCode: projectCode,
      })
    },
    [userProfile, fetcher]
  )

  return useMemo(() => {
    const data = userProfile.data?.featureSessionTracking
      ? userProfile.data?.sessionTrackingProjectCode || null
      : null

    return {
      ...userProfile,
      data: data,
      update,
    }
  }, [userProfile, update])
}

export function useUpdatePassword() {
  const fetcher = useRpcClient()

  return useCallback(
    async (params: UpdatePasswordParams) => {
      return await fetcher({
        method: 'account.updatePassword',
        params: params,
      })
    },
    [fetcher]
  )
}

export function useGetUser() {
  const fetcher = useRpcClient()

  return useCallback(
    async (userId: number) => {
      return await fetcher({
        method: 'admin.getUser',
        params: {
          id: userId,
        },
      })
    },
    [fetcher]
  )
}

import { SearchIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import { styled } from '@mui/material/styles'

import SearchResultsRequestProfile from '../../../../features/search/search-results/request-profile/search-results-request-profile'

const StyledCenterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(12),
}))

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 40,
  height: 40,
  mb: 1,
}))

const StyledFade = styled(Fade)({
  flex: 1,
})

interface NoSearchResultsProps {
  query: string
}

export default function NoSearchResults({ query }: NoSearchResultsProps) {
  return (
    <StyledFade in>
      <StyledCenterContainer>
        <StyledSearchIcon strokeWidth={3} />

        <Typography
          align={'center'}
          color={'text.secondary'}
          variant={'body1'}>
          No results found for “{query}”
        </Typography>

        <Divider sx={{ my: 1, width: 'calc(100% - 48px)' }} />
        <SearchResultsRequestProfile />
      </StyledCenterContainer>
    </StyledFade>
  )
}

import * as Shared from '@gain/rpc/shared-model'
import {
  FinancialResult,
  FinancialResultAmountKeys,
  hasCapital,
  hasEbit,
  hasEbitda,
  hasFte,
  hasGrossMargin,
  hasNetDebt,
  hasRevenue,
  hasTotalAssets,
  isAmountEstimated,
} from '@gain/utils/financials'
import { formatNumber } from '@gain/utils/number'
import { useMemo } from 'react'

export type ValueFormatterFn = (value: number | null, estimate: boolean) => string

export interface FinancialRowYear {
  year: number
  value: number | null
  amountType: Shared.FinancialResultAmountType
  estimate: boolean
}

export interface FinancialRow {
  label: string
  years: FinancialRowYear[]
  valueFormatter: ValueFormatterFn
}

interface CreateFinancialRowParams {
  key: FinancialResultAmountKeys
  label: string
  financials: FinancialResult[]
  valueFormatter: ValueFormatterFn
}

function createFinancialRow({
  key,
  label,
  financials,
  valueFormatter,
}: CreateFinancialRowParams): FinancialRow {
  return {
    label,
    years: financials.map((financial) => ({
      year: financial.year,
      value: financial[key]?.amount || null,
      amountType: financial[key]?.amountType || Shared.FinancialResultAmountType.Actual,
      estimate: isAmountEstimated(financial[key]?.amountType),
    })),
    valueFormatter,
  }
}

interface RowConfig {
  label: string
  key: FinancialResultAmountKeys
  hasDataFn: (financials: FinancialResult[]) => boolean
  valueFormatter: ValueFormatterFn
}

function formatAmount(value: number | null | undefined, estimate: boolean) {
  return formatNumber(value, { round: estimate ? 0 : 1 })
}

function formatFte(value: number | null | undefined, estimate: boolean) {
  return formatNumber(value, {
    round: typeof value === 'number' && value < 10 && !estimate ? 1 : 0,
  })
}

const rowConfig: RowConfig[] = [
  {
    label: 'Revenue',
    key: 'revenue',
    hasDataFn: hasRevenue,
    valueFormatter: formatAmount,
  },
  {
    label: 'Gross margin',
    key: 'grossMargin',
    hasDataFn: hasGrossMargin,
    valueFormatter: formatAmount,
  },
  {
    label: 'EBITDA',
    key: 'ebitda',
    hasDataFn: hasEbitda,
    valueFormatter: formatAmount,
  },
  {
    label: 'EBIT',
    key: 'ebit',
    hasDataFn: hasEbit,
    valueFormatter: formatAmount,
  },
  {
    label: 'Total assets',
    key: 'totalAssets',
    hasDataFn: hasTotalAssets,
    valueFormatter: formatAmount,
  },
  {
    label: 'Net debt',
    key: 'netDebt',
    hasDataFn: hasNetDebt,
    valueFormatter: formatAmount,
  },
  {
    label: 'Trade working capital',
    key: 'capital',
    hasDataFn: hasCapital,
    valueFormatter: formatAmount,
  },
  {
    label: 'FTEs',
    key: 'fte',
    hasDataFn: hasFte,
    valueFormatter: formatFte,
  },
]

export default function useFinancialRows(financials: FinancialResult[]): FinancialRow[] {
  return useMemo(() => {
    return rowConfig
      .filter((config) => config.hasDataFn(financials))
      .map((config) =>
        createFinancialRow({
          label: config.label,
          key: config.key,
          valueFormatter: config.valueFormatter,
          financials,
        })
      )
      .slice(0, 5)
  }, [financials])
}

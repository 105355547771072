import { isDefined } from '@gain/utils/common'
import { CountryCode, formatCountry, isCountryCode, Region, REGIONS } from '@gain/utils/countries'
import { RowType } from '@gain/utils/table'

import { Subregion } from '../../../../../libs/utils/countries/src/regions'
import { ColumnConfig } from '../../common/virtual-table'
import { Column } from './column-picker-model'

type FromConfigOptions = Partial<
  Pick<Column, 'visibleByDefault' | 'canBeHiddenByUser' | 'searchAliases'>
>

/**
 * fromConfig is a utility function that helps create a Column definition
 * given a ColumnConfig
 */
export function fromConfig<T extends RowType>(
  config: ColumnConfig<T>[],
  field: keyof T,
  options: FromConfigOptions = {}
): Column {
  const item = config.find((i) => i.field === field)

  if (!item) {
    throw new Error(`Config with field '${field.toString()}' does not exist`)
  }

  if (!item.headerName) {
    throw new Error(`Config with field '${field.toString()}' does not have a headerName property`)
  }

  return {
    name: field.toString(),
    label: item.headerName,
    visibleByDefault: isDefined(options.visibleByDefault) ? options.visibleByDefault : false,
    canBeHiddenByUser: isDefined(options.canBeHiddenByUser) ? options.canBeHiddenByUser : true,
    searchAliases: options.searchAliases || [],
  }
}

export function getRegionAndCountrySearchAliases(
  items: ReadonlyArray<Region | Subregion | CountryCode> = REGIONS
): string[] {
  const result = new Array<string>()

  for (const item of items) {
    if (isCountryCode(item)) {
      result.push(formatCountry(item))
    } else if ('name' in item) {
      result.push(item.name)
      result.push(...getRegionAndCountrySearchAliases(item.options))
    }
  }

  return result
}

import { ArrowRightIcon, ArrowUpIcon, ScatterIcon } from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import { ChartSizeTypeConfig } from '../../../../common/chart/chart-select'
import {
  ChartTooltipDataCell,
  ChartTooltipDivider,
  ChartTooltipLabelCell,
  ChartTooltipTable,
} from '../../../../common/chart/chart-tooltip'
import { AxisConfig } from '../axis/axis-config'

const StyledIconCell = styled('td')({
  verticalAlign: 'bottom',
  height: '100%',
  paddingRight: 2,
  lineHeight: 1,
})

interface CompanyBubbleEChartTooltipTitleProps {
  asset: AssetListItem
  sizeConfig: ChartSizeTypeConfig<AssetListItem>
  xAxisConfig: AxisConfig
  yAxisConfig: AxisConfig
}

/**
 * CompanyBubbleEChartTooltipTitle renders the contents of the bubble tooltip.
 */
export default function CompanyBubbleEChartTooltipTitle({
  asset,
  sizeConfig,
  xAxisConfig,
  yAxisConfig,
}: CompanyBubbleEChartTooltipTitleProps) {
  return (
    <div>
      <Typography
        fontSize={12}
        fontWeight={'bold'}
        variant={'body2'}>
        {asset.name}
      </Typography>

      <ChartTooltipDivider />

      <ChartTooltipTable>
        <tbody>
          <tr>
            <StyledIconCell>
              <ArrowUpIcon sx={{ height: 14, width: 14 }} />
            </StyledIconCell>
            <ChartTooltipLabelCell>{yAxisConfig.label}</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{yAxisConfig.formatTooltip(asset)}</ChartTooltipDataCell>
          </tr>
          <tr>
            <StyledIconCell>
              <ArrowRightIcon sx={{ height: 14, width: 14 }} />
            </StyledIconCell>
            <ChartTooltipLabelCell>{xAxisConfig.label}</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{xAxisConfig.formatTooltip(asset)}</ChartTooltipDataCell>
          </tr>
          <tr>
            <StyledIconCell>
              <ScatterIcon sx={{ height: 14, width: 14 }} />
            </StyledIconCell>
            <ChartTooltipLabelCell>
              {sizeConfig.formatTooltipLabel?.(asset) ?? sizeConfig.label}
            </ChartTooltipLabelCell>
            <ChartTooltipDataCell>
              {sizeConfig.formatter(sizeConfig.getValue(asset), asset)}
            </ChartTooltipDataCell>
          </tr>
        </tbody>
      </ChartTooltipTable>
    </div>
  )
}

import { AdvisorDealListItem, DealListItem } from '@gain/rpc/app-model'
import { DealReason } from '@gain/rpc/shared-model'
import { intersection } from 'lodash'

import { compareDealListItemPartyByShareAndOrderDesc } from './compare-deal-list-item-party-by-share-and-order-desc'
import { DealListItemParty } from './deal-party.model'
import { partiesFromDealListItem } from './deal-party-utils'
import { getDealReasonLabel } from './deal-util'
import { formatDealFundingSeriesLabel } from './format-deal-funding-series-label'

function getLeadingOrBiggestShareParty(parties: DealListItemParty[]) {
  const sortedParties = [...parties].sort(compareDealListItemPartyByShareAndOrderDesc)

  const leadingParty = sortedParties.find((party) => party.leading)
  if (leadingParty) {
    return leadingParty
  }

  if (sortedParties.length > 0) {
    return sortedParties[0]
  }

  return null
}

function getLeadingOrBiggestSharePartyReason(
  buyers: DealListItemParty[],
  sellers: DealListItemParty[]
) {
  const leadingOrBiggestShareParty =
    getLeadingOrBiggestShareParty(buyers) || getLeadingOrBiggestShareParty(sellers)

  // If we have a party with a reason return that one
  if (leadingOrBiggestShareParty?.reason) {
    return leadingOrBiggestShareParty.reason
  }

  // Else we get the first party that has a reason
  return [...buyers, ...sellers]
    .sort(compareDealListItemPartyByShareAndOrderDesc)
    .find((party) => party.reason)?.reason
}

export function formatDealListItemTitle(
  deal: DealListItem | AdvisorDealListItem,
  dealTypeFilterValues: DealReason[] = []
) {
  const buyers = partiesFromDealListItem(deal, 'buyer')
  const sellers = partiesFromDealListItem(deal, 'seller')

  // If the user did specify a filter in deal type, make sure that type is shown
  const commonReasons = intersection(deal.reasons, dealTypeFilterValues)
  if (commonReasons.length > 0) {
    return getDealReasonLabel(commonReasons[0])
  }

  if (deal.fundingRoundType) {
    return formatDealFundingSeriesLabel(deal.fundingRoundType, { withUnknown: false })
  }

  const reason = getLeadingOrBiggestSharePartyReason(buyers, sellers)
  if (reason) {
    return getDealReasonLabel(reason)
  }

  return 'Deal'
}

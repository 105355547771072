import { BookmarkIcon, PlusIcon, UsersIcon, WatchlistIcon } from '@gain/components/icons'
import { CustomAssetList } from '@gain/rpc/app-model'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import React, { useCallback, useMemo, useState } from 'react'

import { MenuDivider } from '../../../common/menu-divider'
import { MAX_BOOKMARK_LIST_ITEMS } from '../bookmark-list-contants'
import BookmarksListCreateDialog from '../bookmarks-list-create/bookmarks-list-create-dialog'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover, &:focus': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}))

const StyledMenu = styled(Menu)({
  maxWidth: 270,
})

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
  '&::before': {
    paddingLeft: `${theme.spacing(2)} !important`,
  },
}))

interface BookmarksListMenuProps extends Omit<MenuProps, 'onClose'> {
  lists: CustomAssetList[]
  onClose: (reason: 'cancel' | 'select' | 'create' | 'deselect', list?: CustomAssetList) => void
  excludeListIds?: number[]
  selectedListIds?: number[]
  disabledListIds?: number[]
}

export function BookmarksListMenu({
  lists,
  excludeListIds = [],
  selectedListIds = [],
  disabledListIds = [],
  onClose,
  ...props
}: BookmarksListMenuProps) {
  const filteredLists = useMemo(() => {
    return lists
      .filter((list) => !excludeListIds.includes(list.id))
      .sort((a, b) => a.title.localeCompare(b.title))
  }, [lists, excludeListIds])
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true)
  }

  const handleCloseCreateDialog = useCallback(
    (reason, list) => {
      setCreateDialogOpen(false)
      onClose(reason, list)
    },
    [onClose]
  )

  return (
    <>
      <BookmarksListCreateDialog
        onClose={handleCloseCreateDialog}
        open={createDialogOpen}
        disableRedirect
      />

      <StyledMenu
        anchorEl={props.anchorEl}
        onClick={(event) => event.stopPropagation()}
        onClose={() => onClose('cancel')}
        {...props}>
        <StyledMenuItem
          onClick={handleOpenCreateDialog}
          dense>
          <ListItemIcon>
            <PlusIcon color={'primary'} />
          </ListItemIcon>
          <ListItemText>New list</ListItemText>
        </StyledMenuItem>
        {filteredLists.length > 0 && <MenuDivider />}
        {filteredLists.map((list) => (
          <MenuItem
            key={list.id}
            disabled={disabledListIds.includes(list.id)}
            onClick={(event) => {
              event.stopPropagation()
              onClose(selectedListIds.includes(list.id) ? 'deselect' : 'select', list)
            }}
            dense>
            <ListItemIcon>
              {selectedListIds.includes(list.id) ? (
                <WatchlistIcon color={'primary'} />
              ) : (
                <BookmarkIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={list.title}
              secondary={
                disabledListIds.includes(list.id)
                  ? `Max. limit of ${MAX_BOOKMARK_LIST_ITEMS}`
                  : null
              }
            />
            {list.userIds.length > 1 && (
              <StyledListItemSecondaryAction>
                <UsersIcon
                  color={'inherit'}
                  fontSize={'inherit'}
                />
              </StyledListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

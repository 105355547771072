import { AdvisorDealListItem, DealListItem } from '@gain/rpc/app-model'
import { formatDealListItemTitle } from '@gain/utils/deal'

import { MobileListItemText } from '../../../../common/list-mobile'
import useDealTypeFilterValues from './use-deal-type-filter-values'

interface DealDescriptionTableCellProps {
  deal: DealListItem | AdvisorDealListItem
}

export default function DealNameMobileListItemText({ deal }: DealDescriptionTableCellProps) {
  const dealTypeFilterValues = useDealTypeFilterValues()

  return (
    <MobileListItemText
      primary={deal.asset}
      secondary={formatDealListItemTitle(deal, dealTypeFilterValues)}
    />
  )
}

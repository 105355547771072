import { useRpcClient } from '@gain/api/swr'
import PublicPage from '@gain/components/public-page'
import { isNotAuthorized, isValidationIssueArray, RpcError } from '@gain/rpc/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Debug from 'debug'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { AlertDialog } from '../../common/dialog'
import { FormError, FullWidthButton, FullWidthForm, TextInput } from '../../common/form'
import { useAutoAuthRedirect } from '../../features/auth/use-auth-redirect'
import { useZendesk } from '../../features/zendesk'
import { FORGOT_PASSWORD_PATH, HOME_PATH, ResetPasswordRouteParams } from '../utils'

const debug = Debug('gain-pro:forgot-password')

interface ResetPasswordFormValues {
  password: string
  passwordRepeat: string
}

enum PageStatus {
  Fresh,
  Loading,
  Success,
  InputError,
  TokenError,
  UnknownError,
}

/**
 * RouteResetPassword renders the page for resetting your password.
 * A user can fill in a new password and then go to login.
 */
export default function RouteResetPassword() {
  const [responseError, setResponseError] = useState<string | undefined>()
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Fresh)
  const { secret } = useParams<ResetPasswordRouteParams>()

  const { enableChat } = useZendesk()
  const fetcher = useRpcClient()

  // Automatically redirect user if already signed in
  useAutoAuthRedirect()

  // Define the password form
  const form = useForm<ResetPasswordFormValues>({
    defaultValues: { password: '', passwordRepeat: '' },
    resolver: yupResolver(
      yup.object({
        password: yup.string().trim().required(),
        passwordRepeat: yup
          .string()
          .trim()
          .required('Please repeat your password for confirmation')
          .oneOf([yup.ref('password')], 'Passwords should match'),
      })
    ),
  })

  // On form submit, send request for reset password
  const onSubmit: SubmitHandler<ResetPasswordFormValues> = async (formData) => {
    setPageStatus(PageStatus.Loading)
    try {
      await fetcher({
        method: 'account.resetPassword',
        params: {
          newPassword: formData.password,
          token: secret,
        },
      })

      setPageStatus(PageStatus.Success)
    } catch (error) {
      debug('forgot password request failed', error)
      if (error instanceof RpcError) {
        if (isValidationIssueArray(error.data)) {
          const errorMessage = error.data.reduce((agg, item) => `${agg} ${item.message}`, '')
          setResponseError(errorMessage)
          setPageStatus(PageStatus.InputError)
        } else if (isNotAuthorized(error.code)) {
          setPageStatus(PageStatus.TokenError)
        }
      } else {
        setPageStatus(PageStatus.UnknownError)
      }
    }
  }

  // Determine which title to show
  let title: string | undefined
  let message = 'Enter a new password.'
  if (pageStatus === PageStatus.Success) {
    title = 'Password changed'
    message =
      'Your password has been successfully reset. You can now sign in with your new password.'
  } else if (pageStatus === PageStatus.TokenError) {
    title = 'Reset link expired'
    message =
      'The password reset link is expired. Enter your email address again to receive a new reset link in your inbox.'
  }

  return (
    <PublicPage
      message={message}
      title={title}>
      {/* Error dialog for unexpected server errors */}
      <AlertDialog
        message={'An error occurred please try again later'}
        onClose={() => {
          setPageStatus(PageStatus.Fresh)
        }}
        open={pageStatus === PageStatus.UnknownError}
        title={'Server Error'}
      />

      {/* Show password (repeat) form */}
      {![PageStatus.Success, PageStatus.TokenError].includes(pageStatus) && (
        <FormProvider {...form}>
          <FullWidthForm onSubmit={form.handleSubmit(onSubmit)}>
            <TextInput
              autoComplete={'off'}
              autoFocus={true}
              name={'password'}
              placeholder={'Password'}
              type={'password'}
              disableHelperText
            />
            <TextInput
              autoComplete={'off'}
              name={'passwordRepeat'}
              placeholder={'Repeat password'}
              type={'password'}
              disableHelperText
            />

            <FormError>{form.formState.errors.passwordRepeat?.message ?? responseError}</FormError>

            <div>
              <FullWidthButton
                loading={pageStatus === PageStatus.Loading}
                sx={{ mt: 1 }}
                type={'submit'}>
                Reset password
              </FullWidthButton>
            </div>
          </FullWidthForm>
        </FormProvider>
      )}

      {/* Show navigational button on success and token error */}
      {[PageStatus.Success, PageStatus.TokenError].includes(pageStatus) && (
        <FullWidthButton to={pageStatus === PageStatus.Success ? HOME_PATH : FORGOT_PASSWORD_PATH}>
          Ok
        </FullWidthButton>
      )}

      {/* Support footer */}
      <Typography
        color={'text.secondary'}
        textAlign={'center'}
        variant={'body2'}>
        Having trouble signing in?
        <Button
          color={'primary'}
          onClick={enableChat}
          variant={'text'}>
          Contact support
        </Button>
      </Typography>
    </PublicPage>
  )
}

import { Theme, useTheme } from '@mui/material/styles'
import { useCallback, useMemo } from 'react'

export function getRatingColor(
  rating: number | null | undefined,
  theme: Theme,
  type: 'foreground' | 'background' = 'background'
) {
  if (rating === null || rating === undefined) {
    return theme.palette.warning.main
  }

  if (rating <= 2) {
    return theme.palette.error.main
  } else if (rating > 2 && rating < 4) {
    if (type === 'background') {
      return theme.palette.warning.main
    }
    return theme.palette.warning.dark
  }
  return theme.palette.success.main
}

export const useRatingColorCallback = () => {
  const theme = useTheme()

  return useCallback((rating: number | null | undefined) => getRatingColor(rating, theme), [theme])
}

export const useRatingColor = (rating: number | null | undefined) => {
  const theme = useTheme()

  return useMemo(() => getRatingColor(rating, theme), [theme, rating])
}

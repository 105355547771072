import { useIsAuthenticated } from '@gain/modules/auth'
import { ElementType, ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { LOGIN_PATH } from './utils'

export type PrivateRouteProps = Omit<RouteProps, 'component' | 'children'> & {
  component?: ElementType
  children?: ReactNode
}

export function PrivateRoute(props: PrivateRouteProps) {
  const { component: Component, children, ...rest } = props

  const isAuthenticated = useIsAuthenticated()

  return (
    <Route
      {...rest}
      render={({ location, history, match }) => {
        if (isAuthenticated) {
          if (Component) {
            return (
              <Component
                history={history}
                location={location}
                match={match}
              />
            )
          }

          return children
        }

        return (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

import { ComponentType, lazy, LazyExoticComponent } from 'react'

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>

/**
 * lazyRetry is a wrapper around React.lazy that will retry the import once if it fails.
 *
 * Based on https://www.codemzy.com/blog/fix-chunkloaderror-react
 */
export default function lazyRetry(
  component: () => ComponentPromise
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): LazyExoticComponent<ComponentType<any>> {
  return lazy(() => retry(component))
}

function retry(componentImport: () => ComponentPromise): ComponentPromise {
  return new Promise((resolve, reject) => {
    // Check if the window has already been refreshed due to a previous retry
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    )

    // Attempt to import the component
    componentImport()
      .then((component) => {
        // Import successful, reset the refresh flag
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false')
        resolve(component)
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // If not refreshed yet, mark for refresh and reload the page
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true')
          return window.location.reload() // Refresh the page
        }

        // If already attempted refresh, reject with the error
        reject(error)
      })
  })
}

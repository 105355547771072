import { isTruthy } from '@gain/utils/common'
import { stringify } from 'query-string'
import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { BooleanParam, encodeQueryParams, useQueryParam } from 'use-query-params'

import { Params } from './path'
import { encodeUrlStringComponent } from './path-utils'

export interface GenerateAssetPagePathParams {
  id: number | string
  name?: string | null
  preview?: boolean | null
}

export interface AssetPageParams extends Params {
  id: string
  name?: string
  preview?: string
}

export interface AssetAdvisorDealsPagePathParams extends Params {
  advisorId: string
  advisorName: string
}

export function useAssetPageParams() {
  const params = useParams<AssetPageParams>()
  const [preview] = useQueryParam('preview', BooleanParam)

  let name = ''

  try {
    name = decodeURIComponent(params.name || '')
  } catch (e) {
    name = params.name || ''
  }

  return useMemo(
    () => ({
      id: parseInt(params.id, 10),
      name: name,
      preview: !!preview,
    }),
    [name, params.id, preview]
  )
}

export const ASSET_PAGE_PATH = '/asset/:id/:name?'

export function generateAssetPagePath(
  { name, preview, ...params }: GenerateAssetPagePathParams | AssetAdvisorDealsPagePathParams,
  path = ASSET_PAGE_PATH
) {
  const generatedPath = generatePath(path, {
    name: name ? encodeUrlStringComponent(name) : '-',
    ...params,
  })

  const queryParams = stringify(
    encodeQueryParams({ preview: BooleanParam }, { preview: !!preview || undefined })
  )

  return [generatedPath, queryParams].filter(isTruthy).join('?')
}

export const ASSET_PAGE_SUMMARY_PATH = `${ASSET_PAGE_PATH}/summary`
export const ASSET_PAGE_ASSESSMENT_PATH = `${ASSET_PAGE_PATH}/assessment`
export const ASSET_PAGE_BENCHMARKING_PATH = `${ASSET_PAGE_PATH}/benchmarking`
export const ASSET_PAGE_FINANCIALS_PATH = `${ASSET_PAGE_PATH}/financials`
export const ASSET_PAGE_VALUATION_PATH = `${ASSET_PAGE_PATH}/valuation`
export const ASSET_PAGE_BUSINESS_PATH = `${ASSET_PAGE_PATH}/business`
export const ASSET_PAGE_MARKET_PATH = `${ASSET_PAGE_PATH}/market`
export const ASSET_PAGE_OWNERSHIP_PATH = `${ASSET_PAGE_PATH}/ownership`
export const ASSET_PAGE_ADVISORS_PATH = `${ASSET_PAGE_PATH}/advisors`
export const ASSET_PAGE_SOURCES_PATH = `${ASSET_PAGE_PATH}/sources`

/**
 * Collection of all asset pages
 */
export const ASSET_PAGE_TABS = [
  ASSET_PAGE_SUMMARY_PATH,
  ASSET_PAGE_ASSESSMENT_PATH,
  ASSET_PAGE_FINANCIALS_PATH,
  ASSET_PAGE_VALUATION_PATH,
  ASSET_PAGE_BUSINESS_PATH,
  ASSET_PAGE_MARKET_PATH,
  ASSET_PAGE_OWNERSHIP_PATH,
  ASSET_PAGE_ADVISORS_PATH,
  ASSET_PAGE_SOURCES_PATH,
]

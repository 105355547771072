import { useGetGeometryForPlaceId } from '@gain/api/app/hooks'
import { GeoPolygon } from '@gain/rpc/app-model'
import { useTheme } from '@mui/material/styles'
import { useMemo, useRef } from 'react'

import {
  GoogleMap,
  Polygon,
  useGoogleMapsApiLoader,
} from '../../../../common/google-maps/google-maps'

const containerStyle = {
  width: '100%',
  height: '266px',
}

function GeoPolygonMap({ placeId, geoJson, lng, lat }: GeoPolygon) {
  const theme = useTheme()
  const mapRef = useRef<google.maps.Map | null>(null)
  const paths = useMemo(
    () =>
      geoJson.coordinates.map((polygon) =>
        polygon[0].map((coordinate) => ({ lat: coordinate[1], lng: coordinate[0] }))
      ),
    [geoJson.coordinates]
  )

  const centerMap = () => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds()
      paths.forEach((path) => {
        path.forEach((point) => {
          bounds.extend(point)
        })
      })
      mapRef.current.fitBounds(bounds, 5)
    }
  }

  return (
    <GoogleMap
      center={{ lat, lng }}
      clickableIcons={false}
      mapContainerStyle={containerStyle}
      onLoad={(map) => {
        mapRef.current = map
      }}
      options={{
        disableDefaultUI: true,
        noClear: true,
        panControl: false,
        zoomControl: false,
        scrollwheel: false,
        gestureHandling: 'none',
        keyboardShortcuts: false,
      }}>
      {paths.map((path, index) => (
        <Polygon
          key={`${placeId}-${index}`}
          onLoad={centerMap}
          options={{
            fillColor: theme.palette.primary.main,
            fillOpacity: 0.4,
            strokeColor: theme.palette.primary.main,
            strokeOpacity: 0.8,
            strokeWeight: 1.5,
            clickable: false,
          }}
          paths={path}></Polygon>
      ))}
    </GoogleMap>
  )
}

interface FilterGeoPolygonMapProps {
  placeId: string
}

export default function FilterGeoPolygonMap({ placeId }: FilterGeoPolygonMapProps) {
  const { data } = useGetGeometryForPlaceId({ placeId })
  const { isLoaded } = useGoogleMapsApiLoader()

  if (!isLoaded || !data || data.geoJson.coordinates.length === 0) {
    return null
  }

  return (
    <GeoPolygonMap
      key={Date.now()}
      {...data}
    />
  )
}

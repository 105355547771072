import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { isDefined } from '@gain/utils/common'

import { OWNERSHIP_TYPES } from './index'

export interface FormatAssetOwnershipLabelOptions {
  emptyValue?: string
}

export const formatAssetOwnershipType = (
  value: AssetOwnershipType | null | undefined,
  { emptyValue = '-' }: FormatAssetOwnershipLabelOptions = {}
): string => {
  if (!isDefined(value) || value.trim() === '') {
    return emptyValue
  }

  return OWNERSHIP_TYPES[value] ? OWNERSHIP_TYPES[value].title : emptyValue
}

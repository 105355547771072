import {
  InvestorStrategyClassification,
  InvestorStrategyPreferredStake,
} from './lib/rpc-shared-model'
import { Option } from './option'

export const INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS: ReadonlyArray<
  Option<InvestorStrategyClassification>
> = [
  {
    value: InvestorStrategyClassification.Buyout,
    label: 'Buyout',
  },
  {
    value: InvestorStrategyClassification.Growth,
    label: 'Growth',
  },
  {
    value: InvestorStrategyClassification.VentureCapital,
    label: 'Venture capital',
  },
  {
    value: InvestorStrategyClassification.SpecialSituation,
    label: 'Special situation',
  },
  {
    value: InvestorStrategyClassification.Infrastructure,
    label: 'Infrastructure',
  },
  {
    value: InvestorStrategyClassification.Credit,
    label: 'Credit',
  },
  {
    value: InvestorStrategyClassification.CoInvestment,
    label: 'Co-investment',
  },
  {
    value: InvestorStrategyClassification.Others,
    label: 'Others',
  },
]

export const INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS: ReadonlyArray<
  Option<InvestorStrategyPreferredStake>
> = [
  {
    value: InvestorStrategyPreferredStake.Minority,
    label: 'Minority',
  },
  {
    value: InvestorStrategyPreferredStake.Majority,
    label: 'Majority',
  },
  {
    value: InvestorStrategyPreferredStake.Flexible,
    label: 'Flexible',
  },
  {
    value: InvestorStrategyPreferredStake.Unknown,
    label: 'Unknown',
  },
]

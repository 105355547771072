import { InvestorStrategyListItem } from '@gain/rpc/app-model'

import ContentLink from '../../../common/content-link'
import { generateInvestorStrategyPagePathParams } from '../../../routes/investor-strategy'
import TooltipInvestorStrategy from '../investor-strategy-tooltip'

interface InvestorStrategyContentLinkProps {
  strategy?: InvestorStrategyListItem | null
}

export default function InvestorStrategyContentLink({
  strategy,
}: InvestorStrategyContentLinkProps) {
  if (!strategy || !strategy.strategyName || strategy.strategyId === null) {
    return <>-</>
  }

  return (
    <TooltipInvestorStrategy
      id={strategy.strategyId}
      strategy={strategy}>
      <ContentLink
        href={generateInvestorStrategyPagePathParams({
          investorId: strategy.investorId,
          strategyId: strategy.strategyId,
          strategyName: strategy.strategyName,
          investorName: strategy.investorName,
        })}
        label={strategy.strategyName}
      />
    </TooltipInvestorStrategy>
  )
}

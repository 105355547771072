import { AssetOwnershipType } from '@gain/rpc/shared-model'

export interface Ownership {
  id: AssetOwnershipType
  title: string
  explainer: string
  explainerUnverified?: string
}

export const OWNERSHIP_TYPES: Record<AssetOwnershipType, Ownership> = {
  private: {
    id: AssetOwnershipType.Private,
    title: 'Private ownership',
    explainer:
      'This company is majority-owned by an individual or a group of individuals (e.g. family, management team).',
    explainerUnverified: 'It is most likely privately owned.',
  },
  bankrupt: {
    id: AssetOwnershipType.Bankrupt,
    title: 'Bankrupt',
    explainer: 'This company is insolvent and stopped operating.',
  },
  other: {
    id: AssetOwnershipType.Other,
    title: 'Other',
    explainer:
      'Other ownership types (e.g. foundations, employee trusts, creditors, joint ventures).',
  },
  subsidiary: {
    id: AssetOwnershipType.Subsidiary,
    title: 'Subsidiary',
    explainer: 'This company is a part/subsidiary of another company.',
  },
  regular: {
    id: AssetOwnershipType.Regular,
    title: 'PE majority',
    explainer: 'This company is majority-owned by one or several institutional investors.',
  },
  minority: {
    id: AssetOwnershipType.Minority,
    title: 'PE minority',
    explainer: 'This company is minority-owned by one or several institutional investors.',
  },
  listed: {
    id: AssetOwnershipType.Listed,
    title: 'Publicly listed',
    explainer:
      'This company is listed on a stock exchange and (some of) its shares are traded publicly.',
  },
  ventureCapital: {
    id: AssetOwnershipType.VentureCapital,
    title: 'VC-backed',
    explainer: 'This company is backed by venture capital from one or multiple investors.',
  },
  government: {
    id: AssetOwnershipType.Government,
    title: 'Government/semi-public',
    explainer:
      'This company is majority-owned by a government, municipality or other public entity.',
  },
}

import { FieldMetaState } from 'react-final-form'

export function showFieldError<FieldValue>({
  error,
  submitError,
  touched,
}: FieldMetaState<FieldValue>) {
  return (error || submitError) && touched
}

export function formatFieldError<FieldValue>(meta: FieldMetaState<FieldValue>) {
  return showFieldError(meta) ? meta.error || meta.submitError : null
}

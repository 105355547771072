import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListArgs, ListItemKey } from '@gain/rpc/list-model'
import { listFilter } from '@gain/rpc/utils'
import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { useAppListSwr } from './use-app-list-swr'
import useMergeOptions from './use-merge-options'

function useParams<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  Key extends ListItemKey<FilterItem>,
  Value extends Array<FilterItem[Key]> | null
>(field: Key, ids: Value): Partial<ListArgs<FilterItem>> | null {
  return useMemo(() => {
    if (ids !== null && ids.length > 0) {
      return {
        filter: [listFilter<FilterItem>(field, '=', ids as any)],
        limit: ids.length,
      }
    }

    return null
  }, [field, ids])
}

export function createUseAppListItemsSwr<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  Key extends ListItemKey<FilterItem>,
  Value extends Array<FilterItem[Key]> | null
>(method: Method, field: Key, defaultOptions?: SWRConfiguration) {
  return (ids: Value, options?: SWRConfiguration) => {
    const params = useParams<Method, Item, FilterItem, Key, Value>(field, ids)
    const mergedOptions = useMergeOptions(defaultOptions, options)
    return useAppListSwr<Method, Item, FilterItem>(method, params, mergedOptions)
  }
}

import { UserProfile } from '@gain/rpc/app-model'
import { createContext } from 'react'

export interface UserContextType {
  userProfile: UserProfile
  openProjectCodeDialog: () => void
}

export const UserContext = createContext<UserContextType | null>(null)

export default UserContext

import { useUserProfile as useSwrUserProfile } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'

/**
 * Gets the logged-in users profile, if user is not logged in returns empty data
 */
export function useUserProfileIgnoreAuthError() {
  const fetcher = useRpcClient()

  return useSwrUserProfile(undefined, {
    fetcher: async (...args: [any]) => {
      try {
        // eslint-disable-next-line prefer-spread
        return await fetcher.apply(null, args)
      } catch (error) {
        if (isJsonRpcError(error) && error.code === RpcErrorCode.InvalidToken) {
          // silently ignore unauthorized errors
        } else {
          throw error
        }
      }
    },
  })
}

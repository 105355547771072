import { Deal } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { uniq } from 'lodash'
import { useMemo } from 'react'

export function getDealInvestorIds(deal: Deal) {
  const investorIds = [...deal.buyers, ...deal.sellers]
    .map((party) => party.linkedInvestorId)
    .filter(isDefined)
  return uniq(investorIds)
}

export function getDealsInvestorIds(deals: Deal[]) {
  return uniq(deals.flatMap(getDealInvestorIds))
}

export function useDealsInvestorIds(deals: Deal[]) {
  return useMemo(() => getDealsInvestorIds(deals), [deals])
}

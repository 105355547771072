import { UserListItem } from '@gain/rpc/app-model'
import { RenderCellParams } from '@gain/utils/table'
import { isAdministrator } from '@gain/utils/user'
import Chip from '@mui/material/Chip'
import Fade from '@mui/material/Fade'
import { styled } from '@mui/material/styles'

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.common.white,
}))

export function UserListItemAdministratorTableCell({
  value,
}: RenderCellParams<UserListItem, 'role'>) {
  return (
    <Fade in={isAdministrator(value)}>
      <StyledChip
        label={'Admin'}
        size={'small'}
      />
    </Fade>
  )
}

import { RowType } from '@gain/utils/table'
import { useCallback, useMemo, useState } from 'react'

import { SelectionChangeEvent } from '../../../common/virtual-table'

export type HandleSelectionChange = (event: SelectionChangeEvent) => void
export type ClearSelection = () => void

export const useListViewRowSelection = <Row extends RowType>(
  rows: Row[]
): [HandleSelectionChange, Row[], number[], ClearSelection] => {
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<number[]>([])

  const handleSelectionChange = useCallback(
    ({ selected, deselected }: SelectionChangeEvent) => {
      let newSelectedRowIndexes = [...selectedRowIndexes]

      if (deselected) {
        newSelectedRowIndexes = newSelectedRowIndexes.filter((index) => !deselected.includes(index))
      }

      if (selected) {
        newSelectedRowIndexes = [...newSelectedRowIndexes, ...selected]
      }

      setSelectedRowIndexes(newSelectedRowIndexes)
    },
    [selectedRowIndexes]
  )

  const handleClearSelection = useCallback(() => {
    setSelectedRowIndexes([])
  }, [])

  // Derive selectedRows from selectedRowIndexes during rendering
  const selectedRows = useMemo(() => {
    return rows.filter((_, index) => selectedRowIndexes.includes(index))
  }, [rows, selectedRowIndexes])

  return [handleSelectionChange, selectedRows, selectedRowIndexes, handleClearSelection]
}

import { AssociatedTag, TagListItem } from '@gain/rpc/app-model'

export function formatTagUsageCount(tag: TagListItem | AssociatedTag) {
  return [
    tag.assetCount > 0
      ? `${tag.assetCount} ${tag.assetCount === 1 ? 'company' : 'companies'}`
      : false,
  ]
    .filter((value): value is string => value !== false)
    .join(', ')
}

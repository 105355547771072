import { SlotName, SlotPortal } from '@gain/components/slot'
import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { isDefined, noop } from '@gain/utils/common'
import { useIsXs } from '@gain/utils/responsive'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Waypoint } from 'react-waypoint'

import {
  BreakpointVariantMap,
  Container,
  TablePaper,
  useBreakpointVariant,
} from '../../../common/breakpoint-variant'
import ConditionalWrapper from '../../../common/conditional-wrapper'
import Empty from '../../../common/empty'
import { MobileListSkeleton } from '../../../common/list-mobile'
import Panel, { PanelHeader, PanelTitle } from '../../../common/panel'
import { TableEmpty, TableEmptyProps } from '../../../common/table'
import VirtualTable, { VirtualTableRef } from '../../../common/virtual-table'
import FilterBar, {
  FilterApi,
  FilterContextProvider,
  FilterModel,
  isEmpty,
} from '../../filter/filter-bar'
import ListHeader from './list-header'
import { ListViewContext } from './list-view-context'
import { ListViewApi, ListViewApiProps, ListViewMethod, useListViewApi } from './use-list-view-api'

export type ListViewProps<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
> = ListViewApiProps<Method, Item, FilterItem, FilterField> & {
  renderPageActions?: (
    api: ListViewApi<Method, Item, FilterItem, FilterField>
  ) => ReactElement[] | ReactElement
  renderBulkActions?: (api: ListViewApi<Method, Item, FilterItem, FilterField>) => ReactElement

  onSelectionChange?: (rows: Item[]) => void
  checkboxSelection?: boolean
  selectId?: (item: Item) => string | number
  defaultFilterModel?: FilterModel<FilterItem, FilterField>
  apiRef?: MutableRefObject<ListViewApi<Method, Item, FilterItem, FilterField> | null>
  renderFilterBarActions?: (
    api: ListViewApi<Method, Item, FilterItem, FilterField>
  ) => ReactElement | false | undefined
  onFilter?: (filterModel: FilterModel<FilterItem, FilterField>) => void
  tableEmptyProps?: TableEmptyProps
  hideCount?: boolean
  disableAddFilter?: boolean
  disableDeleteFilter?: boolean
  disableOrFilter?: boolean
  disableFilters?: boolean
  disableClear?: boolean
  filterBarSearchLabel?: string
  filterBarSearchPlaceholder?: string
  inline?: boolean

  // The empty state is displayed when filters found no matches. This setting will also display
  // the empty state when there are no filters at all.
  showEmptyWhenNoFilters?: boolean
} & BreakpointVariantMap<Item>

export default function ListView<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
>(props: ListViewProps<Method, Item, FilterItem, FilterField>) {
  const filterApiRef = useRef<FilterApi<FilterItem, FilterField> | null>(null)
  const {
    checkboxSelection,
    renderPageActions = noop,
    renderBulkActions,
    renderFilterBarActions = noop,
    onFilter = noop,
    apiRef,
    tableEmptyProps,
    showEmptyWhenNoFilters = false,
    hideCount,
    disableAddFilter,
    disableDeleteFilter,
    disableOrFilter,
    disableFilters,
    disableClear,
    filterBarSearchLabel,
    filterBarSearchPlaceholder,
    inline,
  } = props

  const breakpointVariant = useBreakpointVariant(props)
  const isXs = useIsXs()

  const api = useListViewApi<Method, Item, FilterItem, FilterField>(props)
  const filterModelRef = useRef(api.filterModel)

  useEffect(() => {
    if (apiRef) {
      apiRef.current = api
    }
  }, [apiRef, api])

  const virtualTableRef = useRef<VirtualTableRef>()

  // Refresh the grid if the api changes
  const handleRefreshGrid = useCallback(async () => {
    // Don't update if showEmptyWhenNoFilters is true and no filters are set
    if (!showEmptyWhenNoFilters || !isEmpty(api.filterModel)) {
      // Reset the scroll position
      virtualTableRef.current?.resetScroll()

      // Mutate the data
      await api.swr.mutate()
    }
  }, [api, showEmptyWhenNoFilters])

  const pageActions = useMemo(() => {
    const actions = renderPageActions(api)

    if (!isDefined(actions)) {
      return []
    }

    if (!Array.isArray(actions)) {
      return [actions]
    }

    return actions
  }, [api, renderPageActions])

  const filterBarActions = useMemo(() => {
    if (renderFilterBarActions) {
      return renderFilterBarActions(api)
    }

    return []
  }, [api, renderFilterBarActions])

  const handleSelectId = useCallback(
    (item: Item) => (props.selectId ? props.selectId(item) : null),
    [props]
  )

  const handleFilterChange = useCallback(
    (value: FilterModel<FilterItem, FilterField>) => {
      api.setFilters(value)
      onFilter(value)
    },
    [api, onFilter]
  )

  useEffect(() => {
    if (filterModelRef.current !== null && api.filterModel === null) {
      filterApiRef.current?.reset()
    }
    filterModelRef.current = api.filterModel
  }, [api.filterModel])

  // Show empty state when initial load has finished and there are no rows; or if
  // no filters are set and showEmptyWhenNoFilters is true.
  const noResults = !api.swr.isLoadingInitial && api.data.rows.length === 0
  const noFilters = isEmpty(api.filterModel) && showEmptyWhenNoFilters
  const showEmpty = noFilters || noResults

  return (
    <ListViewContext.Provider
      value={{
        api,
        filterModel: filterModelRef.current,
        refreshGrid: handleRefreshGrid,
      }}>
      <FilterContextProvider value={filterApiRef.current}>
        <Stack
          flex={1}
          width={'100%'}>
          {!disableFilters && (
            <FilterBar
              ref={filterApiRef}
              actions={filterBarActions}
              addFilterMenu={props.addFilterMenu}
              defaultFilterModel={props.defaultFilterModel}
              disableAddFilter={disableAddFilter}
              disableClear={disableClear}
              disableDeleteFilter={disableDeleteFilter}
              disableOrFilter={disableOrFilter}
              filterConfigMap={props.filterConfigMap}
              gaCategory={api.gaCategory}
              initialFilterModel={api.filterModel || undefined}
              onFilterChange={handleFilterChange}
              onSearchValueChange={api.setSearch}
              readOnly={isXs}
              searchLabel={filterBarSearchLabel}
              searchPlaceholder={filterBarSearchPlaceholder}
              searchValue={api.search}
            />
          )}

          <Container>
            {breakpointVariant.variant === 'virtual-table' && (
              <TablePaper
                tableVariant={'virtual-table'}
                variant={'outlined'}>
                {!showEmpty && (
                  <VirtualTable
                    ref={virtualTableRef}
                    checkboxSelection={checkboxSelection}
                    hideTotalSize={hideCount}
                    loadingInitial={api.swr.isLoadingInitial}
                    loadingMore={api.swr.isLoadingMore}
                    onLoadMore={api.fetchMore}
                    onSelectionChange={api.handleSelectionChange}
                    onSort={api.setSort}
                    rows={api.data.rows}
                    selectionModel={api.selectedRowIndexes}
                    sort={api.sort}
                    totalSize={api.data.totalSize}
                    {...breakpointVariant.VariantProps}
                  />
                )}

                {showEmpty && (
                  <TableEmpty
                    actions={tableEmptyProps?.actions || undefined}
                    message={tableEmptyProps?.message || 'Please adjust your filter settings'}
                    title={tableEmptyProps?.title || 'No filter results'}
                  />
                )}

                {renderBulkActions && renderBulkActions(api)}
              </TablePaper>
            )}

            {breakpointVariant.variant === 'list' && showEmpty && (
              <Box
                alignItems={'center'}
                display={'flex'}
                flex={1}
                flexDirection={'column'}
                justifyContent={'center'}>
                <Empty
                  actions={tableEmptyProps?.actions}
                  message={tableEmptyProps?.message || 'Please adjust your filter settings'}
                  title={tableEmptyProps?.title || 'No filter results'}
                />
              </Box>
            )}

            {breakpointVariant.variant === 'list' && !showEmpty && (
              <ConditionalWrapper
                condition={!inline}
                wrapper={(children) => (
                  <Panel>
                    {breakpointVariant.VariantProps.title && (
                      <PanelHeader align={'start'}>
                        <PanelTitle>{breakpointVariant.VariantProps.title}</PanelTitle>
                      </PanelHeader>
                    )}
                    {children}
                  </Panel>
                )}>
                <List disablePadding>
                  {breakpointVariant.VariantProps.headerProps ? (
                    <ListHeader
                      disableBorderTop={!inline}
                      secondaryTitle={breakpointVariant.VariantProps.headerProps.secondaryTitle}
                      title={breakpointVariant.VariantProps.headerProps.title}
                    />
                  ) : (
                    inline && <Divider />
                  )}
                  {api.swr.isLoadingInitial && <MobileListSkeleton nrOfItems={10} />}
                  {api.data.rows
                    .filter((row) =>
                      breakpointVariant.VariantProps.filterListItem
                        ? breakpointVariant.VariantProps.filterListItem(row)
                        : true
                    )
                    .map((item, index, items) => (
                      <React.Fragment key={handleSelectId(item) || index}>
                        {breakpointVariant.VariantProps.renderListItem(item, index, items)}
                        {index < items.length - 1 && <Divider component={'li'} />}
                      </React.Fragment>
                    ))}
                  {!api.swr.isLoadingInitial && !api.swr.isReachingEnd && (
                    <Waypoint onEnter={api.fetchMore} />
                  )}
                </List>
              </ConditionalWrapper>
            )}
          </Container>

          <SlotPortal slotName={SlotName.Tabs}>
            <Stack
              alignItems={'center'}
              display={'flex'}
              flexDirection={'row'}
              gap={1}
              justifyContent={'center'}>
              {React.Children.map(pageActions, (child, index) =>
                React.cloneElement(child, { key: index })
              )}
            </Stack>
          </SlotPortal>
        </Stack>
      </FilterContextProvider>
    </ListViewContext.Provider>
  )
}

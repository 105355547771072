import { useTagList } from '@gain/api/app/hooks'
import OverflowContainer, { OverflowContainerProps } from '@gain/components/overflow-container'
import Tooltip from '@gain/components/tooltip'
import { AssetListItem, AssetTag } from '@gain/rpc/app-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { formatTagUsageCount } from '@gain/utils/tag'
import { useCallback } from 'react'

import { Tag } from '../../../../common/tag'
import {
  autocompleteIncludeFilterValue,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import { filterModelToQueryString } from '../../../../features/filter/filter-bar'
import { ASSET_LIST_PATH } from '../../../utils'

interface AssetTagsProps extends OverflowContainerProps {
  assetTags: AssetTag[]
}

export default function AssetTags({
  assetTags,
  lineHeight = 22,
  maxLines = 2,
  ...props
}: AssetTagsProps) {
  const tags = useTagList({
    filter: [
      listFilter(
        'id',
        '=',
        assetTags.map((assetTag) => assetTag.tagId)
      ),
    ],
    sort: [listSort('assetCount', 'desc')],
  })

  const handleFormatTagUsageCount = useCallback(
    (tag: AssetTag) => {
      const match = tags.data.items.find((item) => item.id === tag.tagId)

      if (match) {
        return formatTagUsageCount(match)
      }

      return ''
    },
    [tags.data.items]
  )

  return (
    <OverflowContainer
      lineHeight={lineHeight}
      maxLines={maxLines}
      {...props}>
      {assetTags.map((tag) => (
        <Tooltip
          key={tag.id}
          title={handleFormatTagUsageCount(tag)}
          disableInteractive>
          <Tag
            href={[
              ASSET_LIST_PATH,
              filterModelToQueryString<AssetListItem, 'tagIds'>([
                filterValueGroup(
                  filterValueItem('tagIds', autocompleteIncludeFilterValue([tag.tagId]))
                ),
              ]),
            ].join('?')}
            label={tag.tag}
          />
        </Tooltip>
      ))}
    </OverflowContainer>
  )
}

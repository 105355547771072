import Collapse from '@mui/material/Collapse'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { ReactElement } from 'react'

export interface ListCollapseProps {
  isOpen: boolean
  collapsed: ReactElement
  expanded: ReactElement
}

export function ListCollapse({ isOpen, collapsed, expanded }: ListCollapseProps) {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <Collapse
        in={!isOpen && isMdUp}
        timeout={{
          appear: theme.transitions.duration.enteringScreen,
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen,
        }}>
        {collapsed}
      </Collapse>
      <Collapse
        in={isOpen || !isMdUp}
        timeout={{
          appear: theme.transitions.duration.enteringScreen,
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen,
        }}>
        {expanded}
      </Collapse>
    </>
  )
}

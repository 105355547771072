import { createContext, useContext } from 'react'
// eslint-disable-next-line no-restricted-imports
import { HelmetProps } from 'react-helmet'

export type HeadContextType = Omit<HelmetProps, 'children' | 'title'>

export const HeadContext = createContext<HeadContextType | null>(null)

export const useHeadContext = () => {
  const context = useContext(HeadContext)

  if (context === null) {
    throw new Error('HeadContext not provided')
  }

  return context
}

import { useMemo } from 'react'

/**
 * Splits a list into two lists, where the first list contains the first `maxNrOfItems`
 * items and the second list contains the rest of the items, or [] if no items are left.
 *
 * When mergeLastItem is true and the list is split with only 1 item remaining,
 * this item is returned with the visible items instead of being hidden. This item
 * can be used to render in place of a "view more" button shown otherwise.
 */
export function useSplitList<Item>(
  items: Item[] | undefined | null,
  maxNrOfItems: number,
  mergeLastItem = true
): [Item[], Item[]] {
  return useMemo(() => {
    if (items === null || items === undefined) {
      return [new Array<Item>(), new Array<Item>()]
    }

    const visibleItems = items.slice(0, maxNrOfItems)
    const hiddenItems = items.slice(maxNrOfItems)

    if (mergeLastItem && hiddenItems.length <= 1) {
      return [items, []]
    }

    return [visibleItems, hiddenItems]
  }, [items, maxNrOfItems, mergeLastItem])
}

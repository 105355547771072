import Slider, { SliderProps } from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { Control, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'

export interface SliderInputProps<
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>
> extends UseControllerProps<T, N>,
    Pick<SliderProps, 'valueLabelFormat'> {
  name: N
  control: Control<T>
  label: string
  max: number
  min: number
}

/**
 * SliderInput renders a full width slider input field that works
 * within a `react-hook-form` form.
 */
export default function SliderInput<
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>
>(props: SliderInputProps<T, N>) {
  const { label, valueLabelFormat } = props
  const { field } = useController(props)

  const formatLabel = useMemo(() => {
    return (value: number) => {
      if (typeof props.valueLabelFormat === 'function') {
        return props.valueLabelFormat(value, -1)
      }

      return value.toString(10)
    }
  }, [props])

  return (
    <Stack gap={0.5}>
      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}>
        <Typography variant={'body2'}>{label}</Typography>
        <Typography variant={'body2'}>{formatLabel(field.value)}</Typography>
      </Stack>
      {/*@ts-expect-error the "onChange" mismatch does not matter here*/}
      <Slider
        {...field}
        defaultValue={5}
        max={props.max}
        min={props.min}
        valueLabelDisplay={'off'}
        valueLabelFormat={valueLabelFormat}
      />
    </Stack>
  )
}

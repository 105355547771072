import { useLocalStorage } from '@gain/utils/storage'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from 'react'
import { matchPath } from 'react-router-dom'

import { API_DOCS_PATH } from '../../routes/utils'

type MenuState = [
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean | undefined>>,
  menuEnabled: boolean
]
const MenuContext = createContext<MenuState | null>(null)

/**
 * useMenuContext is used to get and set if the main navigation menu is visible.
 */
export function useMenuContext() {
  const menu = useContext(MenuContext)

  if (menu === null) {
    throw new Error('MenuContext not provided')
  }

  return menu
}

interface MenuProviderProps {
  children: ReactNode
}

/**
 * MenuProvider is used to provide the main navigation menu visibility state.
 */
export default function MenuProvider({ children }: MenuProviderProps) {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))

  // Maintain menu visibility state for SM under a different key
  const key = ['show-menu', isSm ? 'sm' : null].filter(Boolean).join('-')

  // Enable menu on sm and up, except on api docs
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isApiDocs = matchPath(window.location.pathname, { path: API_DOCS_PATH })
  const isMenuEnabled = isSmUp && !isApiDocs

  // Menu state must persist over browser sessions
  const [isOpen, setIsOpen] = useLocalStorage(key, !isSm)

  // Combine local storage state and the enable/disable menu state
  const value: MenuState = [isOpen, setIsOpen, isMenuEnabled]

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

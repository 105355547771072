import { ConferenceEditionListItem } from '@gain/rpc/app-model'
import { formatCountry } from '@gain/utils/countries'
import { useSplitList, useVisibleListItems } from '@gain/utils/list'
import OverflowContainer from 'libs/components/overflow-container/src/overflow-container'
import { formatDateRange } from 'libs/utils/date/src/format-date-range'
import useVisibleColumns, { ColumnVisibilityModel } from 'libs/utils/table/src/use-visible-columns'
import { memo, useRef } from 'react'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import Table, { ColumnConfig } from '../../../../common/table'
import Tag from '../../../../common/tag'
import ViewMoreButton from '../../../../common/view-more-button'
import ConferenceEditionContentLink from '../../../../features/conference-edition/conference-edition-content-link'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import { CONFERENCE_LIST_PATH } from '../../../utils'

const TagsCell = memo(function TagsCell({ tagIds, tags }: { tagIds: number[]; tags: string[] }) {
  if (tagIds.length === 0) {
    return <>-</>
  }

  return (
    <OverflowContainer
      chipSize={'small'}
      lineHeight={16}
      maxLines={1}>
      {tagIds.map((tagId, index) => (
        <Tag
          key={tagId}
          href={[
            CONFERENCE_LIST_PATH,
            filterModelToQueryString<ConferenceEditionListItem, 'exhibitorTagIds'>([
              filterValueGroup(
                filterValueItem('exhibitorTagIds', autocompleteIncludeFilterValue([tagId]))
              ),
            ]),
          ].join('?')}
          label={tags[index]}
        />
      ))}
    </OverflowContainer>
  )
})

const columnVisibility: ColumnVisibilityModel<ConferenceEditionListItem> = {
  exhibitorTagIds: 900,
  venueCountryCode: 550,
}

const columns: Array<ColumnConfig<ConferenceEditionListItem>> = [
  {
    headerName: 'Name',
    field: 'name',
    renderCell: ({ row }) => (
      <ConferenceEditionContentLink
        conferenceEdition={row}
        conferenceId={row.id}
        conferenceName={row.name}
        logoFileUrl={row.logoFileUrl}
      />
    ),
  },
  {
    field: 'exhibitorTagIds',
    headerName: 'Tags',
    align: 'left',
    renderCell: ({ row, value }) => {
      return (
        <TagsCell
          tagIds={value}
          tags={row.exhibitorTags}
        />
      )
    },
  },
  {
    field: 'startDate',
    headerName: 'Date',
    width: 170,
    align: 'right',
    valueFormatter: ({ row, value }) => formatDateRange(value, row.endDate),
  },
  {
    field: 'venueCountryCode',
    headerName: 'Location',
    width: 140,
    align: 'right',
    valueFormatter: ({ value }) => formatCountry(value),
  },
]

interface AssetConferencesCardProps extends CardProps {
  conferences: ConferenceEditionListItem[]
}

export default function AssetConferencesCard({
  conferences,
  ...cardProps
}: AssetConferencesCardProps) {
  const [initialConferences, additionalConferences] = useSplitList(conferences, 3)
  const [rows, showAll, handleShowAll] = useVisibleListItems(
    initialConferences,
    additionalConferences
  )
  const tableRef = useRef<HTMLDivElement>(null)
  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  return (
    <Card {...cardProps}>
      <CardHeader title={'Conference participation'} />
      <Table
        ref={tableRef}
        columns={visibleColumns}
        disablePaddingBottomOnLastRow={!showAll}
        rows={rows}
      />
      {!showAll && (
        <ViewMoreButton
          amount={additionalConferences.length}
          onClick={handleShowAll}
          variant={'chevron-down'}
        />
      )}
    </Card>
  )
}

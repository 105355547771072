import { useMemo } from 'react'

import { CellValue, RowType } from './table-model'

export interface ValueFormatterParams<Row extends RowType, Field extends keyof Row> {
  field: Field
  rowIndex: number
  value: Row[Field]
  row: Row
}

export type ValueFormatterFn<Row extends RowType, Field extends keyof Row> = (
  params: ValueFormatterParams<Row, Field>
) => CellValue

export function formatValue<Row extends RowType, Field extends keyof Row>(
  field: Field,
  row: Row,
  rowIndex: number,
  valueFormatter?: ValueFormatterFn<Row, Field>
): CellValue {
  if (field === undefined) {
    return undefined
  }

  if (!valueFormatter) {
    return row[field]
  }

  return valueFormatter({
    field,
    row,
    value: row[field],
    rowIndex,
  })
}

export function useFormattedValue<Row extends RowType, Field extends keyof Row>(
  field: Field,
  row: Row,
  rowIndex: number,
  valueFormatter?: ValueFormatterFn<Row, Field>
): CellValue {
  return useMemo(
    () => formatValue(field, row, rowIndex, valueFormatter),
    [field, row, rowIndex, valueFormatter]
  )
}

import { ChevronDownIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'
import { SvgIconProps } from '@mui/material/SvgIcon'

export const CheckboxAccordionExpandIcon = styled(
  ({ fontSize = 'inherit', ...props }: SvgIconProps) => (
    <ChevronDownIcon
      fontSize={fontSize}
      {...props}
    />
  )
)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

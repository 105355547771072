import { ListItemKey } from '@gain/rpc/list-model'

import DropdownMenu, { DropdownMenuProps } from '../../../../common/dropdown-menu'
import {
  DropdownMenuOptionType,
  isDropdownMenuGroup,
} from '../../../../common/dropdown-menu/dropdown-menu-model'
import {
  FilterCheckboxListOptions,
  FilterConfig,
  OptionValueType,
} from '../filter-config/filter-config-model'

function getOptionLabel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(option: FilterConfig<Item, FilterField>) {
  if ('select' in option && option.select) {
    return option.select.header
  }

  return option.label
}

const letterRegex = /[a-zA-Z]/

/**
 * Flattens the labels of all options AND option groups within a checkbox-list
 * filter to a string array. This value can then be used to allow searching
 * for a label within a checkbox-list (e.g. 'B2B') to match the actual filter
 * (Customer base in this case).
 */
function checkboxListToSearchAliases<ValueType extends OptionValueType>(
  options: FilterCheckboxListOptions<ValueType>
): string[] {
  return options.reduce((acc, current) => {
    if (current.type === 'option-group') {
      return acc.concat(current.label, ...checkboxListToSearchAliases(current.options))
    }

    // Include the option only if it contains a letter to exclude
    // values like "201-500"
    if (letterRegex.test(current.label)) {
      return acc.concat(current.label)
    }

    return acc
  }, new Array<string>())
}

function getOptionSearchAliases<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(option: FilterConfig<Item, FilterField>): string[] {
  if (option.type === 'checkbox-list') {
    return checkboxListToSearchAliases(option.options)
  }

  return []
}

/**
 * Dropdown menu for adding filters to the filter bar. This uses the DropdownMenu
 * component with some additional logic to update the label of the dropdown menu
 * items and add search aliases when applicable.
 */
export default function AddFilterDropdownMenu<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ options, ...props }: DropdownMenuProps<FilterConfig<Item, FilterField>>) {
  // If the type is a select we want to use the header select as the menu label.
  // This is a rather nasty way to do this, but we found no better way to fix
  // this in a reasonable amount of time.
  const updateLabelFromSelectHeader = (
    option: DropdownMenuOptionType<FilterConfig<Item, FilterField>>
  ) => {
    // If it's a dropdown menu group, we want to update the children
    if (isDropdownMenuGroup(option)) {
      return {
        ...option,
        children: option.children.map(updateLabelFromSelectHeader),
      }
    }

    return {
      ...option,
      label: getOptionLabel(option),
      searchAliases: getOptionSearchAliases(option),
    }
  }

  return (
    <DropdownMenu
      horizontalOrigin={'left'}
      options={options.map(updateLabelFromSelectHeader)}
      resetOnClose
      {...props}
    />
  )
}

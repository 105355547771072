import Typography from '@gain/components/typography'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

const StyledRoot = styled(Stack)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  minWidth: 0,
}))

const StyledChildrenContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export interface StatProps {
  label: string
  children: ReactNode
}

export default function Stat({ label, children }: StatProps) {
  return (
    <StyledRoot
      direction={'column'}
      spacing={0.5}>
      <Typography
        color={'text.secondary'}
        variant={'inherit'}>
        {label}
      </Typography>
      {typeof children === 'string' ? (
        <Typography
          color={'text.primary'}
          variant={'inherit'}>
          {children}
        </Typography>
      ) : (
        <StyledChildrenContainer>{children}</StyledChildrenContainer>
      )}
    </StyledRoot>
  )
}

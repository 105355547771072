import { useBookmarksListByAssetId } from '@gain/api/app/hooks'
import { BookmarkFilledIcon, BookmarkIcon } from '@gain/components/icons'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React, { MouseEvent, useCallback, useState } from 'react'

import { BookmarksListAddAssetMenu } from './bookmarks-list-add-asset-menu'

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'fontSize' })<{
  fontSize?: number
}>(({ fontSize }) => ({
  ...(typeof fontSize === 'number' && {
    fontSize,
  }),
}))

interface BookmarksListAddAssetButtonProps {
  className?: string
  assetId: number
  color?: 'primary' | 'default'
  fontSize?: number
  bold?: boolean
  onOpenMenu?: () => void
  onCloseMenu?: () => void
}

export default function BookmarksListAddAssetButton({
  className,
  assetId,
  color = 'primary',
  fontSize,
  bold = false,
  onOpenMenu,
  onCloseMenu,
}: BookmarksListAddAssetButtonProps) {
  const [assetListMenuAnchor, setAssetListMenuAnchor] = useState<Element | null>(null)
  const customLists = useBookmarksListByAssetId(assetId)

  const handleCloseMenu = useCallback(() => {
    setAssetListMenuAnchor(null)
    onCloseMenu?.()
  }, [onCloseMenu])

  const handleOpenMenu = useCallback(
    (event: MouseEvent) => {
      onOpenMenu?.()
      setAssetListMenuAnchor(event.currentTarget)
      event.stopPropagation()
      event.preventDefault()
    },
    [onOpenMenu]
  )

  return (
    <>
      <BookmarksListAddAssetMenu
        anchorEl={assetListMenuAnchor}
        assetIds={[assetId]}
        onClose={handleCloseMenu}
        open={Boolean(assetListMenuAnchor)}
        disableCloseOnSelect
        disableSnackbar
      />

      <StyledIconButton
        className={className}
        color={color}
        fontSize={fontSize}
        onClick={handleOpenMenu}>
        {customLists.length === 0 ? (
          <BookmarkIcon
            fontSize={'inherit'}
            strokeWidth={bold ? 2 : 1}
          />
        ) : (
          <BookmarkFilledIcon
            color={'primary'}
            fontSize={'inherit'}
            strokeWidth={bold ? 2 : 1}
          />
        )}
      </StyledIconButton>
    </>
  )
}

import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { ratingEsgGroup, RatingEsgKey } from '@gain/utils/investment-criteria'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'

import { RatingListItem } from '../../../investment-criteria/investment-criteria-list'

const StyledGroupContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
}))

export interface IndustryEsgAssessmentListProps {
  segment: IndustryMarketSegmentListItem
}

export default function IndustryEsgAssessmentList({ segment }: IndustryEsgAssessmentListProps) {
  const [expanded, setExpanded] = useState<RatingEsgKey | null>(null)

  return (
    <StyledGroupContainer>
      {ratingEsgGroup.ratings.map((rating) => {
        const value = segment[rating.key]

        return (
          <RatingListItem
            key={rating.key}
            expanded={expanded === rating.key}
            onCollapse={() => setExpanded(null)}
            onExpand={() => setExpanded(rating.key)}
            rating={rating}
            ratingValue={segment[rating.key]}
            value={value ? Math.round(value) : null}
          />
        )
      })}
    </StyledGroupContainer>
  )
}

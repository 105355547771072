import { useAssetList, useDeals, useInvestorList } from '@gain/api/app/hooks'
import {
  AssetListItem,
  Deal,
  DealBuyer,
  DealListItem,
  DealSeller,
  InvestorListItem,
} from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { DealPartyData, getDealsAssetIds, getDealsInvestorIds } from '@gain/utils/deal'
import { useMemo } from 'react'

import { DealRow } from './table-deals-columns'

export function useDealsInvestors(deals: Deal[]) {
  return useInvestorList(() => {
    const investorIds = getDealsInvestorIds(deals)

    if (investorIds.length === 0) {
      return null
    }

    return {
      filter: [listFilter('id', '=', investorIds)],
    }
  })
}

export function useDealsAssets(deals: Deal[]) {
  return useAssetList(() => {
    const assetIds = getDealsAssetIds(deals)

    if (assetIds.length === 0) {
      return null
    }

    return {
      filter: [listFilter('id', '=', assetIds)],
    }
  })
}

export function createDealRowParty<Party extends DealBuyer | DealSeller>(
  party: Party,
  investors: InvestorListItem[],
  assets: AssetListItem[]
): Party & DealPartyData {
  return {
    ...party,
    investor: investors.find((investor) => investor.id === party.linkedInvestorId),
    asset: assets.find((asset) => asset.id === party.linkedAssetId),
  }
}

export function useAssetDealRows(dealListItems: DealListItem[]) {
  const deals = useDeals(
    dealListItems.reduce((acc, current) => {
      // Filter deals that are not published to prevent errors
      if (!current.live) {
        return acc
      }

      return acc.concat(current.id)
    }, new Array<number>())
  )
  const investors = useDealsInvestors(deals.data)
  const assets = useDealsAssets(deals.data)

  return useMemo(() => {
    return {
      loading: deals.loading || investors.loading || assets.loading,
      error: deals.error || investors.error || assets.error,
      data: deals.data.map((deal: Deal): DealRow => {
        return {
          ...deal,
          buyers: deal.buyers.map((buyer) =>
            createDealRowParty(buyer, investors.data.items, assets.data.items)
          ),
          sellers: deal.sellers.map((seller) =>
            createDealRowParty(seller, investors.data.items, assets.data.items)
          ),
        }
      }),
    }
  }, [deals, investors, assets])
}

export function useVisibleDealRows(
  initialDeals: DealListItem[],
  additionalDeals: DealListItem[],
  showAll: boolean
) {
  const initialRows = useAssetDealRows(initialDeals)
  const additionalRows = useAssetDealRows(showAll ? additionalDeals : [])

  return useMemo(() => {
    return {
      loading: initialRows.loading || additionalRows.loading,
      error: initialRows.error || initialRows.error,
      data: initialRows.data.concat(additionalRows.data),
    }
  }, [initialRows, additionalRows])
}

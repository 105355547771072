import { useIsAuthenticated } from '@gain/modules/auth'
import { useUserProfileIgnoreAuthError } from '@gain/modules/user'
import { createPath, Location } from 'history'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { HOME_PATH, LOGIN_PATH } from '../../routes/utils'

/**
 * Returns whether the provided hostname refers to a possible
 * CMS URL, which is the only allowed external hostname to
 * redirect to after login.
 */
function isCmsHostname(hostname: string): boolean {
  return hostname.includes('cms.gain.pro') || hostname.includes('localhost:3001')
}

/**
 * useAuthRedirect looks at both the URL and the React Router state
 * to check for a redirect URL to send the user to after logging in.
 * If none is found, it will default to the home page. The redirect
 * is not executed, the URL is returned. Use `useAuthRedirect()` to
 * directly execute the redirect as well.
 */
export function useAuthRedirectUrl() {
  const location = useLocation<{ from?: Location }>()
  const searchParams = new URLSearchParams(location.search)
  let redirectTo: string | null | undefined

  // Initialize redirect URL to router state and convert to string
  const stateFrom = location.state?.from
  if (stateFrom != null) {
    redirectTo = createPath(stateFrom)
  }

  // Fall back to the redirect URL from query params
  if (redirectTo == null) {
    redirectTo = searchParams.get('redirectTo')
  }

  // Fall back to the state field from WorkOS, which can contain a redirect URL as well
  if (redirectTo == null) {
    redirectTo = searchParams.get('state')
  }

  // Prevent redirects to external URLs
  if (redirectTo != null && redirectTo.indexOf('http') === 0) {
    const host = new URL(redirectTo).host
    if (!isCmsHostname(host) && host !== window.location.host) {
      redirectTo = null
    }
  }

  // Prevent endless loop on login and fallback to home page
  if (redirectTo === LOGIN_PATH || redirectTo == null) {
    redirectTo = HOME_PATH
  }

  return redirectTo
}

/**
 * useIsCmsRedirect checks whether the current redirect URL is
 * a CMS redirect URL.
 */
export function useIsCmsRedirect() {
  try {
    const redirectUrl = useAuthRedirectUrl()
    const host = new URL(redirectUrl).host

    return isCmsHostname(host)
  } catch (error) {
    // Ignore error, returns false anyway
  }

  return false
}

/**
 * useAuthRedirect uses `useAuthRedirectUrl` to get the redirect URL
 * and directly redirect the user.
 */
export function useAuthRedirect() {
  const redirectTo = useAuthRedirectUrl()
  const history = useHistory()

  return useCallback(() => {
    // For example the browser extensions has redirect url https://*.chromiumapp.org
    if (redirectTo.startsWith('http')) {
      window.location.replace(redirectTo)
    } else {
      history.replace(redirectTo)
    }
  }, [history, redirectTo])
}

/**
 * Automatically redirects on mount when the user is already logged
 * in. Uses the `useAuthRedirect` logic. Combines `useIsAuthenticated`
 * with trying to fetch the user profile to confirm whether the user
 * is actually logged in.
 */
export function useAutoAuthRedirect() {
  const isAuthenticated = useIsAuthenticated()
  const redirectUser = useAuthRedirect()
  const userProfile = useUserProfileIgnoreAuthError()

  // Redirect user if already authenticated
  useEffect(() => {
    const hasUserProfile =
      !userProfile.loading && userProfile?.data != null && userProfile.error == null
    if (isAuthenticated && hasUserProfile) {
      redirectUser()
    }
  }, [isAuthenticated, redirectUser, userProfile])
}

import { ConferenceIcon } from '@gain/components/icons'
import { SearchResultItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import React from 'react'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { generateConferencePagePath } from '../../../conference/conference-path'

export interface SearchResultListItemConferenceEditionProps {
  item: SearchResultItem
}

export default function SearchResultListItemConferenceEdition({
  item,
}: SearchResultListItemConferenceEditionProps) {
  return (
    <MobileListItem key={`${item.type}-${item.id}`}>
      <MobileListItemButton
        as={Link}
        {...{
          href: generateConferencePagePath({
            conferenceId: item.id,
            conferenceName: item.name,
          }),
        }}>
        <MobileListItemIcon>
          <Logo
            defaultIcon={<ConferenceIcon />}
            size={40}
            src={item.imageUrl}
          />
        </MobileListItemIcon>
        <MobileListItemText
          primary={item.name}
          secondary={'Conference'}
        />
      </MobileListItemButton>
    </MobileListItem>
  )
}

import { AssetListItem } from '@gain/rpc/app-model'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import React from 'react'

import TooltipAsset from '../../../features/asset/asset-tooltip'
import { generateAssetPagePath } from '../../utils'

interface ParentButtonProps {
  parent: AssetListItem | null
}

export default function ParentButton({ parent }: ParentButtonProps) {
  if (parent === null) {
    return null
  }

  return (
    <TooltipAsset id={parent.id}>
      <Button
        component={Link}
        href={generateAssetPagePath({
          id: parent.id,
          name: parent.name,
        })}
        variant={'inline'}>
        {parent.name}
      </Button>
    </TooltipAsset>
  )
}

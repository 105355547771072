import Typography from '@gain/components/typography'
import { useElementWidth } from '@gain/utils/dom'
import { Source } from '@gain/utils/source'
import { styled } from '@mui/material/styles'
import { forwardRef, useMemo, useRef } from 'react'

import { sortSourcesOnDate } from './list-sources.utils'
import ListSourcesItem from './list-sources-item'
import ListSourcesTable, { listSourcesTableClasses } from './list-sources-table'

export interface SourceProps {
  sources: Source[]
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  paddingTop: theme.spacing(2),
}))

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderRadius: 8,
  overflow: 'clip',
}))

const StyledSourcesTable = styled(ListSourcesTable)(({ theme }) => ({
  [`& .${listSourcesTableClasses.head} .${listSourcesTableClasses.cell}`]: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
  },
}))

const StyledList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}))

const ListSources = forwardRef<HTMLDivElement, SourceProps>(function ListSources({ sources }, ref) {
  const containerRef = useRef<HTMLDivElement>(null)
  const width = useElementWidth(containerRef)

  const sortedSources = useMemo(() => sources.sort(sortSourcesOnDate), [sources])

  return (
    <Root ref={ref}>
      <Typography variant={'h5'}>Sources</Typography>
      <Container ref={containerRef}>
        {width > 888 && (
          <StyledSourcesTable
            backgroundColor={'grey'}
            sources={sortedSources}
          />
        )}
        {width <= 888 && (
          <StyledList>
            {sources.map((source, index) => (
              <ListSourcesItem
                key={index}
                source={source}
              />
            ))}
          </StyledList>
        )}
      </Container>
    </Root>
  )
})

export default ListSources

import { createContext, useCallback, useContext, useMemo } from 'react'

import { CheckboxListValue, CheckboxListValueType } from './checkbox-list'

export interface CheckboxListContextType<ValueType extends CheckboxListValueType> {
  value: CheckboxListValue<ValueType>
  addCheckboxValue: (value: ValueType) => void
  removeCheckboxValue: (value: ValueType) => void
  addCheckboxValues: (values: ValueType[]) => void
  removeCheckboxValues: (values: ValueType[]) => void
}

export const CheckboxListContext =
  createContext<CheckboxListContextType<CheckboxListValueType> | null>(null)

export interface UseCheckboxListContextProps<ValueType extends CheckboxListValueType> {
  value: CheckboxListValue<ValueType>
  onChange?: (value: CheckboxListValue<ValueType>) => void
}

export function useCheckboxListContext<ValueType extends CheckboxListValueType>(
  props: UseCheckboxListContextProps<ValueType>
): CheckboxListContextType<ValueType> {
  const handleChange = useCallback(
    (value: ValueType[]) => {
      if (!props.onChange) {
        return
      }
      if (value.length > 0) {
        props.onChange(value)
      } else {
        props.onChange(null)
      }
    },
    [props]
  )

  const value = useMemo(() => props.value || [], [props.value])

  const addCheckboxValue = useCallback(
    (newValue: ValueType) => handleChange([...value, newValue]),
    [handleChange, value]
  )
  const removeCheckboxValue = useCallback(
    (valueToRemove: ValueType) => {
      const copy = value.slice()
      copy.splice(copy.indexOf(valueToRemove), 1)
      handleChange(copy)
    },
    [handleChange, value]
  )

  const addCheckboxValues = useCallback(
    (newValues: ValueType[]) => handleChange([...value, ...newValues]),
    [handleChange, value]
  )

  const removeCheckboxValues = useCallback(
    (valuesToRemove: ValueType[]) => {
      const copy = value.slice()
      valuesToRemove.forEach((valueToRemove) => copy.splice(copy.indexOf(valueToRemove), 1))
      handleChange(copy)
    },
    [handleChange, value]
  )

  return useMemo(
    () => ({
      value,
      addCheckboxValue,
      removeCheckboxValue,
      addCheckboxValues,
      removeCheckboxValues,
    }),
    [addCheckboxValue, removeCheckboxValue, addCheckboxValues, removeCheckboxValues, value]
  )
}

export function useCheckboxList<ValueType extends CheckboxListValueType>() {
  const context = useContext(CheckboxListContext)

  if (!context) {
    throw new Error('CheckboxListContext not provided')
  }

  return context as unknown as CheckboxListContextType<ValueType>
}

import { DealSideShare } from '@gain/rpc/shared-model'
import { compareNumberDesc, valueToEnum } from '@gain/utils/common'

import { compareDealShareDesc } from './compare-deal-share-desc'
import { DealListItemParty } from './deal-party.model'

export function compareDealListItemPartyByShareAndOrderDesc(
  a: DealListItemParty,
  b: DealListItemParty
) {
  const sharePctCompare = compareNumberDesc(a.sharePct, b.sharePct)
  if (sharePctCompare !== 0) {
    return sharePctCompare
  }

  const shareCompare = compareDealShareDesc(
    valueToEnum(DealSideShare, a.share),
    valueToEnum(DealSideShare, b.share)
  )
  if (shareCompare !== 0) {
    return shareCompare
  }

  return 0
}

import { ListItemKey } from '@gain/rpc/list-model'
import { createContext, ReactNode, useContext } from 'react'

import { FilterApi } from './filter-bar/filter-bar-api'

export const FilterContext = createContext<FilterApi | null>(null)

export function FilterContextProvider<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ value, children }: { value: FilterApi<Item, FilterField> | null; children: ReactNode }) {
  return (
    <FilterContext.Provider value={value as unknown as FilterApi}>
      {children}
    </FilterContext.Provider>
  )
}

export function useFilterContext<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>() {
  const context = useContext(FilterContext)

  return context as unknown as FilterApi<Item, FilterField>
}

import type { Rating } from '../rating-model'
import type { RatingEsgKey } from './rating-esg'
import { ratingEsgOverall } from './rating-esg-constants'

export interface FormatEsgRatingOptions {
  emptyValue?: string
}

export function formatEsgRating(
  value: number | null | undefined,
  rating: Rating<RatingEsgKey>,
  { emptyValue = '-' }: FormatEsgRatingOptions = {}
) {
  if (typeof value !== 'number') {
    return emptyValue
  }

  // Round to nearest .5 to make sure we get can match one of the available options
  const rounded = Math.round(value * 2) / 2
  const option = rating.options.find((item) => item.value === rounded)

  if (option) {
    return option.label
  }

  return emptyValue
}

export function formatEsgOverallRating(
  value: number | null | undefined,
  options?: FormatEsgRatingOptions
) {
  return formatEsgRating(value, ratingEsgOverall, options)
}

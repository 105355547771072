import { Components, Theme } from '@mui/material/styles'

export function MuiSlider(_: Theme): Components['MuiSlider'] {
  return {
    defaultProps: {
      size: 'medium',
    },
    styleOverrides: {
      root: { height: 3, padding: '6.5px 0' },
      thumb: {
        width: 16,
        height: 16,
      },
      track: {
        borderWidth: 0,
      },
    },
  }
}

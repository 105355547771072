import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import Stack from '@mui/material/Stack'
import React from 'react'

import MainContainer from '../../common/main-container'
import TabPageTitle from '../../common/tab-page-title'
import { UserSessionsView } from './user-sessions-view'

export function UserSessions() {
  return (
    <MainContainer>
      <Head>
        <title>User sessions</title>
      </Head>

      <Stack
        direction={'row'}
        flexGrow={1}>
        <Stack
          flexGrow={1}
          maxWidth={'100%'}>
          <Stack
            direction={'column'}
            flexGrow={1}
            spacing={2}>
            <Stack
              alignItems={'center'}
              direction={'row'}
              justifyContent={'space-between'}>
              <TabPageTitle>User sessions</TabPageTitle>
              <Stack
                direction={'row'}
                spacing={1}>
                <SlotHome slotName={SlotName.Tabs} />
              </Stack>
            </Stack>
            <UserSessionsView />
          </Stack>
        </Stack>
      </Stack>
    </MainContainer>
  )
}

import { useUserProfileContext } from '@gain/modules/user'
import { ListItemKey } from '@gain/rpc/list-model'
import { UserUnitSystem } from '@gain/rpc/shared-model'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { useForm } from 'react-hook-form'
import { DeepPartial } from 'react-hook-form/dist/types/utils'

import { FullWidthForm, SliderInput } from '../../../../common/form'
import { FilterConfigGeoPoint, FilterGeoPointValue } from '../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../use-track-filter-event'
import FilterGeoPointAutocomplete from './filter-geo-point-autocomplete'
import FilterGeoPointMap from './filter-geo-point-map'
import {
  distanceToMeters,
  FilterGeoPointFormValues,
  formatRadius,
  formValueToFilterValue,
  geoPointFilterValueToFormValue,
} from './filter-geo-point-util'

export interface FilterGeoPointProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigGeoPoint<Item, FilterField>
}

export default function FilterGeoPoint<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterGeoPointProps<Item, FilterField>) {
  const userProfile = useUserProfileContext()
  const trackEvent = useTrackFilterEvent()
  const [showRadius, setShowRadius] = useState(false)

  // We still use react-final-form to manage the filter bar form
  const field = useField<FilterGeoPointValue>(path)

  // Use react-hook-form for internal state of this filter
  const { control, watch, formState, setValue, getValues, reset } =
    useForm<FilterGeoPointFormValues>({
      defaultValues: {
        point: null,
        distance: 5,
      },
      mode: 'all',
    })

  // Update internal state on external change
  useEffect(() => {
    const currentValue = formValueToFilterValue(
      getValues(),
      distanceToMeters[userProfile.unitSystem]
    )

    if (!isEqual(field.input.value, currentValue)) {
      const nextValue = geoPointFilterValueToFormValue(
        field.input.value,
        distanceToMeters[userProfile.unitSystem]
      )
      reset(nextValue)
      setShowRadius(!!nextValue?.point)
    }
  }, [field.input.value, setValue, getValues, reset, userProfile.unitSystem])

  // Update external state on internal change
  useEffect(() => {
    const subscription = watch((value: DeepPartial<FilterGeoPointFormValues>) => {
      if (value?.point) {
        const currentValue = formValueToFilterValue(value, distanceToMeters[userProfile.unitSystem])
        field.input.onChange(currentValue)
        trackEvent(`Filter ${filter.label} value change`, value.point?.location || '', null)
      }
      setShowRadius(!!value?.point)
    })
    return () => subscription.unsubscribe()
  }, [watch, formState, field.input, getValues, trackEvent, filter.label, userProfile.unitSystem])

  return (
    <Stack spacing={1}>
      <FilterGeoPointAutocomplete control={control} />
      <Collapse in={showRadius}>
        <FullWidthForm>
          <FilterGeoPointMap
            lat={field.input.value?.lat}
            lng={field.input.value?.lon}
            radius={field.input.value?.distance}
          />
          <SliderInput
            control={control}
            label={'City radius search'}
            max={userProfile.unitSystem === UserUnitSystem.Miles ? 200 : 300}
            min={1}
            name={'distance'}
            valueLabelFormat={(value) => formatRadius(value, userProfile)}
          />
        </FullWidthForm>
      </Collapse>
    </Stack>
  )
}

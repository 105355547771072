import { useMeasure } from 'react-use'
import { UseMeasureRef } from 'react-use/lib/useMeasure'

/**
 * Hook that returns a ref and the available visible height of the container.
 *
 * The available height is the height of the container excluding the scrollable height.
 */
export function useVisibleContainerHeight<E extends Element = Element>(): [
  UseMeasureRef<E>,
  number
] {
  const [ref, { height }] = useMeasure<E>()

  // Get the height of the container excluding the scrollable height
  const scrollableHeight =
    document.documentElement.scrollHeight - document.documentElement.clientHeight
  const availableHeight = height - scrollableHeight

  return [ref, availableHeight]
}

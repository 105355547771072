import { Sector, Subsector } from '@gain/utils/sector'

export const getSectorAndSubsectorNames = (
  subsectorNames: string[],
  allSectors: ReadonlyArray<Sector>,
  allSubsectors: ReadonlyArray<Subsector>
) => {
  const activeSectors = allSectors.filter((sector) => {
    const sectorSubsectors = allSubsectors.filter((subsector) => subsector.sector === sector.name)
    const matchingSubsectors = sectorSubsectors.filter((subsector) =>
      subsectorNames.includes(subsector.name)
    )
    return matchingSubsectors.length === sectorSubsectors.length
  })

  const remainingSubsectors = subsectorNames
    .map((subsectorName) => allSubsectors.find((subsector) => subsector.name === subsectorName))
    .filter(Boolean)
    .map((subsector) => subsector as Subsector)
    .filter(
      (subsector) => activeSectors.find((sector) => sector.name === subsector.sector) === undefined
    )

  return [
    ...activeSectors.map((sector) => sector.title),
    ...remainingSubsectors.map((subsector) => subsector.title),
  ]
}

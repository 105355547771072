import { ListItemKey } from '@gain/rpc/list-model'

import { FilterValueGroup, FilterValueItem } from './filter-value-model'

export function filterValueGroup<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(...items: FilterValueItem<Item, FilterField>[]): FilterValueGroup<Item, FilterField> {
  return {
    value: items,
  }
}

export function filterValueItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filterId: FilterField,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any = null // TODO: add typing
): FilterValueItem<Item, FilterField> {
  return {
    filterId,
    value,
  }
}

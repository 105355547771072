export function isNetherlands(region: string | null) {
  return region === 'NL'
}

export function isIreland(region: string | null) {
  return region === 'IE'
}

export function isGermany(region: string | null) {
  return region === 'DE'
}

export function isFrance(region: string | null) {
  return region === 'FR'
}

export function isUnitedKingdom(region: string | null) {
  return region === 'GB'
}

export function isBelgium(region: string | null) {
  return region === 'BE'
}

export function isSpain(region: string | null) {
  return region === 'ES'
}

export function isItaly(region: string | null) {
  return region === 'IT'
}

export function isUS(region: string | null) {
  return region === 'US'
}

export function isDenmark(region: string | null) {
  return region === 'DK'
}

export function isFinland(region: string | null) {
  return region === 'FI'
}

import { styled } from '@mui/material/styles'

export const ChartTooltipTable = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
})

export const ChartTooltipLabelCell = styled('td')(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 4,
  color: theme.palette.grey[500],
  verticalAlign: 'middle',
}))

export const ChartTooltipDataCell = styled('td')(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: 2,
  paddingBottom: 2,
  textAlign: 'right',
  verticalAlign: 'middle',
}))

export const ChartTooltipDivider = styled('div')(({ theme }) => ({
  padding: 0,
  marginTop: 2,
  marginBottom: 4,
  height: 1,
  backgroundColor: theme.palette.grey[700],
}))

import { AssetBusinessActivityType } from '@gain/rpc/shared-model'

export const formatAssetBusinessActivityLabel = (value: AssetBusinessActivityType): string => {
  switch (value) {
    case AssetBusinessActivityType.Manufacturing:
      return 'Manufacturing'
    case AssetBusinessActivityType.Design:
      return 'Design'
    case AssetBusinessActivityType.Distribution:
      return 'Distribution'
    case AssetBusinessActivityType.Engineering:
      return 'Engineering'
    case AssetBusinessActivityType.Retail:
      return 'Retail'
    case AssetBusinessActivityType.Operator:
      return 'Operator'
    case AssetBusinessActivityType.ResearchAndDevelopment:
      return 'R&D'
    case AssetBusinessActivityType.Services:
      return 'Services'
    case AssetBusinessActivityType.Other:
      return 'Other'
  }
}

export const grey = {
  50: '#F7F9FA',
  100: '#EFF2F4',
  200: '#E7E9EA',
  300: '#D7D9DA',
  400: '#B3B5B6',
  500: '#939596',
  600: '#6B6C6D',
  700: '#57595A',
  800: '#393A3B',
  900: '#191A1B',
}

import { formatMultiple } from './format-multiple'

/**
 * formatRatio returns the formatted ratio value or 'N/A'
 * if it's empty.
 */
export function formatRatio(value: number | null) {
  return formatMultiple(value, {
    round: 1,
    emptyValue: 'N/A',
  })
}

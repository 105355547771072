import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import TableHead, { TableHeadProps } from '@mui/material/TableHead'
import { tableRowClasses } from '@mui/material/TableRow'
import { forwardRef } from 'react'

import { VirtualTableVariant } from './virtual-table'

export interface VirtualTableHeadProps {
  width: number
  variant?: VirtualTableVariant
}

const propNames = ['width', 'variant']

export const VirtualTableHead = styled(
  forwardRef<HTMLDivElement, TableHeadProps>((props, ref) => (
    <TableHead
      ref={ref}
      component={'div'}
      {...props}
    />
  )),
  {
    shouldForwardProp: (prop) => !propNames.includes(prop as string),
  }
)<VirtualTableHeadProps>(({ width, variant, theme }) => ({
  display: 'block',
  zIndex: 4,
  position: 'sticky',
  top: 0,
  width: width,
  minWidth: width,
  maxWidth: width,
  height: 48,

  ...(variant === 'inline' && {
    backgroundColor: theme.palette.common.white,
    height: 40,
    [`& .${tableRowClasses.head} > .${tableCellClasses.root}`]: {
      backgroundColor: theme.palette.common.white,
    },
  }),
}))

import { SearchItemType } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import Card, { CardProps } from '../../../../common/card/card'
import Skeleton from '../../../../common/skeleton'
import { useSearchAPI } from '../../search-hooks'
import SearchTopResultAdvisor from './top-result/search-top-result-advisor'
import SearchTopResultAsset from './top-result/search-top-result-asset'
import SearchTopResultConferenceEdition from './top-result/search-top-result-conference-edition'
import SearchTopResultIndustry from './top-result/search-top-result-industry'
import SearchTopResultInvestor from './top-result/search-top-result-investor'
import SearchTopResultLegalEntity from './top-result/search-top-result-legal-entity'
import SearchTopResultSkeleton from './top-result/search-top-result-skeleton'
import { useSearchTopResultApi } from './use-search-top-result-api'

export const StyledContainer = styled(Card)<CardProps>(({ theme, href }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.palette.shadow.level1b,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),

  '&:hover': {
    boxShadow: theme.palette.shadow.level1a,
  },

  // Disable the links if we are still loading
  ...(href === '#' && {
    pointerEvents: 'none',
  }),
}))

export default function SearchResults() {
  const {
    searchingOther,
    result: { topResult },
  } = useSearchAPI()

  const topResultApi = useSearchTopResultApi()

  if (topResultApi.noResult) {
    return null
  }

  const isTopResultAnAsset = [SearchItemType.AutomatedAsset, SearchItemType.CuratedAsset].includes(
    topResult?.type as SearchItemType
  )

  return (
    <div>
      <StyledContainer href={topResultApi.to}>
        <Skeleton enabled={searchingOther}>
          <Typography
            sx={{ mb: 2 }}
            variant={'h5'}>
            Top result
          </Typography>
        </Skeleton>

        {topResultApi.searchingOther && <SearchTopResultSkeleton />}

        {isTopResultAnAsset && (
          <SearchTopResultAsset
            asset={topResultApi.asset}
            assetListItem={topResultApi.assetListItem}
          />
        )}
        {topResult?.type === SearchItemType.Industry && (
          <SearchTopResultIndustry industry={topResultApi.industry} />
        )}
        {topResult?.type === SearchItemType.Entity && (
          <SearchTopResultLegalEntity legalEntity={topResultApi.legalEntity} />
        )}
        {topResult?.type === SearchItemType.Investor && (
          <SearchTopResultInvestor investor={topResultApi.investor} />
        )}
        {topResult?.type === SearchItemType.Advisor && (
          <SearchTopResultAdvisor advisor={topResultApi.advisor} />
        )}
        {topResult?.type === SearchItemType.ConferenceEdition && (
          <SearchTopResultConferenceEdition conferenceEdition={topResultApi.conferenceEdition} />
        )}
      </StyledContainer>
    </div>
  )
}

import Head from '@gain/modules/head'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'

import { ListViewInvestor } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'

export default function InvestorListPageInvestorTab() {
  const isXs = useIsXs()
  useTrackPageView('investor', { tab: 'investors', requireTab: true, list: true })

  return (
    <>
      <Head>
        <title>Investors</title>
      </Head>
      {isXs && (
        <MobilePageHeader
          title={'Investors'}
          variant={'list'}
        />
      )}
      <ListViewInvestor />
    </>
  )
}

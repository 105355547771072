import { isTruthy } from '@gain/utils/common'
import { RowType } from '@gain/utils/table'

import { ColumnConfig } from './virtual-table-model'

type CreateColumnConfigType<Row extends RowType> = ColumnConfig<Row> | undefined | null | false

export function createVirtualTableColumns<Row extends RowType>(
  ...columns: CreateColumnConfigType<Row>[]
): ColumnConfig<Row>[] {
  return columns.filter(isTruthy)
}

import { CurrencyListItem } from '@gain/rpc/app-model'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'

/**
 * Returns "NOK m" if currency display is "code", or "€m" if currency
 * display is "symbol".
 */
export function currencyDisplayedAs(currency: CurrencyListItem) {
  let displayValue = currencySymbol(currency)
  if (currency.display === CurrencyDisplayType.Code) {
    displayValue += ' '
  }

  return `${displayValue}m`
}

/**
 * Returns "Danish Krone (DKK)" if currency display is "code", or
 * "Euro (€)" if currency display is "symbol".
 */
export function currencyFullName(currency: CurrencyListItem) {
  if (currency.display === CurrencyDisplayType.Symbol) {
    return `${currency.title} (${currency.symbol})`
  }

  return `${currency.title} (${currencySymbol(currency)})`
}

/**
 * Returns "DKK" if currency display is "code", or "€" if currency
 * display is "symbol".
 */
export function currencySymbol(currency: CurrencyListItem) {
  if (currency.display === CurrencyDisplayType.Symbol) {
    return currency.symbol
  }

  return currency.name // Name actually contains the ISO code
}

import { AssetListItem } from '@gain/rpc/app-model'

// This is used to map filters to the asset table column name that do
// not map 1-to-1 with a table column field.
const filterToAssetTableColumnMapping: Partial<
  Record<keyof AssetListItem, Array<keyof AssetListItem>>
> = {
  fteRangeCategory: ['fte'],
  ownerIds: ['ownerNames', 'majorityOwnerName'], // Show both minority and majority investor columns
  relevanceRank: ['tagIds'],
  headquartersRegion: ['region'],
  headquartersWgs84LngLat: ['region'],
}

export default filterToAssetTableColumnMapping

import { dialogClasses } from '@mui/material/Dialog'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { paperClasses } from '@mui/material/Paper'
import { Components, Theme } from '@mui/material/styles'

import { DialogTransition } from './dialog-transition/dialog-transition'

export const dialogTransitionClasses = generateUtilityClasses('DialogTransition', [
  'entering',
  'entered',
  'exiting',
  'exited',
  'unmounted',
])

export const dialogTransitionProps = {
  duration: 300,
  delay: 100,
  distance: 15,
}

const scaleDialogs = (originNode: HTMLElement, mode: 'enter' | 'exit') => {
  const dialogs = Array.from(
    document.querySelectorAll<HTMLDivElement>(`.${dialogClasses.container} > .${paperClasses.root}`)
  )
    .slice()
    .reverse()
    .filter((element) => !originNode.contains(element))

  if (mode === 'enter') {
    dialogs.forEach(
      (element, index) => (element.style.transform = `scale(${1 - (index + 1) / 20})`)
    )
  } else {
    dialogs.forEach((element, index) => (element.style.transform = `scale(${1 - index / 20})`))
  }
}

export const MuiDialog = (theme: Theme): Components['MuiDialog'] => ({
  defaultProps: {
    PaperProps: {
      elevation: 0,
      onClick: (event) => event.stopPropagation(), // see https://github.com/mui/material-ui/issues/29060#issuecomment-943617752
    },
    TransitionComponent: DialogTransition,
    TransitionProps: {
      onEntering: (node) => scaleDialogs(node, 'enter'),
      onExiting: (node) => scaleDialogs(node, 'exit'),
    },
  },
  styleOverrides: {
    container: {
      [`&.${dialogTransitionClasses.entering} .${dialogClasses.paper}`]: {
        transition: [
          theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: dialogTransitionProps.duration,
            delay: dialogTransitionProps.delay,
          }),
          theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            duration: dialogTransitionProps.duration * 0.4,
            delay: dialogTransitionProps.delay,
          }),
        ].join(','),
        opacity: 1,
        transform: 'translateY(0px)',
      },
      [`&.${dialogTransitionClasses.entered} .${dialogClasses.paper}`]: {
        opacity: 1,
        transform: 'translateY(0px)',
      },
      [`&.${dialogTransitionClasses.exiting} .${dialogClasses.paper}`]: {
        opacity: 0,
        transform: `translateY(${dialogTransitionProps.distance}px)`,
        transition: [
          theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: dialogTransitionProps.duration,
          }),
          theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            duration: dialogTransitionProps.duration * 0.6,
          }),
        ].join(','),
      },
      [`&.${dialogTransitionClasses.exited} .${dialogClasses.paper}`]: {
        opacity: 0,
        transform: `translateY(${dialogTransitionProps.distance}px)`,
      },
    },
    paper: {
      borderRadius: 16,
      border: 'none',
      minHeight: 'auto',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
})

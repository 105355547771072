import { chipClasses, ChipProps } from '@mui/material/Chip'
import { alpha, Components, CSSInterpolation, PaletteColor, Theme } from '@mui/material/styles'
import React from 'react'

import { ChipDeleteIcon } from './chip-delete-icon/chip-delete-icon'

export type ChipColor = 'primary' | 'warning' | 'error' | 'success' | 'info'

const createFilledColorStyle = (
  color: ChipColor,
  paletteColor: keyof PaletteColor,
  theme: Theme
): CSSInterpolation => ({
  backgroundColor: alpha(theme.palette[color].main, 0.14),
  color: theme.palette[color][paletteColor],
  [`&.${chipClasses.clickable}`]: {
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette[color].main, 0.28),
      color: theme.palette[color][paletteColor],
      [`& .${chipClasses.icon}`]: {
        color: 'inherit',
      },
    },
  },
  [`&.${chipClasses.sizeMedium} > .${chipClasses.deleteIcon}`]: {
    color: alpha(theme.palette[color].main, 0.2),
    '&::before': {
      backgroundColor: theme.palette[color].main,
    },
    '&:hover': {
      color: alpha(theme.palette[color].main, 0.2),
    },
  },
})

const createFilledVariant = (
  color: ChipColor,
  paletteColor: keyof PaletteColor,
  theme: Theme
): { props: Partial<ChipProps>; style: CSSInterpolation } => ({
  props: { variant: 'filled', color },
  style: createFilledColorStyle(color, paletteColor, theme),
})

const createOutlinedColorStyle = (
  color: ChipColor,
  paletteColor: keyof PaletteColor,
  theme: Theme
): CSSInterpolation => ({
  borderColor: theme.palette[color].main,
  color: theme.palette[color][paletteColor],
  [`&.${chipClasses.clickable}`]: {
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette[color].main, 0.28),
      color: theme.palette[color][paletteColor],
      [`& .${chipClasses.icon}`]: {
        color: 'inherit',
      },
    },
  },
})

const createOutlinedVariant = (
  color: ChipColor,
  paletteColor: keyof PaletteColor,
  theme: Theme
): { props: Partial<ChipProps>; style: CSSInterpolation } => ({
  props: { variant: 'outlined', color },
  style: createOutlinedColorStyle(color, paletteColor, theme),
})

export function MuiChip(theme: Theme): Components['MuiChip'] {
  return {
    defaultProps: {
      deleteIcon: <ChipDeleteIcon />,
    },
    styleOverrides: {
      root: {
        ...theme.typography.overline,
        height: 24,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey['100'],
      },
      deleteIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [`&.${chipClasses.deleteIconMedium}`]: {
          '&::before': {
            width: 10,
            height: 10,
          },
          fontSize: 16,
        },
      },
      sizeSmall: {
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '13px',
        height: 16,
        [`& .${chipClasses.icon}`]: {
          fontSize: '10px',
          marginLeft: 4,
        },
      },
      label: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      labelSmall: {
        paddingLeft: 6,
        paddingRight: 6,
      },
      icon: {
        fontSize: 12,
        color: 'inherit',
        marginLeft: 8,
        marginRight: -4,
      },
      clickable: {
        '&:hover': {
          backgroundColor: theme.palette.cards.asset,
          color: theme.palette.text.primary,
          [`& .${chipClasses.icon}`]: {
            color: 'inherit',
          },
        },
      },
      deletable: {
        '&:focus': {
          backgroundColor: '',
        },
      },
      outlined: {
        borderColor: theme.palette.divider,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.common.white,
      },
    },
    variants: [
      {
        props: { variant: 'filled', color: 'primary' },
        style: {
          '&&': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
        },
      },
      createFilledVariant('warning', 'dark', theme),
      createFilledVariant('error', 'dark', theme),
      createFilledVariant('success', 'main', theme),
      createFilledVariant('info', 'main', theme),
      createOutlinedVariant('primary', 'dark', theme),
      createOutlinedVariant('warning', 'dark', theme),
      createOutlinedVariant('error', 'dark', theme),
      createOutlinedVariant('success', 'dark', theme),
      createOutlinedVariant('info', 'main', theme),
    ],
  }
}

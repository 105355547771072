import {
  ratingInvestmentCriteriaGroups,
  RatingInvestmentCriteriaKey,
  ratingInvestmentCriteriaList,
  RatingMap,
} from '@gain/utils/investment-criteria'
import { useIsXs } from '@gain/utils/responsive'
import { median } from 'd3'
import { memo, useMemo } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import ListRating from '../../../features/investment-criteria/investment-criteria-list'
import TableRating from '../../../features/investment-criteria/investment-criteria-table'
import { InvestorStrategyRatings } from './investor-rating-utils'

interface InvestorInvestmentCriteriaCardProps {
  strategies: InvestorStrategyRatings[]
}

function InvestorInvestmentCriteriaCard({ strategies }: InvestorInvestmentCriteriaCardProps) {
  const isXs = useIsXs()
  const columns = useMemo(() => {
    return strategies.map((strategy) => ({
      title: strategy.name,
      subtitle: `${strategy.assets.length} ${
        strategy.assets.length === 1 ? 'company' : 'companies'
      }`,
      ratings: strategy.investmentCriteria,
    }))
  }, [strategies])

  const ratings = useMemo(() => {
    return ratingInvestmentCriteriaList.reduce((acc, current) => {
      return {
        ...acc,
        [current.key]: median(
          strategies.map((strategy) => strategy.investmentCriteria[current.key])
        ),
      }
    }, {} as RatingMap<RatingInvestmentCriteriaKey>)
  }, [strategies])

  return (
    <Card sx={{ pb: 0 }}>
      {!isXs && (
        <TableRating
          columns={columns}
          ratingGroups={ratingInvestmentCriteriaGroups}
          title={'Investment criteria'}
        />
      )}
      {isXs && (
        <>
          <CardHeader title={'Investment criteria'} />
          <CardContent sx={{ pb: 3 }}>
            <ListRating
              groups={ratingInvestmentCriteriaGroups}
              rating={ratings}
              variant={'default'}
            />
          </CardContent>
        </>
      )}
    </Card>
  )
}

export default memo(InvestorInvestmentCriteriaCard)

import { Rating, RatingOption, ratingOption } from '../rating-model'
import {
  RatingInvestmentCriteriaKey,
  RatingInvestmentCriteriaKeyWithOverall,
} from './rating-investment-criteria-model'

export const ratingBuyAndBuildOptions: RatingOption[] = [
  ratingOption(1, 'No acquisitions'),
  ratingOption(2, 'Not meaningful'),
  ratingOption(3, 'Incidentally'),
  ratingOption(4, 'Clear M&A record'),
  ratingOption(5, 'Continuous'),
]

export const ratingBuyAndBuild: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Buy-and-build',
  key: 'buyAndBuild',
  options: ratingBuyAndBuildOptions,
  explainer: 'Research team assessment based on the amount and importance of past M&A transactions',
}

export const ratingContractedOptions: RatingOption[] = [
  ratingOption(1, 'Hardly repeat business'),
  ratingOption(2, 'Transactional sales'),
  ratingOption(3, 'Some contracts'),
  ratingOption(4, 'Contract-based sales'),
  ratingOption(5, 'Budget locked in'),
]

export const ratingContracted: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Revenue visibility',
  key: 'contracted',
  options: ratingContractedOptions,
  explainer: 'Research team assessment based on the revenue generation model',
}

export const ratingConversionOptions: RatingOption[] = [
  ratingOption(0.5, '< 20%'),
  ratingOption(1, '20-40%'),
  ratingOption(1.5, '40-60%'),
  ratingOption(2, '60-70%'),
  ratingOption(2.5, '70-75%'),
  ratingOption(3, '75-80%'),
  ratingOption(3.5, '80-85%'),
  ratingOption(4, '85-90%'),
  ratingOption(4.5, '90-95%'),
  ratingOption(5, '> 95%'),
]

export const ratingConversion: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Cash conversion',
  key: 'conversion',
  suffix: 'of EBITDA',
  options: ratingConversionOptions,
  explainer:
    'Based on a 5-year average of (CAPEX-based) cash conversion, excluding one-offs, most notably covid-19',
}

export const ratingEbitdaOptions: RatingOption[] = [
  ratingOption(0.5, '< 0%'),
  ratingOption(1, '0-3%'),
  ratingOption(1.5, '3-6%'),
  ratingOption(2, '6-9%'),
  ratingOption(2.5, '9-12%'),
  ratingOption(3, '12-15%'),
  ratingOption(3.5, '15-20%'),
  ratingOption(4, '20-25%'),
  ratingOption(4.5, '25-30%'),
  ratingOption(5, '> 30%'),
]

export const ratingEbitda: Rating<RatingInvestmentCriteriaKey> = {
  label: 'EBITDA margin',
  key: 'ebitda',
  suffix: 'of revenue',
  options: ratingEbitdaOptions,
  explainer:
    'Based on a 5-year (adjusted) EBITDA average, excluding one-offs, most notably covid-19',
}

export const ratingGrossMarginOptions: RatingOption[] = [
  ratingOption(0.5, '< 10%'),
  ratingOption(1, '10-20%'),
  ratingOption(1.5, '20-30%'),
  ratingOption(2, '30-40%'),
  ratingOption(2.5, '40-50%'),
  ratingOption(3, '50-60%'),
  ratingOption(3.5, '60-70%'),
  ratingOption(4, '70-80%'),
  ratingOption(4.5, '80-90%'),
  ratingOption(5, '> 90%'),
]

export const ratingGrossMargin: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Gross margin',
  key: 'grossMargin',
  suffix: 'of revenue',
  options: ratingGrossMarginOptions,
  explainer: 'Based on a 5-year average, excluding one-offs, most notably covid-19',
}

export const ratingGrowthOptions: RatingOption[] = [
  ratingOption(0.5, '< 0%'),
  ratingOption(1, '0-1%'),
  ratingOption(1.5, '1-2%'),
  ratingOption(2, '2-3%'),
  ratingOption(2.5, '3-4%'),
  ratingOption(3, '4-6%'),
  ratingOption(3.5, '6-8%'),
  ratingOption(4, '8-10%'),
  ratingOption(4.5, '10-15%'),
  ratingOption(5, '> 15%'),
]

export const ratingGrowth: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Revenue growth',
  key: 'growth',
  suffix: 'growth',
  options: ratingGrowthOptions,
  explainer: 'Based on a 5-year CAGR, excluding one-offs, most notably covid-19',
}

export const ratingLeaderOptions: RatingOption[] = [
  ratingOption(1, 'Undifferentiated'),
  ratingOption(2, 'Limited differentiation'),
  ratingOption(3, 'Some USPs'),
  ratingOption(4, 'Top-3 player'),
  ratingOption(5, 'Clear market leader'),
]

export const ratingLeader: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Market position',
  key: 'leader',
  options: ratingLeaderOptions,
  explainer:
    'Research team assessment based on the competitive position factoring in geographic reach and competitive landscape',
}

export const ratingMultinationalOptions: RatingOption[] = [
  ratingOption(1, 'Regional'),
  ratingOption(2, 'Countrywide'),
  ratingOption(3, 'Limited int’l sales'),
  ratingOption(4, '>50% int’l sales'),
  ratingOption(5, 'Global'),
]

export const ratingMultinational: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Multinational',
  key: 'multinational',
  options: ratingMultinationalOptions,
  explainer:
    'Research team assessment based on % of revenue generated domestically vs. internationally',
}

export const ratingNonCyclicalOptions: RatingOption[] = [
  ratingOption(1, 'Highly cyclical'),
  ratingOption(2, 'Cyclical'),
  ratingOption(3, 'GDP-like'),
  ratingOption(4, 'Resilient'),
  ratingOption(5, 'Highly resilient'),
]

export const ratingNonCyclical: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Cyclicality',
  key: 'nonCyclical',
  options: ratingNonCyclicalOptions,
  explainer: 'Research team assessment based on exposure to economic and business cycles',
}

export const ratingOrganicGrowthOptions: RatingOption[] = [
  ratingOption(0.5, '< 0%'),
  ratingOption(1, '0-1%'),
  ratingOption(1.5, '1-2%'),
  ratingOption(2, '2-3%'),
  ratingOption(2.5, '3-4%'),
  ratingOption(3, '4-6%'),
  ratingOption(3.5, '6-8%'),
  ratingOption(4, '8-10%'),
  ratingOption(4.5, '10-15%'),
  ratingOption(5, '> 15%'),
]

export const ratingOrganicGrowth: Rating<RatingInvestmentCriteriaKey> = {
  label: 'Organic growth',
  key: 'organicGrowth',
  suffix: 'growth',
  options: ratingOrganicGrowthOptions,
  explainer:
    'Based on a 5-year CAGR, excluding the reported or estimated revenue of acquired/divested companies',
}

export const ratingOverallOptions: RatingOption[] = [
  ratingOption(0.5, '< 0%'),
  ratingOption(1, '0-1%'),
  ratingOption(1.5, '1-2%'),
  ratingOption(2, '2-3%'),
  ratingOption(2.5, '3-4%'),
  ratingOption(3, '4-6%'),
  ratingOption(3.5, '6-8%'),
  ratingOption(4, '8-10%'),
  ratingOption(4.5, '10-15%'),
  ratingOption(5, '> 15%'),
]

export const ratingOverall: Rating<RatingInvestmentCriteriaKeyWithOverall> = {
  label: 'Overall',
  key: 'overall',
  suffix: 'growth',
  options: ratingOverallOptions,
}

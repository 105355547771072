import * as Shared from '@gain/rpc/shared-model'
import { useTheme } from '@mui/material/styles'

export default function useFinancialTextColor(
  amountType = Shared.FinancialResultAmountType.Actual,
  isForecast = false,
  bold = false
) {
  const theme = useTheme()

  if (isForecast) {
    return theme.palette.text.secondary
  }

  // Otherwise determine color based on variant
  switch (amountType) {
    case Shared.FinancialResultAmountType.Actual:
      return bold ? theme.palette.text.primary : theme.palette.grey[700]
    case Shared.FinancialResultAmountType.Estimated:
      return theme.palette.info.main
    case Shared.FinancialResultAmountType.Ai:
      return theme.palette.warning.dark
  }
}

import Typography from '@gain/components/typography'
import { AssetRating, LegalEntity } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import {
  ratingInvestmentCriteriaGroups,
  RatingInvestmentCriteriaMap,
} from '@gain/utils/investment-criteria'
import Stack from '@mui/material/Stack'
import React, { useMemo, useRef } from 'react'

import Card, {
  CardContent,
  CardContentEmpty,
  CardHeader,
  CardSubHeader,
} from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import InfoButton from '../../../common/info-button'
import { generateLegalEntityPagePath } from '../../../routes/utils'
import InvestmentCriteriaList from '../investment-criteria-list'
import InvestmentCriteriaTable from './investment-criteria-table'

export interface InvestmentCriteriaCardProps {
  rating?: RatingInvestmentCriteriaMap | null
  automated?: boolean
  variant?: 'default' | 'grouped'
  ratingFromLegalEntity?: LegalEntity
  emptyMessage?: string
}

export default function InvestmentCriteriaCard({
  ratingFromLegalEntity,
  rating: ratingProp,
  variant = 'default',
  automated = false,
  emptyMessage = 'No investment criteria available',
}: InvestmentCriteriaCardProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  const rating = useMemo((): AssetRating => {
    return ratingProp as AssetRating
  }, [ratingProp])

  if (!rating) {
    return null
  }

  const isEmpty = Object.values(rating).filter((x) => x !== null).length === 0

  return (
    <Card
      ref={ref}
      sx={{ minHeight: 256 }}>
      <CardHeader
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={1}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              Investment criteria
            </Typography>

            {automated && (
              <InfoButton
                color={'warning'}
                dialogMessage={
                  'The investment criteria are automatically taken from a legal entity that has the same website as the company, and is located in the same region. If there are multiple entities, we use the one that has the most consolidated financials.'
                }
                dialogTitle={'Investment criteria source'}
                label={'Automated'}
                size={'medium'}
              />
            )}
          </Stack>
        }
      />

      {ratingFromLegalEntity && (
        <CardSubHeader>
          <Typography
            color={'text.secondary'}
            display={'flex'}
            flexDirection={'row'}
            variant={'body2'}>
            <Stack direction={['column', 'row']}>
              The investment criteria are retrieved from:&nbsp;
              <Stack
                component={'span'}
                direction={'row'}>
                <ContentLink
                  href={generateLegalEntityPagePath({
                    id: ratingFromLegalEntity.id,
                    name: ratingFromLegalEntity.name,
                  })}
                  label={ratingFromLegalEntity.name}
                />
                <InfoButton
                  dialogMessage={
                    'The investment criteria are automatically taken from a legal entity that has the same website as the company, and is located in the same region. If there are multiple entities, we use the one that has the most consolidated financials.'
                  }
                  dialogTitle={'Investment criteria source'}
                />
              </Stack>
            </Stack>
          </Typography>
        </CardSubHeader>
      )}

      {isEmpty ? (
        <CardContentEmpty message={emptyMessage} />
      ) : (
        <CardContent>
          <Stack
            direction={'column'}
            marginBottom={variant === 'default' ? 0 : 1}
            py={variant === 'default' ? 2 : 1}
            spacing={variant === 'default' ? 3 : 0}>
            {width >= 888 && (
              <InvestmentCriteriaTable
                rating={rating}
                variant={variant}
              />
            )}
            {width < 888 && (
              <InvestmentCriteriaList
                groups={ratingInvestmentCriteriaGroups}
                rating={rating}
                variant={variant}
              />
            )}
          </Stack>
        </CardContent>
      )}
    </Card>
  )
}

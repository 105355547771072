import Typography, { TypographyProps } from '@mui/material/Typography'

export default function ListPageTitle(props: Omit<TypographyProps, 'variant'>) {
  return (
    <Typography
      variant={'h2'}
      {...props}
    />
  )
}

import { checkboxClasses } from '@mui/material/Checkbox'
import { Components, Theme } from '@mui/material/styles'
import React from 'react'

import { CheckboxCheckedIcon } from './checkbox-checked-icon/checkbox-checked-icon'
import { CheckboxIcon } from './checkbox-icon/checkbox-icon'
import { CheckboxIndeterminateIcon } from './checkbox-indeterminate-icon/checkbox-indeterminate-icon'

export const MuiCheckbox = (theme: Theme): Components['MuiCheckbox'] => ({
  defaultProps: {
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />,
    color: 'default',
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: 8,
      color: '',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [`&.${checkboxClasses.checked}`]: {
        color: theme.palette.grey['700'],
      },
      [`&.${checkboxClasses.indeterminate}`]: {
        color: theme.palette.grey['700'],
      },
    },
  },
})

import { ArrowUpRightIcon, CompanyIcon } from '@gain/components/icons'
import { GlobalUltimateOwner } from '@gain/rpc/app-model'
import { addHttpsIfMissing } from '@gain/utils/common'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'

import { CardContent } from '../../../common/card/card'
import KeyValueList, { KeyValueListItem } from '../../../common/key-value/key-value-list'
import Table from '../../../common/table'
import ContentLinkLegalEntity from '../../../features/legal-entity/legal-entity-content-link'
import { UltimateOwnershipLink } from '../../asset'
import { ultimateOwnerColumns } from './legal-entity-ownership-columns'

export interface LegalEntityUltimateOwnerProps {
  legalEntityId: number
  globalUltimateOwner: GlobalUltimateOwner
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1, 0),
}))

export function LegalEntityUltimateOwner({
  legalEntityId,
  globalUltimateOwner,
}: LegalEntityUltimateOwnerProps) {
  const isSameLegalEntity = legalEntityId === globalUltimateOwner.linkedLegalEntityId
  const globalUltimateOwnerLink = useMemo(() => {
    if (isSameLegalEntity) {
      return (
        <Chip
          color={'info'}
          label={'This entity'}
        />
      )
    } else if (
      globalUltimateOwner.linkedLegalEntityId &&
      globalUltimateOwner.linkedLegalEntityName
    ) {
      return (
        <ContentLinkLegalEntity
          id={globalUltimateOwner.linkedLegalEntityId}
          name={globalUltimateOwner.linkedLegalEntityName}
          region={globalUltimateOwner.linkedLegalEntityRegion}
        />
      )
    }

    return (
      <UltimateOwnershipLink
        avatarProps={{ children: <CompanyIcon /> }}
        endIcon={globalUltimateOwner.webUrl && <ArrowUpRightIcon />}
        href={globalUltimateOwner.webUrl && addHttpsIfMissing(globalUltimateOwner.webUrl)}
        label={globalUltimateOwner.name}
        labelTypographyProps={{ disableTooltip: true }}
        region={globalUltimateOwner.region}
      />
    )
  }, [isSameLegalEntity, globalUltimateOwner])

  return (
    <>
      <CardContent>
        <KeyValueList>
          <KeyValueListItem
            label={'Ultimate owner'}
            value={globalUltimateOwnerLink}
          />
        </KeyValueList>
      </CardContent>
      <StyledCardContent>
        {isSameLegalEntity && globalUltimateOwner.subsidiaries.length !== 0 && (
          <Table
            columns={ultimateOwnerColumns}
            rows={globalUltimateOwner.subsidiaries}
          />
        )}
      </StyledCardContent>
    </>
  )
}

import { ArrowUpRightIcon } from '@gain/components/icons'
import { Asset } from '@gain/rpc/app-model'
import {
  formatAssetBusinessActivityLabel,
  formatAssetCustomerBaseLabel,
  formatAssetPricePositioningLabel,
  formatAssetSalesChannelLabel,
} from '@gain/utils/asset'
import {
  formatUrlHostname,
  hasArrayItems,
  isDefined,
  isTruthy,
  toHttpsUrl,
} from '@gain/utils/common'
import { formatCountryCity } from '@gain/utils/countries'
import { formatYear } from '@gain/utils/date'
import { useElementWidth } from '@gain/utils/dom'
import { formatNumber } from '@gain/utils/number'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useMemo, useRef } from 'react'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { KeyValueGridItem, useKeyValueGridKeys } from '../../../../common/key-value/key-value-grid'
import Stat from '../../../../common/stat'
import ButtonAlertEsgOutperformer from '../../../../features/esg/esg-outperformer-alert'
import { FormatSubSector } from '../../../../features/sector'
import AssetTags from '../../asset-summary/asset-business-card/asset-tags'
import { GrowthIndicator } from './growth-indicator.component'

const StyledCard = styled(Card)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

interface StyledCardContentProps {
  columns: number
}

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'columns',
})<StyledCardContentProps>(({ theme, columns }) => ({
  display: 'grid',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(2),
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
}))

const StyledKeyValueGridItem = styled(KeyValueGridItem)(({ theme }) => ({
  padding: 0,
  ...theme.typography.body2,
}))

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,

  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& svg': {
    fontSize: 16,
  },
})

export interface AssetBusinessDetailsCardProps extends CardProps {
  asset: Asset
}

export default function AssetBusinessDetailsCard({
  asset,
  ...cardProps
}: AssetBusinessDetailsCardProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  const columns = useMemo(() => {
    if (width > 1024) {
      return 5
    } else if (width > 832) {
      return 4
    } else if (width > 656) {
      return 3
    } else if (width > 440) {
      return 2
    }

    return 1
  }, [width])

  const latestFinancialResultWithFte = asset.financialResults
    .slice()
    .reverse()
    .find((item) => item.fte !== null)

  const keyValues = useKeyValueGridKeys(asset, [
    {
      field: 'generalInfo.headquartersAddress',
      label: 'Headquarters',
      valueFormatter: (data) =>
        formatCountryCity(
          data.generalInfo?.headquarters,
          data.generalInfo?.headquartersAddress?.city,
          data.generalInfo?.headquartersAddress?.region
        ),
    },
    {
      field: 'generalInfo.subsector',
      label: 'Sector',
      renderValue: (data) => <FormatSubSector subSector={data.generalInfo?.subsector} />,
    },
    {
      field: 'generalInfo.yearFounded',
      label: 'Year founded',
    },
    {
      field: 'fte',
      label: 'FTEs',
      renderValue: (data) =>
        (isDefined(data.generalInfo?.fte) ||
          isDefined(latestFinancialResultWithFte?.fte?.amount)) && (
          <Typography
            alignItems={'center'}
            color={'text.primary'}
            display={'flex'}
            variant={'inherit'}>
            {formatNumber(latestFinancialResultWithFte?.fte?.amount || asset.generalInfo?.fte)}
            {latestFinancialResultWithFte?.year && (
              <Chip
                label={formatYear(latestFinancialResultWithFte?.year, 'short')}
                size={'small'}
                sx={{ ml: 0.5 }}
              />
            )}
            {(latestFinancialResultWithFte?.fteGrowthPct?.amount ?? 0) > 0 &&
              latestFinancialResultWithFte?.fteGrowthPct?.amount && (
                <GrowthIndicator
                  growthPct={latestFinancialResultWithFte.fteGrowthPct.amount}
                  sx={{ ml: 0.5 }}
                />
              )}
          </Typography>
        ),
    },
    {
      field: 'generalInfo.customerBase',
      label: 'Customer base',
      valueFormatter: (data) =>
        data.generalInfo?.customerBase &&
        hasArrayItems(data.generalInfo?.customerBase) &&
        data.generalInfo?.customerBase.map(formatAssetCustomerBaseLabel).join(', '),
    },
    {
      field: 'generalInfo.businessActivity',
      label: 'Business activity',
      valueFormatter: (data) =>
        data.generalInfo?.businessActivity &&
        hasArrayItems(data.generalInfo?.businessActivity) &&
        data.generalInfo?.businessActivity.map(formatAssetBusinessActivityLabel).join(', '),
    },
    {
      field: 'generalInfo.salesChannel',
      label: 'Sales channel',
      valueFormatter: (data) =>
        data.generalInfo?.salesChannel &&
        hasArrayItems(data.generalInfo?.salesChannel) &&
        data.generalInfo?.salesChannel.map(formatAssetSalesChannelLabel).join(', '),
    },
    {
      field: 'generalInfo.pricePositioning',
      label: 'Price positioning',
      valueFormatter: (data) =>
        isDefined(data.generalInfo?.pricePositioning) &&
        formatAssetPricePositioningLabel(data.generalInfo?.pricePositioning),
    },
    {
      field: 'generalInfo.esgOutperformer',
      label: 'ESG assessment',
      renderValue: (data) =>
        isTruthy(data.generalInfo?.esgOutperformer) && (
          <Stack
            alignItems={'center'}
            direction={'row'}
            display={'flex'}>
            Outperformer <ButtonAlertEsgOutperformer />
          </Stack>
        ),
    },
    {
      field: 'generalInfo.url',
      label: 'Website',
      renderValue: (data) =>
        data.generalInfo?.url && (
          <StyledLink
            href={data.generalInfo.url}
            rel={'noopener noreferrer'}
            target={'_blank'}>
            <span>{formatUrlHostname(toHttpsUrl(data.generalInfo.url))}</span> <ArrowUpRightIcon />
          </StyledLink>
        ),
    },
    {
      field: 'generalInfo.linkedinExternalId',
      label: 'LinkedIn',
      renderValue: (data) =>
        data.generalInfo?.linkedinExternalId && (
          <StyledLink
            href={`https://linkedin.com/company/${data.generalInfo.linkedinExternalId}`}
            rel={'noopener noreferrer'}
            target={'_blank'}>
            <span>LinkedIn</span> <ArrowUpRightIcon />
          </StyledLink>
        ),
    },
  ])

  return (
    <StyledCard
      ref={ref}
      {...cardProps}>
      <CardHeader title={'Details'} />
      <StyledCardContent columns={columns}>
        {keyValues.map((keyValue) => (
          <StyledKeyValueGridItem
            key={keyValue.field}
            keyValue={keyValue}
          />
        ))}
      </StyledCardContent>

      {asset.tags && asset.tags.length > 0 && (
        <CardContent sx={{ mt: 2 }}>
          <Stat label={'Tags'}>
            <Stack mt={0.5}>
              <AssetTags
                assetTags={asset.tags}
                maxLines={10}
              />
            </Stack>
          </Stat>
        </CardContent>
      )}
    </StyledCard>
  )
}

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import React from 'react'

import DialogHeader from '../../../common/dialog-header'

interface ShareFeedbackInfoDialogProps {
  open: boolean
  onClose: () => void
}

export default function ShareFeedbackInfoDialog({ open, onClose }: ShareFeedbackInfoDialogProps) {
  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={onClose}
        title={'Why would I share my insights?'}
      />

      <DialogContent>
        <Typography
          variant={'body2'}
          gutterBottom>
          We do our very best to provide an outside in perspective on your assets. However, you hold
          a superior information position.
        </Typography>

        <Typography
          variant={'body2'}
          gutterBottom>
          With the majority of the ecosystem of potential buyers and their advisors now utilising us
          to develop a perspective on your assets, you can benefit from a description that is as
          accurate as possible. Even years before a potential exit, this allows relevant investors
          to warm up to your assets and accurately track them.
        </Typography>

        <Typography variant={'body2'}>
          Such engagement from your buyer field is likely to benefit valuations, either in a
          solicited or pro active (incl trade buyers) exit process.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

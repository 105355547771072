import {
  ratingEsgGroup,
  RatingEsgKey,
  ratingEsgList,
  RatingMap,
} from '@gain/utils/investment-criteria'
import { useIsXs } from '@gain/utils/responsive'
import { median } from 'd3'
import { memo, useMemo } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import ListRating from '../../../features/investment-criteria/investment-criteria-list'
import TableRating from '../../../features/investment-criteria/investment-criteria-table'
import { InvestorStrategyRatings } from './investor-rating-utils'

export interface InvestorEsgAssessmentCardProps {
  strategies: InvestorStrategyRatings[]
}

const InvestorEsgAssessmentCardContent = memo(function IndustryEsgAssessmentCardContent({
  strategies,
}: InvestorEsgAssessmentCardProps) {
  const isXs = useIsXs()
  const columns = useMemo(() => {
    return strategies.map((strategy) => ({
      title: strategy.name,
      subtitle: `${strategy.assets.length} ${
        strategy.assets.length === 1 ? 'company' : 'companies'
      }`,
      ratings: strategy.esg,
    }))
  }, [strategies])

  const ratings = useMemo(() => {
    return ratingEsgList.reduce((acc, current) => {
      return {
        ...acc,
        [current.key]: median(strategies.map((strategy) => strategy.esg[current.key])),
      }
    }, {} as RatingMap<RatingEsgKey>)
  }, [strategies])

  return (
    <Card sx={{ overflow: 'auto', pb: 0 }}>
      {isXs && <CardHeader title={'ESG assessment'} />}
      {!isXs && (
        <TableRating
          columns={columns}
          ratingGroups={[{ ...ratingEsgGroup, label: 'ESG' }]}
          title={'ESG assessment'}
        />
      )}
      {isXs && (
        <CardContent sx={{ pb: 3 }}>
          <ListRating
            groups={[{ ...ratingEsgGroup, label: 'ESG assessment' }]}
            rating={ratings}
            disableGroupLabel
          />
        </CardContent>
      )}
    </Card>
  )
})

export default InvestorEsgAssessmentCardContent

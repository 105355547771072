import { useMemo, useRef } from 'react'

import { chartColorSet } from '../chart-colors'
import { ChartGroup, ChartGroupByConfig } from './chart-group'

/**
 * Returns groups of items based on the groupByGroup configuration.
 *
 * If the groupByGroup has a compare function, the groups will be sorted based on
 * that. The ordering will be saved and used for future calls to ensure the same
 * ordering.
 */
export function useChartGroups<Data>(
  items: Data[],
  groupByGroup: ChartGroupByConfig<Data>
): ChartGroup<Data>[] {
  // Save the historic ordering of the groups to ensure the same ordering when
  // assets are added / removed from the chart.
  const historicOrder = useRef<{
    id: ChartGroupByConfig<Data>['id']
    groups: ChartGroup<Data>[]
  } | null>(null)

  return useMemo(() => {
    // Group the items based on the groupByGroup configuration.
    const groups = items.reduce((acc, item) => {
      const groupValue = groupByGroup.getValue(item)
      const groupIndex = acc.findIndex((group) => group.value === groupValue)
      if (groupIndex > -1) {
        acc[groupIndex].items.push(item)
      } else {
        acc.push({
          value: groupValue,
          label: groupByGroup.getLabel(groupValue),
          items: [item],
          color: chartColorSet[acc.length % chartColorSet.length],
        })
      }
      return acc
    }, new Array<ChartGroup<Data>>())

    if (groupByGroup.compare) {
      // Sort the groups based on the compare function.
      let orderedGroups = groups.sort(groupByGroup.compare).map((group, index) => ({
        ...group,
        color: chartColorSet[index % chartColorSet.length],
      }))

      // If we have a historic order for this groupByGroup, sort by that first.
      // This happens when assets are added / removed from the chart; we want to
      // keep the ordering to ensure groups have the same color and the legend
      // doesn't jump around.
      //
      // It could be that new groups were added; we simply append them to the end.
      const previousOrder = historicOrder.current
      if (previousOrder !== null && previousOrder?.id === groupByGroup.id) {
        let newOrderedGroups: ChartGroup<Data>[] = []

        for (const group of previousOrder.groups) {
          if (!newOrderedGroups.some(({ value }) => value === group.value)) {
            newOrderedGroups.push(group)
          }
        }

        newOrderedGroups = newOrderedGroups.concat(
          orderedGroups.filter(
            ({ value }) => !previousOrder.groups.some((group) => group.value === value)
          )
        )

        orderedGroups = newOrderedGroups
      }

      // Update the reference to the new ordering. We're using a ref here to
      // avoid re-rendering.
      historicOrder.current = { id: groupByGroup.id, groups: orderedGroups }

      return orderedGroups
    }

    return groups
  }, [items, groupByGroup])
}

import { compareNumberDesc } from '@gain/utils/common'

import { compareDealShareDesc } from './compare-deal-share-desc'
import { DealParty } from './deal-party.model'

export function compareDealPartyByShareAndOrderDesc(a: DealParty, b: DealParty) {
  if (a.leadingParty && !b.leadingParty) {
    return -1
  } else if (b.leadingParty && !a.leadingParty) {
    return 0
  }

  const sharePctCompare = compareNumberDesc(a.sharePct?.value, b.sharePct?.value)
  if (sharePctCompare !== 0) {
    return sharePctCompare
  }

  const shareCompare = compareDealShareDesc(a.share?.value, b.share?.value)
  if (shareCompare !== 0) {
    return shareCompare
  }

  return compareNumberDesc(a.order, b.order)
}

import { styled } from '@mui/material/styles'

import checkmark from './checkmark.svg'

export const CheckboxCheckedIcon = styled('span')({
  backgroundColor: 'currentColor',
  borderRadius: 4,
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundColor: '#FFFFFF',
    mask: `url(${checkmark}) no-repeat 50% 50%/12px 12px`,
    content: '""',
  },
})

import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import { dialogActionsClasses } from '@mui/material/DialogActions'
import { dialogContentClasses } from '@mui/material/DialogContent'
import { dialogContentTextClasses } from '@mui/material/DialogContentText'
import { dialogTitleClasses } from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'

export const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    minWidth: 320,
    maxWidth: 360,
    borderRadius: 16,
    minHeight: 0,
    height: 'auto',
  },

  [`& .${dialogTitleClasses.root}`]: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    ...theme.typography.h4,
    padding: theme.spacing(3, 3, 0),
  },

  [`& .${dialogContentClasses.root}`]: {
    paddingTop: 6,
    paddingBottom: theme.spacing(2),
  },

  [`& .${dialogContentTextClasses.root}`]: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    ...theme.typography.body2,
    marginTop: theme.spacing(0.5),
    marginBottom: 0,
  },

  [`& .${dialogActionsClasses.root}`]: {
    padding: theme.spacing(1, 3, 3, 3),
    border: 'none',
    background: 'none',
  },
}))

import { useTheme } from '@mui/material/styles'
import { CustomSeriesRenderItemReturn } from 'echarts/types/dist/shared'
import maxBy from 'lodash/maxBy'
import { useMemo } from 'react'

import { ECOption } from '../../../../common/echart/echart'
import {
  DIMENSION_BUY,
  DIMENSION_HOLD,
  DIMENSION_SELL,
  DIMENSION_TOTAL,
  MarketDataChartData,
} from './market-chart-types'
import {
  PRICE_CHART_VERTICAL_PADDING,
  RECOMMENDATION_CHART_HEIGHT,
  SHARE_PRICE_CHART_HEIGHT,
} from './market-data-chart'
import {
  calculateBrokerRecommendationBarDimensions,
  calculateYAxisLabelWidth,
  formatTick,
  isTick,
  renderBrokerRecommendationBarPart,
} from './market-data-chart-utils'
import { TimeRangeKey } from './time-range-picker'

/**
 * useMarketDataChartOption builds up the ECharts option that ECharts needs to
 * render the Market Data chart. The following list contains a couple of design
 * decisions made while getting to know ECharts:
 *
 *  - The data from the backend is corrected for missing data points
 *    - There are multiple legit scenarios where a data point for a business day
 *      can be missing, e.g. bank-holiday, temporary delisting or a backend issue
 *    - We inject empty data points for each missing business day
 *    - This keeps the broker recommendation logic simple by just calculating
 *      differences in business days
 *  - Stopped using the `time` x-axis in favour of the `category` axis
 *    - The `time` axis is relatively new in ECharts and does not have feature
 *      parity with the `category` axis
 *    - The `time` axis doesn't provide sufficient control over ticks and labels
 *    - The `time` axis is continuous, while our data is based on business days,
 *      which inherently means 5 days per week / 2 day gaps each week
 *    - It's very hard to align two `time` axes when the aggregation level is
 *      different. E.g. days for share price, weeks for broker recommendations.
 *  - Don't use ECharts' axis linking feature
 *    - It sounds good on paper, linking the share price and broker recommendation
 *      axes with an ECharts native feature, but it's quite flaky
 *    - It doesn't work well with our scenario of different aggregation levels,
 *      it results in a lot of edge cases and flaky behaviour
 *    - It always enforces 2-way linking, while in our case we want to highlight
 *      the broker recommendation if you hover the share price, but not the other
 *      way around.
 *  - The broker recommendation chart uses a custom series instead of bar series
 *    - Bar series assume that the bars will always have the same width
 *    - The x axes of our two charts have to perfectly align. But, the broker
 *      recommendation chart has different aggregation levels per selected time
 *      range. E.g. weeks. If today is halfway during a week, the last bar will
 *      always be a partial bar, not a full week. Therefore, the broker chart has
 *      to support bars of different widths. The only way to achieve that is by
 *      using a custom chart and building your own render logic.
 *  - The broker recommendation bars don't rely on the border radius feature
 *    - The individual parts of the bars (buy, hold, sell), can be 1 pixel high,
 *      when the value is 1%. In such case, you can not apply 3px border radius.
 *      You would still end up with a straight bar.
 *    - In regular HTML / DOM, you would wrap all 3 parts in another component
 *      and apply the border radius there.
 *    - Here we mimic that approach by drawing another custom series that is 1px
 *      bigger than the broker recommendation bars, with a transparent background
 *      and a white border with border radius. That gives us a consistent radius,
 *      regardless of how big the individual parts of the bar are.
 *  - Don't use the ECharts cross axis pointer
 *    - It can't snap to a point on a specific series. In fact, it will highlight
 *      all points on the Y axis that intersect the line. Therefore, you can end
 *      up focusing on multiple points on the series. This gets really confusing.
 *    - Briefly tried to overlay an SVG, which worked, but introduced too much
 *      fragile complexity to the code.
 *  - ECharts doesn't have a good API to disable a whole grid / chart
 *    - There's a `show` property, which intuitively handles showing / hiding a
 *      complete grid. This doesn't work. You have to arrange hiding it yourself.
 *    - The 'best' way to arrange this is by not adding the grid / axes / series
 *      to the option object at all. This will break up the current format of the
 *      option object significantly and decouple the share price and broker option.
 *      Both charts are inherently entangled and this would reduce the readability
 *      significantly. Now when you read the file below, you immediately see this
 *      is a special chart, as it has two datasets, two grids, etc.
 *    - When there's no broker data, nothing is drawn, making it effectively hidden
 *      already. Then changing the dimensions of the share price chart to span the
 *      whole chart 'solves' our issue without impairing code readability.
 *
 * Finally, if you want to quickly debug the horizontal alignment of both charts,
 * add this to both `xAxis` definitions:
 *
 * ```
 * axisTick: { show: true, interval: () => true },
 * ```
 */
export default function useMarketDataChartOption(
  data: MarketDataChartData,
  timeRange: TimeRangeKey,
  hasBrokerRecommendations: boolean
) {
  const theme = useTheme()

  return useMemo((): ECOption => {
    // Calculate the maximum price in the chart and bail out if there's no data
    const maxPricePoint = maxBy(data.sharePrice, (item) => item[1])
    if (data.sharePrice.length === 0 || !maxPricePoint || !maxPricePoint[1]) {
      return {}
    }

    // Pre-calculate some values
    const maxSharePrice = maxPricePoint[1]
    const yAxisLabelWidth = calculateYAxisLabelWidth(maxSharePrice)
    const firstDate = data.sharePrice[0][0]
    const firstTick = data.sharePrice.findIndex((dataPoint) => isTick(firstDate, dataPoint[0]))

    return {
      // The datasets that are used in these charts. Defining IDs and dimensions
      // allows us to refer to echart constructs and data points using names.
      //  - https://apache.github.io/echarts-handbook/en/concepts/dataset/
      dataset: [
        {
          id: 'share-price',
          source: data.sharePrice,
          dimensions: ['date', 'value'],
        },
        {
          id: 'broker-recommendations',
          source: data.brokerRecommendations,
          dimensions: ['startdate', 'enddate', 'buy', 'hold', 'sell', 'total'],
        },
      ],

      // This chart defines two grids, one for the share price chart and one for
      // the broker recommendation chart.
      //  - https://echarts.apache.org/en/option.html#grid
      grid: [
        // Share price grid
        {
          id: 'share-price-grid',
          containLabel: true,
          left: 0, // Pin to the left side
          top: theme.spacing(1), // Make sure the labels are not cut off
          right: theme.spacing(0.5), // Make sure the labels are not cut off

          // Pin the bottom of the chart to the start of the broker recommendations
          // or to the bottom of the chart if there's no broker data
          bottom: hasBrokerRecommendations ? RECOMMENDATION_CHART_HEIGHT : theme.spacing(2),
        },

        // Broker recommendations grid
        {
          id: 'broker-recommendations-grid',
          containLabel: true,
          left: 0, // Pin to the left side
          top: SHARE_PRICE_CHART_HEIGHT + PRICE_CHART_VERTICAL_PADDING,
          right: theme.spacing(0.5), // Make sure the labels are not cut off
          bottom: PRICE_CHART_VERTICAL_PADDING,
        },
      ],

      // Define X-axes for both the share price and broker recommendation chart
      //  - https://echarts.apache.org/en/option.html#xAxis
      xAxis: [
        // X-axis config for share price chart
        {
          id: 'share-price-x-axis',
          type: 'category',
          gridId: 'share-price-grid',

          // Make sure the ticks align exactly with the data points
          boundaryGap: false,

          // Customize X-axis ticks and labels
          axisLabel: {
            hideOverlap: true,
            // To prevent the first tick from being cut off to the left, don't
            // center it when it's within the first 20 data points
            alignMinLabel: firstTick < 20 ? 'left' : undefined,
            // Apply custom tick interval, depending on the selected time range
            interval: (number, value) => isTick(firstDate, parseInt(value, 10)),
            // Apply custom date formatting, depending on the selected time range
            formatter: (value, index) =>
              formatTick(firstDate, parseInt(value, 10), index === firstTick),
          },

          // Draw a vertical line on the X-axis that moves along with the data points
          axisPointer: {
            show: true,
            animation: false,
            z: 1,
            label: {
              show: false, // Don't show the data label on the Y-axis
            },
            lineStyle: {
              color: theme.palette.grey[800],
              type: [8, 6],
            },
          },
        },

        // X-axis config for broker recommendations chart
        {
          id: 'broker-recommendations-x-axis',
          type: 'category',
          gridId: 'broker-recommendations-grid',

          // Force this x-axis into a simple grid where the ticks are [0...n],
          // n being the number of items for the share price graph. This simplifies
          // the setup for drawing custom broker recommendation bars significantly.
          min: 0,
          max: data.sharePrice.length - 1,

          // Make sure the ticks align exactly with the data points
          boundaryGap: false,

          // Don't show any labels or ticks for this axis
          axisTick: { show: false },
          axisLabel: { show: false },
          axisLine: { show: false },
        },
      ],

      // Define both y-axes for both charts
      //  - https://echarts.apache.org/en/option.html#yAxis
      yAxis: [
        // Y-axis config for share price chart
        {
          id: 'share-price-y-axis',
          gridId: 'share-price-grid',
          position: 'right', // Show labels to the right
          type: 'value',

          // Don't start the Y axis at 0 to make better use of the Y axis space.
          // Take 20% of the amplitude of the graph and use that as min value.
          // The min value is stripped down to 2 decimals to prevent that ECharts
          // shows a wider label than the labels need.
          min: (value) =>
            Math.max(0, Math.floor((value.min - (value.max - value.min) * 0.2) * 100) / 100),

          // Customize format of the labels. This is a hack that allows us to get
          // the Y-axes and their labels 100% aligned between both charts.
          axisLabel: {
            showMinLabel: false,
            // Make sure to always show 2 decimals whenever the max value is < 10
            formatter: (value) => {
              const newValue = maxSharePrice < 10 ? value.toFixed(2) : value
              return `{label|${newValue}}`
            },
            rich: {
              label: {
                width: yAxisLabelWidth,
                backgroundColor: 'transparent', // Width won't work without background
              },
            },
          },
        },

        // Y-axis config for broker recommendations chart
        {
          gridId: 'broker-recommendations-grid',
          type: 'value',
          position: 'right', // show labels on the right side

          // Force proper structure of the Y-axis, 2 splits = 3 ticks (0, 50, 100)
          // and pin the max value to 100.
          splitNumber: 2,
          max: 100,

          // Customize format of the labels. This is a hack that allows us to get
          // the Y-axes and their labels 100% aligned between both charts.
          axisLabel: {
            formatter: '{label|{value}%}',
            rich: {
              label: {
                width: yAxisLabelWidth,
                backgroundColor: 'transparent', // Width won't work without background
              },
            },
          },

          // Hide split lines
          splitLine: { show: false },
        },
      ],

      // Define the actual series / charts
      //  - https://echarts.apache.org/en/option.html#series
      series: [
        // Share price series
        {
          id: 'share-price',
          datasetId: 'share-price',
          type: 'line',
          xAxisIndex: 0, // Link to the right X-axis
          yAxisIndex: 0, // Link to the right Y-axis
          cursor: 'default',

          // When there are missing data points, the value will be `null`. This
          // will connect the line between two points with a missing data point
          // in between.
          connectNulls: true,

          // Shorten default animation duration
          animationDuration: 600,

          // Highlight the series itself, dim the rest, on emphasis
          emphasis: {
            focus: 'self',
          },

          // Use empty circle as symbol, don't show by default, only on emphasis
          symbol: 'emptyCircle',
          showSymbol: false,

          // Define colors for this series
          lineStyle: {
            color: theme.palette.info.main,
            width: 1.5,
          },
          areaStyle: {
            color: theme.palette.info.main,
            opacity: 0.1,
          },
        },

        // Custom buy series
        {
          id: 'buy',
          datasetId: 'broker-recommendations',
          type: 'custom',
          xAxisIndex: 1, // Link to the right X-axis
          yAxisIndex: 1, // Link to the right Y-axis
          renderItem: (params, api) => {
            // Start buy where hold stops, stack on top of sell and hold
            const startY =
              (api.value(DIMENSION_SELL) as number) + (api.value(DIMENSION_HOLD) as number)
            const yValue = api.value(DIMENSION_BUY) as number
            const endY = startY + yValue

            return renderBrokerRecommendationBarPart(
              data,
              api,
              params.dataIndex,
              startY,
              endY,
              theme.palette.success.main
            )
          },
        },

        // Custom hold series
        {
          id: 'hold',
          datasetId: 'broker-recommendations',
          type: 'custom',
          xAxisIndex: 1, // Link to the right X-axis
          yAxisIndex: 1, // Link to the right Y-axis
          renderItem: (params, api) => {
            // Stack hold on top of sell, start where sell stops
            const startY = api.value(DIMENSION_SELL) as number
            const yValue = api.value(DIMENSION_HOLD) as number
            const endY = startY + yValue

            return renderBrokerRecommendationBarPart(
              data,
              api,
              params.dataIndex,
              startY,
              endY,
              theme.palette.warning.main
            )
          },
        },

        // Custom sell series
        {
          id: 'sell',
          datasetId: 'broker-recommendations',
          type: 'custom',
          xAxisIndex: 1, // Link to the right X-axis
          yAxisIndex: 1, // Link to the right Y-axis
          renderItem: (params, api) => {
            return renderBrokerRecommendationBarPart(
              data,
              api,
              params.dataIndex,
              0,
              api.value(DIMENSION_SELL) as number,
              theme.palette.error.main
            )
          },
        },

        // Missing broker recommendations, render grey bars
        {
          id: 'missing',
          datasetId: 'broker-recommendations',
          type: 'custom',
          xAxisIndex: 1, // Link to the right X-axis
          yAxisIndex: 1, // Link to the right Y-axis
          renderItem: (params, api) => {
            // Bail out if there are ratings
            const total = api.value(DIMENSION_TOTAL) as number
            if (total > 0) {
              return null
            }

            // Render a grey bar (full height) for missing broker recommendations
            return renderBrokerRecommendationBarPart(
              data,
              api,
              params.dataIndex,
              0,
              100,
              theme.palette.grey[300]
            )
          },
        },

        // The following series doesn't really paint any data, but draws a white
        // border with border radius very tightly around all broker recommendations.
        // This makes sure that the border radius is always consistent, even when
        // individual parts (e.g. the sell part) are very small (e.g. 1px high).
        {
          id: 'border-radius',
          datasetId: 'broker-recommendations',
          type: 'custom',
          xAxisIndex: 1, // Link to the right X-axis
          yAxisIndex: 1, // Link to the right Y-axis
          renderItem: (params, api): CustomSeriesRenderItemReturn => {
            const { x, y, width, height } = calculateBrokerRecommendationBarDimensions(
              data,
              api,
              params.dataIndex,
              0,
              100
            )

            return {
              type: 'rect',
              shape: {
                // Increase the size of the bar with 1px in all dimensions such
                // that it's around the actual bar
                x: x - 1,
                y: y - 1,
                width: width + 2,
                height: height + 2,

                // Apply 3px border radius + 1px extra for the bar being 1px bigger
                r: [4, 4, 4, 4],
              },
              style: {
                fill: 'transparent',
                stroke: theme.palette.background.paper,
                opacity: 1,
                lineWidth: 2,
              },
            }
          },
        },
      ],
    }
  }, [data, theme, hasBrokerRecommendations])
}

import { TypographyOptions } from '@mui/material/styles/createTypography'

export const typography: TypographyOptions = {
  fontFamily: 'Mulish, Arial, sans-serif',
  fontSize: 12,
  allVariants: {
    letterSpacing: 'normal',
  },
  h1: {
    fontSize: 32, // 54
    fontWeight: 800, // 800
    lineHeight: '48px', // 68px
  },
  h2: {
    fontSize: 24,
    fontWeight: 800,
    lineHeight: '32px',
  },
  h3: {
    fontSize: 20,
    fontWeight: 800,
    lineHeight: '28px',
  },
  h4: {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: '24px',
  },
  h5: {
    fontSize: 16,
    fontWeight: 800,
    lineHeight: '20px',
  },
  h6: {
    fontSize: 14,
    fontWeight: 800,
    lineHeight: '18px',
  },
  body1: {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.6,
  },
  body2: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
  },
  subtitle1: {
    // Caption 1
    fontSize: 16,
    fontWeight: '600',
    lineHeight: '24px',
  },
  subtitle2: {
    // Caption 2
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  caption: {
    // Caption 3
    fontSize: 10,
    fontWeight: 800,
    lineHeight: '14px',
  },
  button: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    textTransform: 'initial',
  },
  overline: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '15px',
    textTransform: 'none',
  },
}

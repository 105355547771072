import { useListedSecurityValuation } from '@gain/api/app/hooks'
import { ArrowDownIcon, ArrowUpIcon } from '@gain/components/icons'
import { useConvertCurrencyCallback, useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatNumber } from '@gain/utils/number'
import { styled, useTheme } from '@mui/material/styles'
import React from 'react'

import CardMetrics from '../../../../common/metrics/metrics-card'

const StyledArrowDown = styled(ArrowDownIcon)(({ theme }) => ({
  width: 14,
  height: 14,
  position: 'relative',
  top: -1,
  color: theme.palette.error.main,
  verticalAlign: 'middle',
}))

const StyledArrowUp = styled(ArrowUpIcon)(({ theme }) => ({
  width: 14,
  height: 14,
  position: 'relative',
  top: -1,
  color: theme.palette.success.main,
  verticalAlign: 'middle',
}))

const StyledTargetPriceDiff = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: -2, // Improve visual alignment of the SVG icon and metric value
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}))

interface AssetEvaluationMetricsCardProps {
  listedSecurityId: number
  currency: string
  listedSecurityCurrency: string
}

export default function AssetValuationMetricsCard({
  listedSecurityId,
  currency,
  listedSecurityCurrency,
}: AssetEvaluationMetricsCardProps) {
  const theme = useTheme()
  const convertCurrency = useConvertCurrencyCallback()
  const formatCurrency = useFormatCurrencyCallback(listedSecurityCurrency, currency)
  const swrValuation = useListedSecurityValuation({ listedSecurityId })

  // Bail out if there's no currency selected
  if (!currency) {
    return null
  }

  // Format the prices with 3 decimals for penny stocks (< 1.0)
  const convertedSharePrice = convertCurrency(
    swrValuation.data?.sharePrice,
    listedSecurityCurrency,
    currency
  )
  const decimals = convertedSharePrice && convertedSharePrice < 1 ? 3 : 2

  // Calculate ratio for target price diff with share price
  const targetPriceRatio =
    swrValuation.data?.sharePrice && swrValuation.data?.targetPrice
      ? (swrValuation.data.targetPrice / swrValuation.data.sharePrice - 1) * 100
      : null

  return (
    <CardMetrics
      metrics={[
        {
          label: 'Share price',
          value: formatCurrency(swrValuation.data?.sharePrice, {
            round: decimals,
            emptyValue: 'n.a.',
            format: 'raw',
          }),
        },
        {
          label: 'Target price',
          value: formatCurrency(swrValuation.data?.targetPrice, {
            round: decimals,
            emptyValue: 'n.a.',
            format: 'raw',
          }),
          suffixComponent: targetPriceRatio ? (
            <StyledTargetPriceDiff>
              {targetPriceRatio >= 0 ? <StyledArrowUp /> : <StyledArrowDown />}
              {Math.abs(targetPriceRatio).toFixed(1)}%
            </StyledTargetPriceDiff>
          ) : undefined,
        },
        {
          label: 'Market cap',
          value: formatCurrency(swrValuation.data?.marketCap, { emptyValue: 'n.a.' }),
        },
        {
          label: 'Enterprise value',
          value: formatCurrency(swrValuation.data?.enterpriseValue, { emptyValue: 'n.a.' }),
        },
        {
          label: 'EV / EBITDA (LTM)',
          value: swrValuation.data?.evEbitdaLtmratio
            ? `${formatNumber(swrValuation.data.evEbitdaLtmratio, { round: 1 })}x`
            : 'n.a.',
        },
      ]}
      orientationBreakpoint={theme.breakpoints.values['sm']}
    />
  )
}

import {
  useBookmarkListAddAssets,
  useBookmarksListRemoveAssets,
  useCustomAssetLists,
} from '@gain/api/app/hooks'
import { CustomAssetList } from '@gain/rpc/app-model'
import { MenuProps } from '@mui/material/Menu'
import { PopoverOrigin } from '@mui/material/Popover'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { MAX_BOOKMARK_LIST_ITEMS } from '../bookmark-list-contants'
import { BookmarksListMenu } from './bookmarks-list-menu'

export const useDisabledListIds = (lists: CustomAssetList[], assetIds: number[]) => {
  return useMemo(
    () =>
      lists
        .filter(
          (list) =>
            list.assetIds.filter((id) => !assetIds.includes(id)).length + assetIds.length >
              MAX_BOOKMARK_LIST_ITEMS || list.assetIds.length === MAX_BOOKMARK_LIST_ITEMS
        )
        .map((list) => list.id),
    [assetIds, lists]
  )
}

export type BookmarksListAddAssetMenuProps = Omit<
  MenuProps,
  'transformOrigin' | 'onClose' | 'anchorOrigin'
> & {
  assetIds: number[]
  onClose: () => void
  excludeListIds?: number[]
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableCloseOnSelect?: boolean
  disableSnackbar?: boolean
}

export function BookmarksListAddAssetMenu({
  assetIds,
  onClose,
  excludeListIds,
  open,
  anchorEl,
  disableCloseOnSelect,
  disableSnackbar,
  ...props
}: BookmarksListAddAssetMenuProps) {
  const bookmarkLists = useCustomAssetLists()
  const addAssets = useBookmarkListAddAssets()
  const removeAssets = useBookmarksListRemoveAssets()
  const [selectedListIds, setSelectedListIds] = useState<number[]>([])

  const lists = useMemo(() => bookmarkLists?.data ?? [], [bookmarkLists?.data])

  const handleClose = useCallback(
    (reason: 'cancel' | 'create' | 'select' | 'deselect', list?: CustomAssetList) => {
      if (['select', 'create'].includes(reason) && assetIds.length > 0 && list) {
        addAssets(list, assetIds, disableSnackbar)
      } else if (reason === 'deselect' && list) {
        removeAssets(list, assetIds, disableSnackbar)
      }

      if (['select', 'deselect'].includes(reason) && disableCloseOnSelect) {
        return
      }

      onClose()
    },
    [assetIds, onClose, addAssets, removeAssets, disableCloseOnSelect, disableSnackbar]
  )

  const disabledListIds = useDisabledListIds(lists, assetIds)

  // If there is one asset being added show a "Filled" icon if the bookmark is
  // already part of the bookmarks list.
  useEffect(() => {
    if (assetIds.length === 1) {
      const listIds = lists
        .filter((list) => list.assetIds.includes(assetIds[0]))
        .map(({ id }) => id)
      setSelectedListIds(listIds)
    } else {
      setSelectedListIds([])
    }
  }, [assetIds, lists])

  return (
    <BookmarksListMenu
      anchorEl={anchorEl}
      disabledListIds={disabledListIds}
      excludeListIds={excludeListIds}
      lists={lists}
      onClose={handleClose}
      open={open}
      selectedListIds={selectedListIds}
      {...props}
    />
  )
}

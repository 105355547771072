import { SpinnerIcon } from '@gain/components/icons'
import {
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

export const useIsInViewport = (ref: RefObject<HTMLElement>, once?: boolean) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const elem = ref.current
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
      if (once && entry.isIntersecting) {
        observer.unobserve(elem)
        observer.disconnect()
      }
    })
    observer.observe(elem)

    return () => {
      observer.unobserve(elem)
      observer.disconnect()
    }
  }, [once, ref])

  return isIntersecting
}

export type InViewportProps = PropsWithChildren<{
  minWidth?: CSSProperties['minWidth']
  minHeight?: CSSProperties['minHeight']
  className?: string
  keepMounted?: boolean
  onIsInView?: () => void
  showLoader?: boolean
}>

const InViewport = forwardRef<HTMLDivElement | null, InViewportProps>(function InViewport(
  { minWidth, minHeight, children, keepMounted, onIsInView, showLoader = true, ...props },
  ref
) {
  const innerRef = useRef<HTMLDivElement>(null)
  const isInViewport = useIsInViewport(innerRef, keepMounted)

  useImperativeHandle(ref, () => innerRef.current)

  useEffect(() => {
    if (isInViewport && onIsInView) {
      onIsInView()
    }
  }, [isInViewport, onIsInView])

  return (
    <div
      ref={innerRef}
      style={{ minWidth, minHeight }}
      {...props}>
      {isInViewport && children}
      {!isInViewport && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {showLoader && <SpinnerIcon />}
        </div>
      )}
    </div>
  )
})

export default InViewport

import Typography, { TypographyProps } from '@gain/components/typography'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { forwardRef, ReactNode } from 'react'

import { AvatarProps } from '../../../../common/avatar'
import Logo, { LogoProps } from '../../../../common/logo'

export const contentLinkClasses = generateUtilityClasses('ContentLink', ['root', 'title'])

const StyledLink = styled(Link)({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 0,
})

interface UltimateOwnershipLinkProps {
  href?: string | null
  endIcon?: ReactNode
  className?: string
  avatarProps?: AvatarProps
  region?: LogoProps['region']
  label?: string | null
  labelTypographyProps?: TypographyProps
}

const UltimateOwnershipLink = forwardRef<HTMLAnchorElement, UltimateOwnershipLinkProps>(
  function ContentLink(
    { href, className, avatarProps, region, label, labelTypographyProps, endIcon, ...props },
    ref
  ) {
    if (!label) {
      return null
    }

    return (
      <StyledLink
        ref={ref}
        className={className}
        href={href || undefined}
        rel={'noopener noreferrer'}
        target={'_blank'}
        underline={'none'}
        {...props}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          minWidth={0}
          spacing={1}>
          {avatarProps && (
            <Logo
              avatarProps={{
                imagePadding: 0.5,
                ...avatarProps,
              }}
              defaultIcon={avatarProps.children}
              region={region}
              size={24}
              variant={'rounded'}
            />
          )}
          <Stack
            direction={'column'}
            justifyContent={'center'}
            minWidth={0}>
            <Stack
              alignItems={'center'}
              direction={'row'}
              minWidth={0}
              spacing={0}>
              <Typography
                color={'text.primary'}
                variant={'body2'}
                {...labelTypographyProps}
                className={clsx(contentLinkClasses.title, labelTypographyProps?.className)}
                noWrap>
                {label}
              </Typography>
              {endIcon}
            </Stack>
          </Stack>
        </Stack>
      </StyledLink>
    )
  }
)

export default UltimateOwnershipLink

import { isTruthy } from '@gain/utils/common'
import { useElementWidthEffect } from '@gain/utils/dom'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import React, { useCallback, useRef, useState } from 'react'

import Card from '../../card/card'
import Metric, { MetricProps } from './metric'

// Orientation, whether we should show all metrics in 2 horizontal line or in a
// grid with 2 columns. For this component the theme's default breakpoints don't
// work well unfortunately.
type Orientation = 'horizontal' | 'vertical'
const DEFAULT_ORIENTATION_BREAKPOINT = 936

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<{ orientation: Orientation }>(({ theme, orientation }) => ({
  display: 'grid',
  padding: theme.spacing('18px', 3),
  minWidth: 0,
  gap: theme.spacing(3),

  ...(orientation === 'horizontal' && {
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr 1px',
  }),

  ...(orientation === 'vertical' && {
    display: 'grid',
    gridTemplateColumns: '1fr 1px 1fr',
  }),
}))

export function createMetrics(...metrics: Array<MetricProps | false | undefined | null>) {
  return metrics.filter(isTruthy)
}

interface CardMetricsProps {
  metrics: MetricProps[]
  orientationBreakpoint?: number
}

export default function CardMetrics({
  metrics,
  orientationBreakpoint = DEFAULT_ORIENTATION_BREAKPOINT,
}: CardMetricsProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [orientation, setOrientation] = useState<Orientation>('horizontal')

  const handleWidth = useCallback(
    (newWidth: number) =>
      setOrientation(newWidth >= orientationBreakpoint ? 'horizontal' : 'vertical'),
    [orientationBreakpoint]
  )

  const handleShowDivider = useCallback(
    (index: number) => {
      if (orientation === 'horizontal') {
        return index !== 0
      }

      return index > 0 && index % 2 === 1
    },
    [orientation]
  )

  useElementWidthEffect(ref, handleWidth)

  return (
    <Card
      ref={ref}
      sx={{ pb: 0 }}>
      <StyledContainer orientation={orientation}>
        {metrics.map((metricProps, index) => (
          <React.Fragment key={index}>
            {handleShowDivider(index) && (
              <Divider
                orientation={'vertical'}
                flexItem
              />
            )}
            <Metric {...metricProps} />
          </React.Fragment>
        ))}
      </StyledContainer>
    </Card>
  )
}

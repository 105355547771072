import { GlobalUltimateOwner, LegalEntity } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import React from 'react'

import Card, { CardContent, CardHeader, CardHeaderProps } from '../../../common/card/card'
import Table from '../../../common/table'
import { legalEntityShareholdersColumns } from './legal-entity-ownership-columns'
import { LegalEntityUltimateOwner } from './legal-entity-ultimate-owner'

export interface LegalEntityOwnershipProps {
  legalEntity: LegalEntity
  globalUltimateOwner?: GlobalUltimateOwner
}

const StyledCard = styled(Card)({
  height: '100%',
  paddingBottom: 0,
})

const StyledCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 3),

  [theme.breakpoints.only('xs')]: {
    padding: theme.spacing(1.5, 1, 0, 2),
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1, 0),
}))

export default function LegalEntityOwnership({
  legalEntity,
  globalUltimateOwner,
}: LegalEntityOwnershipProps) {
  return (
    <StyledCard>
      <StyledCardHeader title={'Ownership'} />
      <StyledCardContent>
        {legalEntity.legalEntityShareholders.length > 0 && (
          <Grid container>
            <Table
              columns={legalEntityShareholdersColumns}
              rows={legalEntity.legalEntityShareholders}
            />
          </Grid>
        )}

        {legalEntity.legalEntityShareholders.length === 0 && globalUltimateOwner && (
          <LegalEntityUltimateOwner
            globalUltimateOwner={globalUltimateOwner}
            legalEntityId={legalEntity.id}
          />
        )}
      </StyledCardContent>
    </StyledCard>
  )
}

import { FilterableInvestorListItem } from '@gain/rpc/app-model'

// This is used to map filters to the investor table column name that do
// not map 1-to-1 with a table column field.
const filterToInvestorTableColumnMapping: Partial<
  Record<keyof FilterableInvestorListItem, Array<keyof FilterableInvestorListItem>>
> = {
  assetsFiltered: ['assetsTotal'],
  assetSubsectors: ['assetSectors'],
  'asset.ebitdaEur': ['assetEbitdasEur'],
}

export default filterToInvestorTableColumnMapping

import { Deal, DealBuyer, DealSeller } from '@gain/rpc/app-model'

import { compareDealPartyByShareAndOrderDesc } from './compare-deal-party-by-share-and-order-desc'
import { DealParty } from './deal-party.model'
import { getDealReasonLabel } from './deal-util'
import { formatDealFundingSeriesLabel } from './format-deal-funding-series-label'

function getLeadingOrBiggestShareParty(parties: DealParty[]) {
  const sortedParties = [...parties].sort(compareDealPartyByShareAndOrderDesc)

  const leadingParty = sortedParties.find((party) => party.leadingParty)
  if (leadingParty) {
    return leadingParty
  }

  if (sortedParties.length > 0) {
    return sortedParties[0]
  }

  return null
}

function getLeadingOrBiggestSharePartyReason(buyers: DealBuyer[], sellers: DealSeller[]) {
  const leadingOrBiggestShareParty =
    getLeadingOrBiggestShareParty(buyers) || getLeadingOrBiggestShareParty(sellers)

  // If we have a party with a reason return that one
  if (leadingOrBiggestShareParty?.reason) {
    return leadingOrBiggestShareParty.reason
  }

  // Else we get the first party that has a reason
  return [...buyers, ...sellers]
    .sort(compareDealPartyByShareAndOrderDesc)
    .find((party) => party.reason)?.reason
}

interface FormatDealTitleOptions {
  emptyValue?: string
}

export function formatDealTitle(
  deal: Deal | null | undefined,
  { emptyValue = '-' }: FormatDealTitleOptions = {}
) {
  if (!deal) {
    return emptyValue
  }

  if (deal.fundingRoundType) {
    return `${formatDealFundingSeriesLabel(deal.fundingRoundType, { withUnknown: false })} - ${
      deal.asset
    }`
  }

  const reason = getLeadingOrBiggestSharePartyReason(deal.buyers, deal.sellers)

  if (reason) {
    return `${getDealReasonLabel(reason)} - ${deal?.asset}`
  }

  return `Deal - ${deal?.asset}`
}

import { ListMethodFilterType, ListMethodMap, RpcMethodMap } from '@gain/rpc/app-model'
import { List, ListArgs, ListParams } from '@gain/rpc/list-model'
import { formatListArgs } from '@gain/rpc/utils'
import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { useAppSwr } from './use-app-swr'
import { UseRpcResponse } from './use-rpc'

const defaultList: List<any> = {
  items: [],
  counts: {
    filtered: 0,
    total: 0,
  },
  args: formatListArgs(),
}

export type SwrParams<Item> = Partial<Item> | null | (() => Partial<Item> | null) | undefined

export type AppListParams<
  Method extends keyof ListMethodMap,
  FilterItem extends ListMethodFilterType<Method>
> = SwrParams<
  Omit<RpcMethodMap[Method]['params'], 'sort' | 'filter' | 'search'> | Partial<ListArgs<FilterItem>>
>

export interface UseAppListSwrResult<Data = unknown, Error = unknown>
  extends UseRpcResponse<Data, Error> {
  data: Data
  empty: boolean
}

export function useFormatAppListSwrParams<Item extends object>(
  params: SwrParams<ListArgs<Item>>
): ListParams<Item> | null {
  if (params === null) {
    return null
  }

  if (typeof params === 'function') {
    const result = params()
    return result !== null && result !== undefined ? formatListArgs(result) : null
  }

  return formatListArgs(params)
}

export function useAppListSwr<
  Method extends keyof ListMethodMap,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>
>(
  method: Method,
  params?: AppListParams<Method, FilterItem>,
  options?: SWRConfiguration
): UseAppListSwrResult<List<Item, FilterItem>> {
  const formattedParams = useFormatAppListSwrParams<FilterItem>(params)
  const { data, error, mutate } = useAppSwr<Method, ListParams<FilterItem>, any>(
    method,
    formattedParams,
    options
  )

  return useMemo(() => {
    const loading = !error && !data && formattedParams !== null
    return {
      mutate,
      error,
      loading: loading,
      data: data || defaultList,
      empty: (!loading && data?.items.length === 0) || !formattedParams,
    }
  }, [data, error, mutate, formattedParams])
}

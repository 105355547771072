/**
 * Type guard for {string}
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

/**
 * Type guard for {number}
 */
export function isNumber(value: unknown): value is number {
  return typeof value === 'number'
}

/**
 * Type guard for {boolean}
 */
export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean'
}

/**
 * Type guard for {null}
 */
export function isNull(value: unknown): value is null {
  return value === null
}

/**
 * Type guard for not {null}
 */
export function isNotNull<T>(value: T): value is Exclude<T, null> {
  return value !== null
}

/**
 * Type guard for not {null} and empty string
 */
export function isNotEmpty<T>(value: T): value is Exclude<T, null> {
  return isNotNull(value) && value !== ''
}

/**
 * Extracts the keys of an object to an Array type
 *
 * @example
 *
 *   ObjectKeys<{ age: number; name: string }> // = ('age' | 'name')[]
 *
 */
type ObjectKeys<T> = T extends object
  ? (keyof T)[]
  : T extends number
  ? []
  : T extends Array<any> | string
  ? string[]
  : never

/**
 * Returns a typed array of object keys
 * @param value
 */
export const objectKeys = <T extends object>(value: T) => Object.keys(value) as ObjectKeys<T>

/**
 * Utility type to extract keys that are of type number | null.
 */
export type NumberOrNullKeys<T> = {
  [K in keyof T]: T[K] extends number | null ? K : never
}[keyof T]

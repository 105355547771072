import { Asset } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import { useMemo } from 'react'

export const useHasFteMeasurements = (asset?: Asset) => {
  return useMemo(() => {
    if (!asset) {
      return false
    }

    return (
      asset.fteMeasurements.length > 0 ||
      asset.financialResults.filter(({ fte }) => isTruthy(fte?.amount)).length > 0
    )
  }, [asset])
}

import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

import { StyledNewsFeedItemHeader } from './news-feed-item-header'

export default function NewsFeedItemHeaderSkeleton() {
  return (
    <StyledNewsFeedItemHeader>
      <Typography
        color={'textPrimary'}
        variant={'subtitle2'}>
        <Skeleton width={68} />
      </Typography>
    </StyledNewsFeedItemHeader>
  )
}

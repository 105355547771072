import { Asset } from '@gain/rpc/app-model'
import { useMemo } from 'react'

export function useHasBusinessDetails(asset: Asset | undefined) {
  return useMemo(() => {
    if (!asset) {
      return false
    }

    return (
      asset.generalInfo?.headquarters ||
      asset.generalInfo?.fteYear ||
      asset.generalInfo?.customerBase.length ||
      asset.generalInfo?.businessActivity.length ||
      asset.generalInfo?.salesChannel.length ||
      asset.generalInfo?.pricePositioning
    )
  }, [asset])
}

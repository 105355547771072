import { SpinnerIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { forwardRef } from 'react'

import { TABLE_HEAD_CELL_HEIGHT } from '../../theme'

export const VIRTUAL_TABLE_SPINNER_SIZE = 32

export const StyledSpinnerIcon = styled(SpinnerIcon)({
  zIndex: 3,
  width: VIRTUAL_TABLE_SPINNER_SIZE,
  height: VIRTUAL_TABLE_SPINNER_SIZE,
  opacity: 'inherit',
})

export const StyledContainer = styled('div')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
  right: 0,
  top: TABLE_HEAD_CELL_HEIGHT,
})

export type VirtualTableLoaderProps = Pick<SvgIconProps, 'color'> & {
  height: number
}

export const VirtualTableLoader = forwardRef<HTMLDivElement, VirtualTableLoaderProps>(
  ({ color, height, ...props }, ref) => {
    return (
      <StyledContainer
        ref={ref}
        sx={{ height: height - TABLE_HEAD_CELL_HEIGHT }}>
        <StyledSpinnerIcon
          color={color}
          {...props}
        />
      </StyledContainer>
    )
  }
)

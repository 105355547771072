import { AssetListItem } from '@gain/rpc/app-model'
import React, { useMemo } from 'react'

import { ColumnConfig, RenderHeaderParams } from '../../../common/virtual-table'
import { NameVirtualTableHeaderCell } from '../../../common/virtual-table/cells'
import {
  assetListItemTableColumns,
  assetTableColumnNames,
} from '../../asset/asset-list-item-table/asset-list-item-table-columns'
import useListViewColumnVisibilityModel from '../use-list-view-column-visibility-model'

const ACTIVE_SEARCH_COLUMNS = ['ftsHeadline']

export default function useAssetListViewColumns(
  activeFilterColumns: (keyof AssetListItem)[],
  maxLimit?: number,
  maxLimitTooltip?: string
) {
  const columnVisibilityModel = useListViewColumnVisibilityModel(
    'asset',
    assetTableColumnNames,
    activeFilterColumns,
    ACTIVE_SEARCH_COLUMNS
  )

  return useMemo(() => {
    // Override the default name column with maxLimit props
    const nameColumn = {
      ...assetListItemTableColumns[0],
      renderHeader: (params: RenderHeaderParams<AssetListItem, 'name'>) => (
        <NameVirtualTableHeaderCell
          maxLimit={maxLimit}
          maxLimitTooltip={maxLimitTooltip}
          {...params}
        />
      ),
    } as ColumnConfig<AssetListItem>

    // Only return visible columns
    return [nameColumn, ...assetListItemTableColumns.slice(1)].filter(
      ({ field }) => columnVisibilityModel[field]
    )
  }, [columnVisibilityModel, maxLimit, maxLimitTooltip])
}

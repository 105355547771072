import List from '@mui/material/List'
import { MenuProps } from '@mui/material/Menu'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { PopoverActions, PopoverOrigin } from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import React, { useEffect, useRef, useState } from 'react'

import CustomOutlinedInput from '../form/custom-outlined-input'
import DropdownMenuGroup from './dropdown-menu-group'
import DropdownMenuItem from './dropdown-menu-item'
import { DropdownMenuOption, DropdownMenuOptions, isDropdownMenuGroup } from './dropdown-menu-model'
import DropdownMenuPopover from './dropdown-menu-popover'
import { useActiveOptions } from './use-active-options'
import useAnchorOffset from './use-anchor-offset'
import { useTrackDropdownSearchEvent } from './use-track-dropdown-search-event'

// If the remaining space below the anchor element is less than this threshold, the
// dropdown menu will be displayed above the anchor element.
const FLIP_VERTICAL_THRESHOLD = 300

const StyledInputContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 2),
}))

const StyledSearchInput = styled(CustomOutlinedInput)(({ theme }) => ({
  [`&.${outlinedInputClasses.root}`]: {
    backgroundColor: theme.palette.grey['50'],
  },
}))

const StyledList = styled(List)({
  overflow: 'auto',
})

const StyledNoOptionsFound = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  padding: theme.spacing(0.5, 3),
}))

export interface DropdownMenuProps<T extends DropdownMenuOption>
  extends Omit<MenuProps, 'onSelect'> {
  options: DropdownMenuOptions<T>
  onSelect: (item: T) => void
  resetOnClose?: boolean
  searchPlaceholder: string
  gaCategory: string
  horizontalOrigin?: PopoverOrigin['horizontal']
}

/**
 * The dropdown menu component is a popover that displays a list of menu options.
 * The menu allows nested groups of options and includes a search input to filter
 * the list of options.
 */
export default function DropdownMenu<T extends DropdownMenuOption>({
  options,
  onSelect,
  open,
  searchPlaceholder,
  gaCategory,
  resetOnClose = false,
  horizontalOrigin = 'center',
  anchorEl,
  ...menuProps
}: DropdownMenuProps<T>) {
  const [activeGroupId, setActiveGroupId] = useState<null | string>(null)
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const activeOptions = useActiveOptions(options, searchQuery)
  const popoverActions = useRef<PopoverActions | null>(null)
  const [anchorBottom, anchorTop] = useAnchorOffset(anchorEl)

  // If there is more space above the anchor element and the remaining space below
  // is less than FLIP_VERTICAL_THRESHOLD, display the dropdown menu above the anchor.
  const remainingSpaceBottom = window.innerHeight - (anchorBottom ?? 0)
  const showAboveAnchor =
    (anchorTop ?? 0) > remainingSpaceBottom && remainingSpaceBottom < FLIP_VERTICAL_THRESHOLD

  useTrackDropdownSearchEvent(gaCategory, searchQuery, activeOptions)

  useEffect(() => {
    if (!open && resetOnClose) {
      setSearchQuery(null)
      setActiveGroupId(null)
    }
  }, [open, setSearchQuery, setActiveGroupId, resetOnClose])

  return (
    <DropdownMenuPopover
      action={popoverActions}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: horizontalOrigin,
        vertical: showAboveAnchor ? 'top' : 'bottom',
      }}
      open={open}
      showAboveAnchor={showAboveAnchor}
      transformOrigin={{
        horizontal: horizontalOrigin,
        vertical: showAboveAnchor ? 'bottom' : 'top',
      }}
      {...menuProps}>
      <StyledInputContainer>
        <StyledSearchInput
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={searchPlaceholder}
          value={searchQuery || ''}
          autoFocus
          disableShadow
          fullWidth
        />
      </StyledInputContainer>

      {activeOptions.length === 0 && (
        <StyledNoOptionsFound>No matches found for "{searchQuery}"</StyledNoOptionsFound>
      )}

      <StyledList
        dense
        disablePadding>
        {activeOptions.map((item, index) => {
          if (isDropdownMenuGroup(item)) {
            return (
              <DropdownMenuGroup<T>
                key={item.id}
                activeGroupId={activeGroupId}
                alwaysOpen={!!searchQuery}
                disableAnimation={showAboveAnchor}
                group={item}
                isLast={index === activeOptions.length - 1}
                onClose={() => setActiveGroupId(null)}
                onOpen={(groupId) => setActiveGroupId(groupId)}
                onSelect={onSelect}
              />
            )
          }

          return (
            <DropdownMenuItem
              key={item.id}
              icon={item.icon}
              label={item.label}
              onClick={() => onSelect(item)}
            />
          )
        })}
      </StyledList>
    </DropdownMenuPopover>
  )
}

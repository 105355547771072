import {
  isCustomAssetList,
  useBookmarkListAddAssets,
  useUpdateBookmarksFilteredList,
} from '@gain/api/app/hooks'
import {
  Edit3Icon,
  LinkIcon,
  LogOutIcon,
  SearchIcon,
  SlidersIcon,
  Trash2Icon,
  UsersIcon,
} from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import { AssetListItem, BookmarksFilteredList, CustomAssetList } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import { useState } from 'react'

import { MenuDivider } from '../../../common/menu-divider'
import AddAssetsDialog from '../../add-assets-dialog'
import BookmarkListUrlImportDialog from '../bookmark-list-url-import/bookmark-list-url-import-dialog'
import { BookmarksFilteredShareDialog, BookmarksListShareDialog } from '../bookmarks-list-share'
import BookmarksListFiltersDialog from './bookmarks-list-filters-dialog'
import BookmarksListRenameDialog from './bookmarks-list-rename-dialog'
import useDeleteOrLeaveCustomList from './use-delete-or-leave-custom-list'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: theme.spacing(1.75),
}))

const StyledDeleteMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover, &:focus': {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },
}))

interface BookmarksListEditMenuProps extends MenuProps {
  anchorEl: MenuProps['anchorEl']
  list: CustomAssetList | BookmarksFilteredList
  onClose: () => void
  open: boolean

  // disableEditContent hides the "Add company" and "Change filters" menu options
  disableEditContent?: boolean
}

export default function BookmarksListEditMenu({
  anchorEl,
  disableEditContent = false,
  list,
  onClose,
  open,
  ...props
}: BookmarksListEditMenuProps) {
  const userProfile = useUserProfileContext()
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [urlImportDialogOpen, setUrlImportDialogOpen] = useState(false)
  const removeList = useDeleteOrLeaveCustomList()
  const updateFilteredList = useUpdateBookmarksFilteredList()
  const addAssets = useBookmarkListAddAssets()

  const isOwner = list?.ownerUserId === userProfile.id
  const allowEdit = isOwner && !disableEditContent

  const handleShare = () => {
    onClose()
    setShareDialogOpen(true)
  }

  const handleEdit = () => {
    onClose()
    setEditDialogOpen(true)
  }

  const handleUrlImport = () => {
    onClose()
    setUrlImportDialogOpen(true)
  }

  const handleRename = () => {
    onClose()
    setRenameDialogOpen(true)
  }

  const handleDelete = () => {
    onClose()
    removeList(list)
  }

  const handleSubmitEditFilters = async (filters: ListFilter<AssetListItem>[] | null) => {
    await updateFilteredList(list.id, { filters: filters ?? [] })
  }

  const isAssetList = isCustomAssetList(list)
  const ShareDialog = isAssetList ? BookmarksListShareDialog : BookmarksFilteredShareDialog

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={onClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 0,
        }}
        {...props}>
        {isOwner && (
          <StyledMenuItem onClick={handleRename}>
            <Edit3Icon /> Rename
          </StyledMenuItem>
        )}

        <StyledMenuItem onClick={handleShare}>
          <UsersIcon /> Share with others
        </StyledMenuItem>

        {allowEdit && isAssetList && (
          <StyledMenuItem onClick={handleEdit}>
            <SearchIcon /> Add company by name
          </StyledMenuItem>
        )}

        {allowEdit && isAssetList && (
          <StyledMenuItem onClick={handleUrlImport}>
            <LinkIcon /> Add company by URL
          </StyledMenuItem>
        )}

        {allowEdit && !isAssetList && (
          <StyledMenuItem onClick={handleEdit}>
            <SlidersIcon /> Change filters
          </StyledMenuItem>
        )}

        <MenuDivider />

        <StyledDeleteMenuItem onClick={handleDelete}>
          {isOwner ? (
            <>
              <Trash2Icon color={'error'} /> Delete list
            </>
          ) : (
            <>
              <LogOutIcon color={'error'} /> Leave list
            </>
          )}
        </StyledDeleteMenuItem>
      </Menu>

      <BookmarksListRenameDialog
        list={list}
        onClose={() => setRenameDialogOpen(false)}
        open={renameDialogOpen}
      />

      <ShareDialog
        onClose={() => setShareDialogOpen(false)}
        onSubmit={() => list}
        open={shareDialogOpen}
        ownerUserId={list.ownerUserId}
        userIds={list.userIds}
      />

      {!disableEditContent && isAssetList && (
        <>
          <AddAssetsDialog
            onClose={() => setEditDialogOpen(false)}
            onSave={(assetIds: number[]) => addAssets(list, assetIds)}
            open={editDialogOpen}
          />
          <BookmarkListUrlImportDialog
            listId={list.id}
            onClose={() => setUrlImportDialogOpen(false)}
            open={urlImportDialogOpen}
          />
        </>
      )}

      {!disableEditContent && !isAssetList && (
        <BookmarksListFiltersDialog
          dialogTitle={'Filters applied'}
          list={list}
          onClose={() => setEditDialogOpen(false)}
          onSubmit={handleSubmitEditFilters}
          open={editDialogOpen}
        />
      )}
    </>
  )
}

import { ratingEsgOptions } from '@gain/utils/investment-criteria'
import { styled } from '@mui/material/styles'
import React from 'react'

import {
  InvestmentCriteriaTableCell,
  InvestmentCriteriaTableHeaderCell,
  InvestmentCriteriaTableRatingOption,
  InvestmentCriteriaTableRowContainer,
} from '../../../investment-criteria/investment-criteria-card'

const StyledOddRatingOption = styled(InvestmentCriteriaTableRatingOption)({
  opacity: 0,
  minWidth: 40,
})

const StyledEvenRatingOption = styled(InvestmentCriteriaTableRatingOption)({
  minWidth: 80,
})

export default function IndustryEsgAssessmentTableFooter() {
  return (
    <InvestmentCriteriaTableRowContainer>
      <InvestmentCriteriaTableHeaderCell />
      <InvestmentCriteriaTableCell>
        {ratingEsgOptions.map((option, index) => {
          if (index % 2 === 1) {
            return <StyledOddRatingOption key={option.value} />
          }

          return <StyledEvenRatingOption key={option.value}>{option.label}</StyledEvenRatingOption>
        })}
      </InvestmentCriteriaTableCell>
    </InvestmentCriteriaTableRowContainer>
  )
}

import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import YearChip from '../../../common/year-chip'
import { LatestFinancialsSparkLine } from './latest-financials-spark-line.component'
import { LatestFinancialsItemProps } from './latest-financials-vertical-item.component'

const StyledValue = styled('span')(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightBold,
}))

export default function LatestFinancialsHorizontalItem({
  label,
  year,
  value,
  lineValues,
}: LatestFinancialsItemProps) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={0.75}>
      <Typography
        variant={'body2'}
        noWrap>
        {label}:
      </Typography>

      <StyledValue>{value}</StyledValue>

      <LatestFinancialsSparkLine lineValues={lineValues} />

      {year && <YearChip year={year} />}
    </Stack>
  )
}

export interface Subsector {
  id: number
  name: string
  sector: string
  title: string
}

export const SUBSECTORS: ReadonlyArray<Subsector> = [
  {
    id: 2,
    name: 'education',
    sector: 'services',
    title: 'Education',
  },
  {
    id: 3,
    name: 'media',
    sector: 'tmt',
    title: 'Media',
  },
  {
    id: 4,
    name: 'logistics',
    sector: 'services',
    title: 'Logistics',
  },
  {
    id: 5,
    name: 'software',
    sector: 'tmt',
    title: 'Software',
  },
  {
    id: 6,
    name: 'technology',
    sector: 'tmt',
    title: 'Technology',
  },
  {
    id: 7,
    name: 'telecom',
    sector: 'tmt',
    title: 'Telecom',
  },
  {
    id: 8,
    name: 'consumerGoods',
    sector: 'consumer',
    title: 'Consumer goods',
  },
  {
    id: 9,
    name: 'food',
    sector: 'consumer',
    title: 'Food & beverage',
  },
  {
    id: 10,
    name: 'leisure',
    sector: 'consumer',
    title: 'Leisure',
  },
  {
    id: 11,
    name: 'retail',
    sector: 'consumer',
    title: 'Retail',
  },
  {
    id: 12,
    name: 'chemicals',
    sector: 'materialsEnergy',
    title: 'Chemicals',
  },
  {
    id: 13,
    name: 'energy',
    sector: 'materialsEnergy',
    title: 'Energy',
  },
  {
    id: 14,
    name: 'rawMaterials',
    sector: 'materialsEnergy',
    title: 'Raw materials',
  },
  {
    id: 15,
    name: 'agriculture',
    sector: 'industrials',
    title: 'Agriculture',
  },
  {
    id: 16,
    name: 'automotive',
    sector: 'industrials',
    title: 'Automotive',
  },
  {
    id: 17,
    name: 'construction',
    sector: 'industrials',
    title: 'Construction',
  },
  {
    id: 18,
    name: 'manufacturing',
    sector: 'industrials',
    title: 'Manufacturing',
  },
  {
    id: 19,
    name: 'biotechnology',
    sector: 'scienceHealth',
    title: 'Biotechnology',
  },
  {
    id: 20,
    name: 'healthcareServices',
    sector: 'scienceHealth',
    title: 'Healthcare services',
  },
  {
    id: 21,
    name: 'medtech',
    sector: 'scienceHealth',
    title: 'Medtech',
  },
  {
    id: 22,
    name: 'pharmaceuticals',
    sector: 'scienceHealth',
    title: 'Pharmaceuticals',
  },
  {
    id: 23,
    name: 'assetManagement',
    sector: 'financial',
    title: 'Asset management',
  },
  {
    id: 24,
    name: 'banking',
    sector: 'financial',
    title: 'Banking',
  },
  {
    id: 25,
    name: 'insurance',
    sector: 'financial',
    title: 'Insurance',
  },
  {
    id: 26,
    name: 'infrastructure',
    sector: 'other',
    title: 'Infrastructure',
  },
  {
    id: 27,
    name: 'other',
    sector: 'other',
    title: 'Other',
  },
  {
    id: 28,
    name: 'professionalServices',
    sector: 'services',
    title: 'Professional services',
  },
  {
    id: 29,
    name: 'technicalServices',
    sector: 'services',
    title: 'Technical services',
  },
]

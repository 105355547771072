import { ChevronRightIcon } from '@gain/components/icons'
import { ListItemKey } from '@gain/rpc/list-model'
import Chip, { chipClasses } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'
import { useForm } from 'react-final-form'

import { useFilterFieldContext } from '../filter/filter-field-context-provider'
import { FilterOption } from '../filter-config/filter-config-model'

interface ZoomInChipProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  zoomInOption: FilterOption<Item, FilterField>
}

const StyledChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.deleteIcon}`]: {
    color: theme.palette.text.primary,
  },
}))

/**
 * The ZoomInChip is used to allow the user to change the current filter to a
 * new one. It is used to enable filtering on US-states from the region filter.
 * IT SHOULD NOT BE REUSED FOR ANYTHING ELSE!!!
 */
export default function ZoomInChip<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ zoomInOption }: ZoomInChipProps<Item, FilterField>) {
  const form = useForm()
  const { resetValue, filterIdPath } = useFilterFieldContext()

  const handleClick = useCallback(() => {
    // Reset the value of the current filter
    resetValue()

    // Replace the current filter with the filter specified in the zoom-in
    // config
    form.change(filterIdPath, zoomInOption.id)
  }, [filterIdPath, form, resetValue, zoomInOption.id])

  return (
    <StyledChip
      icon={<ChevronRightIcon />}
      label={zoomInOption.label}
      onClick={handleClick}
      size={'medium'}
    />
  )
}

import { useOpenDialog } from '@app/common/dialog'
import { useRpcClient } from '@gain/api/swr'
import { CustomAssetList } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import { useCheckBookmarksListError, useNavigateToNextBookmarksList } from './bookmarks-hooks'
import { useMutateBookmarksList } from './bookmarks-list-hooks'

export function useCreateBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const openDialog = useOpenDialog()

  return useCallback(
    async (title: string): Promise<CustomAssetList | null> => {
      try {
        const result = await fetcher({
          method: 'lists.createCustomAssetList',
          params: { partial: { title } },
        })

        // Add list to SWR cache
        await mutateList(result.id, () => result)

        return result
      } catch (err) {
        openDialog({
          title: 'Create list failed',
          message: 'Something went wrong while creating the list. Please try again.',
          buttonText: 'Ok',
        })
        return null
      }
    },
    [fetcher, mutateList, openDialog]
  )
}

export interface UpdateBookmarksListParams {
  title?: string
  description?: string
}

export function useUpdateBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()

  return useCallback(
    async (id: number, changes: UpdateBookmarksListParams) => {
      try {
        const result = await fetcher({
          method: 'lists.updateCustomAssetList',
          params: { id, partial: changes },
        })

        // Add list to SWR cache
        await mutateList(result.id, () => result)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, fetcher, mutateList]
  )
}

export function useDeleteBookmarksList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()
  const navigateAway = useNavigateToNextBookmarksList()

  return useCallback(
    async (listId: number) => {
      try {
        await fetcher({
          method: 'lists.deleteCustomAssetList',
          params: { id: listId },
        })

        // Remove list from cache
        await mutateList(listId, () => undefined)

        navigateAway(listId, 'customAssetList')
      } catch (err) {
        checkListError(err)
      }
    },
    [fetcher, navigateAway, mutateList, checkListError]
  )
}
